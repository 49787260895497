import React, { useState, useEffect, useRef } from "react";
import "./subscription.css";
import { Box, Card } from "@mui/material";
import assets from "../../config/assets";
import Footer from "../footer/footer";
import CourseDetailHeader from "../course-detail-header/course-detail-header";
import PaymentService from "../../services/payment-service";
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { getCurrentUserData } from "../../utils/util";
import conditional_configs from "../../config/conditional_config";

export default function Subscription() {
  const [planList, setPlanList] = React.useState([]);
  const [priceList, setPriceList] = React.useState([]);
  const [isFree, setIsFree] = React.useState(false);
  const radioOptions = [
    { id: 1, value: 'yearly', label: 'Recurring billing once a year', month: '&1 /month' },
    { id: 2, value: 'quarterly', label: 'Recurring billing every 3 months', month: '&1 /month' }
  ];

  const handleContinueToPayment = () => {
    console.log(value);
    if (value) {
      const data = {
        isFree: isFree,
        priceId: value,
        successUrl: "http://localhost:3000/self-learning-success"
      }
      PaymentService.checkoutSubscription(data).then(response => {
        console.log('checkoutSubscription::', response);
        if (response && response.data) {
          localStorage.setItem('subscription-checkout', JSON.stringify(response.data));
          window.open(response.data.url, '_self');
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, obj) => {
    setPriceList([])
    setPriceList(obj.fullPrices);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState()

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    getMySubscriptionDetail();
  }, []);

  const getPlans = () => {
    PaymentService.getPlans().then(response => {
      console.log('getPlans::', response);
      if (response && response.plans) {
        setPlanList(response.plans);
        console.log('getPlans.plans::', response.plans);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getMySubscriptionDetail = () => {
    if (getCurrentUserData().role == conditional_configs.role.parent) {
      PaymentService.getMySubscriptionDetail()
        .then((response) => {
          console.log("getMySubscriptionDetail", response);
          if (response && response.data && response.data.length > 0) {
            setIsFree(false);
          } else {
            setIsFree(true);
          }
          getPlans();
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (getCurrentUserData().role == conditional_configs.role.learner) {
      PaymentService.getMySubscriptionDetailByKid()
        .then((response) => {
          console.log("getMySubscriptionDetail", response);
          if (response && response.data && response.data.length > 0) {
            setIsFree(false);
          } else {
            setIsFree(true);
          }
          getPlans();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsFree(false);
    }

  }

  const getLowestPrice = (prices) => {
    if (prices && prices.length > 0) {
      return prices.reduce((lowest, current) => {
        return current.unit_amount < lowest.unit_amount ? current : lowest;
      });
    } else {
      return 0;
    }
  }

  return (
    <div>
      {/* header */}

      <CourseDetailHeader referPage={"subscription"} />

      <Box className="">
        <div className="subscription-container">
          {/* subscription-section-1 */}
          <div className="subscription-section-1">
            <div className="container">
              <div className="row justify-content-center">
                <p className="choose-a-plan-text">choose a plan to start your free trial</p>
                {isFree == true ?
                  <p className="choose-a-plan-sub-text">billed after a 3-Day trial period. you can cancel at any time</p> :
                  <p className="choose-a-plan-sub-text">INTRODUCE YOUR CHILDREN TO THE FASCINATING WORLD OF CODING</p>}
              </div>
            </div>
          </div>

          {/* subscription-section-2 */}
          <div className="subscription-section-2">
            <div className="container">
              <div className="row justify-content-center">
                <p className="plan-option-text">Plan Options</p>
                {isFree == true ? <p className="plan-option-sub-text pb-0">Don't worry,you won't be charged now</p> : ""}
                {isFree == true ? <p className="plan-option-des-text">You'll be upgraded to a paid membership plan automatically at the end of the trial period if you don't cancel beforehand.</p> : ""}
              </div>
            </div>
          </div>

          {/* subscription-section-3 */}
          <div className="subscription-section-3">
            <div className="container">
              <div className="card-session-subscription">
                {planList?.map((obj, idx) => {
                  return (
                    <div key={`plans-key-` + idx}>
                      <Card key={'subscription-plans-' + idx} className="subscription-card" sx={{ overflow: "visible", zIndex: "3" }}>
                        <div className="subscription-title-session">
                          <div className="subscription-title-div">
                            <div className="d-flex justify-content-center">
                              <img alt=""
                                className="subscription-card-img"
                                src={assets.homev3.m_icon_circle}
                                style={{ width: "60px", borderRadius: "50%" }}
                              ></img>
                            </div>
                            <p className="subscription-card-title-text mb-0">
                              {obj.name}
                            </p>
                          </div>
                        </div>
                        <div className="subscription-btn-session">
                          <p className="mb-0 subscription-starting-at">Starting at:</p>

                          <p className="subscription-month">
                            <span className="big">
                              <sup>US$</sup>
                              {
                                // Get the lowest active price
                                getLowestPrice(
                                  obj.fullPrices.filter((option) => option.active)
                                ).unit_amount / 100
                              }
                            </span>
                            <span className="small">
                              {obj.fullPrices.filter((option) => option.active).length > 0 &&
                                obj.fullPrices.filter((option) => option.active)[0].recurring ? (
                                obj.fullPrices.filter((option) => option.active)[0].recurring.interval ===
                                  "month" ? (
                                  obj.fullPrices.filter((option) => option.active)[0].recurring
                                    .interval_count === 3 ? (
                                    " /3 months"
                                  ) : (
                                    " /month"
                                  )
                                ) : obj.fullPrices.filter((option) => option.active)[0].recurring.interval ===
                                  "year" ? (
                                  " /year"
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </p>



                          {/* <p className="subscription-month"><span class="big"><sup>US$</sup>{JSON.stringify(getLowestPrice(obj.fullPrices).unit_amount / 100)}</span> <span class="small">/month</span></p> */}
                          <div className="start-a-free-tr-btn-div">
                            {getCurrentUserData().role == conditional_configs.role.parent ?
                              <button type="button" className="start-a-free-tr-btn" onClick={(e) => handleClick(e, obj)} disabled={getCurrentUserData().status_id === 3}>
                                {isFree == true ? "START A FREE TRIAL" : "VIEW PAYMENT OPTIONS"}
                              </button> :
                              ""}
                          </div>
                        </div>
                      </Card>
                      <div className="subscription-details-c-bottom-session">
                        <div class="subscription-details-content-div">
                          <div>
                            {obj?.product?.marketing_features.map((mObj, mIdx) => {
                              return (
                                <div key={`m-obj-` + mIdx} class="subscription-con-details-row">{mObj.name}</div>
                              )
                            })}
                            {/* <div class="subscription-con-details-row">1 Parent Account</div>
                            <div class="subscription-con-details-row">Progress Tracking</div>
                            <div class="subscription-con-details-row">Online Support</div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  )
                })}
              </div>
            </div>
          </div>

          {getCurrentUserData().role === conditional_configs.role.parent && getCurrentUserData().status_id === 3 ? (
            <div className="subscription-page-active-note-div">
              <p className="mb-0">
                You are not an active user. Please <a href="/parent/my-profile" style={{ textDecoration: 'underline' }}>verify your account</a> and subscribe to access all features.
              </p>
            </div>
          ) : ""}




        </div>
      </Box>
      <Footer></Footer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="select-payment-period">
          <p className="select-payment-period-text">Select a payment period:</p>

          <FormControl>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {priceList.filter((option) => option.active).map((option, idx) => (
                <FormControlLabel
                  className="mb-2 mt-1"
                  key={'price-' + option.id}
                  value={option.id}
                  control={<Radio />}
                  label={
                    <div>
                      <p className="redio-option-value pb-0 m-0">
                        {option.recurring.interval === "month" ?
                          option.recurring.interval_count === 1
                            ? "MONTHLY"
                            : option.recurring.interval_count === 3
                              ? "3 MONTHS"
                              : ""
                          : option.recurring.interval === "year"
                            ? "YEARLY"
                            : ""}
                      </p>


                      <div className="d-flex align-items-center">
                        <p className="redio-option-label pb-0 m-0">
                          {option.recurring.interval === "month"
                            ? option.recurring.interval_count === 1
                              ? "Recurring billing monthly"
                              : option.recurring.interval_count === 3
                                ? "Recurring billing every 3 months"
                                : ""
                            : option.recurring.interval === "year"
                              ? "Recurring billing yearly"
                              : ""}
                        </p>
                        <p className="US_month-text mb-0 ms-5">
                          {option.currency === "usd" ? "US$" : ""}
                          {(option.unit_amount / 100)}
                          {option.recurring.interval === "month"
                            ? option.recurring.interval_count === 3
                              ? " /3 months"
                              : " /month"
                            : option.recurring.interval === "year"
                              ? " /year"
                              : ""}
                        </p>
                      </div>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className="continue-to-pay-btn-subscrition">
            <button type="button" className="start-a-free-tr-btn" onClick={handleContinueToPayment}>
              CONTINUE TO PAYMENT
            </button>
          </div>
        </div>
      </Menu>
    </div>
  );
}
