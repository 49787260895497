import React, { useEffect } from "react";
//import Header from '../../components/header/header';
import Header from "./header";
import "./terms-and-conditions.css";
import url_configs from "../../config/url";
import Footer from "../../components/footer/footer";

export default function TermsAndConditions() {
  document.title = "Meeku | Terms and Conditions";

  return (
    <div>
      <Header screen={"home"} />
      <div className="container container-terms-and-conditions">
        <div className="body">
          <h1 className="header-terms-and-conditions">Terms and Conditions</h1>
          <p className="content-terms-and-conditions">
            Welcome to Meeku LLC ("Meeku," "we," "us," or "our"). Meeku is an innovative
            online social learning platform designed for children, providing a safe and engaging
            environment for kids to chat, bring their friends, and learn together. These Terms
            and Conditions ("Terms") govern your access to and use of the Meeku platform,
            including any content, functionality, and services offered on or through our website
            and mobile applications (collectively, the "Platform").</p>

          <p className="content-terms-and-conditions">
            By accessing or using the Platform, you agree to comply with and be bound by these
            Terms. If you do not agree with these Terms, please do not use the Platform. These
            Terms apply to all users of the Platform, including parents, guardians, and their
            children
          </p>
          <p className="content-terms-and-conditions">
            Please read these Terms and Conditions carefully. They include important
            information regarding your legal rights, obligations, and the conditions under which
            you may access and use the platform. If you do not agree to these Terms and
            Conditions, you must not use Meeku.
          </p>
          <p className="content-terms-and-conditions">
            Meeku LLC reserves the right to modify or update these Terms and Conditions at
            any time. We will notify you of any changes by posting the revised Terms and
            Conditions on our platform and updating the "Last Updated" date. Your continued
            use of Meeku after any such changes constitutes your acceptance of the new Terms
            and Conditions.
          </p>
          <p className="content-terms-and-conditions">
            If you have any questions or need further clarification regarding these Terms and
            Conditions, please contact us.
          </p>

          {/* 1. DEFINITIONS */}

          <h4 className="underline font-weight-bold-privacy">1. DEFINITIONS</h4>

          <p className="content-terms-and-conditions">For the purposes of these Terms and Conditions, the following definitions shall
            apply:</p>
          <p className="content-terms-and-conditions">
            "Meeku," "we," "us," "our": Refers to Meeku LLC, including its employees,
            directors, subsidiaries, affiliates, successors, and assigns.
          </p>
          <p className="content-terms-and-conditions">
            "Platform": Refers to the Meeku social learning platform, including the website,
            mobile applications, and any other digital services provided by Meeku LLC.
          </p>
          <p className="content-terms-and-conditions">
            "User," "you," "your": Refers to any individual or entity who accesses or uses the
            Platform, including registered users, visitors, parents, guardians, and children.
          </p>
          <p className="content-terms-and-conditions">
            "Account": Refers to the personalized access credentials created by a User to utilize
            the Platform’s services, including usernames and passwords.
          </p>
          <p className="content-terms-and-conditions">
            "Content": Refers to all text, images, video, audio, data, information, software,
            applications, and other materials available on or through the Platform.
          </p>

          <p className="content-terms-and-conditions">
            "Personal Information": Refers to any information that can be used to identify an
            individual, including but not limited to name, address, email address, phone
            number, and any other information that may be classified as personal data under
            applicable privacy laws.
          </p>

          <p className="content-terms-and-conditions">
            "Service(s)": Refers to the various functionalities, features, and offerings available
            on the Platform, including but not limited to social interactions, learning activities,
            chat functions, and any other interactive services provided by Meeku LLC.
          </p>
          <p className="content-terms-and-conditions">
            "Cookies": Refers to small files placed on your device by the Platform that collect
            and store certain information about your use of the Platform.
          </p>
          <p className="content-terms-and-conditions">
            "Acceptable Use Policy": Refers to the set of rules and guidelines that governs the
            acceptable and prohibited activities when using the Platform.
          </p>
          <p className="content-terms-and-conditions">
            "Prohibited Activities": Refers to activities that are not allowed on the Platform,
            including but not limited to illegal activities, harassment, abuse, spamming, and any
            other actions that violate these Terms and Conditions.
          </p>
          <p className="content-terms-and-conditions">

            "Third-Party Services": Refers to services, products, or content provided by third
            parties that may be accessed or used through the Platform.
          </p>
          <p className="content-terms-and-conditions">
            "COPPA": Refers to the Children’s Online Privacy Protection Act, the United States
            federal law protecting the privacy of children under the age of 13.
          </p>

          {/* 2. ELIGIBILITY */}
          <h4 className="underline font-weight-bold-privacy">2. ELIGIBILITY</h4>

          <p className="content-terms-and-conditions">
            Summary: This clause ensures that users understand the age requirements, the
            necessity for parental consent for younger users, compliance with relevant laws,
            and the verification process, while also reserving Meeku's right to enforce these
            conditions.
          </p>

          <p className="content-terms-and-conditions">
            2.1 Minimum Age Requirement: To create an account and use the Meeku platform,
            users must be at least 13 years old. Users under the age of 13 may only access the
            platform through a parent or legal guardian's account and under their supervision.
            By creating an account, you represent and warrant that you meet this age
            requirement or have the necessary parental consent as required by COPPA
            (Children’s Online Privacy Protection Act).
          </p>
          <p className="content-terms-and-conditions">
            2.2 Parental Consent and Supervision: For users under the age of 13, a parent or
            legal guardian must provide verifiable consent prior to account creation. The
            parent or guardian must supervise the child’s use of the platform and is fully
            responsible for ensuring compliance with these Terms and Conditions.
          </p>
          <p className="content-terms-and-conditions">
            2.3 Compliance with Laws: By accessing or using the Meeku platform, you agree to
            comply with all applicable laws, regulations, and policies, including but not limited
            to COPPA, GDPR, and CCPA. You also agree that you are not barred from receiving
            services under the laws of your jurisdiction.
          </p>

          <p className="content-terms-and-conditions">
            2.4 Account Verification: Meeku reserves the right to verify the age and identity of
            any user at any time. If requested, you agree to provide accurate and complete
            information to facilitate this verification process. Failure to provide such
            information may result in the suspension or termination of your account.
          </p>
          <p className="content-terms-and-conditions">
            2.5 Prohibited Users: Individuals who have been previously removed or banned
            from the Meeku platform are not eligible to create a new account or use the
            platform. Meeku reserves the right to refuse service, terminate accounts, or cancel
            orders at its sole discretion.
          </p>
          <p className="content-terms-and-conditions">
            2.6 Eligibility Acknowledgment: By registering for an account or using the Meeku
            platform, you acknowledge and agree that you meet the eligibility requirements
            outlined in this clause. If you do not meet these requirements, you must not access
            or use the platform.
          </p>

          <h4 className="underline font-weight-bold-privacy">3. ACCOUNT CREATION AND MANAGEMENT</h4>

          <p className="content-terms-and-conditions">Summary: This Clause is outlined to ensure that users understand their
            responsibilities and the measures in place to protect their accounts and the integrity
            of the Meeku platform.</p>

          <p className="content-terms-and-conditions">
            3.1 Account Creation:
          </p>
          <p className="content-terms-and-conditions"> 3.1.1. Eligibility: To create an account on the Meeku platform, users must meet the
            age requirements as stipulated by applicable laws, including COPPA (Children’s
            Online Privacy Protection Act) for users under the age of 13. Parental or guardian
            consent is required for users under the age of majority in their respective
            jurisdictions.</p>
          <p className="content-terms-and-conditions"> 3.1.2 Registration Information: Users must provide accurate, current, and complete
            information during the registration process. This includes, but is not limited to, full
            name, email address, date of birth, and any other information deemed necessary
            by Meeku LLC. Users agree to maintain and promptly update their account
            information to keep it accurate, current, and complete.</p>
          <p className="content-terms-and-conditions">
            3.1.3 Account Security: Users are responsible for maintaining the confidentiality of
            their account credentials, including their username and password. Users agree to
            notify Meeku LLC immediately of any unauthorized use of their account or any
            other breach of security. Meeku LLC will not be liable for any loss or damage arising
            from the user's failure to comply with these security obligations.
          </p>

          <p className="content-terms-and-conditions">
            3.2. Account Management
          </p>
          <p className="content-terms-and-conditions">
            3.2.1 User Responsibilities: Users agree to use their Meeku account solely for
            personal, non-commercial purposes in compliance with these Terms and
            Conditions. Users must not use their account to engage in any illegal activities, to
            harass or harm others, or to violate the rights of any third party.</p>
          <p className="content-terms-and-conditions">
            3.2.2 Parental Controls: Parents and guardians have the ability to monitor and
            manage their child's account. This includes setting usage limits, monitoring
            interactions, and managing privacy settings. Meeku LLC provides tools and
            resources to assist parents in safeguarding their child’s online experience.
          </p>
          <p className="content-terms-and-conditions">
            3.2.3 Account Suspension and Termination:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li>Suspension: Meeku LLC reserves the right to suspend a user’s account, with
                or without notice, if there is a suspicion of a breach of these Terms and
                Conditions, unauthorized use, or any other activity that could harm Meeku
                LLC or its users.</li>

              <li>Termination: Meeku LLC may terminate a user’s account permanently for
                repeated violations of these Terms and Conditions, fraudulent or illegal
                activities, or any other reason deemed necessary to protect the integrity of
                the platform. Users may also terminate their account at any time by
                following the account deletion process provided in their account settings.</li>

            </ul>
          </div>

          <p className="content-terms-and-conditions">3.2.4 Data Retention and Deletion: Upon account termination, Meeku LLC will
            retain user data in accordance with its Privacy Policy and applicable laws. Users
            may request the deletion of their personal data as per the guidelines provided in
            the Privacy Policy. However, some information may be retained for legal or
            legitimate business purposes.</p>


          <h4 className="underline font-weight-bold-privacy">4. USER RESPONSIBILITIES AND CONDUCT</h4>

          <p className="content-terms-and-conditions">
            Summary: This clause ensures that users understand their responsibilities and the
            acceptable conduct expected on the Meeku platform, promoting a safe and positive
            environment for all users.
          </p>
          <p className="content-terms-and-conditions">
            4.1. User Responsibilities
          </p>
          <p className="content-terms-and-conditions"> As a user of Meeku LLC's platform, you agree to:</p>
          <p className="content-terms-and-conditions">
            4.1.1 Provide Accurate Information: Ensure that all information provided during
            account registration and throughout your use of the platform is accurate, current,
            and complete.
          </p>
          <p className="content-terms-and-conditions">
            4.1.2 Protect Your Account: Safeguard your account credentials and notify Meeku
            immediately of any unauthorized use or security breach. You are responsible for all
            activities that occur under your account.
          </p>
          <p className="content-terms-and-conditions">
            4.1.3 Comply with Laws: Abide by all applicable local, state, national, and
            international laws and regulations when using the platform.
          </p>
          <p className="content-terms-and-conditions">
            4.1.4 Monitor Child Activity: If you are a parent or guardian, you are responsible for
            monitoring and managing your child's use of the platform to ensure adherence to
            these Terms and Conditions.
          </p>
          <p className="content-terms-and-conditions">
            4.2. Prohibited Conduct
          </p>
          <p className="content-terms-and-conditions">
            You agree not to engage in any of the following prohibited activities:
          </p>
          <p className="content-terms-and-conditions">
            4.2.1 Illegal Activities: Using the platform for any unlawful purpose or in
            furtherance of illegal activities.
          </p>
          <p className="content-terms-and-conditions">
            4.2.2 Harassment and Abuse: Engaging in harassing, threatening, abusive, or
            defamatory behavior towards other users, Meeku staff, or third parties.
          </p>
          <p className="content-terms-and-conditions">
            4.2.3 Harmful Content: Uploading, posting, transmitting, or otherwise making
            available any content that is harmful, obscene, pornographic, violent,
            discriminatory, or otherwise objectionable.
          </p>
          <p className="content-terms-and-conditions">
            4.2.4 Impersonation: Impersonating any person or entity, or falsely stating or
            misrepresenting your affiliation with a person or entity.
          </p>
          <p className="content-terms-and-conditions">
            4.2.5 Disruption: Interfering with or disrupting the platform, servers, or networks
            connected to the platform, or disobeying any requirements, procedures, policies,
            or regulations of networks connected to the platform.
          </p>
          <p className="content-terms-and-conditions">
            4.2.6 Unauthorized Access: Attempting to gain unauthorized access to the
            platform, other user accounts, or computer systems or networks connected to the
            platform through hacking, password mining, or any other means.
          </p>
          <p className="content-terms-and-conditions">
            4.2.7 Spamming and Phishing: Sending unsolicited or unauthorized advertisements,
            spam, or phishing messages.
          </p>
          <p className="content-terms-and-conditions">
            4.2.8 Exploitation: Exploiting any bugs, vulnerabilities, or unintended features of
            the platform for personal gain or to the detriment of Meeku or its users.
          </p>
          <p className="content-terms-and-conditions">
            4.2.9 Data Mining: Using any automated system, including without limitation
            "robots," "spiders," or "offline readers," to access the platform in a manner that
            sends more request messages to Meeku servers than a human can reasonably
            produce in the same period using a conventional web browser.
          </p>
          <p className="content-terms-and-conditions">
            4.2.10 Intellectual Property Infringement: Uploading, posting, or transmitting any
            content that infringes or violates the intellectual property rights or proprietary
            rights of any party.
          </p>
          <p className="content-terms-and-conditions">
            4.3. Enforcement
          </p>
          <p className="content-terms-and-conditions">
            4.3.1 Monitoring: Meeku reserves the right to monitor user activity to ensure
            compliance with these Terms and Conditions and to investigate any suspected
            breaches.
          </p>
          <p className="content-terms-and-conditions">
            4.3.2 Consequences: Violations of these Terms and Conditions may result in
            account suspension or termination, removal of prohibited content, legal action,
            and/or reporting to law enforcement authorities.
          </p>

          {/* 5. CONTENT OWNERSHIP AND USAGE */}

          <h3 className="underline font-weight-bold-privacy">5. CONTENT OWNERSHIP AND USAGE</h3>


          <p className="content-terms-and-conditions">
            Summary: This clause outlines the intellectual property rights of users while
            allowing Meeku LLC the necessary permissions to operate and enhance the platform
            effectively.
          </p>
          <p className="content-terms-and-conditions">
            5.1. User-Generated Content
          </p>
          <p className="content-terms-and-conditions">
            5.1.1 Ownership: By submitting, posting, or displaying any content on or through
            the Meeku platform, you retain ownership of the content. However, you grant
            Meeku LLC a non-exclusive, royalty-free, transferable, sub-licensable, worldwide
            license to use, store, display, reproduce, modify, create derivative works, perform,
            and distribute your content solely for the purposes of operating, developing,
            providing, and using the Meeku platform.
          </p>
          <p className="content-terms-and-conditions">
            5.1.2 Responsibility: You are solely responsible for the content you post on the
            Meeku platform. This includes ensuring that your content complies with all
            applicable laws, does not infringe on the rights of others, and is not offensive,
            harmful, or inappropriate.
          </p>
          <p className="content-terms-and-conditions">
            5.1.3 Prohibited Content: You agree not to post content that:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li>Violates any laws or regulations.</li>
              <li>Infringes on the intellectual property rights of others.</li>
              <li>Is defamatory, obscene, offensive, or promotes hate or violence.</li>
              <li>Contains personal information about others without their consent.</li>
              <li>Contains viruses, malware, or any other harmful software.</li>
            </ul>
          </div>


          <p className="content-terms-and-conditions">
            5.2. Meeku Content
          </p>
          <p className="content-terms-and-conditions">
            5.2.1 Ownership: All content provided by Meeku LLC, including but not limited to
            text, graphics, logos, images, audio clips, video clips, and software, is the property
            of Meeku LLC or its content suppliers and is protected by intellectual property laws
          </p>
          <p className="content-terms-and-conditions">
            5.2.2 Usage: You are granted a limited, non-exclusive, non-transferable license to
            access and use the content provided by Meeku LLC for your personal, non-
            commercial use only. You may not reproduce, distribute, modify, create derivative
            works, publicly display, or perform the content provided by Meeku LLC without our
            express written permission.
          </p>
          <p className="content-terms-and-conditions">
            5.3. Feedback and Suggestions
          </p>
          <p className="content-terms-and-conditions">
            5.3.1 Submission: Any feedback, suggestions, or ideas you provide to Meeku LLC
            regarding the platform or services ("Feedback") is entirely voluntary.
          </p>
          <p className="content-terms-and-conditions">
            5.3.2 License: By submitting Feedback, you grant Meeku LLC a worldwide,
            perpetual, irrevocable, non-exclusive, royalty-free, fully paid-up, transferable, and
            sub-licensable right to use, reproduce, modify, distribute, and display the Feedback
            in any manner and for any purpose, without any obligation to compensate you.
          </p>
          <p className="content-terms-and-conditions">
            5.4. Third-Party Content
          </p>
          <p className="content-terms-and-conditions">
            5.4.1 Responsibility: The Meeku platform may contain content provided by third
            parties, including links to third-party websites. Meeku LLC does not control,
            endorse, or assume responsibility for any third-party content.
          </p>
          <p className="content-terms-and-conditions">
            5.4.2 Usage: Your use of third-party content is at your own risk and subject to the
            terms and conditions of the third-party provider. Meeku LLC is not liable for any
            damages or losses caused by your interaction with third-party content.
          </p>
          <p className="content-terms-and-conditions">
            5.5. Reporting Violations
          </p>
          <p className="content-terms-and-conditions">
            5.5.1 Process: If you believe any content on the Meeku platform violates these
            Terms and Conditions, please report it to us immediately. We reserve the right to
            investigate and take appropriate action, including removing content and
            terminating accounts.
          </p>
          <p className="content-terms-and-conditions">
            5.5.2 No Obligation: While Meeku LLC reserves the right to monitor and remove
            content that violates these Terms and Conditions, we are not obligated to do so
            and do not guarantee the prompt removal of objectionable content.
          </p>

          {/* 6. CONTENT MODERATION */}

          <h3 className="underline font-weight-bold-privacy">6. CONTENT MODERATION</h3>

          <p className="content-terms-and-conditions">
            6.1. Overview: At Meeku LLC, we are committed to providing a safe, positive, and
            enriching environment for children. To achieve this, we implement rigorous
            content moderation practices to ensure that all user-generated content adheres to
            our standards and policies.
          </p>
          <p className="content-terms-and-conditions">
            6.2. Content Guidelines: All content shared or created on the Meeku platform,
            including but not limited to text, images, videos, and interactive elements
            (collectively, "User Content"), must comply with the following guidelines:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li>Respectfulness: User Content must be respectful and considerate of others.
                Hate speech, harassment, threats, or any content intended to intimidate or
                demean others is strictly prohibited.
              </li>
              <li>Safety: User Content must not include or promote harmful behaviors,
                including but not limited to bullying, self-harm, or unsafe activities.</li>
              <li>Privacy: User Content must not disclose personal information of any
                individual, including other users, without their explicit consent.</li>
              <li>Legality: User Content must comply with all applicable laws and regulations.
                Content that promotes illegal activities, including the use or distribution of
                illegal substances or activities, is prohibited.</li>
              <li> Accuracy: User Content must be accurate and not spread misinformation.
                Misleading or false content, including false claims or deceptive practices, is
                not allowed.</li>
            </ul>
          </div>


          <p className="content-terms-and-conditions">
            6.3. Parental and Guardian Involvement: Parents and guardians play a crucial role
            in overseeing their child’s interactions on the platform. We encourage parents and
            guardians to actively monitor their child’s use of Meeku Platform and to report any
            concerns or inappropriate content.
          </p>
          <p className="content-terms-and-conditions">
            6.4. Reporting and Review: Users, including children, parents, and guardians, are
            encouraged to report any content they believe violates our policies or poses a risk
            to safety. Reports can be submitted via the platform's reporting features. Meeku
            LLC will review all reports promptly and take necessary actions, including content
            removal or user account suspension, to address violations.
          </p>
          <p className="content-terms-and-conditions">
            6.5. Update to Content Moderation Policies: Meeku LLC reserves the right to
            modify its content moderation policies at any time to adapt to new challenges or
            changes in legal requirements. We are committed to transparency and will notify
            users, including parents and guardians, of any significant changes to our
            moderation practices.
          </p>
          <p className="content-terms-and-conditions">
            6.6. Appeals Process: Users who believe that content was unjustly removed or
            accounts were improperly suspended may appeal the decision by contacting our
            support team. Appeals will be reviewed on a case-by-case basis to ensure fairness
            and adherence to our policies.
          </p>
          <p className="content-terms-and-conditions">
            6.7. Limitation of Liability: While we strive to maintain a safe environment, Meeku
            LLC cannot guarantee that all User Content will be free from violations. Users
            acknowledge that Meeku LLC is not liable for any harm or damages arising from
            User Content or moderation actions.
          </p>

          {/* 7. PAYMENT AND SUBSCRIPTION */}

          <h3 className="underline font-weight-bold-privacy">7. PAYMENT AND SUBSCRIPTION</h3>

          <p className="content-terms-and-conditions">
            By subscribing to Meeku LLC's services, you agree to pay the subscription fees
            associated with the chosen plan. Our subscription options include monthly, 3-
            month, or annual plans, each designed to provide flexible access to our platform's
            features.
          </p>
          <p className="content-terms-and-conditions">
            The subscription fees are billed in advance and are non-refundable, except as
            required by law. Payments for monthly subscriptions are charged on a recurring
            basis every month from the date of your initial subscription. For the 3-month plan,
            you will be billed every three months, and for the yearly plan, you will be billed
            annually.
          </p>
          <p className="content-terms-and-conditions">
            You will be automatically charged for the renewal of your subscription at the end
            of each billing period unless you cancel your subscription before the end of the
            current period. Cancellations must be completed through your account settings or
            by contacting our support team. Please note that cancellation will take effect at the
            end of the current billing cycle, and no refunds will be issued for any unused portion
            of the subscription period.
          </p>
          <p className="content-terms-and-conditions">
            Meeku LLC reserves the right to change the subscription fees and billing terms at
            its discretion. Any changes to the fees or billing terms will be communicated to you
            in advance, and your continued use of the platform following such changes will
            constitute your acceptance of the revised terms.
          </p>

          {/* 8. PRIVACY AND DATA PROTECTION */}


          <h3 className="underline font-weight-bold-privacy">8. PRIVACY AND DATA PROTECTION</h3>


          <p className="content-terms-and-conditions">
            At Meeku LLC, we prioritize the privacy and data protection of our users. Our
            comprehensive Privacy Policy outlines our commitment to safeguarding your
            personal information. By using the Meeku platform, you acknowledge and agree to
            the collection, use, and sharing of your data as described in our Privacy Policy.
          </p>
          <p className="content-terms-and-conditions">
            Key Clauses:
          </p>
          <p className="content-terms-and-conditions">
            Information Collection: We collect personal information to provide and improve
            our services. This includes details provided during account creation and activity on
            the platform.
          </p>
          <p className="content-terms-and-conditions">
            Use of Information: Your information is used to personalize your experience,
            enhance platform functionality, and ensure the safety and security of our users.
          </p>
          <p className="content-terms-and-conditions">
            Data Sharing: We do not sell your personal information. Data may be shared with
            third parties only as necessary to provide our services or comply with legal
            obligations.
          </p>
          <p className="content-terms-and-conditions">
            User Rights: You have the right to access, modify, and delete your personal
            information. Our Privacy Policy provides detailed instructions on how to manage
            your data.
          </p>
          <p className="content-terms-and-conditions">
            Children’s Privacy: We take special care to protect the privacy of children. This
            section outlines the measures we take to safeguard children’s data in compliance
            with COPPA.
          </p>
          <p className="content-terms-and-conditions">
            For a full understanding of how we protect your privacy and manage your data,
            please review our [Privacy Policy] (link to Privacy Policy). Your continued use of the
            Meeku platform constitutes your acceptance of these terms and our Privacy Policy.
          </p>

          {/* 9. DISCLAIMERS AND LIMITATION OF LIABILITY */}

          <h3 className="underline font-weight-bold-privacy">9. DISCLAIMERS AND LIMITATION OF LIABILITY</h3>


          <p className="content-terms-and-conditions">
            9.1. General Disclaimers
          </p>
          <p className="content-terms-and-conditions">
            9.1.1 No Warranties: The Meeku platform, including all content, services, and
            features available through it, is provided "as is" and "as available" without
            warranties of any kind, either express or implied. To the fullest extent permissible
            pursuant to applicable law, Meeku LLC disclaims all warranties, express or implied,
            including, but not limited to, implied warranties of merchantability, fitness for a
            particular purpose, non-infringement, accuracy, and reliability. Meeku LLC does not
            warrant that the functions contained in the platform will be uninterrupted or errorfree, that defects will be corrected, or that the platform or the server that makes it
            available are free of viruses or other harmful components.
          </p>
          <p className="content-terms-and-conditions">
            9.1.2. Educational Content: The educational content provided on Meeku Platform
            is for educational purposes only. While we strive to offer valuable and accurate
            educational resources, Meeku LLC makes no representations or warranties
            regarding the accuracy, completeness, or usefulness of any educational content
            provided. Users are responsible for verifying any information before relying on it.
          </p>
          <p className="content-terms-and-conditions">
            9.1.3. Third-Party Content: The platform may include links to third-party websites
            or content. Meeku LLC does not endorse, guarantee, or assume responsibility for
            the accuracy or reliability of any information, content, products, or services
            provided by third-party websites. Accessing third-party links is at your own risk.
          </p>
          <p className="content-terms-and-conditions">
            9.1.4. Technology and Security: Meeku LLC strives to keep the platform secure and
            functioning smoothly, but we do not guarantee uninterrupted, secure, or error-free
            access to the platform. We are not responsible for any issues arising from technical
            problems, hacking attempts, or unauthorized access to your account.
          </p>
          <p className="content-terms-and-conditions">
            9.2. Limitation of Liability
          </p>
          <p className="content-terms-and-conditions">
            9.2.1 Exclusion of Liability: To the maximum extent permitted by applicable law,
            Meeku LLC, its affiliates, officers, directors, employees, agents, licensors, and
            service providers shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages, or any loss of profits or revenues, whether
            incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible
            losses, resulting from (i) your use of or inability to use the Meeku platform; (ii) any
            unauthorized access to or use of our servers and/or any personal information
            stored therein; (iii) any interruption or cessation of transmission to or from our
            platform; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted
            to or through our platform by any third party; (v) any errors or omissions in any
            content or for any loss or damage incurred as a result of the use of any content
            posted, emailed, transmitted, or otherwise made available through the Meeku
            platform; and/or (vi) the defamatory, offensive, or illegal conduct of any third
            party.
          </p>
          <p className="content-terms-and-conditions">
            9.2.2 Aggregate Liability: In no event shall Meeku LLC’s aggregate liability for all
            claims relating to the platform exceed the greater of one hundred U.S. dollars (U.S.
            $100.00) or the total amount of fees paid by you to Meeku LLC for the services in
            the twelve (12) months preceding the claim.
          </p>
          <p className="content-terms-and-conditions">
            9.3. Indemnification
          </p>
          <p className="content-terms-and-conditions">
            9.3.1 User Indemnity: You agree to defend, indemnify, and hold harmless Meeku
            LLC, its affiliates, and their respective officers, directors, employees, agents,
            licensors, and service providers from and against any and all claims, damages,
            obligations, losses, liabilities, costs, or debt, and expenses (including but not limited
            to attorney's fees) arising from: (i) your use of and access to the Meeku platform;
            (ii) your violation of any term of these Terms and Conditions; (iii) your violation of
            any third-party right, including without limitation any copyright, property, or
            privacy right; or (iv) any claim that your content caused damage to a third party.
            This defense and indemnification obligation will survive these Terms and
            Conditions and your use of the Meeku platform.
          </p>

          <p className="content-terms-and-conditions">9.4 All the people, places, and stories in the missions and challenges are pretend. If anything seems like real life, it's just a coincidence! The fun events, even the futuristic or exciting ones, are not meant to be copied in real life. Enjoy the game safely!</p>



          {/* 10. MODIFICATION OF TERMS */}

          <h3 className="underline font-weight-bold-privacy">10. MODIFICATION OF TERMS</h3>



          <p className="content-terms-and-conditions">
            10.1. Right to Modify the Terms and Conditions: Meeku LLC reserves the right, at
            our sole discretion, to modify, amend, or update these Terms and Conditions at any
            time. Any changes will become effective immediately upon being posted on our
            platform unless otherwise stated.
          </p>
          <p className="content-terms-and-conditions">
            10.2. Notification Process for Changes: We are committed to keeping our users
            informed of any changes to these Terms and Conditions. Accordingly, we will
            provide notice of such changes through one or more of the following methods:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li>Posting the updated Terms and Conditions on our platform.
              </li>
              <li>Sending an email notification to registered users.</li>
              <li> Displaying a prominent announcement on our website or within the
                platform.</li>
            </ul>
          </div>


          <p className="content-terms-and-conditions">
            10.3. User’s Responsibility to Review Changes: It is the responsibility of all users to
            review the Terms and Conditions periodically to stay informed of any modifications.
            Continued use of the Meeku platform following the posting of changes constitutes
            your acceptance of those changes. If you do not agree with the modified Terms and
            Conditions, you must discontinue your use of the platform immediately.
          </p>

          {/* 11. TERMINATION OF SERVICE */}

          <h3 className="underline font-weight-bold-privacy">11. TERMINATION OF SERVICE</h3>

          <p className="content-terms-and-conditions">11.1. Conditions for Terminating User Accounts: Meeku LLC reserves the right to
            terminate or suspend user accounts under the following conditions:</p>



          <div class="deletion-privacy-policy">
            <ul>
              <li>Violation of the Terms & Conditions, including but not limited to
                unacceptable use or prohibited activities.</li>
              <li>Provision of false or misleading information during account creation or use
                of the platform.</li>
              <li>Engagement in activities that compromise the safety, security, or integrity of
                the platform or its users. </li>
              <li>Non-compliance with legal obligations, regulatory requirements, or requests
                from law enforcement. </li>
              <li> Prolonged inactivity of the account, as determined by Meeku LLC’s policies.</li>
            </ul>
          </div>

          <p className="content-terms-and-conditions">
            11.2. Consequences of Termination: Upon termination or suspension of an
            account:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li> The user’s access to the platform and all associated services will be
                immediately revoked.</li>
              <li>All content, data, and personal information associated with the account may
                be deleted or rendered inaccessible, subject to Meeku LLC’s data retention
                policies and legal obligations.</li>
              <li>Any ongoing or pending transactions, subscriptions, or services will be
                canceled without the possibility of refund. </li>
              <li>The user will be prohibited from creating a new account or accessing the
                platform using another account. </li>
            </ul>
          </div>

          <p className="content-terms-and-conditions">
            11.3. Appeal Process for Terminated Accounts: Users who believe their account has
            been wrongfully terminated or suspended may appeal the decision by following
            these steps:
          </p>

          <div class="deletion-privacy-policy">
            <ul>
              <li> Submit a formal written appeal to Meeku LLC’s support team within 30 days
                of the termination notice. The appeal must include the user’s account
                information, reason for the appeal, and any supporting evidence.</li>
              <li>Meeku LLC will review the appeal and respond within 14 business days. The
                review process may require additional information or clarification from the
                user.</li>
              <li>If the appeal is successful, the account will be reinstated with any applicable
                conditions or restrictions as determined by Meeku LLC. If the appeal is
                denied, the decision will be final, and the user will be notified of the
                outcome. </li>
            </ul>
          </div>

          <h3 className="underline font-weight-bold-privacy">
            12. GOVERNING LAW</h3>
          <p className="content-terms-and-conditions"> These Terms & Conditions, Privacy Policy, and Kids Policy
            (collectively, the "Policies") shall be governed by and construed in accordance with
            the laws of the United States and the State of California, without regard to its
            conflict of law principles.
          </p>
          <h3 className="underline font-weight-bold-privacy">13. MISCELLANEOUS</h3>
          <p className="content-terms-and-conditions">13.1. Entire Agreement: These Terms & Conditions, along with our Privacy Policy
            and Kids Policy, constitute the entire agreement between you and Meeku LLC
            regarding your use of the Platform and supersede all prior or contemporaneous
            agreements and understandings, whether written or oral, relating to the subject
            matter herein.</p>
          <p className="content-terms-and-conditions">13.2. Severability: If any provision of these Terms & Conditions is found to be
            invalid, illegal, or unenforceable by a court of competent jurisdiction, such
            provision shall be deemed to be modified or omitted to the extent necessary to
            make it valid, legal, and enforceable. The remaining provisions shall continue in full
            force and effect.</p>
          <p className="content-terms-and-conditions">13.3. Waiver: No waiver of any provision of these Terms & Conditions shall be
            deemed a further or continuing waiver of such provision or any other provision.
            Failure by Meeku LLC to enforce any right or provision of these Terms & Conditions
            shall not constitute a waiver of such right or provision.</p>
          <p className="content-terms-and-conditions">13.4. Assignment: Meeku LLC may assign its rights and obligations under these
            Terms & Conditions to any third party without notice. You may not assign or
            transfer any of your rights or obligations under these Terms & Conditions without
            the prior written consent of Meeku LLC.</p>
          <p className="content-terms-and-conditions">13.5. Force Majeure: Meeku LLC shall not be liable for any failure or delay in
            performance under these Terms & Conditions due to causes beyond its reasonable
            control, including but not limited to acts of God, natural disasters, war, terrorism,
            strikes, or interruptions of essential services.</p>
          <p className="content-terms-and-conditions">13.6. Headings: The headings in these Terms & Conditions are for convenience only
            and have no legal or contractual effect.</p>
          <p className="content-terms-and-conditions">13.7. Contact Information: For any questions or concerns regarding these Terms &
            Conditions, please contact us</p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
