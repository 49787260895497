import React, { useState, useEffect } from "react";
import { filter } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import "./course-content.css";
import {
    Autocomplete,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Fade,
    Grid,
    FormControl,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Radio,
    Select,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TextField,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";

import Page from '../../../../../components/Page';

import { SnackbarProvider, useSnackbar } from 'notistack';

//Icon
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SourceIcon from "@mui/icons-material/Source";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from '@mui/material/IconButton';
import LockOutlined from "@mui/icons-material/LockOutlined";
import LockResetOutlined from "@mui/icons-material/LockResetOutlined";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

//FILE UPLOAD
import DropFileInput from '../../../../../components/drop-file-input/DropFileInput';

//SERVICES
import AdminService from "../../../../../services/admin-service";
import DocumentService from "../../../../../services/document-service";
import CourseService from "../../../../../services/courses-service";
import LookUpervice from "../../../../../services/look-up-service";
import SessionService from "../../../../../services/session-service";

//CONFIG
import route_config from "../../../../../config/route_config";
import curriculamTypes from '../../../../../config/curriculam_types';
import conditionalConfig from '../../../../../config/conditional_config';
import questionTypes from '../../../../../config/question-types';
import curriculam_content_file_types from '../../../../../config/curriculam_content_file_types';
import fileTypesConfig from '../../../../../config/file_types';
import mimeTypes from '../../../../../config/mime-types';
import navigate_route_config from "../../../../../config/navigate_route_config";
import games from "../../../../../config/games";
import url_configs from "../../../../../config/url";

import { getCurrentUserData, getUnique } from "../../../../../utils/util";
//ASSETS
import Sampleimg from "../../../../../assets/img/white-board/whiteboard.png";
import Whiteboard_Icon from "../../../../../assets/img/white-board/whiteboard-24.png";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export default function CourseContent() {
    let { courseid } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [contentSessionList, setContentSessionList] = useState([]); //Used in Step - 4 COURSE CONTENT SESSION

    /** CONTENT DETAILS: TYPE: CONENT DECLARATIONS */
    const [anchorElContentFileTypes, setAnchorElContentFileTypes] = React.useState(null);
    const openContentFileTypes = Boolean(anchorElContentFileTypes);

    /** CONTENT DETAILS: TYPE: QUIZ DECLARATIONS */
    const [openAddQuizInContentSession, setOpenAddQuizInContentSession] = React.useState(false);
    const [quizTemplateList, setQuizTemplateList] = React.useState([]);
    const [selectedQuizTemplate, setSelectedQuizTemplate] = React.useState("");
    const [currentContentSessionTemplateQuizPopupIndex, setCurrentContentSessionTemplateQuizPopupIndex] = React.useState();
    const [currentContentIndex, setCurrentContentIndex] = React.useState();
    const [currentContentDetailIndex, setCurrentContentDetailIndex] = React.useState();
    const [acceptContentFileType, setAcceptContentFileType] = React.useState();

    const [whiteboardItems, setWhiteboardItems] = React.useState([]);

    /** CONTENT DETAILS: TYPE: WHITEBOARD DECLARATIONS */
    const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);
    const [openAddWhiteboardInContentSession, setOpenAddWhiteboardInContentSession] = React.useState(false);

    /** CONTENT DETAILS: TYPE: GAME DECLARATIONS */
    const [gameTemplateList, setGameTemplateList] = React.useState(games.gameList);
    const [openAddGameInContentSession, setOpenAddGameInContentSession] = React.useState(false);
    //FORM FIELD DECLARATION

    const {
        control,
        handleSubmit,
        reset,
        register,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (getCurrentUserData() && getCurrentUserData().role == conditionalConfig.role.admin) {
            if (!courseid) {
                navigate(route_config.admin.courses.main_route_name);
            }
            getAllWhiteboardTemplate();
            getContentSessionList('curriculum');
        } else {
            localStorage.clear();
            navigate('/admin-login');
        }
    }, []);

    //#region APIS
    /** GET SESSION LIST */
    //Used in Content Session(Step 4)  and Pods(Step 5)
    const getContentSessionList = (form) => {
        if (courseid)
            CourseService.GetCourseDetailsByCourseId(courseid).then(response => {
                if (form == 'curriculum') {
                    console.log('curriculum', response)
                    setContentSessionList([]);
                    let tempSessionList = [];
                    response.data.coursesession.forEach(element => {
                        let tempSessionObj = {
                            coursesessionid: "",
                            name: "",
                            curriculumdetail: [],
                            contentTypes: []
                        };
                        SessionService.getAdminCourseContentBySessionId(element.id).then(response => {
                            if (response && response.success == "true") {
                                console.log('getAdminCourseContentBySessionId', response)
                                //setWhiteboardList(response.data.coursecontentsessionwhiteboard);
                                // GET CONTENT DETAILS
                                if (response.data.coursecontentsessiondetail && response.data.coursecontentsessiondetail.length > 0) {
                                    response.data.coursecontentsessiondetail.forEach(contentDetEle => {
                                        let contentObj = {
                                            subject: contentDetEle.subject,
                                            curriculumlink: contentDetEle.curriculumlink,
                                            contenttype: conditionalConfig.contentTypes.file,
                                            curriculumtype: conditionalConfig.curriculam_types.content,
                                            sequence: "1",
                                            items: [],
                                        };
                                        if (contentObj.curriculumlink != "" && contentObj.curriculumlink != null && contentObj.curriculumlink != undefined) {
                                            contentObj.curriculumlink = contentDetEle.curriculumlink;
                                            tempSessionObj.curriculumdetail.push(contentObj);
                                        } else {
                                            DocumentService.getDocumentById(contentDetEle.documentid).then(response => {
                                                console.log('Document', response.data);
                                                if (response.data) {
                                                    let docObj = {
                                                        documentid: response.data.id,
                                                        documenttype: response.data.mimetype,
                                                        documentname: response.data.originalname,
                                                        documenturl: contentDetEle.document.documenturl
                                                    }
                                                    contentObj.items.push(docObj);
                                                }
                                                tempSessionObj.curriculumdetail.push(contentObj);
                                            }).catch(e => {
                                                console.log(e);
                                            });
                                        }
                                    });
                                }

                                //GET SESSION WHITEBOADS
                                if (response.data.coursecontentsessionwhiteboard && response.data.coursecontentsessionwhiteboard.length > 0) {
                                    response.data.coursecontentsessionwhiteboard.forEach(whiteboardDetEle => {
                                        let whiteboardItems = [];
                                        let questionItemObj = {
                                            templateid: whiteboardDetEle.templateid,
                                            template_ids: whiteboardDetEle.template_ids,
                                            is_locked: whiteboardDetEle.is_locked
                                        }
                                        whiteboardItems.push(questionItemObj);

                                        let contentObj = {
                                            subject: whiteboardDetEle?.group_name,
                                            curriculumtype: conditionalConfig.curriculam_types?.whiteboard,
                                            templateid: whiteboardDetEle?.whiteboard_template?.id,
                                            templatename: whiteboardDetEle?.group_name,
                                            slug: whiteboardDetEle?.whiteboard_template?.slug,
                                            items: whiteboardItems,
                                            sequence: "1"
                                        };
                                        tempSessionObj.curriculumdetail.push(contentObj);
                                    });
                                }

                                /** GAMES */
                                if (response.data.coursecontentsessiongame && response.data.coursecontentsessiongame.length > 0) {
                                    response.data.coursecontentsessiongame.forEach(obj => {
                                        let gameItems = [];
                                        // let questionItemObj = {
                                        //     templateid: obj.templateid,
                                        //     is_locked: obj.is_locked
                                        // }
                                        gameItems.push(obj);

                                        let contentObj = {
                                            subject: obj?.name,
                                            curriculumtype: conditionalConfig.curriculam_types?.games,
                                            templateid: obj?.puzzleid,
                                            templatename: obj?.name,
                                            slug: '',
                                            items: gameItems,
                                            sequence: "1"
                                        };
                                        tempSessionObj.curriculumdetail.push(contentObj);
                                    });
                                }

                                /** QUIZ */
                                if (response.data.coursecontentsessionquiz && response.data.coursecontentsessionquiz.length > 0) {
                                    // let tempcoursecontentsessionquiz = response.data.coursecontentsessionquiz.reduce((x, y) => {

                                    //     (x[y.templateid] = x[y.templateid] || []).push(y);

                                    //     return x;

                                    // }, {});
                                    //  console.log("grouped quiz", tempcoursecontentsessionquiz);
                                    //  Object.getOwnPropertyNames(tempcoursecontentsessionquiz).forEach((val, idx, array) => {
                                    //     console.log(`${val} -> ${tempcoursecontentsessionquiz[val]}`);

                                    //   });

                                    response.data.coursecontentsessionquiz.forEach(quizEle => {
                                        if (quizEle.coursequestion) {
                                            let tempQuizItems = [];

                                            let tempQuizObj = {
                                                answerlist: quizEle.coursequestion.answerlist,
                                                question: quizEle.coursequestion.question,
                                                questiontype: quizEle.coursequestion.questiontype,
                                                sequence: '1',
                                                templateid: quizEle?.templateid
                                            }

                                            tempQuizObj.answerlist.forEach(element => {
                                                if (element.correct && element.correct == '1') {
                                                    element.correct = 'true'
                                                } else {
                                                    element.correct = 'false'
                                                }
                                            });

                                            tempQuizItems.push(tempQuizObj);

                                            let contentObj = {
                                                subject: quizEle.coursequestion?.question,
                                                curriculumtype: conditionalConfig.curriculam_types?.quiz,
                                                templateid: quizEle?.templateid,
                                                templatename: quizEle?.template?.name,
                                                slug: '',
                                                items: tempQuizItems,
                                                sequence: "1"
                                            };
                                            tempSessionObj.curriculumdetail.push(contentObj);

                                            // if (tempSessionObj.curriculumdetail && tempSessionObj.curriculumdetail.length > 0) {
                                            //     let checkTemplates = tempSessionObj.curriculumdetail.filter(e => e.curriculumtype == conditionalConfig.curriculam_types?.quiz);
                                            //     if (checkTemplates && checkTemplates.length == 0) {
                                            //         let contentObj = {
                                            //             subject: quizEle.coursequestion?.question,
                                            //             curriculumtype: conditionalConfig.curriculam_types?.quiz,
                                            //             templateid: quizEle?.templateid,
                                            //             templatename: quizEle?.template?.name,
                                            //             slug: '',
                                            //             items: tempQuizItems,
                                            //             sequence: "1"
                                            //         };
                                            //         tempSessionObj.curriculumdetail.push(contentObj);
                                            //     }else if(checkTemplates && checkTemplates.length > 0){
                                            //         tempSessionObj.curriculumdetail.forEach(element => {
                                            //             if (element.curriculumtype == conditionalConfig.curriculam_types?.quiz) {
                                            //                 if (element.templateid == quizEle?.templateid) {
                                            //                     element.items.push(tempQuizItems);
                                            //                 }
                                            //                 // else{
                                            //                 //     let contentObj = {
                                            //                 //         subject: quizEle.coursequestion?.question,
                                            //                 //         curriculumtype: conditionalConfig.curriculam_types?.quiz,
                                            //                 //         templateid: quizEle?.templateid,
                                            //                 //         templatename: quizEle?.template?.name,
                                            //                 //         slug: '',
                                            //                 //         items: tempQuizItems,
                                            //                 //         sequence: "1"
                                            //                 //     };
                                            //                 //     tempSessionObj.curriculumdetail.push(contentObj);
                                            //                 // }
                                            //             }
                                                        
                                            //         });
                                            //     }
                                            // } else {
                                            //     let contentObj = {
                                            //         subject: quizEle.coursequestion?.question,
                                            //         curriculumtype: conditionalConfig.curriculam_types?.quiz,
                                            //         templateid: quizEle?.templateid,
                                            //         templatename: quizEle?.template?.name,
                                            //         slug: '',
                                            //         items: tempQuizItems,
                                            //         sequence: "1"
                                            //     };
                                            //     tempSessionObj.curriculumdetail.push(contentObj);
                                            // }



                                        }
                                    });
                                }
                                tempSessionObj.coursesessionid = element.id;
                                tempSessionObj.name = element.name;
                                tempSessionObj.contentTypes = curriculamTypes.types;
                                tempSessionList.push(tempSessionObj);

                                console.log("list", tempSessionList)
                            }
                        })
                            .catch(e => {
                                console.log(e);
                            });

                    });
                    setContentSessionList(tempSessionList);
                    getAllActiveQuizTemplate();
                    //getAllWhiteboardTemplate();
                } else {
                    return
                }
            })
                .catch(e => {
                    console.log(e);
                });
    }

    //#endregion

    //#region CONTENT SESSION LIST - ADD, DELETE OPTIONS(CONTETNT, QUIZ, GAME)

    const getAllWhiteboardTemplate = () => {
        // let templates = JSON.parse(localStorage.getItem('whiteboard-templates'));
        // if(templates && templates.length > 0){
        //   setWhiteboardTemplateList(templates);
        // }

        AdminService.getAllActiveWhiteboardTemplates().then(response => {
            console.log(response.data);
            setWhiteboardTemplateList(response.data);
        })
            .catch(e => {
                console.log(e);
            });
    }

    const getAllActiveQuizTemplate = () => {
        AdminService.getAllActiveQuizTemplate().then(response => {
            console.log(response.data);
            setQuizTemplateList(response.data);
        })
            .catch(e => {
                console.log(e);
            });
    }

    /** ADD OPTIONS(CONTETNT, QUIZ, GAME)  */
    const chooseCurriculamType = (type, index, e) => {
        if (type == conditionalConfig.curriculam_types.content) {
            let tempSessionList = contentSessionList;
            let contentObj = {
                subject: "",
                curriculumlink: "",
                contenttype: "",
                curriculumtype: type,
                sequence: "1",
                items: [],
            };
            tempSessionList[index].curriculumdetail.push(contentObj);
            setContentSessionList([...contentSessionList], tempSessionList);
        } else if (type == conditionalConfig.curriculam_types.quiz) {
            setCurrentContentSessionTemplateQuizPopupIndex(index);
            handleOpenQuizInContentSession();
        } else if (type == conditionalConfig.curriculam_types.whiteboard) {
            setCurrentContentSessionTemplateQuizPopupIndex(index);
            handleOpenWhiteboardInContentSession();
        } else if (type == conditionalConfig.curriculam_types.games) {
            setCurrentContentSessionTemplateQuizPopupIndex(index);
            handleOpenGameInContentSession();
        }
    }

    /** REMOVE OPTIONS(CONTETNT, QUIZ, GAME)  */
    const removeCurriculam = (mainIndex, delIndex, type) => {
        let data = [...contentSessionList];
        if (type == conditionalConfig.curriculam_types.content) {
            data[mainIndex].curriculumdetail.splice(delIndex, 1);
            setContentSessionList(data)
        } else if (type == conditionalConfig.curriculam_types.quiz) {
            data[mainIndex].curriculumdetail.splice(delIndex, 1);
            setContentSessionList(data)
        }
    }
    //#endregion

    //#region EVENTS: CONTENT DETAILS: TYPE: QUIZ
    /** OPEN TEMPLATE QUIZ POPUP */
    const handleOpenQuizInContentSession = () => {
        setOpenAddQuizInContentSession(true)
    };
    /** CLOSE TEMPLATE QUIZ POPUP */
    const handleCloseQuizInContentSession = () => setOpenAddQuizInContentSession(false);

    /** ADD TEMPLATE QUIZ IN CURRICULAM DETAILS */
    const handleSelectQuizTemplateInContentSession = (quizTemplateId, quizTemplateName, questions, e) => {
        console.log(questions);
        let questionItems = [];
        questions.forEach(element => {
            let questionItemObj = {
                templateid: element.quiztemplateid,
                question: element.question,
                questiontype: element.questiontype,
                sequence: element.sequence.toString(),
                answerlist: element.answers
            }
            questionItems.push(questionItemObj);
        });
        let tempSessionList = contentSessionList;
        let contentObj = {
            subject: quizTemplateName,
            curriculumtype: conditionalConfig.curriculam_types.quiz,
            templateid: quizTemplateId,
            templatename: quizTemplateName,
            items: questionItems,
            sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
        };
        tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
        handleCloseQuizInContentSession();
    }
    //#endregion

    //#region EVENTS: CONTENT DETAILS: TYPE: CONTENT

    /** CONTENT FORMS */
    //LESSON TEXT FIELD
    const handleContentFormChange = (curIndex, contentIndex, e, field) => {
        let tempSessionList = contentSessionList;
        if (field == 'subject') {
            tempSessionList[curIndex].curriculumdetail[contentIndex].subject = e.target.value;
            setContentSessionList([...contentSessionList], tempSessionList);
        } else if (field == 'curriculumlink') {
            tempSessionList[curIndex].curriculumdetail[contentIndex].curriculumlink = e.target.value;
            setContentSessionList([...contentSessionList], tempSessionList);
        }
    }

    //SELECTED FILE TYPE
    const selectedFileType = (e, index, fType) => {
        e.stopPropagation();
        console.log(e);
        console.log(index);
        console.log(fType);
        setAcceptContentFileType(fType);
    }
    //CONTENT URL/EMBED CODE
    const handleContentType = (curIndex, contentIndex, e, contentType) => {
        let tempSessionList = contentSessionList;
        if (contentType == conditionalConfig.contentTypes.pasteUrl) {
            tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].contenttype = contentType;
            setContentSessionList([...contentSessionList], tempSessionList);
        } else if (contentType == conditionalConfig.contentTypes.file) {
            handleCloseContentFileTypes();
            let files = e.target.files;
            if (files && files.length > 0) {
                var file = files[0];
                //setcourseCoverPic(file.path);
                console.log(file);
                DocumentService.upload_document(file).then(response => {
                    console.log('File Uploaded Successfully');
                    console.log(response);
                    //tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].items = [];
                    let documentObj = {
                        documentname: response.data.originalname,
                        documentid: response.data.id,
                        documenttype: response.data.mimetype
                    }
                    tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].contenttype = contentType;
                    tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].items.push(documentObj);
                    setContentSessionList([...contentSessionList], tempSessionList);
                })
                    .catch(e => {
                        console.log(e);
                    });
                console.log(files)
            }
        }
    }

    const handleClickContentFileTypes = (event, currentIndex, curDetIndex) => {
        setCurrentContentIndex(currentIndex); // Main Content Index
        setCurrentContentDetailIndex(curDetIndex); // Content Detail Index
        setAnchorElContentFileTypes(event.currentTarget);
    };

    const handleCloseContentFileTypes = () => {
        setAnchorElContentFileTypes(null);
    };

    const downloadDocument = (e, docObj) => {
        e.stopPropagation();
        console.log(docObj);
        if (docObj) {
            window.open(docObj.documenturl, "_blank")
        }
    }
    //#endregion

    //#region EVENTS: CONTENT DETAILS: TYPE: WHITEBOARD
    /** OPEN TEMPLATE WHITEBOARD POPUP */
    const handleOpenWhiteboardInContentSession = () => {
        setOpenAddWhiteboardInContentSession(true)
    };
    /** CLOSE TEMPLATE WHITEBOARD POPUP */
    const handleCloseWhiteboardInContentSession = () => setOpenAddWhiteboardInContentSession(false);


    /** ADD TEMPLATE WHITEBOARD IN CURRICULAM DETAILS */
    const handleSelectedBoardsClick = (e) => {
        let finalSelectedBoards = [];
        let tempWhiteboardItems = whiteboardItems;
        tempWhiteboardItems = getUnique(tempWhiteboardItems, 'templateid');
        if (tempWhiteboardItems && tempWhiteboardItems.length > 0) {
            let questionItemObj = {
                templateid: null,
                template_ids: "",
                is_locked: true
            }
            for (let i = 0; i < tempWhiteboardItems.length; i++) {
                if (tempWhiteboardItems[i].templateid) {
                    if (questionItemObj.template_ids) {
                        questionItemObj.template_ids = questionItemObj.template_ids + ',' + tempWhiteboardItems[i].templateid.toString();
                    } else {
                        questionItemObj.template_ids = tempWhiteboardItems[i].templateid.toString();
                    }
                }
            }
            finalSelectedBoards.push(questionItemObj);
            let tempSessionList = contentSessionList;
            let contentObj = {
                subject: '',
                curriculumtype: conditionalConfig.curriculam_types.whiteboard,
                templateid: null,
                templatename: '',
                items: finalSelectedBoards,
                sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
            };
            tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
            handleCloseWhiteboardInContentSession();
        }
    }

    const handleSelectWhiteboardTemplateInContentSession = (id, name, slug, e) => {
        let tempWhiteboardItems = whiteboardItems;
        if (e && e.target.checked == true) {
            let questionItemObj = {
                templateid: id,
                is_locked: true
            }
            tempWhiteboardItems.push(questionItemObj);
        } else {
            tempWhiteboardItems = tempWhiteboardItems.filter(e => e.templateid != id);
        }
        setWhiteboardItems(tempWhiteboardItems);
    }

    const openWhiteboard = (obj) => {
        let nLink = url_configs.frontend + 'whiteboard-detail/' + obj.id;
        window.open(nLink, '_blank')
    }

    const handleToggleLockWhiteboard = (brdObj, curIdx, curDetIndex, brdIndex, toggleValue, e) => {
        e.stopPropagation();
        let tempSessionList = contentSessionList;
        tempSessionList[curIdx].curriculumdetail[curDetIndex].items[brdIndex].is_locked = toggleValue;
        console.log("handleToggleLockWhiteboard", tempSessionList);
    }

    const showBoardsList = (bdata) => {
        let boardsList = [];
        if (bdata && bdata.template_ids) {
            let splitBoardIds = bdata.template_ids.split(',');
            if (splitBoardIds && splitBoardIds.length > 0) {
                for (let i = 0; i < splitBoardIds.length; i++) {
                    let boardObj = {};
                    let actualBoardList = whiteboardTemplateList;
                    if (actualBoardList && actualBoardList.length > 0) {
                        let newWhiteboardArray = [];
                        //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
                        actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
                            newWhiteboardArray[index] = { ...device }
                        })
                        boardObj = newWhiteboardArray[0];
                        boardObj.is_locked = bdata.is_locked;
                        boardsList.push(boardObj);
                    }
                }
            }
        }
        console.log('SHOW BOARD LIST ', boardsList)
        return boardsList;
    }
    // const handleSelectWhiteboardTemplateInContentSession = (id, name, slug, e) => {
    //     let whiteboardItems = [];
    //     let questionItemObj = {
    //         templateid: id,
    //         is_locked: true
    //     }
    //     whiteboardItems.push(questionItemObj);

    //     let tempSessionList = contentSessionList;
    //     let contentObj = {
    //         subject: name,
    //         curriculumtype: conditionalConfig.curriculam_types.whiteboard,
    //         templateid: id,
    //         templatename: name,
    //         items: whiteboardItems,
    //         sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
    //     };
    //     tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
    //     handleCloseWhiteboardInContentSession();
    // }
    //#endregion

    //#region EVENTS: CONTENT DETAILS: TYPE: GAME
    /** OPEN TEMPLATE GAME POPUP */
    const handleOpenGameInContentSession = () => {
        setOpenAddGameInContentSession(true)
    };
    /** CLOSE TEMPLATE GAME POPUP */
    const handleCloseGameInContentSession = () => setOpenAddGameInContentSession(false);


    /** ADD TEMPLATE GAME IN CURRICULAM DETAILS */
    const handleSelectGameTemplateInContentSession = (id, name, slug, e) => {
        let whiteboardItems = [];
        let questionItemObj = {
            puzzleid: id,
            documentid: null,
            name: name,
            description: name
        }
        whiteboardItems.push(questionItemObj);

        let tempSessionList = contentSessionList;
        let contentObj = {
            subject: name,
            curriculumtype: conditionalConfig.curriculam_types.games,
            templateid: id,
            templatename: name,
            items: whiteboardItems,
            sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
        };
        tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
        handleCloseGameInContentSession();
    }
    //#endregion

    const handleNext = (data) => {
        console.log(data);
        console.log(contentSessionList);
        updateContentSession(contentSessionList);
    }

    const updateContentSession = (data) => {
        let contentSessionModle = {
            courseid: courseid.toString(),
            curriculum: data
        }
        console.log(contentSessionModle);
        CourseService.updateContent(courseid.toString(), contentSessionModle)
            .then(response => {
                console.log(response);
                if (response && response.data && response.success === 'true') {
                    enqueueSnackbar('Content session created successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                } else if (response && response.data && response.success === 'false') {
                    enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                } else {
                    enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
            .catch(error => {
                handleCloseContentFileTypes();
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            });
    }

    const tabNavigation = (screen) => {
        navigate(screen + '/' + courseid);
    }

    return (
        <Page title="Course Content">
            <Container>
                <Typography>
                    <Stack direction="row" spacing={1} mt={3}>
                        <Button
                            id="tab-button"
                            variant="contained"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_info)}
                        >
                            Course Info
                        </Button>
                        <Button
                            id="tab-button"
                            variant="contained"
                            className="tab-btn-active"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_content)}
                        >
                            Course Content
                        </Button>
                        <Button
                            id="tab-button"
                            variant="contained"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_pod)}
                        >
                            Pods
                        </Button>
                    </Stack>
                </Typography>

                <form onSubmit={handleSubmit(handleNext)}>
                    <Typography variant="h6" margin="dense">
                        Course Content
                    </Typography>
                    <div>
                        {contentSessionList.map((curObj, curindex) => {
                            return (
                                <div key={curindex} id={'curriculam_' + curindex} className="row main_curriculam">
                                    {/* ADD CURRICULAM SELECT OPTIONS - CONTENT, QUIZ OR GAMES */}
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 me-3">{curObj.name}</h5>
                                        <Stack direction="row" spacing={1}>
                                            {curriculamTypes.types.map((curTypes, curTypeIndex) => {
                                                return (
                                                    <Button
                                                        className="add_curriculum_btn"
                                                        id={'add_curriculam_' + curindex}
                                                        variant="outlined"
                                                        startIcon={<AddCircleIcon />}
                                                        onClick={(e) => chooseCurriculamType(curTypes.type, curindex, e)}
                                                    >
                                                        {curTypes.name}
                                                    </Button>
                                                )
                                            })}
                                        </Stack>
                                    </div>

                                    {/* CURRICULAM DETAILS - CONTENT/QUIZ */}
                                    {curObj.curriculumdetail.map((curDetObj, curDetIndex) => {
                                        return (
                                            <div>
                                                {
                                                    // CONTENT DESIGN
                                                    curDetObj.curriculumtype == conditionalConfig.curriculam_types.content ?
                                                        <Accordion key={'curriculam_content_' + curindex + '_' + curDetIndex} id={'curriculam_content_id_' + curindex + '_' + curDetIndex} elevation={12} className="pre_assessment_list_accordion mt-2">
                                                            {/* CONTENT TITLE SECTION */}
                                                            <AccordionSummary
                                                                aria-controls="panel1a-content"
                                                                variant="outlined"
                                                            >
                                                                <div className="align-items-center justify-content-between d-flex w-100">
                                                                    <Typography>
                                                                        <Stack className="align-items-center" direction="row" spacing={1}>
                                                                            <IconButton aria-label="library" color="primary">
                                                                                <DragIndicatorIcon color="action" />
                                                                            </IconButton>
                                                                            <span>{curDetObj?.subject ? curDetObj?.subject : 'Content'}</span>
                                                                        </Stack>
                                                                    </Typography>
                                                                    <Typography>
                                                                        <Stack direction="row" spacing={1}>
                                                                            {/* <IconButton aria-label="library" color="primary">
                                                                                <LibraryAddIcon />
                                                                            </IconButton> */}
                                                                            <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                            {/* CONTENT SECTION FORM */}
                                                            <AccordionDetails>
                                                                <Card
                                                                    elevation={12}
                                                                    className="intro_robot_card_lesson"
                                                                >
                                                                    <Container spacing={5}>
                                                                        {/* LESSON NAME TEXTFIELD */}
                                                                        <Grid item xs={12} sm={12}>
                                                                            <TextField
                                                                                id={'subject_id_' + curindex + '_' + curDetIndex}
                                                                                name={'subject_' + curindex + '_' + curDetIndex}
                                                                                label="Lesson Name"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                className="lesson_name mt-1"
                                                                                value={curDetObj.subject}
                                                                                onChange={(e) => handleContentFormChange(curindex, curDetIndex, e, 'subject')}
                                                                            />
                                                                        </Grid>

                                                                        {/* CONTENT URL FIELD */}
                                                                        {curDetObj.contenttype == conditionalConfig.contentTypes.pasteUrl ? <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                                                            <TextField
                                                                                id={'curriculumlink_id' + curindex + '_' + curDetIndex}
                                                                                name={'curriculumlink_' + curindex + '_' + curDetIndex}
                                                                                label='Content URL/Embed Code'
                                                                                variant="outlined"
                                                                                multiline
                                                                                fullWidth
                                                                                rows={4}
                                                                                value={curDetObj.curriculumlink}
                                                                                onChange={(e) => handleContentFormChange(curindex, curDetIndex, e, 'curriculumlink')}
                                                                            />
                                                                        </Grid> : ""}

                                                                        {/* DISPLAY UPLOADED CONTENT FILE */}
                                                                        {curDetObj.contenttype == conditionalConfig.contentTypes.file ? <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                                                            {curDetObj?.items.map((curItemObj, curItemIndex) => {
                                                                                return <div className="align-items-center justify-content-between d-flex w-100">
                                                                                    <Typography>
                                                                                        <Stack direction="row" spacing={1} className="align-items-center link_text pt-2" onClick={(e) => downloadDocument(e, curItemObj)} >
                                                                                            {curItemObj.documenttype == mimeTypes.pdf ?
                                                                                                <img src={fileTypesConfig.pdf} width="30px" /> :
                                                                                                curItemObj.documenttype == mimeTypes.doc ?
                                                                                                    <img src={fileTypesConfig.doc} width="30px" /> :
                                                                                                    curItemObj.documenttype == mimeTypes.ppt ?
                                                                                                        <img src={fileTypesConfig.ppt} width="30px" /> :
                                                                                                        <img src={fileTypesConfig.default} width="30px" />}
                                                                                            <span>{curItemObj.documentname}</span>

                                                                                        </Stack>
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        <Stack direction="row" spacing={1}>
                                                                                            <IconButton aria-label="delete" >
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </Stack>
                                                                                    </Typography>
                                                                                </div>
                                                                            })}
                                                                        </Grid> : ""}

                                                                        <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                                                            <span className="upload_course">
                                                                                Upload Course Material
                                                                            </span>
                                                                        </Grid>
                                                                        <Grid container item xs={12} sm={12} spacing={1}>
                                                                            {/* UPLOAD FILE OPTION */}
                                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                <Box
                                                                                    sx={{
                                                                                        p: 2,
                                                                                        border: "1px dashed grey",
                                                                                        height: 150,
                                                                                    }}
                                                                                >
                                                                                    <Grid container>
                                                                                        <Grid item xs={3}>
                                                                                            <CloudUploadIcon
                                                                                                color="action"
                                                                                                sx={{ fontSize: 40 }}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={9}>
                                                                                            <Typography className="upload_file">
                                                                                                Upload files from computer or drag
                                                                                                drop files here
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid container justifyContent="center">
                                                                                        <div>
                                                                                            <Button
                                                                                                className="select_file_btn"
                                                                                                id="content_file_types_select_file_btn"
                                                                                                aria-controls={
                                                                                                    openContentFileTypes
                                                                                                        ? "content_file_types_menu"
                                                                                                        : undefined
                                                                                                }
                                                                                                aria-haspopup="true"
                                                                                                aria-expanded={
                                                                                                    openContentFileTypes ? "true" : undefined
                                                                                                }
                                                                                                onClick={(e) => handleClickContentFileTypes(e, curindex, curDetIndex)}
                                                                                                variant="outlined"
                                                                                                endIcon={<KeyboardArrowDownIcon />}
                                                                                            >
                                                                                                select file
                                                                                            </Button>
                                                                                            <Menu
                                                                                                id="content_file_types_menu"
                                                                                                MenuListProps={{
                                                                                                    "aria-labelledby":
                                                                                                        "content_file_types_select_file_btn",
                                                                                                }}
                                                                                                anchorEl={anchorElContentFileTypes}
                                                                                                open={openContentFileTypes}
                                                                                                onClose={handleCloseContentFileTypes}
                                                                                                TransitionComponent={Fade}
                                                                                            >
                                                                                                {curriculam_content_file_types.types.map((fileTypes, curTypeIndex) => {
                                                                                                    return (
                                                                                                        <MenuItem key={curTypeIndex} disableRipple onMouseUp={(e) => selectedFileType(e, curTypeIndex, fileTypes.accept)}>
                                                                                                            <div className="content-file-upload">
                                                                                                                <label for="content-file-input">
                                                                                                                    {fileTypes.name}
                                                                                                                </label>
                                                                                                                <input id="content-file-input" accept={acceptContentFileType} type="file" onChange={(e) => handleContentType(curindex, curDetIndex, e, conditionalConfig.contentTypes.file)} />
                                                                                                            </div>
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                            </Menu>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                            {/* PAST URL OPTION */}
                                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                <Box
                                                                                    sx={{
                                                                                        p: 2,
                                                                                        border: "1px dashed grey",
                                                                                        height: 150,
                                                                                    }}
                                                                                >
                                                                                    <Grid container>
                                                                                        <Grid item xs={3}>
                                                                                            <AttachFileOutlinedIcon
                                                                                                color="action"
                                                                                                sx={{ fontSize: 40 }}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={9}>
                                                                                            <Typography className="paste_link">
                                                                                                Paste a link or add a HTML embed code
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid container justifyContent="center">
                                                                                        <Button
                                                                                            className="paste_btn"
                                                                                            variant="outlined"
                                                                                            onClick={(e) => handleContentType(curindex, curDetIndex, e, conditionalConfig.contentTypes.pasteUrl)}
                                                                                        >
                                                                                            Paste URL/code
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Container>
                                                                </Card>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        :
                                                        // QUIZ DESIGN
                                                        curDetObj.curriculumtype == conditionalConfig.curriculam_types.quiz ?
                                                            <Accordion key={'curriculam_quiz_' + curindex + '_' + curDetIndex} id={'curriculam_quiz_id_' + curindex + '_' + curDetIndex} elevation={12} className="pre_assessment_list_accordion mt-2">
                                                                <AccordionSummary
                                                                    aria-controls="panel1a-content"
                                                                    variant="outlined"
                                                                >
                                                                    <div className="align-items-center justify-content-between d-flex w-100">

                                                                        <Typography>
                                                                            <Stack className="align-items-center" direction="row" spacing={1}>
                                                                                <IconButton aria-label="library" color="primary">
                                                                                    <DragIndicatorIcon color="action" />
                                                                                </IconButton>
                                                                                <span>{curDetObj?.templatename}</span>

                                                                                <span className="quiz-flag">QUIZ</span>
                                                                            </Stack>

                                                                        </Typography>
                                                                        <Typography>
                                                                            <Stack direction="row" spacing={1}>
                                                                                <IconButton aria-label="library" color="primary">
                                                                                    <LibraryAddIcon />
                                                                                </IconButton>
                                                                                <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </Typography>
                                                                    </div>
                                                                </AccordionSummary>
                                                                {curDetObj?.items.map((qObj, qindex) => {
                                                                    return (
                                                                        <Card elevation={12} className="pre_assessment_list_card mt-2">
                                                                            <Container spacing={5}>
                                                                                <Grid item xs={12} sm={12}>
                                                                                    <TextField
                                                                                        name={'question_input_' + qindex}
                                                                                        id={'question_input_id_' + qindex}
                                                                                        label="Question"
                                                                                        variant="outlined"
                                                                                        value={qObj?.question}
                                                                                        fullWidth
                                                                                        className="lesson_name mt-1"
                                                                                    />
                                                                                </Grid>
                                                                                {qObj?.answerlist.map((ansObj, aindex) => {
                                                                                    return (
                                                                                        <Grid key={qindex + aindex} item xs={12} sm={12} className="mt-2">
                                                                                            {qObj?.questiontype == questionTypes.multiChoice ?
                                                                                                //  MULTI CHOICE ANSWERS
                                                                                                <div>
                                                                                                    <div className="d-flex align-items-center" id={'ans_' + qindex + '_' + aindex}>
                                                                                                        <Radio style={{ marginRight: "5" }} />
                                                                                                        <TextField
                                                                                                            id={'multi_choice_ans_input_id_' + qindex + '_' + aindex}
                                                                                                            name={'multi_choice_ans_input_name_' + qindex + '_' + aindex}
                                                                                                            label={'Answer ' + (aindex + 1)}
                                                                                                            variant="outlined"
                                                                                                            fullWidth
                                                                                                            className="lesson_name mt-1"
                                                                                                            value={ansObj?.answer}
                                                                                                        />
                                                                                                        <Stack direction="row" spacing={1}>
                                                                                                            {ansObj?.correct != 'true' ? <IconButton aria-label="library" color="primary">
                                                                                                                <CheckIcon />
                                                                                                            </IconButton> : ""}
                                                                                                            <IconButton aria-label="delete">
                                                                                                                <CloseIcon />
                                                                                                            </IconButton>
                                                                                                        </Stack>
                                                                                                    </div>
                                                                                                    {ansObj?.correct == 'true' ? <div className="d-flex justify-content-end correct_answer_text">
                                                                                                        <span>Correct Answer</span>
                                                                                                    </div> : ""}
                                                                                                </div>
                                                                                                :
                                                                                                // SHORT ANSWER
                                                                                                <div className="d-flex align-items-center" id={'ans_' + qindex + '_' + aindex}>
                                                                                                    <TextField
                                                                                                        id={'short_ans_input_id_' + qindex + '_' + aindex}
                                                                                                        label="Short Answer"
                                                                                                        variant="outlined"
                                                                                                        multiline
                                                                                                        fullWidth
                                                                                                        rows={3}
                                                                                                        className="lesson_name mt-1"
                                                                                                        value={ansObj?.answer}
                                                                                                    />
                                                                                                    <CollectionsIcon
                                                                                                        style={{ marginLeft: "10", marginRight: "10" }}
                                                                                                    />
                                                                                                </div>}
                                                                                        </Grid>
                                                                                    )
                                                                                })}

                                                                                {qObj?.questiontype == questionTypes.multiChoice ? <Grid item xs={12} sm={12} className="mt-2" >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <Radio style={{ marginRight: "5" }} />
                                                                                        <span className="add_option_text">Add option </span>
                                                                                    </div>
                                                                                </Grid> : ""}
                                                                            </Container>
                                                                        </Card>
                                                                    )
                                                                }
                                                                )}
                                                            </Accordion>
                                                            :
                                                            curDetObj.curriculumtype == conditionalConfig.curriculam_types.whiteboard ?
                                                                // WHITEBOARD DESIGN
                                                                <Accordion key={'curriculam_whiteboard_' + curindex + '_' + curDetIndex} id={'curriculam_whiteboard_id_' + curindex + '_' + curDetIndex} elevation={12} className="pre_assessment_list_accordion mt-2">
                                                                    <AccordionSummary
                                                                        aria-controls="panel1a-content"
                                                                        variant="outlined"
                                                                    >
                                                                        <div className="align-items-center justify-content-between d-flex w-100">
                                                                            <Typography>
                                                                                <IconButton aria-label="library" color="primary">
                                                                                    <DragIndicatorIcon color="action" />
                                                                                </IconButton>
                                                                                {/* <span>{curDetObj.templatename}</span> */}
                                                                                <span>{curDetObj?.subject ? curDetObj?.subject : ''}</span>
                                                                                <span className="whiteboard-flag">WHITEBOARD</span>
                                                                            </Typography>
                                                                            <Typography>
                                                                                <Stack direction="row" spacing={1}>
                                                                                    {/* <IconButton aria-label="library" color="primary">
                                                                                        {curDetObj?.is_locked ?
                                                                                            <LockResetOutlined /> :
                                                                                            <LockOutlined />
                                                                                        }
                                                                                    </IconButton> */}
                                                                                    <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Typography>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Card
                                                                            elevation={12}
                                                                            className="intro_robot_card_lesson"
                                                                        >
                                                                            <Container spacing={5}>
                                                                                {/* GROUP NAME TEXTFIELD */}
                                                                                <Grid item xs={12} sm={12}>
                                                                                    <TextField
                                                                                        id={'subject_id_' + curindex + '_' + curDetIndex}
                                                                                        name={'subject_' + curindex + '_' + curDetIndex}
                                                                                        label="Group Name"
                                                                                        variant="outlined"
                                                                                        fullWidth
                                                                                        className="lesson_name mt-1"
                                                                                        value={curDetObj.subject}
                                                                                        onChange={(e) => handleContentFormChange(curindex, curDetIndex, e, 'subject')}
                                                                                    />
                                                                                </Grid>
                                                                                {curDetObj?.items.map((curItemObj, curItemIndex) => {
                                                                                    return <div className="w-100">
                                                                                        {showBoardsList(curItemObj).map((curItemWObj, curItemWIndex) => {
                                                                                            return <div className="align-items-center justify-content-between d-flex w-100">
                                                                                                <Typography onClick={(e) => openWhiteboard(curItemWObj)}>
                                                                                                    <Stack direction="row" spacing={1} className="align-items-center link_text pt-2" >
                                                                                                        <img className="pe-2" src={Whiteboard_Icon} width="30px" />
                                                                                                        {curItemWObj.name}
                                                                                                    </Stack>
                                                                                                </Typography>
                                                                                                <Typography>
                                                                                                    <Stack direction="row" spacing={1}>
                                                                                                        {/* <IconButton aria-label="library" color="primary">
                                                                                                            {curItemWObj?.is_locked ?
                                                                                                                <LockOutlined  onClick={(e) => handleToggleLockWhiteboard(curItemWObj, curindex, curDetIndex, curItemWIndex,false, e)} /> :
                                                                                                                <LockOpenOutlinedIcon  onClick={(e) => handleToggleLockWhiteboard(curItemWObj, curindex, curDetIndex, curItemWIndex,true, e)} />
                                                                                                            }
                                                                                                        </IconButton> */}
                                                                                                        {/* <IconButton aria-label="delete" >
                                                                                                            <DeleteIcon />
                                                                                                        </IconButton> */}
                                                                                                    </Stack>
                                                                                                </Typography>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                })}
                                                                            </Container>
                                                                        </Card>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                :
                                                                // GAMES DESIGN
                                                                <Accordion key={'curriculam_game_' + curindex + '_' + curDetIndex} id={'curriculam_game_id_' + curindex + '_' + curDetIndex} elevation={12} className="pre_assessment_list_accordion mt-2">
                                                                    <AccordionSummary
                                                                        aria-controls="panel1a-content"
                                                                        variant="outlined"
                                                                    >
                                                                        <div className="align-items-center justify-content-between d-flex w-100">
                                                                            <Typography>
                                                                                <IconButton aria-label="library" color="primary">
                                                                                    <DragIndicatorIcon color="action" />
                                                                                </IconButton>
                                                                                <span>{curDetObj.templatename}</span>
                                                                                <span className="game-flag">GAME</span>
                                                                            </Typography>
                                                                            <Typography>
                                                                                <Stack direction="row" spacing={1}>
                                                                                    {/* <IconButton aria-label="library" color="primary">
                                                                                        <LibraryAddIcon />
                                                                                    </IconButton> */}
                                                                                    <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Typography>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                </Accordion>
                                                }
                                            </div>
                                        )
                                    })}

                                    {/* SELECT QUIZ TEMPLATE POPUP */}
                                    <Dialog id={'content_quiz_template_popup_' + curindex} open={openAddQuizInContentSession} onClose={handleCloseQuizInContentSession}>
                                        <DialogTitle>
                                            <div className="d-flex justify-content-center;">
                                                <Typography
                                                    color="primary"
                                                    style={{ marginRight: 4 }}
                                                    className="select_quiz_text"
                                                >
                                                    Select Quiz
                                                </Typography>

                                                <Typography
                                                    color="action"
                                                    style={{ marginLeft: "auto" }}
                                                    onClick={handleCloseQuizInContentSession}
                                                    className="select_quiz_close_btn"
                                                >
                                                    <CloseIcon />
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                id="outlined-basic"
                                                label="Search"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Search by Quiz Name"
                                            />
                                            {quizTemplateList.map((quizTemplateObj, quizTemplateIndex) => {
                                                return (
                                                    <Card
                                                        key={'content_quiz_template_card_key_' + curindex + '_' + quizTemplateIndex}
                                                        id={'content_quiz_template_card_id_' + curindex + '_' + quizTemplateIndex}
                                                        sx={{ marginTop: 1 }}
                                                        className="select_quiz_card"
                                                        onClick={(e) => handleSelectQuizTemplateInContentSession(quizTemplateObj.templateid, quizTemplateObj.templatename, quizTemplateObj.questions, e)}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={3}>
                                                                <Paper
                                                                    variant="outlined"
                                                                    className="d-flex justify-content-center card_img_background"
                                                                >
                                                                    <LightbulbIcon sx={{ fontSize: 90 }} />
                                                                </Paper>
                                                            </Grid>

                                                            <Grid item sm={9}>
                                                                <div>
                                                                    <span className="assessment_questions_text">
                                                                        {quizTemplateObj.templatename}
                                                                    </span>
                                                                    <p className="questions_text">{quizTemplateObj?.questions?.length} Questions</p>
                                                                    <div className="d-flex  align-items-center;">
                                                                        <ControlPointIcon style={{ marginRight: 7 }} />
                                                                        <div>
                                                                            <span className="courses_linked_text">
                                                                                0 Courses Linked
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                )
                                            })}
                                        </DialogContent>
                                        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
                                    </Dialog>

                                    {/* SELECT WHITEBOARD TEMPLATE POPUP */}
                                    <Dialog id={'content_whiteboard_template_popup_' + curindex} open={openAddWhiteboardInContentSession} onClose={handleCloseWhiteboardInContentSession}>
                                        <DialogTitle>
                                            <div className="d-flex justify-content-center;">
                                                <Typography
                                                    color="primary"
                                                    style={{ marginRight: 4 }}
                                                    className="select_quiz_text"
                                                >
                                                    Select Whiteboard
                                                </Typography>

                                                <Typography
                                                    color="action"
                                                    style={{ marginLeft: "auto" }}
                                                    onClick={handleCloseWhiteboardInContentSession}
                                                    className="select_quiz_close_btn"
                                                >
                                                    <CloseIcon />
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                id="outlined-basic"
                                                label="Search"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Search by Quiz Name"
                                            />
                                            {whiteboardTemplateList.map((wBoardTemplateObj, wBoardTemplateIndex) => {
                                                return (
                                                    <div
                                                        key={'content_whiteboard_template_key_' + curindex + '_' + wBoardTemplateIndex}
                                                        id={'content_whiteboard_template_id_' + curindex + '_' + wBoardTemplateIndex}
                                                        className="d-flex align-items-center">
                                                        <Checkbox id={'content_whiteboard_template_checkbox_id_' + curindex + '_' + wBoardTemplateIndex}
                                                            onChange={(e) => handleSelectWhiteboardTemplateInContentSession(wBoardTemplateObj.id, wBoardTemplateObj.name, wBoardTemplateObj.slug, e)} />
                                                        <Card
                                                            id={'content_whiteboard_template_card_id_' + curindex + '_' + wBoardTemplateIndex}
                                                            sx={{ marginTop: 1 }}
                                                            className="select_quiz_card"
                                                        // onClick={(e) => handleSelectWhiteboardTemplateInContentSession(wBoardTemplateObj.id, wBoardTemplateObj.name, wBoardTemplateObj.slug, e)}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item sm={3}>
                                                                    <Paper
                                                                        variant="outlined"
                                                                        className="d-flex justify-content-center card_img_background"
                                                                    >
                                                                        <img src={Sampleimg} />
                                                                        {/* <LightbulbIcon sx={{ fontSize: 90 }} /> */}
                                                                    </Paper>
                                                                </Grid>

                                                                <Grid item sm={9}>
                                                                    <div>
                                                                        <span className="assessment_questions_text">
                                                                            {wBoardTemplateObj.name}
                                                                        </span>
                                                                        <div className="d-flex  align-items-center;">
                                                                            <ControlPointIcon style={{ marginRight: 7 }} />
                                                                            <div>
                                                                                <span className="courses_linked_text">
                                                                                    0 Courses Linked
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </div>

                                                )
                                            })}
                                        </DialogContent>
                                        <DialogActions className="dialog_action">
                                            <Button variant="contained" className="add_new_quiz_btn" onClick={(e) => handleSelectedBoardsClick(e)}>
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* SELECT GAME TEMPLATE POPUP */}
                                    <Dialog id={'content_game_template_popup_' + curindex} open={openAddGameInContentSession} onClose={handleCloseGameInContentSession}>
                                        <DialogTitle>
                                            <div className="d-flex justify-content-center;">
                                                <Typography
                                                    color="primary"
                                                    style={{ marginRight: 4 }}
                                                    className="select_game_text"
                                                >
                                                    Select Game
                                                </Typography>

                                                <Typography
                                                    color="action"
                                                    style={{ marginLeft: "auto" }}
                                                    onClick={handleCloseGameInContentSession}
                                                    className="select_game_close_btn"
                                                >
                                                    <CloseIcon />
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>

                                            {gameTemplateList.map((gameTemplateObj, gameTemplateIndex) => {
                                                return (
                                                    <Card
                                                        key={'content_game_template_card_key_' + curindex + '_' + gameTemplateIndex}
                                                        id={'content_game_template_card_id_' + curindex + '_' + gameTemplateIndex}
                                                        sx={{ marginTop: 1 }}
                                                        className="select_quiz_card"
                                                        onClick={(e) => handleSelectGameTemplateInContentSession(gameTemplateObj.id, gameTemplateObj.name, gameTemplateObj.routeName, e)}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={3}>
                                                                <Paper
                                                                    variant="outlined"
                                                                    className="d-flex justify-content-center card_img_background"
                                                                >
                                                                    <img src={gameTemplateObj.img} />
                                                                    {/* <LightbulbIcon sx={{ fontSize: 90 }} /> */}
                                                                </Paper>
                                                            </Grid>

                                                            <Grid item sm={9}>
                                                                <div>
                                                                    <span className="assessment_questions_text">
                                                                        {gameTemplateObj.name}
                                                                    </span>
                                                                    <div className="d-flex  align-items-center;">
                                                                        <ControlPointIcon style={{ marginRight: 7 }} />
                                                                        <div>
                                                                            <span className="courses_linked_text">
                                                                                0 Courses Linked
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                )
                                            })}
                                        </DialogContent>
                                        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
                                    </Dialog>
                                </div>
                            )
                        })}
                    </div>
                    <Button
                        id="submit-button"
                        className={classes.button}
                        variant="contained"
                        type="submit"
                    >Submit
                    </Button>
                </form>
            </Container>
        </Page>
    );
}