import route_config from "./route_config";

const navigate_route_config = {
  admin: {
    parents:
      "/" +
      route_config.admin.users.main_route_name +
      "/" +
      route_config.admin.users.parents,
    learners:
      "/" +
      route_config.admin.users.main_route_name +
      "/" +
      route_config.admin.users.learners,
    experts:
      "/" +
      route_config.admin.users.main_route_name +
      "/" +
      route_config.admin.users.experts,
    block_requests:
      "/" +
      route_config.admin.users.main_route_name +
      "/" +
      route_config.admin.users.block_requests,
    courses: "/" + route_config.admin.courses.main_route_name,
    create_course:
      "/" +
      route_config.admin.courses.main_route_name +
      "/" +
      route_config.admin.courses.create_course,
    games: "/" + route_config.admin.games,
    quiz: "/" + route_config.admin.quiz,
    whiteboard_templates: "/" + route_config.admin.whiteboard_templates,
    customers: "/" + route_config.admin.customers,
    pods: "/" + route_config.admin.pods,
    course_info:
      "/" +
      route_config.admin.courses.main_route_name +
      "/" +
      route_config.admin.courses.course_info,
    course_content:
      "/" +
      route_config.admin.courses.main_route_name +
      "/" +
      route_config.admin.courses.course_content,
    course_pod:
      "/" +
      route_config.admin.courses.main_route_name +
      "/" +
      route_config.admin.courses.course_pod,
    course_detail:
      "/" +
      route_config.admin.courses.main_route_name +
      "/" +
      route_config.admin.courses.course_detail,
    image_library: "/" + route_config.admin.imageLibrary,
    share_licenses_details: "/" + route_config.admin.share_licenses_details,
    sessions: "/" + route_config.admin.sessions,
  },
  parent: {
    my_kids:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.my_kids,
    all_courses:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.all_courses,
    my_courses:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.my_courses,
    self_learning:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.self_learning,
    self_learning_boards:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.self_learning_boards,
    payments:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.payments,
    home:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.home,
    share_licenses_details:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.share_licenses_details,

    courses_description:
      "/" +
      route_config.parent.main_route_name +
      "/" +
      route_config.parent.courses_description,
  },
  expert: {
    course_detail:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.course_detail,
    my_courses:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.my_courses,
    my_schedule:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.my_schedule,
    pods:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.pods,
    curriculum:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.curriculum,
    group_space:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.group_space,
    manage_groups:
      "/" +
      route_config.expert.main_route_name +
      "/" +
      route_config.expert.manage_groups,
  },
  learner: {
    course_detail:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.course_detail,
    my_courses:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.my_courses,
    self_learning:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.self_learning,
    my_schedule:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.my_schedule,
    group_space:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.group_space,
    pods:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.pods,

    missions:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.missions,
    challenges:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.challenges,

    courses_description:
      "/" +
      route_config.learner.main_route_name +
      "/" +
      route_config.learner.courses_description,
  },
  home: {
    privacyPolicy: "/" + route_config.home.privacyPolicy,
    termsAndConditions: "/" + route_config.home.termsAndConditions,
    userGuide: "/" + route_config.home.userGuide,
  },
};

export default navigate_route_config;
