import React from 'react';
import Mycourses from './my-courses';
import "./my-courses.css";
import meta_data from '../../../config/meta_data';

function App() {
    document.title = meta_data.company +'| My-courses';
    return (
        <React.Fragment>
            <Mycourses />
        </React.Fragment>
    );
}

export default App;
