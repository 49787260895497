import url_configs from "./url";

const firebaseConfig = {
    apiKey: url_configs.firebaseConfig.apiKey,
    authDomain: url_configs.firebaseConfig.authDomain,
    projectId: url_configs.firebaseConfig.projectId,
    storageBucket: url_configs.firebaseConfig.storageBucket,
    messagingSenderId: url_configs.firebaseConfig.messagingSenderId,
    appId: url_configs.firebaseConfig.appId
  };
export default firebaseConfig;