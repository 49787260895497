import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Typography,
  Stack,
  ListItemIcon
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import Iconify from "../../Iconify";

import { SnackbarProvider, useSnackbar } from 'notistack';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import "./header.css";
import right_icon_img from "../../../assets/img/header_img/right_arrow_img.png";
import Left_icon_img from "../../../assets/img/header_img/left_img.png";
import Hand_icon_img from "../../../assets/img/header_img/hand_icon_img.png";
import Video_icon_img from "../../../assets/img/header_img/video_icon_img.png";
import Video_icon_off_img from "../../../assets/img/header_img/video_muted.png";
import Voice_icon_img from "../../../assets/img/header_img/voice_icon_img.png";
import Voice_icon_off_img from "../../../assets/img/header_img/audio_muted.png";
import Share_img from "../../../assets/img/header_img/share_img.png";
import Logout_img from "../../../assets/img/header_img/logout_img.png";
import Sand_clock from "../../../assets/img/header_img/sand_clock_img.png";

import { setMainMenu, setIsFluid, setIsGalleryView } from "../../../store/slices/settingsSlice";
import {
  setCameraStatus,
  setShareScreenStatus,
  setParticipants
} from "../../../store/slices/zoomSlice";

//configs
import conditional_configs from "../../../config/conditional_config";
import storage_configs from "../../../config/storage-config";
import navigate_route_config from "../../../config/navigate_route_config";
import url_configs from "../../../config/url";
import meta_data from "../../../config/meta_data";
import assets from "../../../config/assets";
import icons_config from "../../../config/icons";
import zoomConfigs from "../../../config/zoom";

//UTILS
import { getCurrentUserData, getBreakoutSessionStatus, clearClassRoomSessionData, getUnique, getInitialsFromFullName } from '../../../utils/util';
import { setZoomAudio, setLeaveGroup, setAllowParticipants } from "../../../store/slices/zoomSlice";

//SERVICES
import SessionService from "../../../services/session-service";

import io from "socket.io-client";
import error_messages from "../../../config/error_messages";
import ZoomService from "../../../services/zoom-service";
import { getExploreName } from "../../../utils/platform";
import { getActiveCamera, getActiveMicrophone, getActiveSpeaker } from "../../../utils/zoomFunctions";


let sessionSocket;

let socket_conection;
let b_room_socket;

function Header(args) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const { enqueueSnackbar } = useSnackbar();

  //WAITING PARTICIPANTS
  const [waitingPartEl, setWaitingPartEl] = useState()
  const [waitingPartCtrlMenu, setWaitingPartCtrlMenu] = useState(false);
  const [waitingPartEnableNotify, setWaitingPartEnableNotify] = useState(false);
  const allowParticipants = useSelector((state) => state.zoom.allowParticipants);

  const [anchorEl, setAnchorEl] = useState()
  const [raisedHandCtrlMenu, setRaisedHandCtrlMenu] = useState(false);
  const [isRaisedHand, setIsRaisedHand] = useState(false);


  const [copySuccess, setCopySuccess] = useState('');
  let currentUserData = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const mainMenu = useSelector((state) => state.settings.mainMenu);
  const isGalleryView = useSelector((state) => state.settings.isGalleryView);
  const isFluid = useSelector((state) => state.settings.isFluid);
  const zoomClient = useSelector((state) => state.zoom.zoomClient);
  const zoomStatus = useSelector((state) => state.zoom.status);
  const zoomCameraStatus = useSelector((state) => state.zoom.cameraStatus);
  const zoomStream = useSelector((state) => state.zoom.stream);
  const stopBreakoutLoading = useSelector((state) => state.zoom.stopBreakoutLoading);
  const [isOpen, setIsOpen] = useState(false);
  //const [zoomAudioStatus, setAudio] = useState("mute");
  const zoomAudioStatus = useSelector((state) => state.zoom.zoomAudio);

  const breakoutRoomStatus = useSelector((state) => state.settings.breakoutRoomStatus);


  const zoomShareScreenStatus = useSelector((state) => state.zoom.shareScreenStatus);
  const selectedGroup = useSelector((state) => state.settings.selectedGroup);
  const currenGroupName = useSelector((state) => state.settings.currentGroupName);
  //const [isZoomScreenSharing, setIsZoomScreenSharing] = useState(false);

  //GET SLUG FROM LINK
  let { slug } = useParams();

  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  //Share Screen Menu
  const [shareScreenEl, setShareScreenEl] = useState();
  const [shareScreenOptionMenu, setShareScreenOptionMenu] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const [informationBoxOpen, setInformationBoxOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // const isAudioMuted = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.isAudioMuted));
    // if(!isAudioMuted){
    //   setAudio("unmute");
    // }

    b_room_socket = io(url_configs.breakout_room_server);
    b_room_socket.on("raise-your-hand", (raisedObj) => {
      console.log("raisedObj", raisedObj);
      let tempRaisedList = [];
      tempRaisedList.push(raisedObj.data);
      tempRaisedList = getUnique(tempRaisedList, 'id');
      setTimeout(() => {
        console.log("tempRaisedList", tempRaisedList);
        let raisedNotificationList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.raisedNotificationList));
        if (raisedNotificationList && raisedNotificationList.length > 0) {
          for (let i = 0; i < tempRaisedList.length; i++) {
            raisedNotificationList.push(tempRaisedList[i]);
          }
          raisedNotificationList = getUnique(raisedNotificationList, 'id');
          localStorage.setItem(storage_configs.localStorage.sessions.raisedNotificationList, JSON.stringify(raisedNotificationList));
          enqueueSnackbar('Raised hand!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setIsRaisedHand(true);
        } else {
          tempRaisedList = getUnique(tempRaisedList, 'id');
          localStorage.setItem(storage_configs.localStorage.sessions.raisedNotificationList, JSON.stringify(tempRaisedList));
          enqueueSnackbar('Raised hand!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setIsRaisedHand(true);
        }
      }, 2000);
    });

    b_room_socket.on("user-joined", (obj) => {
      console.log("user-joined", obj.data);
      //const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));
      if (getBreakoutSessionStatus()) {
        if (obj && obj.data && obj.data.slug == slug) {
          console.log("user-joined", obj.data);
          if (obj.data.zoomDetail) {
            var waitingParticipants = [];
            waitingParticipants = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.waitingParticipants));
            if (waitingParticipants && waitingParticipants.length > 0) {
              waitingParticipants = waitingParticipants.filter(e => e.displayName !== obj.data.zoomDetail.displayName);
              waitingParticipants.push(obj.data.zoomDetail);
              localStorage.setItem(storage_configs.localStorage.sessions.zoom.waitingParticipants, JSON.stringify(waitingParticipants));
            } else {
              var tempWaitingParticipants = [];
              tempWaitingParticipants.push(obj.data.zoomDetail);
              localStorage.setItem(storage_configs.localStorage.sessions.zoom.waitingParticipants, JSON.stringify(tempWaitingParticipants));
            }
            setWaitingPartEnableNotify(true);
          }
          var userName = obj.data.name.split(' ')[0];
          enqueueSnackbar(userName + ' has joined and waiting in main session!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    });

    // b_room_socket.on("break-out-status", (obj) => {
    //   if (obj && obj.success == true && obj.status == 'STARTED') {
    //     return;
    //   } else {

    //   }
    // });

  }, []);

  //#region Share Screen Menu
  const handleShareScreenMenuClose = () => {
    setShareScreenOptionMenu(false);
  };

  const handleShareScreenMenuOpen = (event) => {
    setShareScreenEl(event.currentTarget);
    setShareScreenOptionMenu(true);
  };

  const handleMenuItemClick = (e, option) => {
    e.stopPropagation();
    //var selfShareCanvas = document.querySelector("#course-view-video");
    if (option) {
      setActiveOption(option.id);
      dispatch(setShareScreenStatus(true));
      if (option.id == 0) {
        setTimeout(async () => {
          var selfShareCanvas = document.querySelector("#course-view-video");
          if (await zoomStream?.getShareStatus() === "ended" && selfShareCanvas) {
            await zoomStream?.startShareScreen(selfShareCanvas, { controls: { preferCurrentTab: true, surfaceSwitching: 'include', selfBrowserSurface: 'include', monitorTypeSurfaces: 'include' }, displaySurface: "window", requestReadReceipt: true });
            handleShareScreenMenuClose();
          } else {
            enqueueSnackbar('Screen sharing is already enabled', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        }, 1000);
      } else if (option.id == 1) {
        setTimeout(async () => {
          var selfShareCanvas = document.querySelector("#course-view-video");
          if (zoomStream?.getShareStatus() === "ended" && selfShareCanvas) {
            await zoomStream?.startShareScreen(selfShareCanvas);
            handleShareScreenMenuClose();
          } else {
            enqueueSnackbar('Screen sharing is already enabled', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        }, 1000);
      } else {
        return;
      }
      console.log("Clicked:", option);
    }
    return;
  };
  //#endregion

  //#region WAITING PARTICIPANTS
  const handleWaitingParticipantsControlMenuClick = (event) => {
    if (getWaitingParticipants().length > 0) {
      setWaitingPartEl(event.currentTarget);
      setWaitingPartCtrlMenu(true);
    }
  };

  const handleWaitingParticipantsControlMenuClose = () => {
    setWaitingPartCtrlMenu(false);
  };

  const handleAllowParticipants = (e, obj) => {
    e.stopPropagation();
    localStorage.setItem('allow-this-pariticipant', JSON.stringify(obj));
    dispatch(setAllowParticipants(true));
    handleWaitingParticipantsControlMenuClose();
  }

  const getAllowedParticipant = () => {
    var partData = JSON.parse(localStorage.getItem('allow-this-pariticipant'));
    return partData;
  }

  const getWaitingParticipants = () => {
    let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.waitingParticipants));
    if (tempList && tempList.length > 0) {
      return tempList;
    } else {
      return [];
    }
  }

  //#endregion

  //#region RAISE HAND
  const handleRaisedHandControlMenuClick = (event) => {
    if (getRaisedNotifications().length > 0) {
      setAnchorEl(event.currentTarget);
      setRaisedHandCtrlMenu(true);
      setIsRaisedHand(false);
    }
  };

  const handleRaisedHandControlMenuClose = () => {
    setRaisedHandCtrlMenu(false);
  };

  const getRaisedNotifications = () => {
    let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.raisedNotificationList));
    if (tempList && tempList.length > 0) {
      return tempList;
    } else {
      return [];
    }
  }

  const handleLowerHand = (e, obj) => {
    e.stopPropagation();
    let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.raisedNotificationList));
    if (tempList && tempList.length > 0) {
      tempList = tempList.filter(e => e.id != obj.id);
      localStorage.setItem(storage_configs.localStorage.sessions.raisedNotificationList, JSON.stringify(tempList));
      handleRaisedHandControlMenuClose();
    }
  }
  //#endregion

  const setMain = (menu) => {
    dispatch(setMainMenu(menu));
    if (menu === "breakout") {
      navigate("/expert-session/break-out-room");
    } else {
      navigate("/expert-session/classroom");
    }
  };

  const setFluid = () => {
    dispatch(setIsFluid(!isFluid));
    // zoomStream.stopVideo();
    // if (zoomCameraStatus && isFluid) {
    //   if (currentUserData.role == conditional_configs.role.expert) {
    //     setTimeout(() => {
    //       ZoomService.renderExpertSelfVideo(zoomStream, zoomClient);
    //     }, 2000);
    //   }
    // }
  };

  //#region EXPERT END SESSION
  const logout = () => {
    Swal.fire({
      title: error_messages.endSessionConfirmation,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire("Logged Out!", "success");
        // if (zoomStatus === "started") {
        //   zoomClient.leave(true);
        //   Swal.fire("Zoom Session ended!", "success");
        // } else {
        //   Swal.fire("Zoom session Already ended", "success");
        // }
        completeSession();
        localStorage.removeItem(storage_configs.localStorage.sessions.raisedNotificationList);
        // if(currentUserData.role == conditional_configs.role.expert){
        //   navigate("/expert/my-courses");
        // }else if(currentUserData.role == conditional_configs.role.learner){
        //   navigate("/learner/my-courses");
        // }else{
        //   return
        // }
      }
    });
  };

  const completeSession = () => {
    if (getCurrentUserData()) {
      if (getCurrentUserData().role == conditional_configs.role.expert) {
        sessionSocket = io(url_configs.breakout_room_server);
        sessionSocket.emit("end-session", { message: slug }, (error) => {
          if (error) {
            console.log(error)
          }
        });
        stopSessionByExpert();
        zoomClient.leave(true);
      } else {
        return
      }
    }
  }

  const stopSessionByExpert = () => {
    let id = localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid);
    if (id) {
      console.log("expert end id" + id)
      let model = {
        membercoursestreamdetailid: id.toString()
      }
      SessionService.stopSessionByExpert(model).then(response => {
        console.log("expert session stopped")
        console.log(response);

        clearClassRoomSessionData();
        navigate(navigate_route_config.expert.my_courses);
        socket_conection = io(url_configs.breakout_room_server);

        socket_conection.emit("check-session-started", { data: response, status: false }, (error) => {
          if (error) {
            console.log(error);
          }
        });
      })
        .catch(e => {
          navigate(navigate_route_config.expert.my_courses);
          console.log(e);
        });
    }
  }
  //#endregion

  //#region ZOOM VIDEO
  const swichOfVideo = () => {
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
    }, 3000);
    if (zoomStream && zoomStream.isCapturingVideo()) {
      //const session = zoomClient.getSessionInfo();
      ZoomService.stopExpertSelfVideo(zoomStream);
      //zoomStream.stopVideo();
      dispatch(setCameraStatus(false));
      //stopRenderVideo(zoomStream, zoomClient, true, session.userId);
    }
  };

  const swichOnVideo = () => {
    dispatch(setCameraStatus(true));

    ZoomService.renderExpertSelfVideo(zoomStream, zoomClient);

    //renderVideo(zoomStream, zoomClient, true);
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
      // zoomStream.startVideo({
      //   videoElement: document.querySelector("#e-self-view-video"),
      // });
    }, 3000);
  };

  const stopRenderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if ((!stream.isSupportMultipleVideos() && (typeof OffscreenCanvas === 'function')) || /android/i.test(navigator.userAgent)) {
        // if MediaStreamTrackProcessor is not supported
        if (!(typeof MediaStreamTrackProcessor === 'function')) {
          // render video on HTML Canvas Element
          setTimeout(() => {
            stream.stopRenderVideo(
              document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId),
              userId
            );
            document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId).style.display = 'none'
          }, 2000);

        } else {
          // show HTML Video Element in DOM
          stream.stopRenderVideo(
            document.querySelector(isHost ? "#e-self-view-video" : "#participants-video-" + userId),
            userId
          );
          document.querySelector(isHost ? "#e-self-view-video" : "#participants-video-" + userId).style.display = 'none'
        }
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // render video on HTML Canvas Element
        stream.stopRenderVideo(
          document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId),
          userId
        );
        document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId).style.display = 'none'
      }
    }
  }

  const renderVideo = (stream, client, isHost) => {
    if (!stream.isCameraTaken()) {
      const session = client.getSessionInfo();
      setTimeout(() => {
        stream.startVideo({ videoElement: document.querySelector(isHost ? '#e-self-view-video' : '#participants-video-' + session.userId), hd: stream.isSupportHDVideo() }).then(() => {
          // if MediaStreamTrackProcessor is not supported
          if (!(typeof MediaStreamTrackProcessor === 'function')) {
            // render video on HTML Canvas Element
            stream.renderVideo(document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + session.userId), session.userId, 320, 180, 0, 0, 2).then(() => {
              // show HTML Canvas Element in DOM
              document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + session.userId).style.display = 'block';
              document.querySelector(isHost ? "#e-self-view-video" : "#participants-video-" + session.userId).style.display = 'none';
            }).catch((error) => {
              console.log(error);
            })
          } else {
            // show HTML Video Element in DOM
            stream.renderVideo(document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + session.userId), session.userId, 320, 180, 0, 0, 2).then(() => {
              // show HTML Canvas Element in DOM
              document.querySelector(isHost ? "#e-self-view-canvas" : "#participants-canvas-" + session.userId).style.display = 'block';
              document.querySelector(isHost ? "#e-self-view-video" : "#participants-video-" + session.userId).style.display = 'none';
            }).catch((error) => {
              console.log(error);
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      }, 5000);
    }
  }

  // const renderVideo = (stream, client, isHost) => {
  //   if (!stream.isCameraTaken()) {
  //     const session = client.getSessionInfo();
  //     // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
  //     if ((!stream.isSupportMultipleVideos() && (typeof OffscreenCanvas === 'function')) || /android/i.test(navigator.userAgent)) {
  //       // start video - video will render automatically on HTML Video Element if MediaStreamTrackProcessor is supported
  //       stream.startVideo({ videoElement: document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId),hd:true }).then(() => {
  //         // if MediaStreamTrackProcessor is not supported
  //         if (!(typeof MediaStreamTrackProcessor === 'function')) {
  //           // render video on HTML Canvas Element
  //           stream.renderVideo(document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId), session.userId, 1280, 720, 0, 0, 3).then(() => {
  //             // show HTML Canvas Element in DOM
  //             document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId).style.display = 'block';
  //             document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId).style.display = 'none';
  //           }).catch((error) => {
  //             console.log(error)
  //           })
  //         } else {
  //           // show HTML Video Element in DOM

  //           document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId).style.display = 'block';
  //           document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId).style.display = 'none';
  //         }
  //       }).catch((error) => {
  //         console.log(error)
  //       })

  //       // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
  //     } else {
  //       stream.startVideo().then(() => {
  //         // render video on HTML Canvas Element
  //         stream.renderVideo(document.querySelector(isHost? '#e-self-view-canvas':"#participants-canvas-"+session.userId), session.userId, 1280, 720, 0, 0, 3).then(() => {
  //           // show HTML Canvas Element in DOM
  //           document.querySelector(isHost?'#e-self-view-canvas':"#participants-canvas-"+session.userId).style.display = 'block';
  //           document.querySelector(isHost?'#e-self-view-video':"#participants-video-"+session.userId).style.display = 'none'
  //         }).catch((error) => {
  //           console.log(error)
  //         })
  //       }).catch((error) => {
  //         console.log(error)
  //       })
  //     }
  //     // stream.startVideo({
  //     //   videoElement: document.querySelector("#e-self-view-video"),
  //     // });
  //   }
  // }
  //#endregion

  //#region ZOOM AUDIO
  const audioToggle = async (flag) => {
    if (flag === "mute") {
      ZoomService.muteAudio(zoomStream);
      //zoomStream.muteAudio();
      //setAudio("mute");
      dispatch(setZoomAudio("mute"));
    } else {
      const firstTimeInteracted = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.firstTimeInteracted));
      if (firstTimeInteracted == true) {
        // localStorage.setItem(storage_configs.localStorage.sessions.zoom.firstTimeInteracted, false);
        // await ZoomService.stopAudio(zoomStream);
        // await ZoomService.startAudio(zoomStream);
        // await ZoomService.unMuteAudio(zoomStream);
        // dispatch(setZoomAudio("unmute"));
        Swal.fire({
          title: error_messages.allowAudio,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Allow",
        }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.setItem(storage_configs.localStorage.sessions.zoom.firstTimeInteracted, false);
            await ZoomService.stopAudio(zoomStream);
            await ZoomService.startAudio(zoomStream);
            await ZoomService.unMuteAudio(zoomStream);
            dispatch(setZoomAudio("unmute"));
          }
        });

      } else {
        await ZoomService.unMuteAudio(zoomStream);
        dispatch(setZoomAudio("unmute"));
      }
      // ZoomService.unMuteAudio(zoomStream);
      //zoomStream.unmuteAudio();
      //setAudio("unmute");
    }
  };

  //#endregion

  //#region ZOOM SCREENSHARE
  const allowScreenShare = () => {
    dispatch(setShareScreenStatus(false));
    zoomStream.setSharePrivilege(3);
    //dispatch(setStream(stream));
  }

  const shareScreen = () => {
    dispatch(setShareScreenStatus(true));
    setTimeout(async () => {
      var selfShareCanvas = document.querySelector("#course-view-video");
      if (zoomStream?.getShareStatus() === "ended" && selfShareCanvas) {
        await zoomStream?.startShareScreen(selfShareCanvas);
        // await zoomStream?.startShareScreen(selfShareCanvas, { controls: {preferCurrentTab:true,surfaceSwitching:'include', selfBrowserSurface:'include', monitorTypeSurfaces:'include'}, displaySurface: "window", requestReadReceipt: true });
      }
    }, 1000);
  };

  const stopShareScreen = () => {
    dispatch(setShareScreenStatus(false));
    setTimeout(() => {
      zoomStream.stopShareScreen();
    }, 1000);
  };

  //#endregion

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
      alert('Link copied');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const shareLink = () => {
    let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

    let generateLink = url_configs.frontend + 'join-demo/' + current_stream_id + '/' + slug;
    copyToClipBoard(generateLink)
  }

  const openGalleryView = () => {
    dispatch(setIsGalleryView(!isGalleryView));
    if (!isGalleryView) {
      ZoomService.renderParticipantVideoInGallery(zoomStream, zoomClient, 0);
    }
  }

  const stopBreakoutRoom = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: error_messages.stopBreakoutSessionConfirmation,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        b_room_socket = io(url_configs.breakout_room_server);
        b_room_socket.emit("stop-breakout-session", { message: 'STOPPED' }, (error) => {
          if (error) {
            console.log(error)
          }
        });
      }
    });

  }

  const getGroupName = () => {
    const current_group_id = localStorage.getItem(storage_configs.localStorage.sessions.current_group_id);
    const currentGroups = JSON.parse(localStorage.getItem("current-groups"));
    if (current_group_id && currentGroups && currentGroups.length > 0) {
      const groupInfo = currentGroups.filter(e => e.coursegroupid == current_group_id)[0];
      if (groupInfo) {
        return groupInfo.groupname;
      }
    }
    return null;
  }

  const leaveCurrentGroup = (grp) => {
    dispatch(setLeaveGroup(true))
  }

  //#region INFORMATION BOX
  const handleClickOpenInformationBox = (e) => {
    setInformationBoxOpen(true);
  };

  const handleCloseInformationBox = () => {
    setInformationBoxOpen(false);
  };
  //#endregion

  return (
    <div>
      {breakoutRoomStatus == 'STARTED' || getBreakoutSessionStatus() == true ? <Navbar
        dark={true}
        expand={"md"}
        container={"fluid"}

        className="meeku-breakout-room-header"
        fill
      >
        <div className="breakout-session-header-expert-main">
          <span>{!stopBreakoutLoading ? error_messages.breakoutSessionInProgress : "Ending the breakout session..."}</span>
          {currenGroupName && getGroupName() ? "" :
            !stopBreakoutLoading ?
              <span className="end-breakout-session-by-expert-btn" onClick={(e) => stopBreakoutRoom(e)}>End Breakout</span>
              :
              ""
          }
        </div>
      </Navbar> : ""}
      <Navbar
        dark={true}
        expand={"md"}
        container={"fluid"}
        {...args}
        className="meeku-header"
        fill
      >
        <div className="nav_title me-2" href="/">
          {meta_data.company}
        </div>
        {currenGroupName && getGroupName() ?
          <div className="header-e-opened_group_floating_lable">
            {getGroupName() ? getGroupName() : ""}

            <span className="leave-group-text-btn" onClick={() => leaveCurrentGroup(getGroupName())}>Leave Group</span>
          </div>
          : ""}
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {/* EXPERT VIDEO ENABLE/DISABLE */}
            <NavItem className="me-4">
              {zoomStream && zoomStream.isCapturingVideo() ? (
                <div>
                  {isVideoLoading ? <span className="loading-dot">...</span> :
                    <NavLink href="#">
                      <img
                        src={Video_icon_img}
                        alt=""
                        className="video-on-link mt-1"
                        onClick={!stopBreakoutLoading ? swichOfVideo : {}}
                        style={{ maxWidth: "auto" }}
                      />
                    </NavLink>}
                </div>
              ) : (
                <div>
                  {isVideoLoading ? <span className="loading-dot">...</span> :
                    <a className="video-off-link" href="#">

                      <img
                        src={Video_icon_off_img}
                        alt=""
                        className="video-off-icon"
                        onClick={!stopBreakoutLoading ? swichOnVideo : {}}
                      />
                    </a>}</div>
              )}
            </NavItem>

            {/* EXPERT AUDIO ENABLE/DISABLE */}
            <NavItem className="me-4">
              {zoomAudioStatus === "unmute" && (zoomStream && !zoomStream.isAudioMuted())? (
                <NavLink href="#">
                  <img
                    src={Voice_icon_img}
                    alt=""
                    className="video-on-link"
                    onClick={() => !stopBreakoutLoading ? audioToggle("mute") : {}}
                  />
                </NavLink>
              ) : (
                <a className="video-off-link" href="#">
                  <img
                    src={Voice_icon_off_img}
                    alt=""
                    className="video-off-icon"
                    onClick={() => !stopBreakoutLoading ? audioToggle("unmute") : {}}
                  />
                </a>
              )}
            </NavItem>

            {/* EXPERT SCREEN SHARE */}
            {currentUserData && currentUserData?.role == conditional_configs.role.expert || currentUserData?.role == conditional_configs.role.learner ?
              <NavItem className="me-4">
                {/* {zoomShareScreenStatus ?
                  <NavLink href="#" onClick={stopShareScreen}>
                    <img src={Share_img} alt="" className="meeku-header-icon" />
                  </NavLink>
                  :
                  <NavLink href="#" onClick={shareScreen}>
                    <img src={Share_img} alt="" className="meeku-header-icon" />
                  </NavLink>
                } */}
                <NavLink href="#" onClick={(e) => !stopBreakoutLoading ? handleShareScreenMenuOpen(e) : {}}>
                  <img src={Share_img} alt="" className="meeku-header-icon" />
                </NavLink>
              </NavItem> : ""}

            {/* EXPERT HAND RAISE STATUS */}
            <NavItem className="me-4">
              <NavLink onClick={(e) => handleRaisedHandControlMenuClick(e)}>
                <div className="hand-icon-container">
                  {getRaisedNotifications().length > 0 ? <span className="raised-hand-notification-dot"></span> : ""}
                  <img src={Hand_icon_img} alt="" className="meeku-header-icon" />
                </div>
              </NavLink>
            </NavItem>

            {/* EXPERT LEFT NAV ENABLE/DISABLE */}
            <NavItem>
              <NavLink href="#" className={!isFluid ? 'meeku-header-icon1-on' : "meeku-header-icon1-off"} onClick={setFluid}>
                {isFluid ? (
                  <img
                    src={Left_icon_img}
                    alt=""
                    className="meeku-header-icon1"
                  />
                ) : (
                  <img
                    src={Left_icon_img}
                    alt=""
                    className="meeku-header-icon1"
                  />
                )}
              </NavLink>
            </NavItem>


          </Nav>

          {/* EXPERT RIGHT SIDE END CONTROLS */}
          <Nav
            className="ms-auto d-flex
    justify-content-center;"
            navbar
          >
            {/* <div className="d-flex align-items-center rec_section_text">
              <div class="circle"></div>
              <div>
                <div>
                  <span>Rec</span>
                </div>
                <span>00:01</span>
              </div>
            </div> */}
            {currentUserData && currentUserData?.role == conditional_configs.role.expert && getBreakoutSessionStatus() == true ?
              <div class="break_out_section rec_section_text">
                <div className="d-flex align-items-center break_out_section_text">
                  <img src={Sand_clock} alt="" className="me-2" />
                  Breakout Session
                </div>
              </div> : ""}

            {/* EXPERT WAITING PARTICIPANTS */}
            {currentUserData && currentUserData?.role == conditional_configs.role.expert && getBreakoutSessionStatus() == true ?
              <NavItem>
                <NavLink onClick={(e) => handleWaitingParticipantsControlMenuClick(e)}>
                  <div className="hand-icon-container">
                    {!allowParticipants && getWaitingParticipants().length > 0 ? <span className="raised-hand-notification-dot"></span> : ""}
                    <img src={assets.notificationAlarm} alt="" className="meeku-header-icon" />
                  </div>
                </NavLink>
              </NavItem> : ""}

            {/* INFORMATION BOX */}
            <NavItem className="me-5 d-flex align-items-center">
              <NavLink onClick={(e) => handleClickOpenInformationBox(e)}>
                {getIcon(icons_config.infoIcon)}
              </NavLink>
            </NavItem>

            {/* EXPERT GALLERY VIEW */}
            {/* {currentUserData && currentUserData.role == conditional_configs.role.expert && getBreakoutSessionStatus() == false || !getBreakoutSessionStatus() ? <NavItem className="me-5">
              <NavLink onClick={() => openGalleryView()}>
                {getIcon(icons_config.gridViewIcon)}
              </NavLink>
            </NavItem> : ""} */}

            {/* {currentUserData && currentUserData.role == conditional_configs.role.expert ? <div className="d-flex align-items-center rec_section_text cursor-pointer">
              <div onClick={() => shareLink()}>
                <span>Share Link</span>
              </div>
              <div className="ms-2" onClick={() => openGalleryView()}>
                {getIcon(icons_config.gridViewIcon)}
              </div>
            </div> : ""} */}


            {breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true ? <div>
              <div onClick={logout} className="d-flex align-items-center logout_box">
                <img src={Logout_img} alt="" className="logout_icon" />
              </div>
            </div> :
              <div title={error_messages.breakoutSessionInProgress} className="d-flex align-items-center logout_box_inactive">
                <img src={Logout_img} alt="" className="logout_icon" />
              </div>
            }



          </Nav>
        </Collapse>
      </Navbar>

      {/* RAISE HAND MENU */}
      <Menu
        anchorEl={anchorEl}
        open={raisedHandCtrlMenu}
        onClose={handleRaisedHandControlMenuClose}
      >
        {getRaisedNotifications()?.map((obj, Index) => {
          return (
            <MenuItem>
              <div className="d-flex justify-content-center align-items-center">
                <div className="raise-hand-left-container">
                  <p className="m-0">
                    <span className="raise-hand-left-initial">{getInitialsFromFullName(obj.name)}</span>
                    <span className="raise-hand-name">{obj.name}</span>
                  </p>
                </div>
                <div className="lower-hand-container" onClick={(e) => handleLowerHand(e, obj)} >Lower Hand</div>
              </div>
            </MenuItem>
          )
        })}
      </Menu>

      {/* WAITING PARTICIPANTS */}
      <Menu
        anchorEl={waitingPartEl}
        open={waitingPartCtrlMenu}
        onClose={handleWaitingParticipantsControlMenuClose}
      >
        {getWaitingParticipants()?.map((obj, Index) => {
          return (
            <MenuItem>
              <div className="d-flex justify-content-center align-items-center">
                <div className="raise-hand-left-container">
                  <p className="m-0">
                    <span className="raise-hand-left-initial">{getInitialsFromFullName(obj?.displayName)}</span>
                    <span className="raise-hand-name">{obj?.displayName}</span>
                  </p>
                </div>
                {getAllowedParticipant()?.userId !== obj?.userId ? <div className="lower-hand-container" onClick={(e) => handleAllowParticipants(e, obj)} >Allow</div> : ""}
              </div>
            </MenuItem>
          )
        })}
      </Menu>

      {/* SHARE SCREEN OPTION MENU */}
      <Menu
        anchorEl={shareScreenEl}
        open={shareScreenOptionMenu}
        onClose={handleShareScreenMenuClose}
      >
        {zoomConfigs.shareScreenOptions.map((option, index) => (
          <MenuItem
            key={option.id}
            onClick={(e) => handleMenuItemClick(e, option)}
            selected={activeOption === option.id}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        fullScreen={fullScreen}
        open={informationBoxOpen}
        onClose={handleCloseInformationBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-center"
          id="responsive-dialog-title"
        >
          <img src={assets.alertIcon} width="90"></img>
        </DialogTitle>
        <DialogContent>
          <div>
            <span className="info-box-label">Browser:</span>
            <span className="info-box-text">{getExploreName()}</span>
          </div>
          <div>
            <span className="info-box-label">Video Permission:</span>
            <span className="info-box-text">{zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'}</span>
          </div>
          <div>
            <span className="info-box-label">Is Video Enabled:</span>
            <span className="info-box-text">{zoomStream && zoomStream.isCapturingVideo() ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <span className="info-box-label">Is Audio Enabled:</span>
            <span className="info-box-text">{zoomStream && !zoomStream.isAudioMuted() ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <span className="info-box-label">Active Camera:</span>
            <span className="info-box-text">{getActiveCamera(zoomStream)}</span>
          </div>
          <div>
            <span className="info-box-label">Active Microphone:</span>
            <span className="info-box-text">{getActiveMicrophone(zoomStream)}</span>
          </div>
          <div>
            <span className="info-box-label">Active Speaker :</span>
            <span className="info-box-text">{getActiveSpeaker(zoomStream)}</span>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <div className="confirm-no-btn" onClick={handleCloseInformationBox}>
            Close
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Header;
