import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Packages
import Swal from "sweetalert2";
import { toPng } from "html-to-image";
import slugify from "react-slugify";
import GroupSpace from "../../../learners/group-space/task-assign";
import { SnackbarProvider, useSnackbar } from "notistack";

import BreakoutWhiteboard from "../../../learners/group-space/breakout-whiteboard";

import "./class-room.css";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import {
  FaVideo,
  FaMicrophone,
  FaRegHandPaper,
  FaMicrophoneSlash,
} from "react-icons/fa";
import { BsFileEarmarkText } from "react-icons/bs";

import ZoomVideo from "@zoom/videosdk";
import Iconify from "../../../../components/Iconify";
import Book_icon_img from "../../../../assets/img/courses_img/book_icon_img.png";
import CourseList from "../../../../components/classroom/expert-v2/course/course";
import DocsList from "../../../../components/classroom/docs/docs";
import NotesList from "../../../../components/classroom/expert-v2/notes/notes";
import ChatList from "../../../../components/classroom/chat/chat";
import CourseView from "../../../../components/classroom/course-view/course-view";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
//Common Redux Storage
import {
  setBreakoutGameList,
  setCurrentGroupName,
  setAutoPlayGameRouteName,
  setSelectedCurriculam,
  setSelectedCurriculamData,
  setBreakoutCurrentScreenData,
  setBreakoutRoomStatus,
  setselectedGroup,
  setDisplayOption,
  setStartSelectedBreakoutRoom,
  setCurrentSubSessionsList,
  setIsFluid,
  setIsChatGeneralNotification,
  setMenu,
} from "../../../../store/slices/settingsSlice";

import {
  setZoomStatus,
  setClient,
  setStream,
  setParticipants,
  setCameraStatus,
  setZoomAudio,
  setLeaveGroup,
  setAllowParticipants,
  setStopBreakoutLoading,
} from "../../../../store/slices/zoomSlice";

import BreakOut from "../breakout-room/breakout-room";

import {
  Box,
  Stack,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Pagination,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  getCurrentUserData,
  generateVideoToken,
  storeZoomSignature,
  checkIsZoomSignatureAlreadyExist,
  zoomInitiated,
  checkIsZoomInitiated,
  storeZoomCurrentUserData,
  storeZoomParticipants,
  storeZoomClient,
  getZoomClient,
  getUnique,
  getOpenedChatGroup,
  storeOpenedChatGroup,
  removeOpenedChatGroup,
  getChats,
  storeChats,
  changeBreakoutSessionStatus,
  storeChatNotifications,
  storeChatGeneralNotifications,
  getBreakoutSessionStatus,
  getExpertOpenedCurrentGroupId,
  getWaitingParticipants,
  stopBreakoutLoading,
} from "../../../../utils/util.js";

import LoadingSpinner from "../../../shared/Loader/LoadingSpinner";

//IMAGES
import groupPeopleImg from "../../../../assets/img/courses_img/people.png";
import leftArrow from "../../../../assets/img/courses_img/leftarrow.png";
import muteImg from "../../../../assets/img/courses_img/un_speaker_img.png";
import unMuteImg from "../../../../assets/img/courses_img/speaker_img.png";
import joinUserImg from "../../../../assets/img/courses_img/user_img.png";
// import roboBuilder from '../../../../assets/img/games/robo_builder.jpg';
// import roboShaper from '../../../../assets/img/games/robo_shaper.png';
// import drillGame from '../../../../assets/img/games/drill_game.png';
// import test_doc from '../../../../assets/test_doc.pdf'
import test_ppt from "../../../../assets/test_ppt.pptx";

//CONFIG
import conditional_configs from "../../../../config/conditional_config";
import navigate_route_config from "../../../../config/navigate_route_config";
import storage_configs from "../../../../config/storage-config";
import url_configs from "../../../../config/url";
import game_config from "../../../../config/game_config";
import games from "../../../../config/games";
import zoomConfigs from "../../../../config/zoom";
import icons_config from "../../../../config/icons";
import assets from "../../../../config/assets";

//SERVICES
import MemberService from "../../../../services/member-service";
import UtilService from "../../../../services/util.service";
import ZoomService from "../../../../services/zoom-service";
import FirebaseService from "../../../../services/firebase-service";

import { getInitialsFromFullName } from "../../../../utils/util";

import io from "socket.io-client";
import SessionService from "../../../../services/session-service";
import error_messages from "../../../../config/error_messages";
import { events } from "../../../../config/events";
import whiteboardConfig from "../../../../config/whiteboard-config";

let chat_server_socket;
let b_room_socket;
//let breakoutRoomStatus = "";

export default function ClassRoom() {
  /** CHAT DECLARATION */
  //chat_server_socket = io(url_configs.chat_server);
  const [currentUserSpeakingEnable, setCurrentUserSpeakingEnable] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const imageRef = useRef(null);
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  const [loadingAllowUser, setLoadingAllowUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [showChatNofigication, setShowChatNofigication] = useState(false);

  const [isMute, setIsMute] = useState(true);
  //const [selectedGroup, setselectedGroup] = useState("");
  const [gameLink, setGameLink] = useState("");
  const [image, setImage] = useState();

  const sideMenu = useSelector((state) => state.settings.menu);
  const isFluid = useSelector((state) => state.settings.isFluid);

  const dispatch = useDispatch();
  const participants = useSelector((state) => state.zoom.participants);
  const zoomStream = useSelector((state) => state.zoom.stream);
  const shareScreenStatus = useSelector(
    (state) => state.zoom.shareScreenStatus
  );
  const leaveGroup = useSelector((state) => state.zoom.leaveGroup);

  const startSelectedBreakoutRoom = useSelector(
    (state) => state.settings.startSelectedBreakoutRoom
  );
  const displayOption = useSelector((state) => state.settings.displayOption);
  const selectedGroup = useSelector((state) => state.settings.selectedGroup);
  const selectedContent = useSelector(
    (state) => state.settings.selectedContent
  );
  const selectedCurriculam = useSelector(
    (state) => state.settings.selectedCurriculam
  );
  const breakoutRoomStatus = useSelector(
    (state) => state.settings.breakoutRoomStatus
  );
  const currentSubSessionsList = useSelector(
    (state) => state.settings.currentSubSessionsList
  );
  const isGalleryView = useSelector((state) => state.settings.isGalleryView);

  const [e_participants_h_idx, setE_participants_h_idx] = useState(0);
  const [selectedUserBoard, setSelectedUserBoard] = useState("");

  //const [startSelectedBreakoutRoom, setStartSelectedBreakoutRoom] = useState(false);
  //const [participants, setParticipants] = useState((state) => state.zoom.participants);
  const [expertInfo, setExpertInfo] = useState({});
  //const [currentSubSessionsList, setCurrentSubSessionsList] = useState([]);
  //const [displayOption, setDisplayOption] = useState("");
  const [isOpenGroupChat, setIsOpenGroupChat] = useState(false);
  const [openedGroup, setOpenedGroup] = useState();
  //const [breakoutRoomStatus, setBreakoutRoomStatus] = useState("");
  const [gameList, setGameList] = useState(games.gameList);

  const zmClient = ZoomVideo.createClient();

  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  //GET SLUG FROM LINK
  let { slug } = useParams();

  let currentUserData = JSON.parse(localStorage.getItem("user"));

  let audioDecode, audioEncode;

  let isUserUpdateCalled = false;

  //b_room_socket = io(url_configs.breakout_room_server);

  //const [selectedDoc, setSelectedDoc] = useState([]);

  //const [breakoutCurrentScreenData, setBreakoutCurrentScreenData] = useState(null);
  const breakoutCurrentScreenData = useSelector(
    (state) => state.settings.breakoutCurrentScreenData
  );
  const breakoutGameList = useSelector(
    (state) => state.settings.breakoutGameList
  );
  //const [breakoutGameList, setBreakoutGameList] = useState([]);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const [openLearnerQuizProgress, setOpenLearnerQuizProgress] =
    React.useState(false);
  const [learnerQuizResults, setLearnerQuizResults] = React.useState();
  const [refreshQuizUser, setRefreshQuizUser] = React.useState();

  const allowParticipants = useSelector(
    (state) => state.zoom.allowParticipants
  );

  useEffect(() => {
    stopBreakoutLoading("0");
    localStorage.setItem(
      storage_configs.localStorage.sessions.e_participants_h_idx,
      JSON.stringify(e_participants_h_idx)
    );

    let tempScreenData = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.currentScreenData
      )
    );
    if (tempScreenData) {
      dispatch(setBreakoutCurrentScreenData(tempScreenData));
      let tempGList = games.gameList.filter(
        (e) => e.id == tempScreenData.puzzleid
      );
      dispatch(setBreakoutGameList(tempGList));
    }
    dispatch(
      setCurrentSubSessionsList(
        JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.zoom.getSubsessionList
          )
        )
      )
    );
    if (slug) {
      /** CHECK IS ZOOM SIGNATURE ALREADY GENERATED */
      let signature = checkIsZoomSignatureAlreadyExist();

      const avatarUrl = getCurrentUserData().document_file_name;
      /** GENERATE ZOOM SIGNATURE IF NOT EXIST */
      if (!signature) {
        signature = generateVideoToken(
          zoomConfigs.sdk_key,
          zoomConfigs.sdk_secret,
          slug,
          zoomConfigs.session_password,
          avatarUrl,
          "",
          zoomConfigs.roleType.host
        );
        /** STORE ZOOM SIGNATURE IN BROWSER STORAGE */
        storeZoomSignature(signature);
      }

      let stream;
      const init = async () => {
        if (getCurrentUserData())
          await zmClient.init("en-US", "Global", { patchJsMedia: true });
        try {
          //if(checkIsZoomInitiated() !== 'true'){
          console.log("zmClient initialised ");
          zoomInitiated();

          /** JOIN INTO ZOOM */
          zmClient
            .join(
              slug,
              signature,
              getCurrentUserData().name + "-" + getCurrentUserData().id,
              zoomConfigs.session_password
            )
            .then((data) => {
              console.log("zmClient Joined ", data);
              localStorage.setItem(
                storage_configs.localStorage.sessions.zoom.firstTimeInteracted,
                true
              );

              localStorage.setItem(
                storage_configs.localStorage.sessions.break_out_session
                  .isNotification,
                true
              );
              stream = zmClient.getMediaStream();
              //START AUDIO SOUND ONLY
              ZoomService.startAudioAndMuteMic(stream);

              storeZoomCurrentUserData(zmClient.getCurrentUserInfo());
              setExpertInfo(zmClient.getCurrentUserInfo());

              /** ZOOM PARTICIPANTS */
              let participants = zmClient.getAllUser();
              //let participants = zmClient.getParticipantsList();
              console.log("participants", participants);
              // participants.forEach((user) => {
              //   if (user.bVideoOn) {
              //     renderVideo(stream, zmClient, user.isHost, user.userId);
              //   }
              // })
              storeZoomParticipants(participants);
              dispatch(setParticipants(participants));

              dispatch(setClient(zmClient));
              stream.setSharePrivilege(
                zoomConfigs.sharePrivilege.multipleShare
              );
              dispatch(setStream(stream));

              dispatch(setZoomStatus("started"));

              setTimeout(async () => {
                //await ZoomService.muteAudio(zmClient.getMediaStream());
                zmClient.on(events.zoom.peerVideoStateChange, (payload) => {
                  console.log(events.zoom.peerVideoStateChange, payload);
                  const participants = zmClient.getAllUser();
                  storeZoomParticipants(participants);
                  dispatch(setParticipants(participants));
                  if (participants) {
                    let getThisUser = participants.filter(
                      (e) => e.userId == payload.userId
                    )[0];
                    if (getThisUser) {
                      if (!getThisUser.isHost) {
                        if (payload.action === "Start") {
                          var curIndex = JSON.parse(
                            localStorage.getItem(
                              storage_configs.localStorage.sessions
                                .e_participants_h_idx
                            )
                          );

                          ZoomService.renderParticipantVideoInExpertHorizontalView(
                            stream,
                            zmClient,
                            curIndex
                          );
                          //renderVideo(stream, zmClient, false, payload.userId);
                        } else if (payload.action === "Stop") {
                          var curIndex = JSON.parse(
                            localStorage.getItem(
                              storage_configs.localStorage.sessions
                                .e_participants_h_idx
                            )
                          );
                          //ZoomService.stopRenderVideo(stream, payload.userId, 'expert');
                          ZoomService.renderParticipantVideoInExpertHorizontalView(
                            stream,
                            zmClient,
                            curIndex
                          );
                          //stopRenderVideo(stream, zmClient, false, payload.userId);
                        }
                      }
                      // if (getThisUser.isHost) {
                      //   if (payload.action === "Start") {
                      //     renderVideo(stream, zmClient, true, payload.userId);
                      //   } else if (payload.action === "Stop") {
                      //     stopRenderVideo(stream, zmClient, true, payload.userId);
                      //   }
                      // } else {
                      //   if (payload.action === "Start") {
                      //     renderVideo(stream, zmClient, false, payload.userId);
                      //   } else if (payload.action === "Stop") {
                      //     stopRenderVideo(stream, zmClient, false, payload.userId);
                      //   }
                      // }
                    }
                  }
                });

                zmClient.on(events.zoom.userAdded, (payload) => {
                  console.log(events.zoom.userAdded, payload);
                  // You can refresh the participants when
                  const participants = zmClient.getAllUser();
                  storeZoomParticipants(participants);
                  setParticipants(participants);
                  dispatch(setParticipants(participants));

                  // const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));
                  // if (checkNotification) {
                  //   //Notification if a user joined
                  //   for (var i = 0; i < payload.length; i++) {
                  //     var userName = payload[i].displayName.split(' ')[0];
                  //     enqueueSnackbar(userName + ' has joined!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                  //   }
                  // }
                });

                zmClient.on(events.zoom.userRemoved, (payload) => {
                  // You can refresh the participants when
                  const participants = zmClient.getAllUser();
                  console.log(events.zoom.userRemoved, payload);
                  storeZoomParticipants(participants);
                  setParticipants(participants);
                  dispatch(setParticipants(participants));
                  if (payload.length > 0) {
                    ZoomService.stopRenderVideo(
                      stream,
                      payload[0].userId,
                      "expert"
                    );
                    setTimeout(() => {
                      ZoomService.stopAllRenderedVideo(
                        stream,
                        zmClient,
                        "expert"
                      );
                      ZoomService.renderParticipantVideoInExpertHorizontalView(
                        stream,
                        zmClient,
                        0
                      );
                    }, 100);
                  }
                  //ZoomService.renderParticipantVideoInExpertHorizontalView(stream, zmClient, 0);
                  var sessionId = localStorage.getItem("joined-subsession-id");
                  //Handle Bird eye view user list
                  if (
                    getBreakoutSessionStatus() == true &&
                    payload &&
                    payload.length > 0
                  ) {
                    let getSubSessions = JSON.parse(
                      localStorage.getItem(
                        storage_configs.localStorage.sessions.zoom
                          .getSubsessionList
                      )
                    );
                    if (getSubSessions) {
                      getSubSessions.forEach((element) => {
                        if (
                          payload[0].subsessionId !== undefined &&
                          element.subsessionId == payload[0].subsessionId
                        ) {
                          element.userList = element.userList.filter(
                            (e) => e.displayName != payload[0].displayName
                          );
                        } else if (
                          sessionId &&
                          element.subsessionId == sessionId
                        ) {
                          element.userList = element.userList.filter(
                            (e) => e.displayName != payload[0].displayName
                          );
                        }
                      });
                      //setTimeout(() => {
                      localStorage.setItem(
                        storage_configs.localStorage.sessions.zoom
                          .getSubsessionList,
                        JSON.stringify(getSubSessions)
                      );
                      dispatch(setCurrentSubSessionsList(getSubSessions));
                      //}, 3000);
                    }
                  }

                  // const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));

                  // if (checkNotification) {
                  //   for (var i = 0; i < payload.length; i++) {
                  //     var userName = payload[i].displayName.split(' ')[0];
                  //     enqueueSnackbar(userName + ' has left!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                  //   }
                  // }
                });

                zmClient.on(events.zoom.userUpdated, (payload) => {
                  console.log(events.zoom.userUpdated, payload);
                  //Check Audio
                  const participants = zmClient.getAllUser();
                  storeZoomParticipants(participants);
                  setParticipants(participants);
                  dispatch(setParticipants(participants));
                  //ZoomService.renderParticipantVideoInExpertHorizontalView(stream, zmClient, 0);
                  let curUser = zmClient.getCurrentUserInfo();
                  if (curUser) {
                    storeZoomCurrentUserData(curUser);
                    setExpertInfo(curUser);
                    let checkIsExistCurUser = payload.filter(
                      (e) => e.userId == curUser.userId
                    )[0];
                    if (
                      checkIsExistCurUser &&
                      checkIsExistCurUser.bVideoOn == true
                    ) {
                      //renderVideo(stream, zmClient, true, checkIsExistCurUser.userId);
                      dispatch(setCameraStatus(true));
                    } else if (
                      checkIsExistCurUser &&
                      checkIsExistCurUser.bVideoOn == false
                    ) {
                      stopRenderVideo(
                        stream,
                        zmClient,
                        false,
                        checkIsExistCurUser.userId
                      );
                    }
                  }
                });

                zmClient.on(events.zoom.activeShareChange, (payload) => {
                  if (payload.state === "Active") {
                    setIsScreenShareOn(true);
                    setTimeout(() => {
                      stream.startShareView(
                        document.querySelector("#screen-share-canvas"),
                        payload.userId
                      );
                    }, 2000);
                  } else if (payload.state === "Inactive") {
                    setIsScreenShareOn(false);
                    stream.stopShareView();
                  }
                });

                // wait until the encoding and decoding process is ready for the audio
                zmClient.on(events.zoom.mediaSDKChange, (payload) => {
                  console.log(events.zoom.mediaSDKChange, payload);
                  const { action, type, result } = payload;
                  if (type === "audio" && result === "success") {
                    if (action === "encode") {
                      audioEncode = true;
                    } else if (action === "decode") {
                      audioDecode = true;
                    }
                    if (audioDecode && audioEncode) {
                      try {
                        // start audio automatically in Safari
                        var st = zmClient.getMediaStream();
                        dispatch(setStream(st));
                        ZoomService.unMuteAudio(st);
                        //dispatch(setZoomAudio("unmute"));
                        storeZoomCurrentUserData(zmClient.getCurrentUserInfo());
                        //stream.startAudio({ autoStartAudioInSafari: true });
                      } catch (err) {
                        console.warn(err);
                      }
                    }
                  }
                  //ZoomService.renderParticipantVideoInExpertHorizontalView(stream, zmClient, 0);
                });

                zmClient.on(events.zoom.activeSpeaker, (payload) => {
                  const participants = zmClient.getAllUser();
                  if (payload && payload.length > 0) {
                    participants.forEach((element) => {
                      element.isTalking = false;
                      payload.forEach((elePayload) => {
                        if (element.userId == elePayload.userId) {
                          element.isTalking = true;
                        } else {
                          element.isTalking = false;
                        }
                      });
                    });
                    storeZoomParticipants(participants);
                    setParticipants(participants);
                    dispatch(setParticipants(participants));
                  }
                });

                // zmClient.on('network-quality-change', (payload) => {
                //   console.log(payload)
                // })
              }, 3000);

              // zmClient.on('bo-room-countdown', (payload) => {
              //   console.log(payload)
              // })
              // zmClient.on("share_content_change", (payload) => {
              //   console.log("share_content_change", payload);
              // });
              // zmClient.on("active-share-change", (payload) => {
              //   console.log("active-share-change", payload);
              // });
              joinToGeneralChat();
            })
            .catch((e) => {
              console.log(e);
              dispatch(setZoomStatus("closed"));
            });
          //}

          //Get Courses Groups by Stream ID
          getMemberCourseGroupByStreamId();
          let checkSubsessions = JSON.parse(
            localStorage.getItem(
              storage_configs.localStorage.sessions.zoom.getSubsessionList
            )
          );
          if (checkSubsessions) {
            checkBreakoutRoomStatus();
            //ZoomService.renderParticipantVideoInExpertHorizontalView(stream, zmClient, 0);
          }
          //Check Breakout Status
          checkOnlyBreakoutStatus();
          setTimeout(() => {
            ZoomService.renderParticipantVideoInExpertHorizontalView(
              stream,
              zmClient,
              0
            );
          }, 2000);
        } catch (e) {
          console.log(`Error:: ${e.reason}`);
          dispatch(setZoomStatus("closed"));
        }
      };

      init();
      return () => {};
    } else {
      navigate(navigate_route_config.expert.my_courses);
    }
  }, []);

  const setSidebarMenu = (e, menu) => {
    dispatch(setIsFluid(false));
    dispatch(setMenu(menu));
    if (menu == "chat") {
      localStorage.removeItem(
        storage_configs.localStorage.sessions.chatGeneralNotification
      );
      dispatch(setIsChatGeneralNotification(false));
    }
  };

  // const checkActiveSpeaker = () => {
  //   // const participants = zmClient.getAllUser();
  //   // if (participants && participants.length > 0) {
  //   //   setTimeout(() => {
  //   //     participants.forEach(element => {
  //   //       element.isTalking = false;
  //   //     });
  //   //     storeZoomParticipants(participants);
  //   //     setParticipants(participants);
  //   //     dispatch(setParticipants(participants));
  //   //   }, 4000);
  //   // }
  // }

  //#region START BREAKOUT SESSION
  if (selectedCurriculam) {
    if (startSelectedBreakoutRoom != true) {
      dispatch(setStartSelectedBreakoutRoom(true));

      if (breakoutRoomStatus !== "STARTED") {
        let currentParticipants = [];
        currentParticipants = zmClient.getAllUser();
        currentParticipants = currentParticipants.filter(
          (e) => e.isHost == false
        );

        if (currentParticipants && currentParticipants.length == 0) {
          setIsLoading(false);
          dispatch(setSelectedCurriculamData(null));
          dispatch(setSelectedCurriculam(null));
          localStorage.removeItem(
            storage_configs.localStorage.sessions.currentScreenData
          );
          alert("No participants joined");
          window.location.reload();
          return;
        }

        /** GET COURSE GROUPS BY CURRENT STREAM ID */
        let current_stream_id = localStorage.getItem(
          storage_configs.localStorage.sessions.current_stream_id
        );
        if (current_stream_id) {
          MemberService.getMemberCourseGroupByStreamIdNew(current_stream_id)
            .then((response) => {
              setGroups(response.data);
              localStorage.setItem(
                "current-groups",
                JSON.stringify(response.data)
              );

              //dispatch(setCurrentSubSessionsList(JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList))));

              setIsLoading(true);

              /** CHECK IF PARTICIPANTS ARE NOT JOINED */
              dispatch(setCurrentSubSessionsList(null));
              console.log("Initiating Creating Subsessions");
              let subsession;
              subsession = zmClient.getSubsessionClient();

              //Format rooms array as per the request model to create Subsession in Zoom
              let rooms = [];

              //Remove Duplicates from groups
              let getGroups = JSON.parse(
                localStorage.getItem("current-groups")
              );
              const tempGroups = [
                ...new Map(
                  getGroups.map((item) => [item["coursegroupid"], item])
                ).values(),
              ];
              for (var i = 0; i < tempGroups.length; i++) {
                let roomObj = "";
                roomObj = tempGroups[i].groupname;
                rooms.push(roomObj);
              }

              /** CLONE WHITEBOARD */
              if (
                selectedCurriculam.selectedCurriculamType &&
                selectedCurriculam.selectedCurriculamType ==
                  conditional_configs.curriculam_types.whiteboard
              ) {
                for (var i = 0; i < tempGroups.length; i++) {
                  if (
                    selectedCurriculam.boardData &&
                    selectedCurriculam.boardData.length > 0
                  ) {
                    for (
                      var j = 0;
                      j < selectedCurriculam.boardData.length;
                      j++
                    ) {
                      FirebaseService.cloneDocument(
                        "scenes",
                        selectedCurriculam?.boardData[j]?.slug,
                        selectedCurriculam.boardData[j]?.slug +
                          "-div-" +
                          selectedCurriculam.id +
                          "-div-" +
                          slug +
                          "-div-" +
                          slugify(tempGroups[i].groupname)
                      );
                    }
                  }
                }
              }

              //Check zoom returned subsession list is empty.
              //Creating Subsession in Zoom manual assign team memeber. 2 - is for assign users manually.
              /** CREATE SUB SESSION */
              subsession.createSubsessions(
                rooms,
                zoomConfigs.createSessionType.manual
              );
              setTimeout(() => {
                /** OPEN THE SUB SESSION */
                ZoomService.openSubsessions(
                  zmClient,
                  subsession,
                  subsession.getSubsessionList().slice(-tempGroups.length)
                );

                setTimeout(() => {
                  let getSubSessions = subsession.getSubsessionList();

                  //Get User IDs from Display Name. Concatinated the user ids with Names.`Example: Jade Williams-1`
                  currentParticipants.forEach((element) => {
                    element.id = Number(element.displayName.split("-")[1]);
                  });
                  for (let i = 0; i < currentParticipants.length; i++) {
                    let getGroup = tempGroups.filter(
                      (e) =>
                        e.members.filter(
                          (a) => a.id == currentParticipants[i].id
                        )[0]
                    )[0];
                    if (getGroup) {
                      let getZoomSubsessionGroup = getSubSessions
                        .slice(-tempGroups.length)
                        .filter(
                          (e) => e.subsessionName == getGroup.groupname
                        )[0];
                      if (subsession && getZoomSubsessionGroup) {
                        //ASSIGN USER TO SUBSESSION
                        ZoomService.assignUserToSubsession(
                          zmClient,
                          subsession,
                          currentParticipants[i].userId,
                          getZoomSubsessionGroup.subsessionId.toString()
                        );
                        sleep(3000);
                      }
                    }
                  }

                  setTimeout(() => {
                    //Declare and initialize Temporary array list for Subsessions
                    let formatedTempSubSessionList = [];
                    let tempSubSessionList = subsession.getSubsessionList();

                    //Check zoom returned subsession list is empty.
                    //If its length is equal to 0, Get values from groups list and assign values.
                    if (tempSubSessionList && tempSubSessionList.length == 0) {
                      tempGroups.forEach((element) => {
                        let subSessionObj = {
                          subsessionId: "",
                          subsessionName: "",
                          image:
                            selectedCurriculam.selectedCurriculamType &&
                            selectedCurriculam.selectedCurriculamType ==
                              conditional_configs.curriculam_types.whiteboard
                              ? "whiteboard"
                              : selectedCurriculam.selectedCurriculamType &&
                                selectedCurriculam.selectedCurriculamType ==
                                  conditional_configs.curriculam_types.quiz
                              ? conditional_configs.curriculam_types.quiz
                              : "games",
                          group_id: "",
                          userList: [],
                          isGameOpened: false,
                          gameLink: "",
                          whiteboardLink: "",
                        };
                        subSessionObj.subsessionId = element.coursegroupid;
                        subSessionObj.subsessionName = element.groupname;
                        subSessionObj.group_id = element.coursegroupid;
                        formatedTempSubSessionList.push(subSessionObj);
                      });
                    }
                    //else: Get values from the Zoom returned values and assign as required.
                    else {
                      tempSubSessionList
                        .slice(-tempGroups.length)
                        .forEach((element) => {
                          // let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(obj.group_name) + '-div-' + slugify(obj.id) +'-div-'+element.group_id + whiteboardConfig.key;

                          let subSessionObj = {
                            subsessionId: "",
                            subsessionName: "",
                            image:
                              selectedCurriculam.selectedCurriculamType &&
                              selectedCurriculam.selectedCurriculamType ==
                                conditional_configs.curriculam_types.whiteboard
                                ? "whiteboard"
                                : selectedCurriculam.selectedCurriculamType &&
                                  selectedCurriculam.selectedCurriculamType ==
                                    conditional_configs.curriculam_types.quiz
                                ? conditional_configs.curriculam_types.quiz
                                : "games",
                            group_id: "",
                            userList: [],
                            isGameOpened: false,
                            gameLink: "",
                            whiteboardLink:
                              selectedCurriculam.selectedCurriculamType &&
                              selectedCurriculam.selectedCurriculamType ==
                                conditional_configs.curriculam_types.whiteboard
                                ? openBRoomBoard(
                                    breakoutCurrentScreenData.boardData[0]
                                      .whiteboardLink
                                  )
                                : "",
                          };
                          subSessionObj.subsessionId = element.subsessionId;
                          subSessionObj.subsessionName = element.subsessionName;
                          subSessionObj.userList = element.userList;
                          if (element.userList.length == 0) {
                            subSessionObj.whiteboardLink = "";
                          }
                          let getTempGroupObj = tempGroups.filter(
                            (e) => e.groupname == element.subsessionName
                          )[0];
                          if (getTempGroupObj) {
                            subSessionObj.group_id =
                              getTempGroupObj.coursegroupid;
                          }

                          let checkSubSessionAlreadyExist =
                            formatedTempSubSessionList.filter(
                              (e) => e.subsessionName == element.subsessionName
                            );
                          if (
                            checkSubSessionAlreadyExist &&
                            checkSubSessionAlreadyExist.length == 0
                          ) {
                            formatedTempSubSessionList.push(subSessionObj);
                          }
                        });
                    }
                    localStorage.setItem(
                      storage_configs.localStorage.sessions.zoom
                        .getSubsessionList,
                      JSON.stringify(formatedTempSubSessionList)
                    );
                    dispatch(
                      setCurrentSubSessionsList(formatedTempSubSessionList)
                    );

                    setTimeout(() => {
                      getScreensOfBreakoutRoom();
                      dispatch(setBreakoutRoomStatus("STARTED"));
                      dispatch(setDisplayOption("breakout_room"));
                      setTimeout(() => {
                        setIsLoading(false);
                        window.location.reload();
                      }, 1500);
                    }, 1000);
                  }, 5000);
                }, 8000);
              }, 3000);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    }
  }
  //#endregion

  const setActiveSubsessionUser = (sList, index) => {
    if (sList && sList.length > 0) {
      sList.forEach((element) => {
        let obj = element;
        if (obj.userList[index]) {
          obj.userList[index].active = true;
          element = obj;
        }
      });
    }
  };

  //SELECTED USER WHITEBOARD SCREEN
  const setActiveSubsessionUserEvent = (e, index, uindex) => {
    e.stopPropagation();
    setSelectedUserBoard("");
    let cData = [];

    // currentSubSessionsList.forEach((device, index) => {
    //   cData[index] = { ...device }
    // })
    cData = JSON.parse(JSON.stringify(currentSubSessionsList));
    if (cData && cData.length > 0) {
      cData.forEach((element) => {
        let obj = element;
        if (obj.userList && obj.userList.length > 0) {
          obj.userList.forEach((ele) => {
            //let tObj = { ...ele };
            ele.active = false;
            //ele = tObj;
          });
        }
      });

      setTimeout(() => {
        let tempCData = JSON.parse(JSON.stringify(cData));

        tempCData[index].userList[uindex].active = true;
        localStorage.setItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList,
          JSON.stringify(tempCData)
        );
        dispatch(setCurrentSubSessionsList(tempCData));

        let sessionScreenDetail = {
          sessionDetail: tempCData[index],
          userDetail: tempCData[index].userList[uindex],
          user: getCurrentUserData(),
        };

        b_room_socket.emit(
          "on-b-room-know-users",
          { data: sessionScreenDetail },
          (error) => {
            if (error) {
              console.log(error);
            }
          }
        );
      }, 500);
    }
  };

  const setActiveSubsessionUserDefault = () => {
    setSelectedUserBoard("");
    let cData = [];
    let tempList = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.zoom.getSubsessionList
      )
    );
    // currentSubSessionsList.forEach((device, index) => {
    //   cData[index] = { ...device }
    // })
    cData = JSON.parse(JSON.stringify(tempList));
    if (cData && cData.length > 0) {
      cData.forEach((element) => {
        let obj = element;
        if (obj.userList && obj.userList.length > 0) {
          obj.userList.forEach((ele) => {
            //let tObj = { ...ele };
            ele.active = false;
            //ele = tObj;
          });
        }
      });

      setTimeout(() => {
        let tempCData = JSON.parse(JSON.stringify(cData));

        tempCData.forEach((element) => {
          if (element.userList[0] && element.userList[0].length > 0) {
            element.userList[0].active = true;
            let sessionScreenDetail = {
              sessionDetail: element,
              userDetail: element.userList[0],
              user: getCurrentUserData(),
            };
            b_room_socket.emit(
              "on-b-room-know-users",
              { data: sessionScreenDetail },
              (error) => {
                if (error) {
                  console.log(error);
                }
              }
            );
          }
        });

        localStorage.setItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList,
          JSON.stringify(tempCData)
        );
        dispatch(setCurrentSubSessionsList(tempCData));
      }, 500);
    }
  };

  const setActiveSubsessionUserEventList = (mObj, index) => {
    //setSelectedUserBoard(null);

    if (mObj && mObj.userList && mObj.userList.length > 0) {
      let cData = [...currentSubSessionsList];

      setTimeout(() => {
        let findCurrentUserActiveIndex = mObj.userList.findIndex(
          (e) => e.active == true
        );

        let curUserIndex = 0;
        if (findCurrentUserActiveIndex == -1) {
          curUserIndex = 0;
        } else if (findCurrentUserActiveIndex + 1 < mObj.userList.length) {
          curUserIndex = findCurrentUserActiveIndex + 1;
        } else {
          curUserIndex = 0;
        }
        cData[index].userList.forEach((element) => {
          element.active = false;
        });
        // setTimeout(() => {
        cData[index].userList[curUserIndex].active = true;

        localStorage.setItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList,
          JSON.stringify(cData)
        );
        dispatch(setCurrentSubSessionsList(cData));

        let sessionScreenDetail = {
          sessionDetail: cData[index],
          userDetail: cData[index].userList[curUserIndex],
          user: getCurrentUserData(),
        };

        b_room_socket.emit(
          "on-b-room-know-users",
          { data: sessionScreenDetail },
          (error) => {
            if (error) {
              console.log(error);
            }
          }
        );
        return cData[index].userList;
        // }, 100);
      }, 180000);
      return cData[index].userList;
    }

    return [];
  };

  //#region ZOOM VIDEO/AUDIO
  const startVideo = (stream, client) => {
    if (!stream.isCameraTaken()) {
      const session = client.getSessionInfo();
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if (
        (!stream.isSupportMultipleVideos() &&
          typeof OffscreenCanvas === "function") ||
        /android/i.test(navigator.userAgent)
      ) {
        // start video - video will render automatically on HTML Video Element if MediaStreamTrackProcessor is supported
        stream
          .startVideo({
            videoElement: document.querySelector("#e-self-view-video"),
            hd: stream.isSupportHDVideo(),
          })
          .then(() => {
            // if MediaStreamTrackProcessor is not supported
            if (!(typeof MediaStreamTrackProcessor === "function")) {
              // render video on HTML Canvas Element
              setTimeout(() => {
                stream
                  .renderVideo(
                    document.querySelector("#e-self-view-canvas"),
                    session.userId,
                    320,
                    180,
                    0,
                    0,
                    2
                  )
                  .then(() => {
                    // show HTML Canvas Element in DOM
                    document.querySelector(
                      "#e-self-view-canvas"
                    ).style.display = "block";
                    document.querySelector("#e-self-view-video").style.display =
                      "none";
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }, 2000);
            } else {
              // show HTML Video Element in DOM
              document.querySelector("#e-self-view-video").style.display =
                "block";
              document.querySelector("#e-self-view-canvas").style.display =
                "none";
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // start video
        stream
          .startVideo()
          .then(() => {
            // render video on HTML Canvas Element
            stream
              .renderVideo(
                document.querySelector("#e-self-view-canvas"),
                session.userId,
                320,
                180,
                0,
                0,
                2
              )
              .then(() => {
                // show HTML Canvas Element in DOM
                document.querySelector("#e-self-view-canvas").style.display =
                  "block";
                document.querySelector("#e-self-view-video").style.display =
                  "none";
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // stream.startVideo({
      //   videoElement: document.querySelector("#self-view-video"),
      // });
    }
  };

  const renderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId), userId, 320, 180, 0, 0, 2).then(() => {
      //   // show HTML Canvas Element in DOM
      //   document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId).style.display = 'block';
      //   document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId).style.display = 'none';
      // }).catch((error) => {
      //   console.log(error);
      //   console.log('error here')
      // })

      setTimeout(() => {
        stream
          .renderVideo(
            document.querySelector(
              isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
            ),
            userId,
            320,
            180,
            0,
            0,
            2
          )
          .then(() => {
            // show HTML Canvas Element in DOM
            document.querySelector(
              isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
            ).style.display = "block";
            document.querySelector(
              isHost ? "#e-self-view-video" : "#participants-video-" + userId
            ).style.display = "none";
          })
          .catch((error) => {
            console.log(error);
            console.log("error here");
          });
        // if (isGalleryView == false) {
        //   stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId), userId, 320, 180, 0, 0, 2).then(() => {
        //     // show HTML Canvas Element in DOM
        //     document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId).style.display = 'block';
        //     document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId).style.display = 'none';
        //   }).catch((error) => {
        //     console.log(error);
        //     console.log('error here')
        //   })
        // } else {
        //   stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-gallery-" + userId), userId, 320, 180, 0, 0, 2).then(() => {
        //     // show HTML Canvas Element in DOM
        //     document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-gallery-" + userId).style.display = 'block';
        //     document.querySelector(isHost ? "#self-view-video" : "#participants-video-gallery-" + userId).style.display = 'none';

        //   }).catch((error) => {
        //     console.log(error);
        //     console.log('error here')
        //   })
        // }
      }, 5000);
    }
  };

  const stopRenderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if (
        (!stream.isSupportMultipleVideos() &&
          typeof OffscreenCanvas === "function") ||
        /android/i.test(navigator.userAgent)
      ) {
        // if MediaStreamTrackProcessor is not supported
        if (!(typeof MediaStreamTrackProcessor === "function")) {
          // render video on HTML Canvas Element
          setTimeout(() => {
            stream.stopRenderVideo(
              document.querySelector(
                isHost
                  ? "#e-self-view-canvas"
                  : "#participants-canvas-" + userId
              ),
              userId
            );
            if (
              document.querySelector(
                isHost
                  ? "#e-self-view-canvas"
                  : "#participants-canvas-" + userId
              )
            ) {
              document.querySelector(
                isHost
                  ? "#e-self-view-canvas"
                  : "#participants-canvas-" + userId
              ).style.display = "none";
            }
          }, 2000);
        } else {
          // show HTML Video Element in DOM
          stream.stopRenderVideo(
            document.querySelector(
              isHost ? "#e-self-view-video" : "#participants-video-" + userId
            ),
            userId
          );
          if (
            document.querySelector(
              isHost ? "#e-self-view-video" : "#participants-video-" + userId
            )
          ) {
            document.querySelector(
              isHost ? "#e-self-view-video" : "#participants-video-" + userId
            ).style.display = "none";
          }
        }
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // render video on HTML Canvas Element
        stream.stopRenderVideo(
          document.querySelector(
            isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
          ),
          userId
        );
        if (
          document.querySelector(
            isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
          )
        ) {
          document.querySelector(
            isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
          ).style.display = "none";
        }
      }
    }
  };

  const audioInit = (stream, client) => {
    // console.log("Start Audio")
    // stream.startAudio({ autoStartAudioInSafari: true }).then((audio)=>{
    //   console.log("Started Audio", audio)
    // }).catch((error) => {
    //   console.log('Audio Error here')
    //   console.log(error);
    // });

    stream
      .startAudio({ autoStartAudioInSafari: true })
      .then((audio) => {
        console.log("Started Audio", audio);
      })
      .catch((error) => {
        console.log("Audio Error here");
        console.log(error);
      });

    // if (audioDecode && audioDecode) {
    //   try {
    //     stream.startAudio();
    //   } catch (err) {
    //     console.warn(err);
    //   }
    // }

    client.on("auto-play-audio-failed", () => {
      console.log("auto play failed, waiting for a user interaction");
    });
  };

  //#endregion

  /**Get Courses Groups by Stream ID  */
  const getMemberCourseGroupByStreamId = () => {
    //Get Stream ID from Local Storage. Which has already stored while start session by Expert.
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id) {
      MemberService.getMemberCourseGroupByStreamIdNew(current_stream_id)
        .then((response) => {
          setGroups(response.data);
          localStorage.setItem("current-groups", JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  //#region CHAT FUNCTIONS AND EVENTS
  const getGroupMembersAsCommaSeparated = (gMembers) => {
    let members = "";
    if (gMembers) {
      gMembers.forEach((element) => {
        if (!members) {
          members = element.firstname;
        } else {
          members = members + ", " + element.firstname;
        }
      });
    }
    return members;
  };

  const getChatsByStreamId = () => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id)
      SessionService.getChatsByStreamId(current_stream_id)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            storeChats(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  /** Join to General Chat */
  const joinToGeneralChat = () => {
    getChatsByStreamId();
    let generalChats = [...messages];
    let storedChats = getChats();
    if (storedChats && storedChats.length > 0) {
      generalChats = storedChats.filter((e) => !e.group);
    }
    setMessages(generalChats);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit(
      "join",
      { name: getCurrentUserData().name, room: slug },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );

    chat_server_socket.on("message", (message) => {
      setShowChatNofigication(false);
      if (message.slug == slug) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message);
        setMessages(generalChats);
        storeChats(generalChats);
        setTimeout(() => {
          setShowChatNofigication(true);
          if (message && message.user != getCurrentUserData().name) {
            let tempNofications = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.chatNotification
              )
            );
            if (!tempNofications) {
              tempNofications = [];
            }
            tempNofications.push(message);
            storeChatNotifications(tempNofications);
            storeChatGeneralNotifications(tempNofications);
            dispatch(setIsChatGeneralNotification(true));
            enqueueSnackbar("Message received", {
              variant: "success",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        }, 200);
        // if (message && message.user != getCurrentUserData().name && (!message.group || (getOpenedChatGroup() && message.group.coursegroupid == getOpenedChatGroup().coursegroupid))) {
        //   enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        // }
      }
    });
  };

  const checkIsAnyNotification = (gId) => {
    if (gId) {
      let curNotification = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.chatNotification
        )
      );
      if (curNotification) {
        curNotification = curNotification.filter(
          (e) => e.group && e.group.coursegroupid == gId
        );
        return curNotification;
      }
    }
    return [];
  };

  const clearChatNotificationByGroup = (gId) => {
    if (gId) {
      let curNotification = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.chatNotification
        )
      );
      curNotification = curNotification.filter(
        (e) => e.group && e.group.coursegroupid !== gId
      );
      storeChatNotifications(curNotification);
    }
  };

  const openGroupChat = (gObj) => {
    let msgs = [...messages].reverse();
    msgs = getChats();
    if (msgs) {
      let openedGrp = getOpenedChatGroup();
      if (openedGrp) {
        msgs = msgs.filter(
          (e) => e.group && e.group.coursegroupid == openedGrp.coursegroupid
        );
      }
      setMessages(msgs);
    }

    if (gObj) {
      storeOpenedChatGroup(gObj);
      setIsOpenGroupChat(true);
      setOpenedGroup(gObj);
      clearChatNotificationByGroup(gObj.coursegroupid);
    }
  };

  const closeGroupChat = (e) => {
    setIsOpenGroupChat(false);
    removeOpenedChatGroup();
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    let chatModel = {
      group: !getOpenedChatGroup() ? null : getOpenedChatGroup(),
      isInSession: true,
      courseStreamId: null,
      room: slug,
      slug: slug,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "expert",
    };
    if (message) {
      chat_server_socket.emit("sendMessage", {
        message: message,
        group: getOpenedChatGroup(),
        slug: slug,
        userId: getCurrentUserData().id,
        userType: "expert",
      });
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  const handleBroadcastChatSubmit = (e) => {
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: true,
      courseStreamId: null,
      room: slug,
      slug: slug,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "expert",
    };
    if (message) {
      let currentGroups = JSON.parse(localStorage.getItem("current-groups"));
      if (currentGroups && currentGroups.length > 0) {
        currentGroups.forEach((element) => {
          chat_server_socket.emit("sendMessage", {
            message: message,
            group: element,
            slug: slug,
            userId: getCurrentUserData().id,
            userType: "expert",
          });
          chatModel.group = element;
          addChats(chatModel);
        });
      }
      setTimeout(() => {
        setMessage("");
        enqueueSnackbar("Broadcast message sent", {
          variant: "success",
          key: "topright",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }, 2000);
    } else alert("empty input");
  };

  const addChats = (data) => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    data.isInSession = true;
    data.courseStreamId = current_stream_id;
    SessionService.addChats(data)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const sendScreenshot = (e) => {
    if (imageRef.current) {
      toPng(imageRef.current, { cacheBust: true })
        .then((dataUrl) => {
          chat_server_socket.emit("sendMessage", {
            message: dataUrl,
            group: getOpenedChatGroup(),
            slug: slug,
            userId: getCurrentUserData().id,
            userType: "expert",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes("data:image/");
      return checkIsImg;
    }
  };
  //#endregion

  //#region BREAKOUT ROOM FUNCTIONS AND EVENTS

  const changeToMute = (e) => {
    e.stopPropagation();
    setIsMute(true);
    ZoomService.stopAllRenderedVideo(zoomStream, zmClient, "expert");
    setTimeout(() => {
      closeCurrentGroup();
    }, 200);
  };

  const changeToUnMute = (e, group, id, obj) => {
    localStorage.setItem(
      storage_configs.localStorage.sessions.break_out_session.isNotification,
      false
    );
    e.stopPropagation();
    let checkAlreadyJoinedGroup = localStorage.getItem(
      storage_configs.localStorage.sessions.current_group_id
    );
    if (checkAlreadyJoinedGroup) {
      dispatch(setselectedGroup(null));
      dispatch(setIsFluid(false));
      if (zoomStream && zoomStream.isCapturingVideo()) {
        const session = zmClient.getSessionInfo();
        zoomStream.stopVideo();
        dispatch(setCameraStatus(false));
        stopRenderVideo(zoomStream, zmClient, true, session.userId);
      }
      let subsession;
      subsession = zmClient.getSubsessionClient();
      subsession.leaveSubsession().then((res) => {
        localStorage.removeItem(
          storage_configs.localStorage.sessions.current_group_id
        );
        setTimeout(() => {
          if (obj && obj.group_id) {
            localStorage.setItem(
              storage_configs.localStorage.sessions.current_group_id,
              obj.group_id
            );
          }
          setIsMute(false);
          dispatch(setCurrentGroupName(group));
          //dispatch(setselectedGroup(group));
          speakToGroup(obj);
        }, 4000);
      });
    } else {
      if (obj && obj.group_id) {
        localStorage.setItem(
          storage_configs.localStorage.sessions.current_group_id,
          obj.group_id
        );
      }
      setIsMute(false);
      dispatch(setCurrentGroupName(group));
      //dispatch(setselectedGroup(group));
      speakToGroup(obj);
    }
  };

  const speakToGroup = async (data) => {
    let subsession;
    subsession = zmClient.getSubsessionClient();
    let getSubsessionList = subsession.getSubsessionList();

    if (getSubsessionList && getSubsessionList.length > 0) {
      //let subsId = getSubsessionList.filter(e=>e.subsessionName == data.subsessionName)[0].subsessionId.toString();
      let subsId = data.subsessionId.toString();
      localStorage.setItem("joined-subsession-id", subsId);
      subsession.joinSubsession(subsId);
      let stream = zmClient.getMediaStream();
      dispatch(setStream(stream));
      setTimeout(async () => {
        //await ZoomService.muteAudio(stream);
        await ZoomService.unMuteAudio(stream);
        dispatch(setZoomAudio("unmute"));
        ZoomService.renderParticipantVideoInExpertHorizontalView(
          stream,
          zmClient,
          0
        );
        let participants = zmClient.getAllUser();
        //let participants = zmClient.getParticipantsList();
        console.log("participants", participants);
        participants.forEach((user) => {
          if (user.bVideoOn && user.isHost) {
            renderVideo(stream, zmClient, user.isHost, user.userId);
          }
        });
      }, 5000);

      // zmClient.on("auto-play-audio-failed", () => {
      //   console.log("auto play failed, waiting for a user interaction");
      // });
      //ZoomService.unMuteAudio(zoomStream);
      //audioInit(stream, zmClient);
      //dispatch(setIsFluid(true));
    }
  };

  const joinToSubSession = async (data) => {
    localStorage.setItem(
      storage_configs.localStorage.sessions.break_out_session.isNotification,
      false
    );
    let subsession;
    subsession = zmClient.getSubsessionClient();
    let getSubsessionList = subsession.getSubsessionList();

    if (getSubsessionList && getSubsessionList.length > 0) {
      //let subsId = getSubsessionList.filter(e=>e.subsessionName == data.subsessionName)[0].subsessionId.toString();
      let subsId = data.subsessionId.toString();
      localStorage.setItem("joined-subsession-id", subsId);
      await subsession.joinSubsession(subsId);
      let stream = zmClient.getMediaStream();
      dispatch(setStream(stream));

      setTimeout(() => {
        ZoomService.renderParticipantVideoInExpertHorizontalView(
          stream,
          zmClient,
          0
        );
        let participants = zmClient.getAllUser();
        //let participants = zmClient.getParticipantsList();
        console.log("participants", participants);
        participants.forEach((user) => {
          if (user.bVideoOn && user.isHost) {
            renderVideo(stream, zmClient, user.isHost, user.userId);
          }
        });
      }, 5000);
      // setTimeout(() => {

      //   //dispatch(setZoomAudio("mute"));
      // }, 5000);
      // await ZoomService.unMuteAudio(stream);
      // dispatch(setZoomAudio("unmute"));
      //ZoomService.unMuteAudio(stream);
      //audioInit(stream, zmClient);
      //dispatch(setIsFluid(true));
      // if (!stream.isCameraTaken()) {
      //   stream.startVideo({
      //     videoElement: document.querySelector("#participants-canvas"),
      //   });
      // }
    }
  };

  const checkParticipantInGroup = (partData) => {
    if (partData) {
      let getSubSessions = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList
        )
      );
      if (getSubSessions) {
        var checkUserExist = getSubSessions.filter(
          (e) => e.userList.filter((a) => a.userId == partData.userId)[0]
        )[0];
        if (checkUserExist) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const joinParticipantToGroup = (e, partData) => {
    setLoadingAllowUser(true);
    e.stopPropagation();
    let subsession;
    subsession = zmClient.getSubsessionClient();
    setTimeout(() => {
      let getSubSessions = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList
        )
      );
      setTimeout(() => {
        console.log("joinParticipantToGroup", partData);
        let getGroups = JSON.parse(localStorage.getItem("current-groups"));
        const tempGroups = [
          ...new Map(
            getGroups.map((item) => [item["coursegroupid"], item])
          ).values(),
        ];
        //Get User IDs from Display Name. Concatinated the user ids with Names.`Example: Jade Williams-1`
        let tempCurrentUserId = Number(partData.displayName.split("-")[1]);
        let getGroup = tempGroups.filter(
          (e) => e.members.filter((a) => a.id == tempCurrentUserId)[0]
        )[0];
        if (getGroup) {
          let getZoomSubsessionGroup = getSubSessions.filter(
            (e) => e.group_id == getGroup.coursegroupid
          )[0];
          let data = {
            userId: tempCurrentUserId,
            sessionSlug: slug,
            currentScreenData: selectedCurriculam,
          };
          if (subsession && getZoomSubsessionGroup) {
            //PUSH THE USER INTO RESPECTIVE GROUP USERLIST
            getSubSessions.forEach((element) => {
              if (element.group_id == getGroup.coursegroupid) {
                element.userList = element.userList.filter(
                  (e) => e.displayName !== partData.displayName
                );
                element.userList.push(partData);
              }
            });

            // ASSIGN USER TO SUBSESSION
            ZoomService.assignUserToSubsession(
              zmClient,
              subsession,
              partData.userId,
              getZoomSubsessionGroup.subsessionId.toString()
            );
            sleep(3000);

            localStorage.setItem(
              storage_configs.localStorage.sessions.zoom.getSubsessionList,
              JSON.stringify(getSubSessions)
            );
            setTimeout(() => {
              dispatch(setCurrentSubSessionsList(getSubSessions));
              setLoadingAllowUser(false);
            }, 8000);
            b_room_socket = io(url_configs.breakout_room_server);

            b_room_socket.emit(
              "on-join-user-in-middle",
              { data: data },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
          }
        }
      }, 2000);
    }, 2000);
  };

  if (allowParticipants == true) {
    dispatch(setAllowParticipants(false));
    setTimeout(() => {
      joinParticipantToGroupWithoutEvent();
    }, 200);
  }
  const joinParticipantToGroupWithoutEvent = () => {
    var partData = JSON.parse(localStorage.getItem("allow-this-pariticipant"));
    if (partData) setLoadingAllowUser(true);
    let subsession;
    subsession = zmClient.getSubsessionClient();
    setTimeout(() => {
      let getSubSessions = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.zoom.getSubsessionList
        )
      );
      setTimeout(() => {
        console.log("joinParticipantToGroup", partData);
        let getGroups = JSON.parse(localStorage.getItem("current-groups"));
        const tempGroups = [
          ...new Map(
            getGroups.map((item) => [item["coursegroupid"], item])
          ).values(),
        ];
        //Get User IDs from Display Name. Concatinated the user ids with Names.`Example: Jade Williams-1`
        let tempCurrentUserId = Number(partData.displayName.split("-")[1]);
        let getGroup = tempGroups.filter(
          (e) => e.members.filter((a) => a.id == tempCurrentUserId)[0]
        )[0];
        if (getGroup) {
          let getZoomSubsessionGroup = getSubSessions.filter(
            (e) => e.group_id == getGroup.coursegroupid
          )[0];
          let tempScreenData = JSON.parse(
            localStorage.getItem(
              storage_configs.localStorage.sessions.currentScreenData
            )
          );
          dispatch(setBreakoutCurrentScreenData(tempScreenData));
          let data = {
            userId: tempCurrentUserId,
            sessionSlug: slug,
            currentScreenData: tempScreenData,
          };
          if (subsession && getZoomSubsessionGroup) {
            //PUSH THE USER INTO RESPECTIVE GROUP USERLIST
            getSubSessions.forEach((element) => {
              if (element.group_id == getGroup.coursegroupid) {
                element.userList = element.userList.filter(
                  (e) => e.displayName !== partData.displayName
                );
                element.userList.push(partData);
              }
            });

            // ASSIGN USER TO SUBSESSION
            ZoomService.assignUserToSubsession(
              zmClient,
              subsession,
              partData.userId,
              getZoomSubsessionGroup.subsessionId.toString()
            );
            sleep(3000);

            localStorage.setItem(
              storage_configs.localStorage.sessions.zoom.getSubsessionList,
              JSON.stringify(getSubSessions)
            );
            setTimeout(() => {
              dispatch(setCurrentSubSessionsList(getSubSessions));
              setLoadingAllowUser(false);
              var waitingParticipants = [];
              waitingParticipants = getWaitingParticipants();
              if (waitingParticipants && waitingParticipants.length > 0) {
                waitingParticipants = waitingParticipants.filter(
                  (e) => e.displayName !== partData.displayName
                );
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom
                    .waitingParticipants,
                  JSON.stringify(waitingParticipants)
                );
                dispatch(setAllowParticipants(false));
              }
            }, 8000);
            b_room_socket = io(url_configs.breakout_room_server);

            b_room_socket.emit(
              "on-join-user-in-middle",
              { data: data },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
          }
        }
      }, 2000);
    }, 2000);
  };

  //OPEN A GROUP
  const selectGroup = (e, group, id, obj) => {
    e.stopPropagation();
    let checkAlreadyJoinedGroup = localStorage.getItem(
      storage_configs.localStorage.sessions.current_group_id
    );
    if (checkAlreadyJoinedGroup) {
      if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
        closeCurrentGroup();
      }
      setTimeout(() => {
        if (obj && obj.group_id) {
          localStorage.setItem(
            storage_configs.localStorage.sessions.current_group_id,
            obj.group_id
          );
        }
        dispatch(setCurrentGroupName(group));
        dispatch(setselectedGroup(group));
        //if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
        joinToSubSession(obj);
        //}
      }, 4000);
    } else {
      if (obj && obj.group_id) {
        localStorage.setItem(
          storage_configs.localStorage.sessions.current_group_id,
          obj.group_id
        );
      }
      dispatch(setCurrentGroupName(group));
      dispatch(setselectedGroup(group));
      //if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
      joinToSubSession(obj);
      //}
    }
  };

  const maximizeGame = (e, gameLink, group, id, obj) => {
    e.stopPropagation();

    let checkAlreadyJoinedGroup = localStorage.getItem(
      storage_configs.localStorage.sessions.current_group_id
    );
    if (checkAlreadyJoinedGroup) {
      if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
        closeCurrentGroup();
      }
      setTimeout(() => {
        if (obj && obj.group_id) {
          localStorage.setItem(
            storage_configs.localStorage.sessions.current_group_id,
            obj.group_id
          );
        }
        dispatch(setCurrentGroupName(group));
        dispatch(setselectedGroup("max_game"));
        setGameLink(gameLink);
        //if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
        joinToSubSession(obj);
        //}
      }, 4000);
    } else {
      if (obj && obj.group_id) {
        localStorage.setItem(
          storage_configs.localStorage.sessions.current_group_id,
          obj.group_id
        );
      }
      dispatch(setCurrentGroupName(group));
      dispatch(setselectedGroup("max_game"));
      setGameLink(gameLink);
      //if (obj.group_id !== getExpertOpenedCurrentGroupId()) {
      joinToSubSession(obj);
      //}
    }
  };

  //CLOSE THE CURRENT OPENED GROUP
  if (leaveGroup == true) {
    dispatch(setLeaveGroup(false));
    ZoomService.stopAllRenderedVideo(zoomStream, zmClient, "expert");
    setTimeout(() => {
      setIsMute(true);
      closeCurrentGroup();
    }, 700);
  }

  const closeCurrentGroup = () => {
    localStorage.setItem(
      storage_configs.localStorage.sessions.break_out_session.isNotification,
      false
    );
    localStorage.removeItem("joined-subsession-id");
    dispatch(setselectedGroup(null));
    dispatch(setIsFluid(false));
    setTimeout(() => {
      if (zoomStream && zoomStream.isCapturingVideo()) {
        const session = zmClient.getSessionInfo();
        zoomStream.stopVideo();
        dispatch(setCameraStatus(false));
        stopRenderVideo(zoomStream, zmClient, true, session.userId);
      }
      let subsession;
      subsession = zmClient.getSubsessionClient();
      localStorage.removeItem(
        storage_configs.localStorage.sessions.current_group_id
      );
      subsession.leaveSubsession();
      dispatch(setCurrentGroupName(null));
      dispatch(setselectedGroup(null));
      // setTimeout(() => {
      //   ZoomService.renderParticipantVideoInExpertHorizontalView(zoomStream, zmClient, 0);
      // }, 3000);
      setTimeout(() => {
        localStorage.setItem(
          storage_configs.localStorage.sessions.break_out_session
            .isNotification,
          true
        );
      }, 8000);
      setTimeout(() => {
        let participants = zmClient.getAllUser();
        //let participants = zmClient.getParticipantsList();
        console.log("participants", participants);
        participants.forEach((user) => {
          if (user.bVideoOn && user.isHost) {
            renderVideo(zoomStream, zmClient, user.isHost, user.userId);
          }
        });
      }, 4000);
      //ZoomService.stopAudio(zoomStream);
      //ZoomService.muteAudio(zoomStream);
      //dispatch(setZoomAudio("mute"));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    }, 1000);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const getGameDetailByGameId = (id) => {
    let gameObj = games.gameList.filter((e) => e.id == id)[0];
    return gameObj;
  };

  const checkBreakoutRoomStatus = () => {
    if (slug) {
      b_room_socket = io(url_configs.breakout_room_server);

      b_room_socket.emit(
        "join",
        {
          name: getCurrentUserData().name,
          sessionSlug: slug,
          room: null,
          currentScreenData: null,
          role: 0,
        },
        (error) => {
          if (error) {
            alert(error);
          }
        }
      );

      b_room_socket.on("session-status", (message) => {
        if (message && message.isBreakoutStarted == true) {
          if (breakoutRoomStatus !== "STARTED") {
            dispatch(setBreakoutRoomStatus("STARTED"));
            dispatch(setDisplayOption("breakout_room"));
          }
        } else {
          dispatch(setBreakoutRoomStatus("STOPPED"));
        }
      });

      // b_room_socket.on("break-out-status", (obj) => {
      //   console.log("break-out-status :::")
      //   if (obj && obj.success == true && obj.status == 'STARTED') {
      //     if (breakoutRoomStatus !== 'STARTED') {
      //       dispatch(setBreakoutRoomStatus("STARTED"));
      //       dispatch(setDisplayOption("breakout_room"));
      //     }
      //   } else {
      //     console.log("stopBreakoutRoom :::")
      //     dispatch(setBreakoutRoomStatus("STOPPED"));
      //     stopBreakoutRoom();
      //   }
      // });

      b_room_socket.on("auto-play-game", (obj) => {
        let tempScreenData = JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.currentScreenData
          )
        );
        if (tempScreenData) {
          tempScreenData.screentype = "startgame";
          dispatch(setBreakoutCurrentScreenData(tempScreenData));
          localStorage.setItem(
            storage_configs.localStorage.sessions.currentScreenData,
            JSON.stringify(tempScreenData)
          );
        }
        // if(obj && obj.data && obj.data.game){
        //   let tempGameObj = getGameDetailByGameId(obj.data.game.id);
        //   b_room_socket.emit("capture-image", { captured_image: 'games', game: tempGameObj });
        // }
        //if(obj && obj.game && obj.game.id == 2){
        let tempGameObj = getGameDetailByGameId(obj.data.game.id);
        let tempList = JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.zoom.getSubsessionList
          )
        );
        //If leaner opened game
        tempList.forEach((element) => {
          element.image = "games";
          element.isGameOpened = true;

          //Spectate User Details
          let spectateUserDetail = {
            id: getCurrentUserData().id,
            name: getCurrentUserData().name,
            type: game_config.userType.teacher,
          };

          //Game Detail
          let gameDetail = {
            id: tempGameObj.id,
            name: tempGameObj.name,
          };

          //Group Detail
          let spectateGroupDetail = {
            id: element.group_id,
            name: "",
          };
          let current_stream_id = localStorage.getItem(
            storage_configs.localStorage.sessions.current_stream_id
          );

          let tempJsonObj = {
            spectateUserDetail: spectateUserDetail,
            gameDetail: gameDetail,
            spectateGroupDetail: spectateGroupDetail,
            userType: game_config.userType.spectator,
            gameSettings: {},
            slug: slug,
            courseStreamId: current_stream_id,
            isInSession: true,
          };
          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList,
            };

            UtilService.encryptJsonValue(model)
              .then((response) => {
                if (response && response.data) {
                  //let tempGameLink = tempGameObj.server + tempGameObj.routeName + '/?token=' + response.data.token;
                  let tempGameLink = tempGameObj.route + response.data.token;
                  element.gameLink = tempGameLink;
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.zoom.getSubsessionList,
            JSON.stringify(tempList)
          );
          dispatch(setCurrentSubSessionsList(tempList));
        }, 4000);
        returnToBreakoutRoom();
        //}
      });

      b_room_socket.on("reply-screen-detail", (obj) => {
        setSelectedUserBoard(null);
        if (obj && slug == obj.data.slug) {
          if (obj.data.session) {
            let cdata = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.zoom.getSubsessionList
              )
            );
            if (cdata && cdata.length > 0) {
              cdata.forEach((element) => {
                if (
                  element.group_id == obj.data.session.sessionDetail.group_id
                ) {
                  element.whiteboardLink = "";
                  element.whiteboardLink = openBRoomBoard(obj.data.url);
                }
              });
              setTimeout(() => {
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom.getSubsessionList,
                  JSON.stringify(cdata)
                );
                dispatch(setCurrentSubSessionsList(cdata));
              }, 100);
            }
            // setTimeout(() => {
            //   setSelectedUserBoard(obj.data.url);
            // }, 100);
          }
        }
      });

      b_room_socket.on("learner-current-board", (obj) => {
        if (obj && slug == obj.data.slug) {
          if (obj.data.user) {
            let cdata = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.zoom.getSubsessionList
              )
            );
            if (cdata && cdata.length > 0) {
              cdata.forEach((element) => {
                if (element.subsessionName.toLowerCase() == obj.data.group) {
                  if (element.userList.length > 0) {
                    element.userList.forEach((usrObj) => {
                      let usrId = Number(usrObj.displayName.split("-")[1]);
                      if (usrId == obj.data.user.id && usrObj.active == true) {
                        element.whiteboardLink = "";
                        element.whiteboardLink = openBRoomBoard(obj.data.url);
                      }
                    });
                  }
                }
              });
              setTimeout(() => {
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom.getSubsessionList,
                  JSON.stringify(cdata)
                );
                dispatch(setCurrentSubSessionsList(cdata));
              }, 600);
            }
          }
        }
      });

      // setInterval(() => {
      //   const data = {
      //     accessed_date: new Date().toLocaleString(),
      //     expert_id: getCurrentUserData().id,
      //     session_id: JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid))
      //   }
      //   b_room_socket.emit("on-broadcast-expert-status", { data:data  }, (error) => {
      //     if (error) {
      //       alert(error);
      //     }
      //   });
      // }, 5000);

      // b_room_socket.on("user-joined", (obj) => {
      //   console.log("user-joined", obj.data);
      //   //const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));
      //   if (getBreakoutSessionStatus()) {
      //     if(obj && obj.data && obj.data.slug == slug){
      //       console.log("user-joined", obj.data);
      //       if(obj.data.zoomDetail){
      //         var waitingParticipants = [];
      //         waitingParticipants = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.waitingParticipants));
      //         if(waitingParticipants && waitingParticipants.length >0){
      //           waitingParticipants = waitingParticipants.filter(e=>e.displayName !== obj.data.zoomDetail.displayName);
      //           waitingParticipants.push(obj.data.zoomDetail);
      //           localStorage.setItem(storage_configs.localStorage.sessions.zoom.waitingParticipants, JSON.stringify(waitingParticipants));
      //         }else{
      //           var tempWaitingParticipants = [];
      //           tempWaitingParticipants.push(obj.data.zoomDetail);
      //           localStorage.setItem(storage_configs.localStorage.sessions.zoom.waitingParticipants, JSON.stringify(tempWaitingParticipants));
      //         }
      //       }
      //       var userName = obj.data.name.split(' ')[0];
      //       enqueueSnackbar(userName + ' has joined and waiting in main session!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      //     }
      //   }
      // });

      //TEMPORARLY COMMETING CODE
      // b_room_socket.on("user-left", (obj) => {
      //   //const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));
      //   if (getBreakoutSessionStatus()) {
      //     if(obj && obj.data && obj.data.slug == slug){
      //       console.log("user-left", obj.data);
      //       var userName = obj.data.name.split(' ')[0];
      //       enqueueSnackbar(userName + ' has left!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      //     }
      //   }
      // });

      // setTimeout(() => {
      //   setActiveSubsessionUserDefault();
      // }, 3000);
    }
  };

  const checkOnlyBreakoutStatus = () => {
    if (slug) {
      b_room_socket = io(url_configs.breakout_room_server);

      b_room_socket.on("break-out-status", (obj) => {
        console.log("break-out-status :::");
        if (obj && obj.success == true && obj.status == "STARTED") {
          if (breakoutRoomStatus !== "STARTED") {
            dispatch(setBreakoutRoomStatus("STARTED"));
            dispatch(setDisplayOption("breakout_room"));
          }
        } else {
          console.log("stopBreakoutRoom :::");
          dispatch(setBreakoutRoomStatus("STOPPED"));
          stopBreakoutRoom();
        }
      });

      // setInterval(() => {
      //   const data = {
      //     accessed_date: new Date().toLocaleString(),
      //     expert_id: getCurrentUserData().id,
      //     session_id: JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid))
      //   }
      //   b_room_socket.emit("on-broadcast-expert-status", { data:data  }, (error) => {
      //     if (error) {
      //       alert(error);
      //     }
      //   });
      // }, 5000);
    }
  };
  const getScreensOfBreakoutRoom = (e) => {
    if (slug) {
      b_room_socket = io(url_configs.breakout_room_server);

      b_room_socket.emit(
        "join",
        {
          name: getCurrentUserData().name,
          sessionSlug: slug,
          room: null,
          currentScreenData: selectedCurriculam,
          role: 1,
        },
        (error) => {
          if (error) {
            alert(error);
          }
        }
      );

      b_room_socket.on("session-status", (message) => {
        console.log("session-status", message);
      });

      b_room_socket.on("auto-play-game", (obj) => {
        // if(obj && obj.data && obj.data.game){
        //   let tempGameObj = getGameDetailByGameId(obj.data.game.id);
        //   b_room_socket.emit("capture-image", { captured_image: 'games', game: tempGameObj });
        // }
        //if(obj && obj.game && obj.game.id == 2){
        let tempGameObj = getGameDetailByGameId(obj.data.game.id);
        let tempList = JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.zoom.getSubsessionList
          )
        );
        //If leaner opened game
        tempList.forEach((element) => {
          element.image = "games";
          element.isGameOpened = true;

          //Spectate User Details
          let spectateUserDetail = {
            id: getCurrentUserData().id,
            name: getCurrentUserData().name,
            type: game_config.userType.teacher,
          };

          //Game Detail
          let gameDetail = {
            id: tempGameObj.id,
            name: tempGameObj.name,
          };

          //Group Detail
          let spectateGroupDetail = {
            id: element.group_id,
            name: "",
          };

          let current_stream_id = localStorage.getItem(
            storage_configs.localStorage.sessions.current_stream_id
          );

          let tempJsonObj = {
            spectateUserDetail: spectateUserDetail,
            gameDetail: gameDetail,
            spectateGroupDetail: spectateGroupDetail,
            userType: game_config.userType.spectator,
            gameSettings: {},
            slug: slug,
            courseStreamId: current_stream_id,
            isInSession: true,
          };
          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList,
            };

            UtilService.encryptJsonValue(model)
              .then((response) => {
                if (response && response.data) {
                  //let tempGameLink = tempGameObj.server + tempGameObj.routeName + '/?token=' + response.data.token;
                  let tempGameLink = tempGameObj.route + response.data.token;
                  element.gameLink = tempGameLink;
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.zoom.getSubsessionList,
            JSON.stringify(tempList)
          );
          dispatch(setCurrentSubSessionsList(tempList));

          //setCurrentSubSessionsList([...currentSubSessionsList], tempList)
        }, 4000);
        returnToBreakoutRoom();
        //}
      });

      b_room_socket.on("reply-screen-detail", (obj) => {
        setSelectedUserBoard(null);
        if (obj && slug == obj.data.slug) {
          if (obj.data.session) {
            let cdata = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.zoom.getSubsessionList
              )
            );
            if (cdata && cdata.length > 0) {
              cdata.forEach((element) => {
                if (
                  element.group_id == obj.data.session.sessionDetail.group_id
                ) {
                  element.whiteboardLink = "";
                  element.whiteboardLink = openBRoomBoard(obj.data.url);
                }
              });
              setTimeout(() => {
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom.getSubsessionList,
                  JSON.stringify(cdata)
                );
                dispatch(setCurrentSubSessionsList(cdata));
              }, 100);
            }
          }
        }
      });

      b_room_socket.on("learner-current-board", (obj) => {
        if (obj && slug == obj.data.slug) {
          if (obj.data.user) {
            let cdata = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.zoom.getSubsessionList
              )
            );
            if (cdata && cdata.length > 0) {
              cdata.forEach((element) => {
                if (element.subsessionName.toLowerCase() == obj.data.group) {
                  if (element.userList.length > 0) {
                    element.userList.forEach((usrObj) => {
                      let usrId = Number(usrObj.displayName.split("-")[1]);
                      if (usrId == obj.data.user.id) {
                        element.whiteboardLink = "";
                        element.whiteboardLink = openBRoomBoard(obj.data.url);
                      }
                    });
                  }
                }
              });
              setTimeout(() => {
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom.getSubsessionList,
                  JSON.stringify(cdata)
                );
                dispatch(setCurrentSubSessionsList(cdata));
              }, 600);
            }
          }
        }
      });

      // setInterval(() => {
      //   const data = {
      //     accessed_date: new Date().toLocaleString(),
      //     expert_id: getCurrentUserData().id,
      //     session_id: JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid))
      //   }
      //   b_room_socket.emit("on-broadcast-expert-status", { data:data  }, (error) => {
      //     if (error) {
      //       alert(error);
      //     }
      //   });
      // }, 5000);

      // setTimeout(() => {
      //   setActiveSubsessionUserDefault();
      // }, 3000);
    }
  };

  const sendGameDetailToLearner = (game) => {
    dispatch(setAutoPlayGameRouteName(game));
    //if(game.id !== 2){
    // b_room_socket.emit("set-auto-play-game", { currentSession: 1, game: game }, (error) => {
    //   if (error) {
    //     console.log(error)
    //   }
    // });
    //}
  };

  //STOP BREAK OUT ROOM
  const stopBreakoutRoom = () => {
    dispatch(setStopBreakoutLoading(true));
    stopBreakoutLoading("1");

    localStorage.removeItem(
      storage_configs.localStorage.sessions.zoom.getSubsessionList
    );
    localStorage.removeItem(
      storage_configs.localStorage.sessions.currentScreenData
    );
    localStorage.removeItem(
      storage_configs.localStorage.sessions.zoom.waitingParticipants
    );
    localStorage.removeItem("joined-subsession-id");
    joinToGeneralChat();
    removeOpenedChatGroup();

    let subsession;
    subsession = zmClient.getSubsessionClient();
    setTimeout(() => {
      subsession.closeAllSubsessions();
      setTimeout(() => {
        changeBreakoutSessionStatus(false);
        dispatch(setCurrentSubSessionsList(null));
        dispatch(setselectedGroup(null));
        dispatch(setDisplayOption(null));
        dispatch(setBreakoutRoomStatus("STOPPED"));

        dispatch(setStartSelectedBreakoutRoom(false));
        dispatch(setSelectedCurriculam(null));
        dispatch(setSelectedCurriculamData(null));
        window.location.reload();
        dispatch(setStopBreakoutLoading(false));
        stopBreakoutLoading("0");
      }, 3000);
    }, 2000);
    //b_room_socket.emit("disconnect");
  };
  //#endregion

  //#region GAME SETTINGS
  const sendToGameSettings = (e) => {
    dispatch(setDisplayOption("game_settings"));
  };

  const gameSettings = (game, index, gtype) => {
    //Expert Detail
    let expertDetail = {
      id: getCurrentUserData().id,
      name: getCurrentUserData().name,
    };

    //Game Detail
    let gameDetail = {
      id: game.id,
      name: game.name,
    };

    //Group Detail
    let groupIds = [];
    let groupDetail = [];
    groups.forEach((element) => {
      let gId = 0;
      gId = element.coursegroupid;
      groupIds.push(gId);

      let groupsObj = {
        id: element.coursegroupid,
        name: element.groupname,
        students: [],
      };
      element.members.forEach((studentObj) => {
        let tempStudentObj = {
          id: studentObj.id,
          name: studentObj.firstname,
        };
        groupsObj.students.push(tempStudentObj);
      });
      groupDetail.push(groupsObj);
    });

    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );

    let tempJsonObj = {
      expertDetail: expertDetail,
      gameDetail: gameDetail,
      groupDetail: groupDetail,
      userType: game_config.userType.teacher,
      slug: slug,
      courseStreamId: current_stream_id,
      isInSession: true,
    };

    if (tempJsonObj) {
      let tempJsonValueList = [];
      tempJsonValueList.push(tempJsonObj);

      let model = {
        jsonvalue: tempJsonValueList,
      };

      UtilService.encryptJsonValue(model)
        .then((response) => {
          if (response && response.data) {
            if (gtype == "gameList") {
              //let tempGameList = [...gameList];
              let tempGameList = [];
              //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
              gameList.forEach((device, index) => {
                tempGameList[index] = { ...device };
              });
              tempGameList[index].token = response.data.token;
              tempGameList[index].is_selected = true;
              setGameList(tempGameList);
            } else {
              dispatch(setBreakoutGameList(null));
              let tempGameList = [];
              //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
              breakoutGameList.forEach((device, index) => {
                tempGameList[index] = { ...device };
              });
              tempGameList[index].token = response.data.token;
              tempGameList[index].is_selected = true;
              dispatch(setBreakoutGameList(tempGameList));
            }
            sendGameDetailToLearner(game);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const returnToBreakoutRoom = (e) => {
    dispatch(setDisplayOption("breakout_room"));
    dispatch(setselectedGroup(""));
    dispatch(setBreakoutCurrentScreenData(""));
  };
  //#endregion

  const onClickWhiteboard = (e, gName) => {
    e.stopPropagation();
    alert("Click on " + gName + " to open the whiteboard");
  };

  const displayOnlyFirstName = (dName) => {
    if (dName) {
      return dName.split(" ")[0];
    }
  };

  const openBRoomBoard = (wLink) => {
    if (wLink) {
      let boardSplit = wLink.split("#room=");
      let firstOffBrd = boardSplit[0] + "&bRoom=1#room=";
      return firstOffBrd + boardSplit[1];
    }
  };

  const renderSwitch = () => {
    switch (sideMenu) {
      case "course":
        return <CourseList />;
      case "docs":
        return <div></div>;
      case "notes":
        return <NotesList />;
      case "chat":
        dispatch(setIsChatGeneralNotification(false));
        localStorage.removeItem(
          storage_configs.localStorage.sessions.chatGeneralNotification
        );
        let msgs = [...messages];
        msgs = getChats();
        if (msgs) {
          let openedGrp = getOpenedChatGroup();
          if (openedGrp) {
            msgs = msgs.filter(
              (e) => e.group && e.group.coursegroupid == openedGrp.coursegroupid
            );
          } else {
            msgs = msgs.filter((e) => !e.group);
          }
          console.log(msgs);
          //msgs = getUnique(msgs, 'text');
          msgs = msgs.reverse();
        }
        // return <ChatList />;
        return (
          <div>
            {breakoutRoomStatus == "STARTED" ? (
              <div className="e-host-course-list-v2-group">
                {!isOpenGroupChat ? (
                  <div>
                    {groups?.map((gObj, gIndex) => {
                      return (
                        <div
                          key={"bird-eye-view-grp-" + gIndex}
                          className="group-list-container-hover"
                          onClick={(e) => openGroupChat(gObj)}
                        >
                          <div className="group-list-container">
                            <div>
                              <span className="group-name">
                                {gObj.groupname}
                              </span>
                              <div className="group-member-desc mt-1">
                                <img src={groupPeopleImg}></img>
                                <span className="ps-2">
                                  {gObj.members.length}
                                </span>
                                <span className="ps-2">
                                  {getGroupMembersAsCommaSeparated(
                                    gObj.members
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>
                              {showChatNofigication == true ? (
                                <div>
                                  {checkIsAnyNotification(gObj.coursegroupid)
                                    .length > 0 ? (
                                    <span className="chat-group-badge-notification">
                                      {
                                        checkIsAnyNotification(
                                          gObj.coursegroupid
                                        ).length
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* {showChatNofigication == true || checkIsAnyNotification(gObj.coursegroupid).length > 0? <span className="chat-group-badge-notification">{checkIsAnyNotification(gObj.coursegroupid).length}</span> : ""} */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="selected-group-main-v2">
                    <div className="group-list-container selected-group-container">
                      <div onClick={(e) => closeGroupChat(e)}>
                        <img src={leftArrow}></img>
                      </div>
                      <div className="ms-2">
                        <span className="group-name">
                          {openedGroup?.groupname}
                        </span>
                        <div className="group-member-desc mt-1">
                          <img src={groupPeopleImg}></img>
                          <span className="ps-2">
                            {openedGroup?.members.length}
                          </span>
                          <span className="ps-2">
                            {getGroupMembersAsCommaSeparated(
                              openedGroup?.members
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="expert-msg-list-v1-inside-b-room-v2">
                      {msgs?.map((val, i) => {
                        return (
                          <div key={"expert-msg-list-a" + i}>
                            <div className="chat-my-msg-main">
                              {val.user === getCurrentUserData().name ? (
                                <div className="chat-my-msg-container">
                                  <div className="my-name-container">
                                    <span className="my-name">
                                      {val.user} (Teacher)
                                    </span>
                                  </div>
                                  <div className="rvr-msg-container">
                                    <span className="rvr-msg">
                                      {findTypeOfMessage(val.text) !== true ? (
                                        val.text
                                      ) : (
                                        <img src={val.text} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="chat-rvr-msg-container">
                                  <div className="rvr-name-container">
                                    <span className="rvr-name">{val.user}</span>
                                  </div>
                                  <div className="rvr-msg-container">
                                    <span className="rvr-msg">
                                      {findTypeOfMessage(val.text) !== true ? (
                                        val.text
                                      ) : (
                                        <img src={val.text} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="expert-msg-list-v2">
                {msgs?.map((val, i) => {
                  return (
                    <div key={"expert-msg-list-b" + i}>
                      <div className="chat-my-msg-main">
                        {val.user === getCurrentUserData().name ? (
                          <div className="chat-my-msg-container">
                            <div className="my-name-container">
                              <span className="my-name">
                                {val.user} (Teacher)
                              </span>
                            </div>
                            <div className="rvr-msg-container">
                              <span className="rvr-msg">
                                {findTypeOfMessage(val.text) !== true ? (
                                  val.text
                                ) : (
                                  <img src={val.text} />
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="chat-rvr-msg-container">
                            <div className="rvr-name-container">
                              <span className="rvr-name">{val.user}</span>
                            </div>
                            <div className="rvr-msg-container">
                              <span className="rvr-msg">
                                {findTypeOfMessage(val.text) !== true ? (
                                  val.text
                                ) : (
                                  <img src={val.text} />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  const calculateGalleryViewGrid = (users) => {
    let getUsersWithoutHost = users.filter((e) => e.isHost !== true);
    if (getUsersWithoutHost && getUsersWithoutHost.length == 1) {
      return `col-lg-12 h-100`;
    } else if (getUsersWithoutHost && getUsersWithoutHost.length == 2) {
      return `col-lg-6 h-100`;
    } else {
      return "col-lg-4 gallery-height";
    }
  };

  const onCheckLearnerQuizProgress = (e, obj) => {
    console.log("quser", obj);
    let sendData = {
      slug: slug,
      userDetail: obj,
    };

    b_room_socket.emit(
      "get-learner-quiz-progress",
      { data: sendData },
      (error) => {
        if (error) {
          console.log(error);
        }
      }
    );
    setRefreshQuizUser(obj);
    handleOpenLearnerQuizProgress();
  };

  const onRefreshQuizProgress = (e) => {
    let sendData = {
      slug: slug,
      userDetail: refreshQuizUser,
    };

    b_room_socket.emit(
      "get-learner-quiz-progress",
      { data: sendData },
      (error) => {
        if (error) {
          console.log(error);
        }
      }
    );
  };

  const handleOpenLearnerQuizProgress = () => {
    setOpenLearnerQuizProgress(true);
    b_room_socket.on("on-send-learner-quiz-progress", (obj) => {
      console.log("on-send-learner-quiz-progress", obj);
      if (obj && obj.data.slug == slug) {
        setLearnerQuizResults(obj.data);
      }
    });
  };
  /** CLOSE TEMPLATE GAME POPUP */

  const handleCloseLearnerQuizProgress = () =>
    setOpenLearnerQuizProgress(false);

  const getOnlyParticipants = (pList) => {
    const itemsPerPage = 5;
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.e_participants_h_idx
      )
    );
    if (pList && pList.length > 0) {
      const tempList = participants.filter((e) => !e.isHost && !e.isManager);
      const startIndex = (curIndex > 0 ? curIndex : 0) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const displayedItems = tempList.slice(startIndex, endIndex);
      return displayedItems;
    } else {
      return [];
    }
  };

  const getOnlyParticipantsLength = (pList) => {
    const itemsPerPage = 5;
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.e_participants_h_idx
      )
    );
    if (pList && pList.length > 0) {
      const tempList = participants.filter((e) => !e.isHost && !e.isManager);
      const startIndex = (curIndex > 0 ? curIndex : 0) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const displayedItems = tempList.slice(startIndex, endIndex);
      return {
        currentPageUsers: displayedItems.length,
        balanceUsers: tempList.length - displayedItems.length,
      };
      //return displayedItems.length;
    } else {
      return 0;
    }
  };

  const nextVideos = () => {
    var curIndex = localStorage.getItem("gallery-video-page-index");
    if (curIndex) {
      ZoomService.renderParticipantVideoInGallery(
        zoomStream,
        zmClient,
        Number(curIndex) + 1
      );
    } else {
      ZoomService.renderParticipantVideoInGallery(zoomStream, zmClient, 0);
    }
  };
  const previousVideos = () => {
    var curIndex = localStorage.getItem("gallery-video-page-index");
    if (curIndex && Number(curIndex) == 0) {
      ZoomService.renderParticipantVideoInGallery(zoomStream, zmClient, 0);
    } else if (curIndex) {
      ZoomService.renderParticipantVideoInGallery(
        zoomStream,
        zmClient,
        Number(curIndex) - 1
      );
    } else {
      ZoomService.renderParticipantVideoInGallery(zoomStream, zmClient, 0);
    }
  };

  const nextParticipantsVideos = () => {
    ZoomService.stopAllRenderedVideo(zoomStream, zmClient, "expert");
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.e_participants_h_idx
      )
    );
    if (curIndex >= 0) {
      ZoomService.renderParticipantVideoInExpertHorizontalView(
        zoomStream,
        zmClient,
        curIndex + 1
      );
      setE_participants_h_idx(curIndex + 1);
    } else {
      ZoomService.renderParticipantVideoInExpertHorizontalView(
        zoomStream,
        zmClient,
        0
      );
      setE_participants_h_idx(0);
    }
  };
  const previousParticipantsVideos = () => {
    ZoomService.stopAllRenderedVideo(zoomStream, zmClient, "expert");
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.e_participants_h_idx
      )
    );
    if (curIndex == 0) {
      ZoomService.renderParticipantVideoInExpertHorizontalView(
        zoomStream,
        zmClient,
        0
      );
      setE_participants_h_idx(0);
    } else if (curIndex > 0) {
      ZoomService.renderParticipantVideoInExpertHorizontalView(
        zoomStream,
        zmClient,
        curIndex - 1
      );
      setE_participants_h_idx(curIndex - 1);
    } else {
      ZoomService.renderParticipantVideoInExpertHorizontalView(
        zoomStream,
        zmClient,
        0
      );
      setE_participants_h_idx(0);
    }
  };

  const check_e_participants_h_idx = () => {
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.e_participants_h_idx
      )
    );
    return curIndex;
  };

  const generateBirdEyeViewBoardLink = (link, obj) => {
    if (link && obj) {
      let beforeComma = link.split(",")[0];
      beforeComma = beforeComma.replace(
        "-div-" + slugify(obj.subsessionName),
        ""
      );
      beforeComma =
        beforeComma +
        "-div-" +
        slugify(obj.subsessionName) +
        whiteboardConfig.key;
      return beforeComma;
    }
  };

  const isOdd = (value) => {
    if (value % 2 == 0) {
      return "";
    } else {
      return "pe-0";
    }
  };

  return (
    <div className="content_session h-100">
      <div
        className={`e-session-container h-100 ms-0 me-0 ${
          !isGalleryView ? `` : `gallery-view-hide`
        }`}
      >
        {/* LEFT SIDE PANEL */}
        <div
          className={`e-session-left-panel-v2 p-0 ${
            !isFluid
              ? "e-session-left-panel-show-v2"
              : "e-session-left-panel-hide-v2"
          }`}
        >
          <div className="e-class_room_session_1-v2">
            {participants.length > 0 &&
              participants?.map((participant) =>
                participant.isHost ? (
                  <div
                    key={`e-host-key-` + participant.userId}
                    className="host-container e-session-host-container-v2"
                  >
                    <div
                      className={
                        !participant.bVideoOn
                          ? "host-image-container-display-hide"
                          : "e-host-image-container-v2"
                      }
                    >
                      <video
                        id="e-self-view-video"
                        className="e-self-video-border"
                      ></video>
                      <canvas
                        id="e-self-view-canvas"
                        className="e-self-video-border"
                      ></canvas>
                      {/* <div className="expert-self-video-enabled-text">Video enabled</div> */}
                      <div className="e-host-label-v2">
                        {participant?.muted == true ||
                        participant?.muted == undefined ? (
                          <FaMicrophoneSlash className="participant-item-card-footer-icon" />
                        ) : (
                          <FaMicrophone className="participant-item-card-footer-icon" />
                        )}
                        {displayOnlyFirstName(participant.displayName)} (Expert)
                      </div>
                    </div>
                    {!participant.bVideoOn ? (
                      <div className="e-host-image-container-v2">
                        <div className="e-host-rouded-video-off-main">
                          <div className="e-host-rounded-video-off">
                            {getCurrentUserData().document_path ? (
                              <div className="e-host-rounded-video-off-div-image-in">
                                <span className="host-video-off-initial-image-in">
                                  <img
                                    src={getCurrentUserData().document_path}
                                    alt="Profile"
                                  />
                                </span>
                              </div>
                            ) : (
                              <div className="e-host-rounded-video-off-div">
                                <span className="host-video-off-initial">
                                  {getInitialsFromFullName(
                                    participant.displayName
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="e-host-label-v2">
                          {participant?.muted == true ||
                          participant?.muted == undefined ? (
                            <FaMicrophoneSlash className="participant-item-card-footer-icon" />
                          ) : (
                            <FaMicrophone className="participant-item-card-footer-icon" />
                          )}
                          {displayOnlyFirstName(participant.displayName)}{" "}
                          (Expert)
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )
              )}
            <div className="e-apps-list-main-buttons">
              <div className="d-flex align-items-center e-apps-list-main ms-3">
                {/* <div className=" me-auto"> Courses </div> */}
                {conditional_configs.appTypeList.map((sObj, sIndex) => {
                  return (
                    <span
                      className={`e-apps-list ${
                        sObj.id == sideMenu
                          ? "e-apps-list-active"
                          : "e-apps-list-in-active"
                      } ${
                        conditional_configs.appTypeList.length - 1 == sIndex
                          ? ""
                          : "me-1"
                      }`}
                      key={"expert-apps-list-" + sIndex}
                      id={"e-apps-list" + sIndex}
                      value={sObj.id}
                      onClick={(e) => setSidebarMenu(e, sObj.id)}
                    >
                      {sObj.value}
                    </span>
                  );
                })}
              </div>
            </div>

            {renderSwitch()}
            {sideMenu == "chat" &&
            (isOpenGroupChat || getBreakoutSessionStatus() != true) ? (
              <div className="e-session-chat-text-box-v2">
                <form className="mt-1" action="" onSubmit={handleChatSubmit}>
                  <div className="input-group mb-1 e-send-input-box-v2">
                    <span
                      className="pe-2 send-screenshot"
                      onClick={(e) => sendScreenshot(e)}
                    >
                      {getIcon(icons_config.screenshot)}
                    </span>
                    <input
                      type="text"
                      className="form-control send-input-v2"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type anything and hit enter"
                    />
                    <div className="input-group-append-v2">
                      <button
                        className="btn btn-outline-secondary"
                        type="sumit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : sideMenu == "chat" ? (
              <div className="e-session-chat-text-box-v2">
                <form
                  className=""
                  action="mt-1"
                  onSubmit={handleBroadcastChatSubmit}
                >
                  <div className="input-group mb-1 e-send-input-box-v2">
                    <span
                      className="pe-2 send-screenshot"
                      onClick={(e) => sendScreenshot(e)}
                    >
                      {getIcon(icons_config.screenshot)}
                    </span>
                    <input
                      type="text"
                      className="form-control send-input-v2"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type anything and hit enter"
                    />
                    <div className="input-group-append-v2">
                      <button
                        className="btn btn-outline-secondary"
                        type="sumit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}

            {/* {sideMenu == 'chat' && (!isOpenGroupChat || getBreakoutSessionStatus() == true) ? 
              <div>
              <form className="mt-2" action="" onSubmit={handleBroadcastChatSubmit}>
                <div className="input-group mb-3 send-input-box-v2">
                  <span className="pe-2 send-screenshot" onClick={(e) => sendScreenshot(e)}>
                    {getIcon(icons_config.screenshot)}
                  </span>
                  <input type="text" className="form-control send-input" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type anything and hit enter" />
                  <div className="input-group-append-v2">
                    <button className="btn btn-outline-secondary" type="sumit">Send</button>
                  </div>
                </div>
              </form>
            </div>
              :""} */}

            {sideMenu == "course" ? (
              <div>
                {/* {displayOption == 'breakout_room' && breakoutRoomStatus == 'STARTED' ?
                    <div className="p-2 text-center" onClick={(e) => sendToGameSettings(e)}>
                      <span className="breakout-room-button">Game Settings</span>
                    </div> : ""} */}
                {/* {displayOption == 'game_settings' && currentSubSessionsList ?
                    <div className="p-2 text-center"  onClick={(e) => returnToBreakoutRoom(e)}>
                      <span className="breakout-room-button" >Return Breakout Session</span>
                    </div> : ""} */}

                {
                  displayOption != "breakout_room" &&
                  breakoutRoomStatus != "STARTED" ? (
                    <div>
                      {/* {isLoading ?
                        <div className="p-2 text-center">
                          <span className="breakout-room-button loading-bg">Sending to Breakout room...</span>
                        </div>
                        :
                        <div className="p-2 text-center" onClick={(e) => sendToBreakoutRoom()}>
                          <span className="breakout-room-button">Send to Breakout room</span>
                        </div>
                      } */}
                    </div>
                  ) : (
                    ""
                  )
                  // <div className="p-2 text-center" onClick={(e) => stopBreakoutRoom(e)}>
                  //   <span className="breakout-room-button" >Stop Breakout Session</span>
                  // </div>
                }
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <button onClick={_handleDecrement}>Class room</button> */}
        </div>

        {/* RIGHT SIDE CONTENT PANEL */}
        <div
          ref={imageRef}
          className={
            !isFluid
              ? "e-session-right-panel-v2 h-100"
              : "e-session-right-panel-100 h-100"
          }
        >
          {isScreenShareOn ? (
            <div
              className={
                getOnlyParticipants(participants) &&
                getOnlyParticipants(participants).length > 0
                  ? `e-selected-course-view-75`
                  : `e-selected-course-view-100`
              }
            >
              <canvas
                id="screen-share-canvas"
                className="learner_share_view_canvas h-100"
              ></canvas>
            </div>
          ) : (
            <div
              className={
                getOnlyParticipants(participants) &&
                getOnlyParticipants(participants).length > 0
                  ? `e-selected-course-view-75`
                  : `e-selected-course-view-100`
              }
            >
              {/* BREAKOUT ROOM - BIRD VIEW */}
              {displayOption == "breakout_room" ? (
                <div className="h-100">
                  {isLoading ? (
                    <LoadingSpinner message="Please wait. Starting the breakout room!" />
                  ) : (
                    <div className="h-100">
                      {breakoutCurrentScreenData &&
                      breakoutCurrentScreenData?.selectedCurriculamType ===
                        conditional_configs.curriculam_types.games ? (
                        <div className="white-boards-tab h-100">
                          <div
                            className="p-2 text-center back-to-bird-eye-view"
                            onClick={(e) => returnToBreakoutRoom(e)}
                          >
                            <span className="breakout-room-button">
                              Back to Birdeye view
                            </span>
                          </div>
                          <Row className="m-0 h-100">
                            {breakoutGameList?.map((gObj, gIndex) => {
                              return (
                                <Col
                                  className="h-100"
                                  sm={12}
                                  key={`e-game-setting-key-` + gIndex}
                                >
                                  <div className="e-game-grid-breakout m-0">
                                    {!gObj.is_selected ? (
                                      <Card
                                        className="h-100"
                                        onClick={(e) =>
                                          gameSettings(
                                            gObj,
                                            gIndex,
                                            "breakoutGameList"
                                          )
                                        }
                                        style={{
                                          backgroundImage: `url(${gObj.img})`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "auto",
                                          backgroundPosition: "center",
                                        }}
                                      >
                                        <div className="game-title">
                                          {gObj.name}
                                        </div>
                                      </Card>
                                    ) : (
                                      <div className="h-100 embed-code-style-v2-e">
                                        <iframe
                                          className="h-100 w-100"
                                          src={gObj.route + gObj.token}
                                        ></iframe>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      ) : (
                        <div className="white-boards-tab h-100">
                          {
                            !selectedGroup ? (
                              <Row className="m-0 h-100">
                                {currentSubSessionsList?.map((sObj, sIndex) => {
                                  return (
                                    <div
                                      key={"sub-sessions-list-" + sIndex}
                                      className={`expert-bird-eye-view-container m-0 ps-0 ${isOdd(
                                        sIndex
                                      )}`}
                                    >
                                      {/* BIRD EYE VIEW - CHECK IS GAMES - SPECTATE MODE */}
                                      {sObj.image == "games" ? (
                                        <Card
                                          body
                                          className={
                                            selectedGroup ===
                                            sObj.subsessionName
                                              ? "group-item-expert-bird-view-active"
                                              : "group-item-expert-bird-view"
                                          }
                                        >
                                          <div className="group-title">
                                            {sObj.subsessionName}
                                          </div>
                                          <iframe
                                            id={"b-view-game" + sIndex}
                                            className="w-100 h-100"
                                            src={sObj.gameLink}
                                          ></iframe>
                                          <div className="group-actions-main">
                                            {!getExpertOpenedCurrentGroupId() ||
                                            getExpertOpenedCurrentGroupId() !==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={muteImg}
                                                onClick={(e) =>
                                                  changeToUnMute(
                                                    e,
                                                    sObj.subsessionName,
                                                    sObj.subsessionId,
                                                    sObj
                                                  )
                                                }
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                            {!isMute &&
                                            getExpertOpenedCurrentGroupId() ==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={unMuteImg}
                                                onClick={(e) => changeToMute(e)}
                                              ></img>
                                            ) : (
                                              ""
                                            )}

                                            <img
                                              className="group-actions"
                                              placeholder="Join"
                                              src={joinUserImg}
                                              onClick={(e) =>
                                                maximizeGame(
                                                  e,
                                                  sObj.gameLink,
                                                  sObj.subsessionName,
                                                  sObj.subsessionId,
                                                  sObj
                                                )
                                              }
                                            ></img>
                                          </div>
                                          <div className="break-out-room-users">
                                            <Stack direction="row" spacing={1}>
                                              {getUnique(
                                                sObj?.userList,
                                                "displayName"
                                              )?.map((uObj, uIndex) => {
                                                return (
                                                  <span
                                                    key={
                                                      "sub-sessions-board-users-" +
                                                      uIndex
                                                    }
                                                    className="b-users"
                                                  >
                                                    {getInitialsFromFullName(
                                                      uObj.displayName
                                                    )}
                                                  </span>
                                                );
                                              })}
                                            </Stack>
                                          </div>
                                        </Card>
                                      ) : // BIRD EYE VIEW - WHITEBOARD
                                      sObj.image == "whiteboard" ? (
                                        <Card
                                          body
                                          className={
                                            selectedGroup ===
                                            sObj.subsessionName
                                              ? "group-item-expert-bird-view-active"
                                              : "group-item-expert-bird-view"
                                          }
                                        >
                                          <div
                                            className="group-title"
                                            onClick={(e) =>
                                              selectGroup(
                                                e,
                                                sObj.subsessionName,
                                                sObj.subsessionId,
                                                sObj
                                              )
                                            }
                                          >
                                            {sObj.subsessionName}
                                          </div>

                                          <div id="wrap">
                                            <div
                                              className="whiteboard-locked-morph-div-expert-b-view"
                                              onClick={(e) =>
                                                onClickWhiteboard(
                                                  e,
                                                  sObj.subsessionName
                                                )
                                              }
                                            ></div>
                                            <iframe
                                              id={"scaled-frame-" + sIndex}
                                              className="scaled-frame"
                                              frameBorder="0"
                                              src={generateBirdEyeViewBoardLink(
                                                sObj.whiteboardLink,
                                                sObj
                                              )}
                                            ></iframe>
                                            {/* <iframe id={"scaled-frame" + sIndex} className="scaled-frame" frameBorder="0" src={sObj.whiteboardLink}></iframe> */}
                                          </div>
                                          <div className="group-actions-main">
                                            {!getExpertOpenedCurrentGroupId() ||
                                            getExpertOpenedCurrentGroupId() !==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={muteImg}
                                                onClick={(e) =>
                                                  changeToUnMute(
                                                    e,
                                                    sObj.subsessionName,
                                                    sObj.subsessionId,
                                                    sObj
                                                  )
                                                }
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                            {!isMute &&
                                            getExpertOpenedCurrentGroupId() ==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={unMuteImg}
                                                onClick={(e) => changeToMute(e)}
                                              ></img>
                                            ) : (
                                              ""
                                            )}

                                            <img
                                              className="group-actions"
                                              placeholder="Join"
                                              src={joinUserImg}
                                              onClick={(e) =>
                                                selectGroup(
                                                  e,
                                                  sObj.subsessionName,
                                                  sObj.subsessionId,
                                                  sObj
                                                )
                                              }
                                            ></img>
                                          </div>
                                          <div className="break-out-room-users">
                                            <Stack direction="row" spacing={1}>
                                              {getUnique(
                                                sObj?.userList,
                                                "displayName"
                                              )?.map((uObj, uIndex) => {
                                                return (
                                                  <span
                                                    key={
                                                      "break-out-room-users-w-" +
                                                      uIndex
                                                    }
                                                    className={
                                                      uObj.active &&
                                                      uObj.active === true
                                                        ? "b-users b-users-active"
                                                        : "b-users"
                                                    }
                                                    onClick={(e) =>
                                                      setActiveSubsessionUserEvent(
                                                        e,
                                                        sIndex,
                                                        uIndex
                                                      )
                                                    }
                                                  >
                                                    {getInitialsFromFullName(
                                                      uObj.displayName
                                                    )}
                                                  </span>
                                                );
                                              })}
                                            </Stack>
                                          </div>
                                        </Card>
                                      ) : // BIRD EYE VIEW - QUIZ
                                      sObj.image == "quiz" ? (
                                        <Card
                                          body
                                          className={
                                            selectedGroup ===
                                            sObj.subsessionName
                                              ? "group-item-expert-bird-view-active"
                                              : "group-item-expert-bird-view"
                                          }
                                        >
                                          <div className="group-title">
                                            {sObj.subsessionName}
                                          </div>
                                          <div className="group-actions-main">
                                            {!getExpertOpenedCurrentGroupId() ||
                                            getExpertOpenedCurrentGroupId() !==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={muteImg}
                                                onClick={(e) =>
                                                  changeToUnMute(
                                                    e,
                                                    sObj.subsessionName,
                                                    sObj.subsessionId,
                                                    sObj
                                                  )
                                                }
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                            {!isMute &&
                                            getExpertOpenedCurrentGroupId() ==
                                              sObj.group_id ? (
                                              <img
                                                className="group-actions"
                                                src={unMuteImg}
                                                onClick={(e) => changeToMute(e)}
                                              ></img>
                                            ) : (
                                              ""
                                            )}

                                            {/* <img className='group-actions' placeholder='Join' src={joinUserImg} onClick={(e) => selectGroup(e, sObj.subsessionName, sObj.subsessionId, sObj)} ></img> */}
                                          </div>
                                          <div className="quiz-time-center-text">
                                            <span>
                                              <img src={assets.quiz}></img>
                                              Quiz Time
                                            </span>
                                          </div>
                                          <div className="break-out-room-users">
                                            <Stack direction="row" spacing={1}>
                                              {getUnique(
                                                sObj?.userList,
                                                "displayName"
                                              )?.map((uObj, uIndex) => {
                                                return (
                                                  <span
                                                    key={
                                                      "break-out-room-users-q-" +
                                                      uIndex
                                                    }
                                                    className="b-users"
                                                    onClick={(e) =>
                                                      onCheckLearnerQuizProgress(
                                                        e,
                                                        uObj
                                                      )
                                                    }
                                                  >
                                                    {getInitialsFromFullName(
                                                      uObj.displayName
                                                    )}
                                                  </span>
                                                );
                                              })}
                                            </Stack>
                                          </div>
                                        </Card>
                                      ) : (
                                        <Card
                                          style={{
                                            backgroundImage: `url(${sObj.image})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                          }}
                                          body
                                          className={
                                            selectedGroup ===
                                            sObj.subsessionName
                                              ? "group-item-expert-bird-view-active"
                                              : "group-item-expert-bird-view"
                                          }
                                        >
                                          <div
                                            className="group-title"
                                            onClick={(e) =>
                                              selectGroup(
                                                e,
                                                sObj.subsessionName,
                                                sObj.subsessionId,
                                                sObj
                                              )
                                            }
                                          >
                                            {sObj.subsessionName}
                                          </div>
                                          <div className="group-actions-main">
                                            {isMute ? (
                                              <img
                                                className="group-actions"
                                                src={muteImg}
                                                onClick={(e) =>
                                                  changeToUnMute()
                                                }
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                            {!isMute ? (
                                              <img
                                                className="group-actions"
                                                src={unMuteImg}
                                                onClick={(e) => changeToMute()}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                            <img
                                              className="group-actions"
                                              placeholder="Join"
                                              src={joinUserImg}
                                              onClick={(e) =>
                                                selectGroup(
                                                  e,
                                                  sObj.subsessionName,
                                                  sObj.subsessionId,
                                                  sObj
                                                )
                                              }
                                            ></img>
                                          </div>
                                          <div className="break-out-room-users">
                                            <Stack direction="row" spacing={1}>
                                              {getUnique(
                                                sObj?.userList,
                                                "displayName"
                                              )?.map((uObj, uIndex) => {
                                                return (
                                                  <span
                                                    key={
                                                      "break-out-room-users-o-" +
                                                      uIndex
                                                    }
                                                    className="b-users"
                                                  >
                                                    {getInitialsFromFullName(
                                                      uObj.displayName
                                                    )}
                                                  </span>
                                                );
                                              })}
                                            </Stack>
                                          </div>
                                        </Card>
                                      )}
                                    </div>
                                  );
                                })}
                              </Row>
                            ) : // MAXIMIZE GAME SCREEN
                            selectedGroup == "max_game" ? (
                              <div>
                                {/* <div className="p-2 text-center" onClick={(e) => returnToBreakoutRoom(e)}>
                                    <span className="breakout-room-button" >Return Breakout Session</span>
                                  </div> */}
                                <Row className="m-0 max_game">
                                  <Col className="h-100" sm={12}>
                                    <iframe
                                      className="h-100 w-100"
                                      src={gameLink}
                                    ></iframe>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <div className="h-100">
                                {breakoutCurrentScreenData ? (
                                  <BreakoutWhiteboard></BreakoutWhiteboard>
                                ) : (
                                  <GroupSpace></GroupSpace>
                                )}
                              </div>
                            )
                            // <GroupSpace></GroupSpace>
                          }
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : // GAME SETTINGS
              displayOption == "game_settings" ? (
                <div className="white-boards-tab">
                  {/* {displayOption == 'game_settings' && currentSubSessionsList ?
                          <div className="p-2 text-center" onClick={(e) => returnToBreakoutRoom(e)}>
                            <span className="breakout-room-button" >Return Breakout Session</span>
                          </div> : ""} */}

                  <Row className="m-0">
                    {gameList?.map((gObj, gIndex) => {
                      return (
                        <Col key={"games-list-" + gIndex} sm={6}>
                          <div className="game-grid m-0">
                            {!gObj.is_selected ? (
                              <Card
                                className="h-100 mt-3"
                                onClick={(e) =>
                                  gameSettings(gObj, gIndex, "gameList")
                                }
                                style={{
                                  backgroundImage: `url(${gObj.img})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                }}
                              >
                                <div className="game-title">{gObj.name}</div>
                              </Card>
                            ) : (
                              <div className="h-100 mt-3">
                                <iframe
                                  className="h-100 w-100"
                                  src={gObj.route + gObj.token}
                                ></iframe>
                              </div>
                            )}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ) : (
                <div className="h-100">
                  <div className="h-100">
                    {isLoading ? (
                      <LoadingSpinner message="Please wait." />
                    ) : (
                      <CourseView className="host-img course-view-frame h-100" />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* BELOW PARTICIPANTS GALARY VIEW */}
          {getOnlyParticipants(participants) &&
          getOnlyParticipants(participants).length > 0 ? (
            <div className="participant_list_expert  d-flex">
              {e_participants_h_idx > 0 ? (
                <div
                  className="e-partipants-pagination-bg"
                  onClick={previousParticipantsVideos}
                >
                  <KeyboardArrowLeft />
                </div>
              ) : (
                ""
              )}
              <canvas
                className="e-participant-view"
                id="participant-videos-canvas-horizontal-view"
                width="1920"
                height="1080"
              ></canvas>
              <canvas
                id="participant-videos-canvas-horizontal-view-card"
                width="1920"
                height="1080"
              ></canvas>
              {/* <canvas id="participant-videos-canvas-horizontal-view-card" width="1920" height="1080"></canvas> */}
              {participants.length > 0 &&
                getOnlyParticipants(participants)?.map((participant, pIndex) =>
                  !participant.isHost ? (
                    <div
                      key={"participants-not-host-below-" + pIndex}
                      id={"participants-not-host-below-id-" + pIndex}
                      className={`e-participant-item-card ${
                        pIndex < 4 ? ` e-participant-item-card-border` : ``
                      } ${
                        participant?.bVideoOn ? "hide-participant-card" : ""
                      }`}
                    >
                      <div
                        className={
                          !participant?.bVideoOn
                            ? "participant-item-card-body-display-hide"
                            : `e-participant-item-card-body ${
                                participant?.isTalking
                                  ? `e-participant-item-card-body-audio-enable`
                                  : ``
                              }`
                        }
                      >
                        {/* AUDIO SPEAKER BLINKING */}
                      </div>
                      {!participant?.bVideoOn ? (
                        <div
                          className={`e-participant-item-card-no-video ${
                            participant?.isTalking
                              ? `e-participant-item-card-audio-border-enable`
                              : "participant-item-card-audio-border-disable"
                          }`}
                        >
                          {participant.userIdentity ? (
                            <div className="e-participants-initials-rounded-view-image-in">
                              <img
                                src={`${url_configs.imageDocumentPath}${participant.userIdentity}`}
                                alt="Profile"
                              />
                            </div>
                          ) : (
                            <div className="e-participants-initials-rounded-view">
                              <span>
                                {getInitialsFromFullName(
                                  participant.displayName
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`participant-item-card-footer ${
                          participant?.bVideoOn ? "hide-participant-card" : ""
                        }`}
                      >
                        {participant?.muted == true ||
                        participant?.muted == undefined ? (
                          <FaMicrophoneSlash
                            className={`participant-item-card-footer-icon ${
                              participant?.bVideoOn
                                ? "hide-participant-card"
                                : ""
                            }`}
                          />
                        ) : (
                          <FaMicrophone
                            className={`participant-item-card-footer-icon ${
                              participant?.bVideoOn
                                ? "hide-participant-card"
                                : ""
                            }`}
                          />
                        )}
                        <div>
                          {displayOnlyFirstName(participant.displayName)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              {participants &&
              getOnlyParticipantsLength(participants).balanceUsers > 0 ? (
                <div
                  className="e-partipants-pagination-bg e-partipants-pagination-right"
                  onClick={nextParticipantsVideos}
                >
                  <KeyboardArrowRightIcon />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* GALLERY VIEW */}
      <div className={`h-100 p-4 ${!isGalleryView ? `gallery-view-hide` : ``}`}>
        {/* <canvas id="participant-videos-canvas" width="1920" height="1080"></canvas> */}
        {/* <button onClick={()=>nextVideos()}>Next Videos</button>
      <button onClick={()=>previousVideos()}>Previous Videos</button> */}
      </div>

      {/* OPEN LEARNER QUIZ RESULT POPUP */}
      <Dialog
        id={"quiz_progress_popup"}
        open={openLearnerQuizProgress}
        onClose={handleOpenLearnerQuizProgress}
      >
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_game_text"
            >
              Quiz Progress{" "}
              <span
                className="refresh-quiz"
                onClick={(e) => onRefreshQuizProgress(e)}
              >
                {getIcon(icons_config.refreshCircleOutline)}
              </span>
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseLearnerQuizProgress}
              className="select_game_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            {!learnerQuizResults?.quizResult ? (
              "No actions found from learner!"
            ) : (
              <div>
                {learnerQuizResults?.quizResult.map((resultObj, index) => (
                  <div>
                    <Accordion
                      key={"learner_quiz_" + index}
                      id={"learner_quiz_id_" + index}
                      elevation={12}
                      className="mt-2"
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        variant="outlined"
                      >
                        <Typography>
                          <Stack
                            className="align-items-center"
                            direction="row"
                            spacing={1}
                          >
                            <span>Attempt - {resultObj.attempt}</span>
                            <span className="quiz-question-pop">
                              {learnerQuizResults?.userDetail?.name}
                            </span>
                            {resultObj?.status == "completed" ? (
                              <span className="quiz-question-pop">
                                {resultObj.totalScore} /{" "}
                                {resultObj.totalQuestions * 5}
                              </span>
                            ) : (
                              ""
                            )}
                            <span
                              className={
                                resultObj?.status == "completed"
                                  ? `quiz-status-flag-completed`
                                  : `quiz-status-flag-inprogress`
                              }
                            >
                              {resultObj?.status == "completed"
                                ? `Completed`
                                : `In progress`}
                            </span>
                          </Stack>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Stack
                            className="align-items-center mb-2"
                            direction="row"
                            spacing={1}
                          >
                            <span>
                              Total Question:{" "}
                              <span className="quiz-question-pop">
                                {resultObj.totalQuestions}
                              </span>
                            </span>
                            <span>
                              Total Score:{" "}
                              <span className="quiz-question-pop">
                                {resultObj.totalScore}
                              </span>
                            </span>
                            <span>
                              Correct Answers:{" "}
                              <span className="quiz-question-pop">
                                {" "}
                                {resultObj.correctAnswers}
                              </span>
                            </span>
                            <span>
                              Wrong Answers:{" "}
                              <span className="quiz-question-pop">
                                {" "}
                                {resultObj.wrongAnswers}
                              </span>
                            </span>
                          </Stack>
                        </Typography>
                        <ol className="ps-0">
                          {resultObj?.learnerQuizAnswers.map(
                            (lAnsObj, aIndex) => (
                              <Card
                                key={`ans-id-` + aIndex}
                                className="mb-2 p-4"
                              >
                                <li className="ms-2">
                                  <Typography className="quiz-question-pop">
                                    {lAnsObj?.actualQuestion?.question}
                                  </Typography>
                                  <ol>
                                    {lAnsObj?.actualQuestion?.choices.map(
                                      (lChoiceObj, cIndex) => (
                                        <li
                                          type="a"
                                          key={`q-choices-` + cIndex}
                                          className={
                                            lChoiceObj?.correct == "1"
                                              ? `quiz-correct-answer-pop`
                                              : `quiz-answer-pop`
                                          }
                                        >
                                          {lChoiceObj.answer}{" "}
                                          {lChoiceObj?.correct == "1"
                                            ? " - Correct Answer"
                                            : ""}
                                        </li>
                                      )
                                    )}
                                  </ol>
                                </li>
                                <div>
                                  <Typography direction="row" spacing={1}>
                                    Learner Answer:{" "}
                                    <span
                                      className={
                                        lAnsObj?.learnerAnswerResult == true
                                          ? `quiz-question-pop quiz-learner-answer-correct`
                                          : `quiz-question-pop quiz-learner-answer-wrong`
                                      }
                                    >
                                      {lAnsObj?.learnerAnswered?.answer}
                                    </span>
                                  </Typography>
                                </div>
                              </Card>
                            )
                          )}
                        </ol>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
