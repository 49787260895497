import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Badge,
  Fade,
  TextField,
  Typography,
  Button,
  Grid, InputBase,
  IconButton,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Container,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardActions,
  CardContent,
  Radio,
  Paper,
  Stack,
  Modal
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SearchIcon from "@mui/icons-material/Search";

//ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

//ASSETS -> IMG
import Sampleimg from "../../../assets/img/sample_img.png";
import "./quiz.css";
import Iconify from '../../../components/Iconify';

//CONFIGS
import iconsConfig from "../../../config/icons";
import questionTypes from '../../../config/question-types';
import conditional_configs from "../../../config/conditional_config";

//SERVICES
import AdminService from "../../../services/admin-service";

import { getCurrentUserData } from "../../../utils/util";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from 'notistack';
import ConfirmationPopup from "../../../components/confirmation-popup/confirmation-popup";

export default function Quiz() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    templatename: Yup.string().required("Quiz template name is required"),
  });

  const [quizTemplateList, setQuizTemplateList] = useState([]);
  const [quiz_card_icon_btn, setQuiz_card_icon_btn] = useState(null);
  const open_select = Boolean(quiz_card_icon_btn);

  const [openQuizTemplate, setOpenQuizTemplate] = useState(false);
  const [anchorElPreAssessment, setAnchorElPreAssessment] = React.useState(null);
  const openPreAssessment = Boolean(anchorElPreAssessment);

  const [quizList, setQuizList] = useState([]);

  const {
    register,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClickAddPreAssessment = (event) => {
    setAnchorElPreAssessment(event.currentTarget);
  };

  const handleCloseAddPreAssessment = () => {
    setAnchorElPreAssessment(null);
  };

  const handlePreAssessmentType = (type, e) => {
    console.log(type);
    addPreAssessmentQuiz(type);
    handleCloseAddPreAssessment();
  };

  const addPreAssessmentQuiz = (qtype) => {
    let newQuiz = {
      question: "",
      questiontype: "",
      answerlist: [
        {
          answer: "",
          correct: "",
          documentid: "1"
        }
      ],
      sequence: "1"
    };
    newQuiz.questiontype = qtype;
    setQuizList([...quizList, newQuiz]);
  }

  const removePreAssessmentQuiz = (index) => {
    let data = [...quizList];
    data.splice(index, 1)
    setQuizList(data)
  }

  const addPreAssessmentAns = (qindex) => {
    let newAns = {
      answer: "",
      correct: "",
      documentid: ""
    };
    let data = [...quizList];
    data[qindex].answerlist.push(newAns);
    setQuizList(data);
  }

  const removePreAssessmentAns = (qindex, aindex) => {

    let data = [...quizList];
    data[qindex].answerlist.splice(aindex, 1);
    setQuizList(data);
  }

  const handlePreAssessmentQuestionFormChange = (index, e) => {

    const { value } = e.target;
    quizList[index].question = value;
    setQuizList(quizList);
  }

  const handlePreAssessmentAnswerFormChange = (qindex, aindex, e) => {
    const { value } = e.target;
    let currentQList = [...quizList];
    let currentQuestion = currentQList[qindex];

    if (currentQuestion && currentQuestion.questiontype == questionTypes.shortAnswer) {
      currentQList[qindex].answerlist[aindex] = {
        answer: value,
        correct: "true",
        documentid: "1"
      };
    } else {
      currentQList[qindex].answerlist[aindex] = {
        answer: value,
        correct: "",
        documentid: "1"
      };
    }
    setQuizList(currentQList);
  }

  const selectPreAssessmentCorrectAns = (qindex, aindex, e) => {
    const { name, value } = e.target;
    let currentQList = [...quizList];
    let currentQuestion = currentQList[qindex];

    if (currentQuestion && currentQuestion.questiontype == questionTypes.multiChoice) {
      currentQList[qindex].answerlist.forEach(element => {
        element.correct = "false";
      });
      currentQList[qindex].answerlist[aindex].correct = 'true';
    }
    setQuizList(currentQList);
  }
  /** OPEN TEMPLATE QUIZ POPUP */
  const handleOpenQuizTemplate = (type) => {
    setOpenQuizTemplate(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseQuizTemplate = () => setOpenQuizTemplate(false);

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };

  //GAME ICON BUTTON FUNCTION
  const card_icon_open = (event) => {
    setQuiz_card_icon_btn(event.currentTarget);
  };
  const card_icon_close = () => {
    setQuiz_card_icon_btn(null);
  };

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      AdminService.getAllActiveQuizTemplate().then(response => {
        setQuizTemplateList(response.data);
        console.log(response.data);
      })
        .catch(e => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  //SUBMIT NEW QUIZ TEMPLATE
  const onSubmit = (data) => {
    if (quizList.length == 0) {
      enqueueSnackbar('Please add questions', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }
    var insertQuizTemplateModle = {
      name: data.templatename,
      quiz: quizList
    }
    AdminService.createQuizTemplate(insertQuizTemplateModle)
      .then(response => {

        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Quiz templated added Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          handleCloseQuizTemplate();
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }

      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };


  // confirmation box
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDeleteOpenDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const handleDelete = () => {
    setConfirmationDialogOpen(false); // Close the dialog after deletion.
  };

  const handleCancel = () => {
    setConfirmationDialogOpen(false); // Close the dialog without deleting.
  };
  // confirmation box end

  //GAME ICON BUTTON FUNCTION END
  return (
    <div>
      {/* TITLE HEADER */}
      <Container maxWidth="md">
        <Box sx={{ width: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
            <Typography variant="h4" gutterBottom className='mb-0'>
              Quiz
              <Badge className="badge_quiz" badgeContent={quizTemplateList?.length} sx={badgeStyle} />
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e) => handleOpenQuizTemplate('CREATE')}>
              New Quiz
            </Button>
          </Stack>
          <div>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper
                  component="form"
                  className="common-search-box"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    placeholder="Search by Quiz name"
                    inputProps={{ "aria-label": "search br course name" }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Box>
        {/* CARD SECTION */}
        <div className="mt-4">
          {quizTemplateList.map((quizTemplateObj, quizTemplateIndex) => {
            return (
              <Card key={quizTemplateIndex} className="quiz_card">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="game_img">
                      <img src={Sampleimg} />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    <div class="d-flex align-items-center">
                      <h5 className="card_quiz_title">
                        {quizTemplateObj?.templatename}
                      </h5>
                      <span
                        className="ms-auto quiz_dot_icon"
                        onClick={card_icon_open}
                      >
                        <MoreVertIcon />
                      </span>
                    </div>
                    <h6 className="card_quiz_sub_title">
                      <Iconify icon={iconsConfig.quiz} />
                      <span className="ps-1">{quizTemplateObj?.questions?.length} Questions</span>

                    </h6>
                    <div class="d-flex align-items-center  mt-2">
                      <span className="play_game_icon">
                        <Iconify icon={iconsConfig.courses} />
                      </span>

                      <p className="card_courses_linked m-0">
                        5 Courses Linked
                      </p>
                      {/* <p className="ms-auto card_courses_linked m-0">
                        Last Updated <strong>{card_datas.updated_date}</strong>
                      </p> */}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
        {/* CARD SECTION END */}
        {/* IconButton */}
        <Grid container justifyContent="center">
          <div>
            <Menu
              id="select_file_btn_menu"
              MenuListProps={{
                "aria-labelledby": "select_file_btn",
              }}
              anchorEl={quiz_card_icon_btn}
              open={open_select}
              onClose={card_icon_close}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={card_icon_close}>
                <ModeEditIcon style={{ marginRight: "10" }} /> Edit Quiz
              </MenuItem>

              <MenuItem onClick={handleDeleteOpenDialog}>
                <DeleteIcon style={{ marginRight: "10" }} /> Delete
              </MenuItem>
            </Menu>
          </div>
        </Grid>
        {/* IconButton */}

        {/* SELECT QUIZ TEMPLATE POPUP */}
        <Dialog fullWidth id={'quiz_template_popup'} open={openQuizTemplate} onClose={handleCloseQuizTemplate}>
          <DialogTitle>
            <div className="d-flex justify-content-center;">
              <Typography
                color="primary"
                style={{ marginRight: 4 }}
                className="select_quiz_text"
              >
                Create Quiz
              </Typography>

              <Typography
                color="action"
                style={{ marginLeft: "auto" }}
                onClick={handleCloseQuizTemplate}
                className="select_quiz_close_btn"
              >
                <CloseIcon />
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <form>
              {/* FIRST NAME TEXT FIELD */}
              <Grid item xs={12} sm={12} className="mt-2">
                <TextField
                  required
                  id="templatename"
                  name="templatename"
                  label="Template Name"
                  fullWidth
                  margin="dense"
                  {...register("templatename")}
                  error={errors.templatename ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.templatename?.message}
                </Typography>
              </Grid>
              {/* ADD PRE ASSESSMENT MENU BUTTON */}
              <Card
                id="add_pre_assessment"
                className="add_curriculum_card mt-2"
                elevation={3}
                aria-controls={openPreAssessment ? 'add_pre_assessment_menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPreAssessment ? 'true' : undefined}
                onClick={handleClickAddPreAssessment}>
                <Grid container justifyContent="center" textAlign="center">
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    variant="outlined"
                  >
                    <Typography>
                      <AddCircleIcon style={{ marginRight: 7 }} /> Add Question
                    </Typography>
                  </AccordionSummary>
                </Grid>
              </Card>

              <Menu
                id="add_pre_assessment_menu"
                MenuListProps={{
                  'aria-labelledby': 'add_pre_assessmentn',
                }}
                anchorEl={anchorElPreAssessment}
                open={openPreAssessment}
                onClose={handleCloseAddPreAssessment}
              >
                <MenuItem onClick={(e) => handlePreAssessmentType(questionTypes.shortAnswer, e)} disableRipple>
                  Short Answer
                </MenuItem>
                <MenuItem onClick={(e) => handlePreAssessmentType(questionTypes.multiChoice, e)} disableRipple>
                  Multiple Choice
                </MenuItem>
              </Menu>

              {quizList.map((qObj, index) => {
                return (
                  <Accordion key={index} id={'question_' + index} elevation={12} className="pre_assessment_list_accordion mt-2">
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      variant="outlined"
                    >
                      <div className="align-items-center justify-content-between d-flex w-100">
                        <Typography>
                          <IconButton aria-label="library" color="primary">
                            <DragIndicatorIcon color="action" />
                          </IconButton>
                          Question - {index + 1}
                        </Typography>
                        <Typography>
                          <Stack direction="row" spacing={1}>
                            <IconButton aria-label="library" color="primary">
                              <LibraryAddIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => removePreAssessmentQuiz(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </Typography>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Card elevation={12} className="pre_assessment_list_card mt-2">
                        <Container spacing={5}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              name={'question_input_' + index}
                              id={'question_input_id_' + index}
                              label="Question"
                              variant="outlined"
                              onChange={event => handlePreAssessmentQuestionFormChange(index, event)}
                              fullWidth
                              className="lesson_name mt-1"
                            />
                          </Grid>
                          {qObj.answerlist.map((ansObj, aindex) => {
                            return (
                              <Grid key={index + aindex} item xs={12} sm={12} className="mt-2">
                                {qObj.questiontype == questionTypes.multiChoice ?
                                  //  MULTI CHOICE ANSWERS
                                  <div>
                                    <div className="d-flex align-items-center" id={'ans_' + index + '_' + aindex}>
                                      <Radio style={{ marginRight: "5" }} />
                                      <TextField
                                        id={'multi_choice_ans_input_id_' + index + '_' + aindex}
                                        name={'multi_choice_ans_input_name_' + index + '_' + aindex}
                                        label={'Answer ' + (aindex + 1)}
                                        variant="outlined"
                                        fullWidth
                                        className="lesson_name mt-1"
                                        value={ansObj.answer}
                                        onChange={event => handlePreAssessmentAnswerFormChange(index, aindex, event)}
                                      />
                                      <Stack direction="row" spacing={1}>
                                        {ansObj.correct != 'true' ? <IconButton aria-label="library" color="primary" onClick={event => selectPreAssessmentCorrectAns(index, aindex, event)}>
                                          <CheckIcon />
                                        </IconButton> : ""}
                                        {/* <IconButton >
                                        <CollectionsIcon />
                                      </IconButton> */}
                                        <IconButton aria-label="delete" onClick={() => removePreAssessmentAns(index, aindex)}>
                                          <CloseIcon />
                                        </IconButton>
                                      </Stack>
                                    </div>
                                    {ansObj.correct == 'true' ? <div className="d-flex justify-content-end correct_answer_text">
                                      <span>Correct Answer</span>
                                    </div> : ""}
                                  </div>
                                  :
                                  // SHORT ANSWER
                                  <div className="d-flex align-items-center" id={'ans_' + index + '_' + aindex}>
                                    <TextField
                                      id={'short_ans_input_id_' + index + '_' + aindex}
                                      label="Short Answer"
                                      variant="outlined"
                                      multiline
                                      fullWidth
                                      rows={3}
                                      className="lesson_name mt-1"
                                      onChange={event => handlePreAssessmentAnswerFormChange(index, aindex, event)}
                                    />
                                    <CollectionsIcon
                                      style={{ marginLeft: "10", marginRight: "10" }}
                                    />
                                  </div>}
                              </Grid>
                            )
                          })}

                          {qObj.questiontype == questionTypes.multiChoice ? <Grid item xs={12} sm={12} className="mt-2" >
                            <div className="d-flex align-items-center cursor-pointer"
                              onClick={() => addPreAssessmentAns(index)}>
                              <Radio style={{ marginRight: "5" }} />
                              <span className="add_option_text">Add option </span>
                            </div>
                          </Grid> : ""}

                          {/* <Grid item xs={12} sm={12} className="mt-2">
                          <div className="correct_ans_dis_text">
                            <Container>
                              <span className="correct_ans_text">
                                Correct Answer Description
                              </span>
                              <TextField
                                id="outlined-multiline-static"
                                label="Option 4"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                className="lesson_name mt-1"
                              />
                            </Container>
                          </div>
                        </Grid> */}
                          {/* <Grid item xs={12} sm={12} className="mt-4">
                          <div className="d-flex align-items-center justify-content-end ">
                            <LibraryAddIcon style={{ marginRight: "10" }} />
                            <DeleteIcon style={{ marginRight: "5" }} />
                          </div>
                        </Grid> */}
                        </Container>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </form>
          </DialogContent>
          <DialogActions className="dialog_action">
            <Button variant="contained" className="add_new_quiz_btn" onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <div>
        <ConfirmationPopup open={confirmationDialogOpen} onDelete={handleDelete} onCancel={handleCancel} onClose={() => setConfirmationDialogOpen(false)} />
      </div>
    </div>
  );
}
