//import * as React from "react";
import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Container, Grid, InputBase, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./my-courses.css";
import { fDate } from "../../../utils/formatTime";
import moment from 'moment';

import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";

import Sampleimg from "../../../assets/img/sample_img.png";

import { LinearProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import NoRecordFound from '../../../components/no-record-found/no-record-found';

//config
import navigate_route_config from '../../../config/navigate_route_config';
import conditional_configs from '../../../config/conditional_config';
import storage_configs from '../../../config/storage-config';
import assets from '../../../config/assets';
import error_messages from '../../../config/error_messages';

//Service
import ExpertAPIService from '../../../services/expert-service';
import SessionService from '../../../services/session-service';

//UTILS
import { getCurrentUserData, getTimeZoneShortForm } from '../../../utils/util';
import { Pagination } from "@mui/material";
import tooltip_messages from '../../../config/tooltip';
import slugify from 'react-slugify';

let coursesDetailsList = [];

export default function Mycourses() {

  const navigate = useNavigate();

  const [courseDetails, setcourseDetails] = useState([]);

  const [courseCount, setCourseCount] = useState();

  const [pendingSessions, setPendingSessions] = useState([]);

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "#000",
      backgroundColor: "#F9BA1A",
      padding: "10px",
    },
  };

  const navigateToPods = (id) => {
    navigate(navigate_route_config.expert.pods + '/' + id);
  }

  const navigateToCurriculum = (id) => {
    navigate(navigate_route_config.expert.curriculum + '/' + id);
  }

  const navigateToCourseDetails = (e, datas) => {
    if (datas) {
      //navigate('/course-detail/'+ slugify(datas.coursename)+'-'+datas.courseid);
      window.open('/course-detail/' + slugify(datas.coursename) + '-' + datas.courseid);
    }
  }

  //pagination
  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const courseDetail = courseDetails.slice(startIndex, endIndex);

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      ExpertAPIService.GetMyCourse(getCurrentUserData().id).then(response => {
        setcourseDetails(response.data);
        coursesDetailsList = response.data;
        if (response.data) {
          setCourseCount(response.data.length);
        }
        getPendingSessions();
      })
        .catch(e => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate('/expert-login');
    }

  }, []);

  const getPendingSessions = () => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {

      ExpertAPIService.getAllScheduledClasses(getCurrentUserData().id).then(response => {
        if (response && response.data) {
          let scheduleData = []
          response.data.forEach(element => {
            SessionService.getPendingSchedules(getCurrentUserData().id, element.coursestreamdetailid).then(res => {
              if (res && res.data && res.data.length > 0) {
                element.schedule = res.data;
                scheduleData.push(element);
              }
            })
              .catch(e => {
                console.log(e);
              });
          });

          setTimeout(() => {
            setPendingSessions(scheduleData);
            console.log(scheduleData)
          }, 3000);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  const convertTime = (time) => {
    if (time) {
      let convertedTime = moment(time, 'HH:mm').format('hh:mm a');
      return convertedTime;
    }
  }

  /** START SESSION */
  const handleStartSession = (e, data, schedata) => {
    localStorage.setItem(storage_configs.localStorage.sessions.current_session_id, data.sessionid.toString());
    localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, data.coursestreamid.toString());
    localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, schedata.id);
    navigate('/expert-session/classroom/' + schedata.slug);
  }

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter the list of courses based on the search query
    const filteredCourses = coursesDetailsList.filter((cours) =>
      cours.coursename.toLowerCase().includes(query.toLowerCase())
    );

    setcourseDetails(filteredCourses);
  };

  return (
    <div className='e_main_my_course_padding'>
      <div>
        {pendingSessions.map((pObj, pidx) => {
          return (
            <div key={'pening-sessions-key-' + pidx} className='pending-sessions-container'>
              <span>
                {pObj.coursename} - {fDate(pObj.startdate)}({convertTime(pObj.from)} - {convertTime(pObj.to)}) {getTimeZoneShortForm()}
              </span>
              &nbsp;&nbsp;&nbsp;
              {pObj?.schedule.map((sObj, sidx) => {
                return (
                  <Button key={'resume-btn-' + sidx} className="pending-join-btn" onClick={(e) => handleStartSession(e, pObj, sObj)}>
                    Resume session
                  </Button>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* TITLE HEADER */}
      <div elevation={3} className="pb-2">
        <Container className="l-my-course-main-container">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className='pb-3'>
              <span className="courses_title_text">Courses</span>
              {courseDetail?.length ? <Badge
                className="badge_course"
                badgeContent={courseDetail?.length}
                sx={badgeStyle}
              /> : ""}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper
                component="form"
                className="common-search-box"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className="search-input"
                  placeholder="Search by Course Name"
                  inputProps={{ 'aria-label': 'search by course name' }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* TITLE HEADER End */}

      {/* CARD SECTION */}
      <Container className='l-my-course-main-container'>
        {courseDetail.length > 0 ? courseDetail.map((datas, index) => {
          return (
            <Grid key={'expert-my-courses-card-' + index} container justifyContent="center">
              {/* CARD SECTION 1 */}
              <Card className="course_card mb-3">
                <Grid container className='step-3'>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <img
                      src={datas.courseimage}
                      alt="user_photo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <Box className="my_courses_text_section">
                      <p className="my_courses_title">{datas.coursename}</p>
                      {/* <span className="my_schedule_text_section">
                        <strong>
                          {datas.completedsessions ? (<>{datas.completedsessions}</>) : (0)}/
                          {datas.totalsessions} sessions
                        </strong>{" "}
                        Complete
                      </span>
                      <p className="m-0">
                        <LinearProgress
                          className="my_schedule_linear"
                          variant="determinate"
                          value={80}
                          color="success"
                          sx={{
                            height: "10px",
                            borderRadius: 2,
                            bgcolor: "#fffff",
                          }}
                        />{" "}
                      </p> */}

                      {datas.nextsessions ? <div className="next_session_div">
                        <span className="my_courses_next_session">
                          NEXT SESSION
                        </span>
                      </div> : ""}

                      <span className="my_courses_next_date">
                        {datas.nextsessions}
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    className="my_courses_btn_group mt-lg-4 mt-4 mt-sm-4"
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <div>
                        <Tooltip title={tooltip_messages.expert.openPods} placement="right">
                          <Button
                            variant="outlined"
                            className="step-4 pods_btn mb-2 me-1 me-lg-0"
                            onClick={(e) => navigateToPods(datas.courseid)}
                          >
                            Pods
                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title={tooltip_messages.expert.openCurriculum} placement="right">
                          <Button
                            variant="outlined"
                            className="step-5 curriculum_btn mb-2 me-1 me-lg-0"
                            onClick={(e) => navigateToCurriculum(datas.courseid)}
                          >
                            Curriculum
                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title={tooltip_messages.viewCourseDetail} placement="right">
                          <Button
                            variant="outlined"
                            className="step-6 course_info_btn mb-2 me-1 me-lg-0" onClick={(e) => navigateToCourseDetails(e, datas)}
                          >
                            Course Info
                          </Button>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>

            </Grid>
          )
        }) :
          <Grid container justifyContent="center" className="mt-3">
            <NoRecordFound noRecordFoundImage={assets.noRecordFound.expertCourses} noRecordFoundText={error_messages.expertNoCoursesFound}></NoRecordFound>

          </Grid>}

        {courseDetail.length > 0 ? <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={Math.ceil(courseDetails.length / itemsPerPage)}
            page={currentPage}
            onChange={handleChange}
            color="primary"
          />
        </Box> : ""}

      </Container>
      {/* CARD SECTION END*/}
    </div>
  );
}
