import http from "../router/http-common";
import api_path from "../config/api";
import storage_configs from "../config/storage-config";
import assets from '../config/assets'
import { getCurrentUserData, getInitialsFromFullName } from "../utils/util";
import url_configs from "../config/url";
import { getActiveCamera, getActiveMicrophone, getActiveSpeaker, handleError } from "../utils/zoomFunctions";
import authHeader from "./auth/auth-header";
import { getExploreName } from '../utils/platform';

var currentURL = window.location.href.split('/');
var slug = currentURL[currentURL.length - 1];
if (slug.includes('#')) {
    // Remove '#' using the replace() method
    slug = slug.replace('#', '');
}

var logData = {
    userId: getCurrentUserData() && getCurrentUserData().id,
    name: getCurrentUserData() && getCurrentUserData().name + '(' + (getCurrentUserData().role == 2 ? 'Kid' : 'Expert') + ')',
    email: getCurrentUserData() && getCurrentUserData().email,
    slug: slug,
    browser: getExploreName(),
    is_video_enabled: null,
    is_audio_enabled: null,
    active_camera: null,
    active_microphone: null,
    active_speaker: null,
    description: null,
    video_permission: null
}


const openSubsessions = async (zoomClient, subSessionClient, subSessionList) => {
    try {
        await subSessionClient.openSubsessions(subSessionList, { isTimerEnabled: false });
    } catch (error) {
        console.error('OPEN SUBSESSIONS', error);
        return;
    }
};

const assignUserToSubsession = async (zoomClient, subSessionClient, userId, sessionId) => {
    try {
        let res = await subSessionClient.assignUserToSubsession(userId, sessionId);
        console.log("Assigned User - SUCCESS", res);
        return;
    } catch (error) {
        console.error('ASSIGN USER TO SUBSESSIONS - ERROR -', error.type);
        if (error && (error.type == 'INTERNAL_ERROR' || error.type == 'OPERATION_TIMEOUT')) {
            assignUserToSubsession(zoomClient, subSessionClient, userId, sessionId);
        }
    }
};

//#region VIDEO
const renderExpertSelfVideo = async (stream, client) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(stream);
    logData.active_microphone = getActiveMicrophone(stream);
    logData.active_speaker = getActiveSpeaker(stream);
    logData.description = "Expert turning on self video";
    logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'

    // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
    if (stream.isRenderSelfViewWithVideoElement()) {
        // start video - video will render automatically on HTML Video element
        stream.startVideo({ videoElement: document.querySelector('#e-self-view-video') }).then(() => {
            // show HTML Video element in DOM
            document.querySelector('#e-self-view-video').style.display = 'block';
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            zoomSessionLogsRegister(logData);

        }).catch((error) => {
            handleError(error);
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            logData.description = JSON.stringify(error);
            logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
            zoomSessionLogsRegister(logData);
        })
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
    } else {
        // start video
        //stream.startVideo({ virtualBackground: { imageUrl: 'blur' } }).then(() => {
        stream.startVideo().then(() => {
            // render video on HTML Canvas element
            stream.renderVideo(document.querySelector('#e-self-view-canvas'), client.getCurrentUserInfo().userId, 320, 180, 0, 0, 2).then(() => {
                // show HTML Canvas element in DOM
                document.querySelector('#e-self-view-canvas').style.display = 'block';
                logData.is_video_enabled = stream && stream.isCapturingVideo();
                logData.active_camera = getActiveCamera(stream);
                zoomSessionLogsRegister(logData);
            }).catch((error) => {
                handleError(error);
                logData.is_video_enabled = stream && stream.isCapturingVideo();
                logData.active_camera = getActiveCamera(stream);
                logData.description = JSON.stringify(error);
                logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
                zoomSessionLogsRegister(logData);
            })
        }).catch((error) => {
            handleError(error);
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            logData.description = JSON.stringify(error);
            logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
            zoomSessionLogsRegister(logData);
        })
    }
};

const renderExpertVideoInLearnerView = async (stream, userId) => {
    setTimeout(() => {
        stream.renderVideo(document.querySelector("#self-view-canvas"), userId, 1920, 1080, 0, 0, 2).then(() => {
            // show HTML Canvas Element in DOM
            document.querySelector("#self-view-canvas").style.display = 'block';
            document.querySelector("#self-view-video").style.display = 'none';
        }).catch((error) => {
            console.error('Render Video ::', error);
        })
    }, 5000);
};

const stopRenderExpertVideoFromLearnerView = async (stream, userId) => {
    if (userId) {
        if (!stream.isCameraTaken()) {
            // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
            if ((!stream.isSupportMultipleVideos() && (typeof OffscreenCanvas === 'function')) || /android/i.test(navigator.userAgent)) {
                // if MediaStreamTrackProcessor is not supported
                if (!(typeof MediaStreamTrackProcessor === 'function')) {
                    // render video on HTML Canvas Element
                    setTimeout(() => {
                        stream.stopRenderVideo(
                            document.querySelector("#self-view-canvas"),
                            userId
                        );
                        if (document.querySelector("#self-view-canvas")) {
                            document.querySelector("#self-view-canvas").style.display = 'none'
                        }
                    }, 2000);

                } else {
                    // show HTML Video Element in DOM
                    stream.stopRenderVideo(
                        document.querySelector("#self-view-video"),
                        userId
                    );
                    if (document.querySelector("#self-view-video")) {
                        document.querySelector("#self-view-video").style.display = 'none'
                    }
                }
                // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
            } else {
                // render video on HTML Canvas Element
                stream.stopRenderVideo(
                    document.querySelector("#self-view-canvas"),
                    userId
                );
                if (document.querySelector("#self-view-canvas")) {
                    document.querySelector("#self-view-canvas").style.display = 'none'
                }
            }
        }
    } else {
        console.error('UserId is undefined.');
    }
};

const stopExpertSelfVideo = async (stream) => {
    stream.stopVideo()
};

const stopAllRenderedVideo = async (stream, client, type) => {
    let participants = client.getAllUser().filter(e => !e.isHost && !e.isManager);
    participants.forEach((participant, index) => {
        if (participant && typeof participant.userId !== 'undefined') {
            stream.stopRenderVideo(document.querySelector(type == 'expert' ? '#participant-videos-canvas-horizontal-view' : '#learner-participant-videos-canvas-vertical-view'), participant.userId)
        } else {
            console.error('Participant or participant.userId is undefined.');
        }
    });
};

const stopAllRenderedVideoExceptCurrentUser = async (stream, client, type) => {
    const session = client.getSessionInfo();
    let participants = client.getAllUser().filter(e => !e.isHost && !e.isManager && session.userId !== e.userId);
    participants.forEach((participant, index) => {
        if (participant && typeof participant.userId !== 'undefined') {
            stream.stopRenderVideo(document.querySelector(type == 'expert' ? '#participant-videos-canvas-horizontal-view' : '#learner-participant-videos-canvas-vertical-view'), participant.userId)
        } else {
            console.error('Participant or participant.userId is undefined.');
        }
    });
};

const stopRenderVideo = async (stream, userId, type) => {
    // if(type == 'expert'){
    //     var canvasCard = document.getElementById('participant-videos-canvas-horizontal-view-card');

    // if (!canvasCard) {
    //     console.error('canvas Card element not found.');
    //     return;
    // }

    // const ctx = canvasCard.getContext('2d');
    // ctx.clearRect(0, 0, canvasCard.width, canvasCard.height);

    // }
    if (userId) {
        stream.stopRenderVideo(document.querySelector(type == 'expert' ? '#participant-videos-canvas-horizontal-view' : '#learner-participant-videos-canvas-vertical-view'), userId);
        logData.active_camera = getActiveCamera(stream);
        logData.active_microphone = getActiveMicrophone(stream);
        logData.active_speaker = getActiveSpeaker(stream);
        logData.description = "User turning off self video";
        logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        logData.is_video_enabled = false;
        logData.active_camera = getActiveCamera(stream);
        zoomSessionLogsRegister(logData);
    } else {
        console.error('UserId is undefined.');
    }
};

// const renderParticipantSelfVideo = async (stream, client) => {
//     // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
//     if (stream.isRenderSelfViewWithVideoElement()) {
//         // start video - video will render automatically on HTML Video element
//         stream.startVideo({ videoElement: document.querySelector('#participants-video-') }).then(() => {
//             // show HTML Video element in DOM
//             document.querySelector('#participants-video-' + client.getCurrentUserInfo().userId).style.display = 'block';
//             document.querySelector('#participants-canvas-' + client.getCurrentUserInfo().userId).style.display = 'none';
//         }).catch((error) => {
//             console.log(error)
//         })
//         // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
//     } else {
//         // start video
//         //stream.startVideo({ virtualBackground: { imageUrl: 'blur' } }).then(() => {
//         stream.startVideo().then(() => {
//             // render video on HTML Canvas element
//             stream.renderVideo(document.querySelector('#participants-canvas-' + client.getCurrentUserInfo().userId), client.getCurrentUserInfo().userId, 320, 180, 0, 0, 2).then(() => {
//                 // show HTML Canvas element in DOM
//                 document.querySelector('#participants-canvas-' + client.getCurrentUserInfo().userId).style.display = 'block';
//                 document.querySelector('#participants-video-' + client.getCurrentUserInfo().userId).style.display = 'none';
//             }).catch((error) => {
//                 console.log(error)
//             })
//         }).catch((error) => {
//             console.log(error)
//         })
//     }
// };

const renderParticipantSelfVideo = async (stream, client) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(stream);
    logData.active_microphone = getActiveMicrophone(stream);
    logData.active_speaker = getActiveSpeaker(stream);
    logData.description = "Expert turning on self video";
    logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'

    // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
    if (stream.isRenderSelfViewWithVideoElement()) {
        // start video - video will render automatically on HTML Video element
        stream.startVideo({ videoElement: document.querySelector('#participants-video-') }).then(() => {
            // show HTML Video element in DOM
            document.querySelector('#participants-video-' + client.getCurrentUserInfo().userId).style.display = 'block';
            document.querySelector('#participants-canvas-' + client.getCurrentUserInfo().userId).style.display = 'none';
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            zoomSessionLogsRegister(logData);
        }).catch((error) => {
            // console.log(error);
            handleError(error);
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            logData.description = JSON.stringify(error);
            logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
            zoomSessionLogsRegister(logData);
        })
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
    } else {
        // start video
        //stream.startVideo({ virtualBackground: { imageUrl: 'blur' } }).then(() => {
        stream.startVideo().then(() => {
            // render video on HTML Canvas element
            stream.renderVideo(document.querySelector('#learner-participant-videos-canvas-vertical-view'), client.getCurrentUserInfo().userId, 320, 180, 0, 0, 2).then(() => {
                // show HTML Canvas element in DOM
                // document.querySelector('#learner-participant-videos-canvas-vertical-view').style.display = 'block';
                // document.querySelector('#participants-video-' + client.getCurrentUserInfo().userId).style.display = 'none';
                logData.is_video_enabled = stream && stream.isCapturingVideo();
                logData.active_camera = getActiveCamera(stream);
                zoomSessionLogsRegister(logData);
            }).catch((error) => {
                handleError(error);
                logData.is_video_enabled = stream && stream.isCapturingVideo();
                logData.active_camera = getActiveCamera(stream);
                logData.description = JSON.stringify(error);
                logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
                zoomSessionLogsRegister(logData);
            })
        }).catch((error) => {
            // console.log(error);
            handleError(error);
            logData.is_video_enabled = stream && stream.isCapturingVideo();
            logData.active_camera = getActiveCamera(stream);
            logData.description = JSON.stringify(error);
            logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
            zoomSessionLogsRegister(logData);
        })
    }
};

const renderParticipantVideoInGallery = async (stream, client, pageIndex) => {
    localStorage.setItem('gallery-video-page-index', pageIndex);
    let participants = client.getAllUser();
    const rows = 4;
    const cols = 4;
    const itemsPerPage = 16;

    var canvas = document.getElementById('participant-videos-canvas');
    const cellWidth = Math.round(canvas.width / cols);
    const cellHeight = Math.round(canvas.height / rows);
    var arr = [];

    for (let i = 0; i < cols; i++) {
        for (let j = 0; j < rows; j++) {
            const x = j * cellWidth;
            const y = i * cellHeight;

            // Coordinates from the top-left corner of each cell
            console.log(`Cell (${i + 1}, ${j + 1}): x=${x}, y=${y}`);
            arr.push({ x: x, y: y })
        }
    }
    //Pagination index calculation
    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = participants.slice(startIndex, endIndex);
    for (let i = 0; i < arr.length; i++) {
        var checkPart = displayedItems[i];
        if (checkPart) {
            stream.renderVideo(document.querySelector('#participant-videos-canvas'), checkPart.userId, 480, 270, arr[i].x, arr[i].y, 2);
        }
    }
}

//Participants Horizontal view in Expert
const renderParticipantVideoInExpertHorizontalView = async (stream, client, pageIndex) => {
    localStorage.setItem(storage_configs.localStorage.sessions.e_participants_h_idx, JSON.stringify(pageIndex));
    let participants = client.getAllUser().filter(e => !e.isHost && !e.isManager);
    var canvas = document.getElementById('participant-videos-canvas-horizontal-view');
    var canvasCard = document.getElementById('participant-videos-canvas-horizontal-view-card');

    if (!canvas) {
        console.error('Canvas element not found.');
        return;
    }

    if (!canvasCard) {
        console.error('canvas Card element not found.');
        return;
    }
    const numColumns = 5;
    const itemsPerPage = 5;

    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const ctx = canvasCard.getContext('2d');
    ctx.clearRect(0, 0, canvasCard.height, canvasCard.height);
    var displayedItems = participants.slice(startIndex, endIndex);

    if (displayedItems.length == 0) {
        displayedItems = participants.slice(0, 5);
        localStorage.setItem(storage_configs.localStorage.sessions.e_participants_h_idx, JSON.stringify(0));
    }

    const columnWidth = (canvas.width - 4 * 15) / 5; // Adjusted width with space
    let arr = [];
    //Single row and multiple columns
    for (let i = 0; i < numColumns; i++) {
        const x = i * (columnWidth + 15);
        arr.push({ x });
        ctx.clearRect(x, 0, canvasCard.height, canvasCard.height);
    }

    console.log(arr)
    console.log(columnWidth);
    console.log(canvas.height);

    displayedItems.forEach((participant, index) => {
        const coordinates = arr[(arr.length - 1) - index];
        const coordinatesReverse = arr[index];
        if (participant && typeof participant.userId !== 'undefined') {
            if (participant && participant.bVideoOn == true) {
                stream.renderVideo(document.querySelector('#participant-videos-canvas-horizontal-view'), participant.userId, columnWidth, canvas.height, (coordinates.x - 10), 0, 2);
                drawExpertParticipantCard(ctx, (coordinatesReverse.x + 10), 0, columnWidth, canvasCard.height, participant, false);
            } else if (participant && participant.bVideoOn == false) {
                stream.stopRenderVideo(document.querySelector('#participant-videos-canvas-horizontal-view'), participant.userId);
            }
        } else {
            console.error('Participant or participant.userId is undefined.');
        }
    });
}

//Participants Vertical View in Learner
const renderParticipantVideoInLearnerVerticalView = async (stream, client, pageIndex, isStartSelfVideo) => {
    localStorage.setItem(storage_configs.localStorage.sessions.l_participants_v_idx, JSON.stringify(pageIndex));
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    const session = client.getSessionInfo();
    let checkIsStartSelfVideo = isStartSelfVideo;

    let participants = client.getAllUser().filter(e => !e.isHost && !e.isManager);
    const canvasWidth = 130;
    const canvasHeight = 970;

    var canvas = document.getElementById('learner-participant-videos-canvas-vertical-view');
    var canvasCard = document.getElementById('learner-participant-videos-canvas-vertical-view-card');

    if (!canvas) {
        console.error('Canvas element not found.');
        return;
    }

    if (!canvasCard) {
        console.error('Canvas element not found.');
        return;
    }

    const rows = 5;
    const cols = 1;
    const itemsPerPage = 5;
    const rowGap = 30;

    // Adjust these values to increase/decrease the width and height of the participant cards
    const cellHeight = (canvas.height - (rows - 1) * rowGap) / rows;

    const arr = [];

    const ctx = canvasCard.getContext('2d');
    ctx.clearRect(0, 0, canvasCard.width, canvasCard.height);
    for (let i = 0; i < cols; i++) {
        for (let j = 0; j < rows; j++) {
            const x = i * (canvasWidth);

            const y = j * (cellHeight + rowGap);
            const videoY = (rows - 1 - j) * (cellHeight + rowGap);
            arr.push({ x, y, videoY });
            ctx.clearRect(x, y, canvasCard.height, canvasCard.height);
        }
    }

    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    var displayedItems = participants.slice(startIndex, endIndex);

    if (displayedItems.length == 0) {
        displayedItems = participants.slice(0, 5);
        localStorage.setItem(storage_configs.localStorage.sessions.l_participants_v_idx, JSON.stringify(0));
    }

    console.log("isSupportMultipleVideos", stream.isSupportMultipleVideos())
    console.log("canva Height", cellHeight)

    // stream.renderVideo(
    //     document.querySelector('#learner-participant-videos-canvas-vertical-view'), 
    //     participant.userId, 
    //     cellHeight,  // Set both width and height to make it square
    //     cellHeight,  // Set both width and height to make it square
    //     (canvasWidth - cellHeight) / 2,  // Center horizontally
    //     (coordinates.videoY + (cellHeight - canvasHeight)) / 2,  // Center vertically
    //     2
    // );

    displayedItems.forEach((participant, index) => {
        const coordinates = arr[index];
        if (participant && typeof participant.userId !== 'undefined') {
            if (participant.userId == session.userId && checkIsStartSelfVideo == true) {
                logData.slug = slug;
                logData.active_camera = getActiveCamera(stream);
                logData.active_microphone = getActiveMicrophone(stream);
                logData.active_speaker = getActiveSpeaker(stream);
                logData.description = "Learner turning on self video";
                logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';

                // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
                if (stream.isRenderSelfViewWithVideoElement()) {
                    // start video - video will render automatically on HTML Video element
                    stream.startVideo({ videoElement: document.querySelector('#learner-participant-videos-video-vertical-view') }).then(() => {
                        // show HTML Video element in DOM
                        drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, true);
                        document.querySelector('#learner-participant-videos-video-vertical-view').style.display = 'block';
                        document.querySelector('#learner-participant-videos-canvas-vertical-view').style.display = 'none';
                        logData.is_video_enabled = stream && stream.isCapturingVideo();
                        logData.active_camera = getActiveCamera(stream);
                        zoomSessionLogsRegister(logData);
                    }).catch((error) => {
                        drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, false);
                        handleError(error);
                        logData.is_video_enabled = stream && stream.isCapturingVideo();
                        logData.active_camera = getActiveCamera(stream);
                        logData.description = JSON.stringify(error);
                        logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
                        zoomSessionLogsRegister(logData);
                    })
                    // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
                } else {
                    // start video
                    // stream.startVideo({ virtualBackground: { imageUrl: 'blur' } }).then(() => {
                    stream.startVideo().then(() => {
                        // render video on HTML Canvas element
                        stream.renderVideo(
                            document.querySelector('#learner-participant-videos-canvas-vertical-view'),
                            session.userId,
                            (160 - 18),  // Set both width and height to make it square
                            100,  // Set both width and height to make it square
                            2,  // Center horizontally
                            (coordinates.videoY - 10),  // Center vertically
                            3).then(() => {
                                drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, true);
                                logData.is_video_enabled = stream && stream.isCapturingVideo();
                                logData.active_camera = getActiveCamera(stream);
                                zoomSessionLogsRegister(logData);
                                // show HTML Canvas element in DOM
                                // document.querySelector('#learner-participant-videos-canvas-vertical-view').style.display = 'block';
                                // document.querySelector('#learner-participant-videos-video-vertical-view').style.display = 'none';
                            }).catch((error) => {
                                drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, false);
                                handleError(error);
                                logData.is_video_enabled = stream && stream.isCapturingVideo();
                                logData.active_camera = getActiveCamera(stream);
                                logData.description = JSON.stringify(error);
                                logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
                                zoomSessionLogsRegister(logData);
                            })
                    }).catch((error) => {
                        drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, false);
                        handleError(error);
                        logData.is_video_enabled = stream && stream.isCapturingVideo();
                        logData.active_camera = getActiveCamera(stream);
                        logData.description = JSON.stringify(error);
                        logData.video_permission = stream && !stream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'
                        zoomSessionLogsRegister(logData);
                    })
                }
                // drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, checkIsStartSelfVideo);
                // checkIsStartSelfVideo = false;
            } else {
                drawPlaceholderDesign(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, isStartSelfVideo);
                if (participant && participant.bVideoOn == true) {
                    // stream.renderVideo(
                    //     document.querySelector('#learner-participant-videos-canvas-vertical-view'),
                    //     participant.userId,
                    //     (canvasWidth - 8),
                    //     cellHeight,
                    //     4,
                    //     (coordinates.videoY - 10),
                    //     2);
                    stream.renderVideo(
                        document.querySelector('#learner-participant-videos-canvas-vertical-view'),
                        participant.userId,
                        (160 - 10),  // Set both width and height to make it square
                        100,  // Set both width and height to make it square
                        2,  // Center horizontally
                        (coordinates.videoY - 10),  // Center vertically
                        2
                    );
                    // Add border radius to the rendered video
                    //   document.querySelector('#learner-participant-videos-canvas-vertical-view').style.borderRadius = '30px';
                } else if (participant && participant.bVideoOn == false) {
                    stream.stopRenderVideo(document.querySelector('#learner-participant-videos-canvas-vertical-view'), participant.userId);
                    //drawLearnerSelfVideoCard(ctx, coordinates.x, coordinates.y, canvasWidth, (cellHeight), participant, false);
                }
            }
        } else {
            console.error('Participant or participant.userId is undefined.');
        }
    });
}
//#endregion

//#region AUDIO
const startAudioAndMuteMic = (zoomStream) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    try {
        zoomStream.startAudio({ autoStartAudioInSafari: true, speakerOnly: true, mute: true });
        logData.active_camera = getActiveCamera(zoomStream);
        logData.active_microphone = getActiveMicrophone(zoomStream);
        logData.active_speaker = getActiveSpeaker(zoomStream);
        logData.description = "User starting audio and mute their mic";
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        logData.is_video_enabled = false;
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;
        logData.active_camera = getActiveCamera(zoomStream);
        zoomSessionLogsRegister(logData);
    } catch (error) {
        console.error('startAudioAndMuteMic', error);
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;;
        logData.active_camera = getActiveCamera(zoomStream);
        logData.description = JSON.stringify(error);
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        zoomSessionLogsRegister(logData);
        return;
    }
};

const startAudio = async (zoomStream) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(zoomStream);
    logData.active_microphone = getActiveMicrophone(zoomStream);
    logData.active_speaker = getActiveSpeaker(zoomStream);
    logData.description = "User starting audio";
    logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
    logData.is_video_enabled = false;
    logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;
    logData.active_camera = getActiveCamera(zoomStream);
    try {
        await zoomStream.startAudio({ autoStartAudioInSafari: true, mute: false });
        zoomSessionLogsRegister(logData);
    } catch (error) {
        console.error('START AUDIO ERROR', error);
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;;
        logData.active_camera = getActiveCamera(zoomStream);
        logData.description = JSON.stringify(error);
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        zoomSessionLogsRegister(logData);
        return;
    }
};

const stopAudio = async (zoomStream) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(zoomStream);
    logData.active_microphone = getActiveMicrophone(zoomStream);
    logData.active_speaker = getActiveSpeaker(zoomStream);
    logData.description = "User stopping audio";
    logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
    logData.is_video_enabled = false;
    logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;
    logData.active_camera = getActiveCamera(zoomStream);
    try {
        await zoomStream.stopAudio();
        zoomSessionLogsRegister(logData);
    } catch (error) {
        console.error('STOP AUDIO ERROR', error);
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;;
        logData.active_camera = getActiveCamera(zoomStream);
        logData.description = JSON.stringify(error);
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        zoomSessionLogsRegister(logData);
        return;
    }
};

const muteAudio = async (zoomStream) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(zoomStream);
    logData.active_microphone = getActiveMicrophone(zoomStream);
    logData.active_speaker = getActiveSpeaker(zoomStream);
    logData.description = "User mute audio";
    logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
    logData.is_video_enabled = false;
    logData.is_audio_enabled = false;
    logData.active_camera = getActiveCamera(zoomStream);
    try {
        localStorage.setItem(storage_configs.localStorage.sessions.zoom.isAudioMuted, true);
        await zoomStream.muteAudio();
        zoomSessionLogsRegister(logData);
    } catch (error) {
        //console.error('MUTE AUDIO ERROR', error);
        await stopAudio(zoomStream);
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;;
        logData.active_camera = getActiveCamera(zoomStream);
        logData.description = JSON.stringify(error);
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        zoomSessionLogsRegister(logData);
        return;
    }
};

const unMuteAudio = async (zoomStream) => {
    var currentURL = window.location.href.split('/');
    var slug = currentURL[currentURL.length - 1];
    if (slug.includes('#')) {
        // Remove '#' using the replace() method
        slug = slug.replace('#', '');
    }
    logData.slug = slug;
    logData.active_camera = getActiveCamera(zoomStream);
    logData.active_microphone = getActiveMicrophone(zoomStream);
    logData.active_speaker = getActiveSpeaker(zoomStream);
    logData.description = "User unmute audio";
    logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
    logData.is_video_enabled = false;
    logData.is_audio_enabled = true;
    logData.active_camera = getActiveCamera(zoomStream);
    try {
        localStorage.setItem(storage_configs.localStorage.sessions.zoom.isAudioMuted, false);
        await zoomStream.unmuteAudio();
        zoomSessionLogsRegister(logData);
    } catch (error) {
        //console.error('UNMUTE AUDIO ERROR', error);
        await startAudio(zoomStream);
        logData.is_audio_enabled = zoomStream && zoomStream.isAudioMuted() ? false : true;;
        logData.active_camera = getActiveCamera(zoomStream);
        logData.description = JSON.stringify(error);
        logData.video_permission = zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed';
        zoomSessionLogsRegister(logData);
        return;
    }
};

const muteAudioByUserId = async (zoomStream, zoomClient) => {
    try {
        await zoomStream.muteAudio(zoomClient.getCurrentUserInfo().userId);
    } catch (error) {
        console.error('MUTE AUDIO ERROR', error);
        return;
    }
};
//#endregion


//#region CANVAS DRAWS
const drawPlaceholderDesign = (
    ctx,
    x,
    y,
    width,
    height,
    participant,
    isStartSelfVideo
) => {
    // Draw a colored rectangle with rounded corners
    if (participant.bVideoOn == true) {
        ctx.fillStyle = "transparent";
    } else {
        ctx.fillStyle = "#2e343a";
    }
    // ctx.fillStyle = !participant.bVideoOn ? '#2e343a' : 'transparent';
    // ctx.fillStyle = !isStartSelfVideo ? '#2e343a' : 'transparent';
    var displayName = participant.displayName.split(" ")[0];
    // Increase the values in the quadraticCurveTo for more rounded corners
    const borderRadius = 30; // Adjust the value as needed
    ctx.beginPath();
    ctx.moveTo(x + borderRadius, y);
    ctx.lineTo(x + width - borderRadius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
    ctx.lineTo(x + width, y + height - borderRadius);
    ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - borderRadius,
        y + height
    );
    ctx.lineTo(x + borderRadius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
    ctx.lineTo(x, y + borderRadius);
    ctx.quadraticCurveTo(x, y, x + borderRadius, y);
    ctx.closePath();
    ctx.fill();

    // Draw a border with a width of 2px and a solid white color
    if (participant.bVideoOn == true) {
        ctx.strokeStyle = "transparent";
    } else {
        ctx.strokeStyle = "white";
    }
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw a circular background for the display name
    const circleRadius = Math.min(width, height) / 6; // Adjust the radius as needed
    ctx.beginPath();
    ctx.arc(x + width / 2, y + height / 2, circleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "white";
    ctx.fill();
    ctx.closePath();

    // Draw text in the center of the circle
    ctx.fillStyle = "black"; // Adjust the text color as needed
    ctx.font = "bold 13px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    const circleRadiusForImage = Math.min(width, height) / 3;
    if (!participant.bVideoOn) {
        if (participant.userIdentity) {
            // Load the image
            const image = new Image();
            const imageSize = 2 * circleRadiusForImage; // Double the circle radius for width and height of the image
            image.onload = () => {
                ctx.save(); // Save the current drawing state
                ctx.beginPath();
                ctx.arc(
                    x + width / 2,
                    y + height / 2,
                    circleRadiusForImage,
                    0,
                    2 * Math.PI
                );
                ctx.closePath();
                ctx.clip(); // Clip the region to draw the image only within the circle
                ctx.drawImage(
                    image,
                    x + width / 2 - circleRadiusForImage,
                    y + height / 2 - circleRadiusForImage,
                    imageSize,
                    imageSize // Width and height of the image
                ); // Draw the image
                ctx.restore(); // Restore the previous drawing state
            };
            image.src = url_configs.imageDocumentPath + participant.userIdentity; // URL of the image
        } else {
            // Draw text if no image is available
            ctx.fillText(
                getInitialsFromFullName(participant.displayName),
                x + width / 2,
                y + height / 2
            );
        }
    }
    // Draw text at the bottom of the participant card

    let textY = y + height + 10; // Adjust the vertical position as needed
    let textX = x + width / 2;
    if (participant.bVideoOn == true) {
        textY = textY + 4;
        textX = textX + 5;
    }
    ctx.fillStyle = "white"; // Adjust the text color as needed
    ctx.font = "12px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(displayName, textX, textY);
    // Draw the icon if the participant is muted
    if (participant.bVideoOn == true || isStartSelfVideo == true) {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 105, y + height + 14, 20);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    } else {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 105, y + height - 23, 25);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    }
};

const drawLearnerSelfVideoCard = (ctx, x, y, width, height, participant, isStartSelfVideo) => {
    // Draw a colored rectangle with rounded corners
    if (participant.bVideoOn == true) {
        ctx.fillStyle = 'transparent';
    } else if (isStartSelfVideo == true) {
        ctx.fillStyle = 'transparent';
    } else {
        ctx.fillStyle = '#2e343a';
    }
    // ctx.fillStyle = !participant.bVideoOn ? '#2e343a' : 'transparent';
    // ctx.fillStyle = !isStartSelfVideo ? '#2e343a' : 'transparent';
    var displayName = participant.displayName.split(' ')[0];
    // Increase the values in the quadraticCurveTo for more rounded corners
    const borderRadius = 30; // Adjust the value as needed
    ctx.beginPath();
    ctx.moveTo(x + borderRadius, y);
    ctx.lineTo(x + width - borderRadius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
    ctx.lineTo(x + width, y + height - borderRadius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height);
    ctx.lineTo(x + borderRadius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
    ctx.lineTo(x, y + borderRadius);
    ctx.quadraticCurveTo(x, y, x + borderRadius, y);
    ctx.closePath();
    ctx.fill();

    // Draw a border with a width of 2px and a solid white color
    if (participant.bVideoOn == true) {
        ctx.strokeStyle = 'transparent';
    } else if (isStartSelfVideo == true) {
        ctx.strokeStyle = 'transparent';
    } else {
        ctx.strokeStyle = 'white';
    }
    // ctx.strokeStyle = !participant.bVideoOn ? 'white' : 'white';
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw a circular background for the display name
    const circleRadius = Math.min(width, height) / 6; // Adjust the radius as needed
    ctx.beginPath();
    ctx.arc(x + width / 2, y + height / 2, circleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.closePath();

    // Draw text in the center of the circle
    ctx.fillStyle = 'black'; // Adjust the text color as needed
    ctx.font = 'bold 13px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(getInitialsFromFullName(participant.displayName), x + width / 2, y + height / 2);

    // Draw text at the bottom of the participant card
    let textY = y + height + 10; // Adjust the vertical position as needed
    let textX = x + width / 2;
    if (participant.bVideoOn == true) {
        textY = textY + 2;
        textX = textX + 5
    } else if (isStartSelfVideo == true) {
        textY = textY + 2;
        textX = textX + 5
    }

    ctx.fillStyle = 'white'; // Adjust the text color as needed
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(displayName, textX, textY);
    // Draw the icon if the participant is muted
    if (participant.bVideoOn == true || isStartSelfVideo == true) {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 100, (y + height + 12), 20);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    } else {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 105, y + height - 23, 25);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    }

};

// Function to draw the icon to the bottom right of the circle
const drawLearnerIcon = (context, icon, x, y, iconSize) => {
    context.drawImage(icon, x - iconSize / 2, y - iconSize / 2, iconSize, iconSize);
};

const drawExpertParticipantCard = (ctx, x, y, width, height, participant, isStartSelfVideo) => {
    // Draw a colored rectangle with rounded corners
    // ctx.fillStyle = '#2e343a';
    ctx.fillStyle = '#fff';
    var displayName = participant.displayName.split(' ')[0];
    // Increase the values in the quadraticCurveTo for more rounded corners
    const borderRadius = 30; // Adjust the value as needed
    ctx.beginPath();
    ctx.moveTo(x + borderRadius, y);
    ctx.lineTo(x + width - borderRadius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
    ctx.lineTo(x + width, y + height - borderRadius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height);
    ctx.lineTo(x + borderRadius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
    ctx.lineTo(x, y + borderRadius);
    ctx.quadraticCurveTo(x, y, x + borderRadius, y);
    ctx.closePath();
    ctx.fill();

    // Draw text at the bottom of the participant card
    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.fillRect(x, 645, width, 80);
    ctx.closePath();

    ctx.fillStyle = 'white'; // Adjust the text color as needed
    ctx.font = '25px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(displayName, x + 100, (y + height / 2) + 145);

    // Draw the icon if the participant is muted
    if (participant.bVideoOn == true || isStartSelfVideo == true) {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 115, y + height + 10, 20);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    } else {
        if (participant.muted == undefined || participant.muted) {
            // Load the icon image
            const icon = new Image();
            icon.onload = () => {
                drawLearnerIcon(ctx, icon, x + width - 105, y + height - 23, 25);
            };
            icon.src = assets.zoomControls.muted; // Replace with the actual path
        }
    }

};

// Function to draw a card
const drawCard = (context, participant, x, y, width, height, borderRadius, bgColor) => {
    // Draw the card
    context.fillStyle = bgColor;
    context.beginPath();
    context.moveTo(x + borderRadius, y);
    context.lineTo(x + width - borderRadius, y);
    context.arcTo(x + width, y, x + width, y + borderRadius, borderRadius);
    context.lineTo(x + width, y + height - borderRadius);
    context.arcTo(x + width, y + height, x + width - borderRadius, y + height, borderRadius);
    context.lineTo(x + borderRadius, y + height);
    context.arcTo(x, y + height, x, y + height - borderRadius, borderRadius);
    context.lineTo(x, y + borderRadius);
    context.arcTo(x, y, x + borderRadius, y, borderRadius);
    context.closePath();
    context.fill();

    // Add box shadow for elevation
    context.shadowColor = 'rgba(0, 0, 0, 0.3)';
    context.shadowBlur = 5;
    context.shadowOffsetX = 2;
    context.shadowOffsetY = 2;
};

const drawCenterInitialCard = (context, participant, x, y, width, height, borderRadius, bgColor) => {
    // Draw a circular background for the display name
    const circleRadius = Math.min(width, height) / 6; // Adjust the radius as needed
    context.beginPath();
    context.arc(x + width / 2, y + height / 2, circleRadius, 0, 2 * Math.PI);
    context.fillStyle = 'white';
    context.fill();
    context.closePath();

    // Draw text in the center of the circle
    context.fillStyle = 'black'; // Adjust the text color as needed
    context.font = 'bold 22px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(getInitialsFromFullName(participant.displayName), x + width / 2, y + height / 2);
};

// Function to draw the icon to the left bottom of the card with space
const drawIcon = (context, icon, x, y, iconSize) => {
    //context.scale(-1, 1);
    context.drawImage(icon, x, y - iconSize, iconSize, iconSize);
};

const drawDisplayName = (context, x, y, columnWidth, displayName, padding) => {
    // Save the current context state
    //context.save();

    // Draw text with padding, centered within the card
    context.fillStyle = 'blue';
    context.font = '18px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle'; // Set text baseline to middle
    //context.fillText(displayName, x + columnWidth / 2, y - padding);
    // // Translate to the position for drawing the text without flipping
    context.translate(x + columnWidth / 2, y - padding);

    context.scale(-1, 1);
    // // Draw the text with padding, aligned to the bottom
    context.fillText(displayName, 0, 0);

    // // Reset the transformation to its original state
    //context.restore();

};

//#endregion

const zoomSessionLogsRegister = async (data) => {
    try {
        const response = await http.post(api_path.zoomSessionLogsRegister, data, {
            headers: authHeader(),
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getZoomSessionLogsBySlug = async (slug) => {
    try {
        const response = await http.get(api_path.getZoomSessionLogsBySlug, {
            params: {
                slug: slug,
            },
        });

        return response.data;
    } catch (error) {
        // Handle errors (e.g., network error, server error)
        console.error("Error fetching Zoom session logs by slug:", error);
        throw error;
    }
};

const ZoomService = {
    openSubsessions,
    assignUserToSubsession,
    renderExpertSelfVideo,
    stopExpertSelfVideo,
    stopRenderExpertVideoFromLearnerView,
    renderExpertVideoInLearnerView,
    renderParticipantSelfVideo,
    renderParticipantVideoInGallery,
    renderParticipantVideoInExpertHorizontalView,
    renderParticipantVideoInLearnerVerticalView,
    startAudio,
    startAudioAndMuteMic,
    muteAudio,
    unMuteAudio,
    muteAudioByUserId,
    stopAudio,
    stopRenderVideo,
    stopAllRenderedVideo,
    stopAllRenderedVideoExceptCurrentUser,
    zoomSessionLogsRegister,
    getZoomSessionLogsBySlug
};
export default ZoomService;