import React, { useEffect, Fragment, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { SnackbarProvider, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Avatar,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import account from "../../../_mock/account";

import "./register-v2.css";

//COMPONENTS
import Iconify from "../../../components/Iconify";

import assets from "../../../config/assets";

import {
  setCheckoutData,
  setCurrentLocationDetail,
} from "../../../store/slices/settingsSlice";
import {
  convertTime,
  getCurrentUserData,
  getCharacterValidationError,
  checkIsINR,
} from "../../../utils/util";
import { fullDate } from "../../../utils/formatTime";

import ParentDataService from "../../../services/parent-service";
import AuthService from "../../../services/auth/auth.service";
import CourseService from "../../../services/courses-service";
import UtilService from "../../../services/util.service";

import icons_config from "../../../config/icons";
import tooltip_messages from "../../../config/tooltip";
import AffiliateService from "../../../services/affiliate-service";
import storage_configs from "../../../config/storage-config";
import ShareLicensesService from "../../../services/share-licenses-service";

import route_config from "../../../config/route_config";

// const getCharacterValidationError = (str) => {
//   return `Your password must have at least 1 ${str} character`;
// };

// REGISTER FORM VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  parentName: Yup.string().required("Parent name is required"),
  parentLastName: Yup.string().required("Last name is required"),
  parentEmail: Yup.string()
    .required("Email is required")
    .email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/\W/, getCharacterValidationError("symbol")),
  country: Yup.string().required("Country is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(9, "Invalid mobile number")
    .max(15, "Invalid mobile number"),
  childAge: Yup.string().required("Select child age"),
  referralCode: Yup.string(),
  conditionsagree: Yup.bool().oneOf([true], "Accept Terms is required"),
});
const ParentRegisterV2 = () => {
  const currentLocationDetail = useSelector(
    (state) => state.settings.currentLocationDetail
  );

  const dispatch = useDispatch();
  const getIcon = (name) => <Iconify icon={name} width={15} height={15} />;

  // showtab function
  const [showtab, setShowtab] = useState(1);

  const [courseInfo, setCourseInfo] = useState();
  const [courseStreamList, setCourseStreamList] = useState([]);

  const [courseStream, setCourseStream] = useState();

  const [parentName, setParentName] = useState();
  const [defaultCountry, setDefaultCountry] = useState("USA");

  const [openEditCoursePanel, setOpenEditCoursePanel] = useState(false);
  const [selectedCourseSchedule, setSelectedCourseSchedule] = useState();

  const handletabclick = (e) => {
    navigate("/parent/my-courses");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (data) {
      if (data.mobileNumber) {
        const parts = data.mobileNumber.split(" ");
        const cleanedNumber = parts.slice(1).join(" ");
        if (cleanedNumber.replace(" ", "").length < 9) {
          setIsSubmitting(false);
          enqueueSnackbar("Invalid mobile number", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          return;
        }
        if (cleanedNumber.replace(" ", "").length > 15) {
          setIsSubmitting(false);
          enqueueSnackbar("Invalid mobile number", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          return;
        }
      }
      var userData = {
        firstname: data.parentName,
        lastname: data.parentLastName,
        email: data.parentEmail,
        password: data.password,
        mobile: data.mobileNumber,
        childAge: data.childAge,
        country: data.country,
        createdby: "0",
        conditionsagree: data.conditionsagree.toString(),
        referral_code: data.referralCode,
      };
      console.log(userData);

      AffiliateService.getAffiliateMemberByRefCode(data.referralCode)
        .then((response) => {
          console.log(response);
          if (
            response &&
            response.data &&
            response.data.length > 0 &&
            !navigatedFrom &&
            navigatedFrom !== route_config.shareLicenses
          ) {
            ParentDataService.register(userData)
              .then((response) => {
                setIsSubmitting(false);
                console.log(response);
                if (
                  response &&
                  response.data &&
                  response.data.success === "true"
                ) {
                  // variant could be success, error, warning, info, or default
                  enqueueSnackbar("Parent registration is successful", {
                    variant: "success",
                    key: "topright",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  parentLogin(userData.email, userData.password);
                } else if (
                  response &&
                  response.data &&
                  response.data.success === "false"
                ) {
                  enqueueSnackbar(response.data.data, {
                    variant: "warning",
                    key: "topright",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                } else {
                  enqueueSnackbar(
                    "Something went wrong. Please contact Admin.",
                    {
                      variant: "warning",
                      key: "topright",
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                  );
                }
              })
              .catch((error) => {
                setIsSubmitting(false);
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    key: "topright",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                  enqueueSnackbar(
                    "Something went wrong. Please contact Admin.",
                    {
                      variant: "error",
                      key: "topright",
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                  );
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                  enqueueSnackbar(error.message, {
                    variant: "error",
                    key: "topright",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }
              });
          } else if (
            response &&
            response.data &&
            response.data.length > 0 &&
            navigatedFrom &&
            navigatedFrom === route_config.shareLicenses
          ) {
            console.log("navigatedFrom", response.data);
            console.log("licence userData", userData);
            setIsSubmitting(false);
            console.log(shareAccessDetails);

            var licenceAccessUserData = {
              firstname: data.parentName,
              lastname: data.parentLastName,
              email: data.parentEmail,
              password: data.password,
              mobile: data.mobileNumber,
              childAge: data.childAge,
              country: data.country,
              createdby: "0",
              conditionsagree: data.conditionsagree.toString(),
              referral_code: data.referralCode,
              licenses: shareAccessDetails.licenses,
              pod_id: shareAccessDetails.pod_id,
            };

            console.log("licenceAccessUserData", licenceAccessUserData);

            ShareLicensesService.shareLicensesCustomerRegisteration(
              licenceAccessUserData
            ).then((response) => {
              setIsSubmitting(false);
              console.log(response);
              if (
                response &&
                response.data &&
                response.data.success === "true"
              ) {
                // variant could be success, error, warning, info, or default
                enqueueSnackbar("New Parent registration is successful", {
                  variant: "success",
                  key: "topright",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
                parentLogin(userData.email, userData.password);
              }
            });
          } else {
            enqueueSnackbar("Invalid referral code.", {
              variant: "warning",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            enqueueSnackbar("Something went wrong. Please contact Admin.", {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            enqueueSnackbar(error.message, {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        });
    }
  };

  const getCurrentCountryInfoByIpAddress = () => {
    UtilService.getCurrentCountryInfoByIpAddress()
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          dispatch(setCurrentLocationDetail(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const parentLogin = (email, password) => {
    AuthService.parent_login(email, password)
      .then((response) => {
        if (response && response.success === "true") {
          // variant could be success, error, warning, info, or default

          if (courseInfo && courseInfo.isFree) {
            if (navigatedFrom && navigatedFrom === route_config.shareLicenses) {
              navigate("/parent/my-courses");
            } else {
              let model = {
                coursestreamid: courseStream.id.toString(),
                memberid: getCurrentUserData().id.toString(),
                paidon: fullDate(),
                linkno: "1234",
                amount: "0.00",
                quantity: 1,
                currency: currentLocationDetail
                  ? currentLocationDetail.currency.toLowerCase()
                  : "usd",
              };
              CourseService.assignCourseToParent(model)
                .then((response) => {
                  console.log(response);
                  setShowtab(2);
                })
                .catch((error) => {
                  navigate("/parent/my-courses");
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    enqueueSnackbar(error.response.data.message, {
                      variant: "error",
                      key: "topright",
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    enqueueSnackbar(
                      "Something went wrong. Please contact Admin.",
                      {
                        variant: "error",
                        key: "topright",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      }
                    );
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                    enqueueSnackbar(error.message, {
                      variant: "error",
                      key: "topright",
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                  }
                });
            }
          } else {
            if (navigatedFrom && navigatedFrom === route_config.shareLicenses) {
              navigate("/checkout");
            } else {
              let model = {
                coursestreamid: courseStream.id.toString(),
                memberid: getCurrentUserData().id.toString(),
                paidon: fullDate(),
                linkno: "1234",
                amount: checkIsINR(
                  currentLocationDetail ? currentLocationDetail.currency : "USD"
                )
                  ? courseInfo?.price_inr
                  : courseInfo?.price,
                quantity: 1,
                currency: currentLocationDetail
                  ? currentLocationDetail.currency.toLowerCase()
                  : "usd",
              };
              console.log("Assign Course Model", model);
              localStorage.setItem("checkout-data", JSON.stringify(model));
              dispatch(setCheckoutData(model));
              navigate("/checkout");
            }
          }
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          enqueueSnackbar(error.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      });
  };
  const [navigatedFrom, setNavigatedFrom] = useState();
  const [shareAccessDetails, setShareAccessDetails] = useState();
  useEffect(() => {
    getCurrentCountryInfoByIpAddress();
    setCourseStreamList(
      JSON.parse(localStorage.getItem("course-streams-list"))
    );
    setCourseStream(JSON.parse(localStorage.getItem("selected-course-to-buy")));
    setCourseInfo(JSON.parse(localStorage.getItem("course-info")));
    setParentName(localStorage.getItem("parent-name"));
    setValue("parentEmail", localStorage.getItem("parent-email"));
    setValue("parentName", localStorage.getItem("parent-name"));
    const ref_code = localStorage.getItem("ref_code");
    if (ref_code) {
      setValue("referralCode", ref_code);
    }

    if (
      localStorage.getItem(storage_configs.localStorage.navigatedFrom) &&
      localStorage.getItem(storage_configs.localStorage.navigatedFrom) ===
        route_config.shareLicenses
    ) {
      setNavigatedFrom(
        localStorage.getItem(storage_configs.localStorage.navigatedFrom)
      );
      setShareAccessDetails(
        JSON.parse(localStorage.getItem("share-access-details"))
      );
    }
  }, []);

  const formatScheduleDate = (obj) => {
    let formatedDate =
      moment(obj?.startdate).format("dddd") +
      ", " +
      moment(obj?.startdate).format("MMM Do") +
      "(" +
      convertTime(obj?.from?.from) +
      ")";
    return formatedDate;
  };

  // const onFormChange = (e, field) => {
  //   //console.log(e.target.value)
  //   if (field == 'parentName') {
  //     setParentName(e.target.value);
  //   } else {
  //     return;
  //   }
  // }

  const openEditCourse = () => {
    setOpenEditCoursePanel(!openEditCoursePanel);
    setSelectedCourseSchedule(courseStream.id);
  };

  const getCourseStreamValue = (obj) => {
    const formatedDt =
      moment(obj?.startdate).format("dddd") +
      ", " +
      moment(obj?.startdate).format("MMM Do") +
      "(" +
      convertTime(obj?.from?.from) +
      ")";
    return formatedDt;
  };

  const selectCourseBySelectEvent = (event) => {
    if (event.target.value) {
      setSelectedCourseSchedule(event.target.value);
      console.log(event.target.value);
      const streams = courseStreamList;
      const getStream = streams.filter((e) => e.id == event.target.value)[0];
      localStorage.setItem("selected-course-to-buy", JSON.stringify(getStream));
      setCourseStream(
        JSON.parse(localStorage.getItem("selected-course-to-buy"))
      );
      console.log(getStream);
      openEditCourse();
    }
  };

  const naviateTo = (route) => {
    navigate(route);
  };
  return (
    <div className="h-100">
      <div className="row h-100 ms-0 me-0">
        <div className="col-lg-6 left-column-pr-v2 p-0" style={{ zIndex: "1" }}>
          <div className="left-column-pr-v2-section">
            <img
              src={assets.course_v2.form_meeku_logo}
              onClick={(e) => naviateTo("/")}
              className="meeku-icon-prv2 cursor-pointer"
            ></img>

            <div className="mt-4">
              <img
                src={assets.course_v2.section_1_group_img}
                className=""
              ></img>
              {/* <img src={courseInfo?.document?.documenturl} className=''></img> */}
            </div>
            <p className="Coding-With-Title-prv2 mt-3">{courseInfo?.name} </p>

            <Card className="Coding-With-card-prv2">
              <Box>
                <div>
                  <div className="d-flex align-items-center">
                    <p className="Coding-With-card-le-text-prv2 mb-0 me-1">
                      Course fee
                    </p>
                    <p className="Coding-With-card-ri-text-prv2 mb-0">
                      $ {!courseInfo?.isFree ? courseInfo?.price : "0.00"}
                    </p>
                  </div>
                  {/* <div className="d-flex align-items-center">
                      <p className="Coding-With-card-le-text-prv2 mb-0 me-1">Weekly Hours</p>
                      <p className="Coding-With-card-ri-text-prv2 mb-0">2 hours / week / 3 weeks</p>
                    </div> */}
                </div>
              </Box>
            </Card>

            {courseInfo?.isFree == true ? (
              <div className="d-flex align-items-center mt-3">
                <p className="Coding-With-card-le-text-prv2">
                  Thank you for reaching this step! Please create your account,
                  book a slot, and we'll confirm shortly. Limited slots, act
                  fast!
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <img src={assets.homev3.m_icon} className="m-icon-prv2"></img>
          <div className="d-flex justify-content-end">
            <img
              src={assets.course_v2.yellow_star_img}
              className="yellow_star_img_prv2 me-3"
            ></img>
          </div>
        </div>
        <div className="col-lg-6 right-column-pr-v2 p-0">
          {/* <div className="d-flex justify-content-end mt-3">
            <img src={assets.homev3.userIcon} className='userIcon-prv2 me-3'></img>
          </div> */}
          <div
            className="tab-content right-column-pr-v2-section mt-4"
            id="pills-tabContent"
          >
            <div
              className={
                showtab === 1
                  ? "tab-pane fade show active p-0"
                  : "tab-pane fade show p-0"
              }
            >
              <div>
                <p className="form-hi-text-pr-v2 m-0">Hi {parentName}</p>
                <p className="Let-us-know-text-pr-v2">
                  Let us know your contact information
                </p>
              </div>
              {courseStream ? (
                <Card className="free-course-reg-card-prv2">
                  <div className="reg-card-prv2">
                    <p className="free-course-reg-pr-v2 m-0">
                      {courseInfo?.isFree
                        ? `FREE COURSE REGISTRATION`
                        : `COURSE REGISTRATION`}
                    </p>
                  </div>
                  <div className="reg-card-prv2-1">
                    {navigatedFrom &&
                    navigatedFrom === route_config.shareLicenses ? (
                      ""
                    ) : (
                      <div>
                        {!openEditCoursePanel ? (
                          <Tooltip
                            title={tooltip_messages.registerParent.editSchedule}
                            placement="bottom"
                          >
                            <span
                              className="edit-course-in-registration"
                              onClick={(e) => openEditCourse(e)}
                            >
                              {getIcon(icons_config.editFilled)}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={tooltip_messages.close}
                            placement="bottom"
                          >
                            <span
                              className="edit-course-in-registration"
                              onClick={(e) => openEditCourse(e)}
                            >
                              {getIcon(icons_config.no)}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}

                    <p className="course-schedule-pr-v2 m-0">Course Schedule</p>
                    {!openEditCoursePanel ? (
                      <p className="course-schedule-date-pr-v2 m-0">
                        {formatScheduleDate(courseStream)}
                      </p>
                    ) : (
                      <div>
                        <FormControl fullWidth margin="dense">
                          <InputLabel id="course-schedule">
                            Course Schedule
                          </InputLabel>
                          <Select
                            labelId="course-schedule"
                            id="course-schedule"
                            name="course-schedule"
                            label="Course Schedule"
                            value={selectedCourseSchedule}
                            onChange={(e) => selectCourseBySelectEvent(e)}
                          >
                            {courseStreamList.map((obj, idx) => {
                              return (
                                <MenuItem
                                  key={`course-schedule-key-` + idx}
                                  value={obj.id}
                                >
                                  {getCourseStreamValue(obj)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </Card>
              ) : (
                ""
              )}

              <Card className="parent-reg-card-prv2 mt-2">
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* PARENT NAME*/}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="parentName"
                        name="parentName"
                        label="Parent Name"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentName)}
                        helperText={
                          errors.parentName ? errors.parentName.message : ""
                        }
                        {...register("parentName", { required: true })}
                      />
                    </Grid>
                    {/* PARENT LASTNAME */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="id-parentLastName"
                        name="parentLastName"
                        label="Last Name"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentLastName)}
                        helperText={
                          errors.parentLastName
                            ? errors.parentLastName.message
                            : ""
                        }
                        {...register("parentLastName", { required: true })}
                      />
                    </Grid>
                    {/* EMAIL FIELD */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="parentEmail"
                        name="parentEmail"
                        label="Email"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentEmail)}
                        helperText={
                          errors.parentEmail ? errors.parentEmail.message : ""
                        }
                        {...register("parentEmail", { required: true })}
                      />
                    </Grid>
                    {/* PASSWORD */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="new-password"
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.password)}
                        helperText={
                          errors.password ? errors.password.message : ""
                        }
                        {...register("password", { required: true })}
                      />
                    </Grid>
                    {/* COUNTRY */}
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                          labelId="country-label"
                          id="country"
                          name="country"
                          label="Country"
                          defaultValue={defaultCountry}
                          error={Boolean(errors.country)}
                          {...register("country", { required: true })}
                          helperText={
                            errors.country ? errors.country.message : ""
                          }
                        >
                          <MenuItem value="India">India</MenuItem>
                          <MenuItem value="USA">
                            United States of America
                          </MenuItem>
                        </Select>
                        {errors.country ? (
                          <FormHelperText className="requiredField">
                            {errors.country.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    {/* MOBILE NUMBER */}
                    <div className="form-group">
                      <div className="input-group">
                        <FormControl fullWidth required margin="dense">
                          <PhoneInput
                            id="mobile-number"
                            name="mobileNumber-name"
                            label="Mobile number"
                            className="w-100 border border-gray-300 bg-white px-4 py-3 text-sm shadow-none parent-reg-mobile-number"
                            maxLength="15"
                            defaultCountry="US"
                            international
                            countryCallingCodeEditable={true}
                            placeholder="Enter phone number with country code"
                            error={Boolean(errors.mobileNumber)}
                            {...register("mobileNumber", { required: true })}
                          />
                          {errors.mobileNumber ? (
                            <FormHelperText className="requiredField">
                              {errors.mobileNumber.message}
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </div>
                    </div>
                    {/* CHILD AGE */}
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="child-age-label">Select Age</InputLabel>
                        <Select
                          labelId="child-age-label"
                          id="childAge"
                          name="childAge"
                          label="Child Age"
                          error={Boolean(errors.childAge)}
                          {...register("childAge", { required: true })}
                          helperText={
                            errors.childAge ? errors.childAge.message : ""
                          }
                        >
                          <MenuItem value="5-9">5-9 Years</MenuItem>
                          <MenuItem value="10-14">10-14 Years</MenuItem>
                        </Select>
                        {errors.childAge ? (
                          <FormHelperText className="requiredField">
                            {errors.childAge.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    {/* REFERRAL CODE */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="id-referralCode"
                        name="referralCode"
                        label="Referral code"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.referralCode)}
                        helperText={
                          errors.referralCode ? errors.referralCode.message : ""
                        }
                        {...register("referralCode", { required: false })}
                      />
                    </Grid>
                    {/* ACCEPT TERMS AND CONDITIONS */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Controller
                            control={control}
                            name="conditionsagree"
                            defaultValue="false"
                            inputRef={register()}
                            render={({ field: { onChange } }) => (
                              <Checkbox
                                color="primary"
                                onChange={(e) => onChange(e.target.checked)}
                              />
                            )}
                          />
                        }
                        label={
                          <Typography
                            color={errors.conditionsagree ? "error" : "inherit"}
                          >
                            By proceeding, you agree to our{" "}
                            <a href="/terms-and-conditions">
                              Terms and Conditions
                            </a>{" "}
                            *
                          </Typography>
                        }
                      />
                      <br />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.conditionsagree
                          ? "(" + errors.conditionsagree.message + ")"
                          : ""}
                      </Typography>
                    </Grid>
                    <div className="d-flex mt-4 pb-3">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="Submit-btn-prv2"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </form>
                  {/* <a href="#" onClick={() => handletabclick(2)}>
                    go to next
                  </a> */}
                </Box>
              </Card>
            </div>
            <div
              className={
                showtab === 2
                  ? "tab-pane fade show active p-0"
                  : "tab-pane fade show p-0"
              }
            >
              <Card className="parent-reg-card-prv2 mt-2">
                <Box>
                  <div className="d-flex justify-content-center mt-2">
                    <img
                      src={assets.course_v2.confetti_img_pr_v2}
                      className="confetti_img_pr_v2"
                      style={{ width: "85px" }}
                    ></img>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <img
                      src={assets.course_v2.yeah_img_pr_v2}
                      className="yeah_img_pr_v2"
                      style={{ width: "185px" }}
                    ></img>
                  </div>
                  <p className="registration-complete-text-prv2 pt-4">
                    Your FREE course registration is complete
                  </p>

                  <div>
                    <p className="complete-qu-text-prv2 m-0">What’s next?</p>
                    <p className="complete-an-text-prv2 m-0">
                      We sent you the detailed instructions on how to join the
                      online class to your registered email.
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="complete-qu-text-prv2 m-0">
                      Talk to your course advisor
                    </p>
                    <p className="complete-an-text-prv2 m-0">admin@meeku.in</p>
                  </div>

                  <div className="d-flex mt-4 pb-3">
                    <div
                      className="Submit-btn-prv2"
                      onClick={(e) => handletabclick(e)}
                    >
                      <span>Go to Courses</span>
                    </div>
                  </div>
                </Box>
              </Card>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <img
              src={assets.homev3.section3_star_img}
              className="section3_star_img_prv2 me-3"
            ></img>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img
              src={assets.homev3.interactive_section_mm_img}
              className="interactive_section_mm_img_prv2"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentRegisterV2;
