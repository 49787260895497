import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";

const getallparents = (data) => {
  return http.get(api_path.a_getallparents, { headers: authHeader() });
};

const getParentProfileById = (data) => {
  return http
    .get(api_path.a_getParentProfileById + "/" + data, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const getalllearners = (data) => {
  return http.get(api_path.a_getalllearners, { headers: authHeader() });
};

const getallexperts = (data) => {
  return http.get(api_path.a_getallexperts, { headers: authHeader() });
};

const getAllActiveExperts = (data) => {
  return http
    .get(api_path.a_getAllActiveExperts, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getAllActiveQuizTemplate = (data) => {
  return http
    .get(api_path.quiz_getAllActiveQuizTemplate, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const createQuizTemplate = (data) => {
  return http
    .post(api_path.quiz_createQuizTemplate, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getAllActiveGames = (data) => {
  return http
    .get(api_path.getAllActiveGames, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const uploadGames = (data) => {
  return http
    .post(api_path.upload_games, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const deleteGame = (data) => {
  return http
    .put(api_path.deleteGame + "/" + data, null, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const deployGame = (data) => {
  return http
    .get(api_path.deployGame + "/" + data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const inviteExperts = (data) => {
  return http
    .post(api_path.a_inviteexperts, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

//region WHITEBOARD TEMPLATES
const createWhiteBoardTemplates = (data) => {
  return http
    .post(api_path.createWhiteBoardTemplates, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getAllActiveWhiteboardTemplates = (data) => {
  return http
    .get(api_path.getAllActiveWhiteboardTemplates, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const deleteWhiteboardTemplate = (data) => {
  return http
    .delete(api_path.deleteWhiteboardTemplate + "/" + data, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};
//endregion

//#region IMAGE LIBRARIES
const addLibrary = async (data) => {
  const response = await http.post(api_path.imageLibraries, data, {
    headers: authHeader(),
  });
  return response.data;
};

const getAllLibrary = async () => {
  const response = await http.get(api_path.imageLibraries, {
    headers: authHeader(),
  });
  return response.data;
};

const inActiveLibrary = async (id, data) => {
  const response = await http.put(
    api_path.imageLibraries + "/delete/" + id,
    data,
    { headers: authHeader() }
  );
  return response.data;
};

const changeCustomerPassword = async (id, newPassword) => {
  try {
    const response = await http.post(
      api_path.a_changeCustomerPassword,
      {
        id: id,
        password: newPassword,
      },
      {
        headers: authHeader(),
      }
    );
    console.log("changeKidPassword", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const getAllExpertStreamDetails = async () => {
  const response = await http.get(api_path.a_getAllExpertStreamDetails, {
    headers: authHeader(),
  });
  return response.data;
};

const getLearnerStreamDetailsById = (id) => {
  return http
    .get(api_path.a_getLearnerStreamDetailsById, {
      params: { id },
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

//#endregion
const AdminService = {
  getallexperts,
  getAllActiveExperts,
  getParentProfileById,
  getalllearners,
  getallparents,
  getAllActiveQuizTemplate,
  createQuizTemplate,
  getAllActiveGames,
  uploadGames,
  deleteGame,
  deployGame,
  inviteExperts,
  createWhiteBoardTemplates,
  getAllActiveWhiteboardTemplates,
  deleteWhiteboardTemplate,
  addLibrary,
  getAllLibrary,
  inActiveLibrary,
  changeCustomerPassword,
  getAllExpertStreamDetails,
  getLearnerStreamDetailsById,
};
export default AdminService;
