import React, { useEffect, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    Avatar,
    Card,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText,
    Tooltip
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from 'notistack';

import './success-page.css';

//CONFIGS
import assets from "../../config/assets";

//SERVICES
import CourseService from "../../services/courses-service";
import { getCurrentUserData } from "../../utils/util";

const SuccessPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [courseInfo, setCourseInfo] = useState();
    const handletabclick = (e) => {
        navigate('/parent/my-courses');
    };

    const navigate = useNavigate();

    useEffect(() => {
        setCourseInfo(JSON.parse(localStorage.getItem('course-info')));
        if (getCurrentUserData()) {
            const query = new URLSearchParams(window.location.href);
            if (query) {
                const redirect_status = query.get('redirect_status');
                if (redirect_status && redirect_status == 'succeeded') {
                    assignCourseToParent()
                    //navigate('/success');
                }else{
                    const free_course_status = query.get('free_course_status');
                    if (free_course_status && free_course_status == 'succeeded') {
                        navigate('/success');
                    }
                    // else{
                    //     enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    // }
                }
            }
        } else {
            navigate('/')
        }
    }, []);

    const assignCourseToParent = () => {
        let checkData = JSON.parse(localStorage.getItem('checkout-data'));
        if (checkData) {
            CourseService.assignCourseToParent(checkData)
                .then(response => {
                    console.log(response);
                    navigate('/success');
                })
                .catch(error => {
                    navigate('/');
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    }
                });
        }
    }

    return (
        <div className="h-100">
            <div className="row h-100 ms-0 me-0">
                <div className="col-lg-6 left-column-sp p-0" style={{ zIndex: "1" }}>
                    <div className="left-column-sp-section">
                        <div className="">
                            <img src={assets.course_v2.form_meeku_logo} className='meeku-icon-sp'></img>
                        </div>
                        <div className='mt-4'>
                            <img src={assets.course_v2.section_1_group_img} className=''></img>
                        </div>
                        <p className="Coding-With-Title-sp mt-3">Coding With Bricks LEGO Based Education For Kids</p>
                    </div>
                    <img src={assets.homev3.m_icon} className='m-icon-sp'></img>
                    <div className="d-flex justify-content-end">
                        <img src={assets.course_v2.yellow_star_img} className='yellow_star_img_sp me-3'></img>
                    </div>
                </div>
                <div className="col-lg-6 right-column-pr-sp p-0">
                    <div className="right-column-pr-sp-section mt-4">
                        <Card className="parent-reg-card-sp mt-2">
                            <Box>
                                <div className="d-flex justify-content-center mt-2">
                                    <img src={assets.course_v2.confetti_img_pr_v2} className='confetti_img_pr_sp' style={{ width: "85px" }}></img>
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    <img src={assets.course_v2.yeah_img_pr_v2} className='yeah_img_pr_sp' style={{ width: "185px" }}></img>
                                </div>
                                {courseInfo?.isFree == true ?
                                    <p className="registration-complete-text-sp pt-4">Your FREE course registration is complete</p> :
                                    <p className="registration-complete-text-sp pt-4">Your course registration is complete</p>}

                                <div>
                                    <p className="complete-qu-text-sp m-0">What’s next?</p>
                                    <p className="complete-an-text-sp m-0">We sent you the detailed instructions on how to join the online class to your registered email.</p>
                                </div>

                                <div className="mt-3">
                                    <p className="complete-qu-text-sp m-0">Talk to your course advisor</p>
                                    <p className="complete-an-text-sp m-0">admin@meeku.in</p>
                                </div>

                                <div className="d-flex mt-4 pb-3">
                                    <div className='Submit-btn-sp' onClick={(e) => handletabclick(e)}>
                                        <span>Go to Courses</span>
                                    </div>
                                </div>
                            </Box>
                        </Card>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <img src={assets.homev3.section3_star_img} className='section3_star_img_sp me-3'></img>
                    </div>

                    <div className="d-flex justify-content-start mt-2">
                        <img src={assets.homev3.interactive_section_mm_img} className='interactive_section_mm_img_sp'></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
