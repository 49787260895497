import React from "react";
import "./transformative-learning.css";
import { Box, Card } from "@mui/material";
import { useState, useEffect } from "react";
import assets from "../../config/assets";
import short_logo from "../../assets/img/short_logo.png";

function FocussedSessionsCard() {
  return (
    <Card className="meeku-world-card mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.focused_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title m-0">Focused sessions</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text">
          Meeku features carefully curated{" "}
          <strong>power-packed sessions</strong> are short and engaging,{" "}
          <strong>maximizing children's screen time</strong> while providing an
          immersive and enjoyable experience.
        </p>
      </Box>
    </Card>
  );
}

function ProblemSolvingCard() {
  return (
    <Card className="meeku-world-card mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.solve_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title m-0">
            Solve real world problems
          </p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text">
          Our STEM platform offers{" "}
          <strong>hands-on experience in tackling complex challenges</strong>{" "}
          with practical problem-solving exercises.
        </p>
      </Box>
    </Card>
  );
}

function TeamLearningCard() {
  return (
    <Card className="meeku-world-card mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.team_based_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title m-0">Team-based learning</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text">
          Meeku involves{" "}
          <strong>collaborative approach promotes critical thinking</strong>,
          communication, and social skills, allowing children to work in teams
          with peers to solve problems.
        </p>
      </Box>
    </Card>
  );
}

function ExperiencedTutorsCard() {
  return (
    <Card className="meeku-world-card mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.experienced_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title m-0">Experienced Tutors</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text">
          At Meeku, maintain a 1:12 ratio of coaches to students, &{" "}
          <strong>
            encourage the development of problem-solving and STEM skills
          </strong>{" "}
          while making learning fun!
        </p>
      </Box>
    </Card>
  );
}

export default function TransformativeLearning() {
  const [selectedSection, setSelectedSection] = useState(null);
  const [displayedCard, setDisplayedCard] = useState(null);
  const [circleRotation, setCircleRotation] = useState(0);

  const rotateSection = (sectionName, degree) => {
    if (sectionName !== selectedSection) {
      setSelectedSection(sectionName);
      setDisplayedCard(sectionName);
      setCircleRotation(circleRotation + 90); // Rotate the circle by one degree
    } else {
      setSelectedSection(null);
      setDisplayedCard(null);
    }
  };

  const sectionClassName = (sectionName) =>
    `section ${sectionName} ${
      sectionName === selectedSection ? "circleactive" : ""
    }`;

  // temp
  useEffect(() => {
    rotateSection("FocussedSessions");
  }, []);

  // useEffect(() => {
  //     // Automatically rotate sections every 2 seconds
  //     const rotationInterval = setInterval(() => {

  //         const sections = ['FocussedSessions', 'ProblemSolving', 'TeamLearning', 'ExperiencedTutors'];
  //         const currentSectionIndex = sections.indexOf(selectedSection);
  //         const nextSectionIndex = (currentSectionIndex + 1) % sections.length;
  //         const nextSection = sections[nextSectionIndex];

  //         rotateSection(nextSection, nextSectionIndex * 90);
  //     }, 2000);

  //     return () => {
  //         clearInterval(rotationInterval); // Clean up the interval when the component unmounts
  //     };
  // }, [selectedSection, circleRotation]);

  return (
    <Box className="">
      <div className="section-3-container">
        <div className="section-3">
          <p className="sections-title-com m-0" style={{ lineHeight: "120%" }}>
            {" "}
            Meeku.World: A transformative learning<br></br> platform for the{" "}
            <strong>STEM</strong> ecosystem{" "}
          </p>
          <div className="d-flex justify-content-center">
            <img
              className="yellowLine-img-2"
              src={assets.homev3.yellowLine}
            ></img>
          </div>
          <div className="d-flex justify-content-end">
            <img
              className="section_star_img"
              src={assets.homev3.section3_star_img}
            ></img>
          </div>

          <div className="container mt-4">
            <div
              className="row align-items-center container-img-section"
              style={{ paddingBottom: "55px" }}
            >
              <div className="col-lg-6 col-md-6 col-12">
                <div className="image-container-circle">
                  <div
                    className="outer-circle"
                    style={{ transform: `rotate(${circleRotation}deg)` }}
                  >
                    <div
                      className={sectionClassName("FocussedSessions")}
                      onClick={() => rotateSection("FocussedSessions", 0)}
                    >
                      <div className="section-text-1">
                        {" "}
                        <svg viewBox="0 0">
                          <path
                            className="path-text-tr"
                            id="curve"
                            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                          />
                          <text className="section-text-all">
                            <textPath href="#curve">Focussed Sessions</textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div
                      className={sectionClassName("ProblemSolving")}
                      onClick={() => rotateSection("ProblemSolving", 90)}
                    >
                      <div className="section-text-2">
                        {" "}
                        <svg viewBox="0 0">
                          <path
                            className="path-text-tr"
                            id="curve"
                            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                          />
                          <text className="section-text-all">
                            <textPath href="#curve">Problem Solving</textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div
                      className={sectionClassName("TeamLearning")}
                      onClick={() => rotateSection("TeamLearning", 180)}
                    >
                      <div className="section-text-3">
                        {" "}
                        <svg viewBox="0 0">
                          <path
                            className="path-text-tr"
                            id="curve"
                            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                          />
                          <text className="section-text-all">
                            <textPath href="#curve">Team Learning</textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div
                      className={sectionClassName("ExperiencedTutors")}
                      onClick={() => rotateSection("ExperiencedTutors", 270)}
                    >
                      <div className="section-text-4">
                        {" "}
                        <svg viewBox="0 0">
                          <path
                            className="path-text-tr"
                            id="curve"
                            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                          />
                          <text className="section-text-all">
                            <textPath href="#curve">
                              Experienced Tutors
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="inner-circle">
                    <div className="inner-content ">
                      <img
                        className=""
                        src={assets.homev3.m_icon_circle}
                        style={{ width: "75%", borderRadius: "50%" }}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="">
                  {displayedCard === "FocussedSessions" && (
                    <FocussedSessionsCard />
                  )}
                  {displayedCard === "ProblemSolving" && <ProblemSolvingCard />}
                  {displayedCard === "TeamLearning" && <TeamLearningCard />}
                  {displayedCard === "ExperiencedTutors" && (
                    <ExperiencedTutorsCard />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
