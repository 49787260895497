import React, { useState, useEffect, useRef } from "react";
//import { BsFileEarmarkText } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setIsRecapOpened, setMenu } from "../../../store/slices/settingsSlice";
import courseFile from "../../../assets/files/what-is-robotics.pdf";
import "./course-view.css";

import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

import WhiteboardTopics from "../whiteboard-topics/whiteboard-topics";

//import WebViewer from '@pdftron/webviewer';

// import CommonWebViewer from "../web-viewer/web-viewer";

//import s from '../../../assets/test_doc.pdf';

//CONFIGS
import storage_configs from "../../../config/storage-config";
import conditional_configs from "../../../config/conditional_config";
import assets from "../../../config/assets";

const quiz = {
  topic: "Javascript",
  level: "Beginner",
  totalQuestions: 10,
  perQuestionScore: 5,
  totalTime: 60, // in seconds
  questions: [
    {
      question:
        "Which function is used to serialize an object into a JSON string in Javascript?",
      choices: ["stringify()", "parse()", "convert()", "None of the above"],
      type: "MCQs",
      correctAnswer: "stringify()",
    },
    {
      question:
        "Which of the following keywords is used to define a variable in Javascript?",
      choices: ["var", "let", "var and let", "None of the above"],
      type: "MCQs",
      correctAnswer: "var and let",
    },
    {
      question:
        "Which of the following methods can be used to display data in some form using Javascript?",
      choices: [
        "document.write()",
        "console.log()",
        "window.alert",
        "All of the above",
      ],
      type: "MCQs",
      correctAnswer: "All of the above",
    },
    {
      question: "How can a datatype be declared to be a constant type?",
      choices: ["const", "var", "let", "constant"],
      type: "MCQs",
      correctAnswer: "const",
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  select: {
    width: 240,
    border: "0px solid gray",
    height: "40px",
    borderRadius: "30px",
    padding: "4px",
    boxShadow: "0px 0px 2px 2px #f6f1f1",
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": {
      width: 320,
      boxShadow: theme.shadows[8], // Use the shadow value from your theme
    },
    "& fieldset": {
      borderWidth: "1px !important",
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
}));

function CourseView(props) {
  const viewer = useRef(null);
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedContent = useSelector(
    (state) => state.settings.selectedContent
  );
  const isRecapClicked = useSelector((state) => state.settings.isRecapClicked);
  const isRecapOpened = useSelector((state) => state.settings.isRecapOpened);
  const selectedCurriculamType = useSelector(
    (state) => state.settings.selectedCurriculamType
  );
  const shareScreenStatus = useSelector(
    (state) => state.zoom.shareScreenStatus
  );
  const [showContent, setShowContent] = React.useState(selectedContent);
  const [testDoc, setTestDoc] = React.useState(
    "https://docs.google.com/viewer?url=https://test.document.meeku.world/1683635532351-sample_ppt_file.pptx&embedded=true"
  );
  const setSidebarMenu = (menu) => {
    dispatch(setMenu(menu));
  };

  // QUIZ VIEW
  const [activeQuestion, setActiveQuestion] = React.useState(0);
  const [selectedAnswer, setSelectedAnswer] = React.useState("");
  const [showResult, setShowResult] = React.useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = React.useState(null);
  const [result, setResult] = React.useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  let { questions } =
    selectedCurriculamType == conditional_configs.curriculam_types.quiz
      ? selectedContent
      : quiz;
  let { question, choices, correctAnswer } = questions[activeQuestion];

  // const { quizList } = selectedContent
  // const { question, choices, correctAnswer } = quizList[activeQuestion]

  //RECAP WHITEBOARDS
  const [whiteBoardTabList, SetWhiteBoardTabList] = React.useState([]);
  const [whiteboardLink, setWhiteboardLink] = React.useState();
  const [whiteboardKey, setWhiteboardKey] = React.useState();

  const [selectedWhiteboardTabIndex, setSelectedWhiteboardTabIndex] =
    useState(0);

  const handleWhiteboardTabChange = (event) => {
    setSelectedWhiteboardTabIndex(event.target.value);
  };

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    setResult((prev) =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  useEffect(() => {
    openRecapBoards();
  }, []);

  const openRecapBoards = () => {
    if (!isRecapOpened) {
      dispatch(setIsRecapOpened(true));
      setWhiteboardLink("");

      let tempBoards = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.group_space.gsp_boards
        )
      );
      if (tempBoards && tempBoards.length > 0) {
        tempBoards[0].active = true;
        setWhiteboardKey(tempBoards[0].id);
        setWhiteboardLink(tempBoards[0].whiteboardLink);
        SetWhiteBoardTabList(tempBoards);
      }
    }
  };
  if (isRecapClicked == true) {
    openRecapBoards();
  }

  const openWhiteBoard = (e, obj, index) => {
    setWhiteboardLink("");
    e.stopPropagation();
    setWhiteboardLink("");
    let data = [...whiteBoardTabList];
    data.forEach((element) => {
      element.active = false;
    });
    setWhiteboardKey(obj.id);
    setWhiteboardLink(data[index].whiteboardLink);
    //let generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(obj.group_name) + '-div-' + slugify(obj.id) + '-div-' + lgrp.coursegroupid + '-div-' + slug + '-div-'+ slugify(lgrp.groupname)+",-4-Gf1BCSf5ns_M4ChSamw";
    //setWhiteboardLink(generateBoardLink);
    data[index].active = true;
    SetWhiteBoardTabList(data);
  };

  const onClickWhiteboard = () => {
    alert("Whiteboard is locked.");
  };
  const checkIsEmdedCode = (content) => {
    if (content) {
      const checkStr = content.slice(0, 4);
      if (checkStr == "http") {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };
  return (
    <div className="course-view-container border-radius-20 h-100">
      {shareScreenStatus ? (
        <video
          className="border-radius-20"
          id="course-view-video"
          width={"100%"}
          height={"100%"}
        ></video>
      ) : (
        ""
      )}
      {isRecapClicked ? (
        <div className="h-100">
          <div className="h-100 w-100 whiteboard-container-section-div">
            <FormControl
              className="dropdown-wb-gsp"
              sx={{
                minWidth: 120,
              }}
            >
              <Select
                className={classes.select}
                value={selectedWhiteboardTabIndex}
                onChange={handleWhiteboardTabChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {whiteBoardTabList.map((wTab, wIndex) => (
                  <MenuItem
                    key={"whiteboard-tab-" + wIndex}
                    value={wIndex}
                    onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                  >
                    {wTab?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <iframe
              id={"l-pod-grpsp-board-" + whiteboardKey}
              className="whiteboard-iframe-gsp whiteboard-link-iframe-gsp"
              src={whiteboardLink}
            ></iframe>
          </div>
        </div>
      ) : selectedCurriculamType ==
          conditional_configs.curriculam_types.content && selectedContent ? (
        <div className="h-100">
          {
            checkIsEmdedCode(selectedContent) ? (
              <div
                id="content-view-embed-code-div"
                className="h-100 embed-code-style-v2-e"
                dangerouslySetInnerHTML={{ __html: selectedContent }}
              ></div>
            ) : (
              <iframe
                id="content-view-iframe"
                className={props.class + `h-100`}
                src={selectedContent}
                width="100%"
                height="100%"
              ></iframe>
            )
            // <CommonWebViewer document={selectedContent} />
          }
        </div>
      ) : selectedCurriculamType ==
          conditional_configs.curriculam_types.whiteboard && selectedContent ? (
        <div className="h-100">
          {/* <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                        </div> */}
          {/* <iframe className="content-section-iframe" src={documentimg} /> */}
          {/* <iframe className={props.class + ' content-section-iframe'} src={selectedContent}></iframe> */}
          <WhiteboardTopics></WhiteboardTopics>
        </div>
      ) : selectedCurriculamType == conditional_configs.curriculam_types.quiz &&
        selectedContent ? (
        <div className="h-100">
          <div className="quiz-container h-100">
            {!showResult ? (
              <div>
                <div>
                  <span className="active-question-no">
                    {addLeadingZero(activeQuestion + 1)}
                  </span>
                  <span className="total-question">
                    /{addLeadingZero(questions.length)}
                  </span>
                </div>
                <h2>{question}</h2>
                <ul>
                  {choices.map((answer, index) => (
                    <li
                      onClick={() => onAnswerSelected(answer.id, index)}
                      key={answer}
                      className={
                        selectedAnswerIndex === index ? "selected-answer" : null
                      }
                    >
                      {answer.answer}
                    </li>
                  ))}
                </ul>
                <div className="flex-right">
                  <button
                    onClick={onClickNext}
                    disabled={selectedAnswerIndex === null}
                  >
                    {activeQuestion === questions.length - 1
                      ? "Finish"
                      : "Next"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="result">
                <h3>Result</h3>
                <p>
                  Total Question: <span>{questions.length}</span>
                </p>
                <p>
                  Total Score:<span> {result.score}</span>
                </p>
                <p>
                  Correct Answers:<span> {result.correctAnswers}</span>
                </p>
                <p>
                  Wrong Answers:<span> {result.wrongAnswers}</span>
                </p>
              </div>
            )}
          </div>

          {/* {selectedContent?.quizList?.map((qObj, qIndex) => {
                                return (
                                    <li key={'course_list_li_q_view' + qIndex}>
                                        <div className="host-course-item d-flex align-items-center">
                                            <div>
                                                <img src={assets.quiz} alt="" className="me-3" />
                                            </div>
                                            <div className="course_list_li_text flex-between">
                                                <span>{qObj?.coursequestion?.question}</span>

                                            </div>
                                        </div>
                                    </li>
                                )
                            })} */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CourseView;
