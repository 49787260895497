

import "./style.css";
import io from "socket.io-client";
import React, { Component } from 'react'

import url_configs from "../../../config/url";

export default class Reactangle extends Component {

  // Development
  socket = io.connect(url_configs.white_board_server);

  constructor(props) {
    super(props);

    //sample

    this.state = {
      name: "tool"
    };

    //sample
  }

  componentDidMount() {
    this.drawOnCanvas();
  }

  componentWillReceiveProps(newProps) {
    if (!this.tmp_ctx) return;
    this.tmp_ctx.strokeStyle = newProps.color;
    this.tmp_ctx.lineWidth = newProps.size;
  }

  drawOnCanvas() {
    var point = [];
    var clicks = 0;
    var sketch = document.querySelector('#sketch');
    var sketch_style = getComputedStyle(sketch);
    // Creating a tmp canvas
    var tmp_canvas = document.createElement('canvas');
    var tmp_ctx = tmp_canvas.getContext('2d');
    tmp_canvas.id = 'tmp_canvas';
    tmp_canvas.width = parseInt(sketch_style.getPropertyValue('width'));
    tmp_canvas.height = parseInt(sketch_style.getPropertyValue('height'));
    sketch.appendChild(tmp_canvas);
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.id = 'paint';
    canvas.width = parseInt(sketch_style.getPropertyValue('width'));
    canvas.height = parseInt(sketch_style.getPropertyValue('height'));
    sketch.appendChild(canvas);
    var root = this;
    setTimeout(function () {
      var image = new Image();
      if (localStorage.getItem("imgSrc")) {
        tmp_ctx.clearRect(0, 0, canvas.width, canvas.height);
        context.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          tmp_ctx.drawImage(image, 0, 0);
          context.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = localStorage.getItem("imgSrc");
      }
    }, 500);

    this.socket.on("canvas-data", function (data) {


      var interval = setInterval(function () {
        if (root.isDrawing) return;

        root.isDrawing = true;
        clearInterval(interval);
        var image = new Image();
        tmp_ctx.clearRect(0, 0, canvas.width, canvas.height);
        context.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          context.drawImage(image, 0, 0);
          tmp_ctx.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = data;
        localStorage.setItem("imgSrc", data);

      }, 200);
    });
    tmp_canvas.addEventListener('mousedown', mousedown, false);
    tmp_canvas.addEventListener('mousemove', mousemove, false);
    tmp_canvas.addEventListener('mouseup', function (e) {
      context.drawImage(tmp_canvas, 0, 0);
      clicks = 0;
      point.length = 0;

      setTimeout(function () {
        //name

        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get("name") != null) {
          context.fillText(urlParams.get("name"), e.pageX, e.pageY - 110);
          tmp_ctx.fillText(urlParams.get("name"), e.pageX, e.pageY - 110);
        }
        //name

        var base64ImageData = canvas.toDataURL();
        localStorage.setItem("imgSrc", base64ImageData);
        root.socket.emit("canvas-data", base64ImageData);
      }, 500);
    }, false);

    function mousemove(e) {
      if (clicks == 1) {
        var x = e.layerX - this.offsetLeft;
        var y = e.layerY - this.offsetTop;
        showRect(x, y);
      }
    }

    /* Drawing on Paint App */

    tmp_ctx.lineWidth = this.props.size;
    tmp_ctx.lineJoin = "round";
    tmp_ctx.lineCap = "round";
    tmp_ctx.strokeStyle = this.props.color;

    /* Drawing on Paint App */
    context.lineWidth = this.props.size;
    context.lineJoin = "round";
    context.lineCap = "round";
    context.strokeStyle = this.props.color;


    function showRect(x, y) {
      tmp_ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas
      tmp_ctx.beginPath();
      var width = x - point[0].x;
      var height = y - point[0].y;
      tmp_ctx.rect(point[0].x, point[0].y, width, height);
      tmp_ctx.stroke();
    }
    function mousedown(e) {
      var x = e.layerX - this.offsetLeft;
      var y = e.layerY - this.offsetTop;
      point.push({
        x,
        y
      });
      clicks++;
    };


  }
  render() {
    return (
      <div id="sketch">
      </div>
    )
  }
}
