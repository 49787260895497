import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Badge,
    Fade,
    TextField,
    Typography,
    Button,
    Grid, InputBase,
    IconButton,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    Container,
    FormControl,
    Stepper,
    Step,
    StepLabel,
    Card,
    CardActions,
    CardContent,
    Radio,
    Paper,
    Stack,
    Modal
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SearchIcon from "@mui/icons-material/Search";

//Packages
import slugify from 'react-slugify';

//ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

//ASSETS -> IMG
import Sampleimg from "../../../assets/img/white-board/whiteboard.png";
import "./image-library.css";
import Iconify from '../../../components/Iconify';

//CONFIGS
import iconsConfig from "../../../config/icons";
import url_configs from "../../../config/url";
import questionTypes from '../../../config/question-types';

//SERVICES
import AdminService from "../../../services/admin-service";

import {
    getCurrentUserData,
} from "../../../utils/util.js";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from 'notistack';
import conditional_configs from "../../../config/conditional_config";

export default function WhiteboardTemplateDetail() {
    let { slug } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const validationSchema = Yup.object().shape({
        templatename: Yup.string().required("Quiz template name is required"),
    });

    const [templateList, setTemplateList] = useState([]);
    const [quiz_card_icon_btn, setQuiz_card_icon_btn] = useState(null);
    const open_select = Boolean(quiz_card_icon_btn);

    const [openTemplate, setOpenTemplate] = useState(false);

    const [enableWhiteboardWindow, setEnableWhiteboardWindow] = useState(false);
    const [whiteboardLink, setWhiteboardLink] = useState("");
    const [selectedTemplateName, setSelectedTemplateName] = useState("");

    const {
        register,
        control,
        handleSubmit,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    /** OPEN TEMPLATE POPUP */
    const handleOpenTemplate = (type) => {
        setOpenTemplate(true)
    };

    const openWhiteboard = (obj) => {
        if (obj && obj.slug) {
            let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + obj.slug + ',-4-Gf1BCSf5ns_M4ChSamw'
            setWhiteboardLink(tempWLink);
            setSelectedTemplateName(obj.name);
            setEnableWhiteboardWindow(true);
        }
    }

    const closeWhiteboard = () => {
        window.close();
        //navigate('/whiteboard-templates');
        // setWhiteboardLink("");
        // setSelectedTemplateName("");
        // setEnableWhiteboardWindow(false);
    }

    /** CLOSE TEMPLATE POPUP */
    const handleCloseTemplate = () => setOpenTemplate(false);

    const badgeStyle = {
        "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: "#f07820",
            padding: "10px",
        },
    };

    //CARD RIGHT TOP MENUS
    const card_icon_open = (event) => {
        setQuiz_card_icon_btn(event.currentTarget);
    };
    const card_icon_close = () => {
        setQuiz_card_icon_btn(null);
    };

    useEffect(() => {
        if (slug) {
            if (getCurrentUserData() && (getCurrentUserData().role == conditional_configs.role.curriculam_creator || getCurrentUserData().role == conditional_configs.role.admin)) {
                let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=preview-" + slug+ ',-4-Gf1BCSf5ns_M4ChSamw'
                        setWhiteboardLink(tempWLink);
                        setSelectedTemplateName(slug.replace("-"," "));
                        setEnableWhiteboardWindow(true);
            } else {
                localStorage.clear();
                navigate('/admin-login');
            }
        } else {
            navigate('/whiteboard-templates');
        }
    }, []);

    return (
        <Paper className="pt-4">
            <Typography
                color="action"
                style={{ marginLeft: "auto" }}
                onClick={(e) => closeWhiteboard()}
                className="close-whiteboard ms-2 pe-4"
            >
                <CloseIcon />
            </Typography>
            <Typography
                color="action"
                style={{ marginLeft: "auto",textTransform: "capitalize" }}
                className="close-whiteboard"
            >
                {selectedTemplateName}
            </Typography>

            <iframe className="iframe-class" src={whiteboardLink}></iframe>
        </Paper>
    );
}
