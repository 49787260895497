import React from "react";
import { useState, useEffect } from "react";
import "./home-v4.css";
import assets from "../../config/assets";
import Header from "./header";
import { Button, Typography, Container, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FooterV4 from "./footer-v4/footer-v4";
import conditional_configs from "../../config/conditional_config";
import { useNavigate } from "react-router-dom";
import { setReferralCode } from "../../utils/util";

export default function Homev4() {

    const home_v4_background_img = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "20px",

    };

    const home_v4_background_img_mobile = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_mobile})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

    };
    const home_v4_background_img_2 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "10px",
        paddingBottom: "0px",
    };

    const home_v4_background_img_3 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_3})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "10px",
        paddingBottom: "100px",
    };

    const home_v4_background_img_5 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_5})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

        paddingBottom: "10px",
    };


    const home_v4_learning_world_bg = {
        backgroundImage: `url(${assets.homev4.home_v4_learning_world_bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "40px",
        paddingBottom: "10px",
    };

    const home_faq_bg_img = {
        backgroundImage: `url(${assets.homev4.home_faq_bg_img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "40px",
        paddingBottom: "5px",
    };

    const learning_adventure_background_img = {
        backgroundImage: `url(${assets.homev4.learning_adventure_background_img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "40px",
        paddingBottom: "5px",
    };

    const navigate = useNavigate();

    useEffect(() => {
        setReferralCode();
    }, []);

    return (
        <div className="main-container-hv3">
            <Header></Header>
            {/* section-1-background-hv4 */}
            <div className="section-1-background-hv4 section-1-large-view-hv4" style={home_v4_background_img}>
                <div className="section-1-container-hv4 mt-5">
                    <div className="container">
                        <div className="row ms-0 me-0">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mt-5">
                                    <p className="desktop-section-1-title-hv4">
                                        MEEKU'S MULTI-ADVENTURE LEARNING WORLD: EXPLORE, CREATE, CODE, AND PLAY TOGETHER!
                                    </p>

                                    <div className="d-flex align-items-center">
                                        <button className="start-for-free-hv4 mt-3 mb-2" onClick={() =>
                                            navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                        }>
                                            <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                                className="" width={40}
                                                src={assets.homev4.white_with_blue_arrow}
                                            ></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="d-flex justify-content-end">
                                    <img
                                        className="home_panda_image"
                                        src={assets.homev4.home_panda_image}
                                    ></img>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section-1-background-hv4-mobile section-1-mobile-view-hv4" style={home_v4_background_img_mobile}>
                <div className="section-1-container-hv4-mobile mt-2">
                    <div className="container">
                        <div className="row ms-0 me-0">
                            <div className="col-lg-6 col-md-8 col-8">
                                <div className="">
                                    <p className="mobile-section-1-title-hv4 mt-5 mb-0">
                                        MEEKU'S MULTI-ADVENTURE LEARNING WORLD: EXPLORE, CREATE, CODE, AND PLAY TOGETHER!
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="row ms-0 me-0">
                            <div className="col-lg-6 col-md-6 col-8">
                                <div className="">
                                    <div className="d-flex align-items-center">
                                        <button className="start-for-free-hv4 mt-3 mb-2" onClick={() =>
                                            navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                        }>
                                            <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                                className="" width={40}
                                                src={assets.homev4.white_with_blue_arrow}
                                            ></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-4">
                                <div className="d-flex justify-content-end">
                                    <img
                                        className="home_panda_image_mobile"
                                        src={assets.homev4.home_panda_image_mobile}
                                    ></img>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-2-container-hv4 */}
            <div className="section-2-container-hv4" style={home_v4_background_img_2}>
                <p className="desktop-section-2-title-hv4">JOURNEY INTO CODING WONDERLAND</p>

                <div className="container pb-5">
                    <div className="row align-items-center ms-0 me-0 pb-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="d-flex justify-content-center mb-3">
                                <div className="video-container-home-vhv4">
                                    <div className="video-container-background-block-vhv4">
                                        <iframe
                                            // src={assets.videos.meeku_video_1}
                                            src="https://player.vimeo.com/video/1010107211?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen
                                            width="100%"
                                            height="300px"
                                            title="Soccer"
                                        ></iframe>
                                    </div>

                                    <p className="video-text-hv4 mb-0">Children Experience ExtraOrdinary!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <p className="section-2-dec-hv4 pt-2"><strong>Meeku: Where kids embark on magical coding quests, transforming
                                curiosity into real-world problem-solving skills through interactive
                                adventures.
                            </strong></p>

                            <div className="d-flex justify-content-end">
                                <img
                                    className=""
                                    src={assets.homev4.rocket_img}
                                ></img>
                            </div>
                        </div>
                    </div>

                    <p className="desktop-section-2-title-hv4 mt-5 mb-1">STRUGGLING WITH STEM? BORED OF LEARNING?</p>
                    <p className="section-2-dec-hv4 mb-0">Is your child <strong>lost in endless scrolling</strong>  on TikTok and instagram? It's time for a change!</p>

                    <p className="section-2-dec-hv4 mb-0">Transform unproductive screen time into <strong>exciting learning adventures </strong> with Meeku.</p>

                    <p className="section-2-dec-hv4 ">Our <strong>mission-driven community</strong>  turns <strong>traditional learning challenges</strong>  into <strong>engaging, inspiring experiences.</strong> </p>
                </div>

                <div className="">
                    <img
                        className="w-100"
                        src={assets.homev4.home_v4_background_img_3}
                    ></img>
                </div>
            </div>
            {/* section-3-container-hv4 */}
            <div className="section-3-container-hv4">
                <div className="container pb-5">
                    <p className="desktop-section-3-title-how-meeku-solve-hv4 mb-5 mt-0">HOW MEEKU SOLVES YOUR PARENTING CHALLENGES</p>
                    <div className="row a ms-0 me-0 align-items-center">

                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">TURN SCREEN TIME INTO LEARNING ADVENTURES</p>
                                <p className="section-2-dec-hv4">Meeku transforms passive screen time into active learning adventures.</p>
                            </div>

                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">FUTURE-READY SKILLS </p>
                                <p className="section-2-dec-hv4">Your child will explore coding, design, and problem-solving – essential skills for tomorrow's world.</p>
                            </div>

                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">SOCIAL LEARNING</p>
                                <p className="section-2-dec-hv4">Meeku encourages teamwork and builds social skills through collaborative projects.</p>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">

                            <div>
                                <img
                                    className="coding-adven-card-image"
                                    src={assets.homev4.how_meeku_solves_group_img}
                                ></img>
                            </div>
                            {/* <div className="d-flex justify-content-center mb-3">
                                <div className="card coding-adven-card-1 me-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_1}
                                    ></img>
                                </div>

                                <div className="card coding-adven-card-2">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_2}
                                    ></img>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="card coding-adven-card-3 me-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_3}
                                    ></img>
                                </div>

                                <div className="card coding-adven-card-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_4}
                                    ></img>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="">
                    <img
                        className="w-100"
                        src={assets.homev4.home_v4_background_img_4}
                    ></img>
                </div>
            </div>

            {/* section-4-container-hv4 */}
            <div className="section-4-container-hv4">
                <div className="container pb-5">
                    <div className="row ms-0 me-0 align-items-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                            <div className="d-flex justify-content-center">
                                <img
                                    className="coding-adven-card-image"
                                    src={assets.homev4.engaging_img}
                                ></img>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt-3">
                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">SAFE AND MONITORED</p>
                                <p className="section-2-dec-hv4">Our platform provides a secure environment for group activities, giving you peace of mind.</p>
                            </div>
                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">EASY PROGRESS TRACKING</p>
                                <p className="section-2-dec-hv4">Our parent dashboard lets you easily monitor your child's growth and achievements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-5-container-hv4 */}
            <div className="section-5-container-hv4" style={home_v4_learning_world_bg}>
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0 align-items-center">


                        <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                            {/* <div className="">
                                <p className="desktop-section-3-title-hv4 mb-0">WHAT'S AWESOME ABOUT MEEKU'S LEARNING WORLD?</p>
                                <p className="section-2-dec-hv4 mb-0">🖥️ <strong>Explore coding and automation:</strong>  Solve challenges in exciting game worlds!  </p>
                                <p className="section-2-dec-hv4 mb-0">🎨 <strong>Design amazing things:</strong>  Let imagination run wild!</p>
                                <p className="section-2-dec-hv4 mb-0">🌍 <strong>Tackle real-world scenarios:</strong> Learn how automation solves everyday problems!</p>
                                <p className="section-2-dec-hv4 mb-0">👫 <strong>Team up with friends:</strong>  Learn and play together!</p>
                            </div>

                            <div>
                                <p className="desktop-section-3-title-hv4 mb-0">PARENTS, HERE'S WHY YOU'LL LOVE IT:</p>
                                <p className="section-2-dec-hv4 mb-0">📚 Fun learning that covers multiple skills.</p>
                                <p className="section-2-dec-hv4 mb-0">🤝 Encourages teamwork and social skills.</p>
                                <p className="section-2-dec-hv4 mb-0">💡 Boosts creativity and problem-solving.</p>
                                <p className="section-2-dec-hv4 mb-0">🌟 Prepares kids for future challenges.</p>
                                <p className="section-2-dec-hv4 mb-0">👀 Easy-to-use parent dashboard to track progress.</p>
                                <p className="section-2-dec-hv4 mb-0">🛡️ Safe, monitored environment for group activities.</p>
                            </div> */}

                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">DISCOVER, CREATE, SHARE: UNLEASH CREATIVITY!</p>
                                <p className="section-2-dec-hv4 mb-0"><strong>Design Challenges:</strong>  Fun, educational projects for young minds. Kids tackle exciting tasks like creating digital pets, building virtual gardens, or designing eco-friendly cities.  </p>
                                <p className="section-2-dec-hv4 mb-0"><strong>Team Collaboration:</strong>  Kids work together, bringing ideas to life.</p>
                                <p className="section-2-dec-hv4 mb-0"><strong>Community Showcase:</strong>  Share projects, get feedback, inspire others.</p>
                            </div>


                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="">
                                <img
                                    className="card_learning_pathways_img"
                                    src={assets.homev4.learning_pathways_img}
                                ></img>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-6-container-hv4 */}
            <div className="section-6-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-4 pb-4">
                            <div className="card be-a-leader-card-hv4">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="be_a_leader_div">
                                            <p className="sub-title-hv4">HOW DO THE ADVENTURES WORK?</p>
                                            <p className="dec-text-hv4 mb-0">1. <strong>Invite</strong> : Bring your friends to join the fun!
                                            </p>
                                            <p className="dec-text-hv4 mb-0"> 2. <strong>Choose</strong> : Pick an exciting adventure. (like saving a virtual city!)</p>
                                            <p className="dec-text-hv4 mb-0">3. <strong>Explore</strong> : Learn new skills with Meeku the Panda's guidance.</p>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
                                        <img
                                            className=""
                                            src={assets.homev4.be_a_leader_img_1}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-4 pb-4">
                            <div className="card be-a-leader-card-hv4-2">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
                                        <img
                                            className=""
                                            src={assets.homev4.be_a_leader_img_2}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="be_a_leader_div_2">
                                            <p className="sub-title-hv4">INVITE YOUR FRIENDS</p>
                                            <p className="dec-text-hv4 mb-0">4. <strong>Create</strong> : Work with your team to build amazing things</p>
                                            <p className="dec-text-hv4 mb-0">5. <strong>Play</strong> : Test your creations in fun challenges</p>
                                            <p className="dec-text-hv4 mb-0">6. <strong>Share</strong> : Show off what you made to family and friends</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    className="how_to_adventure_back_img"
                    src={assets.homev4.how_to_adventure_back_img}
                ></img>
            </div>
            {/* section-7-container-hv4 */}

            <div className="section-7-container-hv4">
                <div className="container">
                    <p className="desktop-section-7-title-hv4 pt-5 mt-0 mb-0">JOIN OUR HAPPY COMMUNITY OF EXPLORERS!</p>
                    <div className="row justify-content-center align-items-center ms-0 me-0">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_1}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">1000 +</p>
                                    <p className="section-7-sub-title">Kids Learning and Playing together</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_2}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">40,000+</p>
                                    <p className="section-7-sub-title">Mission hours completed</p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_3}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">1000 +</p>
                                    <p className="section-7-sub-title">Missions conquered</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* section-8-container-hv4 */}

            <div className="section-8-container-hv4" style={learning_adventure_background_img}>
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0 align-items-center">
                        <div className="col-lg-5 col-md-6 col-sm-12 col-12 mb-4">
                            <div className="d-flex justify-content-center">
                                <div className="video-container-home-vhv4-2">
                                    <div className="video-container-background-block-vhv4">
                                        <iframe
                                            // src={assets.videos.meeku_video_2}
                                            src="https://player.vimeo.com/video/1010107247?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen
                                            width="100%"
                                            height="250px"
                                            title="Soccer"
                                        ></iframe>
                                    </div>
                                    <p className="video-text-hv4 mb-0">EXCITED STUDENTS USING MEEKU</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div className="">
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">READY TO START THE LEARNING ADVENTURE?</p>

                                <p className="section-2-dec-hv4">Try Meeku's Multi-Adventure Learning World free for 3 days! Kids code, design, and solve puzzles with friends.
                                    🌟Join this week for a free "Adventure Explorer" kit!🌟 Turn screen time into dream time - start now!</p>
                                {/* <p className="section-2-dec-hv4">Try It Free for 3 Days! See What Kids Create...</p>

                                <p className="section-2-dec-hv4">🌟<strong>Special Offer:</strong>Join this week and get a free "Adventure Explorer" kit for you and a friend!🌟</p>

                                <p className="section-2-dec-hv4">At Meeku's Multi-Adventure Learning World, we turn screen time into dream time!</p>

                                <p className="section-2-dec-hv4">Your child and their friends will have a blast exploring coding, designing cool stuff, solving real-world puzzles, and playing as a team. It's so fun, they won't even realize they're learning valuable skills!</p>

                                <p className="section-2-dec-hv4">P.S. Parents, imagine your kids eagerly learning about technology, design, and teamwork in a safe, educational environment. Start your free trial today and watch your child's creativity, problem-solving skills, and friendships flourish!</p> */}
                            </div>
                            <div className="d-flex align-items-center">
                                <button className="start-for-free-hv4 mt-3 mb-2" onClick={() =>
                                    navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                }>
                                    <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                        className="" width={40}
                                        src={assets.homev4.white_with_blue_arrow}
                                    ></img>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row ms-0 me-0 mt-3">
                        <div className="col-lg-9 col-md-6 col-sm-12 col-12">
                            <div>
                                <p className="desktop-section-3-title-hv4 mb-0">1000 MISSIONS COMPLETED</p>
                                <p className="section-2-dec-hv4">At Meeku's Multi-Adventure Learning World, we turn screen time into dream time! Your child and their friends will have a blast exploring coding, designing cool stuff, solving real-world puzzles, and playing as a team. It's so fun, they won't even realize they're learning valuable skills! P.S. Parents, imagine your kids eagerly learning about technology, design, and teamwork in a safe, educational environment. Start your free trial today and watch your child's creativity, problem-solving skills, and friendships flourish!</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="">
                                <img
                                    className="card_learning_pathways_img"
                                    src={assets.homev4.student_with_computer_img}
                                ></img>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* section-9-container-hv4 */}

            {/* <div className="section-9-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0 mt-5 mb-5">
                        <div className="card section-9-card-hv4">
                            <p className="ready-to-start-text-hv4">READY TO START YOUR CODING ADVENTURE</p>
                            <p className="ready-to-start-dec-hv4 mb-0">Join us today and embark on a journey of discovery, learning, and innovation. Together, let's shape the future with a passion for coding and design. See you on the inside!</p>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className="sign-up-btn-hv4 mt-3 mb-2" onClick={() =>
                                    navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                }>
                                    <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                        className="" width={40}
                                        src={assets.homev4.blue_with_white_arrow}
                                    ></img>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}


            {/* section-11-background-hv4 */}
            <div className="section-11-background-hv4">
                <div className="container pt-2 pb-5">
                    <div className="pt-4">
                        <img
                            className="meeku_blog_card_img_1"
                            src={assets.homev4.our_success_title_icon}
                        ></img>
                        <p className="desktop-section-11-title-hv4">OUR SUCCESS STORIES</p>
                    </div>
                    <div className="row ms-0 me-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                            <div className="card card_our_success">
                                <p className="card_blog_card_dec">Meeku has transformed my child's interest in learning. The engaging missions and supportive community have made coding fun and exciting. I highly recommend it to any parent looking to empower their child with valuable skills for the future.</p>
                            </div>
                            <div>
                                <img
                                    className="video_img_hv4 d-none d-sm-block"
                                    src={assets.homev4.video_img}
                                ></img>

                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                            <div className="d-flex justify-content-center">
                                <img width={"60%"}
                                    className=""
                                    src={assets.homev4.our_success_stories}
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





            {/* section-12-background-hv4 */}
            <div className="section-12-background-hv4" style={home_faq_bg_img}>
                <div className="container pt-2 pb-1">
                    <div className="pt-4">
                        <p className="desktop-section-12-title-hv4">GOT A QUESTION? CHECK HERE</p>
                    </div>
                    <div className="">
                        <div className="row ms-0 me-0 mt-4">
                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>      What is Meeku?
                                        </Typography>

                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku is an engaging and interactive platform designed for children,
                                            offering a wide range of activities, games, and learning opportunities that are safe and
                                            age-appropriate.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>         How does Meeku ensure my child's safety online?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku prioritizes your child's online safety by implementing strict content guidelines, moderating user interactions.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>    What age group is Meeku suitable for?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku is designed for children aged 7-14 years
                                            old. The content and activities are curated to be both fun and educational for this age group
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>          Do I need to provide consent for my child to use Meeku?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Yes, before your child can
                                            access certain features, we will seek your consent as their parent or legal guardian through a
                                            simple verification process that aligns with the principles of COPPA.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>        Is there a cost associated with using Meeku?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku offers a variety of subscription plans,
                                            including a free tier with limited access to content and premium plans that unlock additional
                                            features and activities.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>  How do I subscribe to Meeku's premium plan?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            You can subscribe to a premium plan
                                            directly through the Meeku website. Simply log in to your account, navigate to the subscription
                                            section, and choose the plan that best suits your needs.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img> Can I cancel my subscription at any time?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Yes, you can cancel your subscription at any
                                            time. Your access to premium features will continue until the end of your current billing cycle.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img> What kind of content can my child access on Meeku?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku offers a wide range of
                                            content, including educational games, interactive stories, creative tools, and safe social features
                                            that encourage learning and creativity.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>   How does Meeku protect my child's personal information?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            We take your child's privacy
                                            seriously and have implemented robust security measures to protect their personal information.
                                            We collect minimal data necessary to provide our services and never share it with third parties
                                            without your consent.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>  How do I contact Meeku for support or questions?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            If you have any questions or need
                                            assistance, you can reach our support team through the "Contact Us" section on our website, or
                                            by emailing {conditional_configs.supportEmail}.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3 accordion-pen-icon-hv4"
                                                src={assets.homev4.pen_icon}
                                            ></img>  Is Meeku accessible on all devices?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Meeku is accessible on most devices, including
                                            tablets, and desktop computers, ensuring your child can enjoy our platform
                                            wherever they are.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            {/* section-10-background-hv4 */}
            <div className="section-10-background-hv4" style={home_v4_background_img_5}>
                <p className="desktop-section-10-title-hv4">MEEKU’S BLOG HUB</p>
                <div className="container pt-2 pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 p-lg-0">
                            <div className="card card_meeku_blog">
                                <img
                                    className="meeku_blog_card_img_1"
                                    src={assets.homev4.meeku_blog_card_img_1}
                                ></img>

                                <div className="d-flex align-items-center mt-3">
                                    <div className="">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={16}
                                            src={assets.homev4.calendar_icon}
                                        ></img>July 12, 2024 </p>
                                    </div>
                                    <div className="ms-auto">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={16}
                                            src={assets.homev4.time_left_icon}
                                        ></img>2 min read </p>
                                    </div>
                                </div>
                                <p className="card_blog_card_title mt-2">Meeku's Scratch Spectacular</p>
                                <p className="card_blog_card_dec">Join Meeku in a Scratch-tastic adventure where we'll learn how to create our very own animated stories and games using Scratch!</p>
                                <div className="d-flex align-items-center mt-2 mb-2">
                                    <div className="">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={24}
                                            src={assets.homev4.card_user_icon}
                                        ></img>Avila Nancy </p>
                                    </div>
                                    <div className="ms-auto">
                                        <button className="coding_btn_hv4">CODING</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="card card_meeku_blog_1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_2}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Python Party with Meeku</p>
                                        <p className="card_blog_card_dec">Let's hang out with Meeku and dive into the fascinating world of Python! Get ready to write some cool code.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card card_meeku_blog_1 mt-1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_3}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Code Quest: Journey into Game Design</p>
                                        <p className="card_blog_card_dec">Join on an epic quest to design. Let your imagination run wild as we bring our game ideas to life!</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card card_meeku_blog_1 mt-1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_4}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Meeku's Coding Comics</p>
                                        <p className="card_blog_card_dec">Check out Meeku's hilarious comics featuring coding adventures, mishaps, and triumphs!</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <FooterV4></FooterV4>

        </div>
    )
}