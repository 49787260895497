const icons_config = {
  courses: "zondicons:education",
  quiz: "material-symbols:quiz-rounded",
  schedule: "healthicons:i-schedule-school-date-time",
  kid: "uil:kid",
  payment: "material-symbols:payments-outline",
  whiteboard: "fluent:whiteboard-20-filled",
  play_btn: "material-symbols:play-circle",
  cog: "gridicons:cog",
  screenshot: "iconoir:screenshot",
  gridViewIcon: "eva:grid-fill",
  refreshCircleOutline: "ion:refresh-circle-outline",
  chat: "tdesign:logo-wechat",
  wboard: "fluent:whiteboard-24-regular",
  games: "fluent:games-32-regular",
  quiz_out_line: "material-symbols:quiz-outline",
  results: "carbon:result",
  tourStartIcon: "mdi:stars",
  home: "ri:home-6-fill",
  tick: "mdi:tick",
  no: "dashicons:no",
  addOutlineRounded: "gridicons:add-outline",
  editFilled: "fluent:edit-16-filled",
  imageLibrary: "fluent:image-library-24-filled",
  deleteFilled: "ic:baseline-delete",
  infoIcon: "material-symbols:info-outline",
  shareIcon: "mage:share-fill",
  challenges: "mdi:rocket-launch",
  missions: "emojione-monotone:honeybee",
};

export default icons_config;
