const error_messages = {
    groupNameExist: 'Group name already exists',
    unableToDeleteMemberFromGroup: 'Cannot delete the member from the Group. A Group should have one member atleast.',
    updateGroupSuccess: 'Group updated successfully.',
    createGroupSuccess: 'Group created successfully.',
    deleteGroupSuccess: 'Group deleted successfully.',
    deleteMemberFromGroup: 'Member has been removed from the group',
    startBreakoutRoomInExpert: 'Please wait. Starting the breakout session!',
    stopBreakoutRoomInExpert: 'Please wait. Stopping the breakout session!',
    startBreakoutRoomInLearner: 'Please wait. Starting the breakout session!',
    stopBreakoutRoomInLearner: 'Please wait...',
    startingGameInLearner: 'Starting %GAMENAME%',
    leaveSessionWarning: 'Are you sure do you want to end the ongoing session?',
    sessionTimeOut: 'Session time out. Please login again.',
    breakoutSessionInProgress: 'Breakout session in progress',
    endSessionConfirmation: 'Are you sure do you want to end the ongoing session?',
    stopBreakoutSessionConfirmation: 'Are you sure do you want to stop breakout session?',
    changePasswordSuccess: 'Password changed successfully.',
    expertNoCoursesFound: 'No courses assigned yet. wait for admin to assign.',
    confirmGroupName: 'Are you sure do you want to create the group?',
    noDataFound:'No Data Found',
    noCourseAssignedToKidByParent: 'No courses assigned.',
    fewSlotsLeft: 'Only few slots left',
    slotsLeft: 'Only %SLOT% slots left',
    oneSlotLeft: 'Only 1 slot left',
    allowAudio: 'Enable Audio',
    expertBreakoutBeforeWarning: 'Initiating a breakout room from the main session may limit your ability to communicate with students unless you join a specific group. Are you sure you want to proceed?',
    learnerBreakoutWarning: "In a breakout room, communication with the Expert won't be possible until she joins your session. If you need to reach out to the Expert, please use the chat option or the raise hand feature, which will notify the Expert that you are trying to contact them",
    expertRejoin: 'Expert will reconnect shortly. Please wait.',
    zoomErrors:{
        VIDEO_USER_FORBIDDEN_CAPTURE:"Please grant permission for camera access in your browser!"
    }
};
    
export default error_messages;