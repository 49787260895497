import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
//import React from 'react';
import './App.css';

// import './theme/styles-variable.css';
//import ReactGA from 'react-ga';
// routes
import Router from './router/routes';
//import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Swal from "sweetalert2";
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

import IdleTimeOutHandler from './utils/IdleTimeOutHandler';
import CountdownTimer from "./components/countdown-timer";
import { clearClassRoomSessionData, getCurrentUserData, setSessionTime } from './utils/util';
import io from "socket.io-client";

import conditional_configs from "./config/conditional_config";
import error_messages from "./config/error_messages";
import sessionNotHandlePages from "./config/session-not-handle-pages";

import AuthService from "./services/auth/auth.service";
import storage_configs from "./config/storage-config";
import SessionService from "./services/session-service";
import url_configs from "./config/url";

// const TRACKING_ID = ""; // OUR_TRACKING_ID

// ReactGA.initialize(TRACKING_ID);
let interval_id;
let b_room_socket;
let checkExpertStateInterval;

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [isLogout, setLogout] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [enableTimer, setEnableTimer] = useState(false);
  const [enablelogoutSession, setEnablelogoutSession] = useState(false);
  const [networkErrorMessage, setNetworkErrorMessage] = useState(false);
  let { slug } = useParams();

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert && localStorage.getItem(storage_configs.localStorage.sessions.zoom.isZoomIntiated) == 'true') {
      b_room_socket = io(url_configs.breakout_room_server);
      const currentSlug = localStorage.getItem(storage_configs.localStorage.sessions.zoom.currentSessionSlug)
      var dt = {
        slug: currentSlug
      }

      checkExpertStateInterval = setInterval(() => {
        if(localStorage.getItem(storage_configs.localStorage.sessions.zoom.isZoomIntiated) == 'true'){
          const currentURL = window.location.href;
          const isExpertOutSideOfSession = localStorage.getItem('isExpertOutSideOfSession');
          console.log('Current URL:', currentURL);
          const regex = new RegExp('expert-session', 'i');
          if (regex.test(currentURL)) {
            if (isExpertOutSideOfSession !== 'true') {
              return;
            }
            localStorage.setItem('isExpertOutSideOfSession', false);
            console.log(`expert-session found in the current URL`);
          } else {
            if (isExpertOutSideOfSession !== 'true') {
              localStorage.setItem('isExpertOutSideOfSession', true);
              b_room_socket.emit("on-expert-opens-other-page", { data: dt }, (error) => {
                if (error) {
                  console.log(error);
                }
              });
            }
            console.log(`expert-session not found in the current URL`);
          }  
        }
      }, 5000);
    }

    //#region NETWORK CHECK
    function onlineHandler() {
      setIsOnline(true);
      const networkStatus = {
        date: new Date().toLocaleString(),
        status: 'online'
      }
      const checkStatus = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.networkStatus));
      if (checkStatus && checkStatus.status === 'offline') {
        //GET LAST OFFLINE DATE TIME
        ///
        //WRITE CODE HERE
        ///
        localStorage.setItem(storage_configs.localStorage.sessions.networkStatus, JSON.stringify(networkStatus));
        //localStorage.removeItem(storage_configs.localStorage.sessions.networkTimerStatus);
        window.location.reload();
      } else {
        localStorage.setItem(storage_configs.localStorage.sessions.networkStatus, JSON.stringify(networkStatus))
      }
    }

    function offlineHandler() {
      setNetworkErrorMessage('Make sure your computer has an active internet connection.');
      setIsOnline(false);
      console.log('network-', isOnline)

      const networkStatus = {
        date: new Date().toLocaleString(),
        status: 'offline'
      }
      const checkStatus = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.networkStatus));
      if (!checkStatus || (checkStatus && checkStatus.status !== 'offline')) {
        localStorage.setItem(storage_configs.localStorage.sessions.networkStatus, JSON.stringify(networkStatus));
        setTimeout(() => {
          setEnableTimer(true);
        }, 5000);
      }
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
    //#endregion

    let tempSessionNotHandlePages = sessionNotHandlePages;
    let location = window.location.pathname;

    let checkIsNotSessionPage = tempSessionNotHandlePages.filter(e => e == location);

    //CHECK IF USER ALREADY LOGGED IN AND OPENING THE TAB AFTER THE SESSION TIME ENDS.
    if (getCurrentUserData()) {
      let currentSessionTime = localStorage.getItem('session-time');
      if (currentSessionTime) {
        const now = new Date();
        let diff = diffInSeconds(now.toString(), currentSessionTime);
        if (diff > (conditional_configs.idleTime / 1000)) {
          alert(error_messages.sessionTimeOut);
          if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
            navigate('/admin-login');
          } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
            navigate('/expert-login');
          } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner) {
            navigate('/learner-login');
          } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
            navigate('/parent-login');
          } else {
            navigate('/admin-login');
          }
          AuthService.logout();
          return () => {
            window.clearInterval(interval_id);
          };
        }
      }
    }

    if (checkIsNotSessionPage && checkIsNotSessionPage.length == 0) {
      interval_id = window.setInterval(() => {
        setSessionTime();
        const networkTimerStatus = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.networkTimerStatus));
        if (networkTimerStatus && networkTimerStatus == 'ended') {
          setEnableTimer(false);
          setEnablelogoutSession(true);
          setNetworkErrorMessage('Your session will be logged out due to internet inactivity!');
          setTimeout(() => {
            const checkStatus = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.networkStatus));
            if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
              const membercoursestreamdetailid = localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid)
              if (checkStatus && checkStatus.status === 'online' && membercoursestreamdetailid) {
                let model = {
                  membercoursestreamdetailid: membercoursestreamdetailid.toString()
                }
                SessionService.stopSessionByExpert(model).then(response => {
                  enqueueSnackbar('The session has been successfully logged out!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                  navigate('/expert-login');
                  localStorage.removeItem("user");
                  localStorage.removeItem(storage_configs.localStorage.sessions.networkTimerStatus);
                })
                  .catch(e => {
                    console.log(e);
                  });
              } else {
                enqueueSnackbar('The session has been successfully logged out!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                navigate('/expert-login');
                localStorage.removeItem("user");
                localStorage.clear();
              }
            } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner) {
              enqueueSnackbar('The session has been successfully logged out!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              navigate('/learner-login');
              localStorage.removeItem("user");
              localStorage.clear();
            } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
              enqueueSnackbar('The session has been successfully logged out!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              navigate('/parent-login');
              localStorage.removeItem("user");
              localStorage.clear();
            }
            else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
              enqueueSnackbar('The session has been successfully logged out!', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              navigate('/admin-login');
              localStorage.removeItem("user");
              localStorage.clear();
            } else {
              return;
            }
          }, 10000);
        }

      }, 5000);
      return () => {
        window.clearInterval(interval_id);
        window.removeEventListener("online", onlineHandler);
        window.removeEventListener("offline", offlineHandler);
      };
    } else {
      return () => {
        window.clearInterval(interval_id);
        window.clearInterval(checkExpertStateInterval);
      };
    }
  }, [])

  const popup = (msg) => {
    Swal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay"
    }).then((result) => {
      if (result.isConfirmed) {
      } else {
      }
    });
  }

  const diffInSeconds = (curDate, sessionDate) => {
    var timeDiff = Math.abs(new Date(curDate).getTime() - new Date(sessionDate).getTime()); // in miliseconds
    var timeDiffInSecond = Math.ceil(timeDiff / 1000); // in second
    return timeDiffInSecond;
  }

  const offLineHeader = () => {
    return (
      <div className="network-error-notification">
        {networkErrorMessage}
        {enableTimer ? <CountdownTimer seconds={conditional_configs.networkReloadTime} /> : ''}
        {/* {enablelogoutSession ? 'Session will be logout.' : ''} */}
      </div>
    )
  }

  return (
    <div className='height-100'>
      {!isOnline && getCurrentUserData() ? offLineHeader() : ''}
      <ThemeProvider>
        <ScrollToTop />
        <SnackbarProvider maxSnack={3}>
          <IdleTimeOutHandler
            onActive={() => { setIsActive(true) }}
            onIdle={() => { setIsActive(false) }}
            onLogout={() => { setLogout(true) }}
          />
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </div>

  );
}
