
//New Account -> Testring
const zoom = {
    sdk_key: "JDqepnw2LJOK9OkwTevvAKDSxZ2dOibGKW3x",
    sdk_secret: "2SFQn4kWxMm7JohQ0jSOtNLl4L1QnBjwPzlE",
    session_password: "Raagaa@123",
    roleType: {
        host: 1,
        participant: 0
    },
    sharePrivilege: {
        locked: 1,
        unlocked: 0,
        multipleShare: 3
    },
    createSessionType: {
        autoAssign: 1,
        manual: 2
    },
    shareScreenOptions: [
        { id: 0, text: "Share current tab" },
        { id: 1, text: "Share screen with options" },
    ],
};

//Old Account -> Durai
// const zoom = {
//     sdk_key: "rPXebzoP23GDG1PngjkA9Ly2coTue3lguV9x",
//     sdk_secret: "Tmv4qOl2lBy4IMiyju7Pf7XLbwZKdV0WD67f",
//     session_password: "Raagaa@123",
//     roleType: {
//         host: 1,
//         participant: 0
//     }
// };

export default zoom;