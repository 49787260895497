import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";
const path = "/landingpageonboard";

const getAll = data => {
    return http.get(path, { headers: authHeader() });
};

const subscribeUser = async (data) => {
    const response = await http.post(api_path.subscribeUser, data, { headers: authHeader() });
    return response.data;
};

const verifycaptcha = async (data) => {
    const response = await http.post(api_path.subscribeUser+'/verifycaptcha', data, { headers: authHeader() });
    return response.data;
};
const CustomerService = {
    getAll,
    subscribeUser,
    verifycaptcha
};
export default CustomerService;