import React, { useEffect, Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";

import "./share-licenses-success-page.css";

//CONFIGS
import assets from "../../config/assets";

//SERVICES
import CourseService from "../../services/courses-service";
import { getCurrentUserData } from "../../utils/util";
import ShareLicensesService from "../../services/share-licenses-service";
import storage_configs from "../../config/storage-config";

import route_config from "../../config/route_config";

const ShareLicensesSuccessPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [courseInfo, setCourseInfo] = useState();
  const handletabclick = (e) => {
    navigate("/parent/my-courses");
  };

  let { token } = useParams();
  console.log("token=" + token);

  const navigate = useNavigate();

  useEffect(() => {
    // Call verifyShareLicensesToken API when component mounts
    verifyToken(token);
  }, [token]);

  const verifyToken = (token) => {
    console.log("token=" + token);
    ShareLicensesService.verifyShareLicensesToken(token)
      .then((response) => {
        console.log(response);

        if (response.isNewCustomer == true) {
          localStorage.setItem(
            "share-access-details",
            JSON.stringify(response.data.decryptedToken)
          );
          localStorage.setItem(
            storage_configs.localStorage.navigatedFrom,
            route_config.shareLicenses
          );

          CourseService.GetCoursesDetailsById(
            response.data.CourseStream.courseId
          )
            .then((response) => {
              if (
                response.data &&
                response.data.coursestream &&
                response.data.coursestream.length > 0
              ) {
                var tempCourseStreams = response.data.coursestream;

                for (let i = 0; i < tempCourseStreams.length; i++) {
                  const checkPodAlreadyStarted = tempCourseStreams[
                    i
                  ].coursestreamdetail.filter(
                    (e) =>
                      e.memberCourseStreamDetails &&
                      e.memberCourseStreamDetails.length > 0
                  );
                  if (checkPodAlreadyStarted.length == 0) {
                    // const currentDate = new Date();
                    // tempCourseStreams[i].coursestreamdetail = tempCourseStreams[i].coursestreamdetail.filter(e => new Date(e.startdate) >= currentDate);
                    tempCourseStreams[i].coursestreamdetail = tempCourseStreams[
                      i
                    ].coursestreamdetail.filter((e) => {
                      const startDate = new Date(e.startdate);
                      const currentDate = new Date();
                      // Extract date components from the full date objects
                      const startDateOnly = new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate()
                      );
                      const currentDateOnly = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate()
                      );
                      return startDateOnly >= currentDateOnly;
                    });
                  } else {
                    tempCourseStreams[i].coursestreamdetail = [];
                  }
                }

                tempCourseStreams = tempCourseStreams.filter(
                  (e) => e.coursestreamdetail && e.coursestreamdetail.length > 0
                );

                tempCourseStreams.forEach((element) => {
                  if (
                    element.coursestreamdetail &&
                    element.coursestreamdetail.length > 0
                  ) {
                    element.from = element.coursestreamdetail[0];
                  }
                });

                localStorage.setItem(
                  "selected-course-to-buy",
                  JSON.stringify(tempCourseStreams[0])
                );

                localStorage.setItem(
                  "course-streams-list",
                  JSON.stringify(tempCourseStreams)
                );
                localStorage.setItem(
                  "course-info",
                  JSON.stringify(response.data.courseinfo[0])
                );
              }
            })
            .catch((e) => {
              console.log(e);
            });

          localStorage.setItem(
            "parent-email",
            response.data.decryptedToken.email
          );

          setTimeout(() => {
            navigate("/parent-registration");
          }, 2000);
        } else {
          enqueueSnackbar("licenses access is successful", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          navigate("/parent-login");
        }
        setCourseInfo(JSON.parse(localStorage.getItem("course-info")));
      })
      .catch((error) => {
        console.error(error);
        // Handle error as needed
      });
  };

  return (
    <div className="h-100">
      <div className="row h-100 ms-0 me-0">
        <div className="col-lg-6 left-column-sp p-0" style={{ zIndex: "1" }}>
          <div className="left-column-sp-section">
            <div className="">
              <img
                src={assets.course_v2.form_meeku_logo}
                className="meeku-icon-sp"
              ></img>
            </div>
            <div className="mt-4">
              <img
                src={assets.course_v2.section_1_group_img}
                className=""
              ></img>
            </div>
            <p className="Coding-With-Title-sp mt-3">
              Coding With Bricks LEGO Based Education For Kids
            </p>
          </div>
          <img src={assets.homev3.m_icon} className="m-icon-sp"></img>
          <div className="d-flex justify-content-end">
            <img
              src={assets.course_v2.yellow_star_img}
              className="yellow_star_img_sp me-3"
            ></img>
          </div>
        </div>
        <div className="col-lg-6 right-column-pr-sp p-0">
          <div className="right-column-pr-sp-section mt-4">
            <Card className="parent-reg-card-sp mt-2">
              <Box>
                <div className="d-flex justify-content-center mt-2">
                  <img
                    src={assets.course_v2.confetti_img_pr_v2}
                    className="confetti_img_pr_sp"
                    style={{ width: "85px" }}
                  ></img>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <img
                    src={assets.course_v2.yeah_img_pr_v2}
                    className="yeah_img_pr_sp"
                    style={{ width: "185px" }}
                  ></img>
                </div>
                {courseInfo?.isFree == true ? (
                  <p className="registration-complete-text-sp pt-4">
                    Your FREE course registration is complete
                  </p>
                ) : (
                  <p className="registration-complete-text-sp pt-4">
                    Your course registration is complete
                  </p>
                )}

                <div>
                  <p className="complete-qu-text-sp m-0">What’s next?</p>
                  <p className="complete-an-text-sp m-0">
                    We sent you the detailed instructions on how to join the
                    online class to your registered email.
                  </p>
                </div>

                <div className="mt-3">
                  <p className="complete-qu-text-sp m-0">
                    Talk to your course advisor
                  </p>
                  <p className="complete-an-text-sp m-0">admin@meeku.in</p>
                </div>

                <div className="d-flex mt-4 pb-3">
                  <div
                    className="Submit-btn-sp"
                    onClick={(e) => handletabclick(e)}
                  >
                    <span>Go to Courses</span>
                  </div>
                </div>
              </Box>
            </Card>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <img
              src={assets.homev3.section3_star_img}
              className="section3_star_img_sp me-3"
            ></img>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img
              src={assets.homev3.interactive_section_mm_img}
              className="interactive_section_mm_img_sp"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLicensesSuccessPage;
