import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import slugify from 'react-slugify';
import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  LinearProgress,
  Grid,
  InputBase,
  Paper,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";

import io from "socket.io-client";

import { SnackbarProvider, useSnackbar } from 'notistack';

import moment from 'moment';

import "./pods.css";

import Iconify from "../../../components/Iconify";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

//Configs
import navigate_route_config from "../../../config/navigate_route_config";
import icons_config from "../../../config/icons";
import storage_configs from "../../../config/storage-config";
import url_configs from "../../../config/url"
import whiteboardConfig from "../../../config/whiteboard-config";
import conditional_configs from "../../../config/conditional_config";
import games from "../../../config/games";
import assets from "../../../config/assets";

//SERVICE
import UtilService from "../../../services/util.service";
import LearnerAPIService from "../../../services/learner-service";
import SessionService from "../../../services/session-service";
import ExpertService from "../../../services/expert-service";
import AdminService from "../../../services/admin-service";

//UTILS
import {
  getInitialsFromFullName,
  getCurrentUserData,
  getChats,
  storeChats,
  getOpenedChatGroup,
  storeOpenedChatGroup,
  removeOpenedChatGroup,
} from '../../../utils/util';
import { setGroupSpaceQuiz } from "../../../store/slices/settingsSlice";
import error_messages from "../../../config/error_messages";
import tooltip_messages from "../../../config/tooltip";
import FirebaseService from "../../../services/firebase-service";
import MemberService from "../../../services/member-service";

let chat_server_socket;

export default function LearnersPods() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;

  const navigate = useNavigate();
  const [courseDetail, setCourseDetail] = useState();
  const [curriculamDetail, SetCurriculamDetail] = useState([]);

  const [sessions, SetSessions] = useState([]);
  const [lastCompletedSessions, SetLastCompletedSessions] = useState([]);
  const [selectedSessionId, SetSelectedSessionId] = useState([]);
  const [openChat, SetOpenChat] = useState(false);
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);

  const [chatRoom, setChatRoom] = useState("");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiCtrlMenu, setEmojiCtrlMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState()

  const [openCompletedSessions, setOpenCompletedSessions] = useState(false);

  /* chat Option Menu */

  const [chatCtrlMenu, setChatCtrlMenu] = useState(false);
  const [chatOptionEl, setChatOptionEl] = useState();
  const [chatType, setChatType] = useState('general_chat');

  //Get course details
  useEffect(() => {
    getCurriculam();
    getAllWhiteboardTemplate();
  }, []);

  const getCurriculam = () => {
    let cId = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    setCourseDetail(cId);
    if (cId)
      ExpertService.GetCurriculum(cId.courseid)
        .then((response) => {
          let tempStreamsList = [];
          console.log('course detail pod', response.data);
          SetCurriculamDetail(response.data);
          if (response.data && response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              let tempObj = response.data[i].courseStreamDetail.filter(e => e.coursestreamid == cId.coursestreamid)[0];
              tempStreamsList.push(tempObj);
            }
          }
          setTimeout(() => {
            SetSessions(tempStreamsList);
            console.log(tempStreamsList)
          }, 100);
          //joinToGeneralChat();
        })
        .catch((e) => {
          console.log(e);
        });
  }

  const getAllWhiteboardTemplate = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      //console.log(response.data);
      setWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }



  const showBoardsList = (contentBoards, slug, isClone) => {
    let lgrp = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.learner_grp_detail));
    let boardsList = [];
    if (contentBoards) {
      for (let h = 0; h < contentBoards.length; h++) {
        if (contentBoards[h] && contentBoards[h].template_ids) {
          let splitBoardIds = contentBoards[h].template_ids.split(',');
          if (splitBoardIds && splitBoardIds.length > 0) {
            for (let i = 0; i < splitBoardIds.length; i++) {
              let boardObj = {};
              let actualBoardList = whiteboardTemplateList;
              if (actualBoardList && actualBoardList.length > 0) {
                let newWhiteboardArray = [];
                //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
                actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
                  newWhiteboardArray[index] = { ...device }
                })
                boardObj = newWhiteboardArray[0];
                let tempWLink = "";

                if (isClone == true && !lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-attended-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-attended-' + getCurrentUserData().id + whiteboardConfig.key;
                } else if (isClone == true && lgrp.groupname) {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                } else if (!lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id + whiteboardConfig.key;
                } else {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                }


                boardObj.whiteboardLink = tempWLink;
                if (getCurrentUserData().role == conditional_configs.role.expert) {
                  boardObj.is_locked = false;
                } else {
                  boardObj.is_locked = true;
                }
                boardsList.push(boardObj);
              }
            }
          }
        }
      }

    }
    return boardsList;
  }

  const openGroupSpaceOld = (e, obj) => {
    console.log(obj)
    if (obj && obj.memberCourseStreamDetails && obj.memberCourseStreamDetails.length > 0) {
      let tempDetail = obj.memberCourseStreamDetails[0]
      let cData = curriculamDetail;
      let getCurriculamDetailBySession = [];
      cData.filter(e => e.id == obj.coursesessionid).forEach((device, index) => {
        getCurriculamDetailBySession[index] = { ...device }
      });
      if (getCurriculamDetailBySession[0]) {
        //WHITEBOADS 
        let sessionBoards = getCurriculamDetailBySession[0].coursesession_whiteboards;
        if (sessionBoards && sessionBoards.length > 0) {
          let wTemplateIds = "";
          sessionBoards.forEach(element => {
            if (wTemplateIds != "") {
              wTemplateIds = wTemplateIds + ',' + element.template_ids;
            } else {
              wTemplateIds = element.template_ids
            }
          });
          setTimeout(() => {
            let boardData = [];
            console.log(wTemplateIds);
            boardData = showBoardsList(wTemplateIds, tempDetail.slug, false);
            localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_boards, JSON.stringify(boardData));
            //navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);
            console.log(boardData);
          }, 100);
        }

        //GAMES
        let sessionGames = getCurriculamDetailBySession[0].coursesession_games;
        if (sessionGames && sessionGames.length > 0) {
          sessionGames.forEach(element => {
            element.gameDetail = games.gameList.filter(e => e.id == element.puzzleid)[0];
          });
          setTimeout(() => {
            localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_games, JSON.stringify(sessionGames));
          }, 100);
        }

        //QUIZ
        let sessionQuiz = getCurriculamDetailBySession[0].coursecontentsessionquiz;
        if (sessionQuiz && sessionQuiz.length > 0) {
          let tempObj = {};
          tempObj.quizList = [];
          let getSelectedQuiz = sessionQuiz;
          tempObj.quizList = getSelectedQuiz;

          tempObj.topic = 'Quiz';
          tempObj.level = 'Beginner';
          tempObj.totalQuestions = getSelectedQuiz.length;
          tempObj.perQuestionScore = 5;
          tempObj.totalTime = 60; // in seconds
          tempObj.questions = [];

          for (let i = 0; i < getSelectedQuiz.length; i++) {
            let tempQObj = {
              question: getSelectedQuiz[i].coursequestion.question,
              type: getSelectedQuiz[i].coursequestion.questiontype,
              choices: getSelectedQuiz[i].coursequestion.answerlist,
              correctAnswer: getSelectedQuiz[i].coursequestion.answerlist.filter(e => e.correct == '1')[0]?.id,
            }
            tempObj.questions.push(tempQObj);
          }

          dispatch(setGroupSpaceQuiz(tempObj));
          setTimeout(() => {
            localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_quiz, JSON.stringify(tempObj));
          }, 100);
        }

        //LOADING NEEDED HERE
        setTimeout(() => {
          navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);
          window.location.reload();
        }, 2000);
      }
      // let tempDetail = obj.memberCourseStreamDetails[0];
      // navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);
    }
  }

  const openGroupSpace = (e, obj) => {
    console.log(obj)
    let tempDetail = obj;
    let cData = curriculamDetail;
    let getCurriculamDetailBySession = [];
    cData.filter(e => e.id == selectedSessionId).forEach((device, index) => {
      getCurriculamDetailBySession[index] = { ...device }
    });
    if (getCurriculamDetailBySession[0]) {
      //WHITEBOADS 
      let sessionBoards = getCurriculamDetailBySession[0].coursesession_whiteboards;

      if (sessionBoards && sessionBoards.length > 0) {
        // let wTemplateIds = [];
        // sessionBoards.forEach(element => {
        //   if(element.template_ids != null){
        //     if (wTemplateIds != "") {
        //       wTemplateIds = wTemplateIds + ',' + element.template_ids;
        //     } else {
        //       wTemplateIds = element.template_ids
        //     }
        //   }
        // });
        setTimeout(() => {
          let boardData = [];
          console.log(sessionBoards);
          if (obj.memberCourseStreamLearnerDetail.length > 0) {
            const checkSessionAttendedByLearner = obj.memberCourseStreamLearnerDetail.filter(e => e.learnerid == getCurrentUserData().id);
            if (checkSessionAttendedByLearner && checkSessionAttendedByLearner.length > 0) {
              boardData = showBoardsList(sessionBoards, tempDetail.slug, false);
            } else {
              boardData = showBoardsList(sessionBoards, tempDetail.slug, false);
            }
          } else {
            boardData = showBoardsList(sessionBoards, tempDetail.slug, false);
          }
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_boards, JSON.stringify(boardData));
          //navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);
          console.log(boardData);
        }, 100);
      }

      //GAMES
      let sessionGames = getCurriculamDetailBySession[0].coursesession_games;
      if (sessionGames && sessionGames.length > 0) {
        sessionGames.forEach(element => {
          element.gameDetail = games.gameList.filter(e => e.id == element.puzzleid)[0];
        });
        setTimeout(() => {
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_games, JSON.stringify(sessionGames));
        }, 100);
      }

      //QUIZ
      let sessionQuiz = getCurriculamDetailBySession[0].coursecontentsessionquiz;
      if (sessionQuiz && sessionQuiz.length > 0) {
        let tempObj = {};
        tempObj.quizList = [];
        let getSelectedQuiz = sessionQuiz;
        tempObj.quizList = getSelectedQuiz;

        tempObj.topic = 'Quiz';
        tempObj.level = 'Beginner';
        tempObj.totalQuestions = getSelectedQuiz.length;
        tempObj.perQuestionScore = 5;
        tempObj.totalTime = 60; // in seconds
        tempObj.questions = [];

        for (let i = 0; i < getSelectedQuiz.length; i++) {
          let tempQObj = {
            question: getSelectedQuiz[i].coursequestion.question,
            type: getSelectedQuiz[i].coursequestion.questiontype,
            choices: getSelectedQuiz[i].coursequestion.answerlist,
            correctAnswer: getSelectedQuiz[i].coursequestion.answerlist.filter(e => e.correct == '1')[0]?.id,
          }
          tempObj.questions.push(tempQObj);
        }

        dispatch(setGroupSpaceQuiz(tempObj));
        setTimeout(() => {
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_quiz, JSON.stringify(tempObj));
        }, 100);
      }

      //LOADING NEEDED HERE
      setTimeout(() => {
        navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);
        window.location.reload();
      }, 2000);
    }
    // let tempDetail = obj.memberCourseStreamDetails[0];
    // navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);

  }

  const openLastCompletedSessions = (e, obj) => {
    SetSelectedSessionId(obj.coursesessionid);
    if (obj && obj.memberCourseStreamDetails.length > 0) {
      SetLastCompletedSessions(obj.memberCourseStreamDetails.sort((a, b) => Number(b.id) - Number(a.id)).slice(0, 3));
      console.log(obj.memberCourseStreamDetails.sort((a, b) => Number(b.id) - Number(a.id)).slice(0, 3));
      if (obj.memberCourseStreamDetails.slice(-3).length == 1) {
        openGroupSpace('', obj.memberCourseStreamDetails[0]);
      } else {
        setOpenCompletedSessions(true);
      }
    }
  }

  const backToMyCourses = (e) => {
    navigate(navigate_route_config.learner.my_courses);
  }

  //#region CHAT

  const chatOptionMenuClickOpen = (event) => {
    setChatOptionEl(event.currentTarget);
    setChatCtrlMenu(true);
  };

  const chatOptionMenuClickClose = () => {
    setChatCtrlMenu(false);
  };

  const emitGroupChat = () => {
    // let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
    const current_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));

    if (current_stream_id && current_stream_id.coursestreamid) {
      MemberService.getMemberCourseGroupByStreamAndLearnerId(current_stream_id.coursestreamid, getCurrentUserData().id).then(
        (res) => {
          console.log("getMemberCourseGroupByStreamAndLearnerId :::", res);
          if(res && res.data){
            storeOpenedChatGroup(res.data);
          SessionService.getChatsByStreamId(current_stream_id.coursestreamid)
            .then(response => {
              console.log("Chats :::", response);
              if (response && response.data && response.data.length > 0) {
                storeChats(response.data.filter(e => e.group && e.group.coursegroupid == res.data.coursegroupid));
              }
              let uName = getCurrentUserData().name;
              let uRoom = res.data.groupname;
              setChatRoom(slugify(uRoom));
              chat_server_socket = io(url_configs.chat_server);
              chat_server_socket.emit("join", { name: uName, room: slugify(uRoom) }, (error) => {
                if (error) {
                  alert(error);
                }
              });
              chat_server_socket.on("message", (message) => {
                  let generalChats = [...messages];
                  let storedChats = getChats();
                  if (storedChats && storedChats.length > 0) {
                    generalChats = storedChats;
                  }
                  generalChats.push(message)
                  setMessages(generalChats);
                  storeChats(generalChats);
          
                  if (message && message.user != getCurrentUserData().name) {
                    enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          
                    let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
                    if (!tempNofications) {
                      tempNofications = [];
                    }
                    tempNofications.push(message);
                    localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
                  }
                
              });
            })
            .catch(error => {
              console.log(error)
            });
          }
        }
      ).catch((e) => {
        console.log(e);
      });
    }
  }

  const openLearnerPodChats = (e, type) => {
    chatOptionMenuClickClose();
    const current_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    SetOpenChat(true);
    setChatType(type);
    if (type == 'general_chat') {
      SessionService.getChatsByStreamId(current_stream_id.coursestreamid)
        .then(response => {
          console.log("Chats :::", response);
          if (response && response.data && response.data.length > 0) {
            storeChats(response.data.filter(e=> !e.group));
          }
          joinToGeneralChat();
        })
        .catch(error => {
          console.log(error)
        });
    } else if (type == 'group_chat') {
      emitGroupChat()
    }

  }

  const closeChat = (e) => {
    SetOpenChat(false);
    window.location.reload();
  }

  const joinToGeneralChat = () => {
    let cId = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    let room = 'pod-' + cId.coursestreamid;
    setChatRoom(room);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit("join", { name: getCurrentUserData().name, room: room }, (error) => {
      if (error) {
        alert(error);
      }
    });

    chat_server_socket.on("message", (message) => {
      if (message.slug == room) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message)
        setMessages(generalChats);
        storeChats(generalChats);

        if (message && message.user != getCurrentUserData().name) {
          enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

          let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
        }
      }
    });

  }

  const onClickEmoji = (emojiData, event) => {
    setSelectedEmoji(emojiData.unified);
    let tempMsg = message;
    //const textAreaElement = document.getElementById('chat-text-area');
    //setMessage(message.substr(0, textAreaElement.selectionStart) +emojiData.emoji  +message.substr(textAreaElement.selectionEnd));
    setMessage(tempMsg + emojiData.emoji)
    handleEmojiControlMenuClose();
  }

  const handleChatSubmit = (e) => {
    console.log('handleChatSubmit', message)
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: chatRoom,
      slug: chatRoom,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "learner"
    }
    if (message) {
      if (chatType == 'group_chat') {
        chat_server_socket.emit("sendMessage", { message: message, group: getOpenedChatGroup(), slug: chatRoom, userId: getCurrentUserData().id, userType: 'learner' });
        chatModel.group = getOpenedChatGroup();
      }else{
        chat_server_socket.emit("sendMessage", { message: message, group: null, slug: chatRoom, userId: getCurrentUserData().id, userType: 'learner' });
      }
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  const addChats = (data) => {
    const current_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    data.isInSession = false;
    data.courseStreamId = current_stream_id.coursestreamid;
    SessionService.addChats(data)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleEmojiControlMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEmojiCtrlMenu(true);
  };

  const handleEmojiControlMenuClose = () => {
    setEmojiCtrlMenu(false);
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes('data:image/');
      return checkIsImg;
    }
  }
  //#endregion

  const handleCloseCompletedSessionsPopup = () => {
    setOpenCompletedSessions(false);
  };

  const renderSwitch = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse()
    }

    return (
      <div className="chat-msg-list-v2" >
        {msgs?.map((val, i) => {
          return (
            <div key={i}>
              <div className="chat-my-msg-main-v2">
                {val.user === getCurrentUserData().name ?
                  <div>
                    <div className="my-name-container-v2">
                      <span className="my-name-v2">Me</span>
                    </div>
                    <div className="chat-my-msg-container-v2">
                      <div className="rvr-msg-container-v2">
                        <span className="rvr-name-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                          <img src={val.text} />}</span>
                      </div></div>
                  </div> :

                  <div className="rvr-container-v2">
                    <div className="rvr-avatar-container">
                      {getInitialsFromFullName(val.user)}
                    </div>
                    <div className="w-100 rvr-msg-container-div">
                      <div className="rvr-name-container-v2">
                        <span className="chat-rvr-name-v2">{val.user}&nbsp;{val?.userType && val?.userType == 'expert' ?'(Expert)':val?.userType && val?.userType == 'admin' ?"(Admin)":""}</span>
                      </div>
                      <div className="chat-rvr-msg-container-v2">
                        <div className="rvr-msg-container-v2">
                          <span className="chat-rvr-msg-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                            <img src={val.text} />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    )
  };

  return (
    <div className="l-pods-main">

      <Container>
        <Card className="l-pods-card mt-3" >
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Typography variant="h4" className="l-pods-coursename">
              {courseDetail?.coursename}
            </Typography>
            <Typography >
              <Tooltip title={tooltip_messages.chatGroupSpace} placement="top">
                <IconButton className="learner-pods-tour-1 l-pods-chat" onClick={(e) => chatOptionMenuClickOpen(e)}>
                  {getIcon(icons_config.chat)}
                </IconButton>
              </Tooltip>
              <Tooltip title={tooltip_messages.back} placement="top">
                <IconButton className="learner-pods-tour-2 l-pods-chat" onClick={(e) => backToMyCourses(e)} >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </Stack>
          {/* <Typography variant="h5" className="l-pods-completed-sessions-label">
              Completed Sessions
            </Typography> */}
          <Typography variant="h5" className="l-pods-completed-sessions-label">
            Sessions
          </Typography>
          <div className="learner-pods-tour-3 l-pods-streams-container">
            {sessions?.map((cSObj, cSIndex) => {
              return (
                <div key={`l-pods-key-` + cSIndex}>
                  {cSObj?.memberCourseStreamDetails.length > 0 && cSObj?.memberCourseStreamDetails[0].enddatetime ?
                    <Tooltip title={tooltip_messages.completedSessionsGroupSpace} placement="top" followCursor>
                      <div className="l-pods-streams-boxes l-pods-streams-boxes-not-completed" onClick={(e) => openLastCompletedSessions(e, cSObj)}>
                        <div>
                          <div className="l-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                          <div className="l-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                        </div>
                      </div></Tooltip> :
                    cSObj?.memberCourseStreamDetails.length == 0 ?
                      <Tooltip title={tooltip_messages.notCompletedSessionsGroupSpace} placement="top" followCursor>
                        <div className="l-pods-streams-boxes l-pods-streams-boxes-completed">
                          <div>
                            <div className="l-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                            <div className="l-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                          </div>
                        </div></Tooltip>
                      :
                      ""
                  }
                </div>
              )
            })}
          </div>

          {/* <Typography variant="h5" className="l-pods-completed-sessions-label">
              Upcoming Sessions
          </Typography>
          <div className="l-pods-streams-container">
            {sessions?.map((cSObj, cSIndex) => {
              return (
                <div key={`l-pods-key-` + cSIndex}>
                  {cSObj?.memberCourseStreamDetails.length == 0 ?
                    <div title={tooltip_messages.notCompletedSessionsGroupSpace} className="l-pods-streams-boxes l-pods-streams-boxes-completed">
                      <div>
                        <div className="l-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                        <div className="l-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                      </div>
                    </div>
                    : ""
                  }</div>
              )
            })}
          </div> */}
        </Card>
      </Container>
      {openChat ?
        <div className="l-pods-chat-container">
          <div className="l-pods-chat-main">
            <div className="l-pods-chat-header">
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                <Typography variant="h4">
                  Chats
                </Typography>
                <Typography >
                  <IconButton className="l-pods-chat" onClick={(e) => closeChat(e)}>
                    <img className="cursor-pointer" src={assets.whiteClose} width="16px" height="16px" />
                  </IconButton>
                </Typography>
              </Stack>
            </div>
            <div className="l-pods-chat-body">
              {renderSwitch()}
            </div>
            <div className="l-pods-chat-footer">

              <form className="mt-2 mb-2 chat-box-container-v2" action="" onSubmit={handleChatSubmit}>
                <textarea id="chat-text-area" rows={3} className="form-control send-input-v2" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write message" />
                <div className="input-group mb-3 send-input-box-v2">

                  <span className="pe-2 send-screenshot d-flex" >
                    <img src={assets.emoji} width={23} height={23} className="me-2" onClick={(e) => handleEmojiControlMenuClick(e)} ></img>
                    {/* <span onClick={(e) => sendScreenshot(e)}> {getIcon(icons_config.screenshot)}</span> */}
                  </span>
                  {/* <span className="pe-2 send-screenshot" >
                       
                      </span> */}
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary chat-send-btn" type="submit">
                      <img src={assets.sendChatWhite}></img>
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> : ""}

      {/* EMOJI MENU */}
      <Menu className="emoji-menu"
        anchorEl={anchorEl}
        open={emojiCtrlMenu}
        onClose={handleEmojiControlMenuClose}
      >
        <MenuItem>
          <EmojiPicker
            onEmojiClick={onClickEmoji}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </MenuItem>
      </Menu>

      {/* chat Option Menu */}
      <Menu
        anchorEl={chatOptionEl}
        open={chatCtrlMenu}
        onClose={chatOptionMenuClickClose}
      >
        <MenuItem>
          <Typography variant="inherit" onClick={(e) => openLearnerPodChats(e, 'general_chat')}>
            General Chat
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="inherit" onClick={(e) => openLearnerPodChats(e, 'group_chat')}>
            My Group
          </Typography>
        </MenuItem>
      </Menu>

      <Dialog
        open={openCompletedSessions}
        onClose={handleCloseCompletedSessionsPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          {"Choose from last three sessions"}
          <span className="add_kids_close_icon" onClick={handleCloseCompletedSessionsPopup}>
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex">
            {lastCompletedSessions?.map((obj, idx) => {
              return (
                <div key={`last-completed-sessions-key-` + obj?.id} onClick={(e) => openGroupSpace(e, obj)}>
                  <div className="l-pods-streams-boxes l-pods-streams-boxes-not-completed l-pods-streams-boxes-width">
                    <div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("dddd").slice(0, 3)}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("MMM Do")}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("LT")} - {moment(obj?.enddatetime).format("LT")}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions >

        </DialogActions>
      </Dialog>
    </div>
  );
}
