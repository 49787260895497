import * as Yup from 'yup';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarProvider, useSnackbar } from 'notistack';

// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

//Services
import AuthService from '../../../services/auth/auth.service';

//Utils
import { getCurrentUserData } from '../../../utils/util';

//Configs
import roleConfig from '../../../config/conditional_config';
import navigate_route_config from '../../../config/navigate_route_config';
import meta_data from '../../../config/meta_data';

import {
  Paper,
  Box,
  Grid,
  Typography
} from '@mui/material';

// ----------------------------------------------------------------------

export default function AdminLogin() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit  = data => {
    AuthService.admin_login(data.email, data.password)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Logged in successful', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
         if(getCurrentUserData().role == roleConfig.role.admin){
          navigate('/customers');
         }else{
          navigate('/whiteboard-templates');
         }
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  useEffect(() => {
    if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin){
      navigate('/customers');
    }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert){
      navigate(navigate_route_config.expert.my_courses);
    }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner){
      navigate(navigate_route_config.learner.my_courses);
    }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent){
      navigate(navigate_route_config.parent.my_kids);
    }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.curriculam_creator){
      navigate(navigate_route_config.admin.whiteboard_templates);
    }else {
      localStorage.clear();
    }
  }, []);

  return (

    <Grid className='form-bg' container
      spacing={0} direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>
      <Paper className='form-bg-c'>
        <Box px={3} py={2} >
          <Typography variant="h6" align="center" margin="dense">
           {meta_data.welcomeText}
          </Typography>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}  sx={{ my: 2 }}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
        <a href='/forgot-password'>Forgot password?</a>
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>

    </Box>
    </Paper>
    </Grid>
  );
}
