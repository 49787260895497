const game_config = {
    url: {
        gameUrl: 'https://test.game.meeku.world/game01?token='
        //gameUrl: 'http://localhost:3000/robo-shaper?token='
    },
    userType:{
        teacher: 'teacher',
        student: 'student',
        spectator: 'spectator'
    }
};
    
export default game_config;