
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import './course-detail.css'
//import { sentenceCase } from 'change-case';
//import { Link as RouterLink } from 'react-router-dom';
import CourseService from '../../../../services/courses-service';
import AdminService from '../../../../services/admin-service';
import SessionService from '../../../../services/session-service';

import { SnackbarProvider, useSnackbar } from 'notistack';

//DATETIME FORMAT
import moment from 'moment';
import { fDate } from "../../../../utils/formatTime";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Grid,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CalendarMonth from '@mui/icons-material/CalendarMonth';

//DATE PICKER
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { fullDate } from '../../../../utils/formatTime';

import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

// components
import Page from '../../../../components/Page';
//import Label from '../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../../sections/@dashboard/user';

// CONFIGS
import navigate_route_config from '../../../../config/navigate_route_config';
import conditional_configs from '../../../../config/conditional_config';
import url_configs from '../../../../config/url';

import { getCurrentUserData } from '../../../../utils/util';
import { setCheckoutData } from '../../../../store/slices/settingsSlice';

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'coursename', label: 'Course Name', alignRight: false },
  { id: 'expertid', label: 'Expert', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'learners', label: 'Learners', alignRight: false },
  { id: 'startdate', label: 'Start Date', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function CourseDetail() {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  let { courseid } = useParams();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUserList] = useState([]);

  const navigate = useNavigate();

  const [streamList, setStreamList] = useState([]);

  const [parentList, setParentList] = useState([]);

  const [kidsList, setKidsList] = useState([]);

  const [selectedParent, setSelectedParent] = React.useState("");

  const [selectedStreamId, setSelectedStreamId] = React.useState("");

  const [openCourseAssign, setOpenCourseAssign] = React.useState(false);

  const [openCourseKids, setOpenCourseKids] = React.useState(false);

  //ADD NEW POD DECRATION
  const [openAddPod, setOpenAddPod] = React.useState(false);
  const [expertList, setExpertList] = useState([]);
  const [selectedExpert, setSelectedExpert] = React.useState("");
  const [sessionStartDate, setSessionStartDate] = React.useState();
  const [sessionStartTime, setSessionStartTime] = React.useState();
  const [sessionEndTime, setSessionEndTime] = React.useState();

  //SCHEDULE A COURSE SESSION
  const [openScheduleCourse, setOpenScheduleCourse] = React.useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = React.useState("");
  const [selectedCourseStream, setSelectedCourseStream] = React.useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getExpertList();
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      GetCoursesDetailsById();
      getParentList();
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }

  }, []);

  const GetAllLearnersByStreamId = (streamId) => {
    CourseService.GetAllLearnersByStreamId(streamId).then(response => {
      console.log('kids', response);
      setKidsList(response.data);
      handleOpenCourseKids(streamId)
    })
      .catch(e => {
        console.log(e);
      });
  }

  const GetCoursesDetailsById = () => {
    CourseService.GetCoursesDetailsById(courseid).then(response => {
      console.log(response);
      if (response.data && response.data.coursestream && response.data.coursestream.length > 0) {
        let tempStreamList = response.data.coursestream;
        tempStreamList.forEach(element => {
          element.coursename = response.data.courseinfo[0].name;
          element.price = response.data.courseinfo[0].price;
          element.maxnolearners = response.data.courseinfo[0].maxnolearners;
          element.minnolearners = response.data.courseinfo[0].minnolearners;
        });
        setStreamList(tempStreamList);
        setUserList(tempStreamList);
        console.log('tempStreamList', tempStreamList);
      }

    })
      .catch(e => {
        console.log(e);
      });
  }

  const getParentList = () => {
    AdminService.getallparents().then(response => {
      console.log(response.data.data);
      setParentList(response.data.data);
      setSelectedParent("");
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const navigateToCreateCourse = () => {
    navigate(navigate_route_config.admin.create_course);
  }

  const handleOpenCourseAssign = (e, strmId) => {
    setSelectedStreamId(strmId);
    setOpenCourseAssign(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseCourseAssign = () => setOpenCourseAssign(false);

  const handleParentChange = (event) => {
    setSelectedParent(event.target.value);
  };

  const handleNext = (data) => {
    console.log(data);
    console.log('Streamid', selectedStreamId);

    let model = {
      coursestreamid: selectedStreamId.toString(),
      memberid: data.selectedParent.toString(),
      paidon: '2023-03-13',
      linkno: '1234',
      amount: '100.00'
    }
    console.log('Assign Course Model', model);

    //setSelectedParent("");

    // CourseService.assignCourseToParent(model)
    //   .then(response => {
    //     console.log(response);

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (response && response.data && response.success === 'true') {
    //       enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else if (response && response.data && response.success === 'false') {
    //       enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   })
    //   .catch(error => {

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log('Error', error.message);
    //       enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   });
  }

  const purchaseCourse = (e, data) => {
    console.log(data);
    if(data && data.coursestreamdetail){
      let model = {
        coursestreamid: data.coursestreamdetail[0].coursestreamid.toString(),
        memberid: getCurrentUserData().id.toString(),
        paidon: fullDate(),
        linkno: '1234',
        amount: data.price
      }
      console.log('Assign Course Model', model);
      dispatch(setCheckoutData(model));
      navigate('/checkout')
    }

    //setSelectedParent("");

    // CourseService.assignCourseToParent(model)
    //   .then(response => {
    //     console.log(response);

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (response && response.data && response.success === 'true') {
    //       enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else if (response && response.data && response.success === 'false') {
    //       enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   })
    //   .catch(error => {

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log('Error', error.message);
    //       enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   });
  }

  const handleOpenCourseKids = (strmId) => {
    setSelectedStreamId(strmId);
    setOpenCourseKids(true)
  };

  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseCourseKids = () => setOpenCourseKids(false);

  const generatePodDetail = (list) => {
    console.log('list', list)
    if (list && list.length > 0) {
      let startDate = '';
      let endDate = '';
      startDate = list[0].startdate;
      endDate = list[(list.length - 1)].startdate;
      //return endDate + ' - ' + startDate;
      return startDate + ' - ' + endDate;
    } else {
      return "";
    }
  }

  // Add new pod
  const handleCloseAddPod = () => setOpenAddPod(false);

  const handleExpertChange = (event) => {
    setSelectedExpert(event.target.value);
  };

  const handleAddPodSubmit = (data) => {

  }

  const openAddPodPopup = (e) => {
    e.stopPropagation();
    setOpenAddPod(true);
    setSelectedExpert(null);
    setSessionStartDate(null);
    setSessionStartTime(null);
    setSessionEndTime(null);
    getExpertList();
    // let tempStreamObj = {
    //   expertid: "",
    //   startdate: "",
    //   session: []
    // }
  }

  const getExpertList = () => {
    AdminService.getAllActiveExperts().then(response => {
      console.log('Experts-',response.data)
      setExpertList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleStreamSessionChange = (event, field) => {
    //let tempStreamList = [...streamList];

    if (field == 'startdate') {
      let stDate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      setSessionStartDate(stDate);
    } else if (field == 'from') {
      let stTime = event.$d;
      setSessionStartTime(stTime);
    } else if (field == 'to') {
      let endTime = event.$d;
      setSessionEndTime(endTime);
    } else {
      return;
    }
  }

  //SCHEDULE A COURSE
  const handleCloseScheduleCourse = () => setOpenScheduleCourse(false);

  const handleChangeCourseSchedule = (event) => {
    setSelectedSchedule(event.target.value);
  };

  const handleOpenCourseSchedule = (e, objStream, cObj) => {
    e.stopPropagation();
    setOpenScheduleCourse(true);
    setSelectedExpert(cObj.expertid);
    setSelectedCourseStream(cObj.id);
    setSelectedSchedule(null);
    setScheduleList([]);
    setScheduleList(objStream);
    getExpertList();
  }

  const submitScheduleClass = (data) => {
    console.log('Schedule class', data)

    const date = new Date();
    let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
    let startSessionModel = {
      expertid: selectedExpert.toString(),
      coursestreamdetailid: data.selectedSchedule.toString(),
      startdatetime: formatedTodayDate
    }
    //let generateLink = url_configs.frontend +'join-demo/'+current_stream_id+'/'+slug;
    console.log('Schedule class - submitted', startSessionModel);
    SessionService.startSessionByExpert(startSessionModel).then(response => {
      console.log('Session-', response);
      if (response && response.success == "true") {
        if (response.data) {
          let tempSlug = response.data.slug;
          let generateLearnerLink = url_configs.frontend + 'join-demo/' + selectedCourseStream + '/' + tempSlug;
          alert(generateLearnerLink)
          //navigate('/expert-session/classroom/' + tempSlug);
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const convertTime = (time) => {
    if (time) {
      let convertedTime = moment(time, 'HH:mm').format('hh:mm a');
      return convertedTime;
    }
  }

  const getExpert = (d) => {
    let eData = [...expertList]
    if (d) {
      if (eData && eData.length > 0) {
        let expertData = eData.filter(e=>e.id == d)[0];
        if(expertData){
          return expertData.firstname
        }else{
          return ""
        }
      }
    }
  }
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pods
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e) => openAddPodPopup(e)} >
            Add Pod
          </Button> */}
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filterPlaceholder='Search Course' /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, courseid, coursename, price, expertid, minnolearners, maxnolearners, startdate, coursestreamdetail } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {coursename}
                            </Typography>
                          </Stack>
                          <div className='pod_detail_col'>{generatePodDetail(coursestreamdetail)}</div>
                        </TableCell>
                        <TableCell align="left">{getExpert(expertid)}</TableCell>
                        <TableCell align="left">${price}</TableCell>
                        <TableCell align="left">{'Min ' + minnolearners + ' Max ' + maxnolearners}</TableCell>
                        <TableCell align="left">{startdate}</TableCell>
                        {/* <TableCell align="left"><span className='pod_detail_col'>{generatePodDetail(coursestreamdetail)}</span></TableCell> */}
                        <TableCell align="left">
                          <Typography>
                            <Stack direction="row" spacing={1}>

                            <Tooltip title="Assign Pod to Parent" placement="bottom-start">
                            <Button variant="contained" startIcon={<Iconify icon="material-symbols:payments-outline" />} onClick={(e)=>purchaseCourse(e, row)} >
            Pay
          </Button>
                              </Tooltip>
                              
                              {/* <Tooltip title="Assign Pod to Parent" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => handleOpenCourseAssign(e, id)} >
                                  <PersonAddIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="View Kids" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => GetAllLearnersByStreamId(id)} >
                                  <ChildCareIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Schedule Class" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => handleOpenCourseSchedule(e, coursestreamdetail, row)} >
                                  <CalendarMonth />
                                </IconButton>
                              </Tooltip> */}
                              {/* <IconButton aria-label="Pod">
                                <KeyboardArrowRightIcon />
                              </IconButton> */}
                            </Stack>
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* ADD NEW POD */}
      <Dialog id='assign_course' open={openAddPod} onClose={handleCloseAddPod}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Add Pod
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseAddPod}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(handleAddPodSubmit)} >
            <div>
              <Controller
                control={control}
                name="selectedExpert"
                rules={{ required: "Expert is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="experts"
                      variant="outlined"
                      margin="dense"
                    >
                      Experts
                    </InputLabel>
                    <Select
                      labelId="experts"
                      id="expert-select"
                      value={selectedExpert}
                      label="Expert"
                      onChange={handleExpertChange}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedExpert}
                      helperText={errors.selectedExpert?.message}
                    >
                      {expertList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{obj.firstname}({obj.email})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            {/* SESSIONS FORM */}
            <Grid item xs={12} sm={12} className="mt-4 mb-2">
              <Grid container alignItems="center" spacing={1}>
                {/* SESSION DATE */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="mt-sm-1">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        fullWidth
                        label="Start Date"
                        id={'session_start_date_id'}
                        name={'session_start_date'}
                        inputFormat="DD/MM/YYYY"
                        value={sessionStartDate}
                        onChange={(e) => handleStreamSessionChange(e, 'startdate')}
                        renderInput={(params) => <TextField
                          fullWidth
                          {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                {/* SESSION FROM TIME */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className=" mt-sm-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="From"
                        id={'session_from_id'}
                        name={'session_from'}
                        value={sessionStartTime}
                        onChange={(e) => handleStreamSessionChange(e, 'from')}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                {/* SESSION TO TIME */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className=" mt-sm-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="To"
                        id={'session_to_id'}
                        name={'session_to'}
                        value={sessionEndTime}
                        onChange={(e) => handleStreamSessionChange(e, 'to')}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* SCHEDULE A COURSE SESSION */}
      <Dialog id='assign_course' open={openScheduleCourse} onClose={handleCloseScheduleCourse}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Schedule Course
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseScheduleCourse}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(submitScheduleClass)} >
            <div>
              <Controller
                control={control}
                name="selectedSchedule"
                rules={{ required: "Schedule time is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="schedules"
                      variant="outlined"
                      margin="dense"
                    >
                      Sessions
                    </InputLabel>
                    <Select
                      labelId="schedules"
                      id="schedules-select"
                      value={selectedSchedule}
                      label="Language"
                      onChange={handleChangeCourseSchedule}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedSchedule}
                      helperText={errors.selectedSchedule?.message}
                    >
                      {scheduleList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{fDate(obj.startdate)}({convertTime(obj.from)} - {convertTime(obj.to)})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* ASSIGN COURSE TO PARENT */}
      <Dialog id='assign_course' open={openCourseAssign} onClose={handleCloseCourseAssign}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Assign Course
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseCourseAssign}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(handleNext)} >
            <div>
              <Controller
                control={control}
                name="selectedParent"
                rules={{ required: "Parent is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="languages"
                      variant="outlined"
                      margin="dense"
                    >
                      Parents
                    </InputLabel>
                    <Select
                      labelId="languages"
                      id="languages-select"
                      value={selectedParent}
                      label="Language"
                      onChange={handleParentChange}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedParent}
                      helperText={errors.selectedParent?.message}
                    >
                      {parentList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{obj.firstname}({obj.email})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* DISPLAY LINKED KIDS TO PARENT */}
      <Dialog id='course_linked_kids' open={openCourseKids} onClose={handleCloseCourseKids}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Kids - <span className='badge-kids-count'>{kidsList.length}</span>
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseCourseKids}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          {kidsList.map((obj, index) => {
            return (
              <div className='stream-kids me-2 mb-2'>{obj.firstname} - {obj.lastname}({obj.email})</div>
            )
          })}
        </DialogContent>
      </Dialog>
    </Page>
  );
}
