import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";

const upload_document = data => {
    const uploading_file = new FormData();
    uploading_file.append("files", data);
    const headerConfig = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
        }
    }
    return http.post(api_path.upload_document, uploading_file, headerConfig).then((response) => {
        console.log(response)
        return response.data;
    });
};

const upload_images = (data) => {
    const uploading_files = new FormData();

    data.forEach((file, index) => {
        uploading_files.append(`images[${index}]`, file);
    });

    const headerConfig = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
        }
    };

    return http.post(api_path.upload_document, uploading_files, headerConfig)
        .then((response) => {
            console.log(response);
            return response.data;
        });
};

const getDocumentById = async (data) => {
    const response = await http.get('/document/' + data, { headers: authHeader() });
    return response.data;
};

const deleteDocument = async (id) => {
    const response = await http.delete(api_path.documents +'/' + id, { headers: authHeader() });
    return response.data;
};


// const getDocumentById = async (data) => {
//     const response = await http.get('/document/'+ data, { headers: authHeader() });
//     return response.data;
// };

const DocumentService = {
    upload_document,
    getDocumentById,
    upload_images,
    deleteDocument
};
export default DocumentService;