import React from "react";
import assets from "../../../../config/assets";
import { Container } from "@mui/material";
import "./courses-details-new.css";

export default function CoursesDetailsNew() {

  return (
    <div className="share-code-course-det-session-div">
      <Container>
        {/* course-detalis-session */}
        <div className="share-code-course-det-session">
          {/* <div>
            <img alt="" src={assets.course_v2.section_1_group_img} className="share-code-course-det-img" />
          </div> */}
          <div>
            <p className="share-code-course-det-title">Soccer Strategist</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <iframe
          src="https://player.vimeo.com/video/957722739"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          width="100%"
          height="400px"
          title="Soccer"
        ></iframe>
        </div>
        
        {/* Your Challenge */}
        <div>
          <div className="share-code-course-det-your-challenge-session mt-5">
            <div class="share-code-course-det-checkpoint-icon"><div class="share-code-course-det-checkpoint-bullet"></div>
            </div>
            <p className="share-code-course-det-header-title m-0">Your Challenge</p>
            {/* <p className="share-code-course-det-end-text mb-0"></p> */}
          </div>

          <div class="share-code-course-det-wrapper">
            <div class="share-code-course-det-line-component rounded-s-h"></div>
            <div className="share-code-course-det-content">

              Let's infuse our living spaces with the vibrant colors and joyful spirit of Easter! Get
              ready to transform your room into a whimsical springtime sanctuary with charming
              Easter decorations. Picture your space adorned with cheerful bunnies, pastel-colored
              eggs, and blossoming flowers, creating an atmosphere of warmth and renewal. From
              adorable bunny bunting fluttering across your walls to elegant floral centerpi

            </div>
          </div>
        </div>


        {/*  GALLERY */}
        <div>
          <div className="share-code-course-det-your-challenge-session mt-5">
            <div class="share-code-course-det-checkpoint-icon"><div class="share-code-course-det-checkpoint-bullet"></div>
            </div>
            <p className="share-code-course-det-header-title m-0">GALLERY</p>
            {/* <p className="share-code-course-det-end-text mb-0">Posts</p> */}
          </div>

          <div class="share-code-course-det-wrapper">
            <div class="share-code-course-det-line-component rounded-s-h"></div>
            <div className="share-code-course-det-content">

              Let's infuse our living spaces with the vibrant colors and joyful spirit of Easter! Get
              ready to transform your room into a whimsical springtime sanctuary with charming
              Easter decorations. Picture your space adorned with cheerful bunnies, pastel-colored
              eggs, and blossoming flowers, creating an atmosphere of warmth and renewal. From
              adorable bunny bunting fluttering across your walls to elegant floral centerpi

            </div>
          </div>
        </div>

        {/*  DO IT YOURSELF */}
        <div>
          <div className="share-code-course-det-your-challenge-session mt-5">
            <div class="share-code-course-det-checkpoint-icon"><div class="share-code-course-det-checkpoint-bullet"></div>
            </div>
            <p className="share-code-course-det-header-title m-0">DO IT YOURSELF</p>
            {/* <p className="share-code-course-det-end-text mb-0">Posts</p> */}
          </div>

          <div class="share-code-course-det-wrapper">
            <div class="share-code-course-det-line-component rounded-s-h"></div>
            <div className="share-code-course-det-content">
              Let's infuse our living spaces with the vibrant colors and joyful spirit of Easter! Get
              ready to transform your room into a whimsical springtime sanctuary with charming
              Easter decorations. Picture your space adorned with cheerful bunnies, pastel-colored
              eggs, and blossoming flowers, creating an atmosphere of warmth and renewal. From
              adorable bunny bunting fluttering across your walls to elegant floral centerpi
            </div>
          </div>
        </div>

      </Container>
    </div>
  );
}
