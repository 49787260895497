import React from 'react';
//import Header from '../../components/header/header';
import LandHeader from '../landing-page';
import BecomeAnExpert from './become-an-expert';
import meta_data from '../../config/meta_data';

function App() {
    document.title = meta_data.company +'| BecomeAnExpert';
    return (
        <div>
            <BecomeAnExpert />
        </div>
    );
}

export default App;
