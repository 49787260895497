import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import moment from 'moment-timezone';
import "./courses-details-v2.css";

import { useSnackbar } from 'notistack';
//Components
import UserNameDetails from '../../../components/user-name-details/user-name-details';
import CourseDetailHeader from "../../../components/course-detail-header/course-detail-header";

import assets from "../../../config/assets";
import { Button, Typography, Container, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';


import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import CourseService from "../../../services/courses-service";

import { getCurrentUserData, getTimeZoneShortForm, getUnique, copyToClipBoard, checkIsUserNotLoggedInOrParentIsLoggedIn, checkIsINR, setReferralCode, convertTime, generateCommonParams } from "../../../utils/util";

import { setCheckoutData, setCurrentLocationDetail } from "../../../store/slices/settingsSlice";
import { fullDate } from "../../../utils/formatTime";

//CONFIGS
import conditional_configs from '../../../config/conditional_config';
import navigate_route_config from "../../../config/navigate_route_config";
import TooltipWithHtml from "../../../components/tooltip-with-html/tooltip-with-html";
import { loadScriptByURL } from "../../../utils/util";


//SERVICES
import CustomerService from "../../../services/customer-service";
import IntegratedTools from "../../../components/Integrated-tools/Integrated-tools";
import Faq from "../../../components/faq/faq";
import Testimonial from "../../../components/testimonial/testimonial";
import Footer from "../../../components/footer/footer";
import UtilService from "../../../services/util.service";
import route_config from "../../../config/route_config";
import TempCustomerService from "../../../services/temp-customers-service";
export default function CourseDetailsV2() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  //HEADER DECLARATIONS
  const [activeHeaderMenu, setActiveHeaderMenu] = React.useState();
  let registerFreeCourse = React.useRef()
  let scheduleSectionRef = React.useRef()
  /** DECLARATIONS */
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  //GET SLUG FROM LINK
  let { slug } = useParams();

  const navigate = useNavigate();

  // mobile view toggle function
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [courseDetail, setCourseDetail] = useState();
  const [courseStream, setCourseStream] = useState([]);
  const [courseStreamDetail, setCourseStreamDetail] = useState([]);

  const [selectedCourseFrom, setSelectedCourseFrom] = useState();
  const [parentEmail, setParentEmail] = useState();
  const [parentName, setParentName] = useState();

  const currentLocationDetail = useSelector((state) => state.settings.currentLocationDetail);

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };
  // mobile view toggle function end

  const section_img_1 = {
    backgroundImage: `url(${assets.homev3.maskGroup_2})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };



  useEffect(() => {
    setReferralCode();
    loadScriptByURL();
    getCurrentCountryInfoByIpAddress();
    if (slug) {
      let splitSlug = slug.split('-');
      let lastIndex = splitSlug[(splitSlug.length - 1)];
      GetCoursesDetailsById(lastIndex);
    } else {
      navigate("/")
    }

  }, []);

  // const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    // best learning section
    date: Yup.string().required("Date is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    // best learning section end
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    const currentUrl = window.location.href;

    try {
      const response = await TempCustomerService.tempCustomersRegister({
        name: parentName,
        email: parentEmail,
        coursename: courseDetail?.courseinfo[0]?.name,
        current_url: currentUrl,
      });
      console.log("Response:", response);
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.expert
    ) {
      enqueueSnackbar("Please logout from current session!", {
        variant: "warning",
        key: "topright",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      enqueueSnackbar("Please logout from current session!", {
        variant: "warning",
        key: "topright",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.learner
    ) {
      enqueueSnackbar("Please logout from current session!", {
        variant: "warning",
        key: "topright",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    const checkCourse = JSON.parse(
      localStorage.getItem("selected-course-to-buy")
    );
    if (!checkCourse) {
      return enqueueSnackbar("Courses are booked!", {
        variant: "warning",
        key: "topright",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    localStorage.setItem("parent-email", parentEmail);
    localStorage.setItem("parent-name", parentName);
    localStorage.setItem(
      "course-info",
      JSON.stringify(courseDetail.courseinfo[0])
    );
    navigate("/" + route_config.parent.registration);
  };

  function handleParentNameChange(event) {
    setParentName(event.target.value)
  }

  function handleParentEmailChange(event) {
    setParentEmail(event.target.value)
  }

  const getCurrentCountryInfoByIpAddress = () => {
    UtilService.getCurrentCountryInfoByIpAddress().then(response => {
      console.log(response.data);
      if (response.data) {
        dispatch(setCurrentLocationDetail(response.data));
      }
    }).catch(e => {
      console.log(e);
    });
  }

  const GetCoursesDetailsById = (cId) => {
    CourseService.GetCoursesDetailsById(cId).then(response => {
      console.log('c-d', response)
      setCourseDetail(response.data);
      if (response.data && response.data.coursestream && response.data.coursestream.length > 0) {
        var tempCourseStreams = response.data.coursestream;

        for (let i = 0; i < tempCourseStreams.length; i++) {
          const checkPodAlreadyStarted = tempCourseStreams[i].coursestreamdetail.filter(e => e.memberCourseStreamDetails && e.memberCourseStreamDetails.length > 0);
          if (checkPodAlreadyStarted.length == 0) {
            // const currentDate = new Date();
            // tempCourseStreams[i].coursestreamdetail = tempCourseStreams[i].coursestreamdetail.filter(e => new Date(e.startdate) >= currentDate);
            tempCourseStreams[i].coursestreamdetail = tempCourseStreams[i].coursestreamdetail.filter(
              (e) => {
                const startDate = new Date(e.startdate);
                const currentDate = new Date();
                // Extract date components from the full date objects
                const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                return startDateOnly >= currentDateOnly;
              }
            );
          } else {
            tempCourseStreams[i].coursestreamdetail = [];
          }
        }

        tempCourseStreams = tempCourseStreams.filter(e => e.coursestreamdetail && e.coursestreamdetail.length > 0);

        tempCourseStreams.forEach(element => {
          if (element.coursestreamdetail && element.coursestreamdetail.length > 0) {
            element.from = element.coursestreamdetail[0];
          }
        });

        setCourseStream(tempCourseStreams);
        console.log(tempCourseStreams);

        let tempStreamDetail = tempCourseStreams.map((obj) => {
          if (obj.coursestreamdetail && obj.coursestreamdetail.length > 0) {
            return obj.coursestreamdetail[0];
          } else {
            return [];
          }
        });

        console.log(tempStreamDetail, 'from')
        setCourseStreamDetail(getUnique(tempStreamDetail, 'from'));
        console.log(getUnique(tempStreamDetail, 'from'))

        if (tempCourseStreams[0]) {
          if (getLicenseCount(tempCourseStreams[0]) >= response.data?.courseinfo[0].maxnolearners) {
            setSelectedCourseFrom("");
            setValue('course-stream-select', "");
            localStorage.removeItem('selected-course-to-buy');
          } else {
            setSelectedCourseFrom(tempCourseStreams[0].id);
            localStorage.setItem('selected-course-to-buy', JSON.stringify(tempCourseStreams[0]));
          }
        }
        localStorage.setItem('course-streams-list', JSON.stringify(tempCourseStreams));
        localStorage.setItem('course-info', JSON.stringify(response.data.courseinfo[0]));
      }
    }).catch(e => {
      console.log(e);
    });
  }

  const selectCourse = (e, courseStreamObj) => {
    console.log(courseStreamObj)
    if (getCurrentUserData()) {
      let model = {
        coursestreamid: courseStreamObj.id.toString(),
        memberid: getCurrentUserData().id.toString(),
        paidon: fullDate(),
        linkno: '0',
        amount: checkIsINR(currentLocationDetail ? currentLocationDetail.currency : 'USD') ? courseDetail.courseinfo[0]?.price_inr : courseDetail.courseinfo[0]?.price,
        quantity: 1,
        currency: currentLocationDetail ? currentLocationDetail.currency.toLowerCase() : 'usd'
      }

      if (courseDetail.courseinfo[0].isFree == true) {
        CourseService.assignCourseToParent(model)
          .then(response => {
            console.log(response);
            if (response && response.data && response.success === 'true') {
              navigate('/success?free_course_status=succeeded');
              enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            } else if (response && response.data && response.success === 'false') {
              enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            } else {
              enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
          })
          .catch(error => {
            navigate('/parent/my-courses');
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
              enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
          });
      } else {
        localStorage.setItem('selected-course-to-buy', JSON.stringify(courseStreamObj));
        localStorage.setItem('checkout-data', JSON.stringify(model));
        dispatch(setCheckoutData(model));
        navigate('/checkout');
      }
    } else {
      localStorage.setItem('selected-course-to-buy', JSON.stringify(courseStreamObj));
      //let formatedDate = moment(courseStreamObj?.startdate).format('dddd') + ',' + moment(courseStreamObj?.startdate).format("MMM Do") + '(' + convertTime(courseStreamObj?.from?.from) + ')';
      setSelectedCourseFrom(courseStreamObj.id);
      RegisterFreeCourse();
    }
  }

  const selectCourseBySelectEvent = (event) => {
    if (event.target.value) {
      setSelectedCourseFrom(event.target.value)
      console.log(event.target.value);
      const streams = courseStream;
      const getStream = streams.filter(e => e.id == event.target.value)[0];
      localStorage.setItem('selected-course-to-buy', JSON.stringify(getStream));
      console.log(getStream);
    }
  }

  const getCourseTooltipDescription = (courseStreamObj, type) => {
    if (type == 'title') {
      const startDt = moment(courseStreamObj?.startdate).format('ll');
      const endDt = moment(courseStreamObj?.coursestreamdetail?.startdate).format('ll');
      return startDt + ' - ' + endDt;
    } else if (type == 'desc') {
      return convertTime(courseStreamObj?.from?.from) + ' ' + getTimeZoneShortForm(generateCommonParams(courseDetail.id));
    }
  }

  const RegisterFreeCourse = () => {
    window.scrollTo({ behavior: 'smooth', top: registerFreeCourse.current.offsetTop })
  }

  const naviageteToScheduleSection = () => {
    window.scrollTo({ behavior: 'smooth', top: scheduleSectionRef.current.offsetTop })
  }

  const getCourseStreamValue = (obj) => {
    const formatedDt = moment(obj?.startdate).format('dddd') + ', ' + moment(obj?.startdate).format("MMM Do") + '(' + convertTime(obj?.from?.from) + ' ' + getTimeZoneShortForm(generateCommonParams(courseDetail.id)) + ')';
    return formatedDt;
  }

  const getLicenseCount = (obj) => {
    var lic = 0;
    if (obj && obj.memberpayment && obj.memberpayment.length > 0) {
      for (var i = 0; i < obj.memberpayment.length; i++) {
        lic = lic + obj.memberpayment[i].license;
      }
    }
    return lic;
  }
  // const handleCopyToClipBoard = (e) => {
  //   copyToClipBoard(window.location.href);
  // }

  const getStartDate = (cStreamObj) => {
    if (cStreamObj && cStreamObj.length > 0) {
      var checkCompletedSession = cStreamObj.filter(e => e.from && e.from.memberCourseStreamDetails && e.from.memberCourseStreamDetails.length == 0);
      if (checkCompletedSession && checkCompletedSession.length > 0) {
        const sortSessions = checkCompletedSession.sort((a, b) => new Date(b.startdate) - new Date(a.startdate));
        return moment(sortSessions[0]?.startdate).format("ll");
      } else {
        return "";
      }
    }
  }

  return (
    <div>
      {/* HEADER - DESKTOP VIEW */}
      <Box className='large-screen-course-v2' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
        {/* header */}
        <CourseDetailHeader referPage={registerFreeCourse} courseDetail={courseDetail} />
      </Box>

      {/* HEADER - MOBILE VIEW */}
      <Box className='mobile-screen-course-v2' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
        {/* header */}
        <CourseDetailHeader referPage={registerFreeCourse} courseDetail={courseDetail} />
      </Box>
      {/* BANNER */}
      <Box className=''>
        <div style={section_img_1} className="background-image-sec-1-cv2">
          <div className="container">
            <div className="row ms-0 me-0">
              <div className="col-lg-6" style={{ zIndex: "1" }}>
                <div className='mb-4'>
                  <p className='desktop-section-1-title-cv2'>{courseDetail?.courseinfo[0]?.name}</p>
                  <p className='white-desc-cv2'>
                    For parents looking to accelerate their kids creative capabilities with activity based learning.
                  </p>
                  {checkIsUserNotLoggedInOrParentIsLoggedIn() ?
                    <div className='d-flex align-items-center mt-2'>
                      {courseDetail?.courseinfo[0]?.isFree ?
                        <div className='register-free-course-btn-cv2 me-3' onClick={(e) => RegisterFreeCourse()} >
                          <span>Enroll for a Free Demo</span>
                        </div> :
                        <div className='register-free-course-btn-cv2 me-3' onClick={(e) => naviageteToScheduleSection()} >
                          <span>Enroll</span>
                        </div>}
                      {/* <p className='register-free-date-cv2 mb-0'>
                        Starts <strong>{moment(courseStream[0]?.startdate).format("ll")}</strong>
                      </p> */}
                      <p className='register-free-date-cv2 mb-0'>
                        {getStartDate(courseStream) ? 'Starts ' : ''} <strong>{getStartDate(courseStream)}</strong>
                      </p>
                    </div> : ""}
                  <div className='d-flex align-items-center mt-3'>
                    <img src={assets.course_v2.fire_icon} className=''></img>
                    <p className='fire-text-cv2 mb-0'>
                      100 Parents interested
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" style={{ zIndex: "1" }}>
                <div className='mb-4 pb-4'>
                  <img src={assets.course_v2.section_1_group_img} className=''></img>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <img src={assets.course_v2.section_1_white_star} className='section_1_white_star-cv2'></img>
              </div>
            </div>
          </div>
          <img src={assets.homev3.m_icon} className='m-icon-cv2'></img>
        </div>
      </Box>
      {/* SECTION_2 */}

      <Box className=''>
        <div className="section-2-container-cv2">
          <div className="section-2-cv2">
            <div className="container">
              <div className="row ms-0 me-0">
                <div className="col-lg-8">
                  <div className="row ms-0 me-0">
                    {/* <Card className="Beginner-level-card-cv2">
                      <Box>
                        <div className="row">
                          <div className="col-4">
                            <p className="m-0 Beginner-level-card-title-cv2">
                              Introductory Phase
                            </p>
                            <p className="m-0 Beginner-level-card-text-cv2">
                              3 hours
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="m-0 Beginner-level-card-title-cv2">
                              Learning phase
                            </p>
                            <p className="m-0 Beginner-level-card-text-cv2">
                              3 hours
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="m-0 Beginner-level-card-title-cv2">
                              Execution Phase
                            </p>
                            <p className="m-0 Beginner-level-card-text-cv2">
                              3 hours
                            </p>
                          </div>
                        </div>
                      </Box>
                    </Card> */}
                  </div>
                  {/* What kids will learn */}
                  <div className="">
                    <Card className="What-kids-learn-card-cv2 mt-4">
                      <Box>
                        <p className="section-2-kids-learn-titles-cv2 ms-3">
                          What kids will learn
                        </p>
                        <div className="row ms-0 me-0">
                          <div className="col-lg-6">
                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Sequential Coding</p>

                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Critical Thinking</p>
                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Mathematics skills</p>
                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Design thinking and arriving at solutions</p>
                          </div>
                          <div className="col-lg-6">

                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Strategies and Execution</p>
                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Problem solving</p>
                            <p className="d-flex align-items-center What_kids_text_cv2">
                              <img src={assets.course_v2.section_1_blue_star} className='section_1_blue_star-icon'></img>
                              Collaborative learning</p>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </div>
                  {/* About Course */}
                  <div className="">
                    <div className="mt-4">
                      <p className="section-2-kids-learn-titles-cv2 mb-1">
                        About Course
                      </p>
                      <p className="section-2-Course-doc-cv2">
                        {courseDetail?.coursedetail[0]?.description}
                      </p>
                    </div>
                    {/* Who is the course for? */}
                    <div className="mt-4">
                      <p className="section-2-kids-learn-titles-cv2 mb-1">
                        Who is the course for?
                      </p>
                      <p className="section-2-Course-doc-cv2">
                        This course is for anyone looking to boost their confidence when it comes to creative problem-solving, you don’t need any previous experience of the topic.
                      </p>
                    </div>
                    {/* Topics Covered */}
                    {/* <div className="mt-4">
                      <p className="section-2-kids-learn-titles-cv2 mb-1">
                        Topics Covered
                      </p>

                      <Card className="Topics-covered-card-cv2 mt-2">
                        <Box>
                          <div className="row ms-0 me-0">
                            <div className="d-flex">
                              <label className="Topics-covered-title-cv2 me-3">WEEK1</label>
                              <label className="Topics-covered-doc-cv2">Introduction to creative and design thinking.</label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK2</label>
                              <label className="Topics-covered-doc-cv2">
                                Basic logic and analytical skills. Developing a structured approach to tackle problems.</label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK3</label>
                              <div className="Topics-covered-doc-cv2">
                                Meeku will explore the creative techniques of Mind Mapping and Synectics:
                                <ul>
                                  <li>
                                    They'll engage in Mind Mapping to visually organize ideas and information, fostering creative problem-solving.
                                  </li>
                                  <li>
                                    They'll also delve into Synectics to develop innovative solutions by connecting seemingly unrelated elements and fostering creativity through analogies and metaphors.
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK4</label>
                              <label className="Topics-covered-doc-cv2">
                                Students will embark on a creative journey of discovery, facilitating their teams to gain a comprehensive understanding of various topics, including touch sensors, within a virtual setup of imaginary worlds, fostering imaginative exploration and learning.
                              </label>
                            </div>
                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK5</label>
                              <label className="Topics-covered-doc-cv2">
                                Students will integrate design thinking and creativity to explore Students will integrate programming in the context of real-world scenarios, further understanding the practical application of sensors to solve problems and create innovative solutions within these scenarios.
                              </label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK6</label>
                              <label className="Topics-covered-doc-cv2">
                                Meeku will bridge the gap between coding and real-life scenarios, applying coding logic to practical situations and gaining insights into how programming can be used to address real-world challenges effectively.
                              </label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK7</label>
                              <label className="Topics-covered-doc-cv2">
                                Collaborative strategy development for design playground with cohorts followed by executing drill missions as a team.
                              </label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK8</label>
                              <label className="Topics-covered-doc-cv2">
                                In Week 8, Meeku will engage in collaborative brainstorming sessions with their cohorts, devising strategies to identify the shortest and quickest routes to achieve their goals. They will then put these strategies into action by executing a mission as a team, honing their problem-solving and teamwork skills.
                              </label>
                            </div>

                            <div className="d-flex mt-2">
                              <label className="Topics-covered-title-cv2 me-3">WEEK9</label>
                              <label className="Topics-covered-doc-cv2">
                                Students will learn to completely automate the workflow using block coding, enabling them to create efficient and automated processes with visual programming blocks.
                              </label>
                            </div>
                          </div>
                        </Box>
                      </Card>
                    </div> */}
                    {/* Schedule */}
                    {checkIsUserNotLoggedInOrParentIsLoggedIn() && courseStream.length > 0 ? <div ref={scheduleSectionRef} ></div> : ""}
                    {checkIsUserNotLoggedInOrParentIsLoggedIn() && courseStream.length > 0 ? <div className="mt-4">
                      <p className="section-2-kids-learn-titles-cv2 mb-1">
                        Schedule
                      </p>
                    </div> : ""}

                    {checkIsUserNotLoggedInOrParentIsLoggedIn() && courseStream.length > 0 ?
                      <div className="course-d-streams-main mb-3">
                        {/* SESSIONS TIMES */}
                        <div className="course-d-sessions-time-main">
                          <div className="course-d-stream-date">&nbsp;</div>
                          <div className="course-d-stream-day">&nbsp;</div>
                          <div className="course-d-stream-sessions-main">
                            {courseStreamDetail?.map((cSObj, cSIndex) => {
                              return (
                                <div key={`c-s-d-t-` + cSIndex} className="course-d-stream-sessions-time">
                                  <span>{convertTime(cSObj.from)}&nbsp;{getTimeZoneShortForm(generateCommonParams(courseDetail.id))}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        {/* STREAMS */}
                        {courseStream?.map((sObj, sIndex) => {
                          return (
                            <div key={`c-s-` + sIndex} className="course-d-streams">
                              <div className="course-d-stream-date">{moment(sObj?.from?.startdate).format("MMM Do")}</div>
                              <div className="course-d-stream-day">{moment(sObj?.from?.startdate).format("dddd").slice(0, 3)}</div>
                              <div className="course-d-stream-sessions-main">
                                {courseStreamDetail?.map((cSDObj, cSDIndex) => {
                                  return (
                                    <div key={`c-s-d-` + cSDIndex}>
                                      {cSDObj.from !== sObj?.from?.from ?
                                        <div className="course-d-stream-sessions-box-inactive">
                                        </div>
                                        :
                                        // IF MAXIMUM NUMBER OF LEARNERS OF COURSES EXCEEDS BY LICENSE
                                        getLicenseCount(sObj) >= courseDetail?.courseinfo[0].maxnolearners ?
                                          <div className="course-d-stream-sessions-box-booked">
                                            Booked
                                          </div>
                                          :
                                          <div onClick={(e) => selectCourse(e, sObj)}>
                                            <span>
                                              <TooltipWithHtml
                                                screen={'course-detail-schedule'}
                                                TooltipDescription={getCourseTooltipDescription(sObj, 'desc')}
                                                TooltipTitle={getCourseTooltipDescription(sObj, 'title')}
                                                courseDetailContent={{ label: 'Select', license: getLicenseCount(sObj), maxnolearners: courseDetail?.courseinfo[0].maxnolearners }} ToolTipName={'Select'} ></TooltipWithHtml>
                                            </span>
                                          </div>}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div> : ""}
                  </div>
                </div>

                <div className="col-lg-4 right-side-detail-padding">
                  <div className="row ms-0 me-0">
                    {/* Course at a Glance */}
                    <Card className="Course-Glance-card-cv2">
                      <Box>
                        <p className="Course-Glance-titles-cv2">
                          Course at a Glance
                        </p>
                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.level_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Phase</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto">Introductory</p>
                          </div>
                        </div>

                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.Weekly_Hours_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Weekly Hours</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto">1 hour/week
                            </p>
                          </div>
                        </div>

                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.Commitment_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Commitment</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto">1 session
                            </p>
                          </div>
                        </div>

                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.age_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Age</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto">8 - 14 years
                            </p>
                          </div>
                        </div>

                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.Language_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Language</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto">English
                            </p>
                          </div>
                        </div>

                        <div className="row ms-0 me-0 mt-3">
                          <div className="col-6 p-0">
                            <div className="d-flex align-items-center">
                              <img src={assets.course_v2.location_icon} className='me-2' style={{ width: "25px" }}></img>
                              <p className="Topics-covered-doc-cv2 mb-0">Location</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="Topics-covered-title-cv2 mb-0 ms-auto"> Online Classes
                            </p>
                          </div>
                        </div>

                        {checkIsUserNotLoggedInOrParentIsLoggedIn() ?
                          <div className='d-flex justify-content-center mt-4'>
                            {courseDetail?.courseinfo[0]?.isFree ?
                              <div className='register-free-course-btn-2-cv2' onClick={(e) => RegisterFreeCourse()}>
                                <span>Enroll for a Free Demo</span>
                              </div> :
                              <div className='register-free-course-btn-2-cv2' onClick={(e) => naviageteToScheduleSection()}>
                                <span>Enroll</span>
                              </div>
                            }
                          </div> : ""}
                        {/* <p className="Share-text-cv2 pt-3 mb-0" onClick={(e) => handleCopyToClipBoard(e)}>Share</p> */}

                      </Box>
                    </Card>

                    {/* <Card className="Instructor-card-cv2 mt-4">
                      <Box>
                        <div className="row">
                          <p className="Course-Glance-titles-cv2">
                            Instructor
                          </p>

                          <div className="d-flex align-items-center">
                            <img src={assets.course_v2.Instructor_gi_icon} className='me-2'></img>
                            <div>
                              <p className="Instructor-card-title-cv2 mb-0">Patricia Williams</p>
                              <p className="Instructor-card-doc-cv2 mb-0">Design Expert 14 yrs Experience</p>
                            </div>

                          </div>
                        </div>
                      </Box>
                    </Card> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box >

      {/* Integrated Tools - DESKTOP  VIEW*/}
      <div>
        <IntegratedTools></IntegratedTools>
      </div>

      {/* PARENT FORM - DESKTOP VIEW VIEW*/}
      {!getCurrentUserData() ? <div ref={registerFreeCourse} ></div> : ""}
      {!getCurrentUserData() ?
        < Box className="" >
          <div className="section-4-container-cv2">
            <p className="sections-title-com-color-w-cv2 pt-5" style={{ lineHeight: "120%" }}><strong>Get your child ready</strong> to tackle the future</p>
            <div className="d-flex justify-content-center">
              <img className="" src={assets.homev3.yellowLine} ></img>
            </div>
            <div className="container">
              <div className="section-4-container-form-cv2">
                <div className="row ms-0 me-0">
                  <div className="col-lg-6 pt-3">
                    <img className="pt-5" src={assets.course_v2.form_course_img_1} ></img>
                  </div>
                  <div className="col-lg-6 pt-3">
                    <p className="form-top-text-cv2">For a limited time we are offering a FREE session to experience. Register Now!</p>
                    <form onSubmit={onSubmit}>
                      <div className="form-group p-0">
                        <select
                          className="form-control child-ready-form child-ready-form-selected-text"
                          placeholder="Date"
                          id="course-stream-select"
                          name="course-stream-select-name"
                          value={selectedCourseFrom}
                          onChange={(e) => selectCourseBySelectEvent(e)}>
                          {courseStream?.map((obj, idx) => {
                            return (
                              <option key={`course-stream-select-key-` + idx} value={obj.id} >{getCourseStreamValue(obj)}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="form-group p-0 mt-3">
                        <input
                          type="text"
                          className="child-ready-form"
                          placeholder="Name"
                          required
                          id="name"
                          name="name"
                          value={parentName}
                          onChange={handleParentNameChange}
                          error={errors.name ? true : false}
                          autoComplete="off"
                        />
                        <Typography variant="inherit" style={{ color: "#FFFFFF" }}>
                          {errors.name?.message}
                        </Typography>
                      </div>

                      <div className="form-group p-0 mt-3">
                        <input
                          type="email"
                          className="child-ready-form"
                          placeholder="Email Id"
                          required
                          id="email"
                          name="email"
                          value={parentEmail}
                          onChange={handleParentEmailChange}
                          error={errors.email ? true : false}
                          autoComplete="off"
                        />
                        <Typography variant="inherit" style={{ color: "#FFFFFF" }}>
                          {errors.email?.message}
                        </Typography>
                      </div>

                      <div className="d-flex mt-4">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="Continue-btn-cv2"
                        >
                          <span>Continue</span>
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="d-flex justify-content-center mt-4 pt-4 pb-4">
                    <img className="form_course_img_2_cv2" src={assets.course_v2.form_course_img_2} ></img>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Box > : ""}

      {/* FAQ - DESKTOP  VIEW*/}
      <Faq></Faq>

      {/* TESTIMONIAL - DESKTOP  VIEW*/}
      <Testimonial></Testimonial>

      {/* SECTION - 7 - DESKTOP FOOTER*/}
      <Footer></Footer>
    </div >
  );
}
//end today