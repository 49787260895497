import url_configs from "./url";

const conditional_configs = {
    curriculam_types: {
        content: 'content',
        quiz: 'quiz',
        games: 'game',
        whiteboard: 'whiteboard'
    },
    role: {
        admin: 0,
        parent: 1,
        learner: 2,
        expert: 3,
        curriculam_creator: 4,
    },
    gameTypes: {
        mutiPlayerGame: 'multi-player-game',
        singlPlayerWebsite: 'single-player-website'
    },
    question_types: {
        shortAnswer: "singleanswer",
        multiChoice: "mcq",
    },
    contentTypes: {
        pasteUrl: 'paste_url',
        file: 'file'
    },
    loader: {
        timeout: 3000
    },
    notificationTypes: {
        waitingParticipants: 1
    },
    selfLearning: {
        parentRegistrationURL: "/self-learning-parent-registration"
    },
    tempCoursesUrl: {
        freeCoursesUrl: url_configs.freeCourse,
        paidCoursesUrl: url_configs.paidCourse
    },
    scheduleList: [
        {
            id: 0,
            value: 'All'
        },
        {
            id: 1,
            value: 'Today'
        },
        {
            id: 2,
            value: 'Tomorrow'
        },
        {
            id: 3,
            value: 'This Week'
        },
        {
            id: 4,
            value: 'This Month'
        }
    ],
    headerMenuList: [
        {
            id: 1,
            value: 'Courses',
            active: false,
            routeTo: url_configs.paidCourse
        },
        {
            id: 2,
            value: 'How it works',
            active: false,
            routeTo: '/'
        },
        {
            id: 3,
            value: 'Enroll for a Free Demo',
            active: true,
            routeTo: url_configs.freeCourse
        }
    ],
    parentCompletionList_1: [
        {
            id: 1,
            title: 'Enroll for a Free Demo',
            description: '2 FREE courses are available',
            active: true,
            routeTo: url_configs.freeCourse
        },
        {
            id: 2,
            title: 'Add Kid',
            description: '',
            active: false,
            routeTo: 'add-kid'
        },
        {
            id: 3,
            title: 'Assign Kid to Course',
            description: '',
            active: false,
            routeTo: 'assign-kid'
        },
        {
            id: 4,
            title: 'Buy a course License',
            description: '5+ courses designed for kids',
            active: false,
            routeTo: url_configs.paidCourse
        },

    ],
    parentCompletionList_2: [
        {
            id: 1,
            title: 'Buy a course License',
            description: '5+ courses designed for kids',
            active: true,
            routeTo: url_configs.paidCourse
        },
        {
            id: 2,
            title: 'Add Kid',
            description: '',
            active: false,
            routeTo: 'add-kid'
        },
        {
            id: 3,
            title: 'Assign Kid to Course',
            description: '',
            active: false,
            routeTo: 'assign-kid'
        }
    ],
    idleTime: 900000, // IN MIL SECONDS
    networkReloadTime: 60, //IN SECONDS
    curriculam_creators: ['curriculam_admin@meekuworld.com', 'curriculam_admin_1@meekuworld.com'],
    currency: {
        usd: '$',
        inr: '₹'
    },
    appTypeList: [
        {
            id: 'course',
            value: "Courses",
            active: true
        },
        {
            id: 'chat',
            value: "Chat",
            active: false
        },
        {
            id: 'notes',
            value: "Notes",
            active: false
        }
    ],
    supportEmail: 'admin@meeku.world',

};

export default conditional_configs;