import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";

import { getCurrentUserData } from "../utils/util";

const shareLicensesRegister = async (data) => {
  try {
    const response = await http.post(api_path.shareLicensesRegister, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const verifyShareLicensesToken = async (token) => {
  try {
    const response = await http.get(api_path.verifyShareLicensesToken, {
      params: { token: token }, // Pass token as a query parameter
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const shareLicensesCustomerRegisteration = (data) => {
  return http.post(api_path.shareLicensesCustomerRegisteration, data);
};

const getShareLicensesDetailsById = (id) => {
  console.log("id", id);
  return http
    .get(api_path.getShareLicensesDetailsById, {
      params: { id },
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle errors here, including CORS-related errors
      console.error("Error fetching share licenses details:", error);
      throw error; // Re-throw the error to propagate it to the caller
    });
};

const getAllShareLicensesDetails = () => {
  return http
    .get(api_path.getAllShareLicensesDetails, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle errors here, including CORS-related errors
      console.error("Error fetching share licenses details:", error);
      throw error; // Re-throw the error to propagate it to the caller
    });
};

const ShareLicensesService = {
  shareLicensesRegister,
  verifyShareLicensesToken,
  shareLicensesCustomerRegisteration,
  getShareLicensesDetailsById,
  getAllShareLicensesDetails,
};
export default ShareLicensesService;
