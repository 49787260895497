import React, {useEffect} from "react";
import { Badge, Container, Grid, Paper, InputBase } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import conditional_configs from "../../../config/conditional_config";
import { getCurrentUserData } from "../../../utils/util";

import "./pods.css";

//table
function Pods_detail_list(podsId, course, startDate, endDate, status) {
  return { podsId, course, startDate, endDate, status };
}
const pods_detail_row = [
  Pods_detail_list(
    "10001",
    "Chinemography",
    "June 12,2022",
    "June 30,2022",
    "completed"
  ),
  Pods_detail_list(
    "10002",
    "Chinemography",
    "June 12,2022",
    "June 30,2022",
    "completed"
  ),
  Pods_detail_list(
    "10003",
    "Chinemography",
    "June 12,2022",
    "June 30,2022",
    "completed"
  ),
];
//table end
export default function Payments() {
  const navigate = useNavigate();
  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
     return;
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };
  return (
    <div>
      <Container>
        <div class="d-flex align-items-center  mb-3">
          <span className="admin_pods_text me-auto">
            Pods
            <Badge
              className="admin_pods_badge"
              badgeContent={3}
              sx={badgeStyle}
            />
          </span>
        </div>

        <Grid container spacing={1} className="mb-3">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={1} alignItems="center">
              {/* Search by
               Course Name */}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Paper
                  component="form"
                  className="pods_search_course_name"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    placeholder="Search by Course Name"
                    inputProps={{ "aria-label": "search by course name" }}
                  />
                </Paper>
              </Grid>
              {/* Search by Course Name END */}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
        </Grid>
        {/* table */}
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="pods_table_header">
              <TableRow>
                <TableCell className="pods_table_header_text col_1">
                  Pods Id
                </TableCell>
                <TableCell
                  className="pods_table_header_text col_2"
                  align="left"
                >
                  Course
                </TableCell>
                <TableCell
                  className="pods_table_header_text col_2"
                  align="left"
                >
                  Start Date
                </TableCell>
                <TableCell
                  className="pods_table_header_text col_2"
                  align="left"
                >
                  End Date
                </TableCell>
                <TableCell
                  className="pods_table_header_text col_3"
                  align="left"
                >
                  Status
                </TableCell>

                <TableCell
                  className="pods_table_header_text col_4"
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pods_detail_row.map((row) => (
                <TableRow
                  key={row.podsId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{row.podsId}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.course}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.startDate}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.endDate}
                  </TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">
                    <MoreVertIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
