import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './drop-file-input.css';

import { FileUploadImageConfig } from '../../config/file_upload_img_config';
import uploadImg from '../../assets/img/file_upload/cloud-upload-regular-240.png';
import { useSnackbar } from 'notistack';

const DropFileInput = props => {

    const { enqueueSnackbar } = useSnackbar();

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);

    const [error, setError] = useState("");

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        setError('');
        const newFile = e.target.files[0];
    
        // Check file size limit (5 MB)
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        if (newFile && newFile.size > maxSize) {
            enqueueSnackbar('File size exceeds the limit (5 MB)', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return setError('File size exceeds the limit (5 MB)');
        }
    
        if (props.fileTypes) {
            let acceptedFileTypes = props.fileTypes.split(',');
            if (acceptedFileTypes) {
                const isAcceptedFileTypes = acceptedFileTypes.filter(e => {
                    return e === newFile.type
                })
                if (isAcceptedFileTypes && isAcceptedFileTypes.length === 0) {
                    return setError('Upload valid file');
                }
            }
        }
    
        // Check if isMultipleFile prop is true
        if (props.isMultipleFile) {
            // Extract the list of new files from the event
            const newFiles = e.target.files;
    
            // If file types are specified, check if the selected files are of the allowed types
            if (props.fileTypes) {
                let acceptedFileTypes = props.fileTypes.split(',');
    
                const invalidFiles = Array.from(newFiles).filter((file) => {
                    return !acceptedFileTypes.includes(file.type);
                });
    
                if (invalidFiles.length > 0) {
                    setError('Upload valid file types');
                    return;
                }
            }
    
            // Update the file list with the new files
            const updatedList = [...fileList, ...newFiles];
            setFileList(updatedList);
    
            // Invoke the onFileChange callback with the updated file list
            props.onFileChange(updatedList);
        } else {
            if (newFile) {
                const updatedList = [...fileList, newFile];
                setFileList(updatedList);
                props.onFileChange(updatedList);
            }
        }
    };
    
    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    <p>Drag & Drop your files here</p>
                </div>

                {props.isMultipleFile ? (
                    <input
                        type="file"
                        value=""
                        accept={props.fileTypes}
                        onChange={onFileDrop}
                        multiple
                    />
                ) : (
                    <input
                        type="file"
                        value=""
                        accept={props.fileTypes}
                        onChange={onFileDrop}
                    />
                )}
            </div>
            <p>{error}</p>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        {/* <p className="drop-file-preview__title">
                            Ready to upload
                        </p> */}
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <img src={FileUploadImageConfig[item.type.split('/')[1]] || FileUploadImageConfig['default']} alt="" />
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        <p>{item.size}B</p>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
    isMultipleFile: PropTypes.bool
}

export default DropFileInput;
