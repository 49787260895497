import ZoomVideo from '@zoom/videosdk';
import { KJUR } from 'jsrsasign';
import storage_configs from '../config/storage-config';
import Swal from "sweetalert2";
import error_messages from '../config/error_messages';

export function handleError(error) {
    if (error) {
        if (typeof error.type && error.type == "VIDEO_USER_FORBIDDEN_CAPTURE") {
            ErrorPopup("Enable Camera Access", error_messages.zoomErrors.VIDEO_USER_FORBIDDEN_CAPTURE);
        }
    }
}

export function getActiveCamera(stream){
    if(stream){
        if(stream.getCameraList().length > 0){
            const currentActiveCamera = stream.getCameraList().filter(e=>e.deviceId == stream.getActiveCamera())[0];
            if(currentActiveCamera){
                return currentActiveCamera.label
            }else {
                return "No camera is currently detected"
            }
        }
    }
}

export function getActiveMicrophone(stream){
    if(stream){
        if(stream.getMicList().length > 0){
            const currentActiveMic = stream.getMicList().filter(e=>e.deviceId == stream.getActiveMicrophone())[0];
            if(currentActiveMic){
                return currentActiveMic.label
            }else {
                return "No microphone is currently detected"
            }
        }
    }
}

export function getActiveSpeaker(stream){
    if(stream){
        if(stream.getSpeakerList().length > 0){
            const currentActiveSpeaker = stream.getSpeakerList().filter(e=>e.deviceId == stream.getActiveMicrophone())[0];
            if(currentActiveSpeaker){
                return currentActiveSpeaker.label
            }else {
                return "No speaker is currently detected"
            }
        }
    }
}

export function ErrorPopup(title, text) {
    Swal.fire({
        title: title,
        text: text,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay"
    }).then((result) => {
        if (result.isConfirmed) {
        } else {
        }
    });
}