import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { FaPlus } from "react-icons/fa";
import whiteboardImg from '../../assets/images/whiteboard.png'

import './Whiteboard.css'

export default class WhiteBoard extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      selectedGroup: 'Team Wolf',
      groups: [
        {
          title: 'Team Wolf'
        },
        {
          title: 'Sea Hawks'
        },
        {
          title: 'Sea Horse'
        },
        {
          title: 'Moonrafters'
        },
      ]
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  navigate(group) {
    this.setState({
      selectedGroup: group.title
    });
  }

  render() {
    return (
      <div>
        <Row className='m-0'>
          {this.state.groups.map(group => (
            <Col sm={6}>
              <div className='whiteboard-grid m-0'>
                <Card body onClick={() => this.navigate(group)} className={this.state.selectedGroup === group.title ? "group-item-active" : "group-item"}>
                  <div className='group-title'>{group.title}</div>
                  {/* <img src={whiteboardImg} className="host-img" /> */}
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}