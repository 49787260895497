import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api from '../config/api';

const GetAllPublishedCourses = data => {
    return http.get(api.getAllPublishedCourses, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const getCourses = data => {
    return http.get(api.a_getCourses, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const GetCourseContentByCourseId = data => {
    return http.get(api.a_GetCourseContentByCourseId + '/'+ data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const GetCourseDetailsByCourseId = data => {
    return http.get(api.a_GetCourseDetailsByCourseId + '/'+ data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const GetCourseInfoByCourseId = data => {
    return http.get(api.a_GetCourseInfoByCourseId + '/'+ data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const deleteCourseInfoCoverPicture = async data => {
    const response = await http.post(api.a_deleteCourseInfoCoverPicture, data, { headers: authHeader() });
    return response.data;
};


const deleteCourseStream = async data => {
    const response = await http.post(api.a_deleteCourseStream, data, { headers: authHeader() });
    return response.data;
};

const GetCoursesDetailsById = data => {
    return http.get(api.a_GetCoursesDetailsById + data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const deleteCourseDescriptionDocument = async data => {
    const response = await http.post(api.a_deleteCourseDescriptionDocument, data, { headers: authHeader() });
    return response.data;
};

const createcourseinfo = data => {
    return http.post(api.a_createCourseInfo, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const updateCourseInfo = async (courseid, data) => {
    const response = await http.put(api.a_updateCourseInfo + '/'+ courseid, data, { headers: authHeader() });
    return response.data;
};

const createcoursedetail = data => {
    return http.post(api.a_createcoursedetail, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const updatecoursedetail = async (data) => {
    const response = await http.post(api.a_updatecoursedetail, data, { headers: authHeader() });
    return response.data;
};

const createpreassessment = data => {
    return http.post(api.a_createpreassessment, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const createContent = data => {
    return http.post(api.a_createcontent, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const updateContent = (id, data) => {
    return http.put(api.a_updatecontent+id, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const createStream = data => {
    return http.post(api.a_createstream, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const updateStream = (id, data) => {
    return http.put(api.a_updatestream+id, data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const assignCourseToParent = (data) => {
    return http.post('/memberpayment/create', data, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const GetAllLearnersByStreamId = (id) => {
    return http.get('/membercourseassignment/GetAllLearnersByStreamId/'+id, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

const CourseService = {
    GetAllPublishedCourses,
    getCourses,
    GetCourseContentByCourseId,
    GetCourseInfoByCourseId,
    GetCoursesDetailsById,
    GetCourseDetailsByCourseId,
    createcourseinfo,
    updateCourseInfo,
    createcoursedetail,
    updatecoursedetail,
    createpreassessment,
    createContent,
    updateContent,
    createStream,
    updateStream,
    assignCourseToParent,
    GetAllLearnersByStreamId,
    deleteCourseInfoCoverPicture,
    deleteCourseDescriptionDocument,
    deleteCourseStream
};
export default CourseService;