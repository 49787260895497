import React from "react";
import assets from "../../config/assets";
import { useState, useEffect } from "react";
import { Button, Typography, Container, Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import conditional_configs from "../../config/conditional_config";
import "./Integrated-tools.css";

export default function IntegratedTools() {
  const [currentItem, setCurrentItem] = useState(0);

  const navigate = useNavigate();

  const interactive_title_items = [
    <p className="interactive-sec-text-title ps-4">
      Discuss and share content
    </p>,
    <p className="interactive-sec-text-title ps-4">
      Challenges designed to solve real world problems
    </p>,
    <p className="interactive-sec-text-title ps-4">
      Safe virtual platform for kids
    </p>,
  ];
  const interactive_des_title_items = [
    <p className="ps-4">
      As a platform, Meeku seeks to foster communication and inculcate the
      practice of healthy discussion.
    </p>,
    <p className="ps-4">
      Our enrichment program is developed for kids aged 8 to 14. Meeku aims to
      provide children with an immersive environment for better decision making.
    </p>,
    <p className="ps-4">
      Our platform ensures a safe learning environment for your child through
      strict privacy measures, and age-appropriate content curation. Parents can
      trust that their child's online learning experience is secure.
    </p>,
  ];
  const interactiveImages = [
    assets.homev3.interactive_section_img_1,
    assets.homev3.interactive_section_img_2,
    assets.homev3.interactive_section_img_3,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem(
        (prevItem) => (prevItem + 1) % interactive_title_items.length
      );
    }, 8000); // Change image every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const handleItemClick = (index) => {
    setCurrentItem(index);
  };

  return (
    <div>
      {/*DESKTOP VIEW VIEW*/}
      <Box className="large-screen-integrated-section">
        <div className="integrated-container">
          <div className="integrated-section">
            <p
              className="interactive-title-text m-0"
              style={{ lineHeight: "120%" }}
            >
              {" "}
              Integrated tools for <strong>interactive</strong> learning
            </p>
            <div className="d-flex justify-content-center">
              <img
                className="yellowLine-img-4"
                src={assets.homev3.yellowLine}
              ></img>
            </div>
            {/* interactive_section_img  */}
            <div className="container">
              <div className="row ms-0 me-0 mt-5">
                <div className="col-lg-6">
                  <div>
                    {interactive_title_items.map((item, index) => (
                      <div
                        key={index}
                        className={`border-left-integrated-section ${
                          index === currentItem ? "active" : ""
                        }`}
                        onClick={() => handleItemClick(index)}
                      >
                        <p
                          className={`interactive-sec-text-title ${
                            index === currentItem
                              ? "interactive-sec-text-title-active"
                              : ""
                          }`}
                        >
                          {item}
                        </p>
                        <div
                          className={`interactive-sec-text ${
                            index === currentItem ? "active" : ""
                          }`}
                        >
                          <p>{interactive_des_title_items[currentItem]}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="d-flex mt-4 ps-4"
                    style={{ paddingBottom: "50px" }}
                  >
                    <div
                      className="register-free-course-btn-inte"
                      onClick={() =>
                        navigate(
                          conditional_configs.tempCoursesUrl.freeCoursesUrl
                        )
                      }
                    >
                      <span>Start for Free</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="">
                    <img
                      src={interactiveImages[currentItem]}
                      alt={`Image ${currentItem}`}
                      style={{ paddingBottom: "50px", height: "440px" }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end interactive_m_icon-div">
                  <img
                    className="interactive_m_icon"
                    src={assets.homev3.interactive_section_m_icon}
                  ></img>
                </div>
              </div>
            </div>
            <div>
              <img
                className="pb-5"
                src={assets.homev3.interactive_section_mm_img}
              ></img>
            </div>
          </div>
        </div>
      </Box>

      {/*MOBILE VIEW VIEW*/}
      <Box className="mobile-screen-integrated-section">
        <div className="integrated-container">
          <div className="integrated-section">
            <p
              className="interactive-title-text m-0"
              style={{ lineHeight: "120%" }}
            >
              {" "}
              Integrated tools for <strong>interactive</strong> learning
            </p>
            <div className="d-flex justify-content-center">
              <img
                className="yellowLine-img-4-mobile"
                src={assets.homev3.yellowLine}
              ></img>
            </div>
            <div className="bd-example">
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <ol className="carousel-indicators carousel-indicators-mobile-sec-4-inte">
                  <li
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                  ></li>
                  <li
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <Card className="interactive-card-mobile mt-3">
                      <Box>
                        <div className="interactive-card-image-sec-mobile">
                          <img
                            className=""
                            src={assets.homev3.interactive_section_img_1}
                          ></img>
                          <p className="interactive-sec-text-title-mobile mt-4">
                            Discuss and share content
                          </p>
                          <p className="interactive-sec-text-mobile">
                            As a platform, Meeku seeks to foster communication
                            and inculcate the practice of healthy discussion.
                          </p>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                          <div
                            className="register-free-course-btn-inte"
                            onClick={() =>
                              navigate(
                                conditional_configs.tempCoursesUrl
                                  .freeCoursesUrl
                              )
                            }
                          >
                            <span>Start for Free</span>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </div>
                  <div className="carousel-item">
                    <Card className="interactive-card-mobile mt-3">
                      <Box>
                        <div className="interactive-card-image-sec-mobile">
                          <img
                            className=""
                            src={assets.homev3.interactive_section_img_2}
                          ></img>
                          <p className="interactive-sec-text-title-mobile mt-4">
                            Challenges designed to solve real world problems
                          </p>
                          <p className="interactive-sec-text-mobile">
                            Our enrichment program is developed for kids aged 8
                            to 14. Meeku aims to provide children with an
                            immersive environment for better decision making.
                          </p>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          <div
                            className="register-free-course-btn-inte"
                            onClick={() =>
                              navigate(
                                conditional_configs.tempCoursesUrl
                                  .freeCoursesUrl
                              )
                            }
                          >
                            <span>Start for Free</span>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </div>
                  <div className="carousel-item">
                    <Card className="interactive-card-mobile mt-3">
                      <Box>
                        <div className="interactive-card-image-sec-mobile">
                          <img
                            className=""
                            src={assets.homev3.interactive_section_img_3}
                          ></img>
                          <p className="interactive-sec-text-title-mobile mt-4">
                            Safe virtual platform for kids
                          </p>
                          <p className="interactive-sec-text-mobile">
                            Our platform ensures a safe learning environment for
                            your child through strict privacy measures, and
                            age-appropriate content curation. Parents can trust
                            that their child's online learning experience is
                            secure.
                          </p>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          <div
                            className="register-free-course-btn-inte"
                            onClick={() =>
                              navigate(
                                conditional_configs.tempCoursesUrl
                                  .freeCoursesUrl
                              )
                            }
                          >
                            <span>Start for Free</span>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleCaptions"
                  role="button"
                  data-bs-slide="prev"
                >
                  <svg
                    class="custom-svg"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="NavigateBeforeIcon"
                  >
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  </svg>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleCaptions"
                  role="button"
                  data-bs-slide="next"
                >
                  <svg
                    class="custom-svg"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="NavigateNextIcon"
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
