import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Container, Grid, InputBase, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./my-courses.css";
// import '../../../theme/styles-variable.css';

import {
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from 'moment-timezone';

import io from "socket.io-client";

import LearnersPods from "../pods/pods";
//ICONS

import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
//ICONS END

import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";
import Sampleimg from "../../../assets/img/sample_img.png";
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from "@mui/material";
import Drawer from "@mui/material/Drawer";

//Configs
import navigateRouteConfig from "../../../config/navigate_route_config";
import gameConfig from "../../../config/game_config";
import storage_configs from "../../../config/storage-config";
import url_configs from "../../../config/url";

//SERVICE
import MemberService from "../../../services/member-service";
import UtilService from "../../../services/util.service";
import LearnerAPIService from "../../../services/learner-service";
import SessionService from "../../../services/session-service";

//FIREBASE
import FirebaseService from "../../../services/firebase-service";
import { addDoc, collection } from "@firebase/firestore";

//UTILS
import { convertTime, generateCommonParams, getCurrentUserData, getTimeZoneShortForm } from '../../../utils/util';
import { Pagination } from "@mui/material";
import slugify from "react-slugify";
import tooltip_messages from "../../../config/tooltip";
import NoRecordFound from "../../../components/no-record-found/no-record-found";
import assets from "../../../config/assets";
import error_messages from "../../../config/error_messages";

let socket_conection;

let coursesList = [];

const drawerWidth = 340;

export default function LearnersCourses() {

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  let interval_id;

  const [openGroupSpacePopup, setopenGroupSpacePopup] = React.useState(false);

  const [currentUserData, setCurrentUserData] = React.useState(JSON.parse(localStorage.getItem('user')));

  const [gameLink, setGameLink] = React.useState();

  const [course, SetCourse] = useState([]);

  const [courseCount, setCourseCount] = useState();

  const navigate = useNavigate();
  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "#000",
      padding: "10px",
      backgroundColor: '#F9BA1A'
    },
  };

  //Drawer function
  const [drawopen, setDrawopen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawopen(true);
  };

  const handleDrawerClose = () => {
    setDrawopen(false);
  };

  //pagination
  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const courses = course.slice(startIndex, endIndex);

  //Get course details
  useEffect(() => {
    //  FirebaseService.cloneDocument("scenes", "new-template-1", "new-template-2").then((res) => {
    //  console.log('Init Firebase Success', res);

    // }).catch((error) => {
    //   console.log('Init Firebase Error:', error);
    // });
    if (getCurrentUserData())
      getCourses();
    socket_conection = io(url_configs.breakout_room_server);
    socket_conection.on("is-started-session", (obj) => {
      console.log('is-started-session', obj);
      if (obj && obj.success == true) {
        let currentCourse = coursesList;
        if (currentCourse && currentCourse.length > 0 && obj.data) {
          let checkIsCourseStarted = currentCourse.filter(e => e.coursestreamid == obj.data.course_stream_id);
          if (checkIsCourseStarted && checkIsCourseStarted.length > 0) {
            getCourses();
          }
        }
      } else if (obj && obj.success == false) {
        getCourses();
      } else {
        return;
      }

    });
    // interval_id = window.setInterval(() => {
    //   getCourses();
    // }, 5000);
    // return () => {
    //   window.clearInterval(interval_id);
    // };
  }, []);


  // const convertTime = (time) => {
  //   if (time) {

  //     let convertedTime = moment(time, 'HH:mm').tz(timeZone).format('hh:mm a');
  //     //let convertedTime =  moment(time, 'HH:mm').tz("America/Los_Angeles").format('hh:mm a');
  //     //console.log(convertedTime);
  //     return convertedTime;
  //   }
  // }

  const getCourses = () => {
    LearnerAPIService.GetMyCourse(getCurrentUserData().id).then(response => {
      SetCourse(response.data);
      coursesList = response.data;
      if (response.data) {
        console.log('getCourses-learners', response);
        setCourseCount(response.data.length);
        coursesList.forEach(element => {
          if (element.courseStreamDetail && element.courseStreamDetail.length > 0) {
            let tempSessionDetail = getCompletedSessions(element.courseStreamDetail);
            if (tempSessionDetail) {
              element.completedsessions = tempSessionDetail.completedsessions;
              element.nextsessions = tempSessionDetail.nextsessions;
            }
          }
        });
        SetCourse(coursesList);
        console.log('getCourses-learners ::', coursesList);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getCompletedSessions = (courseStreamDetail) => {
    let completedsessions = 0;
    let nextsessions = [];

    if (courseStreamDetail) {
      for (let i = 0; i < courseStreamDetail.length; i++) {
        if (courseStreamDetail[i].memberCourseStreamDetails && courseStreamDetail[i].memberCourseStreamDetails.length > 0) {
          if (courseStreamDetail[i].memberCourseStreamDetails[0].enddatetime) {
            const checkLearnerAttendedClass = courseStreamDetail[i].memberCourseStreamDetails[0].memberCourseStreamLearnerDetail.filter(e=>e.learnerid == getCurrentUserData().id);
            if(checkLearnerAttendedClass && checkLearnerAttendedClass.length > 0){
              completedsessions = completedsessions + 1;
            }
          }
        }

        if (courseStreamDetail[i].memberCourseStreamDetails && courseStreamDetail[i].memberCourseStreamDetails.length == 0) {
          nextsessions.push(courseStreamDetail[i]);
        }
      }
    }
    return { completedsessions: completedsessions, nextsessions: nextsessions[0] };
  }


  //Drawer function end

  const navigateToGroupSpace = (streamId, cData) => {
    MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
      (response) => {
        console.log(response);
        localStorage.setItem(storage_configs.localStorage.sessions.learner_grp_detail, JSON.stringify(response.data))
        localStorage.setItem(storage_configs.localStorage.sessions.course_detail, JSON.stringify(cData));
        // handleOpenGroupSpacePopup();
        // handleDrawerOpen();
        navigate(navigateRouteConfig.learner.pods);
        //navigate(navigateRouteConfig.learner.group_space + '/' + streamId);
      }
    ).catch((e) => {
      console.log(e);
    });

  }

  const navigateToCourseInfo = (e, datas) => {
    if (datas) {
      navigate('/course-detail/' + slugify(datas.coursename) + '-' + datas.courseid);
    }
  }

  /** JOIN SESSION */
  const navigateToSession = (data) => {
    if (data) {
      const date = new Date();
      let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');

      let joinSessionModel = {
        membercoursestreamdetailid: data.membercoursestreamdetailid.toString(),
        learnerid: getCurrentUserData().id.toString(),
        startdatetime: formatedTodayDate
      }

      console.log(joinSessionModel);
      SessionService.joinSessionByLearner(joinSessionModel).then(response => {
        console.log(response);
        if (response && response.success == "true") {
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, data.membercoursestreamdetailid);
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamlearnerdetailsid, response.data.id);
          localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, data.coursestreamid);
          navigate('/learner-session/' + data.slug);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** OPEN TEMPLATE QUIZ POPUP */
  const handleOpenGroupSpacePopup = () => {
    setopenGroupSpacePopup(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseGroupSpacePopup = () => setopenGroupSpacePopup(false);

  const handleGameSettings = (groups) => {
    let tempJsonObj = {
      userId: JSON.parse(localStorage.getItem('user')).id,
      userType: "student",
      name: JSON.parse(localStorage.getItem('user')).name,
      groupId: groups,
      setting: 1
    }
    let tempJsonValueList = [];
    tempJsonValueList.push(tempJsonObj);

    let model = {
      jsonvalue: tempJsonValueList
    }
    console.log(model);
    setopenGroupSpacePopup(true)
    UtilService.encryptJsonValue(model).then(response => {
      console.log(response)
      if (response && response.data) {
        let tempGameLink = gameConfig.url.gameUrl + response.data.token;
        setGameLink(tempGameLink);
        setopenGroupSpacePopup(true)
      }

    })
      .catch(e => {
        console.log(e);
      });
  }

  /** SEARCH FUNCTION */
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter the list of courses based on the search query
    const filteredCourses = coursesList.filter((cours) =>
      cours.coursename.toLowerCase().includes(query.toLowerCase())
    );

    SetCourse(filteredCourses);
  };


  return (
    <div className="l_main_my_course_padding">
      {/* TITLE HEADER */}
      <div elevation={3}>
        <Container className="l-my-course-main-container">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pb-3">
              <span className="courses_title_text">Courses</span>
              {courses?.length ? <Badge
                className="badge_course"
                badgeContent={courses?.length}
                sx={badgeStyle}
              /> : ""}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="pb-2">
              <Paper
                component="form"
                className="common-search-box"
                sx={{display: 'flex', alignItems: 'center' }}
              >
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className="search-input"
                  placeholder="Search by Course Name"
                  inputProps={{ 'aria-label': 'search by course name' }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* TITLE HEADER End */}
      {/* CARD SECTION */}
      <Container className="l-my-course-main-container">
        {courses.length > 0 ? 
        courses.map((datas, index) => {
          return (
            <Grid key={`l-my-course-` + index}>
              {/* CARD SECTION 1 */}
              {/* mobile-view */}
              <div className="l-my-course-card-main-mobile-view">
                <Card className="course_card_len mb-3">
                  <Grid container>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <img
                        src={datas.courseimage}
                        alt="user_photo"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box className="my_courses_text_section">
                        <p className="my_courses_title">{datas.coursename}</p>
                        <span className="my_schedule_text_section">
                          Sessions&nbsp;-&nbsp;{datas.completedsessions ? datas?.completedsessions : (0)}&nbsp;/&nbsp;{datas.totalsessionsL}&nbsp;Complete
                        </span>
                        <p className="m-0">
                          <LinearProgress
                            className="my_schedule_linear"
                            variant="determinate"
                            value={(datas?.completedsessions / datas?.totalsessionsL) * 100}
                            color="success"
                            sx={{
                              height: "10px",
                              borderRadius: 2,
                              bgcolor: "#fffff",
                            }}
                          />
                        </p>
                        <div className="">
                          {datas?.completedsessions !== datas.totalsessionsL ? <div>
                            <div className="l-my-course-info-next-session-label"> NEXT SESSION</div>
                            <div className="l-my-course-info-next-session-data"> {moment(datas?.nextsessions?.startdate).format("dddd")}, {moment(datas?.nextsessions?.startdate).format('ll')} {convertTime(datas?.nextsessions?.from)}&nbsp;{getTimeZoneShortForm(generateCommonParams(datas.courseid))} - {convertTime(datas?.nextsessions?.to)}&nbsp;{getTimeZoneShortForm(generateCommonParams(datas.courseid))}</div>
                          </div> :
                            <div className="l-my-course-info-next-session-label"> SESSIONS ARE COMPLETED</div>}
                        </div>

                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      className="my_courses_btn_group mt-lg-4 mt-4 mt-sm-4"
                    >
                      <Grid container justifyContent="center" alignItems="center">
                        {datas?.slug && datas?.streamstatus == 'Stream Started' ?
                          <div className="cursor-pointer">
                            <Tooltip title={tooltip_messages.joinSession} placement="top">
                              <Button
                                variant="outlined"
                                className="l_pods_btn mb-2 me-1 me-lg-0 cursor-pointer"
                                onClick={(e) => navigateToSession(datas)}
                              >
                                Join Session
                              </Button>
                            </Tooltip>
                          </div> : ""}

                        <div className="cursor-pointer">
                          <Tooltip title={tooltip_messages.openGroupSpace} placement="top">
                            <Button
                              variant="outlined"
                              className="step-4 l_curriculum_btn mb-2 me-1 me-lg-0 cursor-pointer"
                              onClick={(e) => navigateToGroupSpace(datas.coursestreamid, datas)}
                            >
                              Group Space
                            </Button>
                          </Tooltip>

                        </div>

                        <div>
                          <Tooltip title={tooltip_messages.viewCourseDetail} placement="top">
                            <Button
                              variant="outlined"
                              className="step-5 course_info_btn mb-2 me-1 me-lg-0"
                              onClick={(e) => navigateToCourseInfo(e, datas)}
                            >
                              Course Info
                            </Button>
                          </Tooltip>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              {/* desktop-view */}
              <div className="l-my-course-card-main-desktop-view mb-2">
                <Card className="step-3 l-my-course-card-main-desktop">
                  <div className="l-my-course-img-main" style={{ backgroundImage: `url(${datas?.courseimage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                    {/* <img src={datas?.courseimage}></img> */}
                  </div>
                  <div className="l-my-course-info-main">
                    <div className="l-my-course-info-cname">{datas?.coursename}</div>
                    <div className="l-my-course-info-bottom">

                      <div className="l-my-course-info-sessions">
                        Sessions&nbsp;-&nbsp;{datas.completedsessions ? datas?.completedsessions : (0)}&nbsp;/&nbsp;{datas.totalsessionsL}&nbsp;Complete
                      </div>
                      <div>
                        <LinearProgress
                          className="my_schedule_linear"
                          variant="determinate"
                          value={(datas?.completedsessions / datas?.totalsessionsL) * 100}
                          color="success"
                          sx={{
                            height: "10px",
                            borderRadius: 2,
                            bgcolor: "#fffff",
                          }}
                        />
                      </div>
                      {datas?.completedsessions !== datas.totalsessionsL ? <div>
                        <div className="l-my-course-info-next-session-label"> NEXT SESSION</div>
                        <div className="l-my-course-info-next-session-data"> {moment(datas?.nextsessions?.startdate).format("dddd")}, {moment(datas?.nextsessions?.startdate).format('ll')} {convertTime(datas?.nextsessions?.from)}&nbsp;{getTimeZoneShortForm(generateCommonParams(datas.courseid))} - {convertTime(datas?.nextsessions?.to)}&nbsp;{getTimeZoneShortForm(generateCommonParams(datas.courseid))}</div>
                      </div> :
                        <div className="l-my-course-info-next-session-label"> SESSIONS ARE COMPLETED</div>}

                    </div>
                  </div>
                  <div className="l-my-course-actions-main">
                    {datas?.slug && datas?.streamstatus == 'Stream Started' ?
                      <div className="cursor-pointer">
                        <Tooltip title={tooltip_messages.joinSession} placement="right">
                          <Button
                            variant="outlined"
                            className="l_pods_btn mb-2 me-1 me-lg-0 cursor-pointer"
                            onClick={(e) => navigateToSession(datas)}
                          >
                            Join Session
                          </Button>
                        </Tooltip>
                      </div> : ""}

                    <div className="cursor-pointer">
                      <Tooltip title={tooltip_messages.openGroupSpace} placement="right">
                        <Button
                          variant="outlined"
                          className="step-4 l_curriculum_btn mb-2 me-1 me-lg-0 cursor-pointer"
                          onClick={(e) => navigateToGroupSpace(datas.coursestreamid, datas)}
                        >
                          Group Space
                        </Button>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title={tooltip_messages.viewCourseDetail} placement="right">
                        <Button
                          variant="outlined"
                          className="step-5 course_info_btn mb-2 me-1 me-lg-0"
                          onClick={(e) => navigateToCourseInfo(e, datas)}
                        >
                          Course Info
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
          )
        }) :
          <Grid container justifyContent="center">
            <div className="">
              <span className="no_data_text">
                <NoRecordFound noRecordFoundImage={assets.noRecordFound.expertCourses} noRecordFoundText={error_messages.noDataFound}></NoRecordFound>
              </span>
            </div>

          </Grid>
        }

        {courses.length > 0 ? <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={Math.ceil(course.length / itemsPerPage)}
            page={currentPage}
            onChange={handleChange}
            color="primary"
          />
        </Box> : ""}

      </Container>
      {/* CARD SECTION END*/}

      {/* Drawer section*/}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drawopen}
      >
        <div className="drawer_section mt-2 mb-3 p-2">
          <div className="">
            {"Course Information"}
            <span className="add_kids_close_icon" onClick={handleDrawerClose}>
              <CloseIcon />
            </span>
          </div>
          <h5 className="cinemography_text">Robotics</h5>
          <a href="" style={{ textDecoration: "none" }}>
            Go to course detail
          </a>
          {/* <div className="d-flex align-items-center mt-3">
            <div>
              <label className="section_text_label">Section 1</label>
              <h6 className="section_date">September 14, 2022</h6>
            </div>
            <div className="ms-auto complete_text_cor_inf">
              <CheckCircleOutlineOutlinedIcon
                sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
              />
              <span className="complete_text_cor_inf">COMPLETE</span>
            </div>
          </div>
          <hr></hr>

          <div className="d-flex align-items-center mt-3">
            <div>
              <label className="section_text_label">Section 2</label>
              <h6 className="section_date">September 15, 2022</h6>
            </div>
            <div className="ms-auto complete_text_cor_inf">
              <CheckCircleOutlineOutlinedIcon
                sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
              />
              <span className="complete_text_cor_inf">COMPLETE</span>
            </div>
          </div>
          <hr></hr>

          <div className="d-flex align-items-center mt-3">
            <div>
              <label className="section_text_label">Section 3</label>
              <h6 className="section_date">September 16, 2022</h6>
            </div>
            <div className="ms-auto missed_text_cor_inf">
              <ErrorOutlineRoundedIcon
                className="missed__cor_inf"
                sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
              />

              <span className="missed_text_cor_inf">MISSED</span>
            </div>
          </div>
          <hr></hr>

          <div className="d-flex align-items-center mt-3">
            <div>
              <label className="section_text_label">Section 4</label>
              <h6 className="section_date">September 17, 2022</h6>
            </div>
            <div className="ms-auto complete_text_cor_inf">
              <CheckCircleOutlineOutlinedIcon
                sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
              />
              <span className="complete_text_cor_inf">COMPLETE</span>
            </div>
          </div>
          <hr></hr>

          <div className="d-flex align-items-center mt-3">
            <div>
              <label className="section_text_label">Section 5</label>
              <h6 className="section_date">September 18, 2022</h6>
            </div>
            <div className="ms-auto complete_text_cor_inf">
              <CheckCircleOutlineOutlinedIcon
                sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
              />
              <span className="complete_text_cor_inf">COMPLETE</span>
            </div>
          </div> */}
          <LearnersPods></LearnersPods>
        </div>
      </Drawer>
      {/* Drawer section end*/}

      {/* PLAY GAME POPUP */}
      <Dialog id='game_settings' open={openGroupSpacePopup} onClose={handleCloseGroupSpacePopup}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            {/* <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              GROUP SPACE
            </Typography> */}

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseGroupSpacePopup}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <LearnersPods></LearnersPods>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>
    </div>
  );
}
