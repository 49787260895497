import React from "react";
import { Grid, Avatar } from "@mui/material";
import account from "../../../_mock/account";
import "./courses-details.css";
import baby_img from "../../../assets/img/baby_img.png";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

//ICONS
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";

export default function CourseDetails() {
  return (
    <div>
      <div className="container">
        <div className="row mt-5">
          {/* Introduction section */}
          <div className="col-12 col-lg-6 col-md-6 ">
            <p className="introduction_text">
              Introduction to Robotics-A <br></br> lego based learning
            </p>
            <Grid container>
              <div className="me-5">
                <label className="price_session_text">PRICE/SESSION</label>
                <p className="price_session_number">
                  <strong>$10</strong>/session
                </p>
              </div>
              <div className="">
                <label className="price_session_text">PRICE/SESSION</label>
                <p className="price_session_number">
                  <strong>10</strong>
                </p>
              </div>
            </Grid>
            <p className="instructors_text m-0">Instructors</p>
            <div className="d-flex align-items-center">
              <Avatar src={account.photoURL} alt="photoURL" />

              <div className="ms-2">
                <div>
                  <span className="Patricia_text">Patricia Williams</span>
                </div>

                <span className="Design_Expert_text">
                  Design Expert 14 yrs Experience
                </span>
              </div>
            </div>
          </div>
          {/* Introduction section end */}
          {/* baby image section */}
          <div className="col-12 col-lg-6 col-md-6 mt-3">
            <img
              src={baby_img}
              alt=""
              className="baby_img"
              style={{ width: "100%", height: "100%" }}
            ></img>
          </div>
          {/* baby image section end */}
        </div>
        {/* About Course section */}
        <div className="row mt-5">
          <div className="col-12 col-lg-8 col-md-8 ">
            <p className="about_course_text mb-1">About Course</p>
            <p className="about_course_discription_text">
              Great designers are empaths.They harness the power of emotional
              response to connect to audience and inspire action.If uou want
              your designs to move people.you need to understand the role
              emotion plays in your work.Join stefan Mumaw for this fun and
              frank exploration,which probes the human condition for lesson that
              can be used in any design project: from branding campaign to
              product packaging.Stefan explains how emotion,rational
              throught,and culture impact our reactions to design,and how you
              can use different choices and tools-layout.typography,color,and
              more-to trigger different emotions and achive connection with your
              brand,product,or service.
            </p>
            <div>
              <p className="about_course_text mb-1">Syllabus</p>
              <div>
                <p className="Session_title_text"> Session 1</p>
                <p className="about_course_discription_text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum has bees the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a gallery of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div>
                <p className="Session_title_text"> Session 2</p>
                <p className="about_course_discription_text">
                  It is a long establisted fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more or less
                  normal.
                  <li>Many desktop publishing packages and web page editors</li>
                  <li>Various version have evolved over the years</li>
                  <li>
                    There are many variantions of passages of Lorem Ipsum
                    available
                  </li>
                  <li>All the Lorem Ipsum generators on the Internet tend</li>
                  <li>
                    {" "}
                    Lorem Ipsum is therefore alwayts free from
                    repetition,injection humour, or non-characteristic
                  </li>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-4 ">
            <Card className="course_glance_card">
              <CardContent>
                <p className="course_glance_text">Course Glance</p>
                {/* Duration */}
                <div className="d-flex align-items-center">
                  <AccessTimeIcon color="action" />

                  <div>
                    <span className="Duration_text ms-1">
                      Duration<strong> 1hr per session</strong>
                    </span>
                  </div>
                </div>
                <p className="between_text ms-4">(Between 11-12am)</p>
                {/* Duration end */}
                {/* Instruction Medium */}
                <div className="d-flex align-items-center">
                  <AvTimerOutlinedIcon color="action" />

                  <div>
                    <span className="Duration_text ms-1">
                      Instruction Medium<strong> English</strong>
                    </span>
                  </div>
                </div>
                {/* Instruction Medium end*/}

                {/*  Starting */}
                <div className="d-flex align-items-center mt-3">
                  <CalendarTodayOutlinedIcon
                    color="action"
                    sx={{ fontSize: 20 }}
                  />

                  <div>
                    <span className="Duration_text ms-1">Starting</span>
                  </div>
                </div>
                <p className="between_text ms-4">
                  <strong> September 12,2022</strong>
                </p>
                {/*  Starting end */}

                {/*   Ending */}
                <div className="d-flex align-items-center">
                  <CalendarTodayOutlinedIcon
                    color="action"
                    sx={{ fontSize: 20 }}
                  />

                  <div>
                    <span className="Duration_text ms-1">Ending</span>
                  </div>
                </div>
                <p className="between_text ms-4">
                  <strong> September 22,2022</strong>
                </p>
                {/*   Ending end */}
                <p className="m-0">Categories</p>
                <Grid container>
                  <button
                    type="button"
                    class="btn btn-outline-primary Categories_btn"
                  >
                    Design
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary Categories_btn"
                  >
                    Emotions
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary Categories_btn"
                  >
                    Psychology
                  </button>
                </Grid>
              </CardContent>
            </Card>

            <Card className="learners_card mt-3 mb-2">
              <CardContent>
                <p className="course_glance_text m-0">Learners info</p>
                {/* Duration */}
                <Grid container>
                  <div className="me-5">
                    <label className="required_text">Min required</label>
                    <p className="">
                      <strong>10</strong>
                    </p>
                  </div>
                  <div className="">
                    <label className="required_text">Max required</label>
                    <p className="">
                      <strong>20</strong>
                    </p>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
        {/* About Course section end*/}
        <div>
          <p className="instructors_text_2 m-0">Instructors</p>
          <div className="d-flex align-items-center">
            <Avatar src={account.photoURL} alt="photoURL" />

            <div className="ms-2">
              <div>
                <span className="Patricia_text">Patricia Williams</span>
              </div>

              <span className="Design_Expert_text">
                Design Expert 14 yrs Experience
              </span>
            </div>
          </div>
          <p className="about_course_discription_text">
            There are many variationsof passages of Lorem Ipsum available, but
            the majority have sufferred alteration in someone form,by injected
            humour,or randomised words which don't look even slightly
            believable.If you are going to use a passage of Lorem Ipsum,you need
            to be sure there isn't anything embarrassing hidden in the middle of
            ext.
          </p>
        </div>
        <br></br>
      </div>
    </div>
  );
}
