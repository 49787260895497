import React from "react";
import MeekuLogo from '../../../assets/img/logo_1.png'
import "./LoadingSpinner.css";

function LoadingSpinner(props) {
  return (
    <div className="spinner-container">
      <div>
        <img src={MeekuLogo} className="loading-logo"></img>
        <div className="loading-text">{props.message}</div>
        </div>
    </div>
  );
}

export default LoadingSpinner;