import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Iconify from '../../../../components/Iconify';

import { setMenu } from "../../../../store/slices/settingsSlice";
import Whiteboard_Icon from "../../../../assets/img/white-board/whiteboard-24.png";
import "./course.css";

import SessionService from "../../../../services/session-service";
import AdminService from "../../../../services/admin-service";
import FirebaseService from "../../../../services/firebase-service";

//CONFIGS
import storage_configs from "../../../../config/storage-config";
import conditional_configs from "../../../../config/conditional_config";
import url_configs from "../../../../config/url";
import icons_config from "../../../../config/icons";
import games from "../../../../config/games";
import assets from "../../../../config/assets";
import whiteboardConfig from "../../../../config/whiteboard-config";

import { getCurrentUserData } from "../../../../utils/util";
import { setSelectedContent, setSelectedCurriculamType, setSelectedCurriculam, setSelectedCurriculamData, setTopicName } from '../../../../store/slices/settingsSlice';

function CourseList() {
  //GET SLUG FROM LINK
  let { slug } = useParams();
  const selectedCurriculamData = useSelector((state) => state.settings.selectedCurriculamData);
  const topicName = useSelector((state) => state.settings.topicName);
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const [contentsList, setContentList] = React.useState([]);
  const [whiteboardList, setWhiteboardList] = React.useState([]);
  const [gameList, setGameList] = React.useState([]);
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);

  //const [selectedCurriculamData, setSelectedCurriculamData] = React.useState(null);

  const dispatch = useDispatch();

  const setSidebarMenu = (menu) => {
    dispatch(setMenu(menu));
  };

  useEffect(() => {
    getAllWhiteboardTemplate();
    //localStorage.removeItem(storage_configs.localStorage.sessions.currentScreenData);
    if (getCurrentUserData().role == conditional_configs.role.expert) {
      let current_session_id = localStorage.getItem(storage_configs.localStorage.sessions.current_session_id)
      SessionService.getCourseContentBySessionId(current_session_id).then(response => {
        if (response && response.success == "true") {
          setContentList(response.data.coursecontentsessiondetail);
          if (response.data && response.data.coursecontentsessionwhiteboard) {
            setWhiteboardList(response.data.coursecontentsessionwhiteboard.filter(e => e.template_ids != null));
          }
          setGameList(response.data.coursecontentsessiongame);
          if (response.data.coursecontentsessiondetail) {
            showContent(response.data.coursecontentsessiondetail[0]);
          }
        }
      })
        .catch(e => {
          console.log(e);
        });
    } else if (getCurrentUserData().role == conditional_configs.role.learner) {
      let membercoursestreamdetailid = localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid)
      SessionService.getCourseContentBymembercoursestreamdetailid(membercoursestreamdetailid).then(response => {
        if (response && response.success == "true") {
          setContentList(response.data.coursecontentsessiondetail);
          if (response.data && response.data.coursecontentsessionwhiteboard) {
            setWhiteboardList(response.data.coursecontentsessionwhiteboard.filter(e => e.template_ids != null));
          }
          setGameList(response.data.coursecontentsessiongame);
          if (response.data.coursecontentsessiondetail) {
            showContent(response.data.coursecontentsessiondetail[0]);
          }
        }
      })
        .catch(e => {
          console.log(e);
        });
    } else {
      setContentList([])
    }
    let checkCurrentSession = localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData);
    if (checkCurrentSession) {
      dispatch(setSelectedCurriculamData(JSON.parse(checkCurrentSession)))
    } else {
      dispatch(setSelectedCurriculamData(null))
    }
  }, []);

  /** FIND IS CURRICULAM LINK OR ANY DOCUMENT URL AND DISPATCH AS SELECTED CONTENT */
  const showContent = (data) => {
    if (data.curriculumlink) {
      localStorage.setItem(storage_configs.localStorage.sessions.show_content, data.curriculumlink)
      dispatch(setSelectedContent(data.curriculumlink));
      dispatch(setTopicName(data.subject));
      dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.content));
    } else {
      localStorage.setItem(storage_configs.localStorage.sessions.show_content, data.document.documenturl)
      dispatch(setSelectedContent(data.document.documenturl));
      dispatch(setTopicName(data.subject));
      dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.content));
    }
    //window.location.reload();
  }

  const openWhiteboardLink = (obj, e) => {
    e.stopPropagation()
    dispatch(setSelectedContent(null));
    dispatch(setSelectedCurriculamType(null));
    if (obj && obj.template_ids) {
      let boardData = showBoardsList(obj.template_ids, obj);
      boardData[0].active = true;
      setTimeout(() => {
        dispatch(setSelectedContent(boardData));
        dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.whiteboard));
      }, 100);
    }
  }

  const showBoardsList = (template_ids, obj) => {
    let boardsList = [];
    if (template_ids) {
      let splitBoardIds = template_ids.split(',');
      if (splitBoardIds && splitBoardIds.length > 0) {
        for (let i = 0; i < splitBoardIds.length; i++) {
          let boardObj = {};
          let actualBoardList = whiteboardTemplateList;
          if (actualBoardList && actualBoardList.length > 0) {
            let newWhiteboardArray = [];
            //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
            actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
              newWhiteboardArray[index] = { ...device }
            })
            boardObj = newWhiteboardArray[0];
            if (boardObj) {
              FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-' + slug);
            }
            let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + boardObj.slug + '-div-' + slug + whiteboardConfig.key;
            boardObj.whiteboardLink = tempWLink;
            if (getCurrentUserData().role == conditional_configs.role.expert) {
              boardObj.is_locked = false;
            } else {
              boardObj.is_locked = true;
            }
            boardsList.push(boardObj);
          }
        }
      }
    }
    console.log('SHOW BOARD LIST ', boardsList)
    return boardsList;
  }

  const getAllWhiteboardTemplate = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      console.log(response.data);
      setWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const startBreakoutSession = (obj, e, ctype) => {
    console.log('whiteboard started', obj);
    let tempObj = null;
    localStorage.removeItem(storage_configs.localStorage.sessions.currentScreenData)
    tempObj = obj;
    if (!tempObj.selectedCurriculamType) {
      tempObj.selectedCurriculamType = ctype;
    }
    dispatch(setSelectedCurriculam(null));
    dispatch(setSelectedCurriculam(tempObj));
    dispatch(setSelectedCurriculamData(tempObj));
  }

  const getGameIcon = (gId) => {
    let tempGameList = games.gameList.filter(e => e.id == gId)[0];
    if (tempGameList) {
      return tempGameList.img;
    }
  }

  const openGameSettings = (e) => {
    e.stopPropagation()
    window.location.reload();
  }
  return (
    <div className="host-course-list">
      {/* <div className="d-flex align-items-center role-label">
        <div className=" me-auto"> Courses </div>
        <span onClick={() => setSidebarMenu("")}>
          <MdClose className="close-icon" />
        </span>
      </div> */}
      <div className="p-2">
        <ul className="course_list_ul-v2 p-0 mb-0">
          {contentsList?.map((contentObj, contentIndex) => {
            return (
              <li key={contentIndex} className="course_list_li" onClick={(e) => showContent(contentObj)}>
                <div className="host-course-item-v2 d-flex align-items-center">
                  <div>
                    <img src={assets.fileWhite} alt="" className="me-3 left-course-icons-v2" />
                  </div>
                  <div className="course_list_li_text-v2">{contentObj.subject}</div>
                </div>
              </li>
            )
          })}
        </ul>
        {/* WHITEBOARD TYPE CONTENT */}
        {/* {whiteboardList && whiteboardList.length > 0 ? <h5 className="ps-1">Whiteboards</h5>:""} */}

        <ul className="course_list_ul-v2 p-0 mb-0">
          {whiteboardList?.map((wObj, wIndex) => {
            return (
              <li key={'course_list_li_w' + wIndex} className={selectedCurriculamData && selectedCurriculamData.id == wObj.id ? 'active-session-v2 course_list_li' : 'course_list_li'} onClick={(e) => openWhiteboardLink(wObj, e)}>
                <div className="host-course-item-v2 d-flex align-items-center">
                  <div>
                    <img src={Whiteboard_Icon} alt="" className="me-3 left-course-icons-v2" />
                  </div>
                  <div className="course_list_li_text-v2 flex-between">
                    <span>{wObj?.group_name}</span>
                    {
                      getCurrentUserData().role == conditional_configs.role.expert ?
                        <span className="play-btn-span" onClick={(e) => startBreakoutSession(wObj, e, conditional_configs.curriculam_types.whiteboard)}>{getIcon(icons_config.play_btn)}</span>
                        // <span>
                        //   {
                        //     !selectedCurriculamData ?
                        //       <span className="play-btn-span" onClick={(e) => startBreakoutSession(wObj, e, conditional_configs.curriculam_types.whiteboard)}>{getIcon(icons_config.play_btn)}</span>
                        //       : ""
                        //   }
                        // </span>
                        : ""
                    }
                  </div>
                </div>
              </li>
            )
          })}
        </ul>

        {/* GAME TYPE CONTENT */}

        <ul className="course_list_ul-v2 p-0">
          {gameList?.map((gObj, gIndex) => {
            return (
              <li key={'course_list_li_w' + gIndex} className={selectedCurriculamData && selectedCurriculamData.id == gObj.id ? 'active-session-v2 course_list_li' : 'course_list_li'} >
                <div className="host-course-item-v2 d-flex align-items-center ps-0">
                  <div>
                    <img src={getGameIcon(gObj?.puzzleid)} alt="" className="me-3 left-course-icons-v2" />
                  </div>
                  <div className="course_list_li_text-v2 flex-between">
                    <span>{gObj?.name}</span>
                    {
                      getCurrentUserData().role == conditional_configs.role.expert ?
                        <span>
                          {
                            !selectedCurriculamData ?
                              <span className="play-btn-span" onClick={(e) => startBreakoutSession(gObj, e, conditional_configs.curriculam_types.games)}>
                                {getIcon(icons_config.play_btn)}
                              </span> :
                              selectedCurriculamData && selectedCurriculamData.id == gObj.id ?
                                <span className="play-btn-span" onClick={(e) => openGameSettings(e)} >
                                  {getIcon(icons_config.cog)}
                                </span> : ""
                          }
                        </span>
                        : ""
                    }
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
}

export default CourseList;
