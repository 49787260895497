import React from 'react';
import ParentRegistrationForm from './parent-registration-form';
//import Header from '../../components/header/header';
import LandHeader from '../../components/landing-header/landing-header';
import './parent-registration-form.css';

import meta_data from '../../config/meta_data';
function App() {
    document.title = meta_data.company + '| Parent Registration';
    return (
        <React.Fragment>
            <LandHeader />
            <ParentRegistrationForm />
        </React.Fragment>
    );
}

export default App;
