import React from 'react';
import './no-record-found.css'

export default function NoRecordFound(props) {
  return (
    <div>
      <div className='d-flex justify-content-center'>
        <img src={props.noRecordFoundImage} alt="" />
      </div>
      <p className='no-record-found-text-n'>{props.noRecordFoundText}</p>
    </div>

  )
}
