import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from 'notistack';

import CourseService from "../../services/courses-service";

import url_configs from "../../config/url";

export default function CheckoutForm() {
  const navigate = useNavigate();

  const checkoutData = useSelector((state) => state.settings.checkoutData);
  const stripeClientSecret = useSelector((state) => state.settings.stripeClientSecret);

  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(stripeClientSecret).then(({ paymentIntent }) => {
    //   // if(paymentIntent.status == "succeeded"){
    //   //   enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   //   assignCourseToParent();
    //   // }else if(paymentIntent.status == "processing"){
    //   //   enqueueSnackbar('Your payment is processing.', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   // }else if(paymentIntent.status == "requires_payment_method"){
    //   //   enqueueSnackbar('Your payment was not successful, please try again.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   // }else{
    //   //   enqueueSnackbar('Something went wrong.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   // }
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       enqueueSnackbar('Your payment was not successful, please try again.', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       //setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       enqueueSnackbar('Something went wrong.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       //setMessage("Something went wrong.");
    //       break;
    //   }
    // });
  }, [stripe]);

  const assignCourseToParent = () => {
    let checkData = JSON.parse(localStorage.getItem('checkout-data'));
    if (checkData) {
      CourseService.assignCourseToParent(checkData)
        .then(response => {
          console.log(response);
          //navigate('/parent/my-courses');
          // if (response && response.data && response.success === 'true') {
          //   enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          // } else if (response && response.data && response.success === 'false') {
          //   enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          // } else {
          //   enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          // }
        })
        .catch(error => {
          navigate('/');
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //assignCourseToParent();

    setIsLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: url_configs.frontend + "success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } 
    // else if (paymentIntent && paymentIntent.status === 'succeeded') {
    //   enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   assignCourseToParent();
    // } 
    else {
      enqueueSnackbar('Payment was not successfully completed!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      setMessage("An unexpected error occurred.");
      navigate(-1);
    }

    setIsLoading(false);

  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <div className="payment-form-container">
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => setEmail(e.target.value)}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button className="stripe-btn" disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>


  );
}