import { v4 as uuidv4 } from "uuid";
import account from "../../../_mock/account";

export const data = [
  {
    id: "1",
    Task: "Write the names of each planet abd thir coolers",
    icon: account,
  },
  {
    id: "2",
    Task: "1min games to identity the gases present in the system",
    icon: account,
  },
  {
    id: "3",
    Task: "Metals that are found on the surface of the mars",
    icon: account,
  },
  {
    id: "4",
    Task: "Search for the planet with 3 outer rings",
    icon: account,
  },
  {
    id: "5",
    Task: "draw each planet with names in the solar system",

    icon: account,
  },
  {
    id: "6",
    Task: "Who travelled to the moon first?",

    icon: account,
  },
];

export const columnsFromBackend = {
  [uuidv4()]: {
    title: "Task",
    items: data,
  },
  [uuidv4()]: {
    title: "To Do's",
    items: [],
  },
  [uuidv4()]: {
    title: "Inprogress",
    items: [],
  },
  [uuidv4()]: {
    title: "Completed",
    items: [],
  },
};
