import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Badge,
  Fade,
  TextField,
  Typography,
  Button,
  Grid, InputBase,
  IconButton,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Container,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardActions,
  CardContent,
  Radio,
  Paper,
  Stack,
  Modal
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SearchIcon from "@mui/icons-material/Search";

//Packages
import slugify from 'react-slugify';

//ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

//ASSETS -> IMG
import Sampleimg from "../../../assets/img/white-board/whiteboard.png";
import "./whiteboard-templates.css";
import Iconify from '../../../components/Iconify';

//CONFIGS
import iconsConfig from "../../../config/icons";
import url_configs from "../../../config/url";
import questionTypes from '../../../config/question-types';

//SERVICES
import AdminService from "../../../services/admin-service";

import {
  getCurrentUserData,
} from "../../../utils/util.js";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from 'notistack';
import conditional_configs from "../../../config/conditional_config";

export default function Whiteboard() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    templatename: Yup.string().required("Quiz template name is required"),
  });

  const [templateList, setTemplateList] = useState([]);
  const [quiz_card_icon_btn, setQuiz_card_icon_btn] = useState(null);
  const open_select = Boolean(quiz_card_icon_btn);

  const [openTemplate, setOpenTemplate] = useState(false);

  const [enableWhiteboardWindow, setEnableWhiteboardWindow] = useState(false);
  const [whiteboardLink, setWhiteboardLink] = useState("");
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  const {
    register,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  /** OPEN TEMPLATE POPUP */
  const handleOpenTemplate = (type) => {
    setOpenTemplate(true)
  };

  const openWhiteboard = (obj) => {
    let nLink = url_configs.frontend + 'whiteboard-detail/' + obj.id;
    window.open(nLink, '_blank')
    //navigate('/whiteboard-detail/'+obj.id);
  }

  // const openWhiteboard = (obj) => {

  //   if (obj && obj.slug) {
  //     let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + obj.slug + ',-4-Gf1BCSf5ns_M4ChSamw'
  //     setWhiteboardLink(tempWLink);
  //     setSelectedTemplateName(obj.name);
  //     setEnableWhiteboardWindow(true);
  //   }
  // }

  const closeWhiteboard = () => {
    setWhiteboardLink("");
    setSelectedTemplateName("");
    setEnableWhiteboardWindow(false);
  }

  const deleteTemplate = (obj, index) => {
    if (obj && obj.id) {
      deleteWhiteboardTemplate(obj.id)
    }
  }
  /** CLOSE TEMPLATE POPUP */
  const handleCloseTemplate = () => setOpenTemplate(false);

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };

  //CARD RIGHT TOP MENUS
  const card_icon_open = (event) => {
    setQuiz_card_icon_btn(event.currentTarget);
  };
  const card_icon_close = () => {
    setQuiz_card_icon_btn(null);
  };

  useEffect(() => {
    // let templates = JSON.parse(localStorage.getItem('whiteboard-templates'));
    // if(templates && templates.length > 0){
    //   setTemplateList(templates);
    // }
    if (getCurrentUserData() && (getCurrentUserData().role == conditional_configs.role.curriculam_creator || getCurrentUserData().role == conditional_configs.role.admin)) {
      getAllActiveWhiteboardTemplates();
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  const getAllActiveWhiteboardTemplates = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      if (response && response.data && response.data.length > 0) {
        if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
          setTemplateList(response.data);
          localStorage.setItem('admin-boards', JSON.stringify(response.data));
        } else {
          setTemplateList(response.data.filter(e => e.createdby == getCurrentUserData().id));
          let tempList = response.data.filter(e => e.createdby == getCurrentUserData().id);
          localStorage.setItem('admin-boards', JSON.stringify(tempList));
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }
  //SUBMIT NEW QUIZ TEMPLATE
  const onSubmit = (data) => {
    console.log('SUBMIT EVENT CALLED')
    if (!data.templatename) {
      enqueueSnackbar('Please enter template name', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }
    var insertTemplateModel = {
      name: data.templatename,
      slug: slugify(data.templatename + '-' + (templateList.length + 1)),
      createdby: getCurrentUserData().id.toString()
    }
    console.log('INSERT DATA - ', insertTemplateModel);
    AdminService.createWhiteBoardTemplates(insertTemplateModel)
      .then(response => {
        console.log('RESPONSE - ', response);
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Whiteboard templated added Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          handleCloseTemplate();
          getAllActiveWhiteboardTemplates();
          openWhiteboard(response.data);
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        console.log('RESPONSE - ', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  const deleteWhiteboardTemplate = (id) => {
    AdminService.deleteWhiteboardTemplate(id)
      .then(response => {

        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Whiteboard templated deleted Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }

      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }
  //GAME ICON BUTTON FUNCTION END
  return (
    <div>
      {/* TITLE HEADER */}

      {!enableWhiteboardWindow ?
        <Container>
          <Box sx={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
              <Typography variant="h4" gutterBottom className='mb-0'>
                Whiteboard Templates
                <Badge className="badge_quiz" badgeContent={templateList?.length} sx={badgeStyle} />
              </Typography>
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e) => handleOpenTemplate('CREATE')}>
                New Whiteboard
              </Button>
            </Stack>
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper
                    component="form"
                    className="common-search-box"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search br course name" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Container>
        : ""}
      {/* CARD SECTION */}
      <div>
        {!enableWhiteboardWindow ?
          <Container>
            <div className="mt-4">
              {templateList?.map((templateObj, templateIndex) => {
                return (
                  <ul className="horizontal-list list-group-item">
                    <li>
                      <Card key={templateIndex} className="games_card">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={(e) => openWhiteboard(templateObj)}>
                            <div class="game_img">
                              <img src={Sampleimg} />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div class="d-flex align-items-center">
                              <h5 className="card_quiz_title" onClick={(e) => openWhiteboard(templateObj)}>
                                {templateObj.name}
                              </h5>
                              {/* <span
                        className="ms-auto quiz_dot_icon"
                        onClick={card_icon_open}
                      >
                        <MoreVertIcon />
                        <DeleteIcon style={{ marginRight: "10" }} />
                      </span> */}
                              <span
                                className="ms-auto quiz_dot_icon"
                                onClick={(e) => deleteTemplate(templateObj, templateIndex)}
                              >
                                <DeleteIcon style={{ marginRight: "10" }} />
                              </span>
                            </div>
                            {/* <h6 className="card_quiz_sub_title">
                      <Iconify icon={iconsConfig.quiz} />
                      <span className="ps-1">{templateObj.questions.length} Questions</span>
                    </h6> */}
                            <div class="d-flex align-items-center  mt-2">
                              <span className="play_game_icon">
                                <Iconify icon={iconsConfig.courses} />
                              </span>

                              <p className="card_courses_linked m-0">
                                0 Courses Linked
                              </p>
                              {/* <p className="ms-auto card_courses_linked m-0">
                        Last Updated <strong>{card_datas.updated_date}</strong>
                      </p> */}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </li></ul>

                );
              })}
            </div>
          </Container>
          :
          <div>
            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={(e) => closeWhiteboard()}
              className="close-whiteboard ms-2"
            >
              <CloseIcon />
            </Typography>
            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              className="close-whiteboard"
            >
              {selectedTemplateName}
            </Typography>

            <iframe className="iframe-class" src={whiteboardLink}></iframe>
          </div>
        }
      </div>
      {/* CARD SECTION END */}

      {/* IconButton */}
      <Grid container justifyContent="center">
        <div>
          <Menu
            id="select_file_btn_menu"
            MenuListProps={{
              "aria-labelledby": "select_file_btn",
            }}
            anchorEl={quiz_card_icon_btn}
            open={open_select}
            onClose={card_icon_close}
            TransitionComponent={Fade}
          >
            {/* <MenuItem onClick={card_icon_close}>
                <ModeEditIcon style={{ marginRight: "10" }} /> Edit Quiz
              </MenuItem> */}

            <MenuItem onClick={(e) => deleteTemplate(e)}>
              <DeleteIcon style={{ marginRight: "10" }} /> Delete
            </MenuItem>
          </Menu>
        </div>
      </Grid>
      {/* IconButton */}

      {/* SELECT TEMPLATE POPUP */}
      <Dialog fullWidth id={'quiz_template_popup'} open={openTemplate} onClose={handleCloseTemplate}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Create Whiteboard Template
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseTemplate}
              className="close-whiteboard"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <form>
            {/* FIRST NAME TEXT FIELD */}
            <Grid item xs={12} sm={12} className="mt-2">
              <TextField
                required
                id="templatename"
                name="templatename"
                label="Template Name"
                fullWidth
                margin="dense"
                {...register("templatename")}
                error={errors.templatename ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.templatename?.message}
              </Typography>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className="dialog_action">
          <Button variant="contained" className="add_new_quiz_btn" onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
