import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";
import { getCurrentUserData } from "../utils/util";

const gameSettingsByExpert = data => {
    data = {
            userId: JSON.parse(localStorage.getItem('user')).id,
            userType: "teacher",
            name: JSON.parse(localStorage.getItem('user')).name,
            groupId: [100],
            setting: 1
    }
    return http.post("http://54.144.210.42:3000/robo-shaper", data).then((response) => {
        return response;
      });
};

const GetGameSettingByGroupId = (groupId, gameId) => { 
    return http.get('/gamesettings/GetGameSettingByGroupId/'+groupId+'/'+gameId,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const getGameResultBySlugAndUserId = async (slug) => {
    const response = await http.get('/puzzleresults/getGameResultBySlugAndUserId?userid='+getCurrentUserData().id+'&slug='+slug,{ headers: authHeader() });
    return response.data;
};

const GameService = {
    gameSettingsByExpert,
    GetGameSettingByGroupId,
    getGameResultBySlugAndUserId
};
export default GameService;