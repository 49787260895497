import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import "./content.css";

import meta_data from "../../config/meta_data";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="static-content-container">
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQaqcdax1E0rI-uBFiWBbWD2cSbWfp-rmXHyUl_UOhxuz6CohiK6zx1nXxVJitUFA/embed?start=false&loop=false&delayms=3000" frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div>
  );
}
