import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { BsFileEarmarkText } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import Iconify from '../../../../components/Iconify';
import slugify from 'react-slugify';
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import Swal from "sweetalert2";

import { Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem, Stack, Typography, IconButton, Menu } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';

import { setIsChatGeneralNotification, setIsFluid, setIsRecapClicked, setIsRecapOpened, setMenu } from "../../../../store/slices/settingsSlice";
import Book_icon_img from "../../../../assets/img/courses_img/book_icon_img.png";
import Whiteboard_Icon from "../../../../assets/img/white-board/whiteboard-24.png";
import off_clock_img from "../../../../assets/img/courses_img/off_clock_img.png";
import short_video_img from "../../../../assets/img/courses_img/short_video_img.png";
import "./course.css";

import { getBreakoutSessionStatus, getCompletedSessions, getCurriculumDetail, getUnique } from "../../../../utils/util";

//SERVICES
import SessionService from "../../../../services/session-service";
import AdminService from "../../../../services/admin-service";
import FirebaseService from "../../../../services/firebase-service";

//CONFIGS
import storage_configs from "../../../../config/storage-config";
import conditional_configs from "../../../../config/conditional_config";
import url_configs from "../../../../config/url";
import icons_config from "../../../../config/icons";
import games from "../../../../config/games";
import whiteboardConfig from "../../../../config/whiteboard-config";
import assets from "../../../../config/assets";

import {
  getCurrentUserData,
  changeBreakoutSessionStatus,
  getExpertOpenedCurrentGroupId
} from "../../../../utils/util";

import {
  setBreakoutGameList,
  setSelectedContent,
  setSelectedCurriculamType,
  setSelectedCurriculam,
  setSelectedCurriculamData,
  setBreakoutCurrentScreenData,
  setCurrentSubSessionsList
} from '../../../../store/slices/settingsSlice';

import { setLeaveGroup } from "../../../../store/slices/zoomSlice";

import io from "socket.io-client";
import error_messages from "../../../../config/error_messages";
import MemberService from "../../../../services/member-service";

let b_room_socket;


function CourseList() {
  //GET SLUG FROM LINK
  let { slug } = useParams();
  const selectedCurriculamData = useSelector((state) => state.settings.selectedCurriculamData);
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const [contentsList, setContentList] = React.useState([]);
  const [whiteboardList, setWhiteboardList] = React.useState([]);
  const [quizList, setQuizList] = React.useState([]);

  const [gameList, setGameList] = React.useState([]);
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);
  const breakoutRoomStatus = useSelector((state) => state.settings.breakoutRoomStatus);
  //const [selectedCurriculamData, setSelectedCurriculamData] = React.useState(null);

  const dispatch = useDispatch();

  //RECAP
  const [openCompletedSessions, setOpenCompletedSessions] = useState(false);
  const [openGroupSpaceButton, setOpenGroupSpaceButton] = useState(false);
  const [lastCompletedSessions, SetLastCompletedSessions] = useState([]);
  const [previousSessions, SetPreviousSessions] = useState([]);
  const [courseGroups, SetCourseGroups] = useState([]);
  const [selectedSessionId, SetSelectedSessionId] = useState([]);
  const [selectedGroup, SetSelectedGroup] = useState();
  const [selectedCompletedSession, SetSelectedCompletedSession] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [lastCompletedSessionsCtrlMenu, setLastCompletedSessionsCtrlMenu] = useState(false);
  const [lastCompletedSessionsEl, setLastCompletedSessionsEl] = useState();

  // const setSidebarMenu = (e, menu) => {
  //   dispatch(setMenu(menu));
  // };

  useEffect(() => {
    getAllWhiteboardTemplate();
    //localStorage.removeItem(storage_configs.localStorage.sessions.currentScreenData);
    if (getCurrentUserData().role == conditional_configs.role.expert) {
      let current_session_id = localStorage.getItem(storage_configs.localStorage.sessions.current_session_id)
      SessionService.getCourseContentBySessionId(current_session_id).then(response => {
        if (response && response.success == "true") {
          var tempContentList = [];
          var tempWhiteboardList = [];
          var tempQuizList = [];
          var tempGameList = [];
          var finalList = [];
          if (response.data.coursecontentsessiondetail.length > 0) {
            tempContentList = response.data.coursecontentsessiondetail.filter(e => e.status == 1).sort((a, b) => Number(a.id) - Number(b.id));
            tempContentList.forEach(element => {
              element.type = conditional_configs.curriculam_types.content;
            });
            //setContentList(response.data.coursecontentsessiondetail.filter(e => e.status == 1).sort((a, b) => Number(a.id) - Number(b.id)));
            const currentActiveContent = localStorage.getItem(storage_configs.localStorage.sessions.show_content);
            if (!currentActiveContent) {
              showContent(response.data.coursecontentsessiondetail.filter(e => e.status == 1).sort((a, b) => Number(a.id) - Number(b.id))[0]);
            } else {
              dispatch(setSelectedContent(currentActiveContent));
            }
          }
          if (response.data && response.data.coursecontentsessionwhiteboard) {
            tempWhiteboardList = response.data.coursecontentsessionwhiteboard.filter(e => e.template_ids != null && e.status == 1);
            tempWhiteboardList.forEach(element => {
              element.type = conditional_configs.curriculam_types.whiteboard;
            });
            //setWhiteboardList(response.data.coursecontentsessionwhiteboard.filter(e => e.template_ids != null && e.status == 1));
          }
          if (response.data && response.data.coursecontentsessionquiz) {
            localStorage.setItem('temp-expert-content-quiz', JSON.stringify(response.data.coursecontentsessionquiz.filter(e => e.status == 1)));
            //setQuizList(getUnique(response.data.coursecontentsessionquiz.filter(e => e.status == 1), 'templateid'));
            tempQuizList = getUnique(response.data.coursecontentsessionquiz.filter(e => e.status == 1), 'templateid');
            tempQuizList.forEach(element => {
              element.type = conditional_configs.curriculam_types.quiz;
            });
          }
          //setGameList(response.data.coursecontentsessiongame.filter(e => e.status == 1));
          tempGameList = response.data.coursecontentsessiongame.filter(e => e.status == 1);
          tempGameList.forEach(element => {
            element.type = conditional_configs.curriculam_types.games;
          });
          finalList = [...tempContentList, ...tempWhiteboardList, ...tempQuizList, ...tempGameList];
          finalList = finalList.sort((a, b) => Number(a.sequence) - Number(b.sequence));
          console.log("content list", finalList)

          setContentList([...finalList]);
          // if (response.data.coursecontentsessiondetail) {
          //   showContent(response.data.coursecontentsessiondetail.filter(e => e.status == 1)[0]);
          // }
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
    // else if (getCurrentUserData().role == conditional_configs.role.learner) {
    //   let membercoursestreamdetailid = localStorage.getItem(storage_configs.localStorage.sessions.membercoursestreamdetailid)
    //   SessionService.getCourseContentBymembercoursestreamdetailid(membercoursestreamdetailid).then(response => {
    //     if (response && response.success == "true") {
    //       console.log(response);

    //       if(response.data.coursecontentsessiondetail.length >0){
    //         setContentList(response.data.coursecontentsessiondetail.filter(e => e.status == 1).sort((a, b) => Number(a.id) - Number(b.id)));
    //         showContent(response.data.coursecontentsessiondetail.filter(e => e.status == 1).sort((a, b) => Number(a.id) - Number(b.id))[0]);
    //       }
    //       if (response.data && response.data.coursecontentsessionwhiteboard) {
    //         setWhiteboardList(response.data.coursecontentsessionwhiteboard.filter(e => e.template_ids != null && e.status == 1));
    //       }
    //       setGameList(response.data.coursecontentsessiongame.filter(e => e.status == 1));
    //       // if (response.data.coursecontentsessiondetail) {
    //       //   showContent(response.data.coursecontentsessiondetail.filter(e => e.status == 1)[0]);
    //       // }
    //     }
    //   })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // } 
    else {
      setContentList([])
    }
    let checkCurrentSession = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
    if (checkCurrentSession) {
      dispatch(setSelectedCurriculamData(checkCurrentSession))
    } else {
      dispatch(setSelectedCurriculamData(null))
    }
  }, []);

  const checkIsEmdedCode = (content) => {
    if (content) {
      const checkStr = content.slice(0, 4);
      if (checkStr == 'http') {
        return false;
      } else {
        return true;
      }
    }
    return false
  }

  /** FIND IS CURRICULAM LINK OR ANY DOCUMENT URL AND DISPATCH AS SELECTED CONTENT */
  const showContent = (data) => {
    let contentDiv;
    dispatch(setIsRecapClicked(false));
    if (data.curriculumlink) {
      localStorage.setItem(storage_configs.localStorage.sessions.show_content, data.curriculumlink)
      dispatch(setSelectedContent(data.curriculumlink));
      dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.content));
      // let checkFormat = checkIsEmdedCode(data.curriculumlink);
      // setTimeout(() => {
      //   if(checkFormat == true){
      //     contentDiv = document.getElementById("content-view-embed-code-div").getElementsByTagName("iframe")[0];
      //   }else{
      //     contentDiv = document.getElementById("content-view-iframe")
      //   }
      //  //let t = parse(stringify(contentDiv))
      //   console.log('test',contentDiv)
      //  // sendExpertCurrentContent(contentDiv);

      // }, 1000);


    } else {
      localStorage.setItem(storage_configs.localStorage.sessions.show_content, data.document.path)
      dispatch(setSelectedContent(data.document.path));
      dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.content));
    }

    setTimeout(() => {
      if (slug) {
        b_room_socket = io(url_configs.breakout_room_server);

        let sendData = {
          slug: slug,
          content: data,
          element: ""
        }
        b_room_socket.emit("on-expert-selected-content", { data: sendData }, (error) => {

        });
      }
    }, 500);

    //window.location.reload();
  }

  const openWhiteboardLink = (obj, e) => {
    e.stopPropagation()
    dispatch(setIsRecapClicked(false));
    dispatch(setSelectedContent(null));
    dispatch(setSelectedCurriculamType(null));
    if (obj && obj.template_ids) {
      let boardData = showBoardsList(obj.template_ids, obj);
      //let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + obj.whiteboard_template.slug + ',-4-Gf1BCSf5ns_M4ChSamw'
      //localStorage.setItem(storage_configs.localStorage.sessions.show_content, tempWLink)
      boardData[0].active = true;
      setTimeout(() => {
        dispatch(setSelectedContent(boardData));
        dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.whiteboard));
      }, 100);
    }
  }

  const openQuiz = (obj, e) => {
    e.stopPropagation();
    dispatch(setIsRecapClicked(false));
    let tempObj = null;
    tempObj = { ...obj };
    if (!tempObj.selectedCurriculamType) {
      tempObj.selectedCurriculamType = conditional_configs.curriculam_types.quiz;
    }

    let getAllQuiz = JSON.parse(localStorage.getItem('temp-expert-content-quiz'));
    if (getAllQuiz && getAllQuiz.length > 0) {
      let getSelectedQuiz = getAllQuiz.filter(e => e.templateid == obj.templateid);
      tempObj.topic = obj.template.name;
      tempObj.level = 'Beginner';
      tempObj.totalQuestions = getSelectedQuiz.length;
      tempObj.perQuestionScore = 5;
      tempObj.totalTime = 60; // in seconds
      tempObj.questions = [];

      for (let i = 0; i < getSelectedQuiz.length; i++) {
        let tempQObj = {
          question: getSelectedQuiz[i].coursequestion.question,
          type: getSelectedQuiz[i].coursequestion.questiontype,
          choices: getSelectedQuiz[i].coursequestion.answerlist,
          correctAnswer: getSelectedQuiz[i].coursequestion.answerlist.filter(e => e.correct == '1')[0]?.id,
        }
        tempObj.questions.push(tempQObj);
      }

      tempObj.quizList = getSelectedQuiz;
      console.log('Opened Quiz', tempObj);
      dispatch(setSelectedContent(null));
      dispatch(setSelectedCurriculamType(null));
      dispatch(setSelectedContent(tempObj));
      dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.quiz));
    }

  }

  const getAllWhiteboardTemplate = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      console.log(response.data);
      setWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const sendExpertCurrentContent = (iContent) => {
    let data = {
      content: iContent
    }
    SessionService.sendExpertCurrentContent(JSON.stringify(data)).then(response => {
      console.log('res i c :::', response);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const showBoardsList = (template_ids, obj) => {
    let boardsList = [];
    if (template_ids) {
      let splitBoardIds = template_ids.split(',');
      if (splitBoardIds && splitBoardIds.length > 0) {
        for (let i = 0; i < splitBoardIds.length; i++) {
          let boardObj = {};
          let actualBoardList = whiteboardTemplateList;
          if (actualBoardList && actualBoardList.length > 0) {
            let newWhiteboardArray = [];
            //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
            actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
              newWhiteboardArray[index] = { ...device }
            })
            boardObj = newWhiteboardArray[0];
            if (boardObj) {
              FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-' + obj.id + '-div-' + slug);
            }
            let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + boardObj.slug + '-div-' + obj.id + '-div-' + slug + whiteboardConfig.key;

            boardObj.whiteboardLink = tempWLink;
            if (getCurrentUserData().role == conditional_configs.role.expert) {
              boardObj.is_locked = false;
            } else {
              boardObj.is_locked = true;
            }

            boardsList.push(boardObj);
          }
        }
      }
    }
    return boardsList;
  }

  const alertOnDisabledActions = (e) => {
    e.stopPropagation();
    let currentGroupName = "";
    let checkCurrentGroupList = JSON.parse(localStorage.getItem("current-groups"));
    if (checkCurrentGroupList && checkCurrentGroupList.length > 0) {
      let checkGroup = checkCurrentGroupList.filter(e => e.coursegroupid == getExpertOpenedCurrentGroupId())[0];
      if (checkGroup) {
        currentGroupName = " " + checkGroup.groupname;
      }
    }
    Swal.fire({
      title: "To initiate another session, please leave the " + currentGroupName + " Group",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Leave",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLeaveGroup(true));
      }
    });
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const openBRoomBoard = (wLink) => {
    if (wLink) {
      let boardSplit = wLink.split('#room=')
      let firstOffBrd = boardSplit[0] + '&bRoom=1#room='
      return firstOffBrd + boardSplit[1]
    }
  }

  const startBreakoutSession = (obj, e, ctype) => {
    dispatch(setIsRecapClicked(false));
    changeBreakoutSessionStatus(true);
    dispatch(setSelectedCurriculam(null));
    dispatch(setSelectedCurriculamData(null));
    dispatch(setBreakoutCurrentScreenData(null));
    dispatch(setBreakoutGameList(null));
    dispatch(setCurrentSubSessionsList([]));

    let tempObj = null;
    localStorage.removeItem(storage_configs.localStorage.sessions.currentScreenData)
    tempObj = { ...obj };
    if (!tempObj.selectedCurriculamType) {
      tempObj.selectedCurriculamType = ctype;
    }
    //If whiteboard
    if (ctype == conditional_configs.curriculam_types.whiteboard) {
      //if whiteboard tagged with multiple templates
      if (obj && obj.template_ids) {
        tempObj.boardData = showBoardsList(obj.template_ids, obj);
        let newWhiteboardArray = [];
        tempObj.boardData.forEach((device, index) => {
          newWhiteboardArray[index] = { ...device }
        })
        newWhiteboardArray[0].active = true;
        tempObj.boardData = newWhiteboardArray;
        setTimeout(() => {
          dispatch(setSelectedCurriculam(tempObj));
          dispatch(setSelectedCurriculamData(tempObj));
          localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempObj))
          dispatch(setBreakoutCurrentScreenData(tempObj));

          let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList));
          if (tempList) {
            tempList.forEach(element => {
              element.image = ctype;
              element.gameLink = "";
              element.isGameOpened = false;
              element.whiteboardLink = "";
              element.whiteboardLink = element.userList.length > 0 ? openBRoomBoard(tempObj.boardData[0].whiteboardLink) : ""
            });
            sleep(200)
            localStorage.setItem(storage_configs.localStorage.sessions.zoom.getSubsessionList, JSON.stringify(tempList));
            dispatch(setCurrentSubSessionsList(tempList));
          }

          let currentGroups = JSON.parse(localStorage.getItem('current-groups'));
          if (currentGroups) {
            //Remove Duplicates from groups
            const tempGroups = [...new Map(currentGroups.map(item => [item['coursegroupid'], item])).values()];

            /** CLONE WHITEBOARD */
            if (tempObj.selectedCurriculamType && tempObj.selectedCurriculamType == conditional_configs.curriculam_types.whiteboard) {
              for (var i = 0; i < tempGroups.length; i++) {
                if (tempObj.boardData && tempObj.boardData.length > 0) {
                  for (var j = 0; j < tempObj.boardData.length; j++) {
                    FirebaseService.cloneDocument("scenes", tempObj?.boardData[j]?.slug, tempObj.boardData[j]?.slug + '-div-' + obj.id + '-div-' + slug + '-div-' + slugify(tempGroups[i].groupname));
                  }
                }
              }
            }
          }
        }, 300);
      } else {
        tempObj.boardData = [];

        //If whiteboard not tagged with mutiple templates. Which means template_ids is null.
        setTimeout(() => {
          dispatch(setSelectedCurriculam(tempObj));
          dispatch(setSelectedCurriculamData(tempObj));
          localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempObj))
          dispatch(setBreakoutCurrentScreenData(tempObj));

          let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList));
          if (tempList) {
            tempList.forEach(element => {
              let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(obj.group_name) + '-div-' + slugify(obj.id) + '-div-' + element.group_id + whiteboardConfig.key;

              element.image = ctype;
              element.gameLink = "";
              element.isGameOpened = false;
              element.whiteboardLink = "";
              element.whiteboardLink = element.userList.length > 0 ? openBRoomBoard(tempWLink) : ""
            });
            sleep(200)
            localStorage.setItem(storage_configs.localStorage.sessions.zoom.getSubsessionList, JSON.stringify(tempList));
            dispatch(setCurrentSubSessionsList(tempList));
          }
        }, 300);
      }
    } else if (ctype == conditional_configs.curriculam_types.quiz) {
      let getAllQuiz = JSON.parse(localStorage.getItem('temp-expert-content-quiz'));
      if (getAllQuiz && getAllQuiz.length > 0) {
        let getSelectedQuiz = getAllQuiz.filter(e => e.templateid == obj.templateid);
        console.log(ctype, getSelectedQuiz);
        tempObj.quizList = getSelectedQuiz;

        tempObj.topic = obj.template.name;
        tempObj.level = 'Beginner';
        tempObj.totalQuestions = getSelectedQuiz.length;
        tempObj.perQuestionScore = 5;
        tempObj.totalTime = 60; // in seconds
        tempObj.questions = [];

        for (let i = 0; i < getSelectedQuiz.length; i++) {
          let tempQObj = {
            question: getSelectedQuiz[i].coursequestion.question,
            type: getSelectedQuiz[i].coursequestion.questiontype,
            choices: getSelectedQuiz[i].coursequestion.answerlist,
            correctAnswer: getSelectedQuiz[i].coursequestion.answerlist.filter(e => e.correct == '1')[0]?.id,
          }
          tempObj.questions.push(tempQObj);
        }
        dispatch(setSelectedCurriculam(null));
        dispatch(setSelectedCurriculam(tempObj));
        dispatch(setSelectedCurriculamData(tempObj));
        localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempObj))
        dispatch(setBreakoutCurrentScreenData(tempObj));
        let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList));

        if (tempList) {
          tempList.forEach(element => {
            //if (screenImg.room == element.subsessionName) {
            element.image = ctype;
            element.gameLink = "";
            element.isGameOpened = false;
            element.whiteboardLink = "";
          });
          sleep(200);
          localStorage.setItem(storage_configs.localStorage.sessions.zoom.getSubsessionList, JSON.stringify(tempList));
          dispatch(setCurrentSubSessionsList(tempList));
        }
      }


    }
    else {
      tempObj.screentype = 'gamesettings';
      dispatch(setSelectedCurriculam(null));
      dispatch(setSelectedCurriculam(tempObj));
      dispatch(setSelectedCurriculamData(tempObj));
      let tempGList = games.gameList.filter(e => e.id == tempObj.puzzleid);
      localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempObj))
      dispatch(setBreakoutCurrentScreenData(tempObj));
      dispatch(setBreakoutGameList(tempGList));
      let tempList = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList));
      if (tempList) {
        tempList.forEach(element => {
          //if (screenImg.room == element.subsessionName) {
          element.image = ctype;
          element.gameLink = "";
          element.isGameOpened = true;
          element.whiteboardLink = "";
          //element.whiteboardLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(element?.subsessionName) + ',-4-Gf1BCSf5ns_M4ChSamw'
          //element.whiteboardLink = url_configs.white_board_server + element?.subsessionName + '?name=' + getCurrentUserData().name
          //}
        });
        sleep(200);
        localStorage.setItem(storage_configs.localStorage.sessions.zoom.getSubsessionList, JSON.stringify(tempList));
        dispatch(setCurrentSubSessionsList(tempList));
      }
    }
    if (breakoutRoomStatus == 'STARTED') {
      setTimeout(() => {
        if (slug) {
          b_room_socket = io(url_configs.breakout_room_server);

          b_room_socket.emit("join", { name: getCurrentUserData().name, sessionSlug: slug, room: null, currentScreenData: tempObj, role: 1 }, (error) => {

          });
        }
      }, 1000);
    }

  }

  const startBreakoutSessionAlert = (obj, e, ctype) => {
    Swal.fire({
      title: error_messages.expertBreakoutBeforeWarning,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.isConfirmed) {
        startBreakoutSession(obj, e, ctype)
      }
    });
  }

  const onClickWhiteboard = () => {
    alert("Whiteboard is locked.")
  }

  const getGameIcon = (gId) => {
    let tempGameList = games.gameList.filter(e => e.id == gId)[0];
    if (tempGameList) {
      return tempGameList.img;
    }
  }

  const openGameSettings = (obj, e, ctype) => {
    e.stopPropagation();
    dispatch(setBreakoutCurrentScreenData(null));
    let tempObj = null;
    localStorage.removeItem(storage_configs.localStorage.sessions.currentScreenData)
    tempObj = { ...obj };
    if (!tempObj.selectedCurriculamType) {
      tempObj.selectedCurriculamType = ctype;
    }
    setTimeout(() => {
      tempObj.screentype = 'gamesettings';
      dispatch(setBreakoutCurrentScreenData(tempObj));
      localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempObj))
    }, 200);
    //window.location.reload();
  }

  //#region RECAP
  const recapOptionMenuClickOpen = (event) => {
    const checkCompletedSessions = getCompletedSessions();
    if (checkCompletedSessions) {
      //setLastCompletedSessionsEl(event.currentTarget);
      SetPreviousSessions(checkCompletedSessions.sort((a, b) => Number(a.id) - Number(b.id)));
      //setLastCompletedSessionsCtrlMenu(true);
    }
  };

  const lastCompletedSessionsClickClose = () => {
    setLastCompletedSessionsCtrlMenu(false);
  };

  const handleOpenPreSession = () => {
    if (!selectedGroup) {
      return enqueueSnackbar('Please a group', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
    openGroupSpace(selectedCompletedSession);
  }

  const openLastCompletedSessions = (e, obj) => {
    SetSelectedSessionId(obj.coursesessionid);
    if (obj && obj.memberCourseStreamDetails.length > 0) {
      const tempDetails = obj.memberCourseStreamDetails.sort((a, b) => Number(b.id) - Number(a.id)).slice(0, 3);
      SetLastCompletedSessions(tempDetails);
      getCourseGroupDetail(obj.coursestreamId);
      setOpenCompletedSessions(true);
      SetSelectedCompletedSession(tempDetails[0]);
    }
  }

  const getCourseGroupDetail = (streamId) => {
    MemberService.getMemberCourseGroupByStreamIdNew(streamId).then(
      (response) => {
        if (response.data && response.data.length > 0) {
          SetCourseGroups(response.data);
        }
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  const handleSelectedCourseGroupChange = (e) => {
    if (e && e.target.value) {
      const gId = e.target.value;
      console.log("e.target.value", e.target.value);
      SetSelectedGroup(courseGroups.filter(e => e.coursegroupid == gId));
    }
  }

  const handleOnSelectCompletedSession = (e, obj) => {
    SetSelectedCompletedSession(obj);
  }

  const handleCloseCompletedSessionsPopup = () => {
    setOpenCompletedSessions(false);
  };

  const openGroupSpace = (obj) => {
    dispatch(setIsRecapClicked(false));
    dispatch(setIsRecapOpened(false));
    let tempDetail = obj;
    const curriculamDetail = getCurriculumDetail();
    if (curriculamDetail) {
      let cData = curriculamDetail;
      let getCurriculamDetailBySession = [];
      cData.filter(e => e.id == selectedSessionId).forEach((device, index) => {
        getCurriculamDetailBySession[index] = { ...device }
      });
      if (getCurriculamDetailBySession[0]) {
        //WHITEBOADS 
        let sessionBoards = getCurriculamDetailBySession[0].coursesession_whiteboards;

        if (sessionBoards && sessionBoards.length > 0) {
          setTimeout(() => {
            let boardData = [];
            console.log(sessionBoards);
            boardData = showBoardsForRecapList(sessionBoards, tempDetail.slug, false);
            localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_boards, JSON.stringify(boardData));
            handleCloseCompletedSessionsPopup();
            dispatch(setIsRecapClicked(true));
          }, 100);
        }
      }
    }
  }

  const showBoardsForRecapList = (contentBoards, slug, isClone) => {
    let lgrp = selectedGroup[0];
    let boardsList = [];
    if (contentBoards) {
      for (let h = 0; h < contentBoards.length; h++) {
        if (contentBoards[h] && contentBoards[h].template_ids) {
          let splitBoardIds = contentBoards[h].template_ids.split(',');
          if (splitBoardIds && splitBoardIds.length > 0) {
            for (let i = 0; i < splitBoardIds.length; i++) {
              let boardObj = {};
              let actualBoardList = whiteboardTemplateList;
              if (actualBoardList && actualBoardList.length > 0) {
                let newWhiteboardArray = [];
                //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
                actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
                  newWhiteboardArray[index] = { ...device }
                })
                boardObj = newWhiteboardArray[0];
                let tempWLink = "";

                if (isClone == true && !lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-attended-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-attended-' + getCurrentUserData().id + whiteboardConfig.key;
                } else if (isClone == true && lgrp.groupname) {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                } else if (!lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id + whiteboardConfig.key;
                } else {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                }


                boardObj.whiteboardLink = tempWLink;
                if (getCurrentUserData().role == conditional_configs.role.expert) {
                  boardObj.is_locked = false;
                } else {
                  boardObj.is_locked = true;
                }
                boardsList.push(boardObj);
              }
            }
          }
        }
      }

    }
    return boardsList;
  }

  //#endregion

  const setSidebarMenu = (e, menu) => {
    dispatch(setIsFluid(false));
    dispatch(setMenu(menu));
    if(menu == 'chat'){
      localStorage.removeItem(storage_configs.localStorage.sessions.chatGeneralNotification);
      dispatch(setIsChatGeneralNotification(false))
    }
  };

  return (
    <div className="e-host-course-list-v2">
      <div className="p-2 e-courses-list-main-v2">
        {/* PDF, PPT CONTENT */}
        {/* {breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true ?  */}
        <ul className="course_list_ul p-0 mb-0">
          <li key={`e-pre-session-recap-`} className="course_list_li mt-1 mb-1" onClick={(e) => recapOptionMenuClickOpen(e)}>
            <Accordion id={'e-pre-sessions-recap-accordion'}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={'e-pre-sessions-recap-accordion-summary'}
                variant="outlined"
              >
                <Typography>Recap</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="course_list_ul p-0">
                  {previousSessions.map((sObj, sIndex) => {
                    return (
                      <li className="course_list_li p-2" key={'expert-course-group-recap-' + sIndex} id={"e-group-chat" + sIndex} value={sObj.id} onClick={(e) => openLastCompletedSessions(e, sObj)}>
                        Session - {(sIndex + 1)}
                      </li>
                    )
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>
          </li>
          {contentsList?.map((contentObj, contentIndex) => {
            if (contentObj.type == conditional_configs.curriculam_types.content && (breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true)) {
              return (
                <li key={`course-list-li-content-key-` + contentIndex} className="course_list_li" onClick={(e) => showContent(contentObj)}>
                  <div className="host-course-item d-flex align-items-center">
                    <div>
                      <img src={Book_icon_img} alt="" className="me-3" />
                    </div>
                    <div className="course_list_li_text">{contentObj.subject}</div>
                  </div>
                </li>
              )
            }
            else if (contentObj.type == conditional_configs.curriculam_types.games) {
              return (
                <li key={'course_list_li_g' + contentIndex} className={selectedCurriculamData && ('e-content-game-' + selectedCurriculamData.id) == ('e-content-game-' + contentObj.id) ? 'active-session course_list_li' : 'course_list_li'} >
                  <div className="host-course-item d-flex align-items-center ps-0">
                    <div>
                      <img width="33px" src={getGameIcon(contentObj?.puzzleid)} alt="" className="me-3" />
                    </div>
                    <div className="course_list_li_text flex-between">
                      <span>{contentObj?.name}</span>
                      {
                        getCurrentUserData().role == conditional_configs.role.expert ?
                          <span>
                            {
                              //ACTIVE PLAY BUTTON
                              !selectedCurriculamData || (selectedCurriculamData && selectedCurriculamData.id !== contentObj.id) && !getExpertOpenedCurrentGroupId() ?
                                <span className="play-btn-span" onClick={(e) => breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true ? startBreakoutSessionAlert(contentObj, e, conditional_configs.curriculam_types.games) : startBreakoutSession(contentObj, e, conditional_configs.curriculam_types.games)}>
                                  {getIcon(icons_config.play_btn)}
                                </span> :
                                // DISABLED PLAY BUTTONS
                                !selectedCurriculamData || (selectedCurriculamData && selectedCurriculamData.id !== contentObj.id) && getExpertOpenedCurrentGroupId() ?
                                  <span className="play-btn-span-disabled" onClick={(e) => alertOnDisabledActions(e)} >
                                    {getIcon(icons_config.play_btn)}
                                  </span> :
                                  //ACTIVE SETTINGS BUTTON
                                  selectedCurriculamData && selectedCurriculamData.id == contentObj.id && !getExpertOpenedCurrentGroupId() ?
                                    <span className="play-btn-span" onClick={(e) => openGameSettings(contentObj, e, conditional_configs.curriculam_types.games)} >
                                      {getIcon(icons_config.cog)}
                                    </span> :
                                    //DISABLED SETTINGS BUTTONS
                                    selectedCurriculamData && selectedCurriculamData.id == contentObj.id && getExpertOpenedCurrentGroupId() ?
                                      <span className="play-btn-span-disabled" onClick={(e) => alertOnDisabledActions(e)} >
                                        {getIcon(icons_config.cog)}
                                      </span> : ""
                            }
                          </span>
                          : ""
                      }
                    </div>
                  </div>
                </li>
              )
            }
            else if (contentObj.type == conditional_configs.curriculam_types.quiz) {
              return (
                <li key={'course_list_li_q' + contentIndex} className={selectedCurriculamData && ('e-content-quiz-' + selectedCurriculamData.id) == ('e-content-quiz-' + contentObj.id) ? 'active-session course_list_li' : 'course_list_li'} onClick={(e) => openQuiz(contentObj, e)}>
                  <div className="host-course-item d-flex align-items-center">
                    <div>
                      <img src={assets.quiz} alt="" className="me-3" />
                    </div>
                    <div className="course_list_li_text flex-between">
                      <span>{contentObj?.template?.name}</span>
                      {
                        getCurrentUserData().role == conditional_configs.role.expert && !getExpertOpenedCurrentGroupId() ?
                          <span className="play-btn-span" onClick={(e) => breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true ? startBreakoutSessionAlert(contentObj, e, conditional_configs.curriculam_types.quiz) : startBreakoutSession(contentObj, e, conditional_configs.curriculam_types.quiz)} >{getIcon(icons_config.play_btn)}</span>
                          :
                          getCurrentUserData().role == conditional_configs.role.expert && getExpertOpenedCurrentGroupId() ?
                            <span className="play-btn-span-disabled" onClick={(e) => alertOnDisabledActions(e)} >{getIcon(icons_config.play_btn)}</span> : ""
                      }
                    </div>
                  </div>
                </li>
              )
            }
            else if (contentObj.type == conditional_configs.curriculam_types.whiteboard) {
              return (
                <li key={'course_list_li_w' + contentIndex} className={selectedCurriculamData && ('e-content-whiteboard-' + selectedCurriculamData.id) == ('e-content-whiteboard-' + contentObj.id) ? 'active-session course_list_li' : 'course_list_li'} onClick={(e) => openWhiteboardLink(contentObj, e)}>
                  <div className="host-course-item d-flex align-items-center">
                    <div>
                      <img src={Whiteboard_Icon} alt="" className="me-3" />
                    </div>
                    <div className="course_list_li_text flex-between">
                      <span>{contentObj?.group_name}</span>
                      {
                        getCurrentUserData().role == conditional_configs.role.expert && !getExpertOpenedCurrentGroupId() ?
                          <span className="play-btn-span" onClick={(e) => breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true ? startBreakoutSessionAlert(contentObj, e, conditional_configs.curriculam_types.whiteboard) : startBreakoutSession(contentObj, e, conditional_configs.curriculam_types.whiteboard)}>{getIcon(icons_config.play_btn)}</span>
                          :
                          getCurrentUserData().role == conditional_configs.role.expert && getExpertOpenedCurrentGroupId() ?
                            <span className="play-btn-span-disabled" onClick={(e) => alertOnDisabledActions(e)}>{getIcon(icons_config.play_btn)}</span> : ""
                      }
                    </div>
                  </div>
                </li>
              )
            }
          })}
        </ul>
        {/* : ""} */}
      </div>

      {/* COMPLETED SESSIONS POPUP */}
      <Dialog
        open={openCompletedSessions}
        onClose={handleCloseCompletedSessionsPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          {"Choose from last three sessions"}
          <span className="add_kids_close_icon" onClick={handleCloseCompletedSessionsPopup}>
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex">
            {lastCompletedSessions?.map((obj, idx) => {
              return (
                <div key={`last-completed-sessions-key-` + obj?.id} onClick={(e) => handleOnSelectCompletedSession(e, obj)}>
                  <div className={`l-pods-streams-boxes l-pods-streams-boxes-width ${selectedCompletedSession && selectedCompletedSession.id == obj.id ? 'e-pods-selected' : 'e-pods-not-selected'}`}>
                    <div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("dddd").slice(0, 3)}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("MMM Do")}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("LT")} - {moment(obj?.enddatetime).format("LT")}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Grid item xs={12} sm={12}>
            <FormControl style={{ width: "100%" }} className="mt-3">
              <InputLabel
                id={"course-groups"}
                variant="outlined"
                margin="dense"
              >
                Groups
              </InputLabel>
              <Select
                labelId={"course-groups"}
                id={"course-groups"}
                name={"course-groups-name"}
                label="Groups"
                margin="dense"
                variant="outlined"
                onChange={event => handleSelectedCourseGroupChange(event)}
              >
                {courseGroups.map((sObj, sIndex) => {
                  return (
                    <MenuItem key={'expert-course-groups-' + sIndex} value={sObj.coursegroupid}>{sObj?.groupname}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog_action">
          <Button variant="contained" className="add_new_quiz_btn" onClick={(e) => handleOpenPreSession()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default CourseList;
