
const storage_configs = {
    localStorage: {
        admin: {
            courses: {
                courseId: "courseId",
                currentStep: 'step'
            }
        },
        sessions: {
            course_detail: 'course_detail',
            learner_grp_detail: 'learner_grp_detail',
            group_space: {
                gsp_boards: 'gsp_boards',
                gsp_games: 'gsp_games',
                gsp_quiz: 'gsp_quiz'
            },
            membercoursestreamlearnerdetailsid: 'membercoursestreamlearnerdetailsid',
            membercoursestreamdetailid: 'membercoursestreamdetailid',
            current_session_id: 'current_session_id',
            current_stream_id: 'current_stream_id',
            current_group_id: 'current_group_id',
            show_content: 'show_content',
            group_space_selected_tab: 'group_space_selected_tab',
            current_learner_groupname: 'current_learner_groupname',
            currentZoomUserId: 'currentZoomUserId',
            currentGame: 'current_game',
            currentScreenData: 'current_screen_data',
            completedSessions: 'completed_sessions',
            curriculumDetail: 'curriculum_detail',
            whiteboardTemplates: 'whiteboard_templates',
            zoom: {
                signature: 'zoom-signature',
                user: 'zoom-current-user',
                participants: 'zoom-participants',
                isZoomIntiated: 'zoom-initiated',
                getSubsessionList: 'zoom-sub-sessions-list',
                zmClient: 'zoom-client',
                isStartedAudio: 'is-zoom-audio-started',
                isAudioMuted: 'is-zoom-audio-muted',
                firstTimeInteracted: 'is-first-time-interacted',
                waitingParticipants: 'waiting-participants',
                currentSessionSlug: 'current-session-slug'
            },
            break_out_session: {
                status: 'break_out_status',
                captured_img: 'break_out_captured_img',
                isNotification: 'breakout_session_notification',
                stopBreakoutLoading: 'stop-breakout-loading'
            },
            chatNotification: 'chatNotification',
            chatGeneralNotification: 'chatGeneralNotification',
            raisedNotificationList: 'raisedNotificationList',
            notificationsList: 'notifications-list',
            networkStatus: 'network-status',
            networkTimerStatus: 'network-timer-status',
            openedChatGroup: 'chat-opened-group',
            chats: 'chats',
            enableSpeakToGroup: 'speak-to-group',
            quizResults: 'quiz-results',
            e_participants_h_idx: 'e-participant-videos-canvas-horizontal-view',
            l_participants_v_idx: 'l-participant-videos-canvas-vertical-view',
            currentActiveContent: 'current-active-content',
            selectedCardDescriptionDetails: 'selected-card-description-details'
        }
    }
};

export default storage_configs;