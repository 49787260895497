import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { Avatar } from "@mui/material";

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  //   min-height: 86px;
  border-radius: 8px;
  max-width: 350px;
  /* background: ${({ isDragging }) =>
    isDragging ? "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: rgb(255 255 255);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  color: black;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

// TaskCard

const TaskCard = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TaskInformation>
            <p className='m-0'>{item.firstname}</p>
            {/* <p className="mt-1 mb-1 task__description">{item.taskdescription}</p> */}
            {/* <div className="secondary-details">

            </div> */}
          </TaskInformation>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
