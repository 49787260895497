import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import "./become-an-expet.css";
import * as Yup from "yup";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function BecomeAnExpert() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        className="contact-email-form"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="become-title">
            Do you want to become an Expert at Meeku?
          </span>
          <span className="close-btn" onClick={handleClose}>
            X
          </span>

          <p className="become-subtitle">
            Let us know your details and we will contact you!
          </p>
        </DialogTitle>
        <DialogContent>
          <div className="">
            <form autoComplete={"off"}>
              <div className="form-group mb-3">
                <div class="input-group">
                  <lable className="con-email-text">Contact email:</lable>
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    margin="dense"
                    {...register("email")}
                    error={errors.email ? true : false}
                  />
                </div>
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  className="err-msg"
                >
                  {errors.email?.message}
                </Typography>
              </div>

              <div className="form-group mb-3">
                <lable>Tell a few words about yourself</lable>
                <div class="input-group">
                  <textarea></textarea>
                </div>
              </div>

              <div className="">
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  submit
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
