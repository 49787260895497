import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { setIsFluid, setIsMaximized } from "../../store/slices/settingsSlice";

import { getBreakoutSessionStatus } from "../../utils/util";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 82;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const MainStyleInBreakoutSession = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: "122px",
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const breakoutRoomStatus = useSelector((state) => state.settings.breakoutRoomStatus);
  const isFluid = useSelector((state) => state.settings.isFluid);
  const isMaximized = useSelector((state) => state.settings.isMaximized);
  
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
     {breakoutRoomStatus == 'STARTED' || getBreakoutSessionStatus() == true ?
      <MainStyleInBreakoutSession>
        <Outlet />
      </MainStyleInBreakoutSession>:
      <MainStyle>
      <Outlet />
    </MainStyle>}
    </RootStyle>
  );
}
