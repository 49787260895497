import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./my-courses.css";
import Swal from "sweetalert2";
//#region MUI MATERIALS
import {
  Avatar,
  Button,
  Badge,
  Container,
  Grid,
  Card,
  Paper,
  FormControl,
  Select,
  InputLabel,
  InputBase,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LinearProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

//#endregion

//#region MUI ICON MATERIALS
import IosShareIcon from "@mui/icons-material/IosShare";
import DoneIcon from "@mui/icons-material/Done";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";
import CloseIcon from "@mui/icons-material/Close";
//#endregion

import { SnackbarProvider, useSnackbar } from "notistack";
import moment from "moment-timezone";
import slugify from "react-slugify";

//COMPONENTS
import Iconify from "../../../components/Iconify";
import account from "../../../_mock/account";
import NoRecordFound from "../../../components/no-record-found/no-record-found";

//SERVICES
import ParentAPIService from "../../../services/parent-service";
import MemberAPIService from "../../../services/member-service";

import {
  getCurrentUserData,
  getUnique,
  getCharacterValidationError,
} from "../../../utils/util";

//CONFIGS
import icons_config from "../../../config/icons";
import assets from "../../../config/assets";
import conditional_configs from "../../../config/conditional_config";
import error_messages from "../../../config/error_messages";
import TourManager from "../../../components/tour-manager/tour-manager";
import TooltipWithArrow from "../../../components/tooltip-with-arrow/tooltip-with-arrow";
import ShareLicensesService from "../../../services/share-licenses-service";

//kids_table

function Courses_detail_list(courseName, assignedKid, subscribedDate) {
  return { courseName, assignedKid, subscribedDate };
}

// categories box icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//mobile

const Puller = styled(Box)(({ theme }) => ({
  width: 90,
  height: 6,
  background: "#818694",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 50px)",
}));

//#region EXPORT FUNCTION MYCOURSES STARTS
export default function MyCourses(props) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const [searchQuery, setSearchQuery] = useState("");

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [kidslist, setkidslist] = useState([]);
  const [SelectCourseData, setSelectCourseData] = useState([]);
  const [courseTableData, setcourseTableData] = useState([]);
  const [filteredCourseTableData, setFilteredCourseTableData] = useState([]);
  const [inProgressCourseTableData, setInProgressCourseTableData] = useState(
    []
  );
  const [
    filteredInProgressCourseTableData,
    setFilteredInProgressCourseTableData,
  ] = useState([]);
  const [completedCourseTableData, setCompletedCourseTableData] = useState([]);
  const [
    filteredCompletedCourseTableData,
    setFilteredCompletedCourseTableData,
  ] = useState([]);
  //const [badgeData, setBadgeData] = useState([0]);
  //const ParentData = JSON.parse(localStorage.getItem('user'));

  const [openAddKid, setOpenAddKid] = React.useState(false);
  const [selectedCourseStream, setSelectedCourseStream] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const [inProgressAnchorEl, setInProgressAnchorEl] = React.useState(null);
  const inProgressOpenMenu = Boolean(inProgressAnchorEl);

  const [dropDownPodList, setDropDownPodList] = useState([]);

  const [completedAnchorEl, setCompletedAnchorEl] = React.useState(null);
  const completedOpenMenu = Boolean(completedAnchorEl);

  const [expandCourseAccordion, setExpandCourseAccordion] = useState();

  //STATUS TAB
  const [activeTab, setActiveTab] = useState(1);

  //PARENT COMPLETION ITEMS
  const [items, setItems] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(25);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  //To get Table value and kids value
  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.parent
    ) {
      ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id)
        .then((response) => {
          //setmyKidsList(response.data);
          console.log(response.data);
          setkidslist(response.data);
        })
        .catch((e) => {
          console.log(e);
        });

      ParentAPIService.getCourseByParentId(getCurrentUserData().id)
        .then((response) => {
          console.log("getCourseByParentId", response.data);
          setcourseTableData(response.data);
          if (response.data && response.data.length > 0) {
            const checkBoughtAnyCourse = response.data.filter(
              (e) => Number(e.amount) > 0
            );
            const kidCheckList = response.data.filter(
              (e) => e.kidDetail.length > 0
            );
            if (checkBoughtAnyCourse.length > 0) {
              var parentCompletionList_2 = JSON.parse(
                JSON.stringify(conditional_configs.parentCompletionList_2)
              );
              if (kidCheckList.length > 0) {
                parentCompletionList_2.forEach((element) => {
                  element.active = true;
                });
                setItems(parentCompletionList_2);
                setCompletionPercentage(100);
              } else {
                setItems(conditional_configs.parentCompletionList_2);
              }
            } else {
              var parentCompletionList_1 = JSON.parse(
                JSON.stringify(conditional_configs.parentCompletionList_1)
              );
              if (kidCheckList.length > 0) {
                parentCompletionList_1.forEach((element) => {
                  element.active = true;
                });
                setTimeout(() => {
                  setItems(parentCompletionList_1);
                  setCompletionPercentage(100);
                }, 100);
              } else {
                setItems(conditional_configs.parentCompletionList_1);
              }
            }
          }
          splitCoursesAsTwoLists(response.data);
          //setFilteredCourseTableData(response.data);
          setFilteredCourseTableData(
            getUnique(response.data, "coursestreamid")
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      localStorage.clear();
      navigate("/parent-login");
    }
  }, []);

  //#region ASSIGN KID
  const [open, setOpen] = useState(false);

  const validate = Yup.object().shape({
    selectcourse: Yup.string().required("Select course "),
    selectkid: Yup.string().required("Select Kid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  });

  const handleClickAssign = (obj, getFrom) => {
    setOpen(true);
    //Get List of kids
    ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id)
      .then((response) => {
        //setmyKidsList(response.data);
        setkidslist(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    const tempCourseList =
      getFrom == "inprogress"
        ? inProgressCourseTableData.filter((e) => e.courseid == obj.courseid)
        : completedCourseTableData.filter((e) => e.courseid == obj.courseid);
    var pods = getUnique(tempCourseList, "coursestreamid");
    setSelectCourseData(pods);

    //Get course by parent id

    // ParentAPIService.getCourseByParentId(getCurrentUserData().id)
    //   .then(response => {
    //     console.log(response.data);
    //     if(response.data && response.data.length >0){

    //       const tempCourseList = getFrom == 'inprogress' ? inProgressCourseTableData.filter(e => e.courseid == obj.courseid) : completedCourseTableData.filter(e => e.courseid == obj.courseid);
    //       var pods = getUnique(tempCourseList, 'coursestreamid');
    //       setSelectCourseData(pods);
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   });
  };

  const handleClickDone = (data) => {
    console.log(data);
    const userId = JSON.parse(localStorage.getItem("user"));
    const parentid = userId.id;
    const assignData = {
      parentid: parentid.toString(),
      learnerid: data.selectkid,
      coursestreamid: data.selectcourse,
    };
    MemberAPIService.courseAssignToKid(assignData)
      .then((response) => {
        if (response && response.success === "true") {
          console.log(response.data);
          enqueueSnackbar("Course assinged Sucessfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setOpen(false);
          window.location.reload();
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
        // setcourseTableData(response.data.courseinfo);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;

    setSearchQuery(query);
    // Filter the list of courses based on the search query
    const fCourses = courseTableData.filter((cours) =>
      cours.coursename.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCourseTableData(fCourses);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToCourseDetail = (obj) => {
    console.log(obj);
    if (obj && obj.courseid) {
      window.open(
        "/course-detail/" + slugify(obj.coursename) + "-" + obj.courseid
      );
    }
  };
  //#endregion

  //#region ADD KID

  const kidsSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    username: Yup.string().required("User name is required"),
    selectcourse: Yup.string().required("User name is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: kidsFormReg,
    control: kidsFormCtrl,
    handleSubmit: kidsFormSubmit,
    setValue,
    formState: { errors: kidsFormErrors },
  } = useForm({
    resolver: yupResolver(kidsSchema),
  });

  const handleClickOpen = (obj) => {
    setOpenAddKid(true);
    setSelectedCourseStream(null);
    // const tempCourseList = getFrom == 'inprogress' ? inProgressCourseTableData.filter(e => e.courseid == obj.courseid) : completedCourseTableData.filter(e => e.courseid == obj.courseid);
    //       var pods = getUnique(tempCourseList, 'coursestreamid');
    setSelectCourseData(obj);
    // ParentAPIService.getCourseByParentId(getCurrentUserData().id)
    //   .then(response => {
    //     setSelectCourseData(response.data);
    //     setSelectedCourseStream(obj);
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   });
  };

  const handleCloseAddKid = () => {
    setOpenAddKid(false);
  };

  const onAddSubmit = (data) => {
    //  setChangeLabel(true);
    // setChangeButton(true);
    const parentid = getCurrentUserData().id;
    var addKidData = {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      email: getCurrentUserData().email,
      password: data.password,
      parentid: parentid.toString(),
      relationship: "2",
      coursestreamid: data.selectcourse,
    };
    console.log("submit add kid::", addKidData);
    console.log("submit add kid:data:", data);
    ParentAPIService.addKid(addKidData)
      .then((response) => {
        if (response && response.success === "true") {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar("Kids Added Sucessfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          handleCloseAddKid();
          window.location.reload();
          setSelectedCourseStream(null);
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  //#region COURSE CARD FUNCTIONALTIES

  const splitCoursesAsTwoLists = async (courses) => {
    if (courses && courses.length > 0) {
      var inProgressList = [];
      var completedList = [];
      for (var i = 0; i < courses.length; i++) {
        if (
          (await checkIsInProgressSessions(courses[i].courseStreamDetail)) ==
          true
        ) {
          inProgressList.push(courses[i]);
        } else {
          //inProgressList.push(courses[i]);
          completedList.push(courses[i]);
        }
      }
      setInProgressCourseTableData(inProgressList);
      setCompletedCourseTableData(completedList);
      setTimeout(async () => {
        console.log("inprogress", inProgressList);
        console.log("completed", completedList);
        setFilteredInProgressCourseTableData(
          getUnique(inProgressList, "courseid")
        );
        setFilteredCompletedCourseTableData(
          getUnique(completedList, "courseid")
        );
      }, 100);
    }
  };

  const getCompletedSessions = (courseStreamDetail) => {
    let completedsessions = 0;
    let nextsessions = [];

    if (courseStreamDetail) {
      for (let i = 0; i < courseStreamDetail.length; i++) {
        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length > 0
        ) {
          if (courseStreamDetail[i].memberCourseStreamDetails[0].enddatetime) {
            completedsessions = completedsessions + 1;
          }
        }

        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length == 0
        ) {
          nextsessions.push(courseStreamDetail[i]);
        }
      }
    }
    return {
      completedsessions: completedsessions,
      nextsessions: nextsessions[0],
    };
  };

  //CHECK THE COURSE STREAM DETAIL ARE IN PROGRESS
  const checkIsInProgressSessions = async (courseStreamDetail) => {
    if (courseStreamDetail) {
      for (let i = 0; i < courseStreamDetail.length; i++) {
        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length > 0
        ) {
          if (
            courseStreamDetail[i].memberCourseStreamDetails[0].startdatetime &&
            !courseStreamDetail[i].memberCourseStreamDetails[0].enddatetime
          ) {
            return true;
          }
        }

        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length == 0
        ) {
          return true;
        }
      }
    }
  };

  //GET TOTAL LICENSE COUNT BY COURSE ID
  const getLicenseCountByCourseId = (obj, getFrom) => {
    if (obj) {
      var lic = 0;
      const filteredList =
        getFrom == "inprogress"
          ? inProgressCourseTableData.filter((e) => e.courseid == obj.courseid)
          : completedCourseTableData.filter((e) => e.courseid == obj.courseid);
      for (var i = 0; i < filteredList.length; i++) {
        lic = lic + filteredList[i].license;
      }
      return lic;
    }
  };

  //GET TOTAL LICENSE COUNT BY COURSE STREAM ID
  const getLicenseCountByCourseStreamId = (obj, getFrom) => {
    if (obj) {
      var lic = 0;
      const filteredList =
        getFrom == "inprogress"
          ? inProgressCourseTableData.filter(
              (e) => e.coursestreamid == obj.coursestreamid
            )
          : completedCourseTableData.filter(
              (e) => e.coursestreamid == obj.coursestreamid
            );
      for (var i = 0; i < filteredList.length; i++) {
        lic = lic + filteredList[i].license;
      }
      return lic;
    }
  };

  //GET KIDS COUNT BY COURSE ID
  const getKidsCountByCourseId = (obj, getFrom) => {
    const tempCourseList =
      getFrom == "inprogress"
        ? inProgressCourseTableData.filter((e) => e.courseid == obj.courseid)
        : completedCourseTableData.filter((e) => e.courseid == obj.courseid);
    if (tempCourseList.length > 0) {
      var assignedKids = 0;
      var tempStreamId = 0;
      for (var c = 0; c < tempCourseList.length; c++) {
        if (tempCourseList[c].coursestreamid != tempStreamId) {
          tempStreamId = tempCourseList[c].coursestreamid;
          assignedKids = assignedKids + tempCourseList[c].kidDetail.length;
        }
      }
      return assignedKids;
    }
  };

  //GET KIDS COUNT BY COURSE STREAM ID
  const getKidsCountByStreamId = (obj, getFrom) => {
    const tempCourseList =
      getFrom == "inprogress"
        ? inProgressCourseTableData.filter(
            (e) => e.coursestreamid == obj.coursestreamid
          )
        : completedCourseTableData.filter(
            (e) => e.coursestreamid == obj.coursestreamid
          );
    if (tempCourseList.length > 0) {
      var assignedKids = 0;
      var tempStreamId = 0;
      for (var c = 0; c < tempCourseList.length; c++) {
        if (tempCourseList[c].coursestreamid != tempStreamId) {
          tempStreamId = tempCourseList[c].coursestreamid;
          assignedKids = assignedKids + tempCourseList[c].kidDetail.length;
        }
      }
      return assignedKids;
    }
  };

  //GET PODS BY COURES ID
  const getPodsByCourseId = async (obj, getFrom) => {
    const tempCourseList =
      getFrom == "inprogress"
        ? inProgressCourseTableData.filter((e) => e.courseid == obj.courseid)
        : completedCourseTableData.filter((e) => e.courseid == obj.courseid);
    return tempCourseList;
  };

  //GET STARTDATE BY COURSESTREAMDETAIL
  const getStartDateByStreamDetail = (obj) => {
    if (obj && obj.length > 0) {
      const startDate = obj[0].startdate;
      return moment(startDate).format("MMM D YYYY");
    }
  };

  //GET END DATE BY COURSESTREAMDETAIL
  const getEndDateByStreamDetail = (obj) => {
    if (obj && obj.length > 0) {
      const endDate = obj[obj.length - 1].startdate;
      return moment(endDate).format("MMM D YYYY");
    }
  };

  //ON CLICK ASSIGNED KID ACCORDION
  const onClickAssignedKidAccordion = async (obj, getFrom) => {
    setExpandCourseAccordion(0);
    if (expandCourseAccordion !== obj.courseid) {
      setExpandCourseAccordion(obj.courseid);
    }
    setDropDownPodList(
      getUnique(await getPodsByCourseId(obj, getFrom), "coursestreamid")
    );

    //  var courseList = await getPodsByCourseId(obj, getFrom);
    //   setDropDownPodList(getUnique(courseList, 'coursestreamid'));
    //   if(getFrom == 'inprogress'){
    //     var data = JSON.parse(JSON.stringify(inProgressCourseTableData));
    //     data.forEach(element => {
    //       if(element.coursestreamid == obj.coursestreamid){
    //         element.podsList = dropDownPodList
    //       }
    //     });
    //     setInProgressCourseTableData(data);
    //     setFilteredInProgressCourseTableData(getUnique(data, 'courseid'));
    //   }else{
    //     var data = JSON.parse(JSON.stringify(completedCourseTableData));
    //     data.forEach(element => {
    //       if(element.coursestreamid == obj.coursestreamid){
    //         element.podsList = dropDownPodList
    //       }
    //     });
    //     setCompletedCourseTableData(data);
    //     setFilteredCompletedCourseTableData(getUnique(data, 'courseid'));
    //   }
  };

  //GET EACH KID DETAIL
  const getEachKidDetail = (kidId) => {
    if (kidId) {
      const tempKidDetail = kidslist.filter((e) => e.id == kidId)[0];
      return tempKidDetail;
    }
  };

  //ADD LEARNER FROM CARD
  const onAddLearnerClick = async (e, obj, getFrom) => {
    handleClickAssign(obj, getFrom);
  };

  //OPEN ADD NEW LEARNER FROM ASSIGN COURSE POPUP
  const switchToAddNewLearner = (e) => {
    setOpen(false);
    handleClickOpen(SelectCourseData);
  };
  const getKidDetail = (kidDetail) => {
    if (kidDetail.length > 0) {
      var assignedKids = "";
      for (var i = 0; i < kidDetail.length; i++) {
        if (assignedKids == "") {
          var tempKidDetail = kidslist.filter(
            (e) => e.id == kidDetail[i].learnerid
          );
          if (tempKidDetail && tempKidDetail.length > 0) {
            assignedKids = tempKidDetail[0].firstname;
          }
        } else {
          var tempKidDetail = kidslist.filter(
            (e) => e.id == kidDetail[i].learnerid
          );
          if (tempKidDetail && tempKidDetail.length > 0) {
            assignedKids = assignedKids + ", " + tempKidDetail[0].firstname;
          }
        }
      }
      return assignedKids;
    }
  };
  //#endregion

  const toggleItemStatus = (item, index) => {
    setOpenAddKid(false);
    setOpen(false);
    if (item.routeTo == "add-kid") {
      setOpenAddKid(true);
      ParentAPIService.getCourseByParentId(getCurrentUserData().id)
        .then((response) => {
          setSelectCourseData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (item.routeTo == "assign-kid") {
      setOpen(true);
      //Get course by parent id
      ParentAPIService.getCourseByParentId(getCurrentUserData().id)
        .then((response) => {
          console.log(response.data);
          if (response.data && response.data.length > 0) {
            setSelectCourseData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.open(item.routeTo);
    }

    // const updatedItems = [...items];
    // updatedItems[index].active = !updatedItems[index].active;
    // setItems(updatedItems);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    setExpandCourseAccordion(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleInProgressDropDownClick = async (event, obj, getFrom) => {
    if (getFrom == "inprogress") {
      setDropDownPodList([]);
      setInProgressAnchorEl(event.currentTarget);
      setDropDownPodList(
        getUnique(await getPodsByCourseId(obj, getFrom), "coursestreamid")
      );
    } else {
      setDropDownPodList([]);
      setCompletedAnchorEl(event.currentTarget);
      setDropDownPodList(
        getUnique(await getPodsByCourseId(obj, getFrom), "coursestreamid")
      );
    }
  };

  const handleInprogressDropDownClose = () => {
    setInProgressAnchorEl(null);
    setCompletedAnchorEl(null);
  };

  const loginAsLearner = () => {
    Swal.fire({
      title: "Are you sure do you want to login as learner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        localStorage.clear();
        navigate("/learner-login");
      }
    });
  };
  const checkIsKidsAdded = () => {
    const kidCheckList = courseTableData.filter((e) => e.kidDetail.length > 0);
    if (kidCheckList.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  //#region Licence Share

  const [openLicenceShare, setOpenLicenceShare] = React.useState(false);

  const [selectedpodId, setSelectedpodId] = useState(null);

  const handleCloseLicenceShare = () => {
    setOpenLicenceShare(false);
  };

  // const [selectedObjDetails, setSelectedObjDetails] = useState(null);

  // const handleLicenceShareClickOpen = async (e, obj, getFrom) => {
  //   console.log("obj", obj);
  //   setSelectedObjDetails(obj);
  //   setSelectedpodId(obj.coursestreamid);
  //   setOpenLicenceShare(true);
  // };

  const [selectedObjDetails, setSelectedObjDetails] = useState(null);
  const [accessLimit, setAccessLimit] = useState(0); // Initialize accessLimit state

  const handleLicenceShareClickOpen = async (e, obj, getFrom) => {
    console.log("obj", obj);
    setSelectedObjDetails(obj);
    setSelectedpodId(obj.coursestreamid);
    setOpenLicenceShare(true);

    // Calculate access limit based on selectedObjDetails
    const kidsCount = getKidsCountByCourseId(obj, "inprogress");
    const licenseCount = getLicenseCountByCourseId(obj, "inprogress");
    const remainingAccess = licenseCount - kidsCount;
    const maxAccess = Math.min(remainingAccess, 3); // Limit access to 3 or remainingAccess, whichever is smaller
    setAccessLimit(maxAccess);
  };

  const LicenceShareSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    access: Yup.string().required("Access is required"),
  });

  const {
    register: LicenceShareReg,
    control: LicenceShareCtrl,
    handleSubmit: LicenceShareSubmit,
    reset: LicenceShareReset,
    formState: { errors: LicenceShareErrors },
  } = useForm({
    resolver: yupResolver(LicenceShareSchema),
  });

  const onLicenceShareSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));

    const datas = {
      pod_id: selectedpodId,
      email: data.email,
      licenses: data.access,
      send_parent_id: getCurrentUserData().id,
    };

    try {
      await ShareLicensesService.shareLicensesRegister(datas);
      enqueueSnackbar("Licenses Share Sucessfully", {
        variant: "success",
        key: "topright",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setOpenLicenceShare(false);
    } catch (error) {
      console.error("Error registering customer:", error);
    }

    LicenceShareReset();
  };
  //#endregion

  return (
    <div style={{ height: "100vh" }}>
      <div className="h-100 large-screen ms-lg-5 ms-md-5 ms-sm-5">
        <div className="row h-100 ms-0 me-0">
          <div className="col-lg-8 left-column-myc p-0" style={{ zIndex: "1" }}>
            <Container className="mt-4" style={{ paddingRight: "75px" }}>
              <div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <span className="courses-text-myc">Courses</span>
                  {/* TABS */}
                  <div
                    className="btn-group course-group-btn-myc parent-course-tour-2"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <button
                      className={`btn parent-course-btn-myc ${
                        activeTab === 1 ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      Inprogress
                    </button>
                    <button
                      className={`btn parent-course-btn-myc ${
                        activeTab === 2 ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(2)}
                    >
                      Completed
                    </button>
                  </div>
                </div>
                {/* INPROGRESS COURSES */}
                <div
                  className={`tab-content-parent-myc ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  {filteredInProgressCourseTableData &&
                  filteredInProgressCourseTableData.length > 0 ? (
                    <div>
                      {filteredInProgressCourseTableData?.map((obj, idx) => {
                        return (
                          <Card
                            key={`p-myc-inprogress-key-` + idx}
                            className="parent-course-card-myc parent-course-tour-3 mb-3"
                          >
                            <div className="row ms-0 me-0">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                                <div className="p_my_courses_image_section">
                                  {obj?.course_image_file_name ? (
                                    <img
                                      width={`100%`}
                                      src={obj?.course_image}
                                      alt={slugify(obj?.coursename)}
                                      className="p-myc-img"
                                    ></img>
                                  ) : (
                                    <img
                                      src={assets.course_v2.section_1_group_img}
                                      className=""
                                    ></img>
                                  )}
                                  {obj?.amount == "0" ? (
                                    <div className="p_my_courses_btn">
                                      <button
                                        className="p_my_courses_free_courses_btn"
                                        type="button"
                                      >
                                        FREE COURSE
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0">
                                <div className="card-course-text-section-myc">
                                  <p className="card-course-title-myc m-0">
                                    {obj?.coursename}
                                  </p>
                                  <div>
                                    <Button
                                      id="basic-button"
                                      aria-controls={
                                        inProgressOpenMenu
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        inProgressOpenMenu ? "true" : undefined
                                      }
                                      onClick={(e) =>
                                        handleInProgressDropDownClick(
                                          e,
                                          obj,
                                          "inprogress"
                                        )
                                      }
                                      className="course-access-available-btn-myc me-2"
                                    >
                                      <strong className="me-1">
                                        {getKidsCountByCourseId(
                                          obj,
                                          "inprogress"
                                        )}
                                        /
                                        {getLicenseCountByCourseId(
                                          obj,
                                          "inprogress"
                                        )}
                                      </strong>
                                      Access Left
                                      <KeyboardArrowDownIcon />
                                    </Button>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={inProgressAnchorEl}
                                      open={inProgressOpenMenu}
                                      onClose={handleInprogressDropDownClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <div className="access-table-section-myc">
                                        <table>
                                          <thead>
                                            <th></th>
                                            <th className="access-table-th-myc">
                                              Access
                                            </th>
                                            <th className="access-table-th-myc">
                                              Starting/Ending
                                            </th>
                                            <th className="access-table-th-myc">
                                              Fee
                                            </th>
                                          </thead>
                                          <tbody>
                                            {dropDownPodList?.map(
                                              (dObj, dIdx) => {
                                                return (
                                                  <tr
                                                    key={
                                                      `d-inprogress-tr-key-` +
                                                      dIdx
                                                    }
                                                  >
                                                    <td className="access-table-tb-pod-myc">
                                                      Pod {dIdx + 1}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}{" "}
                                                      <br></br>
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-free-myc">
                                                      {" "}
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}

                                            {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Menu>
                                    <Tooltip
                                      title="Licence Share"
                                      placement="top"
                                    >
                                      <button
                                        className="course-access-available-btn-myc"
                                        onClick={(e) =>
                                          handleLicenceShareClickOpen(
                                            e,
                                            obj,
                                            "inprogress"
                                          )
                                        }
                                        disabled={
                                          getKidsCountByCourseId(
                                            obj,
                                            "inprogress"
                                          ) >=
                                          getLicenseCountByCourseId(
                                            obj,
                                            "inprogress"
                                          )
                                        }
                                      >
                                        {/* <IosShareIcon sx={{ fontSize: 20 }} /> */}
                                        Share access
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <button
                                      className="assign-kid-btn-myc parent-course-tour-4 mt-3 me-2"
                                      type="button"
                                      onClick={(e) =>
                                        onAddLearnerClick(e, obj, "inprogress")
                                      }
                                    >
                                      Add Learner
                                    </button>
                                    <button
                                      className="buy-course-btn-myc mt-3"
                                      type="button"
                                      title={
                                        obj?.kidDetail.length == 0
                                          ? "Please add kid."
                                          : "Click here to login as learner."
                                      }
                                      disabled={
                                        obj?.kidDetail.length == 0
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => loginAsLearner()}
                                    >
                                      Login as Learner
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cursor-pointer mt-2 mb-2 ps-1">
                              <Accordion
                                expanded={
                                  expandCourseAccordion === obj.courseid
                                }
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon className="expandmoreIcon-myc" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="AccordionSummary-parent myc p-0"
                                  onClick={(e) =>
                                    onClickAssignedKidAccordion(
                                      obj,
                                      "inprogress"
                                    )
                                  }
                                >
                                  <p className="accordion-assigned-learners-text-myc parent-course-tour-5 m-0">
                                    Assigned Learners
                                  </p>
                                </AccordionSummary>
                                <AccordionDetails className="p-0">
                                  {getKidsCountByCourseId(obj, "inprogress") ==
                                  0 ? (
                                    <div className="no-nearners-available-section-myc mt-2">
                                      <p className="no-nearners-available-text-myc m-0">
                                        No Learners available{" "}
                                        <span
                                          className="add-learner-link"
                                          onClick={(e) =>
                                            onAddLearnerClick(
                                              e,
                                              obj,
                                              "inprogress"
                                            )
                                          }
                                        >
                                          Add Learner
                                        </span>
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      {dropDownPodList?.map((dObj, dIdx) => {
                                        return (
                                          <div
                                            key={
                                              `inprogress-assigned-pods-key-` +
                                              dIdx
                                            }
                                            className="pods-section-myc mt-2"
                                          >
                                            <div className="row ms-0 me-0">
                                              <div className="col-lg-5 col-md-5 p-0">
                                                <p className="pods-title-text-myc m-0 p-0">
                                                  Pod {dIdx + 1}
                                                </p>
                                                <div className="row ms-0 me-0">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Starting
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Ending
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="row ms-0 me-0 mt-1">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Fee
                                                    </p>
                                                    <p className="m-0 card-dec-free-text-myc">
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      access left
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-7 col-md-7">
                                                <table>
                                                  <thead>
                                                    <th className="access-table-th-myc">
                                                      ASSIGNED KID
                                                    </th>
                                                    <th className="access-table-th-myc">
                                                      STATUS
                                                    </th>
                                                  </thead>
                                                  <tbody>
                                                    {dObj?.kidDetail?.map(
                                                      (kObj, kIdx) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              `inprogress-kids-detail-key-` +
                                                              kIdx
                                                            }
                                                          >
                                                            <td className="access-table-tb-myc d-flex align-items-center">
                                                              <Avatar
                                                                className="me-1"
                                                                sx={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />{" "}
                                                              {getEachKidDetail(
                                                                kObj?.learnerid
                                                              )?.firstname +
                                                                " " +
                                                                getEachKidDetail(
                                                                  kObj?.learnerid
                                                                )?.lastname}
                                                            </td>
                                                            <td className="access-table-tb-myc">
                                                              {" "}
                                                              <button
                                                                className="states-inprogress-btn-myc"
                                                                type="button"
                                                              >
                                                                INPROGRESS
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}

                                                    {/* <Divider sx={{ borderStyle: 'dashed' }} />
                                                  <tr>
                                                    <td className="access-table-tb-myc d-flex align-items-center"><Avatar className="me-1" sx={{ width: "30px", height: "30px" }} /> Helina Jones</td>
                                                    <td className="access-table-tb-myc"><button className="states-inprogress-btn-myc" type="button">INPROGRESS</button></td>
                                                  </tr> */}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <NoRecordFound
                        noRecordFoundImage={assets.noRecordFound.expertCourses}
                        noRecordFoundText={error_messages.noDataFound}
                      ></NoRecordFound>
                    </div>
                  )}
                </div>
                {/* COMPLETED COURSES */}
                <div
                  className={`tab-content-parent-myc ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  {filteredCompletedCourseTableData &&
                  filteredCompletedCourseTableData.length > 0 ? (
                    <div>
                      {filteredCompletedCourseTableData?.map((obj, idx) => {
                        return (
                          <Card
                            key={`p-myc-completed-key-` + idx}
                            className="parent-course-card-myc mb-3"
                          >
                            <div className="row ms-0 me-0">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                                <div className="p_my_courses_image_section">
                                  {obj?.course_image_file_name ? (
                                    <img
                                      width={`100%`}
                                      src={obj?.course_image}
                                      alt={slugify(obj?.coursename)}
                                      className="p-myc-img"
                                    ></img>
                                  ) : (
                                    <img
                                      src={assets.course_v2.section_1_group_img}
                                      className=""
                                    ></img>
                                  )}
                                  {obj?.amount == "0" ? (
                                    <div className="p_my_courses_btn">
                                      <button
                                        className="p_my_courses_free_courses_btn"
                                        type="button"
                                      >
                                        FREE COURSE
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0">
                                <div className="card-course-text-section-myc">
                                  <p className="card-course-title-myc m-0">
                                    {obj?.coursename}
                                  </p>
                                  <div>
                                    <Button
                                      id="basic-button"
                                      aria-controls={
                                        inProgressOpenMenu
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        inProgressOpenMenu ? "true" : undefined
                                      }
                                      onClick={(e) =>
                                        handleInProgressDropDownClick(
                                          e,
                                          obj,
                                          "completed"
                                        )
                                      }
                                      className="course-access-available-btn-myc"
                                    >
                                      <strong className="me-1">
                                        {getKidsCountByCourseId(
                                          obj,
                                          "completed"
                                        )}
                                        /
                                        {getLicenseCountByCourseId(
                                          obj,
                                          "completed"
                                        )}
                                      </strong>
                                      Access Left
                                      <KeyboardArrowDownIcon />
                                    </Button>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={completedAnchorEl}
                                      open={completedOpenMenu}
                                      onClose={handleInprogressDropDownClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <div className="access-table-section-myc">
                                        <table>
                                          <thead>
                                            <th></th>
                                            <th className="access-table-th-myc">
                                              Access
                                            </th>
                                            <th className="access-table-th-myc">
                                              Starting/Ending
                                            </th>
                                            <th className="access-table-th-myc">
                                              Fee
                                            </th>
                                          </thead>
                                          <tbody>
                                            {dropDownPodList?.map(
                                              (dObj, dIdx) => {
                                                return (
                                                  <tr
                                                    key={
                                                      `d-completed-tr-key-` +
                                                      dIdx
                                                    }
                                                  >
                                                    <td className="access-table-tb-pod-myc">
                                                      Pod {dIdx + 1}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}{" "}
                                                      <br></br>
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-free-myc">
                                                      {" "}
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}

                                            {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Menu>
                                  </div>
                                  <div className="">
                                    {/* <button className="assign-kid-btn-myc mt-3 me-2" type="button">Add Learner</button> */}
                                    <button
                                      className="buy-course-btn-myc mt-3"
                                      type="button"
                                      onClick={(e) =>
                                        navigateToCourseDetail(obj)
                                      }
                                    >
                                      Buy Course
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cursor-pointer mt-2 mb-2 ps-1">
                              <Accordion
                                expanded={
                                  expandCourseAccordion === obj.courseid
                                }
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon className="expandmoreIcon-myc" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="AccordionSummary-parent myc p-0"
                                  onClick={(e) =>
                                    onClickAssignedKidAccordion(
                                      obj,
                                      "completed"
                                    )
                                  }
                                >
                                  <p className="accordion-assigned-learners-text-myc m-0">
                                    Assigned Learners
                                  </p>
                                </AccordionSummary>
                                <AccordionDetails className="p-0">
                                  {getKidsCountByCourseId(obj, "completed") ==
                                  0 ? (
                                    <div className="no-nearners-available-section-myc mt-2">
                                      <p className="no-nearners-available-text-myc m-0">
                                        No Learners available{" "}
                                        <a href="">Add Learner</a>
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      {dropDownPodList?.map((dObj, dIdx) => {
                                        return (
                                          <div
                                            key={
                                              `completed-assigned-pods-key-` +
                                              dIdx
                                            }
                                            className="pods-section-myc mt-2"
                                          >
                                            <div className="row ms-0 me-0">
                                              <div className="col-lg-5 col-md-5 p-0">
                                                <p className="pods-title-text-myc m-0 p-0">
                                                  Pod {dIdx + 1}
                                                </p>
                                                <div className="row ms-0 me-0">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Starting
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Ending
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="row ms-0 me-0 mt-1">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Fee
                                                    </p>
                                                    <p className="m-0 card-dec-free-text-myc">
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      access left
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-7 col-md-7">
                                                <table>
                                                  <thead>
                                                    <th className="access-table-th-myc">
                                                      ASSIGNED KID
                                                    </th>
                                                    <th className="access-table-th-myc">
                                                      STATUS
                                                    </th>
                                                  </thead>
                                                  <tbody>
                                                    {dObj?.kidDetail?.map(
                                                      (kObj, kIdx) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              `completed-kids-detail-key-` +
                                                              kIdx
                                                            }
                                                          >
                                                            <td className="access-table-tb-myc d-flex align-items-center">
                                                              <Avatar
                                                                className="me-1"
                                                                sx={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />{" "}
                                                              {getEachKidDetail(
                                                                kObj?.learnerid
                                                              )?.firstname +
                                                                " " +
                                                                getEachKidDetail(
                                                                  kObj?.learnerid
                                                                )?.lastname}
                                                            </td>
                                                            <td className="access-table-tb-myc">
                                                              {" "}
                                                              <button
                                                                className="states-inprogress-btn-myc"
                                                                type="button"
                                                              >
                                                                COMPLETED
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <NoRecordFound
                        noRecordFoundImage={assets.noRecordFound.expertCourses}
                        noRecordFoundText={error_messages.noDataFound}
                      ></NoRecordFound>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
          <div className="col-lg-4 right-column-myc p-0">
            <Container className="mt-3">
              <Card className="parent-profile-card-myc mb-3">
                <div>
                  <p className="parent-profile-com-text-myc m-0">
                    Parent profile completion
                  </p>

                  <LinearProgress
                    className="parent_profile_linear"
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      height: "10px",
                      borderRadius: 2,
                      bgcolor: "#fffff",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#12B347",
                      },
                    }}
                  />

                  <p className="Complete-text-myc m-0">
                    <strong>{completionPercentage}% </strong>Complete
                  </p>
                  <div>
                    <p className="things-to-do-text-myc m-0">Things to do</p>

                    <ul className="parent-profile-ui-myc p-0">
                      {items.map((item, index) => (
                        <li
                          className="parent-profile-li-myc"
                          key={index}
                          onClick={() => toggleItemStatus(item, index)} // Toggle the item's status on click
                        >
                          <div className="d-flex align-items-center">
                            <span
                              className={
                                item.active
                                  ? "active-ui-myc"
                                  : "inactive-ui-myc"
                              }
                            >
                              <DoneIcon sx={{ fontSize: 15, color: "white" }} />
                            </span>

                            <div className="">
                              <p className="m-0 parent-profile-ui-title-myc">
                                {" "}
                                {item.title}
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ChevronRightIcon sx={{ fontSize: 15 }} />
                            </div>
                          </div>
                          <p className="m-0 parent-profile-ui-des-myc">
                            {" "}
                            {item.description}
                          </p>
                          {items.length - 1 !== index ? (
                            <p
                              className={
                                item.active
                                  ? "border-left-parent-section-myc-active"
                                  : "border-left-parent-section-myc"
                              }
                            ></p>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                    <TourManager></TourManager>
                    {/* <button className="Take-the-Tour-btn-myc" type="button">Take the Tour</button> */}
                  </div>
                </div>
              </Card>
            </Container>
          </div>
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="h-100 mobile-screen">
        <div className="row h-100 ms-0 me-0">
          <div
            className="col-lg-12 left-column-myc p-0"
            style={{ zIndex: "1" }}
          >
            <Container className="mt-3">
              <div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <span className="courses-text-myc">Courses</span>
                  {/* TABS */}
                  <div
                    className="btn-group course-group-btn-myc-mobile parent-course-tour-2"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <button
                      className={`btn parent-course-btn-myc ${
                        activeTab === 1 ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      Inprogress
                    </button>
                    <button
                      className={`btn parent-course-btn-myc ${
                        activeTab === 2 ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(2)}
                    >
                      Completed
                    </button>
                  </div>
                </div>
                {/* INPROGRESS COURSES */}
                <div
                  className={`tab-content-parent-myc ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  {filteredInProgressCourseTableData &&
                  filteredInProgressCourseTableData.length > 0 ? (
                    <div>
                      {filteredInProgressCourseTableData?.map((obj, idx) => {
                        return (
                          <Card
                            key={`p-myc-inprogress-key-` + idx}
                            className="parent-course-card-myc parent-course-tour-3 mb-3"
                          >
                            <div className="row ms-0 me-0">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                                <div className="p_my_courses_image_section">
                                  {obj?.course_image_file_name ? (
                                    <img
                                      width={`100%`}
                                      src={obj?.course_image}
                                      alt={slugify(obj?.coursename)}
                                      className="p-myc-img"
                                    ></img>
                                  ) : (
                                    <img
                                      src={assets.course_v2.section_1_group_img}
                                      className=""
                                    ></img>
                                  )}
                                  {obj?.amount == "0" ? (
                                    <div className="p_my_courses_btn">
                                      <button
                                        className="p_my_courses_free_courses_btn"
                                        type="button"
                                      >
                                        FREE COURSE
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0">
                                <div className="card-course-text-section-myc">
                                  <p className="card-course-title-myc m-0">
                                    {obj?.coursename}
                                  </p>
                                  <div>
                                    <Button
                                      id="basic-button"
                                      aria-controls={
                                        inProgressOpenMenu
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        inProgressOpenMenu ? "true" : undefined
                                      }
                                      onClick={(e) =>
                                        handleInProgressDropDownClick(
                                          e,
                                          obj,
                                          "inprogress"
                                        )
                                      }
                                      className="course-access-available-btn-myc me-2"
                                    >
                                      <strong className="me-1">
                                        {getKidsCountByCourseId(
                                          obj,
                                          "inprogress"
                                        )}
                                        /
                                        {getLicenseCountByCourseId(
                                          obj,
                                          "inprogress"
                                        )}
                                      </strong>
                                      Access Left
                                      <KeyboardArrowDownIcon />
                                    </Button>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={inProgressAnchorEl}
                                      open={inProgressOpenMenu}
                                      onClose={handleInprogressDropDownClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <div className="access-table-section-myc">
                                        <table>
                                          <thead>
                                            <th></th>
                                            <th className="access-table-th-myc">
                                              Access
                                            </th>
                                            <th className="access-table-th-myc">
                                              Starting/Ending
                                            </th>
                                            <th className="access-table-th-myc">
                                              Fee
                                            </th>
                                          </thead>
                                          <tbody>
                                            {dropDownPodList?.map(
                                              (dObj, dIdx) => {
                                                return (
                                                  <tr
                                                    key={
                                                      `d-inprogress-tr-key-m` +
                                                      dIdx
                                                    }
                                                  >
                                                    <td className="access-table-tb-pod-myc">
                                                      Pod {dIdx + 1}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}{" "}
                                                      <br></br>
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-free-myc">
                                                      {" "}
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}

                                            {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Menu>
                                    <Tooltip
                                      title="Licence Share"
                                      placement="top"
                                    >
                                      <button
                                        className="course-access-available-btn-myc"
                                        onClick={(e) =>
                                          handleLicenceShareClickOpen(
                                            e,
                                            obj,
                                            "inprogress"
                                          )
                                        }
                                        disabled={
                                          getKidsCountByCourseId(
                                            obj,
                                            "inprogress"
                                          ) ===
                                          getLicenseCountByCourseId(
                                            obj,
                                            "inprogress"
                                          )
                                        }
                                      >
                                        {/* <IosShareIcon sx={{ fontSize: 20 }} /> */}
                                        Share access
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <div className="">
                                    <button
                                      className="assign-kid-btn-myc parent-course-tour-4 mt-3 me-2"
                                      type="button"
                                      onClick={(e) =>
                                        onAddLearnerClick(e, obj, "inprogress")
                                      }
                                    >
                                      Add Learner
                                    </button>
                                    <button
                                      className="buy-course-btn-myc mt-3"
                                      type="button"
                                      title={
                                        obj?.kidDetail.length == 0
                                          ? "Please add kid."
                                          : "Click here to login as learner."
                                      }
                                      disabled={
                                        obj?.kidDetail.length == 0
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => loginAsLearner()}
                                    >
                                      Login as Learner
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cursor-pointer mt-2 mb-2 ps-1">
                              <Accordion
                                expanded={
                                  expandCourseAccordion === obj.courseid
                                }
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon className="expandmoreIcon-myc" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="AccordionSummary-parent myc p-0"
                                  onClick={(e) =>
                                    onClickAssignedKidAccordion(
                                      obj,
                                      "inprogress"
                                    )
                                  }
                                >
                                  <p className="accordion-assigned-learners-text-myc parent-course-tour-5 m-0">
                                    Assigned Learners
                                  </p>
                                </AccordionSummary>
                                <AccordionDetails className="p-0">
                                  {getKidsCountByCourseId(obj, "inprogress") ==
                                  0 ? (
                                    <div className="no-nearners-available-section-myc mt-2">
                                      <p className="no-nearners-available-text-myc m-0">
                                        No Learners available{" "}
                                        <span
                                          className="add-learner-link"
                                          onClick={(e) =>
                                            onAddLearnerClick(
                                              e,
                                              obj,
                                              "inprogress"
                                            )
                                          }
                                        >
                                          Add Learner
                                        </span>
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      {dropDownPodList?.map((dObj, dIdx) => {
                                        return (
                                          <div
                                            key={
                                              `inprogress-assigned-pods-key-` +
                                              dIdx
                                            }
                                            className="pods-section-myc mt-2"
                                          >
                                            <div className="row ms-0 me-0">
                                              <div className="col-lg-5 col-md-5 p-0">
                                                <p className="pods-title-text-myc m-0 p-0">
                                                  Pod {dIdx + 1}
                                                </p>
                                                <div className="row ms-0 me-0">
                                                  <div className="col-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Starting
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Ending
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="row ms-0 me-0 mt-1">
                                                  <div className="col-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Fee
                                                    </p>
                                                    <p className="m-0 card-dec-free-text-myc">
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      access left
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "inprogress"
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-7 col-md-7 p-0">
                                                <table className="mt-1">
                                                  <thead>
                                                    <th className="access-table-th-myc">
                                                      ASSIGNED KID
                                                    </th>
                                                    <th className="access-table-th-myc">
                                                      STATUS
                                                    </th>
                                                  </thead>
                                                  <tbody>
                                                    {dObj?.kidDetail?.map(
                                                      (kObj, kIdx) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              `inprogress-kids-detail-key-` +
                                                              kIdx
                                                            }
                                                          >
                                                            <td className="access-table-tb-myc d-flex align-items-center">
                                                              <Avatar
                                                                className="me-1"
                                                                sx={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />{" "}
                                                              {getEachKidDetail(
                                                                kObj?.learnerid
                                                              )?.firstname +
                                                                " " +
                                                                getEachKidDetail(
                                                                  kObj?.learnerid
                                                                )?.lastname}
                                                            </td>
                                                            <td className="access-table-tb-myc">
                                                              {" "}
                                                              <button
                                                                className="states-inprogress-btn-myc"
                                                                type="button"
                                                              >
                                                                INPROGRESS
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}

                                                    {/* <Divider sx={{ borderStyle: 'dashed' }} />
                                                  <tr>
                                                    <td className="access-table-tb-myc d-flex align-items-center"><Avatar className="me-1" sx={{ width: "30px", height: "30px" }} /> Helina Jones</td>
                                                    <td className="access-table-tb-myc"><button className="states-inprogress-btn-myc" type="button">INPROGRESS</button></td>
                                                  </tr> */}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <NoRecordFound
                        noRecordFoundImage={assets.noRecordFound.expertCourses}
                        noRecordFoundText={error_messages.noDataFound}
                      ></NoRecordFound>
                    </div>
                  )}
                </div>
                {/* COMPLETED COURSES */}
                <div
                  className={`tab-content-parent-myc ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  {filteredCompletedCourseTableData &&
                  filteredCompletedCourseTableData.length > 0 ? (
                    <div>
                      {filteredCompletedCourseTableData?.map((obj, idx) => {
                        return (
                          <Card
                            key={`p-myc-completed-key-` + idx}
                            className="parent-course-card-myc mb-3"
                          >
                            <div className="row ms-0 me-0">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                                <div className="p_my_courses_image_section">
                                  {obj?.course_image_file_name ? (
                                    <img
                                      width={`100%`}
                                      src={obj?.course_image}
                                      alt={slugify(obj?.coursename)}
                                      className="p-myc-img"
                                    ></img>
                                  ) : (
                                    <img
                                      src={assets.course_v2.section_1_group_img}
                                      className=""
                                    ></img>
                                  )}
                                  {obj?.amount == "0" ? (
                                    <div className="p_my_courses_btn">
                                      <button
                                        className="p_my_courses_free_courses_btn"
                                        type="button"
                                      >
                                        FREE COURSE
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0">
                                <div className="card-course-text-section-myc">
                                  <p className="card-course-title-myc m-0">
                                    {obj?.coursename}
                                  </p>
                                  <div>
                                    <Button
                                      id="basic-button"
                                      aria-controls={
                                        inProgressOpenMenu
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        inProgressOpenMenu ? "true" : undefined
                                      }
                                      onClick={(e) =>
                                        handleInProgressDropDownClick(
                                          e,
                                          obj,
                                          "completed"
                                        )
                                      }
                                      className="course-access-available-btn-myc"
                                    >
                                      <strong className="me-1">
                                        {getKidsCountByCourseId(
                                          obj,
                                          "completed"
                                        )}
                                        /
                                        {getLicenseCountByCourseId(
                                          obj,
                                          "completed"
                                        )}
                                      </strong>
                                      Access Left
                                      <KeyboardArrowDownIcon />
                                    </Button>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={completedAnchorEl}
                                      open={completedOpenMenu}
                                      onClose={handleInprogressDropDownClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <div className="access-table-section-myc">
                                        <table>
                                          <thead>
                                            <th></th>
                                            <th className="access-table-th-myc">
                                              Access
                                            </th>
                                            <th className="access-table-th-myc">
                                              Starting/Ending
                                            </th>
                                            <th className="access-table-th-myc">
                                              Fee
                                            </th>
                                          </thead>
                                          <tbody>
                                            {dropDownPodList?.map(
                                              (dObj, dIdx) => {
                                                return (
                                                  <tr
                                                    key={
                                                      `d-completed-tr-key-` +
                                                      dIdx
                                                    }
                                                  >
                                                    <td className="access-table-tb-pod-myc">
                                                      Pod {dIdx + 1}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}{" "}
                                                      <br></br>
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </td>
                                                    <td className="access-table-tb-free-myc">
                                                      {" "}
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}

                                            {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Menu>
                                  </div>
                                  <div className="">
                                    {/* <button className="assign-kid-btn-myc mt-3 me-2" type="button">Add Learner</button> */}
                                    <button
                                      className="buy-course-btn-myc mt-3"
                                      type="button"
                                      onClick={(e) =>
                                        navigateToCourseDetail(obj)
                                      }
                                    >
                                      Buy Course
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cursor-pointer mt-2 mb-2 ps-1">
                              <Accordion
                                expanded={
                                  expandCourseAccordion === obj.courseid
                                }
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon className="expandmoreIcon-myc" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="AccordionSummary-parent myc p-0"
                                  onClick={(e) =>
                                    onClickAssignedKidAccordion(
                                      obj,
                                      "completed"
                                    )
                                  }
                                >
                                  <p className="accordion-assigned-learners-text-myc m-0">
                                    Assigned Learners
                                  </p>
                                </AccordionSummary>
                                <AccordionDetails className="p-0">
                                  {getKidsCountByCourseId(obj, "completed") ==
                                  0 ? (
                                    <div className="no-nearners-available-section-myc mt-2">
                                      <p className="no-nearners-available-text-myc m-0">
                                        No Learners available{" "}
                                        <a href="">Add Learner</a>
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      {dropDownPodList?.map((dObj, dIdx) => {
                                        return (
                                          <div
                                            key={
                                              `completed-assigned-pods-key-` +
                                              dIdx
                                            }
                                            className="pods-section-myc mt-2"
                                          >
                                            <div className="row ms-0 me-0">
                                              <div className="col-lg-5 col-md-5 p-0">
                                                <p className="pods-title-text-myc m-0 p-0">
                                                  Pod {dIdx + 1}
                                                </p>
                                                <div className="row ms-0 me-0">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Starting
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getStartDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Ending
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getEndDateByStreamDetail(
                                                        dObj.courseStreamDetail
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="row ms-0 me-0 mt-1">
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      Fee
                                                    </p>
                                                    <p className="m-0 card-dec-free-text-myc">
                                                      {dObj?.amount !== "0" ? (
                                                        <span>
                                                          {dObj?.currency ==
                                                          "inr"
                                                            ? conditional_configs
                                                                .currency.inr
                                                            : conditional_configs
                                                                .currency.usd}
                                                          {dObj?.amount}
                                                        </span>
                                                      ) : (
                                                        "FREE Course"
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-6 col-md-6 p-0">
                                                    <p className="m-0 card-sub-title-myc">
                                                      access left
                                                    </p>
                                                    <p className="m-0 card-date-text-myc">
                                                      {getKidsCountByStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}{" "}
                                                      /{" "}
                                                      {getLicenseCountByCourseStreamId(
                                                        dObj,
                                                        "completed"
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-7 col-md-7">
                                                <table>
                                                  <thead>
                                                    <th className="access-table-th-myc">
                                                      ASSIGNED KID
                                                    </th>
                                                    <th className="access-table-th-myc">
                                                      STATUS
                                                    </th>
                                                  </thead>
                                                  <tbody>
                                                    {dObj?.kidDetail?.map(
                                                      (kObj, kIdx) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              `completed-kids-detail-key-` +
                                                              kIdx
                                                            }
                                                          >
                                                            <td className="access-table-tb-myc d-flex align-items-center">
                                                              <Avatar
                                                                className="me-1"
                                                                sx={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />{" "}
                                                              {getEachKidDetail(
                                                                kObj?.learnerid
                                                              )?.firstname +
                                                                " " +
                                                                getEachKidDetail(
                                                                  kObj?.learnerid
                                                                )?.lastname}
                                                            </td>
                                                            <td className="access-table-tb-myc">
                                                              {" "}
                                                              <button
                                                                className="states-inprogress-btn-myc"
                                                                type="button"
                                                              >
                                                                COMPLETED
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <NoRecordFound
                        noRecordFoundImage={assets.noRecordFound.expertCourses}
                        noRecordFoundText={error_messages.noDataFound}
                      ></NoRecordFound>
                    </div>
                  )}
                </div>
              </div>

              <Box sx={{ textAlign: "center", pb: 15 }}></Box>
              <div
                className="parent-profile-section-mobile-myc-div mt-5"
                onClick={toggleDrawer(true)}
              >
                <CssBaseline />
                <Puller />
                <p className="parent-profile-com-text-myc mb-0 mt-2">
                  Parent profile completion
                </p>
                <LinearProgress
                  className="parent_profile_linear"
                  variant="determinate"
                  value={completionPercentage}
                  sx={{
                    height: "10px",
                    borderRadius: 2,
                    bgcolor: "#fffff",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#12B347",
                    },
                  }}
                />
                <p className="Complete-text-myc m-0">
                  <strong>{completionPercentage}% </strong>Complete
                </p>
              </div>
            </Container>
          </div>
          <div className="col-lg-12 right-column-myc p-0">
            <div>
              <CssBaseline />
              <SwipeableDrawer
                anchor="bottom"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <Puller />
                <div className="parent-profile-section-mobile-myc">
                  <p className="parent-profile-com-text-myc mb-0 mt-2">
                    Parent profile completion
                  </p>

                  <LinearProgress
                    className="parent_profile_linear"
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      height: "10px",
                      borderRadius: 2,
                      bgcolor: "#fffff",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#12B347",
                      },
                    }}
                  />
                  <p className="Complete-text-myc m-0">
                    <strong>{completionPercentage}% </strong>Complete
                  </p>
                  <div>
                    <p className="things-to-do-text-myc m-0">Things to do</p>

                    <ul className="parent-profile-ui-myc p-0">
                      {items.map((item, index) => (
                        <li
                          className="parent-profile-li-myc"
                          key={index}
                          onClick={() => toggleItemStatus(item, index)} // Toggle the item's status on click
                        >
                          <div className="d-flex align-items-center">
                            <span
                              className={
                                item.active
                                  ? "active-ui-myc"
                                  : "inactive-ui-myc"
                              }
                            >
                              <DoneIcon sx={{ fontSize: 15, color: "white" }} />
                            </span>

                            <div className="">
                              <p className="m-0 parent-profile-ui-title-myc">
                                {" "}
                                {item.title}
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ChevronRightIcon sx={{ fontSize: 15 }} />
                            </div>
                          </div>
                          <p className="m-0 parent-profile-ui-des-myc">
                            {" "}
                            {item.description}
                          </p>
                          {items.length - 1 !== index ? (
                            <p
                              className={
                                item.active
                                  ? "border-left-parent-section-myc-active"
                                  : "border-left-parent-section-myc"
                              }
                            ></p>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                    <TourManager></TourManager>
                    {/* <button className="Take-the-Tour-btn-myc" type="button">Take the Tour</button> */}
                  </div>
                </div>
              </SwipeableDrawer>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Assign Learner"}
            <span className="add_kids_close_icon" onClick={handleClose}>
              <CloseIcon />
            </span>
          </DialogTitle>
          <DialogContent>
            <div className="row">
              {/* FORM SECTION */}
              <div className="col-12">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth required margin="dense">
                      <InputLabel id="selectCourse-label">
                        Select Course
                      </InputLabel>
                      <Select
                        labelId="selectCourse-label"
                        required
                        id="selectcourse"
                        name="selectcourse"
                        label="Select Course"
                        {...register("selectcourse")}
                        error={errors.selectcourse ? true : false}
                      >
                        {SelectCourseData.map((val, idx) => (
                          <MenuItem
                            key={`c-menu-key-` + idx}
                            value={val.coursestreamid}
                          >
                            {val.coursename}&nbsp;(
                            {getStartDateByStreamDetail(
                              val.courseStreamDetail
                            )}{" "}
                            - {getEndDateByStreamDetail(val.courseStreamDetail)}
                            )
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth required margin="dense">
                      <InputLabel id="selectKid-label">Select Kid</InputLabel>
                      <Select
                        labelId="selectKid-label"
                        required
                        id="selectkid"
                        name="selectkid"
                        label="Select Kid"
                        {...register("selectkid")}
                        error={errors.selectkid ? true : false}
                      >
                        {kidslist.map((val, idx) => (
                          <MenuItem
                            key={`p-my-courses-kids-select-key-` + idx}
                            value={val.id}
                          >
                            {val.firstname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div
                className="add-new-learner-link mt-2"
                onClick={(e) => switchToAddNewLearner()}
              >
                Add New Learner
              </div>
              {/* FORM SECTION END*/}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit(handleClickDone)}
              className="my_kids_save_btn my_kids_save_btn_black mb-3"
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Add kid dialogue box*/}
      <div>
        <Dialog
          open={openAddKid}
          onClose={handleCloseAddKid}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Learner"}
            <span className="add_kids_close_icon" onClick={handleCloseAddKid}>
              <CloseIcon />
            </span>
          </DialogTitle>

          <DialogContent>
            {/* <div>
                  <span>Course: </span><span className="add-kid-selected-coursename">{selectedCourseStream?.coursename}</span>
                </div> */}
            <div className="row">
              {/* FORM SECTION */}
              <div className="col-12">
                <Grid container spacing={1}>
                  {/* FIRST NAME TEXT FIELD */}
                  <Grid item xs={12} sm={12} className="mt-2">
                    <TextField
                      required
                      id="firstname"
                      name="firstname"
                      label="First Name"
                      // defaultValue={kids_datas.firstname}
                      fullWidth
                      margin="dense"
                      {...kidsFormReg("firstname")}
                      error={Boolean(kidsFormErrors.firstname)}
                      helperText={
                        kidsFormErrors.firstname
                          ? kidsFormErrors.firstname?.message
                          : ""
                      }
                    />
                    {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.firstname?.message}
                        </Typography> */}
                  </Grid>
                  {/* LAST NAME TEXT FIELD */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="lastname"
                      name="lastname"
                      label="Last Name"
                      //defaultValue={kids_datas.lastname}
                      fullWidth
                      margin="dense"
                      {...kidsFormReg("lastname")}
                      error={kidsFormErrors.lastname ? true : false}
                      helperText={
                        kidsFormErrors.lastname
                          ? kidsFormErrors.lastname?.message
                          : ""
                      }
                    />
                    {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.lastname?.message}
                        </Typography> */}
                  </Grid>

                  {/* USERNAME */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="username"
                      name="username"
                      label="User Name"
                      //defaultValue={kids_datas.username}
                      fullWidth
                      margin="dense"
                      {...kidsFormReg("username")}
                      autoComplete="new-username"
                      error={kidsFormErrors.username ? true : false}
                      helperText={
                        kidsFormErrors.username
                          ? kidsFormErrors.username?.message
                          : ""
                      }
                    />
                    {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.username?.message}
                        </Typography> */}
                  </Grid>

                  {/* PASSWORD FIELD */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      autoComplete="new-password"
                      id="password"
                      name="password"
                      label="Password(for kids login)"
                      type="password"
                      //defaultValue={kids_datas.password}
                      fullWidth
                      margin="dense"
                      {...kidsFormReg("password")}
                      error={kidsFormErrors.password ? true : false}
                      helperText={
                        kidsFormErrors.password
                          ? kidsFormErrors.password?.message
                          : ""
                      }
                    />
                    {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.password?.message}
                        </Typography> */}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth required margin="dense">
                      <InputLabel id="selectCourse-label">
                        Select Course
                      </InputLabel>
                      <Select
                        labelId="selectCourse-label"
                        required
                        id="selectcourse"
                        name="selectcourse"
                        label="Select Course"
                        {...kidsFormReg("selectcourse")}
                        error={kidsFormErrors.selectcourse ? true : false}
                        helperText={
                          kidsFormErrors.selectcourse
                            ? kidsFormErrors.selectcourse?.message
                            : ""
                        }
                      >
                        {/* <MenuItem value={1}>Greek Mythology</MenuItem>
                            <MenuItem value={2}>Greek Mythology</MenuItem>
                            <MenuItem value={3}>Greek Mythology</MenuItem>*/}

                        {SelectCourseData.map((val) => (
                          <MenuItem value={val.coursestreamid}>
                            {val.coursename}&nbsp;(
                            {getStartDateByStreamDetail(
                              val.courseStreamDetail
                            )}{" "}
                            - {getEndDateByStreamDetail(val.courseStreamDetail)}
                            )
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              {/* FORM SECTION END*/}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              onClick={kidsFormSubmit(onAddSubmit)}
              className="my_kids_save_btn  my_kids_save_btn_black mb-3"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* licence share  dialogue box*/}
      <div>
        <Dialog
          open={openLicenceShare}
          onClose={handleCloseLicenceShare}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Licence Share"}
            <span
              className="add_kids_close_icon"
              onClick={handleCloseLicenceShare}
            >
              <CloseIcon />
            </span>
          </DialogTitle>

          <DialogContent>
            <div className="row">
              {/* FORM SECTION */}
              <div className="col-12">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} className="mt-2">
                    <form onSubmit={LicenceShareSubmit(onLicenceShareSubmit)}>
                      <Grid item xs={12} sm={12}>
                        {/* EMAIL FIELD */}

                        <Controller
                          control={LicenceShareCtrl}
                          name="email"
                          rules={{ required: "Email is required" }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextField
                              id="email"
                              name="email"
                              label="Email"
                              fullWidth
                              margin="dense"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              {...LicenceShareReg("email")}
                              error={LicenceShareErrors.email ? true : false}
                              helperText={LicenceShareErrors.email?.message}
                            />
                          )}
                        />

                        <Controller
                          control={LicenceShareCtrl}
                          name="access"
                          rules={{ required: "Access is required" }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextField
                              id="access"
                              label="Access"
                              variant="outlined"
                              type="number"
                              fullWidth
                              margin="dense"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              {...LicenceShareReg("access")}
                              inputProps={{
                                max:
                                  getLicenseCountByCourseId(
                                    selectedObjDetails,
                                    "inprogress"
                                  ) -
                                  getKidsCountByCourseId(
                                    selectedObjDetails,
                                    "inprogress"
                                  ),
                              }}
                              error={LicenceShareErrors.access}
                              helperText={LicenceShareErrors.access?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Button
                        className="mb-3"
                        id="submit-button"
                        variant="contained"
                        type="submit"
                      >
                        Share
                      </Button>
                    </form>
                  </Grid>
                </Grid>
              </div>
              {/* FORM SECTION END*/}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
//#endregion
