// scroll bar
import 'simplebar/src/simplebar.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import $ from 'jquery';
// import Popper from 'popper.js';
//import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './flexboxgrid.min.css';
import './index.css';
import './theme/styles-variable.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//new
import { Provider } from 'react-redux'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
   <HelmetProvider>

    <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
    <DndProvider backend={HTML5Backend}>
      <App />
      </DndProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </HelmetProvider>
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
