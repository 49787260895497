import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./payments.css";
import {
  Avatar,
  Button,
  Badge,
  Container,
  Grid,
  Paper,
  FormControl,
  Select,
  InputLabel,
  InputBase,
  Typography,
} from "@mui/material";


import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { SnackbarProvider, useSnackbar } from 'notistack';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import account from "../../../_mock/account";

//COMPONENTS
import Iconify from "../../../components/Iconify";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

//ICONS
import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";

import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';

import ParentAPIService from '../../../services/parent-service';
import MemberAPIService from '../../../services/member-service';

import { getCurrentUserData, getUnique, getCharacterValidationError } from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";
import slugify from "react-slugify";

//CONFIGS
import icons_config from "../../../config/icons";

//kids_table

function Courses_detail_list(courseName, assignedKid, subscribedDate) {
  return { courseName, assignedKid, subscribedDate };
}


// categories box icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Payments() {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const [searchQuery, setSearchQuery] = useState('');

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [kidslist, setkidslist] = useState([]);
  const [SelectCourseData, setSelectCourseData] = useState([]);
  const [courseTableData, setcourseTableData] = useState([]);
  const [filteredCourseTableData, setFilteredCourseTableData] = useState([]);
  const [badgeData, setBadgeData] = useState([0]);
  const ParentData = JSON.parse(localStorage.getItem('user'));

  const [openAddKid, setOpenAddKid] = React.useState(false);
  const [selectedCourseStream, setSelectedCourseStream] = React.useState();

  //To get Table value and kids value
  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      ParentAPIService.GetMyLidsByParentsId(ParentData.id).then(response => {
        //setmyKidsList(response.data);
        console.log(response.data);
        setkidslist(response.data);

      })
        .catch(e => {
          console.log(e);
        });

      ParentAPIService.getCourseByParentId(ParentData.id)
        .then(response => {
          console.log(response.data);
          setcourseTableData(response.data);
          setFilteredCourseTableData(response.data);
          // setFilteredCourseTableData(getUnique(response.data, 'coursestreamid'));
          setBadgeData(response.data.length)
        })
        .catch(error => {

          console.log(error)
        });
    } else {
      localStorage.clear();
      navigate('/parent-login');
    }


  }, []);

  const validate = Yup.object().shape({
    selectcourse: Yup.string().required("Select course "),
    selectkid: Yup.string().required("Select Kid"),

  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  });

  //popup
  const [open, setOpen] = useState(false);

  const handleClickAssign = () => {
    setOpen(true);
    //Get List of kids
    ParentAPIService.GetMyLidsByParentsId(ParentData.id).then(response => {
      //setmyKidsList(response.data);
      setkidslist(response.data);
      console.log(response.data);
    })
      .catch(e => {
        console.log(e);
      });

    //Get course by parent id

    ParentAPIService.getCourseByParentId(ParentData.id)
      .then(response => {
        console.log(response.data);
        setSelectCourseData(response.data);
      })
      .catch(error => {
        console.log(error)
      });

  };

  const handleClickDone = (data) => {
    console.log(data);
    const userId = JSON.parse(localStorage.getItem('user'));
    const parentid = userId.id;
    const assignData = {
      parentid: parentid.toString(),
      learnerid: data.selectkid,
      coursestreamid: data.selectcourse
    }
    MemberAPIService.courseAssignToKid(assignData).then(response => {
      if (response && response.success === 'true') {
        console.log(response.data);
        enqueueSnackbar('Course assinged Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        setOpen(false);
        window.location.reload();
      } else if (response && response.success === 'false') {
        enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      // setcourseTableData(response.data.courseinfo);

    })
      .catch(error => {
        console.log(error)
      });
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;

    setSearchQuery(query);
    // Filter the list of courses based on the search query
    const fCourses = courseTableData.filter((cours) =>
      cours.coursename.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCourseTableData(fCourses);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToCourseDetail = (obj) => {
    console.log(obj)
    if (obj && obj.courseid) {
      window.open('/course-detail/' + slugify(obj.coursename) + '-' + obj.courseid);
    }
  }

  const getKidDetail = (kidDetail) => {
    if (kidDetail.length > 0) {
      var assignedKids = ""
      for (var i = 0; i < kidDetail.length; i++) {
        if (assignedKids == "") {
          var tempKidDetail = kidslist.filter(e => e.id == kidDetail[i].learnerid);
          if (tempKidDetail && tempKidDetail.length > 0) {
            assignedKids = tempKidDetail[0].firstname;
          }
        } else {
          var tempKidDetail = kidslist.filter(e => e.id == kidDetail[i].learnerid);
          if (tempKidDetail && tempKidDetail.length > 0) {
            assignedKids = assignedKids + ", " + tempKidDetail[0].firstname;
          }
        }
      }
      return assignedKids
    }
  }
  //popup end

  //#region ADD KID

  const kidsSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    username: Yup.string().required("User name is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: kidsFormReg,
    control: kidsFormCtrl,
    handleSubmit: kidsFormSubmit,
    setValue,
    formState: { errors: kidsFormErrors }
} = useForm({
    resolver: yupResolver(kidsSchema)
});

  const handleClickOpen = (obj) => {
    setOpenAddKid(true);
    setSelectedCourseStream(null);
    ParentAPIService.getCourseByParentId(getCurrentUserData().id)
      .then(response => {
        setSelectCourseData(response.data);
        setSelectedCourseStream(obj);
      })
      .catch(error => {
        console.log(error)
      });
  };

  const handleCloseAddKid = () => {
    setOpenAddKid(false);
  };

  const onAddSubmit = (data) => {
    //  setChangeLabel(true);
    // setChangeButton(true);
    const parentid = getCurrentUserData().id;
    var addKidData = {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      email: getCurrentUserData().email,
      password: data.password,
      parentid: parentid.toString(),
      relationship: "2",
      coursestreamid: selectedCourseStream.coursestreamid
    };
    console.log('submit add kid::',addKidData)
    console.log('submit add kid:data:',data)
    ParentAPIService.addKid(addKidData)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Kids Added Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          handleCloseAddKid();
          window.location.reload();
          setSelectedCourseStream(null);
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        console.log(error)
      });
  };

  const getLicenseCount =(obj)=>{
    if(obj){
      var lic = 0
      const filteredList = courseTableData.filter(e=>e.coursestreamid == obj.coursestreamid);
      for(var i =0; i <filteredList.length; i++){
        lic = lic + filteredList[i].license;
      }
      return lic;
    }
  }

  //#endregion
  return (
    <div className="pt-lg-4 pt-md-4 pt-4 ps-lg-5 ps-2 pe-lg-5 pe-2">
      <Container>
        <div className="d-flex align-items-center  mb-3">
          <span className="parent_payment_text me-auto">
            Payments
            <Badge
              className="parent_badge_courses"
              badgeContent={filteredCourseTableData.length}
              sx={badgeStyle}
            />
          </span>

          <Button
            variant="contained"
            className="parent_assign_btn parent-course-tour-3"
            onClick={handleClickAssign}
          >
            Assign
          </Button>
        </div>

        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper
          component="form"
          className=" common-search-box parent-course-tour-2 mb-3"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
          <IconButton  aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className="search-input"
            placeholder="Search by Course Name"
            inputProps={{ 'aria-label': 'search by course name' }}
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </Paper>
</Grid>
        {/* <Grid container spacing={1} alignItems="center">
             <Grid item xs={12} sm={12} md={12} lg={6}>
                <Autocomplete
                  className="parent_kids_select"
                  multiple
                  id="checkboxes-tags-demo"
                  options={kidslist}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.firstname}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.firstname}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Kids" />
                  )}
                />
              </Grid> 
                <Paper
                  component="form"
                  className="parent_search_course_name"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    placeholder="Search by Course Name"
                    inputProps={{ "aria-label": "search by course name" }}
                  />
                </Paper>
            </Grid> */}


        <TableContainer className="parent-course-tour-4">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="parent_courses_table_header">
              <TableRow>
              <TableCell className="parent_courses_table_header_text col_1">
                  Invoice #
                </TableCell>
                <TableCell className="parent_courses_table_header_text col_1">
                  Course Name
                </TableCell>

                <TableCell className="parent_courses_table_header_text col_1">
                  Assigned Kid
                </TableCell>

                <TableCell className="parent_courses_table_header_text col_1">
                  License
                </TableCell>

                <TableCell
                  className="parent_courses_table_header_text col_2"
                  align="left"
                >
                  Amount
                </TableCell>
                <TableCell
                  className="parent_courses_table_header_text col_3"
                  align="left"
                >
                  Subscribed Date
                </TableCell>

                <TableCell
                  className="payment_table_header_text col_4"
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCourseTableData?.map((row, idx) => (
                <TableRow
                  key={`p-my-courses-key-` + idx}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left" type="date">
                    {`MEEKU-`}{moment(row.paidon).format("YYYY/MM/DD")}{`-`+row.id}
                  </TableCell>
                  <TableCell className="cursor-pointer parent-course-tour-5" align="left" style={{ color: "blue" }} onClick={(e) => navigateToCourseDetail(row)} >
                    {row.coursename}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.kidDetail.length < getLicenseCount(row) && row.kidDetail.length > 0 ?
                      <span className="parent-no-kids-assigned" onClick={()=>handleClickOpen(row)}>{getKidDetail(row.kidDetail) }{getIcon(icons_config.addOutlineRounded)}</span>:
                      row.kidDetail.length < getLicenseCount(row) && row.kidDetail.length == 0 ?
                      <span className="parent-no-kids-assigned" onClick={()=>handleClickOpen(row)}>No kids assigned{getIcon(icons_config.addOutlineRounded)}</span> : 
                      getKidDetail(row.kidDetail)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.license}
                  </TableCell>
                  <TableCell component="th" scope="row">
                   {row?.currency == 'inr' ? conditional_configs.currency.inr: conditional_configs.currency.usd}{row.amount}
                  </TableCell>
                  <TableCell align="left" type="date">
                    {moment(row.paidon).format("YYYY/MM/DD ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog */}
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" >
              {"Assign Course To Kid    "}
              <span className="assign_kids_close_icon" onClick={handleClose}>
                <CloseIcon />
              </span>

            </DialogTitle>

            <DialogContent>
              <div className="row">
                {/* FORM SECTION */}
                <div className="col-xs-12">
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} className="mb-3" >
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="selectCourse-label">
                          Select Course
                        </InputLabel>
                        <Select
                          labelId="selectCourse-label"
                          required
                          id="selectcourse"
                          name="selectcourse"
                          label="Select Course"
                          {...register("selectcourse")}
                          error={errors.selectcourse ? true : false}

                        >
                          {
                            SelectCourseData.map((val, idx) => <MenuItem key={`c-menu-key-` + idx} value={val.coursestreamid}>{val.coursename}</MenuItem>)}
                        </Select>
                      </FormControl>


                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="selectKid-label">
                          Select Kid
                        </InputLabel>
                        <Select
                          labelId="selectKid-label"
                          required
                          id="selectkid"
                          name="selectkid"
                          label="Select Kid"
                          {...register("selectkid")}
                          error={errors.selectkid ? true : false}
                        >
                          {kidslist.map((val, idx) => <MenuItem key={`p-my-courses-kids-select-key-` + idx} value={val.id}>{val.firstname}</MenuItem>)}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </div>
                {/* FORM SECTION END*/}


              </div>
            </DialogContent>
            <DialogActions >
              <Button
                onClick={handleSubmit(handleClickDone)}
                className="my_kids_save_btn mb-3"
              >
                Assign
              </Button>
            </DialogActions>
          </Dialog>
        </div>

          {/* Add kid dialogue box*/}
          <div>
            <Dialog
              open={openAddKid}
              onClose={handleCloseAddKid}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" >
                {"Add Kid"}
                <span className="add_kids_close_icon" onClick={handleCloseAddKid}>
                  <CloseIcon />
                </span>
              </DialogTitle>

              <DialogContent>
                <div>
                  <span>Course: </span><span className="add-kid-selected-coursename">{selectedCourseStream?.coursename}</span>
                </div>
                <div className="row">
                  {/* FORM SECTION */}
                  <div className="col-sm-6">
                    <Grid container spacing={1}>
                      {/* FIRST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12} className="mt-2">
                        <TextField
                          required

                          id="firstname"
                          name="firstname"
                          label="First Name"
                          // defaultValue={kids_datas.firstname}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("firstname")}
                          error={Boolean(kidsFormErrors.firstname)}
                          helperText={kidsFormErrors.firstname ? kidsFormErrors.firstname?.message : ''}
                        />
                        {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.firstname?.message}
                        </Typography> */}
                      </Grid>
                      {/* LAST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required

                          id="lastname"
                          name="lastname"
                          label="Last Name"
                          //defaultValue={kids_datas.lastname}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("lastname")}
                          error={kidsFormErrors.lastname ? true : false}
                          helperText={kidsFormErrors.lastname ? kidsFormErrors.lastname?.message : ''}
                        />
                        {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.lastname?.message}
                        </Typography> */}
                      </Grid>

                      {/* USERNAME */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="username"
                          name="username"
                          label="User Name"
                          //defaultValue={kids_datas.username}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("username")}
                          autoComplete="new-username"
                          error={kidsFormErrors.username ? true : false}
                          helperText={kidsFormErrors.username ? kidsFormErrors.username?.message : ''}
                        />
                        {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.username?.message}
                        </Typography> */}
                      </Grid>

                      {/* PASSWORD FIELD */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          autoComplete="new-password"
                          id="password"
                          name="password"
                          label="Password(for kids login)"
                          type="password"
                          //defaultValue={kids_datas.password}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("password")}
                          error={kidsFormErrors.password ? true : false}
                          helperText={kidsFormErrors.password ? kidsFormErrors.password?.message : ''}
                        />
                        {/* <Typography variant="inherit" color="textSecondary">
                          {kidsFormErrors.password?.message}
                        </Typography> */}
                      </Grid>
                    </Grid>
                  </div>
                  {/* FORM SECTION END*/}

                  {/* IMAGE SECTION */}
                  <div className="col-sm-6">
                    <div className="upload_section">
                      <div class="d-flex align-items-center justify-content-center">
                        <CameraAltSharpIcon
                          sx={{ fontSize: 80 }}
                          color="action"
                        />
                      </div>
                      <div class="d-flex align-items-center justify-content-center">
                        <p className="mykids_upload_text">
                          Upload pic or select your avatar
                        </p>
                      </div>

                      <div class="d-flex align-items-center justify-content-center">
                        <Avatar src={account.photoURL} alt="photoURL" />
                        <Avatar src={account.photoURL} alt="photoURL" />
                        <Avatar src={account.photoURL} alt="photoURL" />
                        <Avatar src={account.photoURL} alt="photoURL" />
                      </div>
                    </div>
                  </div>
                  {/* IMAGE SECTION END */}
                </div>
              </DialogContent>
              <DialogActions >
                <Button
                type="submit"
                  onClick={kidsFormSubmit(onAddSubmit)}
                  className="my_kids_save_btn mb-3"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/*  End of Add kid dialogue box*/}
      </Container>
    </div>
  );
}
