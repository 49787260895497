import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";

const GetMyCourse = (id) => {
    return http.get(api_path.e_get_my_courses+'/'+id, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const addExpert = (data) => {
    return http.post(api_path.e_addexpert,data, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

//#region SCHEDULES
const GetTodaySchedule = (id) => { 
    return http.get(api_path.e_get_todays_schedule+ '/'+id,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetTommorowsSchedule = (id) => { 
    return http.get(api_path.e_get_tommorows_schedule+ '/'+id,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetThisWeekSchedule = (id) => { 
    return http.get(api_path.e_get_thisweek_schedule+ '/'+id,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetThisMonthSchedule = (id) => { 
    return http.get(api_path.e_get_thismonth_schedule+ '/'+id,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const getAllScheduledClasses = (id) => { 
    return http.get(api_path.e_getAllScheduledClasses+ '/'+id,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};
//#endregion

const GetPodDetailByCourseId = (courseid, expertid) => { 
    return http.get(api_path.e_GetPodDetailByCourseId+ '/?courseid='+courseid+'&expertid='+expertid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetCurriculum = (id) => {
    return http.get(api_path.e_getcurriculum + id, { headers: authHeader() }).then((response) => {
      return response.data;
      });
};
const ExpertService = {
    GetMyCourse,
    addExpert,
    GetTodaySchedule,
    GetTommorowsSchedule,
    GetThisWeekSchedule,
    GetThisMonthSchedule,
    getAllScheduledClasses,
    GetPodDetailByCourseId,
    GetCurriculum
};

export default ExpertService;