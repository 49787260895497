
const mime_types = {
    zip: "application/zip",
    png: "image/png",
    jpeg: "image/jpeg",
    pdf: "application/pdf",
    doc: "application/msword",
    ppt: "application/vnd.ms-powerpoint"
};
    
export default mime_types;