import React, { useState, useEffect, useCallback } from "react";
//import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  FormControl,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardActions,
  CardContent,
  Radio,
  Paper,
  Stack,
  Modal
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//DATETIME FORMAT
import moment from 'moment';
import { format, parseISO } from 'date-fns';
//import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";

import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import "./create-course-stepper.css";

//Icon
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SourceIcon from "@mui/icons-material/Source";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from '@mui/material/IconButton';
import LockOutlined from "@mui/icons-material/LockOutlined";
import LockResetOutlined from "@mui/icons-material/LockResetOutlined";
import Whiteboard_Icon from "../../../../assets/img/white-board/whiteboard-24.png";
//Icon

import Autocomplete from "@mui/material/Autocomplete";
import AccordionDetails from "@mui/material/Accordion";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { SnackbarProvider, useSnackbar } from 'notistack';

// import InputAdornment from "@mui/material/InputAdornment";

// import AccountCircle from "@mui/icons-material/AccountCircle";

// import { DragDropContext, Draggable } from "react-beautiful-dnd";
// import { Droppable } from "react-beautiful-dnd";

import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import PreAssessmentList from "./pre-assessment-list/pre-assessment-list";

// import CourseCurriculum from "./course-curriculum";

//DATE PICKER
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//ASSETS
import Sampleimg from "../../../../assets/img/white-board/whiteboard.png";

//FILE UPLOAD
import DropFileInput from '../../../../components/drop-file-input/DropFileInput';

//CONFIGS
import navigate_route_config from "../../../../config/navigate_route_config";
import conditionalConfig from '../../../../config/conditional_config';
import questionTypes from '../../../../config/question-types';
import curriculamTypes from '../../../../config/curriculam_types';
import curriculam_content_file_types from '../../../../config/curriculam_content_file_types';
import fileTypesConfig from '../../../../config/file_types';
import mimeTypes from '../../../../config/mime-types';
import games from "../../../../config/games";
import url_configs from "../../../../config/url";

//SERVICES
import AdminService from "../../../../services/admin-service";
import DocumentService from "../../../../services/document-service";
import CourseService from "../../../../services/courses-service";
import LookUpervice from "../../../../services/look-up-service";

//MODELS
import courseInfoModel from "../models/course_info_model";
import preAssessmentModel from '../models/pre_assessment_model';
import quizModel from '../models/quiz_model';
import answerModel from '../models/answer_model';
import courseSessionModel from '../models/course_session_model';

import { getCurrentUserData, getUnique } from "../../../../utils/util";
import storage_configs from "../../../../config/storage-config";
import Iconify from "../../../../components/Iconify";
import icons_config from "../../../../config/icons";
import ConfirmationPopup from "../../../../components/confirmation-popup/confirmation-popup";
import SessionService from "../../../../services/session-service";
import DraggableAccordion from "../../../../components/draggable-accordion/draggable-accordion";
const courseData = {
  courseid: "",
  no_of_sessions: "",
  sessionList: [],
  documentid: ""
};

//#region STEPPER
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Course Overview",
    "Session",
    "Course Curriculum",
    "Streams",
  ];
}

const contentSessionQuizModelStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const CourseStepper = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  // confirmation box
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationPopupOnOkay, setConfirmationPopupOnOkay] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const [courseid, setCouseId] = useState(); // Get Values from Step - 1 Submit
  const [number_of_sessions, setNumberOfSessions] = useState(); // Get Values from Step - 1 Submit and used in Step - 2 -> SESSTIONS LIST
  const [sessionList, setSessionList] = useState([]); // Used in Step - 2 -> SESSTIONS LIST
  const [contentSessionList, setContentSessionList] = useState([]); //Used in Step - 4 COURSE CONTENT SESSION
  var tempSessionList = []; // Get Values from Step - 1 Submit for Step - 2 SESSIONS LIST

  const [streamSessionList, setStreamSessionList] = useState([]); // Used in Step - 5 Add Stream
  const [expertList, setExpertList] = useState([]); // Used in Step - 5 Add Stream

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditionalConfig.role.admin) {
      const currentStep = localStorage.getItem(storage_configs.localStorage.admin.courses.currentStep);
      if (currentStep) {
        setActiveStep(Number(currentStep));
        loadDataBasedOnsteps(currentStep);
      }
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }

    //getContentSessionList('pod');
  }, []);

  //NAVIGATE BETWEEN STEPS
  const loadDataBasedOnsteps = (step) => {
    if (Number(step) == 0) {
      getCourseCategories();
    } else if (Number(step) == 1) {
      GetCourseDetailsByCourseId();
    } else if (Number(step) == 2) {
      getContentSessionList('curriculum');
    } else if (Number(step) == 3) {
      getExpertList();
    }
  }

  //CONFIRMATION BOX CANCEL
  const handleCancel = () => {
    setConfirmationDialogOpen(false); // Close the dialog without deleting.
  };

  const handleOnDeleteClick = () => {
    if (confirmationPopupOnOkay === "cover-pic") {
      deleteCourseCoverPicture();
    } else if (confirmationPopupOnOkay === "course-desc") {
      deleteCourseDescriptionDocument();
    }
    handleCancel();
  }

  const handleDeleteOpenDialog = (from) => {
    setConfirmationPopupOnOkay(from);
    setConfirmationDialogOpen(true);
  };

  //#region DDLISTS DECLARATIONS
  const [categoryList, setCategoryList] = useState([]);
  //#endregion

  //#region DDLISTS API
  const getCourseCategories = () => {
    console.log('Get Categories');
    LookUpervice.GetCourseCategory().then(response => {
      console.log(response.data)
      setCategoryList(response.data);
      //CALLING THIS API FOR UPDATE
      const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
      if (updCourseId) {
        GetCourseInfoForStep1Form(response.data, updCourseId);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }
  /** GET SESSION LIST */
  //Used in Content Session(Step 4)  and Pods(Step 5)
  const getContentSessionList = (form) => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    if (updCourseId)
      CourseService.GetCourseDetailsByCourseId(updCourseId).then(response => {
        if (form == 'curriculum') {
          console.log("currii:: ", response);
          getAllActiveQuizTemplate();
          getAllWhiteboardTemplate();
          setContentSessionList([]);
          let tempSessionList = [];

          response.data.coursesession.forEach(element => {
            let tempSessionObj = {
              coursesessionid: "",
              name: "",
              curriculumdetail: [],
              contentTypes: []
            };
            SessionService.getAdminCourseContentBySessionId(element.id).then(response => {
              if (response && response.success == "true") {
                console.log('getAdminCourseContentBySessionId', response)
                //setWhiteboardList(response.data.coursecontentsessionwhiteboard);
                // GET CONTENT DETAILS
                if (response.data && response.data.coursecontentsessiondetail !== null && response.data.coursecontentsessiondetail.length > 0) {
                  response.data.coursecontentsessiondetail.forEach(contentDetEle => {
                    if (contentDetEle.status == 1) {
                      let contentObj = {
                        subject: contentDetEle.subject,
                        curriculumlink: contentDetEle.curriculumlink,
                        contenttype: conditionalConfig.contentTypes.file,
                        curriculumtype: conditionalConfig.curriculam_types.content,
                        sequence: contentDetEle.sequence,
                        items: [],
                      };
                      if (contentObj.curriculumlink != "" && contentObj.curriculumlink != null && contentObj.curriculumlink != undefined) {
                        contentObj.curriculumlink = contentDetEle.curriculumlink;
                        contentObj.contenttype = conditionalConfig.contentTypes.pasteUrl;
                        tempSessionObj.curriculumdetail.push(contentObj);
                      } else {
                        if (contentObj.document) {
                          contentObj.contenttype = conditionalConfig.contentTypes.file;
                          let docObj = {
                            documentid: contentObj.document.id,
                            documenttype: contentObj.document.mimetype,
                            documentname: contentObj.document.originalname,
                            documenturl: contentObj.document.path
                          }
                          contentObj.items.push(docObj);
                        }
                        tempSessionObj.curriculumdetail.push(contentObj);
                        // DocumentService.getDocumentById(contentDetEle.documentid).then(response => {
                        //   console.log('Document', response.data);
                        //   if (response.data) {
                        //     let docObj = {
                        //       documentid: response.data.id,
                        //       documenttype: response.data.mimetype,
                        //       documentname: response.data.originalname,
                        //       documenturl: contentDetEle.document.documenturl
                        //     }
                        //     contentObj.items.push(docObj);
                        //   }
                        //   tempSessionObj.curriculumdetail.push(contentObj);
                        // }).catch(e => {
                        //   console.log(e);
                        // });
                      }
                    }
                  });
                }

                //GET SESSION WHITEBOADS
                if (response.data.coursecontentsessionwhiteboard && response.data.coursecontentsessionwhiteboard.length > 0) {
                  response.data.coursecontentsessionwhiteboard.forEach(whiteboardDetEle => {
                    if (whiteboardDetEle.status == 1) {
                      let whiteboardItems = [];
                      let questionItemObj = {
                        templateid: whiteboardDetEle.templateid,
                        template_ids: whiteboardDetEle.template_ids,
                        is_locked: whiteboardDetEle.is_locked
                      }
                      whiteboardItems.push(questionItemObj);

                      let contentObj = {
                        subject: whiteboardDetEle?.group_name,
                        curriculumtype: conditionalConfig.curriculam_types?.whiteboard,
                        templateid: whiteboardDetEle?.whiteboard_template?.id,
                        templatename: whiteboardDetEle?.group_name,
                        slug: whiteboardDetEle?.whiteboard_template?.slug,
                        items: whiteboardItems,
                        sequence: whiteboardDetEle.sequence
                      };
                      tempSessionObj.curriculumdetail.push(contentObj);
                    }
                  });
                }

                /** GAMES */
                if (response.data.coursecontentsessiongame && response.data.coursecontentsessiongame.length > 0) {
                  response.data.coursecontentsessiongame.forEach(obj => {
                    if (obj.status == 1) {
                      let gameItems = [];
                      gameItems.push(obj);

                      let contentObj = {
                        subject: obj?.name,
                        curriculumtype: conditionalConfig.curriculam_types?.games,
                        templateid: obj?.puzzleid,
                        templatename: obj?.name,
                        slug: '',
                        items: gameItems,
                        sequence: obj.sequence
                      };
                      tempSessionObj.curriculumdetail.push(contentObj);
                    }
                  });
                }

                /** QUIZ */
                if (response.data.coursecontentsessionquiz && response.data.coursecontentsessionquiz.length > 0) {
                  response.data.coursecontentsessionquiz.forEach(quizEle => {
                    if (quizEle.status == 1) {
                      if (quizEle.coursequestion) {
                        let tempQuizItems = [];

                        let tempQuizObj = {
                          answerlist: quizEle.coursequestion.answerlist,
                          question: quizEle.coursequestion.question,
                          questiontype: quizEle.coursequestion.questiontype,
                          sequence: '1',
                          templateid: quizEle?.templateid
                        }

                        tempQuizObj.answerlist.forEach(element => {
                          if (element.correct && element.correct == '1') {
                            element.correct = 'true'
                          } else {
                            element.correct = 'false'
                          }
                        });

                        tempQuizItems.push(tempQuizObj);
                        if (tempSessionObj.curriculumdetail) {
                          const checkDuplicateTemplateId = tempSessionObj.curriculumdetail.filter(e => e.curriculumtype == conditionalConfig.curriculam_types?.quiz && e.templateid == quizEle?.templateid);
                          if (checkDuplicateTemplateId && checkDuplicateTemplateId.length == 0) {
                            let contentObj = {
                              subject: quizEle.coursequestion?.question,
                              curriculumtype: conditionalConfig.curriculam_types?.quiz,
                              templateid: quizEle?.templateid,
                              templatename: quizEle?.template?.name,
                              slug: '',
                              items: tempQuizItems,
                              sequence: quizEle.sequence
                            };
                            tempSessionObj.curriculumdetail.push(contentObj);
                          } else {
                            tempSessionObj.curriculumdetail.forEach(element => {
                              if (element.templateid == quizEle?.templateid) {
                                element.items.push(tempQuizObj);
                              }
                            });
                            // checkDuplicateTemplateId[0].items.push(tempQuizObj);
                            // //tempSessionObj.curriculumdetail = tempSessionObj.curriculumdetail.filter(e=> e.curriculumtype == conditionalConfig.curriculam_types?.quiz && e.templateid !== quizEle?.templateid)
                            // tempSessionObj.curriculumdetail.push(checkDuplicateTemplateId[0]);
                          }
                        }

                      }
                    }
                  });
                }
                tempSessionObj.coursesessionid = element.id;
                tempSessionObj.name = element.name;
                tempSessionObj.contentTypes = curriculamTypes.types;
                tempSessionList.push(tempSessionObj);
              }
            })
              .catch(e => {
                console.log(e);
              });

          });
          setTimeout(() => {
            tempSessionList.forEach(element => {
              element.curriculumdetail.sort((a, b) => Number(a.sequence) - Number(b.sequence))
            });
            setContentSessionList(tempSessionList.sort((a, b) => Number(a.coursesessionid) - Number(b.coursesessionid)));
            console.log('get upd curriculum::', tempSessionList)
          }, 5000);

        } else if (form == 'pod') {
          setStreamSessionList([]);
          let tempStreamSessionList = [];
          response.data.coursesession.forEach(element => {
            let tempStreamSessionObj = {
              coursesessionid: element.id,
              coursesessionname: element.name,
              startdate: "",
              from: "",
              to: ""
            };
            tempStreamSessionList.push(tempStreamSessionObj);
          });
          setStreamSessionList(tempStreamSessionList);
          getExpertList();
          //console.log(tempStreamSessionList)
        } else {
          return
        }
      })
        .catch(e => {
          console.log(e);
        });
  }

  /** GET EXPERT LIST */
  //Used in Course Stream Dropdown
  const getExpertList = () => {
    AdminService.getAllActiveExperts().then(response => {
      console.log(response.data)
      setExpertList(response.data);
      GetCourseStreams();
    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion

  //GENERATE SESSIONS LIST FOR STEP 2
  const generateSessionList = (noOfSessions) => {
    tempSessionList = [];
    for (var i = 1; i <= noOfSessions; i++) {
      const tempSessionName = 'Session ' + i;
      const tempSessionObj = {
        name: tempSessionName,
        description: ""
      }
      tempSessionList.push(tempSessionObj);
    }
    setSessionList(tempSessionList);
    return tempSessionList;
  }

  //#region STEP - 1 COURSE OVERVIEW
  const [language, setLanguage] = React.useState("");
  const [courseCoverPic, setcourseCoverPic] = React.useState("");
  const [courseCoverPicLink, setcourseCoverPicLink] = React.useState("");
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  //const [courseid, setCourseId] = useState("");

  /** STEP - 1 CHANGE EVENTS */
  const handleCategoriesChange = (cat_data) => {
    let categories = [];
    cat_data.forEach(element => {
      let categoryObj = {
        categoryid: !element.id ? element.categoryid : element.id,
        name: element.name
      }
      categories.push(categoryObj);
    });
    setSelectedCategories(categories);
    setValue("categories", categories, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  //#region API SERVICE
  /** DELETE COURSE COVER PICTURE */
  const deleteCourseCoverPicture = () => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    if (updCourseId) {
      var data = {
        courseid: updCourseId,
        documentid: courseCoverPic
      }
      if (data.documentid) {
        CourseService.deleteCourseInfoCoverPicture(data).then(response => {
          console.log('File deleted', response.data);
          setcourseCoverPic("");
          setcourseCoverPicLink("");
          enqueueSnackbar('Cover picture removed successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          getCourseCategories();
        })
          .catch(e => {
            console.log(e);
          });
      }

    }
  }

  /** GET COURSE INFO API */
  const GetCourseInfoForStep1Form = (categoryList, updCourseId) => {
    CourseService.GetCourseInfoByCourseId(updCourseId).then(response => {
      console.log(response);
      if (response && response.data && response.data.coursecategory && response.data.coursecategory.length > 0) {
        let catList = [];
        response.data.coursecategory.forEach(element => {
          if (categoryList && categoryList.length > 0 && element.status == 1) {
            let catObj = {
              categoryid: element.category,
              name: categoryList.filter(e => e.id == element.category)[0].name
            }
            catList.push(catObj);
          }
        });
        setTimeout(() => {
          setValue("categories", catList, {
            shouldValidate: true,
            shouldDirty: true
          });
          setSelectedCategories(catList);
        }, 2000);
      }

      if (response && response.data && response.data.courseinfo && response.data.courseinfo.length > 0) {
        setTimeout(() => {
          setValue("course_name", response.data.courseinfo[0].name, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("selected_language", response.data.courseinfo[0].language, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("isFree", response.data.courseinfo[0].isFree, {
            shouldValidate: true,
            shouldDirty: true
          });
          if (response.data.courseinfo[0].document) {
            setcourseCoverPic(response.data.courseinfo[0].document.id);
            setcourseCoverPicLink(response.data.courseinfo[0].document.path);
          }

          setValue("course_price", response.data.courseinfo[0].price.toString(), {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("course_price_inr", response.data.courseinfo[0].price_inr.toString(), {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("number_of_sessions", response.data.courseinfo[0].noofsessions, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("min_number_of_learners", response.data.courseinfo[0].minnolearners, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("max_number_of_learners", response.data.courseinfo[0].maxnolearners, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("price_per_lesson", response.data.courseinfo[0].priceperlesson, {
            shouldValidate: true,
            shouldDirty: true
          });
        }, 2000);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  const GetCourseInfoByCourseId = (updCourseId) => {
    CourseService.GetCourseInfoByCourseId(updCourseId).then(response => {
      console.log(response);
      if (response && response.data && response.data.courseinfo && response.data.courseinfo.length > 0) {
        generateSessionList(response.data.courseinfo[0].noofsessions);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  /** CREATE COURSE INFO API */
  const createCourseInfo = (data) => {
    var createCourseInfoModel = {
      name: data.course_name,
      category: selectedCategories,
      language: data.selected_language.toString(),
      documentid: courseCoverPic.toString(),
      price: data.course_price,
      price_inr: data.course_price_inr,
      priceperlesson: data.price_per_lesson,
      minnolearners: data.min_number_of_learners,
      maxnolearners: data.max_number_of_learners,
      noofsessions: data.number_of_sessions,
      isFree: data.isFree,
      createdby: getCurrentUserData().id.toString()
    };
    console.log('createCourseInfoModel', createCourseInfoModel);
    CourseService.createcourseinfo(createCourseInfoModel)
      .then(response => {
        console.log(response);
        if (response && response.data && response.success === 'true') {

          setCouseId(response.data.courseid);
          localStorage.setItem(storage_configs.localStorage.admin.courses.courseId, response.data.courseid.toString());
          const tempNoOfSessions = Number(response.data.noofsessions);
          setNumberOfSessions(tempNoOfSessions)

          tempSessionList = [];

          //GENERATE SESSIONS LIST FOR STEP 2
          tempSessionList = generateSessionList(tempNoOfSessions);

          enqueueSnackbar('Course overview added successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "1");
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));

          //STORE COURSE INFO (STEP-1) VALUES IN LOCAL STORAGE
          var storeStepOneInfo = {
            step: 1,
            name: "Course Overview",
            data: response.data,
            courseId: response.data.courseid,
            no_of_sessions: Number(response.data.noofsessions),
            sessionList: tempSessionList
          }
          localStorage.setItem("step_1", JSON.stringify(storeStepOneInfo));
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  /** UPDATE COURSE INFO API */
  const updateCourseInfo = (data) => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    var updateCourseInfoModel = {
      courseid: localStorage.getItem(storage_configs.localStorage.admin.courses.courseId),
      name: data.course_name,
      category: selectedCategories,
      language: data.selected_language.toString(),
      documentid: courseCoverPic.toString(),
      price: data.course_price,
      price_inr: data.course_price_inr,
      priceperlesson: data.price_per_lesson,
      minnolearners: data.min_number_of_learners.toString(),
      maxnolearners: data.max_number_of_learners.toString(),
      noofsessions: data.number_of_sessions.toString(),
      isFree: !data.isFree ? false : true,
      updatedby: getCurrentUserData().id.toString()
    };
    console.log('updateCourseInfoModel', updateCourseInfoModel);
    CourseService.updateCourseInfo(updCourseId, updateCourseInfoModel)
      .then(response => {
        console.log("Response upd course", response);
        if (response && response.data && response.success === 'true') {

          setCouseId(response.data.courseid);
          const tempNoOfSessions = Number(response.data.noofsessions);
          setNumberOfSessions(tempNoOfSessions)
          tempSessionList = [];

          //GENERATE SESSIONS LIST FOR STEP 2
          tempSessionList = generateSessionList(tempNoOfSessions);

          enqueueSnackbar('Course overview modified successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "1");

          //STEP 2 CALL
          GetCourseDetailsByCourseId();
          //STORE COURSE INFO (STEP-1) VALUES IN LOCAL STORAGE
          var storeStepOneInfo = {
            step: 1,
            name: "Course Overview",
            data: response.data,
            courseId: response.data.courseid,
            no_of_sessions: Number(response.data.noofsessions),
            sessionList: tempSessionList
          }
          localStorage.setItem("step_1", JSON.stringify(storeStepOneInfo))

        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  //#region STEP - 1 DOCUMENT UPLOAD
  const handleCourseCoverPicChange = (files) => {
    console.log(files);
    if (files && files.length > 0) {
      var file = files[0];
      //setcourseCoverPic(file.path);
      //console.log(courseCoverPic);
      DocumentService.upload_document(file).then(response => {
        console.log('File Uploaded Successfully');
        console.log(response);
        setcourseCoverPic(response.data.id);
      })
        .catch(e => {
          console.log(e);
        });
      console.log(files)
    }
  }
  //#endregion

  //#endregion

  //#endregion

  //#region STEP - 2 COURSE DETAIL
  const [sessionDescDocumentStep_2, setSessionDescDocumentStep_2] = React.useState("");
  const [isUpdateForm, setUpdateForm] = React.useState(false);
  const [courseDescriptionDocument, setCourseDescriptionDocument] = React.useState();
  const [courseDetail, setCourseDetail] = React.useState();

  /** SESSIONS LIST CHANGE EVENT */
  const handleSessionsChange = (e) => {
    const { id, value } = e.target;
    const index = id.charAt(id.length - 1);
    sessionList[index].description = value;
    setSessionList(sessionList);
  };

  //#region STEP - 2 API SERVICE

  /** DELETE COURSE COVER PICTURE */
  const deleteCourseDescriptionDocument = () => {
    if (courseDetail) {
      var data = {
        id: courseDetail.id,
        documentid: courseDetail.documentid
      }
      if (data.documentid) {
        CourseService.deleteCourseDescriptionDocument(data).then(response => {
          console.log('File deleted', response.data);
          setCourseDescriptionDocument(null);
          enqueueSnackbar('Course description document removed successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        })
          .catch(e => {
            console.log(e);
          });
      }

    }
  }

  //#region STEP - 2 DOCUMENT UPLOAD
  const handleCourseSessionDescDocumentChange = (files) => {
    console.log(files);
    if (files && files.length > 0) {
      var file = files[0];
      DocumentService.upload_document(file).then(response => {
        console.log('File Uploaded Successfully');
        console.log(response);
        setSessionDescDocumentStep_2(response.data.id);
      })
        .catch(e => {
          console.log(e);
        });
      console.log(files)
    }
  }
  //#endregion

  /** CREATE COURSE DETAIL API */
  const createCourseDetail = (data) => {
    var createCourseDetailModel = {
      courseid: courseid.toString(),
      coursedetail: {
        description: data.course_description,
        documentid: sessionDescDocumentStep_2.toString()
      },
      coursesession: sessionList
    };
    console.log(createCourseDetailModel);
    CourseService.createcoursedetail(createCourseDetailModel)
      .then(response => {
        console.log('CreateCourseDetail');
        console.log(response);
        localStorage.setItem('coursedetailid', response.data.id);
        //coursedetailid
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Sessions added successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          //STEP 3 GET SESSIONS FOR CURRICULUM
          getContentSessionList('curriculum');
          localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "2");
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  /** UPDATE COURSE DETAIL API */
  const updateCourseDetail = (data) => {
    var updateCourseDetailModel = {
      id: courseDetail.id.toString(),
      description: data.course_description,
      documentid: sessionDescDocumentStep_2.toString(),
      updatedby: getCurrentUserData().id.toString()
    };
    console.log(updateCourseDetailModel);
    CourseService.updatecoursedetail(updateCourseDetailModel)
      .then(response => {
        console.log('updatecoursedetail');
        console.log(response);
        localStorage.setItem('coursedetailid', courseDetail.id);
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Course detail modified successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          //STEP 3 GET SESSIONS FOR CURRICULUM
          getContentSessionList('curriculum');
          localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "2");
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  // /** GET COURSE CONTENT SESSION LIST FROM API */
  const GetCourseDetailsByCourseId = () => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    if (updCourseId)
      CourseService.GetCourseDetailsByCourseId(updCourseId).then(response => {
        console.log("GetCourseDetailsByCourseId", response)
        //If response.data is null consider as Add New Details. Else consider as Edit.
        if (response.data) {
          console.log("Course detail", "Update")
          setValue("course_description", response.data.coursedetail[0].description.toString(), {
            shouldValidate: true,
            shouldDirty: true
          });

          setCourseDetail(response.data.coursedetail[0]);

          localStorage.setItem('coursedetailid', response.data.coursedetail[0].id);

          setCourseDescriptionDocument(response.data.coursedetail[0].document);
          if (response.data.coursedetail[0].document) {
            setSessionDescDocumentStep_2(response.data.coursedetail[0].document.id)
          }
          if (response.data.coursesession && response.data.coursesession.length > 0) {
            setUpdateForm(true)
          } else {
            setUpdateForm(false)
          }
        } else {
          console.log("Course detail", "Create")
          const step_1_Detail = JSON.parse(localStorage.getItem('step_1'));
          if (step_1_Detail && step_1_Detail.sessionList) {
            setSessionList(step_1_Detail.sessionList);
          } else {
            GetCourseInfoByCourseId(updCourseId);
          }
        }
      })
        .catch(e => {
          console.log(e);
        });
  }

  //#endregion

  //#endregion

  //#region STEP - 3 PRE ASSESSMENT
  const [anchorElPreAssessment, setAnchorElPreAssessment] = React.useState(null);
  const [qtype, setQtype] = React.useState(null);
  const openPreAssessment = Boolean(anchorElPreAssessment);
  const [quizList, setQuizList] = useState([]);

  const handleClickAddPreAssessment = (event) => {
    setAnchorElPreAssessment(event.currentTarget);
  };

  const handleCloseAddPreAssessment = () => {
    setAnchorElPreAssessment(null);
  };

  const handlePreAssessmentType = (type, e) => {
    console.log(type);
    setQtype(type);
    addPreAssessmentQuiz(type);
    handleCloseAddPreAssessment();
  };

  const addPreAssessmentQuiz = (qtype) => {
    let newQuiz = {
      question: "",
      questiontype: "",
      answerlist: [
        {
          answer: "",
          correct: "",
          documentid: "1"
        }
      ],
      sequence: "1"
    };
    newQuiz.questiontype = qtype;
    setQuizList([...quizList, newQuiz]);
  }

  const removePreAssessmentQuiz = (index) => {
    let data = [...quizList];
    data.splice(index, 1)
    setQuizList(data)
  }

  const addPreAssessmentAns = (qindex) => {
    let newAns = answerModel;
    let data = [...quizList];
    data[qindex].answerlist.push(newAns);
    setQuizList(data);
  }

  const removePreAssessmentAns = (qindex, aindex) => {
    let data = [...quizList];
    data[qindex].answerlist.splice(aindex, 1);
    setQuizList(data);
  }

  const handlePreAssessmentQuestionFormChange = (index, e) => {
    const { name, value } = e.target;
    quizList[index].question = value;
    setQuizList(quizList);
    console.log(quizList);
  }

  const handlePreAssessmentAnswerFormChange = (qindex, aindex, e) => {
    const { name, value } = e.target;
    let currentQList = [...quizList];
    let currentQuestion = currentQList[qindex];

    if (currentQuestion && currentQuestion.questiontype == questionTypes.shortAnswer) {
      currentQList[qindex].answerlist[aindex] = {
        answer: value,
        correct: "true",
        documentid: "1"
      };
    } else {
      currentQList[qindex].answerlist[aindex] = {
        answer: value,
        correct: "",
        documentid: "1"
      };
    }
    setQuizList(currentQList);
    console.log(currentQList);
  }

  const selectPreAssessmentCorrectAns = (qindex, aindex, e) => {
    const { name, value } = e.target;
    let currentQList = [...quizList];
    let currentQuestion = currentQList[qindex];

    if (currentQuestion && currentQuestion.questiontype == questionTypes.multiChoice) {
      currentQList[qindex].answerlist.forEach(element => {
        element.correct = "false";
      });
      currentQList[qindex].answerlist[aindex].correct = 'true';
    }
    setQuizList(currentQList);
    console.log(currentQList);
  }
  //#region STEP - 3 API SERVICE

  /** CREATE PRE ASSESSMENT API */
  const createPreAssessment = (data) => {
    var createPreAssessmentModel = {
      courseid: courseid.toString(),
      name: "PreAssessment_" + courseid.toString(),
      quiz: data
    };
    console.log(createPreAssessmentModel);
    CourseService.createpreassessment(createPreAssessmentModel)
      .then(response => {
        console.log(response);
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Pre assessment added successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          getContentSessionList('curriculum');
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }
  //#endregion

  //#endregion

  //#region STEP - 4 COURSE CONTENT

  /** CONTENT DETAILS: TYPE: CONENT DECLARATIONS */
  const [anchorElContentFileTypes, setAnchorElContentFileTypes] = React.useState(null);
  const openContentFileTypes = Boolean(anchorElContentFileTypes);

  /** CONTENT DETAILS: TYPE: QUIZ DECLARATIONS */
  const [openAddQuizInContentSession, setOpenAddQuizInContentSession] = React.useState(false);
  const [quizTemplateList, setQuizTemplateList] = React.useState([]);
  const [selectedQuizTemplate, setSelectedQuizTemplate] = React.useState("");
  const [currentContentSessionTemplateQuizPopupIndex, setCurrentContentSessionTemplateQuizPopupIndex] = React.useState();
  const [currentContentIndex, setCurrentContentIndex] = React.useState();
  const [currentContentDetailIndex, setCurrentContentDetailIndex] = React.useState();
  const [acceptContentFileType, setAcceptContentFileType] = React.useState();

  /** CONTENT DETAILS: TYPE: WHITEBOARD DECLARATIONS */
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);
  const [openAddWhiteboardInContentSession, setOpenAddWhiteboardInContentSession] = React.useState(false);
  const [whiteboardItems, setWhiteboardItems] = React.useState([]);

  /** CONTENT DETAILS: TYPE: GAME DECLARATIONS */
  const [gameTemplateList, setGameTemplateList] = React.useState(games.gameList);
  const [openAddGameInContentSession, setOpenAddGameInContentSession] = React.useState(false);

  //#region CONTENT SESSION LIST - ADD, DELETE OPTIONS(CONTETNT, QUIZ, GAME)

  const getAllWhiteboardTemplate = () => {
    // let templates = JSON.parse(localStorage.getItem('whiteboard-templates'));
    // if(templates && templates.length > 0){
    //   setWhiteboardTemplateList(templates);
    // }

    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      console.log(response.data);
      setWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getAllActiveQuizTemplate = () => {
    AdminService.getAllActiveQuizTemplate().then(response => {
      console.log(response.data);
      setQuizTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** ADD OPTIONS(CONTETNT, QUIZ, GAME)  */
  const chooseCurriculamType = (type, index, e) => {
    if (type == conditionalConfig.curriculam_types.content) {
      let tempSessionList = contentSessionList;
      let contentObj = {
        subject: "",
        curriculumlink: "",
        contenttype: "",
        curriculumtype: type,
        sequence: "1",
        items: [],
      };
      tempSessionList[index].curriculumdetail.push(contentObj);
      setContentSessionList([...contentSessionList], tempSessionList);
    } else if (type == conditionalConfig.curriculam_types.quiz) {
      setCurrentContentSessionTemplateQuizPopupIndex(index);
      handleOpenQuizInContentSession();
    } else if (type == conditionalConfig.curriculam_types.whiteboard) {
      setCurrentContentSessionTemplateQuizPopupIndex(index);
      setWhiteboardItems([]);
      handleOpenWhiteboardInContentSession();
    } else if (type == conditionalConfig.curriculam_types.games) {
      setCurrentContentSessionTemplateQuizPopupIndex(index);
      handleOpenGameInContentSession();
    }
  }

  /** REMOVE OPTIONS(CONTETNT, QUIZ, GAME)  */
  const removeCurriculam = (mainIndex, delIndex, type) => {
    let data = [...contentSessionList];
    if (type == conditionalConfig.curriculam_types.content) {
      data[mainIndex].curriculumdetail.splice(delIndex, 1);
      setContentSessionList(data)
    } else if (type == conditionalConfig.curriculam_types.quiz) {
      data[mainIndex].curriculumdetail.splice(delIndex, 1);
      setContentSessionList(data)
    }
  }
  //#endregion

  //#region EVENTS: CONTENT DETAILS: TYPE: QUIZ
  /** OPEN TEMPLATE QUIZ POPUP */
  const handleOpenQuizInContentSession = () => {
    setOpenAddQuizInContentSession(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseQuizInContentSession = () => setOpenAddQuizInContentSession(false);

  /** ADD TEMPLATE QUIZ IN CURRICULAM DETAILS */
  const handleSelectQuizTemplateInContentSession = (quizTemplateId, quizTemplateName, questions, e) => {
    console.log(questions);
    let questionItems = [];
    questions.forEach(element => {
      let questionItemObj = {
        templateid: element.quiztemplateid,
        question: element.question,
        questiontype: element.questiontype,
        sequence: element.sequence.toString(),
        answerlist: element.answers
      }
      questionItems.push(questionItemObj);
    });
    let tempSessionList = contentSessionList;
    let contentObj = {
      subject: quizTemplateName,
      curriculumtype: conditionalConfig.curriculam_types.quiz,
      templateid: quizTemplateId,
      templatename: quizTemplateName,
      items: questionItems,
      sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
    };
    tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
    handleCloseQuizInContentSession();
  }
  //#endregion

  //#region EVENTS: CONTENT DETAILS: TYPE: CONTENT

  /** CONTENT FORMS */
  //LESSON TEXT FIELD
  const handleContentFormChange = (curIndex, contentIndex, e, field) => {
    let tempSessionList = contentSessionList;
    if (field == 'subject') {
      tempSessionList[curIndex].curriculumdetail[contentIndex].subject = e.target.value;
      setContentSessionList([...contentSessionList], tempSessionList);
    } else if (field == 'curriculumlink') {
      tempSessionList[curIndex].curriculumdetail[contentIndex].curriculumlink = e.target.value;
      setContentSessionList([...contentSessionList], tempSessionList);
    }
  }

  //SELECTED FILE TYPE
  const selectedFileType = (e, index, fType) => {
    e.stopPropagation();
    console.log(e);
    console.log(index);
    console.log(fType);
    setAcceptContentFileType(fType);
  }
  //CONTENT URL/EMBED CODE
  const handleContentType = (curIndex, contentIndex, e, contentType) => {
    let tempSessionList = contentSessionList;
    if (contentType == conditionalConfig.contentTypes.pasteUrl) {
      tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].contenttype = contentType;
      setContentSessionList([...contentSessionList], tempSessionList);
    } else if (contentType == conditionalConfig.contentTypes.file) {
      handleCloseContentFileTypes();
      let files = e.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        //setcourseCoverPic(file.path);
        console.log(file);
        DocumentService.upload_document(file).then(response => {
          console.log('File Uploaded Successfully');
          console.log(response);
          //tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].items = [];
          let documentObj = {
            documentname: response.data.originalname,
            documentid: response.data.id,
            documenttype: response.data.mimetype
          }
          tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].contenttype = contentType;
          tempSessionList[currentContentIndex].curriculumdetail[currentContentDetailIndex].items.push(documentObj);
          setContentSessionList([...contentSessionList], tempSessionList);
        })
          .catch(e => {
            console.log(e);
          });
        console.log(files)
      }
    }
  }

  const handleClickContentFileTypes = (event, currentIndex, curDetIndex) => {
    setCurrentContentIndex(currentIndex); // Main Content Index
    setCurrentContentDetailIndex(curDetIndex); // Content Detail Index
    setAnchorElContentFileTypes(event.currentTarget);
  };

  const handleCloseContentFileTypes = () => {
    setAnchorElContentFileTypes(null);
  };
  //#endregion

  //#region EVENTS: CONTENT DETAILS: TYPE: WHITEBOARD
  /** OPEN TEMPLATE QUIZ POPUP */
  const handleOpenWhiteboardInContentSession = () => {
    setOpenAddWhiteboardInContentSession(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseWhiteboardInContentSession = () => setOpenAddWhiteboardInContentSession(false);

  /** ADD TEMPLATE QUIZ IN CURRICULAM DETAILS */
  // const handleSelectWhiteboardTemplateInContentSession = (id, name, slug, e) => {
  //   let whiteboardItems = [];
  //   let questionItemObj = {
  //     templateid: id,
  //     is_locked: true
  //   }
  //   whiteboardItems.push(questionItemObj);

  //   let tempSessionList = contentSessionList;
  //   let contentObj = {
  //     subject: name,
  //     curriculumtype: conditionalConfig.curriculam_types.whiteboard,
  //     templateid: id,
  //     templatename: name,
  //     items: whiteboardItems,
  //     sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
  //   };
  //   tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
  //   setWhiteboardItems(whiteboardItems);
  // }

  const handleSelectWhiteboardTemplateInContentSession = (id, name, slug, e) => {
    let tempWhiteboardItems = whiteboardItems;
    if (e && e.target.checked == true) {
      let questionItemObj = {
        templateid: id,
        is_locked: true
      }
      tempWhiteboardItems.push(questionItemObj);
    } else {
      tempWhiteboardItems = tempWhiteboardItems.filter(e => e.templateid != id);
    }
    setWhiteboardItems(tempWhiteboardItems);
  }

  /** ADD TEMPLATE WHITEBOARD IN CURRICULAM DETAILS */
  const handleSelectedBoardsClick = (e) => {
    let finalSelectedBoards = [];
    let tempWhiteboardItems = whiteboardItems;
    tempWhiteboardItems = getUnique(tempWhiteboardItems, 'templateid');
    if (tempWhiteboardItems && tempWhiteboardItems.length > 0) {
      let questionItemObj = {
        templateid: null,
        template_ids: "",
        is_locked: true
      }
      for (let i = 0; i < tempWhiteboardItems.length; i++) {
        if (tempWhiteboardItems[i].templateid) {
          if (questionItemObj.template_ids) {
            questionItemObj.template_ids = questionItemObj.template_ids + ',' + tempWhiteboardItems[i].templateid.toString();
          } else {
            questionItemObj.template_ids = tempWhiteboardItems[i].templateid.toString();
          }
        }
      }
      finalSelectedBoards.push(questionItemObj);
      let tempSessionList = contentSessionList;
      let contentObj = {
        subject: 'Playground-' + (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.filter(e => e.curriculumtype == conditionalConfig.curriculam_types.whiteboard).length + 1).toString(),
        curriculumtype: conditionalConfig.curriculam_types.whiteboard,
        templateid: null,
        templatename: '',
        items: finalSelectedBoards,
        sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
      };
      tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
      handleCloseWhiteboardInContentSession();
    }
  }

  const showBoardsList = (bdata) => {
    let boardsList = [];
    if (bdata && bdata.template_ids) {
      let splitBoardIds = bdata.template_ids.split(',');
      if (splitBoardIds && splitBoardIds.length > 0) {
        for (let i = 0; i < splitBoardIds.length; i++) {
          let boardObj = {};
          let actualBoardList = whiteboardTemplateList;
          if (actualBoardList && actualBoardList.length > 0) {
            let newWhiteboardArray = [];
            //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
            actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
              newWhiteboardArray[index] = { ...device }
            })
            boardObj = newWhiteboardArray[0];
            boardObj.is_locked = bdata.is_locked;
            boardsList.push(boardObj);
          }
        }
      }
    }
    console.log('SHOW BOARD LIST ', boardsList)
    return boardsList;
  }

  const openWhiteboard = (obj) => {
    let nLink = url_configs.frontend + 'whiteboard-detail/' + obj.id;
    window.open(nLink, '_blank')
  }

  //#endregion

  //#region EVENTS: CONTENT DETAILS: TYPE: GAME
  /** OPEN TEMPLATE GAME POPUP */
  const handleOpenGameInContentSession = () => {
    setOpenAddGameInContentSession(true)
  };
  /** CLOSE TEMPLATE GAME POPUP */
  const handleCloseGameInContentSession = () => setOpenAddGameInContentSession(false);

  /** ADD TEMPLATE GAME IN CURRICULAM DETAILS */
  const handleSelectGameTemplateInContentSession = (id, name, slug, e) => {
    let whiteboardItems = [];
    let questionItemObj = {
      puzzleid: id,
      documentid: null,
      name: name,
      description: name
    }
    whiteboardItems.push(questionItemObj);

    let tempSessionList = contentSessionList;
    let contentObj = {
      subject: name,
      curriculumtype: conditionalConfig.curriculam_types.games,
      templateid: id,
      templatename: name,
      items: whiteboardItems,
      sequence: (tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.length + 1).toString()
    };
    tempSessionList[currentContentSessionTemplateQuizPopupIndex].curriculumdetail.push(contentObj);
    handleCloseGameInContentSession();
  }

  //#endregion

  const createContentSession = (data) => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    var contentList = data;
    contentList.forEach(element => {
      element.curriculumdetail.forEach((val, idx) => {
        val.sequence = idx;
      });
    });
    let contentSessionModle = {
      courseid: updCourseId.toString(),
      curriculum: contentList
    }
    console.log(contentSessionModle);
    CourseService.updateContent(updCourseId.toString(), contentSessionModle)
      .then(response => {
        console.log(response);
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Content session created successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setActiveStep(activeStep + 1);
          setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "3");
          getContentSessionList('pod');
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  //#endregion

  //#region STEP - 5 COURSE STREAM
  const [streamList, setStreamList] = useState([]);
  const [streamCrudList, setStreamCrudList] = useState([]);
  const [openCoursePodCRUD, setOpenCoursePodCRUD] = React.useState(false);

  /** STREAM EXPERT FIELD CHANGE EVENT */
  const handleStreamExpertFieldChange = (event, streamIndex) => {
    // let tempStreamList = [...streamList];
    // tempStreamList[streamIndex].expertid = event.target.value;
    // setStreamList(tempStreamList);

    let tempStreamCrudList = [...streamCrudList];
    tempStreamCrudList[streamIndex].expertid = event.target.value;
    setStreamCrudList(tempStreamCrudList);
  }

  /** STREAM SESSION FORM CHANGE EVENT */
  const handleStreamSessionChange = (event, field, streamIndex, sessionIndex) => {
    //let tempStreamList = [...streamList];
    let tempStreamCrudList = [...streamCrudList];
    if (sessionIndex == 0 && field == 'startdate') {
      //tempStreamList[streamIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      tempStreamCrudList[streamIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (field == 'startdate') {
      //tempStreamList[streamIndex].session[sessionIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      //setStreamList(tempStreamList);

      tempStreamCrudList[streamIndex].session[sessionIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      setStreamCrudList(tempStreamCrudList);
    } else if (field == 'from') {
      //tempStreamList[streamIndex].session[sessionIndex].from = event.$d;
      //setStreamList(tempStreamList);

      tempStreamCrudList[streamIndex].session[sessionIndex].from = event.$d;
      setStreamCrudList(tempStreamCrudList);
    } else if (field == 'to') {
      //tempStreamList[streamIndex].session[sessionIndex].to = event.$d;
      //setStreamList(tempStreamList);

      tempStreamCrudList[streamIndex].session[sessionIndex].to = event.$d;
      setStreamCrudList(tempStreamCrudList);
    } else {
      return;
    }
  }

  /** CLOSE COURSE POD CRUD POPUP */
  const handleCloseCoursePodCRUD = () => setOpenCoursePodCRUD(false);

  /** ADD POD ITEM */
  const addPodItem = (e) => {
    setStreamCrudList([]);
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    let tempStreamCrudList = [];
    let tempStreamObj = {
      expertid: "",
      startdate: "",
      session: []
    }

    CourseService.GetCourseDetailsByCourseId(updCourseId).then(response => {
      response.data.coursesession.forEach(element => {
        let tempStreamSessionObj = {
          coursesessionid: element.id,
          coursesessionname: element.name,
          startdate: "",
          from: "",
          to: ""
        };
        tempStreamObj.session.push(tempStreamSessionObj);
      });
      //setStreamList([...streamList, tempStreamObj]);
      tempStreamCrudList.push(tempStreamObj);
      setStreamCrudList(tempStreamCrudList);
      setOpenCoursePodCRUD(true);
    }).catch(e => {
      console.log(e);
    });
  }

  const removePodItem = (index, streamObj) => {
    let data = [...streamList];
    data.splice(index, 1)
    setStreamList(data);
    if (streamObj && streamObj.streamid) {
      const data = {
        id: streamObj.streamid,
        updatedby: getCurrentUserData().id.toString()
      }
      console.log("streamOBj", data);
      CourseService.deleteCourseStream(data).then(response => {
        console.log(response);
        enqueueSnackbar('Course stream deleted successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  const GetCourseStreams = () => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    CourseService.GetCoursesDetailsById(updCourseId).then(response => {
      console.log(response);
      //setCourseDetailId(response.data.coursedetail[0].id.toString());
      if (response.data && response.data.coursestream && response.data.coursestream.length > 0) {
        let tempStreamList = [];
        response.data.coursestream.forEach(streamEle => {
          let tempStreamObj = {
            streamid: streamEle.id,
            expertid: streamEle.expertid,
            startdate: streamEle.startdate,
            session: []
          }

          streamEle.coursestreamdetail.forEach(cStreamEle => {
            let tempStreamSessionObj = {
              coursesessionid: "",
              coursesessionname: "",
              startdate: "",
              from: "",
              to: ""
            };
            tempStreamSessionObj.coursesessionid = cStreamEle.coursesessionid;
            tempStreamSessionObj.startdate = cStreamEle.startdate;
            tempStreamSessionObj.from = cStreamEle.startdate + 'T' + cStreamEle.from;
            tempStreamSessionObj.to = cStreamEle.startdate + 'T' + cStreamEle.to;
            tempStreamObj.session.push(tempStreamSessionObj);
          });

          tempStreamList.push(tempStreamObj);
        });

        setStreamList(tempStreamList);
        console.log('tempStreamList', tempStreamList);
      }

    })
      .catch(e => {
        console.log(e);
      });
  }

  /** CREATE COURSE STREAM */
  const createCourseStream = (data) => {
    const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
    let createCourseStreamModel = {
      courseid: updCourseId.toString(),
      coursedetailid: localStorage.getItem('coursedetailid').toString(),
      stream: data
    }
    createCourseStreamModel.stream.forEach(streamObj => {
      streamObj.session.forEach(sessionObj => {
        if (sessionObj && sessionObj.to) {
          sessionObj.to = moment(sessionObj.to, 'DD/MM/YYYY').format('HH:mm:ss');
        }
        if (sessionObj && sessionObj.from) {
          sessionObj.from = moment(sessionObj.from, 'DD/MM/YYYY').format('HH:mm:ss');
        }
      });
    });
    console.log(createCourseStreamModel);
    CourseService.createStream(createCourseStreamModel)
      .then(response => {
        console.log(response);
        handleCloseCoursePodCRUD();
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Course stream created successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          //setActiveStep(activeStep + 1);
          //setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
          loadDataBasedOnsteps(activeStep);
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }
  //#endregion

  //#region DRAGGABLE ACCORDION
  const onClickContent = (idx) => {
    console.log('onClickContent', idx);
    localStorage.setItem('content-idx', idx);
    let tempSessionList = contentSessionList;
    localStorage.setItem('content-list', JSON.stringify(tempSessionList));
  }

  const moveAccordion = (dragIndex, hoverIndex) => {
    var tempList = contentSessionList;
    let tempSessionList = tempList;
    console.log("tempSessionList", tempSessionList);
    const idx = localStorage.getItem('content-idx');
    console.log('CurrentContentIndex', idx)
    if (tempSessionList && tempSessionList.length > 0) {
      const draggedAccordion = tempSessionList[Number(idx)].curriculumdetail[dragIndex];
      const newAccordions = tempSessionList[Number(idx)].curriculumdetail;
      newAccordions.splice(dragIndex, 1);
      newAccordions.splice(hoverIndex, 0, draggedAccordion);
      tempList[Number(idx)].curriculumdetail = newAccordions;
      setContentSessionList([...contentSessionList], tempList);
    }
  };

  //#endregion

  //#region ALL FORM DESIGNS
  function getStepContent(step) {
    /** COURSE OVERVIEW FORM STEP - 1 */
    if (step == 0) {
      return (
        <Grid container spacing={1}>
          <Typography variant="h6" margin="dense">
            Lets start with the course information
          </Typography>
          {/* Course Name */}
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="course_name"
              rules={{ required: "Course name is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="course_name"
                  label="Course name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.course_name}
                  helperText={errors.course_name?.message}
                />
              )}
            />
          </Grid>
          {/* Select Categories */}
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="categories"
              rules={{ required: false }}
              defaultValue={[]}
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  id="categories"
                  multiple
                  filterSelectedOptions
                  options={categoryList}
                  value={value}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, values) => handleCategoriesChange(values)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )
                  }
                />
              )}
            />
          </Grid>
          {/* language */}
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="selected_language"
              rules={{ required: "Language is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl style={{ width: "100%" }} className="mt-2">
                  <InputLabel
                    id="languages"
                    variant="outlined"
                    margin="dense"
                  >
                    Language
                  </InputLabel>
                  <Select
                    labelId="languages"
                    id="languages-select"
                    value={language}
                    label="Language"
                    onChange={handleLanguageChange}
                    margin="dense"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.selected_language}
                    helperText={errors.selected_language?.message}
                  >
                    <MenuItem value={1}>English</MenuItem>
                    <MenuItem value={2}>Tamil</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {/* Upload Cover Picture */}
          <Grid item xs={12} sm={12}>
            {!courseCoverPicLink ? <Controller
              control={control}
              name="course_cover_pic"
              rules={{ required: false }}
              render={({ field }) => (
                <div className="box">
                  <span>Upload course cover picture</span>
                  <DropFileInput
                    {...field}
                    onFileChange={(files) => handleCourseCoverPicChange(files)}
                  />
                </div>
              )}
            /> :
              <div className="course-info-cover-pic-container">
                <span className="course-info-cover-pic-delete-icon" onClick={(e) => handleDeleteOpenDialog('cover-pic')}>
                  <Iconify icon={icons_config.deleteFilled} />
                </span>
                <img width={200} height={200} src={courseCoverPicLink}></img>
              </div>}
          </Grid>
          {/* Price Information */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" margin="dense">
              Price Information
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="isFree"
                  defaultValue={false}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      color="primary"
                      label="Free Course"
                      checked={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors.isFree}
                      helperText={errors.isFree?.message}
                    />
                  )}
                />
              }
              label={
                <Typography color={errors.isFree ? 'error' : 'inherit'}>
                  Free course {getValues('isFree')}
                </Typography>
              }
            />
          </Grid>
          {/* Course Price */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="course_price"
              rules={{ required: "Course price is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="course_price"
                  label="Course price"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.course_price}
                  helperText={errors.course_price?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="course_price_inr"
              rules={{ required: "Course price in INR is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="course_price_inr"
                  label="Price INR"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.course_price_inr}
                  helperText={errors.course_price_inr?.message}
                />
              )}
            />
          </Grid>
          {/* Number Of sessions */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="number_of_sessions"
              rules={{ required: "No. of sessions is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="number_of_sessions"
                  label="Number Of sessions"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.number_of_sessions}
                  helperText={errors.number_of_sessions?.message}
                />
              )}
            />
          </Grid>
          {/* Min.number of learners */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="min_number_of_learners"
              rules={{ required: "Minimum no. of learners is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="min_number_of_learners"
                  label="Min number of learners"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.min_number_of_learners}
                  helperText={errors.min_number_of_learners?.message}
                />
              )}
            />
          </Grid>
          {/* Max.number of learners */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="max_number_of_learners"
              rules={{ required: "Maximum no. of learners is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="max_number_of_learners"
                  label="Max number of learners"
                  variant="outlined"
                  fullWidth
                  type="number"
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.max_number_of_learners}
                  helperText={errors.max_number_of_learners?.message}
                />
              )}
            />
          </Grid>
          {/* pre-course-price number of learners */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="price_per_lesson"
              rules={{ required: "Price per lesson is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="price_per_lesson"
                  label="Price per lesson"
                  variant="outlined"
                  fullWidth
                  type="number"
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.price_per_lesson}
                  helperText={errors.price_per_lesson?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      )
    }
    /** COURSE SESSION FORM STEP - 2 */
    else if (step == 1) {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" margin="dense">
                Lets start with the course information
                {/* {sessionList} */}
              </Typography>
            </Grid>
            {/* COURSE DESCRIPTION FIELD */}
            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name="course_description"
                rules={{ required: "Course description is required" }}
                defaultValue=""
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    id="outlined-multiline-static"
                    label="Course Description"
                    name="course_description_field"
                    multiline
                    fullWidth
                    rows={4}
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.course_description}
                    helperText={errors.course_description?.message}
                  />
                )}
              />
            </Grid>
            {/* COURSE DESCRIPTION FIELD */}
            <Grid item xs={12} sm={12}>
              {!courseDescriptionDocument ?
                <Controller
                  control={control}
                  name="course_desc_document"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <div className="box">
                      <span>Upload course description</span>
                      <DropFileInput
                        {...field}
                        onFileChange={(files) => handleCourseSessionDescDocumentChange(files)}
                      />
                    </div>
                  )}
                /> :
                <div>
                  <Typography variant="h6" margin="dense">
                    Document
                  </Typography>
                  <div className="course-sessions-document-container">
                    <span className="course-sessions-document-name">{courseDescriptionDocument?.originalname}</span>
                    <span className="course-sessions-document-delete-icon" onClick={(e) => handleDeleteOpenDialog('course-desc')}>
                      <Iconify icon={icons_config.deleteFilled} />
                    </span>
                  </div>
                </div>}
            </Grid>
            {/* SESSIONS */}
            {!isUpdateForm ?
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">Sessions</Typography>
              </Grid> : ""}
            {/* SESSIONS LIST */}
            {!isUpdateForm ?
              <Grid item xs={12} sm={12}>
                <div>
                  {sessionList.map((obj, index) => (
                    <Accordion id={'session_accordian_' + index}
                      key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={'session_accordian_summary_' + index}
                        variant="outlined"
                      >
                        <Typography>{obj.name}</Typography>
                      </AccordionSummary>

                      <TextField
                        id={'sessions_desc_' + index}
                        label={obj.name}
                        variant="outlined"
                        multiline
                        fullWidth
                        name={'session_' + index}
                        rows={4}
                        onChange={handleSessionsChange}
                      />
                    </Accordion>
                  ))}
                  {/* <button onClick={hanclick}>submit</button> */}
                </div>
              </Grid> : ""}
          </Grid>
        </>
      );
    }
    /** PRE ASSESSMENT FORM STEP - 3 */

    //#region PRE ASSESSMENT COMMENTING AS OF NOW
    // else if (step == 2) {
    //   return (
    //     <div>
    //       {/* ADD PRE ASSESSMENT MENU BUTTON */}
    //       <Card
    //         id="add_pre_assessment"
    //         className="add_curriculum_card"
    //         elevation={3}
    //         aria-controls={openPreAssessment ? 'add_pre_assessment_menu' : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={openPreAssessment ? 'true' : undefined}
    //         onClick={handleClickAddPreAssessment}>
    //         <Grid container justifyContent="center" textAlign="center">
    //           <AccordionSummary
    //             aria-controls="panel1a-content"
    //             variant="outlined"
    //           >
    //             <Typography>
    //               <AddCircleIcon style={{ marginRight: 7 }} /> Add Question
    //             </Typography>
    //           </AccordionSummary>
    //         </Grid>
    //       </Card>
    //       <Menu
    //         id="add_pre_assessment_menu"
    //         MenuListProps={{
    //           'aria-labelledby': 'add_pre_assessmentn',
    //         }}
    //         anchorEl={anchorElPreAssessment}
    //         open={openPreAssessment}
    //         onClose={handleCloseAddPreAssessment}
    //       >
    //         <MenuItem onClick={(e) => handlePreAssessmentType(questionTypes.shortAnswer, e)} disableRipple>
    //           Short Answer
    //         </MenuItem>
    //         <MenuItem onClick={(e) => handlePreAssessmentType(questionTypes.multiChoice, e)} disableRipple>
    //           Multiple Choice
    //         </MenuItem>
    //       </Menu>
    //       {/* PreAssessmentList */}
    //       <form className="pb-2">
    //         {quizList.map((qObj, index) => {
    //           return (
    //             <Accordion key={index} id={'question_' + index} elevation={12} className="pre_assessment_list_accordion mt-2">
    //               <AccordionSummary
    //                 aria-controls="panel1a-content"
    //                 variant="outlined"
    //               >
    //                 <div className="align-items-center justify-content-between d-flex w-100">
    //                   <Typography>
    //                     <IconButton aria-label="library" color="primary">
    //                       <DragIndicatorIcon color="action" />
    //                     </IconButton>
    //                     Question - {index + 1}
    //                   </Typography>
    //                   <Typography>
    //                     <Stack direction="row" spacing={1}>
    //                       <IconButton aria-label="library" color="primary">
    //                         <LibraryAddIcon />
    //                       </IconButton>
    //                       <IconButton aria-label="delete" onClick={() => removePreAssessmentQuiz(index)}>
    //                         <DeleteIcon />
    //                       </IconButton>
    //                     </Stack>
    //                   </Typography>
    //                 </div>
    //               </AccordionSummary>
    //               <AccordionDetails>
    //                 <Card elevation={12} className="pre_assessment_list_card mt-2">
    //                   <Container spacing={5}>
    //                     <Grid item xs={12} sm={12}>
    //                       <TextField
    //                         name={'question_input_' + index}
    //                         id={'question_input_id_' + index}
    //                         label="Question"
    //                         variant="outlined"
    //                         onChange={event => handlePreAssessmentQuestionFormChange(index, event)}
    //                         fullWidth
    //                         className="lesson_name mt-1"
    //                       />
    //                     </Grid>
    //                     {qObj.answerlist.map((ansObj, aindex) => {
    //                       return (
    //                         <Grid key={index + aindex} item xs={12} sm={12} className="mt-2">
    //                           {qObj.questiontype == questionTypes.multiChoice ?
    //                             //  MULTI CHOICE ANSWERS
    //                             <div>
    //                               <div className="d-flex align-items-center" id={'ans_' + index + '_' + aindex}>
    //                                 <Radio style={{ marginRight: "5" }} />
    //                                 <TextField
    //                                   id={'multi_choice_ans_input_id_' + index + '_' + aindex}
    //                                   name={'multi_choice_ans_input_name_' + index + '_' + aindex}
    //                                   label={'Answer ' + (aindex + 1)}
    //                                   variant="outlined"
    //                                   fullWidth
    //                                   className="lesson_name mt-1"
    //                                   value={ansObj.answer}
    //                                   onChange={event => handlePreAssessmentAnswerFormChange(index, aindex, event)}
    //                                 />
    //                                 <Stack direction="row" spacing={1}>
    //                                   {ansObj.correct != 'true' ? <IconButton aria-label="library" color="primary" onClick={event => selectPreAssessmentCorrectAns(index, aindex, event)}>
    //                                     <CheckIcon />
    //                                   </IconButton> : ""}
    //                                   {/* <IconButton >
    //                                     <CollectionsIcon />
    //                                   </IconButton> */}
    //                                   <IconButton aria-label="delete" onClick={() => removePreAssessmentAns(index, aindex)}>
    //                                     <CloseIcon />
    //                                   </IconButton>
    //                                 </Stack>
    //                               </div>
    //                               {ansObj.correct == 'true' ? <div className="d-flex justify-content-end correct_answer_text">
    //                                 <span>Correct Answer</span>
    //                               </div> : ""}
    //                             </div>
    //                             :
    //                             // SHORT ANSWER
    //                             <div className="d-flex align-items-center" id={'ans_' + index + '_' + aindex}>
    //                               <TextField
    //                                 id={'short_ans_input_id_' + index + '_' + aindex}
    //                                 label="Short Answer"
    //                                 variant="outlined"
    //                                 multiline
    //                                 fullWidth
    //                                 rows={3}
    //                                 className="lesson_name mt-1"
    //                                 onChange={event => handlePreAssessmentAnswerFormChange(index, aindex, event)}
    //                               />
    //                               <CollectionsIcon
    //                                 style={{ marginLeft: "10", marginRight: "10" }}
    //                               />
    //                             </div>}
    //                         </Grid>
    //                       )
    //                     })}
    //                     {qObj.questiontype == questionTypes.multiChoice ? <Grid item xs={12} sm={12} className="mt-2" >
    //                       <div className="d-flex align-items-center cursor-pointer"
    //                         onClick={() => addPreAssessmentAns(index)}>
    //                         <Radio style={{ marginRight: "5" }} />
    //                         <span className="add_option_text">Add option </span>
    //                       </div>
    //                     </Grid> : ""}
    //                     {/* <Grid item xs={12} sm={12} className="mt-2">
    //                       <div className="correct_ans_dis_text">
    //                         <Container>
    //                           <span className="correct_ans_text">
    //                             Correct Answer Description
    //                           </span>
    //                           <TextField
    //                             id="outlined-multiline-static"
    //                             label="Option 4"
    //                             variant="outlined"
    //                             fullWidth
    //                             multiline
    //                             rows={3}
    //                             className="lesson_name mt-1"
    //                           />
    //                         </Container>
    //                       </div>
    //                     </Grid> */}
    //                     {/* <Grid item xs={12} sm={12} className="mt-4">
    //                       <div className="d-flex align-items-center justify-content-end ">
    //                         <LibraryAddIcon style={{ marginRight: "10" }} />
    //                         <DeleteIcon style={{ marginRight: "5" }} />
    //                       </div>
    //                     </Grid> */}
    //                   </Container>
    //                 </Card>
    //               </AccordionDetails>
    //             </Accordion>
    //           )
    //         })}
    //       </form>
    //       {quizList.length > 2 ? <Card
    //         id="add_pre_assessment"
    //         className="add_curriculum_card"
    //         elevation={3}
    //         aria-controls={openPreAssessment ? 'add_pre_assessment_menu' : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={openPreAssessment ? 'true' : undefined}
    //         onClick={handleClickAddPreAssessment}>
    //         <Grid container justifyContent="center" textAlign="center">
    //           <AccordionSummary
    //             aria-controls="panel1a-content"
    //             variant="outlined"
    //           >
    //             <Typography>
    //               <AddCircleIcon style={{ marginRight: 7 }} /> Add Question
    //             </Typography>
    //           </AccordionSummary>
    //         </Grid>
    //       </Card> : ''}
    //       {/* PreAssessmentList */}
    //     </div>
    //   )
    // }
    //#endregion

    /** CONTENT SESSIONS STEP - 4 */
    else if (step == 2) {
      return (
        <div>
          {contentSessionList.map((curObj, curindex) => {
            return (
              <div key={'curriculum_key_' + curObj.coursesessionid} id={'curriculam_' + curindex} className="row main_curriculam">
                {/* ADD CURRICULAM SELECT OPTIONS - CONTENT, QUIZ OR GAMES */}
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 me-3">{curObj.name}</h5>
                  <Stack direction="row" spacing={1}>
                    {curriculamTypes.types.map((curTypes, curTypeIndex) => {
                      return (
                        <Button
                        key={'curriculum-types-btns-'+curTypeIndex}
                          className="add_curriculum_btn"
                          id={'add_curriculam_' + curindex}

                          variant="outlined"
                          startIcon={<AddCircleIcon />}
                          onClick={(e) => chooseCurriculamType(curTypes.type, curindex, e)}
                        >
                          {curTypes.name}
                        </Button>
                      )
                    })}
                  </Stack>
                </div>

                {/* CURRICULAM DETAILS - CONTENT/QUIZ */}
                {curObj.curriculumdetail.map((curDetObj, curDetIndex) => {
                  return (
                    <div>
                      <DraggableAccordion key={'session-drag-key-' + curindex + '-' + curDetIndex} id={'session-drag-id'  + curindex + '-' + curDetIndex} index={curDetIndex} moveAccordion={moveAccordion}>
                        <Accordion onMouseDown={(e) => onClickContent(curindex)} id={'curriculam_content_id_' + curindex + '_' + curDetIndex} elevation={12} className="pre_assessment_list_accordion mt-2">
                          {curDetObj.curriculumtype == conditionalConfig.curriculam_types.content ?
                            // CONTENT TITLE SECTION
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              variant="outlined"
                            >
                              <div className="align-items-center justify-content-between d-flex w-100">
                                <Typography>
                                  <Stack className="align-items-center" direction="row" spacing={1}>
                                    <IconButton aria-label="library" color="primary">
                                      <DragIndicatorIcon color="action" />
                                    </IconButton>
                                    <span>{curDetObj?.subject ? curDetObj?.subject : 'Content'}</span>
                                  </Stack>
                                </Typography>
                                <Typography>
                                  <Stack direction="row" spacing={1}>
                                    {/* <IconButton aria-label="library" color="primary">
                                      <LibraryAddIcon />
                                    </IconButton> */}
                                    <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Stack>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            : curDetObj.curriculumtype == conditionalConfig.curriculam_types.quiz ?
                              // QUIZ TITLE SECTION
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                variant="outlined"
                              >
                                <div className="align-items-center justify-content-between d-flex w-100">

                                  <Typography>
                                    <Stack className="align-items-center" direction="row" spacing={1}>
                                      <IconButton aria-label="library" color="primary">
                                        <DragIndicatorIcon color="action" />
                                      </IconButton>
                                      <span>{curDetObj.templatename}</span>

                                      <span className="quiz-flag">QUIZ</span>
                                    </Stack>

                                  </Typography>
                                  <Typography>
                                    <Stack direction="row" spacing={1}>
                                      {/* <IconButton aria-label="library" color="primary">
                                        <LibraryAddIcon />
                                      </IconButton> */}
                                      <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Stack>
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              : curDetObj.curriculumtype == conditionalConfig.curriculam_types.whiteboard ?
                                // WHITEBOARD TITLE SECTION
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  variant="outlined"
                                >
                                  <div className="align-items-center justify-content-between d-flex w-100">
                                    <Typography>
                                      <IconButton aria-label="library" color="primary">
                                        <DragIndicatorIcon color="action" />
                                      </IconButton>
                                      {/* <span>{curDetObj.templatename}</span> */}
                                      <span>{curDetObj?.subject ? curDetObj?.subject : ''}</span>
                                      <span className="ms-2 whiteboard-flag">WHITEBOARD</span>
                                    </Typography>
                                    <Typography>
                                      <Stack direction="row" spacing={1}>
                                        {/* <IconButton aria-label="library" color="primary">
                                          {curDetObj?.is_locked ?
                                            <LockResetOutlined /> :
                                            <LockOutlined />
                                          }
                                        </IconButton> */}
                                        <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Stack>
                                    </Typography>
                                  </div>
                                </AccordionSummary>
                                :
                                // GAME TITLE SECTION
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  variant="outlined"
                                >
                                  <div className="align-items-center justify-content-between d-flex w-100">
                                    {/* <Typography>
                                    <IconButton aria-label="library" color="primary">
                                      <DragIndicatorIcon color="action" />
                                    </IconButton>
                                    Game - {curDetIndex + 1}
                                  </Typography> */}
                                    <Typography>
                                      <IconButton aria-label="library" color="primary">
                                        <DragIndicatorIcon color="action" />
                                      </IconButton>
                                      <span>{curDetObj.templatename}</span>
                                      <span className="game-flag">GAME</span>
                                    </Typography>
                                    <Typography>
                                      <Stack direction="row" spacing={1}>
                                        {/* <IconButton aria-label="library" color="primary">
                                        <LibraryAddIcon />
                                      </IconButton> */}
                                        <IconButton aria-label="delete" onClick={() => removeCurriculam(curindex, curDetIndex, conditionalConfig.curriculam_types.content)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Stack>
                                    </Typography>
                                  </div>
                                </AccordionSummary>
                          }

                          {/* CONTENT SECTION FORM */}
                          {curDetObj.curriculumtype == conditionalConfig.curriculam_types.content ?
                            <AccordionDetails>
                              <Card
                                elevation={12}
                                className="intro_robot_card_lesson"
                              >
                                <Container spacing={5}>
                                  {/* LESSON NAME TEXTFIELD */}
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      id={'subject_id_' + curindex + '_' + curDetIndex}
                                      name={'subject_' + curindex + '_' + curDetIndex}
                                      label="Lesson Name"
                                      variant="outlined"
                                      fullWidth
                                      className="lesson_name mt-1"
                                      value={curDetObj.subject}
                                      onChange={(e) => handleContentFormChange(curindex, curDetIndex, e, 'subject')}
                                    />
                                  </Grid>

                                  {/* CONTENT URL FIELD */}
                                  {curDetObj.contenttype == conditionalConfig.contentTypes.pasteUrl ? <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                    <TextField
                                      id={'curriculumlink_id' + curindex + '_' + curDetIndex}
                                      name={'curriculumlink_' + curindex + '_' + curDetIndex}
                                      label='Content URL/Embed Code'
                                      variant="outlined"
                                      multiline
                                      fullWidth
                                      rows={4}
                                      value={curDetObj.curriculumlink}
                                      onChange={(e) => handleContentFormChange(curindex, curDetIndex, e, 'curriculumlink')}
                                    />
                                  </Grid> : ""}

                                  {/* DISPLAY UPLOADED CONTENT FILE */}
                                  {curDetObj.contenttype == conditionalConfig.contentTypes.file ? <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                    {curDetObj.items.map((curItemObj, curItemIndex) => {
                                      return <Typography>
                                        <Stack direction="row" spacing={1} className="align-items-center pt-2">
                                          {curItemObj.documenttype == mimeTypes.pdf ?
                                            <img src={fileTypesConfig.pdf} width="30px" /> :
                                            curItemObj.documenttype == mimeTypes.doc ?
                                              <img src={fileTypesConfig.doc} width="30px" /> :
                                              curItemObj.documenttype == mimeTypes.ppt ?
                                                <img src={fileTypesConfig.ppt} width="30px" /> :
                                                <img src={fileTypesConfig.default} width="30px" />}
                                          <span>{curItemObj.documentname}</span>
                                        </Stack>
                                      </Typography>
                                    })}
                                  </Grid> : ""}

                                  <Grid item xs={12} sm={12} className="mt-3 mb-1">
                                    <span className="upload_course">
                                      Upload Course Material
                                    </span>
                                  </Grid>
                                  <Grid container item xs={12} sm={12} spacing={1}>
                                    {/* UPLOAD FILE OPTION */}
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Box
                                        sx={{
                                          p: 2,
                                          border: "1px dashed grey",
                                          height: 150,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item xs={3}>
                                            <CloudUploadIcon
                                              color="action"
                                              sx={{ fontSize: 40 }}
                                            />
                                          </Grid>
                                          <Grid item xs={9}>
                                            <Typography className="upload_file">
                                              Upload files from computer or drag
                                              drop files here
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid container justifyContent="center">
                                          <div>
                                            <Button
                                              className="select_file_btn"
                                              id="content_file_types_select_file_btn"
                                              aria-controls={
                                                openContentFileTypes
                                                  ? "content_file_types_menu"
                                                  : undefined
                                              }
                                              aria-haspopup="true"
                                              aria-expanded={
                                                openContentFileTypes ? "true" : undefined
                                              }
                                              onClick={(e) => handleClickContentFileTypes(e, curindex, curDetIndex)}
                                              variant="outlined"
                                              endIcon={<KeyboardArrowDownIcon />}
                                            >
                                              select file
                                            </Button>
                                            <Menu
                                              id="content_file_types_menu"
                                              MenuListProps={{
                                                "aria-labelledby":
                                                  "content_file_types_select_file_btn",
                                              }}
                                              anchorEl={anchorElContentFileTypes}
                                              open={openContentFileTypes}
                                              onClose={handleCloseContentFileTypes}
                                              TransitionComponent={Fade}
                                            >
                                              {curriculam_content_file_types.types.map((fileTypes, curTypeIndex) => {
                                                return (
                                                  <MenuItem key={curTypeIndex} disableRipple>
                                                    <div className="content-file-upload" onClick={(e) => selectedFileType(e, curTypeIndex, fileTypes.accept)}>
                                                      <label for="content-file-input">
                                                        {fileTypes.name}
                                                      </label>
                                                      <input id="content-file-input" accept={acceptContentFileType} type="file" onChange={(e) => handleContentType(curindex, curDetIndex, e, conditionalConfig.contentTypes.file)} />
                                                    </div>
                                                  </MenuItem>
                                                )
                                              })}
                                            </Menu>
                                          </div>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                    {/* PAST URL OPTION */}
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Box
                                        sx={{
                                          p: 2,
                                          border: "1px dashed grey",
                                          height: 150,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item xs={3}>
                                            <AttachFileOutlinedIcon
                                              color="action"
                                              sx={{ fontSize: 40 }}
                                            />
                                          </Grid>
                                          <Grid item xs={9}>
                                            <Typography className="paste_link">
                                              Paste a link or add a HTML embed code
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid container justifyContent="center">
                                          <Button
                                            className="paste_btn"
                                            variant="outlined"
                                            onClick={(e) => handleContentType(curindex, curDetIndex, e, conditionalConfig.contentTypes.pasteUrl)}
                                          >
                                            Paste URL/code
                                          </Button>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Container>
                              </Card>
                            </AccordionDetails>
                            :
                            curDetObj.curriculumtype == conditionalConfig.curriculam_types.quiz ?
                              <div>
                                {curDetObj.items.map((qObj, qindex) => {
                                  return (
                                    <Card elevation={12} className="pre_assessment_list_card mt-2">
                                      <Container spacing={5}>
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                            name={'question_input_' + qindex}
                                            id={'question_input_id_' + qindex}
                                            label="Question"
                                            variant="outlined"
                                            value={qObj.question}
                                            fullWidth
                                            className="lesson_name mt-1"
                                          />
                                        </Grid>
                                        {qObj.answerlist.map((ansObj, aindex) => {
                                          return (
                                            <Grid key={qindex + aindex} item xs={12} sm={12} className="mt-2">
                                              {qObj.questiontype == questionTypes.multiChoice ?
                                                //  MULTI CHOICE ANSWERS
                                                <div>
                                                  <div className="d-flex align-items-center" id={'ans_' + qindex + '_' + aindex}>
                                                    <Radio style={{ marginRight: "5" }} />
                                                    <TextField
                                                      id={'multi_choice_ans_input_id_' + qindex + '_' + aindex}
                                                      name={'multi_choice_ans_input_name_' + qindex + '_' + aindex}
                                                      label={'Answer ' + (aindex + 1)}
                                                      variant="outlined"
                                                      fullWidth
                                                      className="lesson_name mt-1"
                                                      value={ansObj.answer}
                                                    />
                                                    <Stack direction="row" spacing={1}>
                                                      {ansObj.correct != 'true' ? <IconButton aria-label="library" color="primary">
                                                        <CheckIcon />
                                                      </IconButton> : ""}
                                                      <IconButton aria-label="delete">
                                                        <CloseIcon />
                                                      </IconButton>
                                                    </Stack>
                                                  </div>
                                                  {ansObj.correct == 'true' ? <div className="d-flex justify-content-end correct_answer_text">
                                                    <span>Correct Answer</span>
                                                  </div> : ""}
                                                </div>
                                                :
                                                // SHORT ANSWER
                                                <div className="d-flex align-items-center" id={'ans_' + qindex + '_' + aindex}>
                                                  <TextField
                                                    id={'short_ans_input_id_' + qindex + '_' + aindex}
                                                    label="Short Answer"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    rows={3}
                                                    className="lesson_name mt-1"
                                                    value={ansObj.answer}
                                                  />
                                                  <CollectionsIcon
                                                    style={{ marginLeft: "10", marginRight: "10" }}
                                                  />
                                                </div>}
                                            </Grid>
                                          )
                                        })}

                                        {qObj.questiontype == questionTypes.multiChoice ? <Grid item xs={12} sm={12} className="mt-2" >
                                          <div className="d-flex align-items-center">
                                            <Radio style={{ marginRight: "5" }} />
                                            <span className="add_option_text">Add option </span>
                                          </div>
                                        </Grid> : ""}
                                      </Container>
                                    </Card>
                                  )
                                }
                                )}</div>
                              :
                              curDetObj.curriculumtype == conditionalConfig.curriculam_types.whiteboard ?
                                <AccordionDetails>
                                  <Card
                                    elevation={12}
                                    className="intro_robot_card_lesson"
                                  >
                                    <Container spacing={5}>
                                      {curDetObj?.items.map((curItemObj, curItemIndex) => {
                                        return <div className="w-100">
                                          {showBoardsList(curItemObj).map((curItemWObj, curItemWIndex) => {
                                            return <div className="align-items-center justify-content-between d-flex w-100">
                                              <Typography onClick={(e) => openWhiteboard(curItemWObj)}>
                                                <Stack direction="row" spacing={1} className="align-items-center link_text pt-2" >
                                                  <img className="pe-2" src={Whiteboard_Icon} width="30px" />
                                                  {curItemWObj.name}
                                                </Stack>
                                              </Typography>
                                            </div>
                                          })}
                                        </div>
                                      })}
                                    </Container>
                                  </Card>
                                </AccordionDetails>
                                : ""
                          }
                        </Accordion>
                      </DraggableAccordion>
                    </div>
                  )
                })}

                {/* SELECT QUIZ TEMPLATE POPUP */}
                <Dialog id={'content_quiz_template_popup_' + curindex} open={openAddQuizInContentSession} onClose={handleCloseQuizInContentSession}>
                  <DialogTitle>
                    <div className="d-flex justify-content-center;">
                      <Typography
                        color="primary"
                        style={{ marginRight: 4 }}
                        className="select_quiz_text"
                      >
                        Select Quiz
                      </Typography>

                      <Typography
                        color="action"
                        style={{ marginLeft: "auto" }}
                        onClick={handleCloseQuizInContentSession}
                        className="select_quiz_close_btn"
                      >
                        <CloseIcon />
                      </Typography>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      fullWidth
                      placeholder="Search by Quiz Name"
                    />
                    {quizTemplateList.map((quizTemplateObj, quizTemplateIndex) => {
                      return (
                        <Card
                          key={'content_quiz_template_card_key_' + curindex + '_' + quizTemplateIndex}
                          id={'content_quiz_template_card_id_' + curindex + '_' + quizTemplateIndex}
                          sx={{ marginTop: 1 }}
                          className="select_quiz_card"
                          onClick={(e) => handleSelectQuizTemplateInContentSession(quizTemplateObj.templateid, quizTemplateObj.templatename, quizTemplateObj.questions, e)}>
                          <Grid container spacing={2}>
                            <Grid item sm={3}>
                              <Paper
                                variant="outlined"
                                className="d-flex justify-content-center card_img_background"
                              >
                                <LightbulbIcon sx={{ fontSize: 90 }} />
                              </Paper>
                            </Grid>

                            <Grid item sm={9}>
                              <div>
                                <span className="assessment_questions_text">
                                  {quizTemplateObj.templatename}
                                </span>
                                <p className="questions_text">{quizTemplateObj?.questions?.length} Questions</p>
                                <div className="d-flex  align-items-center;">
                                  <ControlPointIcon style={{ marginRight: 7 }} />
                                  <div>
                                    <span className="courses_linked_text">
                                      0 Courses Linked
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      )
                    })}
                  </DialogContent>
                  {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
                </Dialog>

                {/* SELECT WHITEBOARD TEMPLATE POPUP */}
                <Dialog id={'content_whiteboard_template_popup_' + curindex} open={openAddWhiteboardInContentSession} onClose={handleCloseWhiteboardInContentSession}>
                  <DialogTitle>
                    <div className="d-flex justify-content-center;">
                      <Typography
                        color="primary"
                        style={{ marginRight: 4 }}
                        className="select_quiz_text"
                      >
                        Select Whiteboard
                      </Typography>

                      <Typography
                        color="action"
                        style={{ marginLeft: "auto" }}
                        onClick={handleCloseWhiteboardInContentSession}
                        className="select_quiz_close_btn"
                      >
                        <CloseIcon />
                      </Typography>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      fullWidth
                      placeholder="Search by Quiz Name"
                    />
                    {whiteboardTemplateList.map((wBoardTemplateObj, wBoardTemplateIndex) => {
                      return (
                        <div key={'content_whiteboard_template_key_' + curindex + '_' + wBoardTemplateIndex} id={'content_whiteboard_template_id_' + curindex + '_' + wBoardTemplateIndex} className="d-flex align-items-center">
                          <Checkbox id={'content_whiteboard_template_checkbox_id_' + curindex + '_' + wBoardTemplateIndex}
                            onChange={(e) => handleSelectWhiteboardTemplateInContentSession(wBoardTemplateObj.id, wBoardTemplateObj.name, wBoardTemplateObj.slug, e)} />
                          <Card
                            key={'content_whiteboard_template_card_key_' + curindex + '_' + wBoardTemplateIndex}
                            id={'content_whiteboard_template_card_id_' + curindex + '_' + wBoardTemplateIndex}
                            sx={{ marginTop: 1 }}
                            className="select_quiz_card">
                            <Grid container spacing={2}>
                              <Grid item sm={3}>
                                <Paper
                                  variant="outlined"
                                  className="d-flex justify-content-center card_img_background"
                                >
                                  <img src={Sampleimg} />
                                  {/* <LightbulbIcon sx={{ fontSize: 90 }} /> */}
                                </Paper>
                              </Grid>

                              <Grid item sm={9}>
                                <div>
                                  <span className="assessment_questions_text">
                                    {wBoardTemplateObj.name}
                                  </span>
                                  {/* <div className="d-flex  align-items-center;">
                                    <ControlPointIcon style={{ marginRight: 7 }} />
                                    <div>
                                      <span className="courses_linked_text">
                                        0 Courses Linked
                                      </span>
                                    </div>
                                  </div> */}
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )
                    })}
                  </DialogContent>
                  <DialogActions className="dialog_action">
                    <Button variant="contained" className="add_new_quiz_btn" onClick={(e) => handleSelectedBoardsClick(e)}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* SELECT GAME TEMPLATE POPUP */}
                <Dialog id={'content_game_template_popup_' + curindex} open={openAddGameInContentSession} onClose={handleCloseGameInContentSession}>
                  <DialogTitle>
                    <div className="d-flex justify-content-center;">
                      <Typography
                        color="primary"
                        style={{ marginRight: 4 }}
                        className="select_game_text"
                      >
                        Select Game
                      </Typography>

                      <Typography
                        color="action"
                        style={{ marginLeft: "auto" }}
                        onClick={handleCloseGameInContentSession}
                        className="select_game_close_btn"
                      >
                        <CloseIcon />
                      </Typography>
                    </div>
                  </DialogTitle>
                  <DialogContent>

                    {gameTemplateList.map((gameTemplateObj, gameTemplateIndex) => {
                      return (
                        <Card
                          key={'content_game_template_card_key_' + curindex + '_' + gameTemplateIndex}
                          id={'content_game_template_card_id_' + curindex + '_' + gameTemplateIndex}
                          sx={{ marginTop: 1 }}
                          className="select_quiz_card"
                          onClick={(e) => handleSelectGameTemplateInContentSession(gameTemplateObj.id, gameTemplateObj.name, gameTemplateObj.routeName, e)}>
                          <Grid container spacing={2}>
                            <Grid item sm={3}>
                              <Paper
                                variant="outlined"
                                className="d-flex justify-content-center card_img_background"
                              >
                                <img src={gameTemplateObj.img} />
                                {/* <LightbulbIcon sx={{ fontSize: 90 }} /> */}
                              </Paper>
                            </Grid>

                            <Grid item sm={9}>
                              <div>
                                <span className="assessment_questions_text">
                                  {gameTemplateObj.name}
                                </span>
                                <div className="d-flex  align-items-center;">
                                  <ControlPointIcon style={{ marginRight: 7 }} />
                                  <div>
                                    <span className="courses_linked_text">
                                      0 Courses Linked
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      )
                    })}
                  </DialogContent>
                </Dialog>

              </div>
            )
          })}
        </div>
      )
    }
    /** CONTENT POD/STREAM STEP - 5 */
    else if (step == 3) {
      return (
        <div>
          <div className="d-flex align-items-center">
            <Stack direction="row" spacing={1}>
              <Button
                className="add_pod_btn"
                id={'add_pod_'}
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={(e) => addPodItem(e)}
              >
                Add Pod
              </Button>
            </Stack>
          </div>

          {streamList.map((steamObj, steamIndex) => {
            return <Accordion key={'stream_' + steamIndex} className="mt-2 pre_assessment_list_accordion" elevation={12}>
              <AccordionSummary
                aria-controls="panel1a-content"
                variant="outlined"
              >
                <div className="align-items-center justify-content-between d-flex w-100">
                  <Typography>
                    <Stack className="align-items-center" direction="row" >
                      <IconButton aria-label="library" color="primary">
                        <DragIndicatorIcon color="action" />
                      </IconButton>
                      <span>Pod - {steamIndex + 1}</span>
                    </Stack>
                  </Typography>
                  <Typography>
                    <Stack direction="row" spacing={1}>
                      {/* <IconButton aria-label="library" color="primary">
                                      <LibraryAddIcon />
                                    </IconButton> */}
                      <IconButton aria-label="delete" onClick={(e) => removePodItem(steamIndex, steamObj)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Container spacing={5}>
                  {/* SELECT EXPERT FIELD */}
                  <Grid item xs={12} sm={12}>
                    <FormControl className="w-100" disabled="true">
                      <InputLabel
                        id={'stream_expert_id_' + steamIndex}
                        variant="outlined"
                        margin="dense">
                        Assign Expert
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId={'stream_expert_id_' + steamIndex}
                        label="Assign Expert"
                        id={'stream_expert_id_' + steamIndex}
                        name={'stream_expert_' + steamIndex}
                        value={steamObj.expertid}
                        onChange={(e) => handleStreamExpertFieldChange(e, steamIndex)}
                        margin="dense"
                        variant="outlined"
                      >
                        {expertList.map((expertObj, expertIndex) => {
                          return <MenuItem value={expertObj.id}>{expertObj.firstname}</MenuItem>
                        }
                        )}
                      </Select>
                    </FormControl>
                    {/* <Autocomplete
                      id={'stream_expert_id_'+steamIndex}
                      name={'stream_expert_'+steamIndex}
                      options={expertList}
                      getOptionLabel={(option) => option.firstname}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={steamObj.expertid}
                      onChange={(e) => handleStreamExpertFieldChange(e, steamIndex)}
                      renderInput={(params) => (
                        <TextField {...params} label="Assign Expert" />
                      )}
                    /> */}
                  </Grid>
                  {/* SESSIONS LIST */}
                  {steamObj.session.map((streamSessionObj, streamSessionIndex) => {
                    return <div key={'stream_sessions_key_' + steamIndex + '_' + streamSessionIndex}>
                      <Grid item xs={12} sm={12} md={12} lg={12} className="mt-4">
                        <div>
                          <span className="session_text mt-sm-1">{streamSessionObj.coursesessionname}</span>
                        </div>
                      </Grid>
                      {/* SESSIONS FORM */}
                      <Grid item xs={12} sm={12} className=" mb-4">
                        <Grid container alignItems="center" spacing={1}>
                          {/* SESSION DATE */}
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="mt-sm-1">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  fullWidth
                                  disabled="true"
                                  label="Start Date"
                                  id={'session_start_date_id_' + steamIndex + '_' + streamSessionIndex}
                                  name={'session_start_date_' + steamIndex + '_' + streamSessionIndex}
                                  inputFormat="DD/MM/YYYY"
                                  value={streamSessionObj.startdate}
                                  onChange={(e) => handleStreamSessionChange(e, 'startdate', steamIndex, streamSessionIndex)}
                                  renderInput={(params) => <TextField
                                    fullWidth
                                    {...params} />}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                          {/* SESSION FROM TIME */}
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className=" mt-sm-1">
                              {/* <TextField
                                fullWidth
                                label="From"
                                id={'session_from_id_' + steamIndex + '_' + streamSessionIndex}
                                name={'session_from_' + steamIndex + '_' + streamSessionIndex}
                                value={streamSessionObj.from}
                                onChange={(e) => handleStreamSessionChange(e, 'from', steamIndex, streamSessionIndex)}
                              /> */}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  disabled="true"
                                  label="From"
                                  id={'session_from_id_' + steamIndex + '_' + streamSessionIndex}
                                  name={'session_from_' + steamIndex + '_' + streamSessionIndex}
                                  value={streamSessionObj.from}
                                  onChange={(e) => handleStreamSessionChange(e, 'from', steamIndex, streamSessionIndex)}
                                  renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                          {/* SESSION TO TIME */}
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className=" mt-sm-1">
                              {/* <TextField
                                fullWidth
                                label="To"
                                id={'session_to_id_' + steamIndex + '_' + streamSessionIndex}
                                name={'session_to_' + steamIndex + '_' + streamSessionIndex}
                                value={streamSessionObj.to}
                                onChange={(e) => handleStreamSessionChange(e, 'to', steamIndex, streamSessionIndex)}
                              /> */}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  disabled="true"
                                  label="To"
                                  id={'session_to_id_' + steamIndex + '_' + streamSessionIndex}
                                  name={'session_to_' + steamIndex + '_' + streamSessionIndex}
                                  value={streamSessionObj.to}
                                  onChange={(e) => handleStreamSessionChange(e, 'to', steamIndex, streamSessionIndex)}
                                  renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  })}

                </Container>
              </AccordionDetails>
            </Accordion>
          })}
          {/* COURSE POD ADD/UPDATE */}
          <Dialog id={'course_stream_crud_popup'} open={openCoursePodCRUD} onClose={handleCloseCoursePodCRUD}>
            <DialogTitle>
              <div className="d-flex justify-content-center;">
                <Typography
                  color="primary"
                  style={{ marginRight: 4 }}
                  className="select_game_text"
                >
                  Create Pod
                </Typography>

                <Typography
                  color="action"
                  style={{ marginLeft: "auto" }}
                  onClick={handleCloseCoursePodCRUD}
                  className="select_game_close_btn"
                >
                  <CloseIcon />
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              {streamCrudList.map((steamObj, steamIndex) => {
                return <Accordion key={'stream_' + steamIndex} className="mt-2 pre_assessment_list_accordion" elevation={12}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    variant="outlined"
                  >
                    <div className="align-items-center justify-content-between d-flex w-100">
                      <Typography>
                        <Stack className="align-items-center" direction="row" >
                          <IconButton aria-label="library" color="primary">
                            <DragIndicatorIcon color="action" />
                          </IconButton>
                          <span>Pod - {steamIndex + 1}</span>
                        </Stack>
                      </Typography>
                      <Typography>
                        <Stack direction="row" spacing={1}>
                          {/* <IconButton aria-label="library" color="primary">
                                      <LibraryAddIcon />
                                    </IconButton> */}
                          {/* <IconButton aria-label="delete" onClick={(e) => removePodItem(steamIndex)}>
                        <DeleteIcon />
                      </IconButton> */}
                        </Stack>
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container spacing={5}>
                      {/* SELECT EXPERT FIELD */}
                      <Grid item xs={12} sm={12}>
                        <FormControl className="w-100">
                          <InputLabel
                            id={'crud_stream_expert_id_' + steamIndex}
                            variant="outlined"
                            margin="dense">
                            Assign Expert
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId={'crud_stream_expert_id_' + steamIndex}
                            label="Assign Expert"
                            id={'crud_stream_expert_id_' + steamIndex}
                            name={'crud_stream_expert_' + steamIndex}
                            value={steamObj.expertid}
                            onChange={(e) => handleStreamExpertFieldChange(e, steamIndex)}
                            margin="dense"
                            variant="outlined"
                          >
                            {expertList.map((expertObj, expertIndex) => {
                              return <MenuItem value={expertObj.id}>{expertObj.firstname}</MenuItem>
                            }
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* SESSIONS LIST */}
                      {steamObj.session.map((streamSessionObj, streamSessionIndex) => {
                        return <div key={'stream_sessions_key_' + steamIndex + '_' + streamSessionIndex}>
                          <Grid item xs={12} sm={12} md={12} lg={12} className="mt-4">
                            <div>
                              <span className="session_text mt-sm-1">{streamSessionObj.coursesessionname}</span>
                            </div>
                          </Grid>
                          {/* SESSIONS FORM */}
                          <Grid item xs={12} sm={12} className=" mb-4">
                            <Grid container alignItems="center" spacing={1}>
                              {/* SESSION DATE */}
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className="mt-sm-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                      fullWidth
                                      label="Start Date"
                                      id={'session_start_date_id_' + steamIndex + '_' + streamSessionIndex}
                                      name={'session_start_date_' + steamIndex + '_' + streamSessionIndex}
                                      inputFormat="DD/MM/YYYY"
                                      value={streamSessionObj.startdate}
                                      onChange={(e) => handleStreamSessionChange(e, 'startdate', steamIndex, streamSessionIndex)}
                                      renderInput={(params) => <TextField
                                        fullWidth
                                        {...params} />}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </Grid>
                              {/* SESSION FROM TIME */}
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className=" mt-sm-1">
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                      label="From"
                                      id={'session_from_id_' + steamIndex + '_' + streamSessionIndex}
                                      name={'session_from_' + steamIndex + '_' + streamSessionIndex}
                                      value={streamSessionObj.from}
                                      onChange={(e) => handleStreamSessionChange(e, 'from', steamIndex, streamSessionIndex)}
                                      renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </Grid>
                              {/* SESSION TO TIME */}
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className=" mt-sm-1">
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                      label="To"
                                      id={'session_to_id_' + steamIndex + '_' + streamSessionIndex}
                                      name={'session_to_' + steamIndex + '_' + streamSessionIndex}
                                      value={streamSessionObj.to}
                                      onChange={(e) => handleStreamSessionChange(e, 'to', steamIndex, streamSessionIndex)}
                                      renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      })}

                    </Container>
                  </AccordionDetails>
                </Accordion>
              })}
            </DialogContent>
            <DialogActions className="dialog_action">
              <Button variant="contained" className="add_new_quiz_btn" onClick={(e) => createCourseStream(streamCrudList)}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }
  //#endregion

  //#region STEPPER COMMON
  const isStepOptional = (step) => {
    return step === 1 || step === 2;
    //return null;
  };

  const isStepSkipped = (step) => {
    //loadDataBasedOnsteps(step)
    return skippedSteps.includes(step);
  };

  /** NEXT BUTTON CLICK */
  const handleNext = (data) => {
    console.log(data);
    console.log(activeStep);
    //Save Course Info
    if (activeStep === 0) {
      const updCourseId = localStorage.getItem(storage_configs.localStorage.admin.courses.courseId);
      if (updCourseId) {
        updateCourseInfo(data);
      } else {
        createCourseInfo(data);
      }
    } else if (activeStep === 1) {
      if (courseDetail) {
        updateCourseDetail(data);
      } else {
        createCourseDetail(data);
      }
    }
    // else if (activeStep === 2) {
    //   console.log('Submit PreAssessment');
    //   console.log(quizList);
    //   createPreAssessment(quizList);
    // } 
    else if (activeStep === 2) {
      console.log('Submit Content Session');
      console.log(contentSessionList);
      createContentSession(contentSessionList);
    } else if (activeStep === 3) {
      console.log('Submit Content Stream');
      //createCourseStream(streamList);
      closeCreateCourses();
      // setActiveStep(activeStep + 1);
      // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
    }
  };

  /** PREVIOUS BUTTON CLICK */
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, (activeStep - 1));
    loadDataBasedOnsteps((activeStep - 1));
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
    localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, (activeStep + 1));
    loadDataBasedOnsteps(activeStep + 1)
  };
  //#endregion

  const closeCreateCourses = () => {
    navigate(navigate_route_config.admin.courses);
  }

  return (
    <div>
      <nav className="stepper_nav navbar">
        <div className="container-fluid d-flex align-items-center">
          <h2>Add new course</h2>
          <Stack direction="row" spacing={1}>
            <IconButton aria-label="delete" onClick={closeCreateCourses}>
              <CloseIcon />
            </IconButton>
          </Stack>
          {/* <Button variant="contained" className="publish_btn">Publish</Button> */}
        </div>
      </nav>
      <div>
        <Grid container spacing={12}>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <Box className="stepper-section">
              <Typography variant="h6" className="completed-text">
                {activeStep + ""}/5 completed
              </Typography>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                className="stepper-section-1"
              >
                {steps.map((step, index) => {
                  const labelProps = {};
                  const stepProps = {};
                  if (isStepOptional(index)) {
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step {...stepProps} key={index}>
                      <StepLabel {...labelProps}>{step}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={9} lg={9} >
            {activeStep === steps.length ? (
              <Container component="main" maxWidth="md" className="stepper_total_content">
                <Grid container>
                  <div className="w-100 text-center">

                    <Typography variant="h4" align="center">
                      Course created successfully. To publish the course click the publish button below.
                    </Typography>
                    <Stack className="justify-content-center" direction="row" spacing={1}>
                      <Button
                        id="prev-btn"
                        onClick={closeCreateCourses}
                      >
                        Close
                      </Button>
                      <Button
                        id="next-btn"
                        variant="contained"
                        type="submit"
                        onClick={closeCreateCourses}
                      >
                        Publish
                      </Button>
                    </Stack>

                  </div>
                </Grid></Container>
            ) : (
              <>
                <Container component="main" maxWidth="md" className="stepper_total_content">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      {/* STEPPER COMPLETE COURSE CREATE FORM */}
                      <form onSubmit={handleSubmit(handleNext)}>
                        {getStepContent(activeStep)}
                        <Button
                          id="prev-btn"
                          className={classes.button}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          prev
                        </Button>
                        {isStepOptional(activeStep) && (
                          <Button
                            className={classes.button}
                            variant="contained"
                            onClick={handleSkip}
                            id="next-btn"
                          >
                            skip
                          </Button>
                        )}
                        <Button
                          id="next-btn"
                          className={classes.button}
                          variant="contained"
                          // onClick={handleNext}
                          type="submit"
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : "Next"}
                        </Button>
                      </form>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}></Grid>
                  </Grid>
                </Container>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <ConfirmationPopup open={confirmationDialogOpen} onDelete={handleOnDeleteClick} onCancel={handleCancel} onClose={() => setConfirmationDialogOpen(false)}> </ConfirmationPopup>
    </div>
  );
};
//#endregion STEPPER

export default CourseStepper;
