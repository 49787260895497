import { configureStore } from '@reduxjs/toolkit'
import settingsReducer from './slices/settingsSlice'
import zoomReducer from './slices/zoomSlice'
//for zoom
// import userReducer from './slices/userSlice'

export default configureStore({
  reducer: {
      settings:settingsReducer,
      //for zoom
      zoom:zoomReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['zoom/setClient','zoom/stream'],
        ignoredActionPaths: ['payload','zoom'],
        ignoredPaths: ['zoom.zoomClient','zoom.stream'],
      },
    }),
})