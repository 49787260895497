import React, { useEffect, useState, createRef, useRef } from "react";
import { toPng } from "html-to-image";
import { useNavigate, useParams } from "react-router-dom";
import "./class-room.css";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Col, Container, Row } from "reactstrap";
import {
  FaVideo,
  FaMicrophone,
  FaRegHandPaper,
  FaMicrophoneSlash,
  FaVideoSlash,
  FaPhoneSlash,
} from "react-icons/fa";
import { BsFileEarmarkText } from "react-icons/bs";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

//Packages
import { Menu, MenuItem, Typography, Stack, ListItemIcon } from "@mui/material";
import { MdClose } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import { SnackbarProvider, useSnackbar } from "notistack";

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

//ASSETS
import Book_icon_img from "../../../../assets/img/courses_img/book_icon_img.png";
import ShortLogo from "../../../../assets/img/short_logo.png";
import VideoOnImg from "../../../../assets/img/header_img/video_icon_img.png";
import VideoOffImg from "../../../../assets/img/header_img/video_muted.png";
import VoiceOnImg from "../../../../assets/img/header_img/voice_icon_img.png";
import VoiceOffImg from "../../../../assets/img/header_img/audio_muted.png";

//COMPONENTS
import CourseList from "../../../../components/classroom/v2/course/course";
import CourseView from "../../../../components/classroom/v2/course-view/course-view";

import NotesList from "../../../../components/classroom/v2/notes/notes";

import ZoomVideo from "@zoom/videosdk";
import Iconify from "../../../../components/Iconify";
import {
  checkIsZoomSignatureAlreadyExist,
  clearClassRoomSessionData,
  generateVideoToken,
  storeZoomSignature,
  zoomInitiated,
} from "../../../../utils/util.js";
import {
  setZoomStatus,
  setClient,
  setStream,
  setCameraStatus,
  setParticipants,
} from "../../../../store/slices/zoomSlice";
import {
  setMenu,
  setIsGalleryView,
} from "../../../../store/slices/settingsSlice";
import { useScreenshot } from "use-react-screenshot";
import {
  setMainMenu,
  setIsFluid,
  setAutoPlayGameRouteName,
  setSelectedContent,
  setSelectedCurriculamType,
  setTopicName,
  setBreakoutCurrentScreenData,
} from "../../../../store/slices/settingsSlice";

//UTILS
import {
  getCurrentUserData,
  getInitialsFromFullName,
  getChats,
  storeChats,
  getOpenedChatGroup,
  storeOpenedChatGroup,
  removeOpenedChatGroup,
  changeBreakoutSessionStatus,
  getBreakoutSessionStatus,
  getUnique,
} from "../../../../utils/util";

//SERVICES
import MemberService from "../../../../services/member-service";
import DocumentService from "../../../../services/document-service";
import SessionService from "../../../../services/session-service";

//CONFIG
import navigate_route_config from "../../../../config/navigate_route_config";
import storage_configs from "../../../../config/storage-config";
import url_configs from "../../../../config/url";
import fileTypesConfig from "../../../../config/file_types";
import error_messages from "../../../../config/error_messages";
import zoomConfigs from "../../../../config/zoom";
import games from "../../../../config/games";
import conditional_configs from "../../../../config/conditional_config";
import icons_config from "../../../../config/icons";
import assets from "../../../../config/assets";

import GroupSpace from "../../../learners/group-space/task-assign-v2";

import BreakoutWhiteboard from "../../../learners/group-space/breakout-whiteboard-v2";

import io from "socket.io-client";

import LoadingSpinner from "../../../shared/Loader/LoadingSpinner";
import slugify from "react-slugify";
import ZoomService from "../../../../services/zoom-service";
import { events } from "../../../../config/events";
import { snackbar_options } from "../../../../config/snackbar_options";
import { getExploreName } from "../../../../utils/platform";
import { getActiveCamera, getActiveMicrophone, getActiveSpeaker } from "../../../../utils/zoomFunctions";

let chat_server_socket;
let b_room_socket;

const quiz = {
  topic: "Javascript",
  level: "Beginner",
  totalQuestions: 10,
  perQuestionScore: 5,
  totalTime: 60, // in seconds
  questions: [
    {
      question:
        "Which function is used to serialize an object into a JSON string in Javascript?",
      choices: ["stringify()", "parse()", "convert()", "None of the above"],
      type: "MCQs",
      correctAnswer: "stringify()",
    },
    {
      question:
        "Which of the following keywords is used to define a variable in Javascript?",
      choices: ["var", "let", "var and let", "None of the above"],
      type: "MCQs",
      correctAnswer: "var and let",
    },
    {
      question:
        "Which of the following methods can be used to display data in some form using Javascript?",
      choices: [
        "document.write()",
        "console.log()",
        "window.alert",
        "All of the above",
      ],
      type: "MCQs",
      correctAnswer: "All of the above",
    },
    {
      question: "How can a datatype be declared to be a constant type?",
      choices: ["const", "var", "let", "constant"],
      type: "MCQs",
      correctAnswer: "const",
    },
  ],
};

export default function ClassRoom() {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const [isClickedRaiseHand, setIsClickedRaiseHand] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const isGalleryView = useSelector((state) => state.settings.isGalleryView);
  /** NOTES DECLARATION */
  const [selecetdColor, setColor] = useState("#f15c5c");
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  /** CHAT DECLARATION */
  //chat_server_socket = io(url_configs.chat_server);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiCtrlMenu, setEmojiCtrlMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  /** Breakout room */
  //b_room_socket = io(url_configs.breakout_room_server);
  const imageRef = useRef(null);
  const [contentInnerHTML, setContentInnerHTML] = useState();

  const [breakOutSessionStatus, setBreakOutSessionStatus] = useState("");
  const selectedContent = useSelector((state) => state.settings.selectedContent);
  const sideMenu = useSelector((state) => state.settings.menu);
  const isFluid = useSelector((state) => state.settings.isFluid);

  const dispatch = useDispatch();
  const participants = useSelector((state) => state.zoom.participants);
  //const [participants, setParticipants] = useState((state) => state.zoom.participants);
  const [expertInfo, setExpertInfo] = useState({});
  const [userInfo, setUserInfo] = useState();

  const [isScreenShareOn, setIsScreenShareOn] = useState(false);

  const [memberCourseGroupData, setMemberCourseGroupData] = useState();
  const [sessionDocuments, setSessionDocuments] = useState([]);

  const zmClient = ZoomVideo.createClient();
  const navigate = useNavigate();

  const autoPlayGameRouteName = useSelector(
    (state) => state.settings.autoPlayGameRouteName
  );

  const [gameList, setGameList] = useState(games.gameList);

  let currentUserData = JSON.parse(localStorage.getItem("user"));

  //const [breakoutCurrentScreenData, setBreakoutCurrentScreenData] = useState(null);
  const breakoutCurrentScreenData = useSelector(
    (state) => state.settings.breakoutCurrentScreenData
  );

  const zoomCameraStatus = useSelector((state) => state.zoom.cameraStatus);

  const zoomStream = useSelector((state) => state.zoom.stream);

  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const zoomClient = useSelector((state) => state.zoom.zoomClient);

  const [zoomAudioStatus, setAudio] = useState("mute");

  const [footerCtrlMenu, setFooterCtrlMenu] = useState(false);

  const [rightSideMenuBar, setRightSideMenuBar] = useState(false);

  const [pageMenuTitle, setPageMenuTitle] = useState(null);

  const [confirmationBoxOpen, setConfirmationBoxOpen] = React.useState(false);

  const [informationBoxOpen, setInformationBoxOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const topicName = useSelector((state) => state.settings.topicName);

  const [e_participants_h_idx, setE_participants_h_idx] = useState(0);
  //let breakoutCurrentScreenData = JSON.parse(storage_configs.localStorage.sessions.currentScreenData);

  //let interval_id;

  //GET SLUG FROM LINK
  let { slug } = useParams();

  let audioDecode, audioEncode;

  // QUIZ VIEW
  const [activeQuestion, setActiveQuestion] = React.useState(0);
  const [selectedAnswer, setSelectedAnswer] = React.useState("");
  const [showResult, setShowResult] = React.useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = React.useState(null);
  const [result, setResult] = React.useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  let { questions } =
    breakoutCurrentScreenData &&
      breakoutCurrentScreenData.selectedCurriculamType ==
      conditional_configs.curriculam_types.quiz
      ? breakoutCurrentScreenData
      : quiz;
  let { question, choices, correctAnswer } = questions[activeQuestion];
  const [learnerAnswer, setLearnerAnswer] = React.useState(null);
  // const { quizList } = selectedContent
  // const { question, choices, correctAnswer } = quizList[activeQuestion]

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    setResult((prev) =>
      selectedAnswer
        ? {
          ...prev,
          score: prev.score + 5,
          correctAnswers: prev.correctAnswers + 1,
        }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    formQuizResultData(
      questions[activeQuestion],
      learnerAnswer,
      selectedAnswer,
      "inprogress",
      result
    );
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      formQuizResultData(
        questions[activeQuestion],
        learnerAnswer,
        selectedAnswer,
        "completed",
        result
      );
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const formQuizResultData = (
    activeQues,
    lAnswer,
    learnerAnswerResult,
    status,
    result
  ) => {
    let learnerQuizAnswers = [];
    let lQuizAnsObj = {
      actualQuestion: activeQues,
      learnerAnswered: lAnswer,
      learnerAnswerResult: learnerAnswerResult,
    };
    learnerQuizAnswers.push(lQuizAnsObj);

    if (status == "inprogress") {
      let quizResults = [];

      let getQuizResultsFromStorage = JSON.parse(
        localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
      );
      if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
        getQuizResultsFromStorage.forEach((element) => {
          if (element.status == status) {
            element.learnerQuizAnswers.push(lQuizAnsObj);
            addQuiz(element);
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.quizResults,
            JSON.stringify(getQuizResultsFromStorage)
          );
        }, 100);
      } else {
        let quizResultObj = {
          attempt: 1,
          totalQuestions: questions.length,
          totalScore: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
          status: status,
          userDetail: getCurrentUserData(),
          learnerQuizAnswers: learnerQuizAnswers,
        };
        quizResults.push(quizResultObj);
        localStorage.setItem(
          storage_configs.localStorage.sessions.quizResults,
          JSON.stringify(quizResults)
        );
        addQuiz(quizResultObj);
      }
    } else {
      let getQuizResultsFromStorage = JSON.parse(
        localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
      );
      if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
        getQuizResultsFromStorage.forEach((element) => {
          if (element.status == "inprogress") {
            element.status = status;
            element.totalScore =
              learnerAnswerResult == true ? result.score + 5 : result.score;
            element.correctAnswers =
              learnerAnswerResult == true
                ? result.correctAnswers + 1
                : result.correctAnswers;
            element.wrongAnswers =
              learnerAnswerResult !== true
                ? result.wrongAnswers + 1
                : result.wrongAnswers;
            element.learnerQuizAnswers.push(lQuizAnsObj);
            addQuiz(element);
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.quizResults,
            JSON.stringify(getQuizResultsFromStorage)
          );
        }, 100);
      }
    }
  };

  const addQuiz = (quizData) => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );

    if (quizData) {
      let quizModel = {
        isInSession: true,
        courseStreamId: current_stream_id,
        slug: slug,
        user: getCurrentUserData().name,
        userId: getCurrentUserData().id,
        totalScore: quizData.totalScore.toString(),
        correctAnswers: quizData.correctAnswers,
        wrongAnswers: quizData.wrongAnswers,
        totalQuestions: quizData.totalQuestions,
        attempt: quizData.attempt,
        status: quizData.status,
        learnerQuizAnswers: quizData.learnerQuizAnswers,
      };

      SessionService.updateQuizBySlugAndLearner(slug, quizModel)
        .then((response) => { })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onClickBackQuiz = () => {
    setActiveQuestion((prev) => prev - 1);
  };

  const retryQuiz = () => {
    setActiveQuestion(0);
    setShowResult(false);
    setResult({
      score: 0,
      correctAnswers: 0,
      wrongAnswers: 0,
    });
    let getQuizResultsFromStorage = JSON.parse(
      localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
    );
    if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
      let quizResultObj = {
        attempt: getQuizResultsFromStorage.length + 1,
        totalQuestions: questions.length,
        totalScore: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
        status: "inprogress",
        userDetail: getCurrentUserData(),
        learnerQuizAnswers: [],
      };
      getQuizResultsFromStorage.push(quizResultObj);
      localStorage.setItem(
        storage_configs.localStorage.sessions.quizResults,
        JSON.stringify(getQuizResultsFromStorage)
      );
    }
  };
  const onAnswerSelected = (answer, index, answerObj) => {
    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }

    setLearnerAnswer(answerObj);
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  useEffect(() => {
    b_room_socket = io(url_configs.breakout_room_server);
    b_room_socket.on("expert-opens-other-page", (obj) => {
      console.log("expert-opens-other-page", obj);
      if (obj && obj.data && obj.data.slug && obj.data.slug == slug) {
        enqueueSnackbar(error_messages.expertRejoin, snackbar_options.warning);
      }
    });

    localStorage.removeItem(
      storage_configs.localStorage.sessions.chatNotification
    );
    //dispatch(setSelectedContent('https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'));
    if (slug) {
      /** CHECK IS ZOOM SIGNATURE ALREADY GENERATED */
      let signature = checkIsZoomSignatureAlreadyExist();
      const avatarUrl = getCurrentUserData().document_file_name;

      /** GENERATE ZOOM SIGNATURE IF NOT EXIST */
      if (!signature) {
        signature = generateVideoToken(
          zoomConfigs.sdk_key,
          zoomConfigs.sdk_secret,
          slug,
          zoomConfigs.session_password,
          avatarUrl,
          "",
          zoomConfigs.roleType.participant
        );
        /** STORE ZOOM SIGNATURE IN BROWSER STORAGE */
        storeZoomSignature(signature);
      }

      let stream;
      let currentZoomUserId = localStorage.getItem(
        storage_configs.localStorage.sessions.currentZoomUserId
      );
      //localStorage.getItem()
      const init = async () => {
        localStorage.setItem(
          storage_configs.localStorage.sessions.l_participants_v_idx,
          JSON.stringify(e_participants_h_idx)
        );

        if (getCurrentUserData()) {
          await zmClient.init("en-US", "Global", { patchJsMedia: true });
          try {
            console.log("zmClient initialised ");
            zoomInitiated(slug);

            zmClient
              .join(
                slug,
                signature,
                getCurrentUserData().name + "-" + getCurrentUserData().id,
                zoomConfigs.session_password
              )
              .then((data) => {
                localStorage.setItem(
                  storage_configs.localStorage.sessions.currentZoomUserId,
                  data.userId
                );
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom
                    .firstTimeInteracted,
                  true
                );
                stream = zmClient.getMediaStream();
                ZoomService.startAudioAndMuteMic(stream);

                const usrInfo = zmClient.getCurrentUserInfo();
                setUserInfo(usrInfo);
                localStorage.setItem(
                  storage_configs.localStorage.sessions.zoom.user,
                  JSON.stringify(usrInfo)
                );
                //setExpertInfo(zmClient.getCurrentUserInfo());

                dispatch(setClient(zmClient));
                dispatch(setStream(zmClient.getMediaStream()));

                let participants = zmClient.getAllUser();
                console.log("participants", participants);
                // participants.forEach((user) => {
                //   if (user.bVideoOn) {
                //     renderVideo(stream, zmClient, user.isHost, user.userId);
                //   }
                // })
                dispatch(
                  setParticipants(
                    participants.sort(
                      (a, b) => Number(b.isHost) - Number(a.isHost)
                    )
                  )
                );

                // dispatch(setCameraStatus(false));
                dispatch(setZoomStatus("started"));

                const expertData = participants.filter((e) => e.isHost == true);
                if (expertData.length > 0) {
                  ZoomService.renderExpertVideoInLearnerView(
                    stream,
                    expertData[0].userId
                  );
                }

                ZoomService.renderParticipantVideoInLearnerVerticalView(
                  stream,
                  zmClient,
                  0,
                  false
                );
                var activeShareUserId = stream.getActiveShareUserId();
                if (activeShareUserId && activeShareUserId > 0) {
                  setIsScreenShareOn(true);
                  setTimeout(() => {
                    stream.startShareView(
                      document.querySelector("#screen-share-canvas"),
                      activeShareUserId
                    );
                  }, 1000);
                }

                //audioInit(stream, zmClient);
                setTimeout(() => {
                  zmClient.on(events.zoom.peerVideoStateChange, (payload) => {
                    console.log(events.zoom.peerVideoStateChange, payload);
                    const participants = zmClient.getAllUser();
                    dispatch(
                      setParticipants(
                        participants.sort(
                          (a, b) => Number(b.isHost) - Number(a.isHost)
                        )
                      )
                    );
                    if (participants) {
                      let getThisUser = participants.filter(
                        (e) => e.userId == payload.userId
                      )[0];
                      if (getThisUser) {
                        if (getThisUser.isHost) {
                          if (payload.action === "Start") {
                            var curIndex = JSON.parse(
                              localStorage.getItem(
                                storage_configs.localStorage.sessions
                                  .l_participants_v_idx
                              )
                            );
                            ZoomService.renderParticipantVideoInLearnerVerticalView(
                              stream,
                              zmClient,
                              curIndex,
                              false
                            );
                            ZoomService.renderExpertVideoInLearnerView(
                              stream,
                              payload.userId
                            );
                            // renderVideo(stream, zmClient, true, payload.userId);
                          } else if (payload.action === "Stop") {
                            ZoomService.stopRenderExpertVideoFromLearnerView(
                              stream,
                              payload.userId
                            );
                            //stopRenderVideo(stream, zmClient, true, payload.userId);
                          }
                        } else {
                          if (payload.action === "Start") {
                            var curIndex = JSON.parse(
                              localStorage.getItem(
                                storage_configs.localStorage.sessions
                                  .l_participants_v_idx
                              )
                            );
                            ZoomService.renderParticipantVideoInLearnerVerticalView(
                              stream,
                              zmClient,
                              curIndex,
                              false
                            );
                            //renderVideo(stream, zmClient, false, payload.userId);
                          } else if (payload.action === "Stop") {
                            // ZoomService.stopRenderVideo(stream, payload.userId, 'learner');
                            var curIndex = JSON.parse(
                              localStorage.getItem(
                                storage_configs.localStorage.sessions
                                  .l_participants_v_idx
                              )
                            );
                            ZoomService.renderParticipantVideoInLearnerVerticalView(
                              stream,
                              zmClient,
                              curIndex,
                              false
                            );
                            // stopRenderVideo(stream, zmClient, false, payload.userId);
                          }
                        }
                      }
                      const participantsForExpert = zmClient.getAllUser();
                        participantsForExpert.forEach((user) => {
                          if (user.bVideoOn && user.isHost) {
                            ZoomService.renderExpertVideoInLearnerView(
                              stream,
                              user.userId
                            );
                          }
                        })
                    }
                  });

                  zmClient.on("share_content_change", (payload) => { });

                  zmClient.on(events.zoom.activeShareChange, (payload) => {
                    if (payload.state === "Active") {
                      setIsScreenShareOn(true);
                      setTimeout(() => {
                        stream.startShareView(
                          document.querySelector("#screen-share-canvas"),
                          payload.userId
                        );
                      }, 2000);
                    } else if (payload.state === "Inactive") {
                      setIsScreenShareOn(false);
                      stream.stopShareView();
                    }
                  });

                  // wait until the encoding and decoding process is ready for the audio
                  zmClient.on(events.zoom.mediaSDKChange, (payload) => {
                    const { action, type, result } = payload;
                    if (type === "audio" && result === "success") {
                      if (action === "encode") {
                        audioEncode = true;
                      } else if (action === "decode") {
                        audioDecode = true;
                      }
                      if (audioDecode && audioEncode) {
                        try {
                          // start audio automatically in Safari
                          //stream.startAudio({ autoStartAudioInSafari: true });
                          var st = zmClient.getMediaStream();
                          dispatch(setStream(st));
                          ZoomService.unMuteAudio(st);
                          setAudio("unmute");
                        } catch (err) {
                          console.warn(err);
                        }
                      }
                    }
                  });

                  // let hostInfo = zmClient.getAllUser().filter(e => e.isHost == true);
                  // if (hostInfo) {
                  //   console.log('Render Initial Host Video', hostInfo)
                  //   renderVideo(stream, zmClient, true, hostInfo[0].userId);
                  // }

                  zmClient.on(events.zoom.userRemoved, (payload) => {
                    console.log(events.zoom.userRemoved, payload);
                    // You can refresh the participants when
                    const participants = zmClient.getAllUser();
                    setParticipants(
                      participants.sort(
                        (a, b) => Number(b.isHost) - Number(a.isHost)
                      )
                    );
                    dispatch(
                      setParticipants(
                        participants.sort(
                          (a, b) => Number(b.isHost) - Number(a.isHost)
                        )
                      )
                    );
                    // if(payload.length > 0){
                    //   ZoomService.stopRenderVideo(stream, payload[0].userId,'learner');
                    // }
                    if (payload.length > 0) {
                      ZoomService.stopRenderVideo(
                        stream,
                        payload[0].userId,
                        "learner"
                      );
                      setTimeout(() => {
                        //ZoomService.stopAllRenderedVideo(stream, zmClient, 'learner');
                        ZoomService.renderParticipantVideoInLearnerVerticalView(
                          stream,
                          zmClient,
                          0,
                          false
                        );
                      }, 100);
                    }
                  });

                  zmClient.on(events.zoom.userUpdated, (payload) => {
                    const participants = zmClient.getAllUser();
                    //console.log("participants-user-updated-1", participants);
                    setParticipants(
                      participants.sort(
                        (a, b) => Number(b.isHost) - Number(a.isHost)
                      )
                    );
                    dispatch(
                      setParticipants(
                        participants.sort(
                          (a, b) => Number(b.isHost) - Number(a.isHost)
                        )
                      )
                    );
                    if (
                      payload.length > 0 &&
                      (payload[0].muted == false || payload[0].muted == true)
                    ) {
                      ZoomService.renderParticipantVideoInLearnerVerticalView(
                        stream,
                        zmClient,
                        0,
                        false
                      );
                    }
                  });

                  zmClient.on(events.zoom.activeSpeaker, (payload) => {
                    const participants = zmClient.getAllUser();
                    if (payload && payload.length > 0) {
                      participants.forEach((element) => {
                        element.isTalking = false;
                        payload.forEach((elePayload) => {
                          if (element.userId == elePayload.userId) {
                            element.isTalking = true;
                          } else {
                            element.isTalking = false;
                          }
                        });
                      });
                      setParticipants(
                        participants.sort(
                          (a, b) => Number(b.isHost) - Number(a.isHost)
                        )
                      );
                      dispatch(
                        setParticipants(
                          participants.sort(
                            (a, b) => Number(b.isHost) - Number(a.isHost)
                          )
                        )
                      );
                    }
                  });

                  emitBreakoutRoom();
                }, 3000);
              })
              .catch((e) => {
                dispatch(setZoomStatus("closed"));
              });
            getCourseContentBymembercoursestreamdetailid();
            //emitChat();
            getMemberCourseGroup();
            joinToGeneralChat();
          } catch (e) {
            getCourseContentBymembercoursestreamdetailid();
            //emitChat();
            getMemberCourseGroup();
            emitBreakoutRoom();
            dispatch(setZoomStatus("closed"));
          }
        }
        // else {
        //   await zmClient.init("en-US", `CDN`);

        //   stream = zmClient.getMediaStream();
        //   ZoomService.startAudioAndMuteMic(stream);
        //   // if (!stream.isCameraTaken()) {
        //   //   stream.startVideo({
        //   //     videoElement: document.querySelector("#participants-canvas"),
        //   //   });
        //   // }

        //   //setExpertInfo(zmClient.getCurrentUserInfo());

        //   let participants = zmClient.getAllUser();
        //   // participants.forEach((user) => {
        //   //   if (user.bVideoOn) {
        //   //     renderVideo(stream, zmClient, user.isHost, user.userId);
        //   //   }
        //   // })
        //   dispatch(setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))));

        //   dispatch(setClient(zmClient));
        //   dispatch(setStream(zmClient.getMediaStream()));

        //   dispatch(setCameraStatus(false));
        //   dispatch(setZoomStatus("started"));

        //   //audioInit(stream, zmClient);
        //   setTimeout(() => {
        //     zmClient.on(events.zoom.peerVideoStateChange, (payload) => {
        //       const participants = zmClient.getAllUser();
        //       dispatch(setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))));
        //       if (participants) {
        //         let getThisUser = participants.filter(e => e.userId == payload.userId)[0];
        //         if (getThisUser) {
        //           if (getThisUser.isHost) {
        //             if (payload.action === "Start") {
        //               renderVideo(stream, zmClient, true, payload.userId);
        //             } else if (payload.action === "Stop") {
        //               stopRenderVideo(stream, zmClient, true, payload.userId);
        //             }
        //           } else {
        //             if (payload.action === "Start") {
        //               renderVideo(stream, zmClient, false, payload.userId);
        //             } else if (payload.action === "Stop") {
        //               stopRenderVideo(stream, zmClient, false, payload.userId);
        //             }
        //           }
        //         }
        //       }
        //     });

        //     zmClient.on("share_content_change", (payload) => {
        //     });

        //     zmClient.on("active-share-change", (payload) => {
        //       if (payload.state === 'Active') {
        //         setIsScreenShareOn(true);
        //         setTimeout(() => {
        //           stream.startShareView(document.querySelector('#screen-share-canvas'), payload.userId)
        //         }, 2000);
        //       } else if (payload.state === 'Inactive') {
        //         setIsScreenShareOn(false);
        //         stream.stopShareView()
        //       }
        //     });

        //     // wait until the encoding and decoding process is ready for the audio
        //     zmClient.on("media-sdk-change", (payload) => {
        //       console.log("current user data-2", payload)
        //       const { action, type, result } = payload;
        //       if (type === "audio" && result === "success") {
        //         if (action === "encode") {
        //           audioEncode = true;
        //         } else if (action === "decode") {
        //           audioDecode = true;
        //         }
        //         if (audioDecode && audioEncode) {
        //           try {
        //             // start audio automatically in Safari
        //             var st = zmClient.getMediaStream();
        //             dispatch(setStream(st));
        //             ZoomService.unMuteAudio(st);
        //             setAudio("unmute");
        //             //stream.startAudio({ autoStartAudioInSafari: true });
        //           } catch (err) {
        //             console.warn(err);
        //           }
        //         }
        //       }
        //     });

        //     // let hostInfo = zmClient.getAllUser().filter(e => e.isHost == true);
        //     // if (hostInfo) {
        //     //   console.log('Render Initial Host Video', hostInfo)
        //     //   renderVideo(stream, zmClient, true, hostInfo[0].userId);
        //     // }

        //     zmClient.on('user-updated', (payload) => {
        //       const participants = zmClient.getAllUser();
        //       setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost)));
        //       dispatch(setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))));
        //     });

        //     zmClient.on('active-speaker', (payload) => {
        //       const participants = zmClient.getAllUser();
        //       if (payload && payload.length > 0) {
        //         participants.forEach(element => {
        //           element.isTalking = false;
        //           payload.forEach(elePayload => {
        //             if (element.userId == elePayload.userId) {
        //               element.isTalking = true;
        //             } else {
        //               element.isTalking = false;
        //             }
        //           });
        //         });
        //         setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost)));
        //         dispatch(setParticipants(participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))));
        //       }
        //     });

        //   }, 3000);
        //   //emitChat();
        //   getCourseContentBymembercoursestreamdetailid();
        //   getMemberCourseGroup();
        //   emitBreakoutRoom();
        // }
      };

      init();
      getNotes();

      return () => { };
    } else {
      navigate(navigate_route_config.learner.my_courses);
    }
  }, []);

  // const checkActiveSpeaker = () => {
  //   const participants = zmClient.getAllUser();
  //   if (participants && participants.length > 0) {
  //     setTimeout(() => {
  //       participants.forEach(element => {
  //         element.isTalking = false;
  //       });
  //       setParticipants(participants);
  //       dispatch(setParticipants(participants));
  //     }, 500);
  //   }
  // }

  const getUserAudioStatus = () => {
    if (zmClient) {
      const userInfo = zmClient.getCurrentUserInfo();
      if (userInfo) {
        return userInfo.muted;
      }
    }
  };

  const stopRenderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if (
        (!stream.isSupportMultipleVideos() &&
          typeof OffscreenCanvas === "function") ||
        /android/i.test(navigator.userAgent)
      ) {
        // if MediaStreamTrackProcessor is not supported
        if (!(typeof MediaStreamTrackProcessor === "function")) {
          // render video on HTML Canvas Element
          setTimeout(() => {
            stream.stopRenderVideo(
              document.querySelector(
                isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
              ),
              userId
            );
            if (
              document.querySelector(
                isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
              )
            ) {
              document.querySelector(
                isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
              ).style.display = "none";
            }
          }, 2000);
        } else {
          // show HTML Video Element in DOM
          stream.stopRenderVideo(
            document.querySelector(
              isHost ? "#self-view-video" : "#participants-video-" + userId
            ),
            userId
          );
          if (
            document.querySelector(
              isHost ? "#self-view-video" : "#participants-video-" + userId
            )
          ) {
            document.querySelector(
              isHost ? "#self-view-video" : "#participants-video-" + userId
            ).style.display = "none";
          }
        }
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // render video on HTML Canvas Element
        stream.stopRenderVideo(
          document.querySelector(
            isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
          ),
          userId
        );
        if (
          document.querySelector(
            isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
          )
        ) {
          document.querySelector(
            isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
          ).style.display = "none";
        }
      }
    }
  };

  const renderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      setTimeout(() => {
        stream
          .renderVideo(
            document.querySelector(
              isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
            ),
            userId,
            320,
            180,
            0,
            0,
            2
          )
          .then(() => {
            // show HTML Canvas Element in DOM
            document.querySelector(
              isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
            ).style.display = "block";
            document.querySelector(
              isHost ? "#self-view-video" : "#participants-video-" + userId
            ).style.display = "none";
          })
          .catch((error) => {
            console.log("Render Video :: renderVideo", error);
          });
      }, 5000);

      // // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      // if ((!stream.isSupportMultipleVideos() && (typeof OffscreenCanvas === 'function')) || /android/i.test(navigator.userAgent)) {
      //   // if MediaStreamTrackProcessor is not supported
      //   if (!(typeof MediaStreamTrackProcessor === 'function')) {
      //     // render video on HTML Canvas Element
      //     setTimeout(() => {
      //       stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId), userId, 1280, 720, 0, 0, 3).then(() => {
      //         // show HTML Canvas Element in DOM
      //         document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId).style.display = 'block';
      //         document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId).style.display = 'none';
      //       }).catch((error) => {
      //         console.log(error);
      //         console.log('error here')
      //       })
      //     }, 3000);
      //   } else {
      //     setTimeout(() => {
      //       // show HTML Video Element in DOM
      //       stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId), userId, 1280, 720, 0, 0, 3).then(() => {
      //         // show HTML Canvas Element in DOM
      //         document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId).style.display = 'block';
      //         document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId).style.display = 'none';
      //       }).catch((error) => {
      //         console.log(error);
      //         console.log('error here')
      //       })
      //       // stream.renderVideo(document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId), userId, 1280, 720, 0, 0, 3).then(() => {
      //       //   document.querySelector(isHost ? '#self-view-video' : '#participants-video-' + userId).style.display = 'block';
      //       //   document.querySelector(isHost ? '#self-view-canvas' : '#participants-canvas-' + userId).style.display = 'none';
      //       // });
      //     }, 3000);
      //   }
      //   // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      // } else {
      //   // render video on HTML Canvas Element
      //   stream.renderVideo(document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId), userId, 1280, 720, 0, 0, 3).then(() => {
      //     // show HTML Canvas Element in DOM
      //     document.querySelector(isHost ? "#self-view-canvas" : "#participants-canvas-" + userId).style.display = 'block';
      //     document.querySelector(isHost ? "#self-view-video" : "#participants-video-" + userId).style.display = 'none'
      //   }).catch((error) => {
      //     console.log(error)
      //   })
      // }
    }
  };

  const audioInit = (stream, client) => {
    // console.log("Start Audio")
    // stream.startAudio({ autoStartAudioInSafari: true }).then((audio)=>{
    //   console.log("Started Audio", audio)
    // }).catch((error) => {
    //   console.log('Audio Error here')
    //   console.log(error);
    // });

    stream
      .startAudio({ autoStartAudioInSafari: true })
      .then((audio) => {
        console.log("Started Audio", audio);
      })
      .catch((error) => {
        console.log("Audio Error ::");
        console.log(error);
      });
    // if (audioDecode && audioDecode) {
    //   try {
    //     stream.startAudio();
    //   } catch (err) {
    //     console.warn(err);
    //   }
    // }
    client.on("auto-play-audio-failed", () => { });
  };

  //#region CHAT FUNCTIONS AND EVENTS
  const checkIsAnyNotification = () => {
    if (rightSideMenuBar) {
      localStorage.removeItem(
        storage_configs.localStorage.sessions.chatNotification
      );
    }
    let curNotification = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.chatNotification
      )
    );
    if (curNotification && curNotification.length > 0) {
      return curNotification;
    }
    return [];
  };

  const getChatsByStreamId = () => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id)
      SessionService.getChatsByStreamId(current_stream_id)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            storeChats(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const joinToGeneralChat = () => {
    getChatsByStreamId();

    let generalChats = [...messages];
    let storedChats = getChats();
    if (storedChats && storedChats.length > 0) {
      generalChats = storedChats.filter((e) => !e.group);
    }
    setMessages(generalChats);

    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit(
      "join",
      { name: getCurrentUserData().name, room: slug },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );

    chat_server_socket.on("message", (message) => {
      if (message.slug == slug) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message);
        setMessages(generalChats);
        storeChats(generalChats);

        if (
          message &&
          message.user != getCurrentUserData().name &&
          rightSideMenuBar != true &&
          (!message.group ||
            message.group.coursegroupid == getOpenedChatGroup().coursegroupid)
        ) {
          enqueueSnackbar("Message received", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
        if (
          message &&
          message.user != getCurrentUserData().name &&
          rightSideMenuBar != true
        ) {
          let tempNofications = JSON.parse(
            localStorage.getItem(
              storage_configs.localStorage.sessions.chatNotification
            )
          );
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(
            storage_configs.localStorage.sessions.chatNotification,
            JSON.stringify(tempNofications)
          );
        }
        // if (message && message.user != getCurrentUserData().name && (getBreakoutSessionStatus() == true && message.group.coursegroupid == getOpenedChatGroup().coursegroupid)) {
        //   enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        // }

        // if (message && message.user != getCurrentUserData().name && (getBreakoutSessionStatus() !== true && !message.group)) {
        // }
      }
    });
  };

  const emitChat = () => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id) {
      MemberService.getMemberCourseGroupByStreamAndLearnerId(
        current_stream_id,
        getCurrentUserData().id
      )
        .then((response) => {
          let uName = getCurrentUserData().name;
          let uRoom = response.data.groupname;
          chat_server_socket = io(url_configs.chat_server);
          chat_server_socket.emit(
            "join",
            { name: uName, room: slugify(uRoom) },
            (error) => {
              if (error) {
                alert(error);
              }
            }
          );
          chat_server_socket.on("message", (message) => {
            setMessages((messages) => [...messages, message]);
            if (
              message &&
              message.user != getCurrentUserData().name &&
              message.room == slugify(uRoom) &&
              rightSideMenuBar != true
            ) {
              enqueueSnackbar("Message received", {
                variant: "success",
                key: "topright",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onClickEmoji = (emojiData, event) => {
    setSelectedEmoji(emojiData.unified);
    let tempMsg = message;
    //const textAreaElement = document.getElementById('chat-text-area');
    //setMessage(message.substr(0, textAreaElement.selectionStart) +emojiData.emoji  +message.substr(textAreaElement.selectionEnd));
    setMessage(tempMsg + emojiData.emoji);
    handleEmojiControlMenuClose();
  };

  const handleEmojiControlMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEmojiCtrlMenu(true);
  };

  const handleEmojiControlMenuClose = () => {
    setEmojiCtrlMenu(false);
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: true,
      courseStreamId: null,
      room: slug,
      slug: slug,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "learner",
    };
    if (message) {
      if (getBreakoutSessionStatus() == true) {
        chat_server_socket.emit("sendMessage", {
          message: message,
          group: getOpenedChatGroup(),
          slug: slug,
          userId: getCurrentUserData().id,
          userType: "learner",
        });
        chatModel.group = getOpenedChatGroup();
      } else {
        chat_server_socket.emit("sendMessage", {
          message: message,
          group: null,
          slug: slug,
          userId: getCurrentUserData().id,
          userType: "learner",
        });
      }
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  const addChats = (data) => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    data.isInSession = true;
    data.courseStreamId = current_stream_id;
    SessionService.addChats(data)
      .then((response) => { })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendScreenshot = (e) => {
    if (imageRef.current) {
      toPng(imageRef.current, { cacheBust: true })
        .then((dataUrl) => {
          //setMessage(dataUrl);
          chat_server_socket.emit("sendMessage", {
            message: dataUrl,
            group: getOpenedChatGroup(),
            slug: slug,
            userId: getCurrentUserData().id,
            userType: "expert",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes("data:image/");
      return checkIsImg;
    }
  };
  //#endregion

  //#region DOCUMENTS FUNCTIONS AND EVENTS
  const onChangeDocumentUpload = (e) => {
    let files = e.target.files;
    if (files && files.length > 0) {
      var file = files[0];
      let current_stream_id = localStorage.getItem(
        storage_configs.localStorage.sessions.current_stream_id
      );
      if (current_stream_id) {
        MemberService.getMemberCourseGroupByStreamAndLearnerId(
          current_stream_id,
          getCurrentUserData().id
        )
          .then((response) => {
            let membercoursegroupid = response.data.membercoursegroupid;
            if (response.data) {
              DocumentService.upload_document(file)
                .then((doc_response) => {
                  let documentObj = {
                    membercoursegroupid: membercoursegroupid.toString(),
                    documentid: doc_response.data.id.toString(),
                    memberid: getCurrentUserData().id.toString(),
                  };
                  uploadDocumnetInSession(documentObj);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const getMemberCourseGroup = () => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id) {
      MemberService.getMemberCourseGroupByStreamAndLearnerId(
        current_stream_id,
        getCurrentUserData().id
      )
        .then((response) => {
          if (response.data) {
            setMemberCourseGroupData(response.data);
            localStorage.setItem(
              storage_configs.localStorage.sessions.current_learner_groupname,
              response.data.groupname
            );
            getSessionDocuments(response.data.membercoursegroupid);
            storeOpenedChatGroup(response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getSessionDocuments = (id) => {
    SessionService.getSessionDocuments(id)
      .then((response) => {
        setSessionDocuments(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadDocumnetInSession = (docModel) => {
    if (docModel) {
      SessionService.uploadDocumnetInSession(docModel)
        .then((response) => { })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const showSessionDocument = (doc) => {
    if (doc && doc.document) {
      localStorage.setItem(
        storage_configs.localStorage.sessions.show_content,
        doc.document.documenturl
      );
      window.location.reload();
    }
  };
  //#endregion

  //#region BREAKOUT ROOM FUNCTIONS AND EVENTS
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const emitBreakoutRoom = () => {
    //if(breakOutSessionStatus == 'STARTED'){
    let tempScreenData = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.currentScreenData
      )
    );
    if (tempScreenData) {
      dispatch(setBreakoutCurrentScreenData(tempScreenData));
      let tempGameObj = getGameDetailByGameId(tempScreenData.puzzleid);
      if (tempGameObj) {
        dispatch(setAutoPlayGameRouteName(tempGameObj));
        localStorage.setItem(
          storage_configs.localStorage.sessions.group_space_selected_tab,
          "games"
        );
      }
    }
    //}
    b_room_socket = io(url_configs.breakout_room_server);

    b_room_socket.emit(
      "join",
      {
        name: getCurrentUserData().name,
        sessionSlug: slug,
        room: null,
        currentScreenData: null,
        role: 0,
      },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );

    b_room_socket.on("session-status", (message) => {
      console.log("session-status", message);
      if (message && message.success == false && message.session == slug) {
        //clearInterval(interval_id);
        enqueueSnackbar("Class Ended. Thank you.", {
          variant: "success",
          key: "topright",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        clearClassRoomSessionData();
        navigate(navigate_route_config.learner.my_courses);
      } else if (message && message.isBreakoutStarted == true) {
        setTimeout(() => {
          let current_learner_groupname = localStorage.getItem(
            storage_configs.localStorage.sessions.current_learner_groupname
          );

          if (current_learner_groupname) {
            setBreakOutSessionStatus("STARTED");

            b_room_socket.emit(
              "join-breakout-session",
              {
                name: getCurrentUserData().name,
                sessionSlug: slug,
                room: current_learner_groupname,
                role: 0,
              },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
            //emitChat();
          }
        }, 5000);
      }
    });

    b_room_socket.on("break-out-status", (obj) => {
      console.log("break-out-status", obj);
      if (obj && obj.success == true && obj.status == "STARTED") {
        localStorage.removeItem(
          storage_configs.localStorage.sessions.quizResults
        );
        setActiveQuestion(0);
        setShowResult(false);
        setResult({
          score: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
        });
        changeBreakoutSessionStatus(true);
        getMemberCourseGroup();
        if (obj.currentScreenData) {
          localStorage.setItem(
            storage_configs.localStorage.sessions.currentScreenData,
            JSON.stringify(obj.currentScreenData)
          );
          dispatch(setBreakoutCurrentScreenData(obj.currentScreenData));
        }

        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          let current_learner_groupname = localStorage.getItem(
            storage_configs.localStorage.sessions.current_learner_groupname
          );

          if (current_learner_groupname) {
            setBreakOutSessionStatus(obj.status);

            let subsession;
            subsession = zmClient.getSubsessionClient();
            let getSubsessionList = subsession.getSubsessionList();
            sleep(1000);
            // JOIN INTO ZOOM SUBSESSION
            if (getSubsessionList && getSubsessionList.length > 0) {
              //let subsId = getSubsessionList[0].subsessionId.toString().replace('{', '').replace('}', '');
              const participants = zmClient.getAllUser();
              dispatch(
                setParticipants(
                  participants.sort(
                    (a, b) => Number(b.isHost) - Number(a.isHost)
                  )
                )
              );
              //dispatch(setParticipants(getSubsessionList[0].userList));
              //console.log('PARTI::',getSubsessionList[0].userList);
              sleep(1000);
              //ZoomService.startAudio(stream);
              //audioInit(stream, zmClient);
              let subsId = getSubsessionList[0].subsessionId.toString();
              subsession.joinSubsession(subsId);

              ZoomService.stopAllRenderedVideo(zoomStream, zmClient, "learner");
              setTimeout(() => {
                ZoomService.renderParticipantVideoInLearnerVerticalView(
                  zoomStream,
                  zmClient,
                  0,
                  false
                );
              }, 200);
              // if (!stream.isCameraTaken()) {
              //   stream.startVideo({
              //     videoElement: document.querySelector("#participants-canvas"),
              //   });
              // }
            }

            b_room_socket.emit(
              "join-breakout-session",
              {
                name: getCurrentUserData().name,
                sessionSlug: slug,
                room: current_learner_groupname,
                role: 0,
              },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );

            //emitChat();
          }
        }, 5000);

        // joinToGeneralChat();
      } else {
        //dispatch(setIsFluid(false));
        changeBreakoutSessionStatus(false);
        removeOpenedChatGroup();
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          setBreakOutSessionStatus(obj.status);
          localStorage.removeItem(
            storage_configs.localStorage.sessions.currentScreenData
          );
          localStorage.removeItem(
            storage_configs.localStorage.sessions.quizResults
          );

          sleep(1000);
          window.location.reload();
        }, 3000);
      }
      dispatch(setIsGalleryView(false));
    });

    b_room_socket.on("auto-play-game", (obj) => {
      let tempGameObj = getGameDetailByGameId(obj.data.game.id);
      dispatch(setAutoPlayGameRouteName(tempGameObj));
      localStorage.setItem(
        storage_configs.localStorage.sessions.group_space_selected_tab,
        "games"
      );
      localStorage.setItem(
        storage_configs.localStorage.sessions.currentGame,
        JSON.stringify(tempGameObj)
      );
      //b_room_socket.emit("capture-image", { captured_image: 'games', game: tempGameObj });
      // interval_id = setInterval(() => {
      //   b_room_socket.emit("capture-image", { captured_image: 'games' });
      // }, 5000);
    });

    b_room_socket.on("expert-selected-content", (obj) => {
      console.log("expert-selected-content", obj);
      let tempExpertSelectedContent = obj.data;
      if (tempExpertSelectedContent && tempExpertSelectedContent.slug == slug) {
        if (tempExpertSelectedContent.content.curriculumlink) {
          localStorage.setItem(
            storage_configs.localStorage.sessions.show_content,
            tempExpertSelectedContent.content.curriculumlink
          );
          dispatch(
            setSelectedContent(tempExpertSelectedContent.content.curriculumlink)
          );
          localStorage.setItem(storage_configs.localStorage.sessions.currentActiveContent, tempExpertSelectedContent.content.curriculumlink)
          dispatch(
            setSelectedCurriculamType(
              conditional_configs.curriculam_types.content
            )
          );
        } else {
          localStorage.setItem(
            storage_configs.localStorage.sessions.show_content,
            tempExpertSelectedContent.content.document.documenturl
          );
          dispatch(
            setSelectedContent(
              tempExpertSelectedContent.content.document.documenturl
            )
          );
          localStorage.setItem(storage_configs.localStorage.sessions.currentActiveContent, tempExpertSelectedContent.content.document.documenturl)
          dispatch(
            setSelectedCurriculamType(
              conditional_configs.curriculam_types.content
            )
          );
        }
      }
    });

    b_room_socket.on("join-user-in-middle", (sobj) => {
      console.log("join-user-in-middle", sobj);
      let obj = sobj.data;
      if (
        obj &&
        obj.sessionSlug == slug &&
        obj.userId == getCurrentUserData().id
      ) {
        changeBreakoutSessionStatus(true);
        if (obj.currentScreenData) {
          localStorage.setItem(
            storage_configs.localStorage.sessions.currentScreenData,
            JSON.stringify(obj.currentScreenData)
          );
          dispatch(setBreakoutCurrentScreenData(obj.currentScreenData));
        }
        setIsLoading(true);

        setTimeout(() => {
          let current_learner_groupname = localStorage.getItem(
            storage_configs.localStorage.sessions.current_learner_groupname
          );

          if (current_learner_groupname) {
            setBreakOutSessionStatus("STARTED");

            let subsession;
            subsession = zmClient.getSubsessionClient();
            let getSubsessionList = subsession.getSubsessionList();
            sleep(1000);
            // JOIN INTO ZOOM SUBSESSION
            if (getSubsessionList && getSubsessionList.length > 0) {
              //let subsId = getSubsessionList[0].subsessionId.toString().replace('{', '').replace('}', '');
              const participants = zmClient.getAllUser();
              dispatch(
                setParticipants(
                  participants.sort(
                    (a, b) => Number(b.isHost) - Number(a.isHost)
                  )
                )
              );
              //dispatch(setParticipants(getSubsessionList[0].userList));
              //console.log('PARTI::',getSubsessionList[0].userList);
              //let stream = zmClient.getMediaStream();
              sleep(1000);
              //ZoomService.startAudio(stream);
              //audioInit(stream, zmClient);
              let subsId = getSubsessionList[0].subsessionId.toString();
              subsession.joinSubsession(subsId);
              setIsLoading(false);
            }

            b_room_socket.emit(
              "join-breakout-session",
              {
                name: getCurrentUserData().name,
                sessionSlug: slug,
                room: current_learner_groupname,
                role: 0,
              },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
          }
        }, 5000);
      }
    });

    b_room_socket.on("on-get-learner-quiz-progress", (obj) => {
      console.log("on-get-learner-quiz-progress", obj);
      if (obj && obj.data.slug == slug) {
        let splitDisplayName = obj.data.userDetail.displayName.split("-");
        let getLearnerId;
        if (splitDisplayName && splitDisplayName.length > 0) {
          getLearnerId = splitDisplayName[splitDisplayName.length - 1];
          if (getLearnerId == getCurrentUserData().id) {
            let sendData = {
              userDetail: getCurrentUserData(),
              slug: slug,
              quizResult: JSON.parse(
                localStorage.getItem(
                  storage_configs.localStorage.sessions.quizResults
                )
              ),
            };
            setTimeout(() => {
              b_room_socket.emit(
                "send-learner-quiz-progress",
                { data: sendData },
                (error) => {
                  if (error) {
                    console.log(error);
                  }
                },
                200
              );
            });
          }
        }
      }
    });

    let data = {
      id: getCurrentUserData().id,
      slug: slug,
      name: getCurrentUserData().name,
      zoomDetail: JSON.parse(
        localStorage.getItem(storage_configs.localStorage.sessions.zoom.user)
      ),
    };

    b_room_socket.emit("on-user-joined", { data: data }, (error) => {
      if (error) {
        alert(error);
      }
    });
    // setTimeout(() => {
    //   if (breakOutSessionStatus == 'STARTED') {
    //     emitChat();
    //   } else {
    //     joinToGeneralChat();
    //   }
    // }, 2000);

    // b_room_socket.on("rejoin-subsession", (obj) => {
    //   console.log('rejoin-subsession', obj);
    //   if (obj && obj.data && obj.data.length > 0) {
    //     let subsession;
    //     subsession = zmClient.getSubsessionClient();
    //     let getSubsessionList = subsession.getSubsessionList();

    //     // JOIN INTO ZOOM SUBSESSION
    //     //let subsId = getSubsessionList[0].subsessionId.toString().replace('{', '').replace('}', '');
    //     let curUsrData = obj.data.filter(e => e.userId == getCurrentUserData().id)[0];
    //     if (curUsrData) {
    //       let subsId = curUsrData.sessionId;
    //       console.log(subsId, 'rejoin-subsession-id');
    //       let jSub = subsession.joinSubsession(subsId);
    //       setTimeout(() => {
    //         let stream = zmClient.getMediaStream();
    //         audioInit(stream, zmClient);
    //       }, 2000);

    //       console.log("subsession-list-user-list-rejoin", getSubsessionList[0].userList);
    //       console.log(jSub);

    //       console.log("subsession-participants-list-rejoin", getSubsessionList[0].userList);
    //       dispatch(setParticipants(getSubsessionList[0].userList));
    //     }
    //   }

    // });
  };
  //#endregion

  const getGameDetailByGameId = (id) => {
    let gameObj = gameList.filter((e) => e.id == id)[0];
    return gameObj;
  };

  const displayOnlyFirstName = (dName) => {
    if (dName) {
      return dName.split(" ")[0];
    }
  };

  // ZOOM FUNCTIONS
  const swichOffLearnerVideo = () => {
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
    }, 3000);

    if (zoomStream.isCapturingVideo()) {
      const session = zoomClient.getSessionInfo();
      zoomStream.stopVideo();
      // dispatch(setCameraStatus(false));
      // stopRenderVideoControls(zoomStream, zoomClient, false, session.userId);
      ZoomService.stopRenderVideo(zoomStream, session.userId, "learner");
      setTimeout(() => {
        const participants = zoomClient.getAllUser();
        dispatch(
          setParticipants(
            participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))
          )
        );
        ZoomService.renderParticipantVideoInLearnerVerticalView(
          zoomStream,
          zoomClient,
          0,
          false
        );
      }, 3000);
    } else {
      window.location.reload();
    }
  };

  const swichOnLearnerVideo = () => {
    // dispatch(setCameraStatus(true));
    // ZoomService.renderParticipantSelfVideo(zoomStream, zoomClient);
    ZoomService.renderParticipantVideoInLearnerVerticalView(
      zoomStream,
      zoomClient,
      0,
      true
    );
    //renderVideoControls(zoomStream, zoomClient, false);
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
      const participants = zoomClient.getAllUser();
      dispatch(
        setParticipants(
          participants.sort((a, b) => Number(b.isHost) - Number(a.isHost))
        )
      );
    }, 2000);
  };

  const stopRenderVideoControls = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if (
        (!stream.isSupportMultipleVideos() &&
          typeof OffscreenCanvas === "function") ||
        /android/i.test(navigator.userAgent)
      ) {
        // if MediaStreamTrackProcessor is not supported
        if (!(typeof MediaStreamTrackProcessor === "function")) {
          // render video on HTML Canvas Element
          setTimeout(() => {
            stream.stopRenderVideo(
              document.querySelector(
                isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
              ),
              userId
            );
            document.querySelector(
              isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
            ).style.display = "none";
          }, 2000);
        } else {
          // show HTML Video Element in DOM
          stream.stopRenderVideo(
            document.querySelector(
              isHost ? "#self-view-video" : "#participants-video-" + userId
            ),
            userId
          );
          document.querySelector(
            isHost ? "#self-view-video" : "#participants-video-" + userId
          ).style.display = "none";
        }
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // render video on HTML Canvas Element
        stream.stopRenderVideo(
          document.querySelector(
            isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
          ),
          userId
        );
        document.querySelector(
          isHost ? "#self-view-canvas" : "#participants-canvas-" + userId
        ).style.display = "none";
      }
    }
  };

  const renderVideoControls = (stream, client, isHost) => {
    if (!stream.isCameraTaken()) {
      const session = client.getSessionInfo();
      setTimeout(() => {
        stream
          .startVideo({
            videoElement: document.querySelector(
              isHost
                ? "#self-view-video"
                : "#participants-video-" + session.userId
            ),
            hd: stream.isSupportHDVideo(),
          })
          .then(() => {
            // if MediaStreamTrackProcessor is not supported
            if (!(typeof MediaStreamTrackProcessor === "function")) {
              // render video on HTML Canvas Element
              stream
                .renderVideo(
                  document.querySelector(
                    isHost
                      ? "#self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ),
                  session.userId,
                  320,
                  180,
                  0,
                  0,
                  2
                )
                .then(() => {
                  // show HTML Canvas Element in DOM
                  document.querySelector(
                    isHost
                      ? "#self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ).style.display = "block";
                  document.querySelector(
                    isHost
                      ? "#self-view-video"
                      : "#participants-video-" + session.userId
                  ).style.display = "none";
                })
                .catch((error) => {
                  console.log("Render video :: renderVideoControls", error);
                });
            } else {
              // show HTML Video Element in DOM
              stream
                .renderVideo(
                  document.querySelector(
                    isHost
                      ? "#self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ),
                  session.userId,
                  320,
                  180,
                  0,
                  0,
                  2
                )
                .then(() => {
                  // show HTML Canvas Element in DOM
                  document.querySelector(
                    isHost
                      ? "#self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ).style.display = "block";
                  document.querySelector(
                    isHost
                      ? "#self-view-video"
                      : "#participants-video-" + session.userId
                  ).style.display = "none";
                })
                .catch((error) => {
                  console.log("Render video :: renderVideoControls", error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    }
  };

  const audioToggleControls = async (flag) => {
    if (flag === "mute") {
      ZoomService.muteAudio(zoomStream);
      //zoomStream.muteAudio();
      setAudio("mute");
    } else {
      const firstTimeInteracted = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.zoom.firstTimeInteracted
        )
      );
      if (firstTimeInteracted == true) {
        Swal.fire({
          title: error_messages.allowAudio,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Allow",
        }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.setItem(
              storage_configs.localStorage.sessions.zoom.firstTimeInteracted,
              false
            );
            await ZoomService.stopAudio(zoomStream);
            await ZoomService.startAudio(zoomStream);
            await ZoomService.unMuteAudio(zoomStream);
            setAudio("unmute");
          }
        });
      } else {
        ZoomService.unMuteAudio(zoomStream);
        setAudio("unmute");
      }
      // ZoomService.unMuteAudio(zoomStream);
      //zoomStream.unmuteAudio();
      // setAudio("unmute");
    }
  };

  const leaveSession = (e) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        zoomClient.leave();
        endSessionByLearner();
      }
    });
  };

  const endSessionByLearner = () => {
    b_room_socket = io(url_configs.breakout_room_server);
    zoomClient.leave();
    let id = localStorage.getItem(
      storage_configs.localStorage.sessions.membercoursestreamlearnerdetailsid
    );
    if (id) {
      let current_stream_id = localStorage.getItem(
        storage_configs.localStorage.sessions.current_stream_id
      );
      if (current_stream_id) {
        handleCloseConfirmationBox();
        MemberService.getMemberCourseGroupByStreamAndLearnerId(
          current_stream_id,
          getCurrentUserData().id
        )
          .then((response) => {
            if (response && response.data && response.data.groupname) {
              let data = {
                id: getCurrentUserData().id,
                slug: slug,
                name: getCurrentUserData().name,
                group: response.data.groupname,
              };

              b_room_socket.emit("on-user-left", { data: data }, (error) => {
                if (error) {
                  alert(error);
                }
              });
            }
            let model = {
              membercoursestreamlearnerdetailsid: id.toString(),
            };
            SessionService.endSessionByLearner(model)
              .then((response) => {
                clearClassRoomSessionData();
                dispatch(setBreakoutCurrentScreenData(null));
                navigate(navigate_route_config.learner.my_courses);
              })
              .catch((e) => {
                navigate(navigate_route_config.learner.my_courses);
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const handleFooterControlMenuClick = (event) => {
    setFooterCtrlMenu(true);
  };
  const handleFooterControlMenuClose = () => {
    setFooterCtrlMenu(false);
  };

  const openPageMenuItem = (e, page) => {
    setRightSideMenuBar(true);
    e.stopPropagation();
    handleFooterControlMenuClose();
    dispatch(setMenu(page));
    if (page == "course") {
      setPageMenuTitle("Course Topics");
    } else if (page == "docs") {
      setPageMenuTitle("Documents");
    } else if (page == "notes") {
      setPageMenuTitle("Notes");
    } else if (page == "chat") {
      setPageMenuTitle("Chat");
      localStorage.removeItem(
        storage_configs.localStorage.sessions.chatNotification
      );
    } else {
      setPageMenuTitle("");
    }
  };

  const closePageMenuItem = (e) => {
    e.stopPropagation();
    setRightSideMenuBar(false);
  };

  const getCourseContentBymembercoursestreamdetailid = () => {
    let membercoursestreamdetailid = localStorage.getItem(
      storage_configs.localStorage.sessions.membercoursestreamdetailid
    );
    SessionService.getCourseContentBymembercoursestreamdetailid(
      membercoursestreamdetailid
    )
      .then((response) => {
        if (response && response.success == "true") {
          const currentActiveContent = localStorage.getItem(storage_configs.localStorage.sessions.currentActiveContent);
          if (response.data.coursecontentsessiondetail && !currentActiveContent) {
            showContent(
              response.data.coursecontentsessiondetail
                .filter((e) => e.status == 1)
                .sort((a, b) => Number(a.id) - Number(b.id))[0]
            );
          } else {
            dispatch(setSelectedContent(currentActiveContent))
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const showContent = (data) => {
    if (data.curriculumlink) {
      localStorage.setItem(
        storage_configs.localStorage.sessions.show_content,
        data.curriculumlink
      );
      dispatch(setSelectedContent(data.curriculumlink));
      dispatch(setTopicName(data.subject));
      dispatch(
        setSelectedCurriculamType(conditional_configs.curriculam_types.content)
      );
    } else {
      localStorage.setItem(
        storage_configs.localStorage.sessions.show_content,
        data.document.documenturl
      );
      dispatch(setSelectedContent(data.document.documenturl));
      dispatch(setTopicName(data.subject));
      dispatch(
        setSelectedCurriculamType(conditional_configs.curriculam_types.content)
      );
    }
    //window.location.reload();
  };

  const handleClickOpenConfirmationBox = (e) => {
    setConfirmationBoxOpen(true);
  };

  const handleCloseConfirmationBox = () => {
    setConfirmationBoxOpen(false);
  };

  const handleClickRaiseHand = (e) => {
    setIsClickedRaiseHand(true);
    b_room_socket = io(url_configs.breakout_room_server);
    // { name: getCurrentUserData().name, room: slug }

    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );
    if (current_stream_id) {
      MemberService.getMemberCourseGroupByStreamAndLearnerId(
        current_stream_id,
        getCurrentUserData().id
      )
        .then((response) => {
          console.log("Raised group ::: ", response);
          if (response && response.data && response.data.groupname) {
            let data = {
              id: getCurrentUserData().id,
              slug: slug,
              name: getCurrentUserData().name,
              group: response.data.groupname,
            };

            b_room_socket.emit(
              "on-raise-your-hand",
              { data: data },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
          }
          setTimeout(() => {
            setIsClickedRaiseHand(false);
          }, 5000);
        })
        .catch((e) => {
          setTimeout(() => {
            setIsClickedRaiseHand(false);
          }, 5000);
          console.log(e);
        });
    }
  };

  const enableGalleryView = (e) => {
    dispatch(setIsGalleryView(!isGalleryView));
  };

  const calculateGalleryViewGrid = (users) => {
    //let getUsersWithoutHost = users.filter(e => e.isHost !== true);
    let getUsersWithoutHost = users;
    if (getUsersWithoutHost && getUsersWithoutHost.length == 1) {
      return `col-lg-12 h-100`;
    } else if (getUsersWithoutHost && getUsersWithoutHost.length <= 4) {
      return `col-lg-6 learner-gallery-height-col-2`;
    } else {
      return "col-lg-4 learner-gallery-height";
    }
  };

  const calculateExpertVideoInnerGalleryView = (users) => {
    //let getUsersWithoutHost = users.filter(e => e.isHost !== true);
    let getUsersWithoutHost = users;
    if (getUsersWithoutHost && getUsersWithoutHost.length > 2) {
      return `h-80`;
    } else {
      return "h-90";
    }
  };

  //#region NOTES
  const addNotes = () => {
    if (note) {
      setIsRequired(false);
      if (selecetdColor === "") {
        Swal.fire({
          title: "Colour required",
          text: "You won't be able to add notes without selecting a color!",
          icon: "warning",
        });
        return;
      }
      let notesArr = notes;
      notesArr.push({ note: note, color: selecetdColor });
      setNotes(notesArr);
      localStorage.setItem("meekuNotes", JSON.stringify(notesArr));
      getNotes();
      setNote("");
    } else {
      setIsRequired(true);
      // Swal.fire(
      //   {
      //     title: 'Notes required',
      //     text: "You won't be able to add notes without selecting a color!",
      //     icon: 'warning',
      //   }
      // );
    }
    // setColor('');
  };

  const getNotes = () => {
    // localStorage.setItem('meekuNotes', JSON.stringify([]));
    if (localStorage.getItem("meekuNotes") != null) {
      let localNotes = localStorage.getItem("meekuNotes");
      let parsedNotes = JSON.parse(localNotes);
      setNotes(parsedNotes);
    }
  };

  const handleNotes = (e) => {
    if (e.target.value) {
      setNote(e.target.value);
      setIsRequired(false);
    } else {
      setNote(e.target.value);
      setIsRequired(true);
    }
  };

  const removeNote = (e, index) => {
    let localNotes = localStorage.getItem("meekuNotes");
    let parsedNotes = JSON.parse(localNotes);
    parsedNotes.splice(index, 1);
    setNotes(parsedNotes);
    localStorage.setItem("meekuNotes", JSON.stringify(parsedNotes));
    getNotes();
  };

  //#endregion

  const getOnlyParticipantsLength = (pList) => {
    const itemsPerPage = 5;
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.l_participants_v_idx
      )
    );
    if (pList && pList.length > 0) {
      const tempList = participants.filter((e) => !e.isHost && !e.isManager);
      const startIndex = (curIndex > 0 ? curIndex : 0) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const displayedItems = tempList.slice(startIndex, endIndex);
      return {
        currentPageUsers: displayedItems.length,
        balanceUsers: tempList.length - displayedItems.length,
      };
    } else {
      return 0;
    }
  };

  const nextParticipantsVideos = () => {
    ZoomService.stopAllRenderedVideoExceptCurrentUser(
      zoomStream,
      zmClient,
      "learner"
    );
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.l_participants_v_idx
      )
    );
    if (curIndex >= 0) {
      ZoomService.renderParticipantVideoInLearnerVerticalView(
        zoomStream,
        zmClient,
        curIndex + 1,
        false
      );
      setE_participants_h_idx(curIndex + 1);
    } else {
      ZoomService.renderParticipantVideoInLearnerVerticalView(
        zoomStream,
        zmClient,
        0,
        false
      );
      setE_participants_h_idx(0);
    }
  };
  const previousParticipantsVideos = () => {
    ZoomService.stopAllRenderedVideoExceptCurrentUser(
      zoomStream,
      zmClient,
      "learner"
    );
    var curIndex = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.l_participants_v_idx
      )
    );
    if (curIndex == 0) {
      ZoomService.renderParticipantVideoInLearnerVerticalView(
        zoomStream,
        zmClient,
        0,
        false
      );
      setE_participants_h_idx(0);
    } else if (curIndex > 0) {
      ZoomService.renderParticipantVideoInLearnerVerticalView(
        zoomStream,
        zmClient,
        curIndex - 1,
        false
      );
      setE_participants_h_idx(curIndex - 1);
    } else {
      ZoomService.renderParticipantVideoInLearnerVerticalView(
        zoomStream,
        zmClient,
        0,
        false
      );
      setE_participants_h_idx(0);
    }
  };
  const checkExpertJoined = (obj) => {
    if (obj) {
      const hostUser = obj.filter((e) => e.isHost);
      if (hostUser[0]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const renderSwitch = () => {
    switch (sideMenu) {
      case "course":
        return <CourseList />;
      case "docs":
        return (
          <div>
            <div className="ms-2">
              <Typography>
                {sessionDocuments?.map((sDoc, sIndex) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={1}
                      className="align-items-center pt-2"
                    >
                      {/* {curItemObj.documenttype == mimeTypes.pdf ?
                  <img src={fileTypesConfig.pdf} width="30px" /> :
                  curItemObj.documenttype == mimeTypes.doc ?
                    <img src={fileTypesConfig.doc} width="30px" /> :
                    curItemObj.documenttype == mimeTypes.ppt ?
                      <img src={fileTypesConfig.ppt} width="30px" /> :
                      <img src={fileTypesConfig.default} width="30px" />} */}
                      <img src={fileTypesConfig.pdf} width="30px" />
                      <span onClick={(e) => showSessionDocument(sDoc)}>
                        {sDoc.document.filename}
                      </span>
                    </Stack>
                  );
                })}
              </Typography>
            </div>
            <div className="mt-3 ms-2">
              <input type="file" onChange={(e) => onChangeDocumentUpload(e)} />
              {/* <button onClick={this.onFileUpload}>
                  Upload!
                </button> */}
            </div>
          </div>
        );
      case "notes":
        // return <NotesList />;
        let nts = [...notes];
        if (nts) {
          nts = nts.reverse();
        }
        return (
          <div className="get-notes-learner-main">
            {nts.length > 0 &&
              nts.map((noteOb, noteIndex) => {
                return (
                  <div
                    className="note-item-learner"
                    style={{ background: noteOb.color }}
                    key={`l-notes-key-` + noteIndex}
                  >
                    {noteOb.note}
                    <MdClose
                      className=" removeNotes-learner close-icon-learner"
                      onClick={(e) => removeNote(e, noteIndex)}
                    />
                  </div>
                );
              })}
          </div>
        );
      case "chat":
        //return <ChatList />;
        let msgs = [...messages];
        msgs = getChats();
        if (msgs) {
          let openedGrp = getOpenedChatGroup();
          if (openedGrp && getBreakoutSessionStatus() == true) {
            msgs = msgs.filter(
              (e) => e.group && e.group.coursegroupid == openedGrp.coursegroupid
            );
          } else {
            msgs = msgs.filter((e) => !e.group);
          }
          //msgs = getUnique(msgs, 'text');
          msgs = msgs.reverse();
        }

        return (
          <div className="chat-msg-list-v2">
            {msgs?.map((val, i) => {
              return (
                <div key={i}>
                  <div className="chat-my-msg-main-v2">
                    {val.user === getCurrentUserData().name ? (
                      <div>
                        <div className="my-name-container-v2">
                          <span className="my-name-v2">Me</span>
                        </div>
                        <div className="chat-my-msg-container-v2">
                          <div className="rvr-msg-container-v2">
                            <span className="rvr-name-v2">
                              {findTypeOfMessage(val.text) !== true ? (
                                val.text
                              ) : (
                                <img src={val.text} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="rvr-container-v2">
                        <div className="rvr-avatar-container">
                          {getInitialsFromFullName(val.user)}
                        </div>
                        <div className="w-100 rvr-msg-container-div">
                          <div className="rvr-name-container-v2">
                            <span className="chat-rvr-name-v2">
                              {val.user}&nbsp;
                              {val?.userType && val?.userType == "expert"
                                ? "(Expert)"
                                : val?.userType && val?.userType == "admin"
                                  ? "(Admin)"
                                  : ""}
                            </span>
                          </div>
                          <div className="chat-rvr-msg-container-v2">
                            <div className="rvr-msg-container-v2">
                              <span className="chat-rvr-msg-v2">
                                {findTypeOfMessage(val.text) !== true ? (
                                  val.text
                                ) : (
                                  <img src={val.text} />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      default:
        return <></>;
    }
  };
  const maximizeScreen = (e) => {
    e.stopPropagation();
    dispatch(setIsFluid(true));
  };

  const miniimizeScreen = (e) => {
    e.stopPropagation();
    dispatch(setIsFluid(false));
  };

  const breakOutWarning = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: error_messages.learnerBreakoutWarning,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };

  //#region INFORMATION BOX
  const handleClickOpenInformationBox = (e) => {
    setInformationBoxOpen(true);
  };

  const handleCloseInformationBox = () => {
    setInformationBoxOpen(false);
  };
  //#endregion

  return (
    <div className="learner-class-room-container-v2">
      {/* HEADER LARGE SCREEN*/}
      <div className="large-screen">
        <div className="row ms-0 me-0 learner-class-room-header-height">
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0">
            <p className="learner-class-room-logo-container-v2 mb-0">
              <img
                className="learner-class-room-short-logo"
                src={ShortLogo}
              ></img>
              <span className="ms-2 learner-class-room-bread-crumbs text-color-white">
                {" "}
                MEEKU{" "}
                <span className="text-color-active-breadcrumbs">
                  / CLASSROOM
                </span>
                
              </span>
              <span className="ps-1 cursor-pointer text-color-white" onClick={(e) => handleClickOpenInformationBox(e)}>
                  {getIcon(icons_config.infoIcon)}
                </span>
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 text-center text-color-white">
            <div className="large-screen">
              <div className="controls-container ">
                {/* AUDIO ON/OFF */}
                {getUserAudioStatus() === true ||
                  getUserAudioStatus() === undefined ? (
                  <div
                    className="l-class-control-box"
                    onClick={() => audioToggleControls("unmute")}
                  >
                    <FaMicrophoneSlash className="footer-control-icons-v2" />
                  </div>
                ) : (
                  <div
                    className="l-class-control-box"
                    onClick={() => audioToggleControls("mute")}
                  >
                    <FaMicrophone className="footer-control-icons-v2" />
                  </div>
                )}
                {/* VIDEO ON/OFF */}
                {zoomStream && zoomStream.isCapturingVideo() ? (
                  <div>
                    {isVideoLoading ? (
                      <div className="l-class-control-box">
                        <img src={assets.whiteDots} width="23px"></img>
                      </div>
                    ) : (
                      <div
                        className="l-class-control-box"
                        onClick={swichOffLearnerVideo}
                      >
                        <FaVideo className="footer-control-icons-v2" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {isVideoLoading ? (
                      <div className="l-class-control-box">
                        <img src={assets.whiteDots} width="23px"></img>
                      </div>
                    ) : (
                      <div
                        className="l-class-control-box"
                        onClick={swichOnLearnerVideo}
                      >
                        <FaVideoSlash className="footer-control-icons-v2" />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="l-class-control-box"
                  onClick={handleFooterControlMenuClick}
                >
                  {checkIsAnyNotification().length > 0 ? (
                    <span className="general-notification-dot"></span>
                  ) : (
                    ""
                  )}
                  <img src={assets.whiteDots} width="23px"></img>
                </div>

                {/* <div className={`control-box ${!isGalleryView ? `` : `clicked-raised-hand`}`} onClick={(e) => enableGalleryView(e)}>
                {getIcon(icons_config.gridViewIcon)}
              </div> */}

                {!isClickedRaiseHand ? (
                  <div
                    className="l-class-control-box"
                    onClick={(e) => handleClickRaiseHand(e)}
                  >
                    <img src={assets.handIcon} width="23px"></img>
                  </div>
                ) : (
                  <div className="l-class-control-box clicked-raised-hand">
                    <img src={assets.handIcon} width="23px"></img>
                  </div>
                )}

                {/* <div className="l-class-control-box" onClick={(e) => handleClickOpenInformationBox(e)}>
                  {getIcon(icons_config.infoIcon)}
                </div> */}

                <div
                  className="l-class-control-box control-red-bg"
                  onClick={(e) => handleClickOpenConfirmationBox(e)}
                >
                  <img src={assets.zoomControls.end} width="23px"></img>
                </div>

              </div>
            </div>
            {/* <span className="learner-class-room-title-v2">{!isGalleryView ? (getBreakoutSessionStatus() ? "" : topicName) : ""}</span> */}
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 d-flex justify-content-end">
            <div className="learner-user-name-container">
              {getBreakoutSessionStatus() ? (
                <span
                  className="me-1 cursor-pointer"
                  onClick={(e) => breakOutWarning(e)}
                >
                  {getIcon(icons_config.infoIcon)}
                </span>
              ) : (
                ""
              )}
              <span className="me-1">{getCurrentUserData().name}</span>
              {getCurrentUserData().document_path ? (
                <span className="learner-user-name-icon">
                  <img src={getCurrentUserData().document_path} alt="Profile" />
                </span>
              ) : (
                <span className="learner-user-name-icon">
                  {getInitialsFromFullName(getCurrentUserData().name)}
                </span>
              )}
            </div>
            {/* <div className="learner-class-room-session-timing-v2  text-color-white">
              <span>09:00</span>
            </div> */}
          </div>
        </div>
      </div>
      {/* HEADER MOBILE SCREEN */}
      <div className="mobile-screen">
        {/* HEADER MOBILE SCREEN LANDSCAPE */}
        <div className="mobile-screen-landscape">
          <div className="row ms-0 me-0 learner-class-room-header-height">
            <div className="col-4 ps-0 pe-0">
              <p className="learner-class-room-logo-container-v2 mb-0">
                <img
                  className="learner-class-room-short-logo"
                  src={ShortLogo}
                ></img>
                <span className="ms-2 learner-class-room-bread-crumbs text-color-white">
                  {" "}
                  MEEKU{" "}
                  <span className="text-color-active-breadcrumbs">
                    / CLASSROOM
                  </span>
                </span>
              </p>
            </div>
            <div className="col-4 ps-0 pe-0 text-center  text-color-white">
              <span className="learner-class-room-title-v2">{topicName}</span>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 d-flex justify-content-end">
              <div className="learner-user-name-container">
                {getBreakoutSessionStatus() ? (
                  <span
                    className="me-1 cursor-pointer"
                    onClick={(e) => breakOutWarning(e)}
                  >
                    {getIcon(icons_config.infoIcon)}
                  </span>
                ) : (
                  ""
                )}
                <span className="me-1">{getCurrentUserData().name}</span>
                <span className="learner-user-name-icon">
                  {getInitialsFromFullName(getCurrentUserData().name)}
                </span>
              </div>
            </div>
            {/* <div className="col-4 ps-0 pe-0 d-flex justify-content-end">
              <div className="learner-class-room-session-timing-v2  text-color-white">
                <span>09:00</span>
              </div>
            </div> */}
          </div>
        </div>
        {/* HEADER MOBILE SCREEN PORTRAIT */}
        <div className="mobile-screen-portrait">
          <div className="row ms-0 me-0 learner-class-room-header-height">
            <div className="col-6 col-sm-6 ps-0 pe-0">
              <p className="learner-class-room-logo-container-v2 mb-0">
                <img
                  className="learner-class-room-short-logo"
                  src={ShortLogo}
                ></img>
                <span className="ms-2 learner-class-room-bread-crumbs text-color-white">
                  {" "}
                  MEEKU{" "}
                  <span className="text-color-active-breadcrumbs">
                    / CLASSROOM
                  </span>
                </span>
              </p>
            </div>
            {/* <div className="col-6 col-sm-6 ps-0 pe-0 d-flex justify-content-end">
              <div className="learner-class-room-session-timing-v2  text-color-white">
                <span>09:00</span>
              </div>
            </div> */}
          </div>
          <div className="row ms-0 me-0 learner-class-room-header-height">
            <div className="col-12 ps-0 pe-0 text-center  text-color-white">
              <span className="learner-class-room-title-v2">{topicName}</span>
            </div>
          </div>
        </div>
      </div>
      {/* CONTENT */}
      <div className="height-100">
        <div className="learner-class-room-content-height">
          <div className="learner-class-room-content-row">
            {/* LEFT SIDE CONTENT */}
            {!isGalleryView ? (
              <div
                ref={imageRef}
                className={
                  rightSideMenuBar
                    ? "learner-class-room-content-container-extended-v2"
                    : `${isFluid
                      ? "learner-class-room-content-container-extended-v2"
                      : "learner-class-room-content-container-v2"
                    }`
                }
              >
                {isFluid !== true && !isLoading ? (
                  <span
                    className="screen-resize"
                    title="Maximize"
                    onClick={(e) => maximizeScreen(e)}
                  >
                    <img src={assets.maximize}></img>
                  </span>
                ) : isFluid == true && !isLoading ? (
                  <span
                    className="screen-resize"
                    title="Minimize"
                    onClick={(e) => miniimizeScreen(e)}
                  >
                    <img src={assets.minimize}></img>
                  </span>
                ) : (
                  ""
                )}
                {breakOutSessionStatus !== "STARTED" ? (
                  <div className="height-100">
                    {isLoading ? (
                      <LoadingSpinner
                        message={error_messages.startBreakoutRoomInLearner}
                      />
                    ) : (
                      <div className="height-100">
                        {isScreenShareOn ? (
                          <canvas
                            id="screen-share-canvas"
                            className="learner_share_view_canvas border-radius-20"
                          ></canvas>
                        ) : (
                          <CourseView />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="height-100">
                    {isLoading ? (
                      <LoadingSpinner
                        message={error_messages.stopBreakoutRoomInLearner}
                      />
                    ) : (
                      <div className="height-100 p-0">
                        {breakoutCurrentScreenData &&
                          (breakoutCurrentScreenData.selectedCurriculamType ==
                            conditional_configs.curriculam_types.whiteboard ||
                            breakoutCurrentScreenData.selectedCurriculamType ==
                            conditional_configs.curriculam_types.games) ? (
                          <BreakoutWhiteboard></BreakoutWhiteboard>
                        ) : breakoutCurrentScreenData &&
                          breakoutCurrentScreenData.selectedCurriculamType ==
                          conditional_configs.curriculam_types.quiz ? (
                          <div className="quiz-container-v2">
                            {!showResult ? (
                              <div className="h-100 w-100">
                                <div>
                                  <span className="active-question-no">
                                    {addLeadingZero(activeQuestion + 1)}
                                  </span>
                                  <span className="total-question">
                                    /{addLeadingZero(questions.length)}
                                  </span>
                                </div>
                                <h2>{question}</h2>
                                <ul>
                                  {choices.map((answer, index) => (
                                    <li
                                      onClick={() =>
                                        onAnswerSelected(
                                          answer.id,
                                          index,
                                          answer
                                        )
                                      }
                                      key={`q-ans` + answer.id}
                                      className={
                                        selectedAnswerIndex === index
                                          ? "selected-answer"
                                          : null
                                      }
                                    >
                                      {answer.answer}
                                    </li>
                                  ))}
                                </ul>
                                <div className="d-flex justify-content-between pb-4">
                                  {activeQuestion > 0 ? (
                                    <div className="flex-left">
                                      <span
                                        className="quiz-back-btn"
                                        onClick={onClickBackQuiz}
                                      >
                                        Back
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="flex-right">
                                    <button
                                      onClick={onClickNext}
                                      disabled={selectedAnswerIndex === null}
                                    >
                                      {activeQuestion === questions.length - 1
                                        ? "Finish"
                                        : "Next"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="result">
                                <h3>Result</h3>
                                <p>
                                  Total Question:{" "}
                                  <span>{questions.length}</span>
                                </p>
                                <p>
                                  Total Score:<span> {result.score}</span>
                                </p>
                                <p>
                                  Correct Answers:
                                  <span> {result.correctAnswers}</span>
                                </p>
                                <p>
                                  Wrong Answers:
                                  <span> {result.wrongAnswers}</span>
                                </p>
                                <div className="flex-right">
                                  <span
                                    className="retry-quiz-btn"
                                    onClick={(e) => retryQuiz(e)}
                                  >
                                    Retry
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <LoadingSpinner
                            message={"Please wait. Expert will allow you."}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* RIGHT SIDEBAR */}
                {rightSideMenuBar ? (
                  <div className="right-sidebar">
                    <div className="right-sidebar-heading">
                      <span>{pageMenuTitle}</span>
                      <img
                        className="cursor-pointer"
                        src={assets.whiteClose}
                        onClick={(e) => closePageMenuItem(e)}
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div className="right-sidebar-container">
                      <div className="right-sidebar-body">{renderSwitch()}</div>
                    </div>
                    {sideMenu == "chat" ? (
                      <form
                        className="mt-2 mb-2 chat-box-container-v2"
                        action=""
                        onSubmit={handleChatSubmit}
                      >
                        <textarea
                          id="chat-text-area"
                          rows={3}
                          className="form-control send-input-v2"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Write message"
                        />
                        <div className="input-group mb-3 send-input-box-v2">
                          <span className="pe-2 send-screenshot d-flex">
                            <img
                              src={assets.emoji}
                              width={23}
                              height={23}
                              className="me-2"
                              onClick={(e) => handleEmojiControlMenuClick(e)}
                            ></img>
                            <span onClick={(e) => sendScreenshot(e)}>
                              {" "}
                              {getIcon(icons_config.screenshot)}
                            </span>
                          </span>
                          {/* <span className="pe-2 send-screenshot" >
                       
                      </span> */}
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary chat-send-btn"
                              type="submit"
                            >
                              <img src={assets.sendChatWhite}></img>
                              Send
                            </button>
                          </div>
                        </div>
                      </form>
                    ) : sideMenu == "notes" ? (
                      <div className="notes-input-learner">
                        <textarea
                          className="notes-textarea-learner"
                          rows={4}
                          placeholder="Add notes here.."
                          value={note}
                          onChange={handleNotes}
                        ></textarea>
                        <div className="notes-action-learner">
                          <div className="notes-color-learner">
                            <div
                              className="notes-red-learner"
                              style={{
                                zoom: selecetdColor === "#f15c5c" ? "1.3" : "1",
                              }}
                              onClick={() => setColor("#f15c5c")}
                            ></div>
                            <div
                              className="notes-blue-learner"
                              style={{
                                zoom: selecetdColor === "#0dcaf0" ? "1.3" : "1",
                              }}
                              onClick={() => setColor("#0dcaf0")}
                            ></div>
                            <div
                              className="notes-yellow-learner"
                              style={{
                                zoom: selecetdColor === "#ffc107" ? "1.3" : "1",
                              }}
                              onClick={() => setColor("#ffc107")}
                            ></div>
                            <div
                              className="notes-violet-learner"
                              style={{
                                zoom: selecetdColor === "#9b79fb" ? "1.3" : "1",
                              }}
                              onClick={() => setColor("#9b79fb")}
                            ></div>
                          </div>
                          <div
                            className="add-note-v2-learner"
                            onClick={addNotes}
                          >
                            +&nbsp;ADD NOTE
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {/* RIGHT SIDE PARTICIPANTS */}
            <div
              className={
                !isGalleryView
                  ? `${isFluid
                    ? "participants-column-hidden"
                    : "participants-column"
                  }`
                  : `w-100`
              }
            >
              <div>
                {/* PARTICIPANTS LIST */}
                <div
                  className={
                    !isGalleryView
                      ? `learners-list-box-v2`
                      : `row w-100 learner-gallery-view-row m-0`
                  }
                >
                  {participants.length > 0 &&
                    participants?.map(
                      (participant, pInd) =>
                        participant.isHost ? (
                          <div
                            key={`expert-` + participant.userId}
                            className={
                              !isGalleryView
                                ? `learner-class-room-expert-box-v2`
                                : `learner-class-room-expert-box-v2 col-12 col-sm-12 p-3 ${calculateGalleryViewGrid(
                                  participants
                                )}`
                            }
                          >
                            <span className="expert-lable">Expert</span>
                            <div
                              className={
                                !isGalleryView
                                  ? `learner-class-room-expert-container-v2`
                                  : `learner-class-room-expert-container-v2 h-100`
                              }
                            >
                              <div
                                className={
                                  !isGalleryView
                                    ? `host-container-v2`
                                    : `host-container-expert-gallery-v2 ${calculateExpertVideoInnerGalleryView(
                                      participants
                                    )}`
                                }
                              >
                                <div
                                  className={
                                    !participant.bVideoOn
                                      ? "host-image-container-display-hide"
                                      : `host-image-container-v2-transparent ${participant?.isTalking
                                        ? `host-image-container-v2-audio-enable`
                                        : ``
                                      }`
                                  }
                                >
                                  <video
                                    id="self-view-video"
                                    width="1920"
                                    height="1080"
                                  ></video>
                                  <canvas
                                    id="self-view-canvas"
                                    width="1920"
                                    height="1080"
                                  ></canvas>
                                  {participant?.muted == true ||
                                    participant?.muted == undefined ? (
                                    <img
                                      src={assets.zoomControls.muted}
                                      className="participant-item-card-footer-icon-v2"
                                    ></img>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {!participant.bVideoOn ? (
                                  <div className="host-image-container-v2">
                                    <div
                                      className={`host-rounded-video-off-v2 ${participant?.isTalking
                                          ? `host-rounded-video-off-v2-audio-enable`
                                          : "host-rounded-video-off-v2-audio-disable"
                                        }`}
                                    >
                                      {participant.userIdentity ? (
                                        <div className="host-rounded-video-off-div-v2">
                                          <span className="host-video-off-initial-v2-in-image">
                                            <img
                                              src={`${url_configs.imageDocumentPath}${participant.userIdentity}`}
                                              alt="Profile"
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="host-rounded-video-off-div-v2">
                                          <span className="host-video-off-initial-v2">
                                            {getInitialsFromFullName(
                                              participant.displayName
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {participant?.muted == true ||
                                      participant?.muted == undefined ? (
                                      <img
                                        src={assets.zoomControls.muted}
                                        className="participant-item-card-footer-icon-v2"
                                      ></img>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="host-label-v2">
                                  {displayOnlyFirstName(
                                    participant.displayName
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      // (
                      //   <div key={participant.userId} className={!isGalleryView ? (pInd == 1 ? 'host-container-v2' : 'host-container-v2 learners-list-containter-v2') : `col-12 col-sm-12 p-3 ${calculateGalleryViewGrid(participants)}`}>
                      //     <div className={!participant.bVideoOn ? 'host-image-container-display-hide' : 'host-image-container-v2'} >
                      //       <video
                      //         id={'participants-video-' + participant.userId}
                      //       ></video>
                      //       <canvas id={'participants-canvas-' + participant.userId}></canvas>
                      //       {participant?.muted == true || participant?.muted == undefined ?
                      //         <img src={assets.zoomControls.muted} className="participant-item-card-footer-icon-v2"></img> :
                      //         ""}
                      //     </div>
                      //     {!participant.bVideoOn ?
                      //       <div className="host-image-container-v2">
                      //         <div className={`host-rounded-video-off-v2 ${participant?.isTalking ? `host-rounded-video-off-v2-audio-enable` : 'host-rounded-video-off-v2-audio-disable'}`}>
                      //           <div className="host-rounded-video-off-div-v2">
                      //             <span className="host-video-off-initial-v2" >{getInitialsFromFullName(participant.displayName)}</span>
                      //           </div>
                      //         </div>
                      //         {participant?.muted == true || participant?.muted == undefined ?
                      //           <img src={assets.zoomControls.muted} className="participant-item-card-footer-icon-v2"></img> :
                      //           ""}
                      //       </div> : ""
                      //     }
                      //     <div className="host-label-v2">
                      //       {displayOnlyFirstName(participant.displayName)}
                      //     </div>
                      //   </div>
                      // )
                    )}
                </div>

                <div
                  className={
                    checkExpertJoined(participants) !== true
                      ? `mt-2 learner-participants-canvas-main-div learner-participants-canvas-main-div-left-adjustment`
                      : `mt-2 learner-participants-canvas-main-div`
                  }
                >
                  <canvas
                    id="learner-participant-videos-canvas-vertical-view"
                    width="130"
                    height="700"
                  ></canvas>
                  {/* <canvas id="learner-participant-videos-video-vertical-view" width="130" height="700"></canvas> */}
                  <canvas
                    id="learner-participant-videos-canvas-vertical-view-card"
                    width="130"
                    height="700"
                  ></canvas>
                </div>
              </div>
              <div className="d-flex justify-between learner-participants-pagination-main">
                {e_participants_h_idx > 0 ? (
                  <div
                    title="Previous participants"
                    className="learner-participants-prev-videos-div"
                    onClick={previousParticipantsVideos}
                  >
                    <KeyboardArrowLeft />
                  </div>
                ) : (
                  ""
                )}
                {participants &&
                  getOnlyParticipantsLength(participants).balanceUsers > 0 ? (
                  <div
                    title="Next participants"
                    className="learner-participants-next-videos-div"
                    onClick={nextParticipantsVideos}
                  >
                    <KeyboardArrowRight />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER CONTROLS */}
        <div className="footer-controls mobile-screen">
          <div className="controls-container">
            {/* AUDIO ON/OFF */}
            {getUserAudioStatus() === true ||
              getUserAudioStatus() === undefined ? (
              <div
                className="l-class-control-box"
                onClick={() => audioToggleControls("unmute")}
              >
                <FaMicrophoneSlash className="footer-control-icons-v2" />
              </div>
            ) : (
              <div
                className="l-class-control-box"
                onClick={() => audioToggleControls("mute")}
              >
                <FaMicrophone className="footer-control-icons-v2" />
              </div>
            )}
            {/* VIDEO ON/OFF */}
            {zoomStream && zoomStream.isCapturingVideo() ? (
              <div>
                {isVideoLoading ? (
                  <div className="l-class-control-box">
                    <img src={assets.whiteDots} width="23px"></img>
                  </div>
                ) : (
                  <div
                    className="l-class-control-box"
                    onClick={swichOffLearnerVideo}
                  >
                    <FaVideo className="footer-control-icons-v2" />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isVideoLoading ? (
                  <div className="l-class-control-box">
                    <img src={assets.whiteDots} width="23px"></img>
                  </div>
                ) : (
                  <div
                    className="l-class-control-box"
                    onClick={swichOnLearnerVideo}
                  >
                    <FaVideoSlash className="footer-control-icons-v2" />
                  </div>
                )}
              </div>
            )}
            <div
              className="l-class-control-box"
              onClick={handleFooterControlMenuClick}
            >
              {checkIsAnyNotification().length > 0 ? (
                <span className="general-notification-dot"></span>
              ) : (
                ""
              )}
              <img src={assets.whiteDots} width="23px"></img>
            </div>

            {/* <div className={`control-box ${!isGalleryView ? `` : `clicked-raised-hand`}`} onClick={(e) => enableGalleryView(e)}>
                {getIcon(icons_config.gridViewIcon)}
              </div> */}

            {!isClickedRaiseHand ? (
              <div
                className="l-class-control-box"
                onClick={(e) => handleClickRaiseHand(e)}
              >
                <img src={assets.handIcon} width="23px"></img>
              </div>
            ) : (
              <div className="l-class-control-box clicked-raised-hand">
                <img src={assets.handIcon} width="23px"></img>
              </div>
            )}

            <div className="l-class-control-box" onClick={(e) => handleClickOpenInformationBox(e)}>
              {getIcon(icons_config.infoIcon)}
            </div>

            <div
              className="l-class-control-box control-red-bg"
              onClick={(e) => handleClickOpenConfirmationBox(e)}
            >
              <img src={assets.zoomControls.end} width="23px"></img>
            </div>
          </div>
        </div>
      </div>

      <Menu
        className="large-screen"
        open={footerCtrlMenu}
        onClose={handleFooterControlMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={(e) => openPageMenuItem(e, "chat")}>
          <ListItemIcon>
            {checkIsAnyNotification().length > 0 ? (
              <span className="general-notification-dot"></span>
            ) : (
              ""
            )}
            <img src={assets.chat} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Chat</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => openPageMenuItem(e, "notes")}>
          <ListItemIcon>
            <img src={assets.notes} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Notes</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => openPageMenuItem(e, "docs")}>
          <ListItemIcon>
            <img src={assets.document} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Documents</Typography>
        </MenuItem>
        {/* <MenuItem onClick={(e) => openPageMenuItem(e, 'course')}>
          <ListItemIcon>
            <img src={assets.course_topic} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Course Topics</Typography>
        </MenuItem> */}
      </Menu>

      <Menu
        className="mobile-screen"
        open={footerCtrlMenu}
        onClose={handleFooterControlMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem onClick={(e) => openPageMenuItem(e, "chat")}>
          <ListItemIcon>
            {checkIsAnyNotification().length > 0 ? (
              <span className="general-notification-dot"></span>
            ) : (
              ""
            )}
            <img src={assets.chat} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Chat</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => openPageMenuItem(e, "notes")}>
          <ListItemIcon>
            <img src={assets.notes} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Notes</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => openPageMenuItem(e, "docs")}>
          <ListItemIcon>
            <img src={assets.document} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Documents</Typography>
        </MenuItem>
        {/* <MenuItem onClick={(e) => openPageMenuItem(e, 'course')}>
          <ListItemIcon>
            <img src={assets.course_topic} className="page-menu-item-img" />
          </ListItemIcon>
          <Typography variant="inherit">Course Topics</Typography>
        </MenuItem> */}
      </Menu>

      {/* EMOJI MENU */}
      <Menu
        className="emoji-menu"
        anchorEl={anchorEl}
        open={emojiCtrlMenu}
        onClose={handleEmojiControlMenuClose}
      >
        <MenuItem>
          <EmojiPicker
            onEmojiClick={onClickEmoji}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </MenuItem>
      </Menu>

      <Dialog
        fullScreen={fullScreen}
        open={confirmationBoxOpen}
        onClose={handleCloseConfirmationBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-center"
          id="responsive-dialog-title"
        >
          <img src={assets.alertIcon} width="90"></img>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="leave-session-warn">
            {error_messages.leaveSessionWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <div className="confirm-no-btn" onClick={handleCloseConfirmationBox}>
            No
          </div>
          <div className="confirm-yes-btn" onClick={endSessionByLearner}>
            Yes
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        fullScreen={fullScreen}
        open={informationBoxOpen}
        onClose={handleCloseInformationBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-center"
          id="responsive-dialog-title"
        >
          <img src={assets.alertIcon} width="90"></img>
        </DialogTitle>
        <DialogContent>
          <div>
            <span className="info-box-label">Browser:</span>
            <span className="info-box-text">{getExploreName()}</span>
          </div>
          <div>
            <span className="info-box-label">Video Permission:</span>
            <span className="info-box-text">{zoomStream && !zoomStream.isCaptureForbidden() ? 'Allowed' : 'Not allowed'}</span>
          </div>
          <div>
            <span className="info-box-label">Is Video Enabled:</span>
            <span className="info-box-text">{zoomStream && zoomStream.isCapturingVideo() ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <span className="info-box-label">Is Audio Enabled:</span>
            <span className="info-box-text">{zoomStream && !zoomStream.isAudioMuted() ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <span className="info-box-label">Active Camera:</span>
            <span className="info-box-text">{getActiveCamera(zoomStream)}</span>
          </div>
          <div>
            <span className="info-box-label">Active Microphone:</span>
            <span className="info-box-text">{getActiveMicrophone(zoomStream)}</span>
          </div>
          <div>
            <span className="info-box-label">Active Speaker :</span>
            <span className="info-box-text">{getActiveSpeaker(zoomStream)}</span>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <div className="confirm-no-btn" onClick={handleCloseInformationBox}>
            Close
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
