
import { useState, useEffect, useRef } from 'react';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
//import { sentenceCase } from 'change-case';
//import { Link as RouterLink } from 'react-router-dom';
import CourseService from '../../../../services/courses-service';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Menu, MenuItem, ListItemIcon, ListItemText
} from '@mui/material';
// components
import Page from '../../../../components/Page';
//import Label from '../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../../sections/@dashboard/user';

//ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ChildCareIcon from '@mui/icons-material/ChildCare';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// CONFIGS
import navigate_route_config from '../../../../config/navigate_route_config';
import { getCurrentUserData } from '../../../../utils/util';
import conditional_configs from '../../../../config/conditional_config';
import storage_configs from '../../../../config/storage-config';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'coursename', label: 'Course Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'expertid', label: 'Experts', alignRight: false },
  { id: 'learners', label: 'Learners', alignRight: false },
  { id: 'schedule', label: 'Schedules', alignRight: false },
  { id: 'categories', label: 'Categories', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.coursename.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function Courses() {

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUserList] = useState([]);

  const navigate = useNavigate();

  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    localStorage.removeItem(storage_configs.localStorage.admin.courses.courseId);
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      CourseService.getCourses().then(response => {
        if(response && response.data && response.data.length>0){
          console.log(response.data)
          setUserList(response.data.sort((a, b) => Number(b.courseid) - Number(a.courseid)));
        }
      })
        .catch(e => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }

  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const navigateToCreateCourse = (courseId) => {
    if(courseId !== ""){
      localStorage.setItem(storage_configs.localStorage.admin.courses.courseId, courseId.toString());
    }
    localStorage.setItem(storage_configs.localStorage.admin.courses.currentStep, "0");
    navigate(navigate_route_config.admin.create_course);
  }

  const navigateToCourseDetail = (route, course_id) => {
    navigate(route + '/' + course_id);
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
          <Typography variant="h4" gutterBottom className='mb-0'>
            Courses
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e)=>navigateToCreateCourse("")}>
            New Course
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filterPlaceholder='Search Course Name' />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { courseid, coursename, price, expertid, minnolearners, maxnolearners, schedules, name, } = row;
                    const isItemSelected = selected.indexOf(courseid) !== -1;

                    return (
                      <TableRow
                        hover
                        key={courseid}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, courseid)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {coursename}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">${price}</TableCell>
                        <TableCell align="left">{expertid}</TableCell>
                        <TableCell align="left">{'Min ' + minnolearners + ' Max ' + maxnolearners}</TableCell>
                        <TableCell align="left">{schedules}</TableCell>
                        <TableCell align="left" >{name}</TableCell>
                        <TableCell align="left" >
                          <Typography>
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Edit Course" placement="bottom-start">
                                <IconButton aria-label="edit" onClick={(event) => navigateToCreateCourse(courseid)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              {/* <IconButton aria-label="Kid" >
                                <ChildCareIcon />
                              </IconButton> */}
                              <Tooltip title="Pod Detail" placement="bottom-start">
                                <IconButton aria-label="Pod" onClick={(event) => navigateToCourseDetail(navigate_route_config.admin.course_detail, courseid)}>
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </Tooltip>

                            </Stack>
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}
                        <TableCell align="right">
                          {/* <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                            <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
                          </IconButton>
                          <Menu
                            open={isOpen}
                            anchorEl={ref.current}
                            onClose={() => setIsOpen(false)}
                            PaperProps={{
                              sx: { width: 200, maxWidth: '100%' },
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>

                            <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:edit-fill" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                          </Menu> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

    </Page>


  );
}
