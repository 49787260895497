import React, { useState } from 'react';
import { useEffect } from 'react';
import Joyride from 'react-joyride';
import tour_Manager_Steps from '../../config/tour_start';
import Iconify from '../Iconify';
import icons_config from '../../config/icons';
import './tour-manager.css'
import { getCurrentUserData } from '../../utils/util';
import conditional_configs from '../../config/conditional_config';
import navigate_route_config from '../../config/navigate_route_config';

const TourManager = () => {

  const [currentUrl, setCurrentUrl] = useState();


  const getIcon = (name) => <Iconify icon={name} width={20} height={20} sx={{ marginRight: '4px', marginLeft: '4px' }} />;
  const [runTour, setRunTour] = useState(false);


  useEffect(() => {
    const CurrentUrl = window.location.pathname;
    setCurrentUrl(CurrentUrl)
    console.log(currentUrl)
  });

  const startTour = () => {
    setRunTour(true);
  };

  const stopTour = () => {
    setRunTour(false);
  };

  return (
    <div>


      {/* <button onClick={stopTour}>Stop Tour</button> */}


      {
        getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner && currentUrl == navigate_route_config.learner.my_courses ?
          <div>
            <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
            <Joyride
              steps={tour_Manager_Steps.learnerTourSteps}
              run={runTour}
              continuous={true}
              showProgress={true}
              showSkipButton={true}
              locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
            />
          </div>
          :
          getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert && currentUrl == navigate_route_config.expert.my_courses ?
            <div>
              <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
              <Joyride
                steps={tour_Manager_Steps.expertTourSteps}
                run={runTour}
                continuous={true}
                showProgress={true}
                showSkipButton={true}
                locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
              />
            </div>

            :
            getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner && currentUrl == navigate_route_config.learner.pods ?
              <div>
                <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                <Joyride
                  steps={tour_Manager_Steps.learnerPodsTourSteps}
                  run={runTour}
                  continuous={true}
                  showProgress={true}
                  showSkipButton={true}
                  locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                  styles={
                    {
                      buttonClose: {
                        display: 'none',
                      },
                    }
                  }
                />
              </div>
              :
              getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert && currentUrl == navigate_route_config.expert.pods + '/' + '125' ?
                <div>
                  <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                  <Joyride
                    steps={tour_Manager_Steps.expertPodsTourSteps}
                    run={runTour}
                    continuous={true}
                    showProgress={true}
                    showSkipButton={true}
                    locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                    styles={
                      {
                        buttonClose: {
                          display: 'none',
                        },
                      }
                    }
                  />
                </div> :
                //parent
                getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.my_courses ?
                  <div>
                    <button className='Take-the-Tour-btn-myc' onClick={startTour}>Take the Tour</button>
                    <Joyride
                      steps={tour_Manager_Steps.parentCoursesTourSteps}
                      run={runTour}
                      continuous={true}
                      showProgress={true}
                      showSkipButton={true}
                      locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                      styles={
                        {
                          buttonClose: {
                            display: 'none',
                          },
                        }
                      }
                    />
                  </div> :
                  getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.my_kids ?
                    <div>
                      <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                      <Joyride
                        steps={tour_Manager_Steps.parentKidsTourSteps}
                        run={runTour}
                        continuous={true}
                        showProgress={true}
                        showSkipButton={true}
                        locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                        styles={
                          {
                            buttonClose: {
                              display: 'none',
                            },
                          }
                        }
                      />
                    </div> :
                    getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.home ?
                      <div>
                        <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                        <Joyride
                          steps={tour_Manager_Steps.parentOtherCoursesTourSteps}
                          run={runTour}
                          continuous={true}
                          showProgress={true}
                          showSkipButton={true}
                          locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                          styles={
                            {
                              buttonClose: {
                                display: 'none',
                              },
                            }
                          }
                        />
                      </div> : ""
      }
    </div>
  );
};

export default TourManager;
