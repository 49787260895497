import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import slugify from 'react-slugify';
import * as Yup from 'yup';
import * as nsfwjs from 'nsfwjs';
import Filter from 'bad-words';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import "./course-detail-white-board.css"
import SelfLearningService from "../../../../services/self-learning-service";
import { checkIsEmdedCode, findTypeOfMessage, getChats, getCurrentUserData, getInitialsFromFullName, storeChats, validateEmail, isAddressIncluded, checkForPlacesInfo, copyToClipBoardWithMsgWithOutAlert } from "../../../../utils/util";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Stack, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from "@mui/icons-material/Close";
import games from "../../../../config/games";
import assets from "../../../../config/assets";
import conditional_configs from "../../../../config/conditional_config";
import UtilService from "../../../../services/util.service";
import game_config from "../../../../config/game_config";
import io from "socket.io-client";
import url_configs from "../../../../config/url";
import GameService from "../../../../services/games-service";
import { fDate } from "../../../../utils/formatTime";
import FirebaseService from "../../../../services/firebase-service";
import Iconify from "../../../../components/Iconify";
import icons_config from "../../../../config/icons";
import SessionService from "../../../../services/session-service";
import storage_configs from "../../../../config/storage-config";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentService from "../../../../services/payment-service";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import navigate_route_config from "../../../../config/navigate_route_config";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

let b_room_socket;
let chat_server_socket;
let friends_server_socket;

//#region MUI TABS
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//#endregion

//#region MUI Card
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
//#endregion
export default function CourseDetailWhiteBoard() {
  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;
  const { enqueueSnackbar } = useSnackbar();

  // const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [myGames, setMyGames] = React.useState([]);
  const [myBoards, setMyBoards] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [gameResults, setGameResults] = React.useState([]);
  const [openGameShareCodePopup, setGameShareCodePopup] = useState(false);
  const [openedContent, setOpenedContent] = useState();
  const [openedGroupData, setOpenedGroupData] = useState();
  const [openJoinWithCodePopup, setOpenJoinWithCodePopup] = useState(false);
  const [openGameSettings, setOpenGameSettings] = useState(false);
  const [gameSettingLink, setGameSettingLink] = useState();

  const [openJoinWithCodeGame, setOpenJoinWithCodeGame] = useState();

  //WHITEBOARD TAB DECLARATIONS
  const [openBoardShareCodePopup, setOpenBoardShareCodePopup] = useState(false);
  const [openWhiteboardTabScreen, setOpenWhiteboardTabScreen] = useState(false);
  const [whiteBoardTabList, setWhiteBoardTabList] = React.useState([]);
  const [whiteboardLink, setWhiteboardLink] = React.useState();
  const [whiteboardKey, setWhiteboardKey] = React.useState();

  //CHATS
  const [enableChatWindow, setEnableChatWindow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [privateMessageCount, setPrivateMessageCount] = useState([]);
  const [groupMessageCount, setGroupMessageCount] = useState([]);
  const [planMessagecount, setplanMessagecount] = useState(0);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState();
  const [remainingMessageCount, setRemainingMessageCount] = useState();


  //MY FRIENDS LIST
  const [myIntractedFriendsList, setMyIntractedFriendsList] = React.useState([]);
  const [myChatFriendsList, setMyChatFriendsList] = React.useState([]);
  const [myFriendsAnchorEl, setMyFriendsAnchorEl] = useState();
  const [myFriendsCtrlMenu, setMyFriendsCtrlMenu] = useState(false);
  const [openIndividualChat, setOpenIndividualChat] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [inviteFriendPopup, setInviteFriendPopup] = useState(false);
  const [userName, setUserName] = useState(false);

  const [myChatInviteList, setMyChatInviteList] = useState([]);

  const [subscriptionStatus, setSubscriptionStatus] = useState();

  const [showSuccessImage, setShowSuccessImage] = useState(false);

  const [joinWithCodeErrorMessage, setJoinWithCodeErrorMessage] = useState("");

  const navigate = useNavigate();

  const filter = new Filter();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const game_card_img = {
    backgroundImage: `url(${assets.course_v4.game_card_img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "30px",
    paddingBottom: "50px",
    width: "100%",
    height: "100%"
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //Get course details
  useEffect(() => {
    getCourseDetailByLearnerId();
    socketConnections();
    getChatFriends();
    getIntractedFriends();
    getMyInvites();

    // Initial fetch
    fetchMessageCounts();

  }, []);

  useEffect(() => {
    friends_server_socket = io(url_configs.chat_server);
    friends_server_socket.on('private_message', (data) => {
      console.log(data);
      const { sender_id, message } = data;
      console.log(`Received message from ${sender_id}: ${message}`);
      enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      //update unread message count
      getChatFriends();

      // Only display messages from the friend
      if (sender_id === openIndividualChat.learner.id) {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    });
    getMySubscriptionDetail();
    return () => {
      friends_server_socket.disconnect();
    };
  }, [openIndividualChat ? openIndividualChat.learner.id : ""]);

  // Function to fetch message counts at intervals
  const fetchMessageCounts = () => {
    getCurrentUserPrivateMessageById();
    getCurrentUserGroupMessageById();
  };


  /** SOCKET CONNECTIONS */
  const socketConnections = () => {
    b_room_socket = io(url_configs.breakout_room_server);
    // b_room_socket.emit("join", { name: getCurrentUserData().name, sessionSlug: openedGroupData.code, room: null, role: 0 }, (error) => {
    //   if (error) {
    //     console.log(error)
    //     //alert(error);
    //   }
    // });

    b_room_socket.on("auto-play-game", (obj) => {
      let tempGameObj = getGameDetail(obj.data.game.id);

      //setIsLoading(true);
      setTimeout(() => {
        getGameLink(tempGameObj);
        // let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
        // if (tempScreenData) {
        //   tempScreenData.screentype = "startgame";
        //   setBreakoutCurrentScreenData(tempScreenData);
        //   localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempScreenData));
        // }
      }, conditional_configs.loader.timeout);
    });

    b_room_socket.on("joined-self-learner", (obj) => {
      getPlayersList();
    });
  }

  /** GET GAME PLAY LINK */
  const getGameLink = (gameData) => {
    if (gameData) {
      const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
      const tempOpenedContent = JSON.parse(localStorage.getItem('openedContent'));
      //Group Detail
      let groupDetail = {
        id: tempOpenedGroupData.id,
        name: tempOpenedGroupData.code,
      }

      //Learner Detail
      let learnerDetail = {
        id: getCurrentUserData().id,
        name: getCurrentUserData().name,
        groupDetail: groupDetail
      }

      //Game Detail
      let gameDetail = {
        id: tempOpenedContent.game_id,
        name: getGameDetail(tempOpenedContent?.game_id)?.name
      }

      //Game Settings
      let gameSettings = {};

      GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id).then(response => {
        console.log(response)
        if (response && response.data && response.data.length > 0) {
          gameSettings = response.data[0].learnersettings[0];
          console.log('Game Settings');
          console.log(gameSettings);


          let tempJsonObj = {
            learnerDetail: learnerDetail,
            gameDetail: gameDetail,
            userType: game_config.userType.student,
            gameSettings: gameSettings,
            slug: tempOpenedGroupData.code,
            courseStreamId: null,
            isInSession: true
          }


          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList
            }
            console.log('My Game Obj')
            console.log(model);
            UtilService.encryptJsonValue(model).then(response => {
              console.log(response)
              if (response && response.data) {
                setGameSettingLink("");
                let tempGameLink = '';
                tempGameLink = gameData.route + response.data.token;
                setTimeout(() => {
                  setGameSettingLink(tempGameLink);
                }, conditional_configs.loader.timeout);
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        } else {
          //gameSettings = response.data[0].learnersettings[0];
          console.log('Game Settings');
          console.log(gameSettings);

          let tempJsonObj = {
            learnerDetail: learnerDetail,
            gameDetail: gameDetail,
            userType: game_config.userType.student,
            gameSettings: gameSettings,
            slug: tempOpenedGroupData.code,
            courseStreamId: null,
            isInSession: true
          }
          console.log('My Game Obj')
          console.log(tempJsonObj);
          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList
            }

            console.log(model);
            UtilService.encryptJsonValue(model).then(response => {
              console.log(response)
              if (response && response.data) {
                setGameSettingLink("");
                let tempGameLink = '';
                tempGameLink = gameData.route + response.data.token;
                setTimeout(() => {
                  setGameSettingLink(tempGameLink);
                }, conditional_configs.loader.timeout);
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        }
      })
        .catch(e => {
          console.log(e);
        });

    }
  }

  /** GET COURSE DETAIL */
  const getCourseDetailByLearnerId = () => {
    SelfLearningService.getCourseDetailByLearnerId(getCurrentUserData().id).then(response => {
      console.log('getCourseDetailByLearnerId::', response);
      if (response && response.success == "true") {
        setMyGames(response.data.games);
        setMyBoards(response.data.boards)
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const navigateToCoursesDescription = (BoardName, Data) => {
    // Save the clicked object data to localStorage
    localStorage.setItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails, JSON.stringify(Data))
    navigateTo(navigate_route_config.learner.courses_description + '/' + BoardName);
  }

  const getGameDetail = (id) => {
    if (id)
      return games.gameList.filter(e => e.id == id)[0];
  }

  const getPlayersList = () => {
    const tempOpenedData = JSON.parse(localStorage.getItem('openedGroupData'));
    if (tempOpenedData) {
      var shareId;
      if (tempOpenedData.content_type == conditional_configs.curriculam_types.games) {
        shareId = tempOpenedData.game_id
      }

      if (tempOpenedData.content_type == conditional_configs.curriculam_types.whiteboard) {
        shareId = tempOpenedData.self_learning_board_id
      }
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(tempOpenedData.course_id, getCurrentUserData().id, shareId, tempOpenedData.content_type).then(response => {
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  };

  /** HANDLE OPEN **GAME** TAB SHARE CODE POPUP */
  const handleOpenGameShareCodePopup = (e, obj) => {
    if ((obj.isFree && subscriptionStatus == "trialing") || subscriptionStatus == "active") {
      setEnableChatWindow(false);
      setGameShareCodePopup(true);
      setOpenedContent(obj);
      setPlayers([]);
      if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.singlPlayerWebsite) {
        setGameSettingLink("");
        setOpenGameSettings(true);
        setGameSettingLink(getGameDetail(obj.game_id).route);
        return;
      }
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.game_id, conditional_configs.curriculam_types.games).then(response => {
        console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
          setOpenedGroupData(response.data.groupData);
          localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
          if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.mutiPlayerGame) {
            socketConnections();
          }
          joinToGeneralChat();
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  };

  /** HANDLE CLOSE **GAME** TAB SHARE CODE POPUP */
  const handleGamesShareCodePopupClose = () => {
    setGameShareCodePopup(false);
    setOpenedGroupData(null);
    localStorage.removeItem("openedGroupData");
    setEnableChatWindow(false);
    if (openGameSettings) {
      setOpenGameSettings(false);
      window.location.reload();
    }
  };

  /** HANDLE OPEN **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleOpenBoardShareCodePopup = (e, obj) => {
    if ((obj.isFree && subscriptionStatus == "trialing") || subscriptionStatus == "active") {
      setEnableChatWindow(false);
      setOpenBoardShareCodePopup(true);
      setOpenedContent(obj);
      setPlayers([]);
      localStorage.setItem('openedContent', JSON.stringify(obj));
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.id, conditional_configs.curriculam_types.whiteboard).then(response => {
        console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
          setOpenedGroupData(response.data.groupData);
          localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
          joinToGeneralChat();
          SelfLearningService.getBoardsTemplatesById(response.data.groupData.self_learning_board_id)
            .then((response) => {
              console.log("getBoardsTemplatesById", response);
              if (response.data) {
                localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(response.data.whiteboardTemplates));
              }
            })
            .catch((error) => {
              console.log(error);
            });
          socketConnections();
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** HANDLE CLOSE **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleBoardShareCodePopupClose = () => {
    setOpenBoardShareCodePopup(false);
    setCopyMessage('');
    localStorage.removeItem("openedGroupData");
    setOpenedGroupData(null);
    setEnableChatWindow(false);
    if (openWhiteboardTabScreen) {
      setOpenWhiteboardTabScreen(false);
      window.location.reload();
    }
  };

  /** SHARE CODE */
  const shareCode = (e, type) => {
    e.stopPropagation();
    var createGroupModel = {
      course_id: openedContent.course_id,
      group_created_by: getCurrentUserData().id,
      group_name: "",
      code: "",
      content_type: type,
      game_id: null,
      self_learning_board_id: null,
      status: 1,
      createdby: getCurrentUserData().id,
      updatedby: null
    }
    if (type == conditional_configs.curriculam_types.games) {
      createGroupModel.game_id = openedContent.game_id;
    } else if (type == conditional_configs.curriculam_types.whiteboard) {
      createGroupModel.self_learning_board_id = openedContent.id;
    } else {
      createGroupModel.game_id = null;
      createGroupModel.self_learning_board_id = null;
    }
    console.log(createGroupModel);
    SelfLearningService.shareCode(createGroupModel).then(response => {
      console.log('shareCode::', response);
      setOpenedGroupData(response.data);
      localStorage.setItem('openedGroupData', JSON.stringify(response.data));

      /** IF SHARE CODE FOR WHITEBOARD */
      if (type == conditional_configs.curriculam_types.whiteboard) {
        SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(openedContent.course_id, getCurrentUserData().id, openedContent.id, conditional_configs.curriculam_types.whiteboard).then(resp => {
          console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId-sharecode::', resp);
          if (resp && resp.data && resp.success == "true") {
            setPlayers(resp.data.players);
            setOpenedGroupData(resp.data.groupData);
            localStorage.setItem('openedGroupData', JSON.stringify(resp.data.groupData));
            SelfLearningService.getBoardsTemplatesById(resp.data.groupData.self_learning_board_id)
              .then((res) => {
                console.log("getBoardsTemplatesById-resp", res);
                if (res.data) {
                  localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(res.data.whiteboardTemplates));
                }
                // const actualWhiteboardTemplates = JSON.parse(localStorage.getItem('actualWhiteboardTemplates'));
                for (var i = 0; i < res.data.whiteboardTemplates.length; i++) {
                  setWhiteboardLink("");
                  console.log(res.data.whiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + resp.data.groupData.code.toLowerCase())
                  FirebaseService.cloneDocument("scenes", res.data.whiteboardTemplates[i]?.slug, res.data.whiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + response.data.code.toLowerCase());
                }
                openBoard('');
              })
              .catch((error) => {
                console.log(error);
              });
            socketConnections();
          }
        })
          .catch(e => {
            console.log(e);
          });




      }
      getPlayersList();
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleOpenJoinWithCodePopup = (e) => {
    e.stopPropagation();
    setOpenJoinWithCodePopup(true);
    setGameSettingLink("");
    setEnableChatWindow(false);
  }

  const handleCloseJoinWithCodePopup = (e) => {
    e.stopPropagation();
    setOpenJoinWithCodePopup(false);
    setJoinWithCodeErrorMessage("");
    localStorage.removeItem("openedGroupData");
    setOpenedGroupData(null);
    setEnableChatWindow(false);
    if (openJoinWithCodeGame) {
      setOpenJoinWithCodeGame();
      window.location.reload();
    }
  }

  //#region SHARE CODE FORM AND SCHEMA
  const ShareCodeSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code: '',
  };

  const methods = useForm({
    resolver: yupResolver(ShareCodeSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;
  //#endregion

  /** JOIN WITH CODE SUBMIT EVENT */
  const onJoinWithCodeSubmit = data => {
    console.log('onJoinWithCodeSubmit', data)
    b_room_socket = io(url_configs.breakout_room_server);
    console.log(data);
    const createGroupDetailModel = {
      group_id: null,
      learner_id: getCurrentUserData().id,
      code: data.code,
      isMaster: false,
      status: 1,
      createdby: getCurrentUserData().id,
      updatedby: null
    }

    SelfLearningService.joinWithCode(createGroupDetailModel).then(response => {
      console.log('joinWithCode::', response);

      if (response && response.data && response.success == "true") {
        setShowSuccessImage(true);  // Show success image
        setTimeout(() => {
          setShowSuccessImage(false);  // Hide success image after 2 seconds
        }, 3000);

        localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
        joinToGeneralChat();
        if (response.data.groupData.content_type == conditional_configs.curriculam_types.games) {
          setOpenJoinWithCodeGame(conditional_configs.curriculam_types.games);
          const tempGameData = getGameDetail(response.data.groupData.game_id);
          var tempContent = {
            game_id: response.data.groupData.game_id,
            name: tempGameData.name
          }
          localStorage.setItem('openedContent', JSON.stringify(tempContent));
          getGameLink(tempGameData);
          b_room_socket.emit("on-joined-self-learner", { data: createGroupDetailModel }, (error) => {
            if (error) {
              console.log(error);
            }
          });
        } else if (response.data.groupData.content_type == conditional_configs.curriculam_types.whiteboard) {
          setOpenJoinWithCodeGame(conditional_configs.curriculam_types.whiteboard);
          const groupData = JSON.parse(localStorage.getItem('openedGroupData'));
          SelfLearningService.getBoardsTemplatesById(groupData.self_learning_board_id)
            .then((resp) => {
              console.log("getBoardsTemplatesById", resp);
              if (resp.data) {
                localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(resp.data.whiteboardTemplates));
                if (resp.data.whiteboardTemplates && resp.data.whiteboardTemplates.length > 0) {
                  const openBoardsModel = {
                    group_id: groupData.id,
                    code: groupData.code,
                    createdby: getCurrentUserData().id,
                    self_learning_board_id: groupData.self_learning_board_id
                  }
                  console.log("openBoardsModel", openBoardsModel)
                  SelfLearningService.openBoards(openBoardsModel)
                    .then((res) => {
                      console.log("openBoards", res);
                      if (res && res.success == "true" && res.data) {
                        setWhiteBoardTabList(res.data.whiteboardTemplates)
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setJoinWithCodeErrorMessage(response.data)
        // alert(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });

  }

  /** SHOW RESULTS */
  const onShowResultSubmit = data => {
    console.log('onShowResultSubmit', data)
    if (data) {
      getGameResultBySlugAndUserId(data.code);
    }
  }

  /** GET **GAME** RESULTS BY SHARED CODE */
  const getGameResultBySlugAndUserId = (code) => {
    GameService.getGameResultBySlugAndUserId(code)
      .then((response) => {
        console.log("Gameresults", response);
        setGameResults([]);
        if (response && response.data && response.data.length > 0) {
          setGameResults(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** START GAME BUTTON EVENT */
  const startGame = (e) => {
    //Expert Detail
    let expertDetail = {
      id: getCurrentUserData().id,
      name: getCurrentUserData().name
    }

    //Game Detail
    let gameDetail = {
      id: openedContent.game_id,
      name: getGameDetail(openedContent?.game_id)?.name
    }

    var groupDetail = [];
    //Group Detail
    let groupsObj = {
      id: openedGroupData.id,
      name: openedGroupData.code,
      students: players
    }

    groupDetail.push(groupsObj);

    let tempJsonObj = {
      expertDetail: expertDetail,
      gameDetail: gameDetail,
      groupDetail: groupDetail,
      userType: game_config.userType.teacher,
      slug: openedGroupData.code,
      courseStreamId: null,
      isInSession: true
    }

    if (tempJsonObj) {
      let tempJsonValueList = [];
      tempJsonValueList.push(tempJsonObj);

      let model = {
        jsonvalue: tempJsonValueList
      }

      console.log(model);
      localStorage.setItem('openedGroupData', JSON.stringify(openedGroupData));
      localStorage.setItem('openedContent', JSON.stringify(openedContent));
      UtilService.encryptJsonValue(model).then(response => {
        if (response && response.data) {
          var gameLink = getGameDetail(openedContent?.game_id).route + response.data.token
          setGameSettingLink(gameLink);
          setOpenGameSettings(true);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** OPEN BOARD BUTTON EVENT */
  const openBoard = (e) => {
    setOpenWhiteboardTabScreen(true);
    setWhiteboardLink("");
    const tempData = JSON.parse(localStorage.getItem('openedGroupData'));
    const actualWhiteboardTemplates = JSON.parse(localStorage.getItem('actualWhiteboardTemplates'));
    if (actualWhiteboardTemplates && actualWhiteboardTemplates.length > 0) {
      // for (var i = 0; i < actualWhiteboardTemplates.length; i++) {
      //   console.log(actualWhiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + tempData.code.toLowerCase())
      //   FirebaseService.cloneDocument("scenes", actualWhiteboardTemplates[i]?.slug, actualWhiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + tempData.code.toLowerCase());
      // }
      const openBoardsModel = {
        group_id: tempData.id,
        code: tempData.code,
        createdby: getCurrentUserData().id,
        self_learning_board_id: tempData.self_learning_board_id
      }
      console.log("openBoardsModel", openBoardsModel)
      SelfLearningService.openBoards(openBoardsModel)
        .then((response) => {
          console.log("openBoards", response);
          if (response && response.success == "true" && response.data) {
            setWhiteBoardTabList(response.data.whiteboardTemplates)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const openWhiteBoard = (e, obj, index) => {
    e.stopPropagation();
    setWhiteboardLink("");
    let data = [...whiteBoardTabList];
    data.forEach((element) => {
      element.active = false;
    });
    setWhiteboardKey(obj.id);
    var generateBoardLink;
    if (openedContent) {
      generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "&self_learning_board_id=" + openedContent.id + "#room=" + obj.board_slug.toLowerCase() + ",-4-Gf1BCSf5ns_M4ChSamw";
    } else {
      const groupData = JSON.parse(localStorage.getItem('openedGroupData'));
      generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "&self_learning_board_id=" + groupData.self_learning_board_id + "#room=" + obj.board_slug.toLowerCase() + ",-4-Gf1BCSf5ns_M4ChSamw";
    }
    setTimeout(() => {
      setWhiteboardLink(generateBoardLink);
    }, 500);
    data[index].active = true;
    setWhiteBoardTabList(data);
  };

  //#region CHAT
  const openChatWindow = (e) => {
    e.stopPropagation();
    setEnableChatWindow(true);
    // chat_server_socket = io(url_configs.chat_server);
    // joinToGeneralChat();
  }

  const closeChat = (e) => {
    e.stopPropagation();
    setEnableChatWindow(false);
  }

  const renderChatWindow = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse();
    }

    return (
      <div className="group-chats-main-div-cgv4">
        {msgs?.length === 0 ? (
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_icon}
              ></img>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_found_text}
              ></img>
            </div>
          </div>
        ) : (msgs?.map((val, i) => (
          <div key={i} style={{ marginBottom: '10px' }}>
            {val.userId === getCurrentUserData().id ? (
              <div>
                <div className="chat-my-msg-container-ind-chat-cgv4">
                  <div className="rvr-msg-container-ind-chat-cgv4">
                    <p className="my-name-ind-chat-cgv4 mb-0">Me</p>
                    <span className="rvr-name-ind-chat-cgv4">
                      {findTypeOfMessage(val.text) !== true ? (
                        val.text
                      ) : (
                        <img src={val.text} />
                      )}</span>
                  </div>
                </div>
              </div>
            ) : (

              <div className="rvr-container-ind-chat">
                <div className="rvr-avatar-container-ind-chat">{getInitialsFromFullName(val.user)}</div>
                <div className="w-100 rvr-msg-container-div-ind-chat">

                  <div className="chat-rvr-msg-container-ind-chat-cgv4">
                    <div className="rvr-msg-container-ind-chat-cgv4">
                      <p className="chat-rvr-name-ind-chat-cgv4 mb-0"> {val.user}&nbsp;
                        {val?.userType && val?.userType == "expert"
                          ? "(Expert)"
                          : val?.userType && val?.userType == "admin"
                            ? "(Admin)"
                            : ""}</p>
                      <span className="chat-rvr-msg-ind-chat-cgv4"> {findTypeOfMessage(val.text) !== true ? (
                        val.text
                      ) : (
                        <img src={val.text} />
                      )}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
        ))}
      </div>
    );
  };

  const renderIndividualChatWindow = () => {
    let msgs = [...messages];
    if (msgs) {
      msgs = msgs.reverse();
    }

    return (
      <div className="individual-chats-main-div-cgv4">
        {msgs.length === 0 ? (
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_icon}
              ></img>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_found_text}
              ></img>
            </div>
          </div>
        ) : (msgs.map((msg, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            {msg.sender_id === getCurrentUserData().id ? (
              // <div style={{ textAlign: 'right', color: 'blue' }}>
              //   <strong>You:</strong> {msg.message}
              // </div>
              <div>
                <div className="chat-my-msg-container-ind-chat-cgv4">
                  <div className="rvr-msg-container-ind-chat-cgv4">
                    <p className="my-name-ind-chat-cgv4 mb-0">You</p>
                    <span className="rvr-name-ind-chat-cgv4">{checkMessageString(msg.message) ? <img width={"100px"} src={msg.message}></img> : msg.message}</span>
                  </div>
                </div>
              </div>
            ) : (
              // <div style={{ textAlign: 'left', color: 'green' }}>
              //   <strong>Friend:</strong> {msg.message}
              // </div>
              <div className="rvr-container-ind-chat">
                <div className="rvr-avatar-container-ind-chat">{getInitialsFromFullName(msg.sender_name)}</div>
                <div className="w-100 rvr-msg-container-div-ind-chat">

                  <div className="chat-rvr-msg-container-ind-chat-cgv4">
                    <div className="rvr-msg-container-ind-chat-cgv4">
                      <p className="chat-rvr-name-ind-chat-cgv4 mb-0">{msg.sender_name}</p>
                      <span className="chat-rvr-msg-ind-chat-cgv4">{checkMessageString(msg.message) ? <img width={"100px"} src={msg.message}></img> : msg.message}</span>
                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>
        )
        ))}
      </div>
    )
  }

  const getRemainingMessageCount = (e) => {
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    let remainingMessages = 0;

    if (subscriptionStatus === "trialing") {
      // Set remaining messages for trialing plans (e.g., 50 messages for trial pack)
      remainingMessages = currentPlan?.trialing_message_count - totalMessages;
    } else {
      // Set remaining messages for active plans
      remainingMessages = currentPlan?.active_message_count - totalMessages;
    }

    setRemainingMessageCount(remainingMessages); // Set the remaining messages count
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // get remaining messages
    getRemainingMessageCount();


    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (filter.isProfane(message)) {
      alert('Message not sent. Please avoid using profane language.');
      return;
    }

    if (checkForPlacesInfo(message)) {
      alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
      return;
    }
    if (isAddressIncluded(message)) {
      alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
      return;
    }

    const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: tempOpenedGroupData.code,
      slug: tempOpenedGroupData.code,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType:
        getCurrentUserData().role == conditional_configs.role.learner
          ? "learner"
          : getCurrentUserData().role == conditional_configs.role.expert
            ? "expert"
            : "admin",
    };
    if (message) {
      chat_server_socket.emit("sendMessage", {
        message: message,
        group: tempOpenedGroupData.code,
        slug: tempOpenedGroupData.code,
        userId: getCurrentUserData().id,
        userType: chatModel.userType,
      });
      chatModel.group = tempOpenedGroupData.code;
      setMessage("");
      //add group chart for session_chats table

      SessionService.addChats(chatModel)
        .then((response) => { })
        .catch((error) => {
          console.log(error);
        });
      // get message count
      fetchMessageCounts();

      // addChats(chatModel);
    } else alert("empty input");

  }

  const joinToGeneralChat = () => {
    const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));

    let room = tempOpenedGroupData.code;
    //setChatRoom(room);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit("join", { name: getCurrentUserData().name, room: room }, (error) => {
      if (error) {
        alert(error);
      }
    });

    chat_server_socket.on("message", (message) => {
      if (message.slug == room) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message)
        setMessages(generalChats);
        storeChats(generalChats);

        if (message && message.user != getCurrentUserData().name) {
          enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

          let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
        }
      }
    });

  }

  //#endregion

  //#region MY FRIENDS LIST

  const handleOpenInviteFriendPopup = (e) => {
    setInviteFriendPopup(true);
  }

  const handleCloseInviteFriendPopup = (e) => {
    setInviteFriendPopup(false);
  }

  const getChatFriends = () => {
    SelfLearningService.getChatFriends().then(response => {
      console.log('getChatFriends::', response);
      if (response && response.success == "true") {
        setMyChatFriendsList(response.data);
      }
      getMyInvites();
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** MY INTRACTED FRIENDS LIST */
  const getIntractedFriends = () => {
    SelfLearningService.getIntractedFriends().then(response => {
      console.log('getIntractedFriends::', response);
      if (response && response.success == "true") {
        setMyIntractedFriendsList(response.data);
      }
      //getMyInvites();
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getMyPrivateMessageByRecipientId = (recipient_id) => {
    SelfLearningService.getMyPrivateMessageByRecipientId(recipient_id).then(response => {
      console.log('getMyPrivateMessageByRecipientId::', response);
      if (response && response.success == "true") {
        setMessages(response.data);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  // get current user private messages by id

  const getCurrentUserPrivateMessageById = () => {
    SelfLearningService.getCurrentUserPrivateMessageById(getCurrentUserData().id).then(response => {
      console.log('getCurrentUserPrivateMessageById::', response);
      if (response && response.success == "true") {
        setPrivateMessageCount(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  // get current user group messages by id

  const getCurrentUserGroupMessageById = () => {
    SelfLearningService.getCurrentUserGroupMessageById(getCurrentUserData().id).then(response => {
      console.log('getCurrentUserGroupMessageById::', response);
      if (response && response.success == "true") {
        setGroupMessageCount(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleMyFriendsCtrlMenuOpen = (event) => {
    setMyFriendsCtrlMenu(true);
    setMyFriendsAnchorEl(event.currentTarget);
    setOpenIndividualChat(null);
    var rootContainer = document.getElementById('root');
    rootContainer.style.overflow = 'hidden'; // Show the modal container
    getChatFriends();
  };

  const handleMyFriendsCtrlMenuClose = () => {
    setMyFriendsCtrlMenu(false);
    setOpenIndividualChat(null);
    var rootContainer = document.getElementById('root');
    rootContainer.style.overflow = 'auto'; // Show the modal container
    //
    getChatFriends();
  };

  const backToMyFriendsList = (e) => {
    setMyFriendsCtrlMenu(true);
    setOpenIndividualChat(null);
    //
    getChatFriends();
  }

  const chatToFriend = (e, obj) => {
    e.stopPropagation();
    setOpenIndividualChat(obj);
    getMyPrivateMessageByRecipientId(obj.learner.id);
    getRemainingMessageCount();
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const textClips = ["Hi", "Good Morning", "Hello", "Yes", "No", "Thank you", "Goodbye"];


  const sendTextClip = (clip) => {

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    // get remaining messages
    getRemainingMessageCount();


    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (clip.trim() !== '') {
      const insertIndividualMsgModel = {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: clip,
        createdby: getCurrentUserData().id
      };
      sendPrivateMessage(insertIndividualMsgModel);
      friends_server_socket.emit('send_private_message', insertIndividualMsgModel);
      setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
    }
    // get message count
    fetchMessageCounts();
  };

  const sendTextClipForGroup = (clip) => {

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    // get remaining messages
    getRemainingMessageCount();

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (clip.trim() !== '') {
      const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
      let chatModel = {
        group: null,
        isInSession: false,
        courseStreamId: null,
        room: tempOpenedGroupData.code,
        slug: tempOpenedGroupData.code,
        text: message,
        user: getCurrentUserData().name,
        userId: getCurrentUserData().id,
        userType:
          getCurrentUserData().role == conditional_configs.role.learner
            ? "learner"
            : getCurrentUserData().role == conditional_configs.role.expert
              ? "expert"
              : "admin",
      };
      if (clip) {
        chat_server_socket.emit("sendMessage", {
          message: clip,
          group: tempOpenedGroupData.code,
          slug: tempOpenedGroupData.code,
          userId: getCurrentUserData().id,
          userType: chatModel.userType,
        });
        chatModel.group = tempOpenedGroupData.code;
        setMessage("");

        //add group chart for session_chats table

        SessionService.addChats(chatModel)
          .then((response) => { })
          .catch((error) => {
            console.log(error);
          });

        // get message count
        fetchMessageCounts();

        // addChats(chatModel);
      } else alert("empty input");
    }
  };


  const sendMessage = (e) => {
    e.preventDefault();

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    // get remaining messages
    getRemainingMessageCount();

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (message.trim() !== '') {
      if (filter.isProfane(message)) {
        alert('Message not sent. Please avoid using profane language.');
        return;
      }

      if (checkForPlacesInfo(message)) {
        alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
        return;
      }

      if (isAddressIncluded(message)) {
        alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
        return;
      }

      // Send private message to the friend
      friends_server_socket.emit('send_private_message', {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: message,
        createdby: getCurrentUserData().id
      });

      var insertIndividualMsgModel = {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: message,
        createdby: getCurrentUserData().id
      }
      sendPrivateMessage(insertIndividualMsgModel);
      // Add your own message to the UI
      setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
      setMessage('');
      // get message count
      fetchMessageCounts();
    }
  };

  const checkMessageString = (msg) => {
    if (msg) {
      const mimeType = msg.match(/data:([^;]+);base64,/);

      if (mimeType && mimeType[1]) {
        return true;
      } else {
        return false
      }
    }
    return false
  }

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setMessage('');
    if (file && file.size <= 2 * 1024 * 1024) { // Check if file size is <= 2MB
      // setLoading(true);
      const reader = new FileReader();

      reader.onload = async () => {
        const base64Image = new Image();
        base64Image.src = reader.result;

        base64Image.onload = async () => {
          const model = await nsfwjs.load();
          const predictions = await model.classify(base64Image);

          // Convert probabilities to percentages
          const predictionsWithPercentages = predictions.map(prediction => ({
            className: prediction.className,
            probability: (prediction.probability * 100).toFixed(2) // Convert to percentage and format to 2 decimal places
          }));
          e.target.value = null;
          const validateImage = predictionsWithPercentages.filter(e => (e.className == "Porn" || e.className == "Sexy" || e.className == "Hentai") && Number(e.probability) >= 10);
          if (validateImage.length > 0) {
            return alert('This content cannot be shared.');
          }
          setMessage(base64Image.src);
          // Prevent default behavior (new line) and submit the form
          // Send private message to the friend
          friends_server_socket.emit('send_private_message', {
            sender_id: getCurrentUserData().id,
            sender_name: getCurrentUserData().name,
            recipient_id: openIndividualChat.learner.id,
            recipient_name: openIndividualChat.learner.firstname,
            message: base64Image.src,
            createdby: getCurrentUserData().id
          });

          var insertIndividualMsgModel = {
            sender_id: getCurrentUserData().id,
            sender_name: getCurrentUserData().name,
            recipient_id: openIndividualChat.learner.id,
            recipient_name: openIndividualChat.learner.firstname,
            message: base64Image.src,
            createdby: getCurrentUserData().id
          }
          sendPrivateMessage(insertIndividualMsgModel);
          // Add your own message to the UI
          setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
          setMessage('');
          // setLoading(false);
        };
      };

      reader.readAsDataURL(file);
    } else {
      alert('File size exceeds 2MB limit.');
    }
  };

  const sendPrivateMessage = (data) => {
    SelfLearningService.sendPrivateMessage(data)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Prevent default behavior (new line) and submit the form
      e.preventDefault();
      sendMessage(e);
    }
  };

  const handleKeyPressForGroup = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Prevent default behavior (new line) and submit the form
      e.preventDefault();
      handleChatSubmit(e);
    }
  };

  const handleInviteFriendUserNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
  };

  // ON SUBMIT INVITE FRIEND
  const onSubmitInviteFriend = (e) => {
    e.preventDefault();
    var inviteFriendModel = {
      invited_by: getCurrentUserData().id,
      invite_user_type: "learner",
      email: null,
      username: userName,
      friend_id: null,
      status: "1",
      createdby: getCurrentUserData().id,
      updatedby: null
    };
    if (validateEmail(inviteFriendModel.username)) {
      inviteFriendModel.email = inviteFriendModel.username;
      inviteFriendModel.username = null;
      inviteFriendModel.invite_user_type = "parent";
    }

    console.log(inviteFriendModel);
    SelfLearningService.inviteFriendToChat(inviteFriendModel).then(response => {
      console.log('inviteFriendToChat::', response);
      if (response && response.success == "true") {
        // setInviteFriendPopup(false);
        handleMyFriendsCtrlMenuClose();
        setUserName(''); // Clear the form
        enqueueSnackbar("Invited successfully!", { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else if (response && response.success == "false") {
        enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        return;
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const inviteInteractedFriend = (e, obj) => {
    console.log(obj)
    e.stopPropagation();
    const inviteFriendModel = {
      invited_by: getCurrentUserData().id,
      invite_user_type: "learner",
      email: null,
      username: obj.username,
      friend_id: null,
      status: "1",
      createdby: getCurrentUserData().id,
      updatedby: null
    };
    console.log(inviteFriendModel);
    SelfLearningService.inviteFriendToChat(inviteFriendModel).then(response => {
      console.log('inviteFriendToChat::', response);
      if (response && response.success == "true") {
        setInviteFriendPopup(false);
        enqueueSnackbar("Invited successfully!", { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        getChatFriends();
        getIntractedFriends();
      } else if (response && response.success == "false") {
        enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        return;
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getMyInvites = () => {
    SelfLearningService.getMyInvites().then(response => {
      if (response && response.success == "true") {
        console.log('getMyInvites::', response);
        setMyChatInviteList(response.data);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [learnerToRemove, setLearnerToRemove] = useState(null);
  const [showRejectConfirmModal, setShowRejectConfirmModal] = useState(false);
  const [showAcceptConfirmModal, setShowAcceptConfirmModal] = useState(false);
  const [inviteToFriendDetail, setInviteToFriendDetail] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');

  const copyShareCodeLink = () => {
    const copyingData = url_configs.frontend + "self-learning-parent-registration?ref_code=" + openedGroupData.code
    copyToClipBoardWithMsgWithOutAlert(copyingData)
    setCopyMessage("Please share the code with people you know.");
    // Clear the message after 3 seconds
    setTimeout(() => {
      setCopyMessage("");
    }, 3000); // 3000 milliseconds = 3 seconds
  }

  // remove Learner From Group

  const removeLearnerFromGroup = (learner_id) => {
    const removeData = {
      learner_id: learner_id,
      group_id: openedGroupData.id
    }
    const tempPlayers = [...players];
    const filteredPlayers = tempPlayers.filter(e => e.id !== learner_id);
    console.log(removeData);
    SelfLearningService.removeLearnerFromGroup(removeData).then(response => {
      console.log('getCourseDetailByLearnerId::', response);
      if (response && response.success == "true") {
        setPlayers([]);
        setPlayers(filteredPlayers);
        enqueueSnackbar('Player removed successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleRemovePlayerClick = (e, learner_id) => {
    e.stopPropagation();
    setLearnerToRemove(learner_id);
    setShowConfirmModal(true);
  };

  const handleConfirmPlayerRemove = () => {
    removeLearnerFromGroup(learnerToRemove);
    setShowConfirmModal(false);
    setLearnerToRemove(null);
  };

  const handleCancelPlayerRemove = () => {
    setShowConfirmModal(false);
    setLearnerToRemove(null);
  };

  // remove Learner From Group end

  // accept Friend Invite
  const acceptChatInvite = (e, obj) => {
    if (obj) {
      setInviteToFriendDetail(obj);
      setShowAcceptConfirmModal(true);
    }
  }

  const handleConfirmAcceptFriendInvite = () => {
    if (inviteToFriendDetail) {
      SelfLearningService.acceptChatInvite(inviteToFriendDetail.inviteDetail)
        .then(response => {
          getChatFriends();
        })
        .catch(error => {
          console.log(error)
        });
    }
    setShowAcceptConfirmModal(false);
  };

  const handleCancelAcceptFriendInvite = () => {
    setShowAcceptConfirmModal(false);
  };

  // accept Friend Invite end


  // Reject Friend Invite

  const rejectChatInvite = (e, obj) => {
    if (obj) {
      setInviteToFriendDetail(obj);
      setShowRejectConfirmModal(true);
    }
  };

  const handleConfirmRejectFriendInvite = () => {
    if (inviteToFriendDetail) {
      var inviteDetailModel = inviteToFriendDetail.inviteDetail;
      inviteDetailModel.updatedby = getCurrentUserData().id;
      SelfLearningService.rejectChatInvite(inviteDetailModel)
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    }
    setShowRejectConfirmModal(false);
  };

  const handleCancelRejectFriendInvite = () => {
    setShowRejectConfirmModal(false);
  };

  // Reject Friend Invite end

  const getMySubscriptionDetail = () => {
    PaymentService.getMySubscriptionDetailByKid()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data && response.data.length > 0) {
          setSubscriptionStatus(response.data[0].subscription.status);
          setSubscriptionPlanId(response.data[0].product.id)
        }

        let message_count = 0;
        // Loop through the subscription plans
        for (let i = 0; i < url_configs.plans.length; i++) {
          const checkProducts = response.data.filter(
            (e) =>
              e.product.id === url_configs.plans[i].id &&
              (e.subscription.status === "active" || e.subscription.status === "trialing")
          );

          if (checkProducts && checkProducts.length > 0) {
            // Check if the subscription is 'active' or 'trialing' and apply the corresponding message count
            if (checkProducts[0].subscription.status === "active") {
              message_count += url_configs.plans[i].active_message_count;
            } else if (checkProducts[0].subscription.status === "trialing") {
              message_count += url_configs.plans[i].trialing_message_count;
            }
          }
        }

        setplanMessagecount(message_count); // Set the calculated total message count
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const checkSubscriptionDetail = () => {
    if (subscriptionStatus == "active" || subscriptionStatus == "trialing") {
      return true;
    } else {
      return false;
    }
  }

  const navigateTo = (link) => {
    navigate(link);
  }

  const getBoardStyles = (boardName) => {
    switch (boardName) {
      case 'Amazon Delivery System':
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
      case 'SuDoKu':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'Maze':
        return { backgroundColor: "CC333333", boxShadow: "0px 0px 90px 0px #CC3333CC inset" };
      case 'Soccer':
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
      case 'Moon Mission':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'Restaurant':
        return { backgroundColor: "CC333333", boxShadow: "0px 0px 90px 0px #CC3333CC inset" };
      default:
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
    }
  }


  const [isMaximized, setIsMaximized] = useState(false); // State to manage maximized/minimized state

  const toggleWhiteboardSize = () => {
    setIsMaximized(prevState => !prevState); // Toggle the state
  };

  const handlePaste = (event) => {

    console.log("past")
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted content
    const pastedText = event.clipboardData.getData('text');
    if (checkIsEmdedCode(pastedText) == true) {
      console.log("past1")
      setValue("code", pastedText, {
        shouldValidate: true,
        shouldDirty: true
      });
    } else {
      var splitLink = pastedText.split("?")[1];
      if (splitLink) {
        console.log("past2")
        var ref_code = splitLink.split("ref_code=")[1];
        if (ref_code) {
          setValue("code", ref_code, {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      }
    }
  };

  // Function to clear the error message
  const handleCloseError = () => {
    setJoinWithCodeErrorMessage("");
  };

  return (
    <div>
      <img
        className="" width={'100%'}
        src={assets.course_v4.lets_play_learn_banner}

      ></img>
      <Container className="self-learning-main-container pt-3">
        <div className="d-flex justify-content-end align-items-center">
          {checkSubscriptionDetail() == true ? <Button className="join-with-code-btn-cwv4 mb-2 me-2" onClick={(e) => handleMyFriendsCtrlMenuOpen(e)} >My Friends
            {myChatInviteList.length > 0 ? <span className="my-friends-count-badge">{myChatInviteList.length}</span> : ""}
          </Button> : ""}
          {checkSubscriptionDetail() == true ? <Button className="join-with-code-btn-cwv4 mb-2" onClick={(e) => handleOpenJoinWithCodePopup(e)}>Join with code</Button> : ""}
        </div>
        <p className="challenges-text-title-cgv4 mt-2">CHALLENGES</p>
        <Box sx={{ width: '100%' }}>
          {/* WHITEBOARDS */}
          <div>
            {myBoards.length > 0 ? <div className="row">
              {
                myBoards.sort((a, b) => a.sequence - b.sequence).map((obj, index) => {
                  const boardStyles = getBoardStyles(obj?.name);
                  return (
                    <div key={`self-learning-whiteboards-` + obj.id} className={`col-12 col-lg-3 col-md-4 col-sm-12 pb-5`}>
                      {obj.coming_soon ? (

                        // Coming Soon Card

                        <Card className={`cursor-pointer missions_game_card_cv4 ${obj.isFree && subscriptionStatus == "trialing" ? '' : subscriptionStatus == "active" ? '' : 'dim-opacity'}`} onClick={() => navigateToCoursesDescription(obj?.name, obj)}
                          style={{ backgroundColor: "rgb(90 104 96 / 36%);", boxShadow: "#111112cf 0px 0px 90px 0px inset" }} >
                          <div className="missions_game_card_img_cv4">

                            <img
                              className="game_card_img_cv4" width={'100%'}
                              src={obj?.image || assets.whiteboard}
                              alt={obj?.name}
                            ></img>
                          </div>

                          <div className="missions_game_card_content">
                            <p className="missions_game_card_title">{obj?.name}</p>

                            {subscriptionStatus == "trialing" ?
                              <div className="d-flex align-items-center justify-content-center">
                                <button className="comimg_soon_trialing_st_btn">
                                  <p className="mb-0 me-2 btn-text-hv4">Coming soon</p>
                                  <img
                                    className=""
                                    width={40}
                                    src={assets.homev4.white_with_blue_arrow}
                                  ></img>
                                </button>
                              </div> : ''}
                          </div>
                          {subscriptionStatus == "active" ? <div className="d-flex align-items-center justify-content-center comimg_soon_active_st_btn">
                            <p className="mb-0 btn-text-hv4">Coming soon</p>
                          </div> : ''}
                        </Card>
                      ) : (

                        // Regular Game Card

                        <Card className={`cursor-pointer missions_game_card_cv4 ${obj.isFree && subscriptionStatus == "trialing" ? '' : subscriptionStatus == "active" ? '' : 'dim-opacity'}`} style={boardStyles}
                          onClick={(e) => {
                            if (obj.isFree && subscriptionStatus === "trialing") {
                              handleOpenBoardShareCodePopup(e, obj); // Click event for free trial
                            } else if (!obj.isFree && subscriptionStatus === "trialing") {
                              navigateToCoursesDescription(obj?.name, obj)
                            } else {
                              handleOpenBoardShareCodePopup(e, obj); // Click event for active subscription or other cases
                            }
                          }} >
                          <div className="missions_game_card_img_cv4">

                            <img
                              className="game_card_img_cv4" width={'100%'}
                              src={obj?.image || assets.whiteboard}
                              alt={obj?.name}
                            ></img>
                          </div>

                          <div className="missions_game_card_content">
                            <p className="missions_game_card_title">{obj?.name}</p>

                            {
                              obj.isFree && subscriptionStatus == "trialing" ?
                                <div className="d-flex align-items-center justify-content-center">
                                  <button className="start-for-free-cv4">
                                    <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                      className="" width={40}
                                      src={assets.homev4.white_with_blue_arrow}
                                    ></img>
                                  </button>
                                </div> : subscriptionStatus == "active" ? ''
                                  :
                                  <div className="d-flex align-items-center justify-content-center">
                                    <button className="start-for-free-cv4">
                                      <p className="mb-0 me-2 btn-text-hv4">Purchase</p>  <IconButton aria-label="locked">
                                        <LockTwoToneIcon />
                                      </IconButton>
                                    </button>
                                  </div>
                            }

                          </div>
                        </Card>
                      )}
                    </div>
                  )
                })

              }
            </div> :
              <div className="row">
                <p>No records found. Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
              </div>}
            {subscriptionStatus == "canceled" ? <div className="row">
              <p>Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
            </div> : ""}
          </div>
        </Box>

        {/* FRIENDS LIST CHAT */}
        {myFriendsCtrlMenu ?
          <div>
            {!openIndividualChat ?
              <div className="my-friends-list-main-div">
                <div className="my-friends-list-inner-div-cgv4">
                  <div>
                    <div className="d-flex align-items-center pt-2 pb-3">
                      <div className="my-friends-invite-title-cvg4">INVITE & CHAT</div>

                      <span className="close_icon_cgv4_1" onClick={handleMyFriendsCtrlMenuClose}>
                        <img
                          className="" width={'30px'}
                          src={assets.course_v4.close_icon}
                        ></img>
                      </span>

                    </div>

                    <div className="content-cgv4 mb-2" onSubmit={onSubmitInviteFriend}>
                      <form className="subscription-cgv4" >
                        <input
                          id={'invite-friend-id'}
                          required
                          autoComplete="off"
                          className="add-email-cgv4"
                          type="text"
                          placeholder="Type Username/Email"
                          name="invite-friend-name"
                          onChange={handleInviteFriendUserNameChange}
                        />
                        <button
                          className="submit-email-cgv4"
                          type="submit"
                        >
                          <span className="before-submit-cgv4">Send invite</span>
                        </button>
                      </form>
                    </div>

                    {/* <div className="border-bottom-style"></div> */}
                    {/* <div className="d-flex align-items-center justify-content-between pb-2">
                    <div className="my-friends-title">My Friends</div>
                    <Button variant="contained" onClick={handleOpenInviteFriendPopup}>Invite Friend</Button>
                  </div> */}

                    {/* MY FRIENDS LIST */}
                    {myChatFriendsList?.sort((a, b) => {
                      // Sort based on lastChatTime in descending order
                      const dateA = new Date(a.lastChatTime || 0);  // If no lastChatTime, default to 0
                      const dateB = new Date(b.lastChatTime || 0);
                      return dateB - dateA;  // Most recent first (descending)
                    }).map((obj, Index) => {
                      return (
                        <div>
                          <div className="d-flex align-items-center my-friends-list-ul" key={`my-friends-key-` + Index} onClick={(e) => chatToFriend(e, obj)}>
                            <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.learner?.firstname)}</div>
                            <div className="my-friends-name">
                              {obj?.learner?.firstname}
                            </div>

                            {obj?.unreadMessageCount ? <div className="ms-auto unread_message_div">
                              <p className="unread_message_count_text mb-0">{obj?.unreadMessageCount}</p>
                            </div> : ""}

                          </div>
                          <div className="border-bottom-style"></div>
                        </div>

                      );
                    })}

                    {myChatFriendsList.length == 0 && myChatInviteList.length == 0 ? <div className="my-friends-no-records-found">No records found!</div> : ""}

                    {/* INVITED USERS LIST */}
                    {myChatInviteList?.map((obj, Index) => {
                      return (
                        <div className="d-flex align-items-center my-friends-list-ul justify-content-between pt-3" key={`my-invited-friends-key-` + Index}>
                          <div className="d-flex align-items-center">
                            <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.invitedUser?.firstname)}</div>
                            <div className="my-friends-name">
                              {obj?.invitedUser?.firstname}
                            </div>
                          </div>
                          <div>
                            <button className="accept-btn-cgv4 me-1" onClick={(e) => acceptChatInvite(e, obj)} >Accept</button>
                            <button className="reject-btn-cgv4" onClick={(e) => rejectChatInvite(e, obj)}>Reject</button>
                          </div>
                        </div>
                      );
                    })}

                    {/* show Accept Confirm Modal */}

                    {showAcceptConfirmModal && (
                      <div className="modal-overlay-cgv4">
                        <div className="modal-content-cgv4">
                          <div className="d-flex">
                            <p className="Join-with-code-text-cgv4 mt-1 mb-0">FRIEND INVITE</p>
                            <span className="cursor-pointer ms-auto" onClick={handleCancelAcceptFriendInvite}>
                              <img
                                className="" width={'30px'}
                                src={assets.course_v4.close_icon}
                              ></img>
                            </span>
                          </div>

                          <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to accept friend invite?</p>


                          <div className="d-flex justify-content-center align-items-center pb-3">
                            <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelAcceptFriendInvite}>
                              <p className="mb-0">No</p>
                            </button>
                            <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmAcceptFriendInvite}>
                              <p className="mb-0 me-2">Yes</p> <img
                                className="" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* show Reject Confirm Modal */}

                    {showRejectConfirmModal && (
                      <div className="modal-overlay-cgv4">
                        <div className="modal-content-cgv4">
                          <div className="d-flex">
                            <p className="Join-with-code-text-cgv4 mt-1 mb-0">FRIEND INVITE</p>
                            <span className="cursor-pointer ms-auto" onClick={handleCancelRejectFriendInvite}>
                              <img
                                className="" width={'30px'}
                                src={assets.course_v4.close_icon}
                              ></img>
                            </span>
                          </div>

                          <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to reject friend invite?</p>


                          <div className="d-flex justify-content-center align-items-center pb-3">
                            <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelRejectFriendInvite}>
                              <p className="mb-0">No</p>
                            </button>
                            <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmRejectFriendInvite}>
                              <p className="mb-0 me-2">Yes</p> <img
                                className="" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="my-friends-title">My Interacted Friends</div>
                </div> */}

                    {/* MY INTRACTED FRIENDS LIST */}
                    {/* {myIntractedFriendsList?.map((obj, Index) => {
                  return (
                    <div className="d-flex align-items-center my-friends-list-ul justify-content-between" key={`my-intracted-friends-key-` + Index}>
                      <div className="d-flex align-items-center">
                        <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.learner?.firstname)}</div>
                        <div className="my-friends-name">
                          {obj?.learner?.firstname}
                        </div>
                      </div>
                      <div>

                       {obj.inviteStatus == null? 
                       <Button variant="contained" className="me-1" onClick={(e) => inviteInteractedFriend(e, obj)}>Invite</Button>
                       :
                       obj.inviteStatus == "invited" ?
                       <Button variant="contained" className="me-1">Invited</Button>
                       :""
                  }
                      </div>
                    </div>
                  );
                })} */}

                    {/* {myIntractedFriendsList.length == 0 ? <div className="my-friends-no-records-found">No records found!</div> : ""} */}


                  </div>
                </div>
              </div>
              :
              <div className="my-friends-list-main-div-message">
                <div className="my-friends-list-inner-div-message-cgv4">
                  {/* <div className="background-color-blue-cgv4"></div> */}
                  <div className="h-100 p-2">
                    <div className="d-flex align-items-center">
                      <div className="my-friends-title ms-2">{openIndividualChat.learner.firstname}</div>
                      <span className="close_icon_cgv4" onClick={handleMyFriendsCtrlMenuClose} >
                        <img
                          className="" width={'30px'}
                          src={assets.course_v4.close_icon}
                        ></img>
                      </span>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <IconButton aria-label="Back" type="button" onClick={(e) => backToMyFriendsList(e)} >
                        <img
                          className="" width={''}
                          src={assets.course_v4.left_arrow}
                        ></img>
                      </IconButton>
                      <div className="my-friends-invite-title-cvg4">CHAT</div>
                    </div>
                    <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                    <div className="h-100">
                      <div className="h-100">
                        {renderIndividualChatWindow()}
                        <div className="my-friends-chat-text-box-main-div">
                          <div className="text-clips-container">
                            {textClips.map((clip, index) => (
                              <button key={index} className="text-clip-button" onClick={() => sendTextClip(clip)}>
                                {clip}
                              </button>
                            ))}
                          </div>
                          <form className="w-100 d-flex align-items-center" onSubmit={(e) => sendMessage(e)}>
                            {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                              <label for="image-file-input-1-1">
                                <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                              </label>
                              <input
                                id="image-file-input-1-1"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                              />
                            </div> */}
                            <textarea
                              id="ind-chat-input-id"
                              type="text"
                              rows={"1"}
                              value={message}
                              onChange={handleMessageChange}
                              onKeyPress={handleKeyPress}
                              placeholder="Type here..."
                              className="ind-chat-input-cgv4"
                              disabled={remainingMessageCount === 0}
                            />
                            <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                              <IconButton type="submit" disabled={remainingMessageCount === 0}>
                                <img
                                  className="" width={'40px'}
                                  src={assets.course_v4.send_arrow}
                                ></img>
                              </IconButton>
                            </div>

                          </form>
                          {/* Display message */}

                          <p className="mb-0 message-limited-text-cgv4">
                            {remainingMessageCount > 0
                              ? `You have ${remainingMessageCount} messages left.`
                              : "You have reached your message limit."}
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            }
          </div>
          : ""}

        {/* JOIN WITH CODE */}
        <Dialog
          open={openJoinWithCodePopup}
          onClose={handleCloseJoinWithCodePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={openJoinWithCodeGame == conditional_configs.curriculam_types.games ? true : openJoinWithCodeGame == conditional_configs.curriculam_types.whiteboard ? true : false}
          fullWidth={openJoinWithCodeGame !== conditional_configs.curriculam_types.games ? true : openJoinWithCodeGame !== conditional_configs.curriculam_types.whiteboard ? true : false}
        >
          <DialogTitle id="alert-dialog-title" >
            <p className="Join-with-code-text-cgv4 mt-3 mb-0">JOIN WITH CODE</p>
            <span className="close_icon_cgv4" onClick={handleCloseJoinWithCodePopup}>
              <img
                className="" width={'30px'}
                src={assets.course_v4.close_icon}
              ></img>
            </span>
          </DialogTitle>
          <DialogContent>
            {/* show Success Image */}
            {showSuccessImage && (
              <div className="success-image-overlay">
                <img width={'100%'} src={assets.course_v4.code_entered_successfully} alt="Code entered successfully!" />
              </div>
            )}

            {!openJoinWithCodeGame ?
              <div className="row">
                <div className="col-lg-12">

                  {/* Display error message if it exists */}
                  {joinWithCodeErrorMessage && (
                    <div className="join-with-code-error-msg" style={{ color: "red", display: "flex", alignItems: "center", padding: "8px", backgroundColor: '#ede4e4', borderRadius: '4px' }}>
                      <span>{joinWithCodeErrorMessage}</span>
                      <button className="ms-auto"
                        onClick={handleCloseError}
                        style={{
                          marginLeft: "10px",
                          border: "none",
                          background: "transparent",
                          color: "red",
                          cursor: "pointer",
                          fontSize: "16px",
                          justifyContent: 'end'
                        }}
                        aria-label="Close"
                      >
                        &times;
                      </button>
                    </div>
                  )}
                  <div className="image-container-cgv4">
                    <img
                      className="join_with_code_img_cgv4" width={'100%'}
                      src={assets.course_v4.join_with_code_text_box_background}
                    ></img>
                    <FormProvider methods={methods}>
                      <Stack spacing={3} sx={{ my: 2 }} className="join_with_code_input_cgv4">
                        <RHFTextField name="code" onPaste={handlePaste} label="Enter code" />
                      </Stack>
                      {gameResults?.map((resultObj, index) => (
                        <div>
                          <Accordion
                            key={"learner_game_" + index}
                            id={"learner_game_id_" + index}
                            elevation={12}
                            className="mt-2"
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              variant="outlined"
                            >
                              <Typography>
                                <Stack
                                  className="align-items-center"
                                  direction="row"
                                  spacing={1}
                                >
                                  <span className="gsp-quiz-question-pop">
                                    {resultObj?.gamename}
                                  </span>
                                  <p className="gsp-quiz-question-pop">
                                    ({fDate(resultObj.createdAt)})
                                  </p>
                                </Stack>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {resultObj?.puzzleresultsdetail.map(
                                (lObj, aIndex) => (
                                  <Card
                                    key={`game-id-` + aIndex}
                                    className="mb-2 p-2"
                                  >
                                    <div>
                                      {Object.entries(lObj?.results[0]).map(
                                        ([property, value]) => (
                                          <div
                                            key={property}
                                            className="align-items-center"
                                          >
                                            <span className="gsp-game-property-text">
                                              {property}:{" "}
                                              <span className="gsp-quiz-question-pop">
                                                {value}
                                              </span>
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Card>
                                )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}

                      <div className="d-flex justify-content-end align-items-center">
                        <button type="submit" className="Leader-board-btn-cgv4 me-2" onClick={handleSubmit(onShowResultSubmit)}>
                          <p className="mb-0 me-2">Leader board</p> <img
                            className="" width={40}
                            src={assets.homev4.white_with_blue_arrow}
                          ></img>
                        </button>
                        <button type="submit" className="Join-with-code-btn-cgv4 pe-0 me-2" loading={isSubmitting} onClick={handleSubmit(onJoinWithCodeSubmit)}>
                          <p className="mb-0 me-2">Join with code</p>
                        </button>
                      </div>

                    </FormProvider>
                  </div>
                </div>
              </div> :
              <div className="h-100 w-100">
                {openJoinWithCodeGame == conditional_configs.curriculam_types.games ?
                  <iframe id="self-learning-game-play" width="100%" height="100%" src={gameSettingLink}></iframe>
                  :
                  openJoinWithCodeGame == conditional_configs.curriculam_types.whiteboard ?
                    <div className="h-100">
                      <div className="h-100">
                        <div className="whiteboard-tabs-gsp">
                          {whiteBoardTabList.map((wTab, wIndex) => {
                            return (
                              <div
                                key={"id_whiteboard-" + wIndex}
                                className="whiteboard-tab-container-gsp"
                              >
                                <div
                                  className={
                                    wTab?.active
                                      ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                                      : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                                  }
                                  onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                                >
                                  <div className="whiteboard-tab-name-gsp">
                                    {wTab.tabName}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                        {whiteboardLink ? <iframe
                          id={"l-pod-grpsp-board-" + whiteboardKey}
                          className="self-learning-whiteboard-iframe-gsp"
                          height="93%"
                          src={whiteboardLink}
                        ></iframe> : <div className="self-learning-empty-board">Please select board</div>}
                      </div>
                    </div> : ""}
              </div>}
          </DialogContent>
          {/* <DialogActions >
          <Button className="share-code-start-game-btn mb-3">Join</Button>
        </DialogActions> */}

          {enableChatWindow ?
            <div className="l-pods-chat-container-cwv4">
              <div className="l-pods-chat-main">
                <div className="l-pods-chat-header">
                  <div className="d-flex align-items-center">
                    <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                      <img
                        className="me-1" width={''}
                        src={assets.course_v4.left_arrow}
                      ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                    </IconButton>
                    <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                      <img
                        className="" width={'30px'}
                        src={assets.course_v4.close_icon}
                      ></img>
                    </span>
                  </div>
                  <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                </div>
                <div className="l-pods-chat-body">{renderChatWindow()}</div>
                <div className="my-friends-chat-text-box-main-div">
                  <div className="text-clips-container">
                    {textClips.map((clip, index) => (
                      <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                        {clip}
                      </button>
                    ))}
                  </div>
                  <form
                    className="w-100 d-flex align-items-center"
                    action=""
                    onSubmit={(e) => handleChatSubmit(e)}
                  >
                    {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                      <label for="image-file-input-2-1">
                        <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                      </label>
                      <input
                        id="image-file-input-2-1"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div> */}
                    <textarea
                      id="ind-chat-input-id"
                      rows={"1"}
                      className="form-control ind-chat-input-cgv4"
                      value={message}
                      onKeyPress={handleKeyPressForGroup}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type here..."
                      disabled={remainingMessageCount === 0}
                    />
                    <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                      <IconButton type="submit" disabled={remainingMessageCount === 0}>
                        <img
                          className="" width={'40px'}
                          src={assets.course_v4.send_arrow}
                        ></img>
                      </IconButton>
                    </div>
                  </form>
                  {/* Display message */}

                  <p className="mb-0 message-limited-text-cgv4">
                    {remainingMessageCount > 0
                      ? `You have ${remainingMessageCount} messages left.`
                      : "You have reached your message limit."}
                  </p>
                </div>
              </div>
            </div>
            : ""}
          {openJoinWithCodeGame ?
            <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
              {getIcon(icons_config.chat)}
            </IconButton> : ""}
        </Dialog>

        {/* OPEN SHARE CODE GAME POPUP */}
        <Dialog
          open={openGameShareCodePopup}
          onClose={handleGamesShareCodePopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={openGameSettings}
        >
          <DialogTitle id="alert-dialog-title" >
            {getGameDetail(openedContent?.game_id)?.name}
            <span className="add_kids_close_icon" onClick={handleGamesShareCodePopupClose}>
              <CloseIcon />
            </span>
            {/* {!openGameSettings ? <span className="add_kids_close_icon" onClick={handleGamesShareCodePopupClose}>
            <CloseIcon />
          </span> :
            <span className="add_kids_close_icon" onClick={setOpenGameSettings(false)}>
              <CloseIcon />
            </span>
            } */}
          </DialogTitle>
          <DialogContent>
            {openGameSettings == false ?
              <div>
                {openedGroupData && openedGroupData.code ?
                  <div className="share-code-text">
                    <span>Code:&nbsp;</span>
                    <span>{openedGroupData.code}</span>
                  </div>
                  :
                  ""}
                <div className="row">
                  <div className="col-lg-6">
                    <img width={500} height={300} alt={openedContent?.name} src={getGameDetail(openedContent?.game_id)?.img}></img>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center">
                    <div>
                      <Typography variant="h4">Players:</Typography>
                      {players.length == 0 ? <Typography variant="h7">No Players in this Group</Typography> : ""}
                      {players.map((obj, index) => {
                        return (
                          <span key={`playesrs-` + index} className=" d-flex align-items-center">
                            <span className="players-status-dot me-2"></span>
                            {obj.name}
                            {getCurrentUserData().id !== obj.id ?
                              <IconButton aria-label="close" className="remove-player" onClick={(e) => removeLearnerFromGroup(e, obj.id)} >
                                <DeleteIcon />
                              </IconButton>
                              :
                              <span className="player-master">You</span>}
                          </span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div> :
              <div className="h-100 w-100">
                <iframe id="self-learning-game-settings" width="100%" height="100%" src={gameSettingLink}></iframe>
              </div>}

          </DialogContent>
          {!openGameSettings && openedGroupData && openedGroupData.code ?
            <DialogActions >
              <Button className="share-code-start-game-btn mb-3" onClick={(e) => startGame(e)}>Start</Button>
            </DialogActions> :
            !openGameSettings ?
              <DialogActions >
                <Button className="share-code-start-game-btn mb-3" onClick={(e) => shareCode(e, conditional_configs.curriculam_types.games)}>Share code</Button>
              </DialogActions> : ""
          }

          {enableChatWindow ?
            <div className="l-pods-chat-container-cwv4">
              <div className="l-pods-chat-main">
                <div className="l-pods-chat-header">

                  <div className="d-flex align-items-center">
                    <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                      <img
                        className="me-1" width={''}
                        src={assets.course_v4.left_arrow}
                      ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                    </IconButton>
                    <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                      <img
                        className="" width={'30px'}
                        src={assets.course_v4.close_icon}
                      ></img>
                    </span>
                  </div>
                  <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                </div>
                <div className="l-pods-chat-body">{renderChatWindow()}</div>
                <div className="my-friends-chat-text-box-main-div">
                  <div className="text-clips-container">
                    {textClips.map((clip, index) => (
                      <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                        {clip}
                      </button>
                    ))}
                  </div>
                  <form
                    className="w-100 d-flex align-items-center"
                    action=""
                    onSubmit={(e) => handleChatSubmit(e)}
                  >
                    {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                      <label for="image-file-input-3-1">
                        <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                      </label>
                      <input
                        id="image-file-input-3-1"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div> */}
                    <textarea
                      id="ind-chat-input-id"
                      rows={"1"}
                      className="form-control ind-chat-input-cgv4"
                      value={message}
                      onKeyPress={handleKeyPressForGroup}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type here..."
                      disabled={remainingMessageCount === 0}
                    />
                    <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                      <IconButton type="submit" disabled={remainingMessageCount === 0}>
                        <img
                          className="" width={'40px'}
                          src={assets.course_v4.send_arrow}
                        ></img>
                      </IconButton>
                    </div>
                  </form>
                  {/* Display message */}

                  <p className="mb-0 message-limited-text-cgv4">
                    {remainingMessageCount > 0
                      ? `You have ${remainingMessageCount} messages left.`
                      : "You have reached your message limit."}
                  </p>
                </div>
              </div>
            </div>
            : ""}
          {openGameSettings ? <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
            {getIcon(icons_config.chat)}
          </IconButton> : ""}
        </Dialog>

        {/* OPEN SHARE CODE WHITEBOARD POPUP */}
        <Dialog
          open={openBoardShareCodePopup}
          onClose={handleBoardShareCodePopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          fullScreen={openWhiteboardTabScreen}
        >
          <DialogTitle id="alert-dialog-title">
            {!isMaximized && (
              <p className="Join-with-code-text-cgv4 text-align-center mt-1 mb-0">{openedContent?.name}</p>
            )}

            {whiteboardLink ? <div onClick={toggleWhiteboardSize} className="max-min-iconcgv4 me-4">
              {isMaximized ? <img
                className="" width={'18px'}
                src={assets.course_v4.minimize_icon}
              ></img>
                :
                <img
                  className="" width={'18px'}
                  src={assets.course_v4.maximize_icon}
                ></img>}
            </div> : ''}

            <span className="close_icon_cgv4" onClick={handleBoardShareCodePopupClose}>
              <img
                className="" width={'30px'}
                src={assets.course_v4.close_icon}
              ></img>
            </span>
          </DialogTitle>
          <DialogContent>
            {!openWhiteboardTabScreen ?
              <div>

                <div className="card game-card-cgv4">
                  <div style={game_card_img}>
                    <div className="share-code-text-cgv4">
                      <div className="row">
                        <div className="col-lg-6 col-md-5 col-sm-6 col-12">

                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-6 col-12">
                          <div className="">
                            <div>
                              <p className="player-text-cgv4">Members</p>
                              {players && players.length == 0 ? <p className="no-players-text-cgv4"> No Members in this Group</p> : ""}
                              {players.map((obj, index) => {
                                return (
                                  <span key={`playesrs-` + index} className=" d-flex align-items-center mb-2">
                                    <span className="players-status-dot me-2"></span>
                                    <div>
                                      <p className="player-name-text-cgv4 mb-0">  {obj?.name} </p>
                                      {getCurrentUserData().id !== obj.id ?
                                        <div className="remove-player" onClick={(e) => handleRemovePlayerClick(e, obj.id)}>
                                          <img
                                            className="cursor-pointer" width={13}
                                            src={assets.course_v4.delete_icon}
                                          ></img>
                                        </div>
                                        :
                                        <span className="player-master-cgv4">You</span>}

                                      {showConfirmModal && (
                                        <div className="modal-overlay-cgv4">
                                          <div className="modal-content-cgv4">
                                            <div className="d-flex">
                                              <p className="Join-with-code-text-cgv4 mt-1 mb-0">DELETE</p>
                                              <span className="cursor-pointer ms-auto" onClick={handleCancelPlayerRemove}>
                                                <img
                                                  className="" width={'30px'}
                                                  src={assets.course_v4.close_icon}
                                                ></img>
                                              </span>
                                            </div>

                                            <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to remove this player?</p>


                                            <div className="d-flex justify-content-center align-items-center pb-3">
                                              <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelPlayerRemove}>
                                                <p className="mb-0">No</p>
                                              </button>
                                              <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmPlayerRemove}>
                                                <p className="mb-0 me-2">Yes</p> <img
                                                  className="" width={40}
                                                  src={assets.homev4.white_with_blue_arrow}
                                                ></img>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </span>
                                )
                              })}
                            </div>
                          </div>

                          {openedGroupData && openedGroupData.code ?
                            <div>
                              <div className="share-code-div-cgv4">
                                <span className="mb-0"><strong>CODE&nbsp;:</strong>&nbsp;</span>
                                <span className="mb-0 d-flex align-items-center">{openedGroupData.code}
                                  <img
                                    className="cursor-pointer" width={30}
                                    src={assets.self_learning.copyLink}
                                    onClick={(e) => copyShareCodeLink()}
                                  ></img>
                                </span>

                              </div>
                              {copyMessage && <span className="copy-code-text-cgv4">{copyMessage}</span>}
                              {/* <p className="copy-code-text-cgv4">Copy Code</p> */}
                            </div>

                            :
                            ""}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center pt-4 pb-4">
                    <img
                      className="hide-on-mobile-view-cgv4 ms-3" width={55}
                      src={assets.course_v4.plus_arrow}
                    ></img>
                    <div className="ms-auto">
                      {!openWhiteboardTabScreen && openedGroupData && openedGroupData.code ?
                        <div className="d-flex align-items-center justify-content-end me-3">
                          <button className="start-game-cgv4 p-2 me-2" onClick={() => navigateToCoursesDescription(openedContent?.name, openedContent)}>
                            <p className="mb-0">Description</p>
                          </button>
                          <button className="start-game-cgv4" onClick={(e) => openBoard(e)}>
                            <p className="mb-0 me-2 ">Open</p>  <img
                              className="hide-on-mobile-view-cgv4" width={40}
                              src={assets.homev4.white_with_blue_arrow}
                            ></img>
                          </button>
                        </div>
                        :
                        !openWhiteboardTabScreen ?
                          <div className="d-flex align-items-center justify-content-end me-3">
                            <button className="start-game-cgv4 p-2 me-2" onClick={() => navigateToCoursesDescription(openedContent?.name, openedContent)}>
                              <p className="mb-0">Description</p>
                            </button>
                            <button className="start-game-cgv4" onClick={(e) => shareCode(e, conditional_configs.curriculam_types.whiteboard)}>
                              <p className="mb-0 me-2">Share code</p>  <img
                                className="hide-on-mobile-view-cgv4" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button>
                          </div>
                          : ""
                      }
                    </div>
                  </div>


                </div>
              </div> :
              <div className="h-100">
                {/* <div className="share-code-text-in-detail mb-2">
                  <span>Code:&nbsp;</span>
                  <span>{openedGroupData?.code}</span>
                </div> */}

                {!isMaximized && (
                  <div>
                    <div className="d-flex justify-content-center align-item-center share-code-text-in-detail mb-2">
                      <span>Code:&nbsp;</span>
                      <span className="">{openedGroupData?.code}</span>
                      <ContentCopyIcon
                        className="cursor-pointer ms-2" sx={{ fontSize: 17 }}
                        onClick={(e) => copyShareCodeLink()}
                      /> {copyMessage && <span className="copy-code-text-cwv4">{copyMessage}</span>}
                    </div>

                  </div>

                )}


                <div className="whiteboard-tabs-gsp">
                  {whiteBoardTabList.map((wTab, wIndex) => {
                    return (
                      <div
                        key={"id_whiteboard-" + wIndex}
                        className="whiteboard-tab-container-gsp"
                      >
                        <div
                          className={
                            wTab?.active
                              ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                              : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                          }
                          onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                        >
                          <div className="whiteboard-tab-name-gsp">
                            {wTab.tabName}

                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
                {whiteboardLink ? <iframe
                  id={"l-pod-grpsp-board-" + whiteboardKey}
                  className={`self-learning-whiteboard-iframe-gsp ${isMaximized ? 'fullscreen' : ''}`}
                  height="93%"
                  src={whiteboardLink}
                ></iframe> : <div className="self-learning-empty-board">Please select board</div>}
              </div>}
          </DialogContent>



          {/* {!openGameSettings ? <DialogActions >
          <Button className="share-code-start-game-btn mb-3" onClick={(e) => startGame(e)}>Start</Button>
        </DialogActions> : ""} */}
          {enableChatWindow ?
            <div className="l-pods-chat-container-cwv4">
              <div className="l-pods-chat-main">
                <div className="l-pods-chat-header">

                  <div className="d-flex align-items-center">
                    <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                      <img
                        className="me-1" width={''}
                        src={assets.course_v4.left_arrow}
                      ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                    </IconButton>
                    <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                      <img
                        className="" width={'30px'}
                        src={assets.course_v4.close_icon}
                      ></img>
                    </span>
                  </div>
                  <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                </div>
                <div className="l-pods-chat-body">{renderChatWindow()}</div>
                <div className="my-friends-chat-text-box-main-div">
                  <div className="text-clips-container">
                    {textClips.map((clip, index) => (
                      <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                        {clip}
                      </button>
                    ))}
                  </div>
                  <form
                    className="w-100 d-flex align-items-center"
                    action=""
                    onSubmit={(e) => handleChatSubmit(e)}
                  >
                    {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                      <label for="image-file-input-4-1">
                        <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                      </label>
                      <input
                        id="image-file-input-4-1"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div> */}
                    <textarea
                      id="ind-chat-input-id"
                      rows={"1"}
                      className="form-control ind-chat-input-cgv4"
                      value={message}
                      onKeyPress={handleKeyPressForGroup}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type here..."
                      disabled={remainingMessageCount === 0}
                    />

                    <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                      <IconButton type="submit" disabled={remainingMessageCount === 0}>
                        <img
                          className="" width={'40px'}
                          src={assets.course_v4.send_arrow}
                        ></img>
                      </IconButton>
                    </div>
                  </form>
                  {/* Display message */}

                  <p className="mb-0 message-limited-text-cgv4">
                    {remainingMessageCount > 0
                      ? `You have ${remainingMessageCount} messages left.`
                      : "You have reached your message limit."}
                  </p>
                </div>
              </div>
            </div>
            : ""}
          {openWhiteboardTabScreen ?
            <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
              {getIcon(icons_config.chat)}
            </IconButton> : ""}
        </Dialog>

        {/* OPEN INVITE FRIEND POPUP */}
        <Dialog
          open={inviteFriendPopup}
          onClose={handleCloseInviteFriendPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth="true"
        >
          <DialogTitle id="alert-dialog-title" >
            <span>Invite Friend</span>

            <span className="self-learning-w-close" onClick={(e) => handleCloseInviteFriendPopup(e)}>
              <CloseIcon />
            </span>
          </DialogTitle>
          <DialogContent>
            <form className="mt-4">
              <TextField
                id={'invite-friend-id'}
                label="Email/Username"
                variant="outlined"
                fullWidth
                name="invite-friend-name"
                onChange={handleInviteFriendUserNameChange}
              />
            </form>
          </DialogContent>
          <DialogActions >
            <Button className="share-code-start-game-btn mb-3" type="submit" onClick={(e) => onSubmitInviteFriend(e)}>Submit</Button>
          </DialogActions>
        </Dialog>

      </Container>
    </div>

  )
}
