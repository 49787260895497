import React, { useEffect } from "react";
//import Header from '../../components/header/header';
import Header from "./header";
import "./privacy-policy.css";
import Footer from "../../components/footer/footer";
import conditional_configs from "../../config/conditional_config";

export default function PrivacyPolicy() {
  document.title = "Meeku | Privacy Policy";

  return (
    <div>
      <Header screen={"home"} />
      <div class="container container-privacy-policy">
        <div class="body">
          <h1 class="header-privacy-policy">Privacy Policy</h1>

          <p class="content-privacy-policy">
            Protecting your private information is our priority. This Statement
            of Privacy applies to meeku.world, and Meeku LLC and governs data
            collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to Meeku LLC include
            https://meeku.world/ and Meeku. The Meeku application is a online
            Education application. By using the Meeku application, you consent
            to the data practices described in this statement.{" "}
          </p>

          <h3>Collection of your Personal Information</h3>

          <p class="content-privacy-policy">
            In order to better provide you with products and services offered,
            Meeku may collect personally identifiable information, such as your:{" "}
          </p>
          <ul class="dash">
            <li>First and Last Name</li>
            <li>Mailing Address</li>
            <li>E-mail Address</li>
            <li>Phone Number</li>
          </ul>

          <p class="content-privacy-policy">
            If you purchase Meeku's products and services, we collect billing
            and credit card information. This information is used to complete
            the purchase transaction.
          </p>

          <p class="content-privacy-policy">
            {" "}
            Meeku may also collect anonymous demographic information, which is
            not unique to you, such as your:
          </p>
          <ul class="dash">
            <li> Age</li>
            <li>Gender</li>
          </ul>

          <p class="content-privacy-policy">
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an account; (b) entering a sweepstakes or contest sponsored by us or
            one of our partners; (c) signing up for special offers from selected
            third parties; (d) sending us an email message; (e) submitting your
            credit card or other payment information when ordering and
            purchasing products and services. To wit, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </p>

          <h3>Use of your Personal Information</h3>

          <p class="content-privacy-policy">
            Meeku collects and uses your personal information to operate and
            deliver the services you have requested.
          </p>

          <p class="content-privacy-policy">
            {" "}
            Meeku may also use your personally identifiable information to
            inform you of other products or services available from Meeku and
            its affiliates.
          </p>

          <h3>Sharing Information with Third Parties</h3>

          <p class="content-privacy-policy">
            Meeku does not sell, rent or lease its customer lists to third
            parties.{" "}
          </p>

          <p class="content-privacy-policy">
            Meeku may share data with trusted partners to help perform
            statistical analysis, send you email or postal mail, provide
            customer support, or arrange for deliveries. All such third parties
            are prohibited from using your personal information except to
            provide these services to Meeku, and they are required to maintain
            the confidentiality of your information.{" "}
          </p>

          <p class="content-privacy-policy">
            Meeku may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Meeku or the site; (b) protect
            and defend the rights or property of Meeku; and/or (c) act under
            exigent circumstances to protect the personal safety of users of
            Meeku, or the public.
          </p>

          <h3>Security of your Personal Information</h3>

          <p class="content-privacy-policy">
            Meeku secures your personal information from unauthorized access,
            use, or disclosure. Meeku uses the following methods for this
            purpose:
          </p>
          <ul class="dash">
            <li> SSL Protocol</li>
          </ul>

          <p class="content-privacy-policy">
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol.
          </p>

          <p class="content-privacy-policy">
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.
          </p>

          <h3>Right to Deletion</h3>

          <p class="content-privacy-policy">
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
          </p>
          <div class="deletion-privacy-policy">
            <ul>
              <li> Delete your personal information from our records; and</li>
              <li>
                {" "}
                Direct any service providers to delete your personal information
                from their records.
              </li>
            </ul>
          </div>
          <p class="content-privacy-policy">
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to:
          </p>
          <div class="deletion-privacy-policy">
            <ul>
              <li>
                {" "}
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and us;
              </li>
              <li>
                {" "}
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </li>
              <li>
                {" "}
                Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li>
                {" "}
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </li>
              <li>
                {" "}
                Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li>
                {" "}
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li>
                {" "}
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </li>
              <li> Comply with an existing legal obligation; or</li>
              <li>
                {" "}
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </li>
            </ul>
          </div>

          <h3>Children Under Thirteen</h3>

          <p class="content-privacy-policy">
            Meeku collects personally identifiable information from children
            under the age of thirteen. Meeku collects this information for the
            following reason(s): Provide online education to children between 8
            and 13.
          </p>

          <p class="content-privacy-policy">
            We provide information about our personal data practices for
            children on our home page and wherever we knowingly collect personal
            data from children on our application. If you are under the age of
            thirteen, you must ask your parent or guardian for permission to use
            this application. If you are a parent and you have questions
            regarding our data collection practices, please contact us using the
            information provided at the end of this Statement of Privacy.{" "}
          </p>

          <h3>E-mail Communications</h3>

          <p class="content-privacy-policy">
            From time to time, Meeku may contact you via email for the purpose
            of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication.{" "}
          </p>

          <p class="content-privacy-policy">
            If you would like to stop receiving marketing or promotional
            communications via email from Meeku, you may opt out of such
            communications by Click on unsubscribe.{" "}
          </p>

          <h3>Changes to this Statement</h3>
          <p class="content-privacy-policy">
            Meeku reserves the right to change this Privacy Policy from time to
            time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our application, and/or by updating any privacy information. Your
            continued use of the application and/or Services available after
            such modifications will constitute your: (a) acknowledgment of the
            modified Privacy Policy; and (b) agreement to abide and be bound by
            that Policy.{" "}
          </p>

          <h3>Contact Information</h3>
          <p class="content-privacy-policy">
            Meeku welcomes your questions or comments regarding this Statement
            of Privacy. If you believe that Meeku has not adhered to this
            Statement, please contact Meeku at:{" "}
          </p>

          <p class="content-privacy-policy">
            Meeku LLC
            {/* <br>_________________ </br>
                        <br> _________________, _________________, _________________</br> */}
          </p>

          <p className="content-privacy-policy">
            Email Address:{" "}
            <a href="mailto:{{conditional_configs.supportEmail}}">{conditional_configs.supportEmail}</a>
          </p>

          <p class="content-privacy-policy">Effective as of March 13, 2023 </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
