import React, { useEffect, useState, createRef, useRef } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "./login-button.css"
import {
    Menu,
    MenuItem,
    Typography,
    Stack,
    ListItemIcon,
    Box, Divider, Avatar
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import { getCurrentUserData, getInitialsFromFullName } from "../../utils/util";
import account from "../../_mock/account";
import roleConfig from "../../config/conditional_config";
import AuthService from "../../services/auth/auth.service";
import navigate_route_config from "../../config/navigate_route_config";
import assets from "../../config/assets";


export default function LoginButton(props) {

    const [headerCtrlMenu, setHeaderCtrlMenu] = useState(false);

    const zoomClient = useSelector((state) => state.zoom.zoomClient);

    const navigate = useNavigate();

    const handleHeaderControlMenuClick = (event) => {
        setHeaderCtrlMenu(true);
    };
    const handleHeaderControlMenuClose = () => {
        setHeaderCtrlMenu(false);
    };


    const logOut = () => {
        if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
            navigate('/admin-login');
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
            if (zoomClient && zoomClient.stream) {
                navigate('/expert-login');
                zoomClient.leave(true);
            } else {
                navigate('/expert-login');
            }
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
            if (zoomClient && zoomClient.stream) {
                navigate('/learner-login');
                zoomClient.leave(true);
            } else {
                navigate('/learner-login');
            }
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
            navigate('/parent-login');
        } else {
            navigate('/admin-login');
        }
        setHeaderCtrlMenu(null);
        AuthService.logout();
    };

    const navigateTo = (page) => {
        if (page) {
            navigate(page)
        }
    }

    return (
        <div>
            {/* large-screen-login-button-btn */}

            <div className="large-screen-login-button-btn">
                <div className="btn-group">
                    <span className={`${props?.className}`} id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <button className="login-btn-hv4 me-3">Login</button>
                        {/* <img src={assets.homev3.userIcon} className="avatar-margin-large" style={{ width: "55%" }}></img> */}
                        {/* <Avatar className="avatar-margin-large"></Avatar> */}
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li><a className="dropdown-item" onClick={() => navigateTo('/parent-login')}>Login as Parent</a></li>
                        <li><a className="dropdown-item" onClick={() => navigateTo('/learner-login')}>Login as Learner</a></li>
                    </ul>
                </div>
            </div>

            {/* mobile-screen-login-button-btn */}

            <div className="mobile-screen-login-button-btn">
                <div className="login-btn-container-det">
                    <div onClick={handleHeaderControlMenuClick}>
                        <img src={assets.homev3.userIcon} className="" style={{ width: "50%" }}></img>
                        {/* <Avatar></Avatar> */}
                    </div>
                    <Menu className="mt-5"
                        open={headerCtrlMenu}
                        onClose={handleHeaderControlMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >

                        <Stack>
                            <MenuItem to={'/parent-login'} component={RouterLink}>
                                <Typography variant="inherit">Login as Parent</Typography>
                            </MenuItem>
                        </Stack>

                        <Stack>
                            <MenuItem to={'/learner-login'} component={RouterLink} >
                                <Typography variant="inherit">Login as Learner</Typography>
                            </MenuItem>
                        </Stack>
                    </Menu>
                </div>
            </div>

        </div>
    )
}

