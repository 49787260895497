import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../services/admin-service";
import conditional_configs from "../../../config/conditional_config";
import { getCurrentUserData } from "../../../utils/util";
import slugify from "react-slugify";

export default function Sessions() {
  const [expertStreamDetails, setExpertStreamDetails] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      getAllExpertStreamDetails();
    } else {
      localStorage.clear();
      navigate("/admin-login");
    }
  }, []);

  const getAllExpertStreamDetails = () => {
    AdminService.getAllExpertStreamDetails()
      .then((response) => {
        setExpertStreamDetails(response);
        console.log("getAllExpertStreamDetails", response);
      })
      .catch((error) => {
        console.error("Error fetching expert stream details:", error);
      });
  };

  const navigateToSessionDetails = (id) => {
    navigate("/sessions/" + id);
  };

  const navigateToZoomSessionLog = (obj) => {
    navigate("/zoom-session-logs/" + slugify(obj));
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Box sx={{ width: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={3}
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Sessions
            <Badge
              className="badge_quiz"
              badgeContent={expertStreamDetails.length}
              color="secondary"
            >
              {/* Badge */}
            </Badge>
          </Typography>
        </Stack>
      </Box>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Course Name</TableCell>
                <TableCell>Expert Name</TableCell>
                <TableCell>Expert Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Logs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expertStreamDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((stream) => {
                  const isSelected = selected.indexOf(stream.id) !== -1;
                  return (
                    <TableRow
                      key={stream.id}
                      hover
                      onClick={(event) => handleClick(event, stream.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      selected={isSelected}
                    >
                      <TableCell
                        onClick={(event) => navigateToSessionDetails(stream.id)}
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                          <Typography
                            className="link_text"
                            variant="subtitle2"
                            noWrap
                          >
                            {stream.slug}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{stream.expertname}</TableCell>
                      <TableCell>{stream.email}</TableCell>
                      <TableCell>
                        {stream.coursestreamstatus === 80 ? (
                          <div className="d-flex align-items-center courses-staream-status-text-s">
                            <span className="courses-staream-s-circle"></span>{" "}
                            Start
                          </div>
                        ) : (
                          <div className="d-flex align-items-center courses-staream-status-text-e">
                            <span className="courses-staream-e-circle"></span>{" "}
                            End
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(stream.startdatetime).format("D/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(stream.enddatetime).format("D/MM/YYYY")}
                      </TableCell>

                      <TableCell>
                        <Tooltip title="Zoom Session Log" placement="top-start">
                          <Button
                            onClick={(event) =>
                              navigateToZoomSessionLog(stream.slug)
                            }
                          >
                            Logs{" "}
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={expertStreamDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
