import { v4 as uuidv4 } from "uuid";
import account from "../../../../_mock/account";

export const g0 = [
  {
    id: "5",
    Task: "Rkid_2",
    icon: account,
  }
];

export const g1 = [
  {
    id: "1",
    Task: "Rajkumar_8",
    icon: account,
  },
  {
    id: "2",
    Task: "Rajkumar_9",
    icon: account,
  }
];

export const g2 = [
  {
    id: "3",
    Task: "Srikanth",
    icon: account,
  },
  {
    id: "4",
    Task: "Sukanya",
    icon: account,
  }
];

export const columnsFromBackend = {
  [uuidv4()]: {
    title: "Participants",
    items: [],
  },
  [uuidv4()]: {
    title: "239",
    items: g0,
  },
  [uuidv4()]: {
    title: "Test_03112022_4-G1",
    items: g1,
  },
  [uuidv4()]: {
    title: "Test_03112022_4-G2",
    items: g2,
  },
};
