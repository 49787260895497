
import { createSlice } from '@reduxjs/toolkit'

export const zoomSlice = createSlice({
  name: 'zoom',
  initialState: {
    //stream: {},
    stream: null,
    status: 'closed',
    zoomClient: {},
    cameraStatus: false,
    shareScreenStatus : false,
    participants: [],
    zoomAudio: 'mute',
    leaveGroup: false,
    allowParticipants: false,
    stopBreakoutLoading: false,
  },
  reducers: {
    setStream: (state, action) => {
      state.stream = action.payload
    },

    setZoomStatus: (state, action) => {
      state.status = action.payload
    },
    setClient: (state, action) => {
      state.zoomClient = action.payload
    },
    setCameraStatus: (state, action) => {
      state.cameraStatus = action.payload
    },
    setShareScreenStatus: (state, action) => {
      state.shareScreenStatus = action.payload
    },
    setParticipants: (state, action) => {
      state.participants = action.payload
    },
    setZoomAudio: (state, action) => {
      state.zoomAudio = action.payload
    },
    setLeaveGroup: (state, action) => {
      state.leaveGroup = action.payload
    },
    setAllowParticipants: (state, action) => {
      state.allowParticipants = action.payload
    },
    setStopBreakoutLoading: (state, action) => {
      state.stopBreakoutLoading = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const 
{ 
  setStream, 
  setZoomStatus, 
  setClient, 
  setCameraStatus, 
  setShareScreenStatus, 
  setParticipants, 
  setZoomAudio, 
  setLeaveGroup,
  setAllowParticipants,
  setStopBreakoutLoading
 } = zoomSlice.actions

export default zoomSlice.reducer