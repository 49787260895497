import React, { useState, useRef } from "react";
//import "./HomePage.css";
import { Stage, Layer } from "react-konva";
import { addTextNode } from "../text-node/text-node";

function HomePage() {
  const [selectedId, selectShape] = useState(null);
  const [shapes, setShapes] = useState([]);
  const stageEl = React.createRef();
  const layerEl = React.createRef();


  const drawText = () => {
    const id = addTextNode(stageEl.current.getStage(), layerEl.current);
    const shs = shapes.concat([id]);
    setShapes(shs);
  };
  
  return (
    <div className="home-page">
      <div>
        <button variant="secondary" onClick={drawText}>
          Text
        </button>
      </div>
    
      <Stage
        width={window.innerWidth * 0.9}
        height={window.innerHeight - 150}
        ref={stageEl}
        onMouseDown={e => {
          // deselect when clicked on empty area
          const clickedOnEmpty = e.target === e.target.getStage();
          if (clickedOnEmpty) {
            selectShape(null);
          }
        }}
      >
        <Layer ref={layerEl}>
          
        </Layer>
      </Stage>
    </div>
  );
}
export default HomePage;