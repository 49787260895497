import React from "react";
import { Grid, Avatar, Button } from "@mui/material";

import baby_img from "../../../assets/img/baby_img.png";


import Box from "@mui/material/Box";


//ICONS
import CloseIcon from "@mui/icons-material/Close";


import Drawer from "@mui/material/Drawer";
import "./courses-information.css";

const drawerWidth = 360;

export default function CourseInformation() {
  //Drawer function
  const [drawopen, setDrawopen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawopen(true);
  };

  const handleDrawerClose = () => {
    setDrawopen(false);
  };

  //Drawer function end
  return (
    <div>
      <div className="container">
        <div className="row mt-5">
          {/* Introduction section */}
          <div className="col-12 col-lg-6 col-md-6 ">
            <p className="introduction_text">
              Introduction to Robotics-A <br></br> lego based learning
            </p>
            <Grid container>
              <div className="me-5">
                <label className="price_session_text">PRICE/SESSION</label>
                <p className="price_session_number">
                  <strong>$10</strong>/session
                </p>
              </div>
              <div className="">
                <label className="price_session_text">SESSIONS</label>
                <p className="price_session_number">
                  <strong>10</strong>
                </p>
              </div>
            </Grid>

            <Grid container>
              <div className="me-5">
                <label className="price_session_text">Instruction Medium</label>
                <p className="price_session_number">
                  <strong>English</strong>
                </p>
              </div>
              <div className="">
                <label className="price_session_text">kids age criteria</label>
                <p className="price_session_number">
                  <strong>8-12 years</strong>
                </p>
              </div>
            </Grid>
            <p className="">Categories</p>
            <Grid container>
              <div className="">
                <Button
                  variant="outlined"
                  className="Categories__btn me-2 mt-3 mt-lg-0"
                >
                  Design
                </Button>
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  className="Categories__btn me-2 mt-3 mt-lg-0"
                >
                  Emotions
                </Button>
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  className="Categories__btn mt-3 mt-lg-0"
                >
                  Psychology
                </Button>
              </div>
            </Grid>
            <div className="">
              <Button
                variant="outlined"
                className="select_slot_btn me-2"
                onClick={handleDrawerOpen}
              >
                Select the Slot
              </Button>
            </div>
          </div>
          {/* Introduction section end */}
          {/* baby image section */}
          <div className="col-12 col-lg-6 col-md-6 mt-3">
            <img
              src={baby_img}
              alt=""
              className="baby_img"
              style={{ width: "100%", height: "100%" }}
            ></img>
          </div>
          {/* baby image section end */}
        </div>
        {/* About Course section */}
        <div className="row mt-5">
          <div className="col-12 col-lg-12 col-md-12 ">
            <p className="about_course_text mb-1">About Course</p>
            <p className="about_course_discription_text">
              Great designers are empaths.They harness the power of emotional
              response to connect to audience and inspire action.If uou want
              your designs to move people.you need to understand the role
              emotion plays in your work.Join stefan Mumaw for this fun and
              frank exploration,which probes the human condition for lesson that
              can be used in any design project: from branding campaign to
              product packaging.Stefan explains how emotion,rational
              throught,and culture impact our reactions to design,and how you
              can use different choices and tools-layout.typography,color,and
              more-to trigger different emotions and achive connection with your
              brand,product,or service.
            </p>
            <div>
              <p className="about_course_text mb-1">Syllabus</p>
              <div>
                <p className="Session_title_text m-0"> Session 1</p>
                <h5>Seccion Topic</h5>
                <p className="about_course_discription_text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum has bees the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a gallery of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div>
                <p className="Session_title_text m-0"> Session 2</p>
                <h5>Seccion Topic</h5>
                <p className="about_course_discription_text">
                  It is a long establisted fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more or less
                  normal.
                  <li>Many desktop publishing packages and web page editors</li>
                  <li>Various version have evolved over the years</li>
                  <li>
                    There are many variantions of passages of Lorem Ipsum
                    available
                  </li>
                  <li>All the Lorem Ipsum generators on the Internet tend</li>
                  <li>
                    {" "}
                    Lorem Ipsum is therefore alwayts free from
                    repetition,injection humour, or non-characteristic
                  </li>
                </p>
              </div>
              <div>
                <p className="Session_title_text m-0"> Session 3</p>
                <h5>Seccion Topic</h5>
                <p className="about_course_discription_text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum has bees the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a gallery of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              {/* Course Slots */}
              <div className="row ms-0 me-0">
                <p className="course_slots_text">Course Slots</p>
                <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mt-lg-0">
                  <Box className="course_section_box">
                    <p className="m-0 starting_text_slots">Staring from</p>
                    <p className="m-0 date_text_slots">oct 10,2022</p>
                    <p className="m-0 time_text_slots">
                      Monday 10 30am to 11 30am
                    </p>
                    <p className="m-0 week_text_slots">2 session per week</p>
                    <div>
                      <Box className="slots_remaining_box">
                        <p className="slots_remain_text m-0">
                          3 slots remaining
                        </p>
                      </Box>
                    </div>
                    <Grid container className="mt-4">
                      <div className="">
                        <p className="enrool__price_text">$100</p>
                      </div>
                      <div>
                        <Button variant="outlined" className="enrool__btn">
                          Enroll Course
                        </Button>
                      </div>
                    </Grid>
                    <p className="view_session_text m-0">View session info</p>
                  </Box>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mt-lg-0">
                  <Box className="course_section_box">
                    <p className="m-0 starting_text_slots">Staring from</p>
                    <p className="m-0 date_text_slots">oct 15,2022</p>
                    <p className="m-0 time_text_slots">
                      Monday 10 30am to 11 30am
                    </p>
                    <p className="m-0 week_text_slots">2 session per week</p>
                    <div>
                      <Box className="slots_remaining_box">
                        <p className="slots_remain_text m-0">
                          5 slots remaining
                        </p>
                      </Box>
                    </div>
                    <Grid container className="mt-4">
                      <div className="">
                        <p className="enrool__price_text">$100</p>
                      </div>
                      <div>
                        <Button variant="outlined" className="enrool__btn">
                          Enroll Course
                        </Button>
                      </div>
                    </Grid>
                    <p className="view_session_text m-0">View session info</p>
                  </Box>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mt-lg-0">
                  <Box className="course_section_box">
                    <p className="m-0 starting_text_slots">Staring from</p>
                    <p className="m-0 date_text_slots">oct 20,2022</p>
                    <p className="m-0 time_text_slots">
                      Monday 10 30am to 11 30am
                    </p>
                    <p className="m-0 week_text_slots">2 session per week</p>
                    <div>
                      <Box className="slots_remaining_box">
                        <p className="slots_remain_text m-0">
                          7 slots remaining
                        </p>
                      </Box>
                    </div>
                    <Grid container className="mt-4">
                      <div className="">
                        <p className="enrool__price_text">$100</p>
                      </div>
                      <div>
                        <Button variant="outlined" className="enrool__btn">
                          Enroll Course
                        </Button>
                      </div>
                    </Grid>
                    <p className="view_session_text m-0">View session info</p>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Course section end*/}

        <br></br>
      </div>

      {/* Drawer section*/}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drawopen}
      >
        <div className="drawer_section mt-2 mb-3 p-2">
          <div className="">
            <h5>{"Cart (1)"}</h5>
            <span className="cart_close_icon" onClick={handleDrawerClose}>
              <CloseIcon />
            </span>
          </div>
          <Grid container className="mt-3">
            <div className="me-2">
              <Box className="cart_drawer_box"></Box>
            </div>
            <div className="">
              Introduction to Robotics-A<br></br> logo based learning
              <div>
                <Button size="small">Remove course</Button>
              </div>
            </div>
            <div className="ms-3">
              <p className="">$10.00</p>
            </div>
          </Grid>

          <div className="footer">
            <p className="me-5">
              Total amount to pay <span className="ms-5">$100</span>
            </p>
            <Button variant="outlined" className="make_payment_btn">
              Make Payment
            </Button>
          </div>
        </div>
      </Drawer>
      {/* Drawer section end*/}
    </div>
  );
}
