import React, { useEffect, useState } from 'react'


import WhiteBoard from '../../../../components/whiteboard/WhiteBoard1'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import './breakout-room.css'




export default function BreakOut() {

  const navigate = useNavigate();
  const endBreakout = () => {
    Swal.fire({
      title: 'End Breakout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/expert");
      }
    })
  }

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = new Date();
    setHours((time.getHours()) % 12)
    setMinutes(time.getMinutes());
    setSeconds(time.getSeconds());
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);
  //hours + ':' + 
  return (
    <div className=''
    >
      <div className="breakout-progress-navbar mt-3">
        <div>
          <p>Breakout session in progress</p>
        </div>
        <div>
          <p>{minutes + ':' + seconds} sec</p>
        </div>
        <div>
          <button style={{ background: 'rgb(250, 236, 34)', border: '1px', fontSize: '12px', fontWeight: '600', color: '#522bc1', width: '100%' }}>View Rooms</button>
        </div>
        <div>
          <button style={{ background: 'rgb(250, 236, 34)', border: '1px', fontSize: '12px', fontWeight: '600', color: '#522bc1', width: '100%' }} onClick={endBreakout}>End Breakout</button>
        </div>
      </div>
   
    
          <div className='white-boards-tab'>
            <WhiteBoard />
          </div>
       
    </div>
  )
}
