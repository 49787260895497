import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";

const getAffiliateMemberByRefCode = async (refCode) => {
    const response = await http.get(api_path.getAffiliateMemberByRefCode + refCode, { headers: authHeader() });
    return response.data;
  };

const AffiliateService = {
    getAffiliateMemberByRefCode
};
export default AffiliateService;