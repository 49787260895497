import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarProvider, useSnackbar } from 'notistack';

// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
//Services
import AuthService from '../../../services/auth/auth.service';

//CONFIG
import navigate_route_config from '../../../config/navigate_route_config';
import roleConfig from '../../../config/conditional_config';
import meta_data from '../../../config/meta_data';

//Utils
import { getCurrentUserData } from '../../../utils/util';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Box,
  Grid,
  Typography
} from '@mui/material';

import './expert-login-v2.css';
import assets from '../../../config/assets';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ExpertLogin() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = data => {
    AuthService.expert_login(data.email, data.password)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Logged in successful', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          navigate(navigate_route_config.expert.my_courses);
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
      navigate('/customers');
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
      navigate(navigate_route_config.expert.my_courses);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
      navigate(navigate_route_config.learner.my_courses);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
      navigate(navigate_route_config.parent.my_kids);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.curriculam_creator) {
      navigate(navigate_route_config.admin.whiteboard_templates);
    } else {
      localStorage.removeItem("user");
    }
  }, []);

  return (

    <Page title="Login">
      <div className="">
        <div className="row ms-0 me-0">
          {/* backgroundImage section */}
          <div className="col-md-8 left-side-expe-v2 d-none d-md-block" style={{ backgroundImage: `url(${assets.login.expert_login_img})`, backgroundSize: 'cover', backgroundPosition: 'left' }}>

          </div>
          {/* Forms section */}
          <div className="col-md-4 right-side-expe-v2">
            <div className=''>
              <ContentStyle>

                <div className='d-flex justify-content-center'>
                  <Logo />
                </div>

                <Typography className='sign-text-expe-v2'>
                  Sign in as a Expert!
                </Typography>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <RHFTextField name="email" label="Email address" />

                    <RHFTextField
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Stack direction="row" alignItems="center" justifyContent="start">
                    <Link variant="subtitle2" className='mt-1 mb-3' underline="hover" to="/forgot-password" component={RouterLink} smooth="true">
                      Forgot password?
                    </Link>
                  </Stack>

                  <LoadingButton className='login-btn-expe-v2' fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Login
                  </LoadingButton>
                </FormProvider>
              </ContentStyle>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
