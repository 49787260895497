import React, { useState, useEffect } from "react";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin, Toolbar, TransformToolbarSlot } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import UserGuidePdf from "../../assets/user-guide-pdf/user_guide_meeku_compressed.pdf";
import './user-guide.css'



const UserGuide = () => {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    useEffect(() => {
        const interval = setInterval(() => {
            const canvases = document.querySelectorAll('.pdf-viewer-container canvas');
            canvases.forEach(canvas => {
                canvas.style.transform = 'none';
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                <div className="pdf-viewer-container" style={{ height: '750px', transform: "none" }}>
                    <Viewer
                        fileUrl={UserGuidePdf}
                        plugins={[defaultLayoutPluginInstance]} />
                </div>
            </Worker>
        </div>

    );
};

export default UserGuide;
