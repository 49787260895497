import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import {  AppBar, Toolbar, } from '@mui/material';
// components

//


import Headerzoom from "../../components/classroom/header/header";


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;


const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));



// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (

    <RootStyle>
      <Headerzoom onClick={onOpenSidebar} />
    </RootStyle>
    
  );
}
