import React, { Component, useEffect, useState } from "react";
//import { BsFileEarmarkText } from "react-icons/bs";
import { MdClose, MdSend } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { ImAttachment } from "react-icons/im";
import { FiSmile } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setMenu } from "../../../store/slices/settingsSlice";

import "./chat.css";

import socketIO from "socket.io-client";
//const socket = socketIO.connect('http://localhost:3001');
/* detect url in a message and add a link tag */
function detectURL(message) {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (urlMatch) {
    return '<a href="' + urlMatch + '">' + urlMatch + "</a>";
  });
}

/* ========== */
/* Title component */
class Title extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [] };
    this.usersList = this.usersList.bind(this);
  }
  usersList() {
    // socket.on(`receive_users`, (data) => {
    // 	console.log(` Chat User Data ${data[0].groupId}`)
    // 	this.setState({
    // 		users: data
    // 	});
    // })
  }

  componentDidMount() {
    this.usersList();
  }

  render() {
    return (
      <>
        {this.state.users.map((user) => (
          <div className={"chatApp__convTitle"}>
            {/* {this.props.owner}'s display */}
            <div className="group-label">{user.groupName}</div>
            <div className="group-participants">
              <div className="participant-count">
                <HiUsers className="participant-user" />{" "}
                <span className="particiapnt-number">
                  {user.Participatent.length}
                </span>
              </div>
              <div className="participant-members">
                {user.Participatent.map((usersName) => (
                  <p>{usersName},</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}
/* end Title component */
/* ========== */

/* ========== */
/* InputMessage component - used to type the message */
class InputMessage extends Component {
  constructor(props) {
    super(props);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
  }
  handleSendMessage(event) {
    event.preventDefault();
    /* Disable sendMessage if the message is empty */
    if (this.messageInput.value.length > 0) {
      this.props.sendMessageLoading(
        this.ownerInput.value,
        this.ownerAvatarInput.value,
        this.messageInput.value
      );
      /* Reset input after send*/
      this.messageInput.value = "";
    }
  }
  handleTyping(event) {
    /* Tell users when another user has at least started to write */
    if (this.messageInput.value.length > 0) {
      this.props.typing(this.ownerInput.value);
    } else {
      /* When there is no more character, the user no longer writes */
      this.props.resetTyping(this.ownerInput.value);
    }
  }
  render() {
    /* If the chatbox state is loading, loading class for display */
    var loadingClass = this.props.isLoading
      ? "chatApp__convButton--loading"
      : "";
    let sendButtonIcon = <MdSend />;
    return (
      <form onSubmit={this.handleSendMessage} className="">
        <input
          type="hidden"
          ref={(owner) => (this.ownerInput = owner)}
          value={this.props.owner}
        />
        <input
          type="hidden"
          ref={(ownerAvatar) => (this.ownerAvatarInput = ownerAvatar)}
          value={this.props.ownerAvatar}
        />
        <input
          type="text"
          ref={(message) => (this.messageInput = message)}
          className={"chatApp__convInput"}
          placeholder="Type anything and hit enter"
          onKeyDown={this.handleTyping}
          onKeyUp={this.handleTyping}
          tabIndex="0"
        />
        <div
          className={"chatApp__convButton " + loadingClass}
          onClick={this.handleSendMessage}
        >
          {sendButtonIcon}
        </div>
      </form>
    );
  }
}
/* end InputMessage component */
/* ========== */

/* ========== */
/* TypingIndicator component */
class TypingIndicator extends React.Component {
  // constructor(props) {
  // 	super(props);
  // }
  render() {
    let typersDisplay = "";
    let countTypers = 0;
    /* for each user writing messages in chatroom */
    for (var key in this.props.isTyping) {
      /* retrieve the name if it isn't the owner of the chatbox */
      if (key !== this.props.owner && this.props.isTyping[key]) {
        typersDisplay += ", " + key;
        countTypers++;
      }
    }
    /* formatting text */
    typersDisplay = typersDisplay.substr(1);
    typersDisplay += countTypers > 1 ? " are " : " is ";
    /* if at least one other person writes */
    if (countTypers > 0) {
      return (
        <div className={"chatApp__convTyping"}>
          {typersDisplay} writing
          <span className={"chatApp__convTypingDot"}></span>
        </div>
      );
    }
    return <div className={"chatApp__convTyping"}></div>;
  }
}
/* end TypingIndicator component */
/* ========== */

/* ========== */
/* MessageList component - contains all messages */
class MessageList extends React.Component {
  // constructor(props) {
  // 	super(props);
  // }
  render() {
    return (
      <div className={"chatApp__convTimeline"}>
        {this.props.messages
          .slice(0)
          .reverse()
          .map((messageItem) => (
            <MessageItem
              key={messageItem.id}
              owner={this.props.owner}
              sender={messageItem.sender}
              senderAvatar={messageItem.senderAvatar}
              message={messageItem.message}
            />
          ))}
      </div>
    );
  }
}
/* end MessageList component */
/* ========== */

/* ========== */
/* MessageItem component - composed of a message and the sender's avatar */
class MessageItem extends React.Component {
  render() {
    /* message position formatting - right if I'm the author */
    let messagePosition =
      this.props.owner === this.props.sender
        ? "chatApp__convMessageItem--right"
        : "chatApp__convMessageItem--left";
    return (
      <div
        className={"chatApp__convMessageItem " + messagePosition + " clearfix"}
      >
        <img
          src={this.props.senderAvatar}
          alt={this.props.sender}
          className="chatApp__convMessageAvatar"
        />
        <div
          className="chatApp__convMessageValue"
          dangerouslySetInnerHTML={{ __html: this.props.message }}
        ></div>
      </div>
    );
  }
}
/* end MessageItem component */
/* ========== */

/* ========== */
/* ChatBox component - composed of Title, MessageList, TypingIndicator, InputMessage */
class ChatBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.sendMessageLoading = this.sendMessageLoading.bind(this);
    //var timeout = null;
  }
  /* catch the sendMessage signal and update the loading state then continues the sending instruction */
  sendMessageLoading(sender, senderAvatar, message) {
    this.setState({ isLoading: true });

    console.log(`===========>${this.props.socket}`);
    this.props.sendMessage(sender, senderAvatar, message);
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 400);
  }
  render() {
    return (
      <div className={"chatApp__conv"}>
        <Title owner={this.props.owner} />
        <MessageList owner={this.props.owner} messages={this.props.messages} />
        <div className={"chatApp__convSendMessage "}>
          <div className="">
            <TypingIndicator
              owner={this.props.owner}
              isTyping={this.props.isTyping}
            />
            <InputMessage
              isLoading={this.state.isLoading}
              owner={this.props.owner}
              ownerAvatar={this.props.ownerAvatar}
              sendMessage={this.props.sendMessage}
              sendMessageLoading={this.sendMessageLoading}
              typing={this.props.typing}
              resetTyping={this.props.resetTyping}
            />
          </div>
          <div className="chat_settings align-items-center">
            <div className="toallcheck">
              <input type={"checkbox"} className="me-1" /> To all Groups
            </div>
            <div className="chat-attachments ms-auto">
              {" "}
              <div className="attachment-icons">
                <ImAttachment className="me-1" /> <FiSmile className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* end ChatBox component */
/* ========== */

/* ========== */
/* ChatRoom component - composed of multiple ChatBoxes */
class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [
        {
          id: 1,
          sender: "Shun",
          senderAvatar: "https://i.pravatar.cc/150?img=32",
          message: "Hello 👋",
        },
        {
          id: 2,
          sender: "Gabe",
          senderAvatar: "https://i.pravatar.cc/150?img=56",
          message: "Hey!",
        },
      ],
      isTyping: [],
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.typing = this.typing.bind(this);
    this.resetTyping = this.resetTyping.bind(this);
  }
  /* adds a new message to the chatroom */
  sendMessage(sender, senderAvatar, message) {
    setTimeout(() => {
      let messageFormat = detectURL(message);
      let newMessageItem = {
        id: this.state.messages.length + 1,
        sender: sender,
        senderAvatar: senderAvatar,
        message: messageFormat,
      };
      this.setState({ messages: [...this.state.messages, newMessageItem] });
      this.resetTyping(sender);
    }, 400);
  }
  /* updates the writing indicator if not already displayed */
  typing(writer) {
    if (!this.state.isTyping[writer]) {
      let stateTyping = this.state.isTyping;
      stateTyping[writer] = true;
      this.setState({ isTyping: stateTyping });
    }
  }
  /* hide the writing indicator */
  resetTyping(writer) {
    let stateTyping = this.state.isTyping;
    stateTyping[writer] = false;
    this.setState({ isTyping: stateTyping });
  }
  render() {
    let users = {};
    //let chatBoxes = [];
    let messages = this.state.messages;
    let isTyping = this.state.isTyping;
    let sendMessage = this.sendMessage;
    let typing = this.typing;
    let resetTyping = this.resetTyping;

    /* user details - can add as many users as desired */
    users[0] = { name: "Shun", avatar: "https://i.pravatar.cc/150?img=32" };
    users[1] = { name: "Gabe", avatar: "https://i.pravatar.cc/150?img=56" };
    /* test with two other users :)
		users[2] = { name: 'Kate', avatar: 'https://i.pravatar.cc/150?img=47' };
		users[3] = { name: 'Patrick', avatar: 'https://i.pravatar.cc/150?img=14' };
		*/

    /* creation of a chatbox for each user present in the chatroom */
    var user = users[0];

    return (
      <div className={"chatApp__room"}>
        <ChatBox
          key={0}
          owner={user.name}
          ownerAvatar={user.avatar}
          sendMessage={sendMessage}
          typing={typing}
          resetTyping={resetTyping}
          messages={messages}
          isTyping={isTyping}
          socket={"ssss"}
        />
      </div>
    );
  }
}
/* end ChatRoom component */
/* ========== */

function ChatList() {
  const dispatch = useDispatch();

  const setSidebarMenu = (menu) => {
    console.log("ChatRoom ChatRoom");
    dispatch(setMenu(menu));
  };

  // useEffect(() => {
  // 	socket.emit('join_room', { userName: `Suresh`, socketID: socket.id, roomId: 123 });

  // 	socket.emit('on_chat_load', () => {
  // 		console.log(`receive_users`)
  // 	});
  // 	console.log("Test")
  // }, [])

  return (
    <div className="host-course-list">
      <div className="d-flex align-items-center e-role-label">
        <div className=" me-auto"> Chat </div>
        <span onClick={() => setSidebarMenu("")}>
          <MdClose className="close-icon" />
        </span>
      </div>
      <ChatRoom />
    </div>
  );
}

export default ChatList;
