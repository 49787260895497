import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";

import { getCurrentUserData } from "../utils/util";

const tempCustomersRegister = async (data) => {
  try {
    const response = await http.post(api_path.tempCustomersRegister, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const TempCustomerService = {
  tempCustomersRegister,
};
export default TempCustomerService;
