
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Swal from "sweetalert2";

import styled from "@emotion/styled";
import { columnsFromBackend } from "./task-assign-list";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskCard from "./task-assign-card";
import Box from "@mui/material/Box";
import account from "../../../../_mock/account";

import { Button, Grid, Avatar, Typography, TextField, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Iconify from "../../../../components/Iconify";

//FORMS
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//images
import Assignment_img from "../../../../assets/img/group_space/assignment_img.png";
import planning_img from "../../../../assets/img/group_space/planning_img.png";
import team_img from "../../../../assets/img/group_space/team_img.png";
import whiteboard_img from "../../../../assets/img/group_space/whiteboard_img.png";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End
//ICONS
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//SERVICE
import MemberService from "../../../../services/member-service";

//CONFIG
import error_messages from "../../../../config/error_messages";
import conditional_configs from "../../../../config/conditional_config";
import { getCurrentUserData } from "../../../../utils/util";
import "./task-assign.css";
import icons_config from "../../../../config/icons";
// Style
const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: white;
  min-width: 300px;
  border-radius: 8px;
  padding: 13px;
  margin-right: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  font-size: 18px;
  padding: 2px 5px;
  font-weight: bold;
  align-self: flex-start;
`;
// Style end
const TaskAssign = () => {
  const getIcon = (name) => <Iconify icon={name} width={15} height={15} />;
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isAddNewGroupClicked, setIsAddNewGroupClicked] = useState(false);
  const [isEditGroupNameClicked, setIsEditGroupNameClicked] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [groupOptions, setGroupOptions] = React.useState(null);
  const open_select = Boolean(groupOptions);
  const [selectedGroup, setSelectedGroup] = React.useState();

  //GET STREAM ID FROM LINK
  let { courseid, streamId } = useParams();

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      if (streamId) {
        getMemberCourseGroupByStreamIdNew();
      }
    } else {
      localStorage.clear();
      navigate('/expert-login');
    }
  }, []);

  //#region FORMS

  const groupOptionsOpen = (event, data) => {
    setSelectedGroup(data);
    console.log(data)
    setGroupOptions(event.currentTarget);
  };


  const groupOptionsClose = () => {
    console.log('Called')
    setGroupOptions(null);
  };

  const validationSchema = Yup.object().shape({
    groupName: Yup.string().required("Group name is required")
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    register: editFormReg,
    control: editFormCtrl,
    handleSubmit: editFormSubmit,
    setValue,
    formState: { errors: editFormErrors }
  } = useForm();

  const onAddNewGroupClick = (data) => {
    setIsAddNewGroupClicked(true);
  }

  /** ADD NEW COURSE GROUP */
  const onAddNewGroupSubmit = (data) => {
    if (data.groupName) {
      console.log(data);
      setIsAddNewGroupClicked(false);
      let checkDuplicates = columns.filter((e) => e.groupname.toLowerCase() == data.groupName.toLowerCase());
      console.log(checkDuplicates)
      if (checkDuplicates.length == 0) {
        let creatGroupModel = {
          courseid: courseid.toString(),
          groupname: data.groupName,
          news: "news",
          mission: "mission"
        }
        console.log(creatGroupModel)
        MemberService.createCourseGroup(creatGroupModel).then(
          (response) => {
            if (response && response.data) {
              let createMemberCourseGroupModel = {
                coursestreamid: streamId,
                coursegroupid: response.data.id.toString(),
                memberid: "0"
              }
              MemberService.createMemberCourseGroup(createMemberCourseGroupModel).then(
                (response) => {
                  if (response && response.data) {
                    if (streamId) {
                      getMemberCourseGroupByStreamIdNew();
                      enqueueSnackbar(error_messages.createGroupSuccess, { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    }
                  }
                }
              ).catch((e) => {
                console.log(e);
              });
            }
          }
        ).catch((e) => {
          console.log(e);
        });
      } else {
        enqueueSnackbar(error_messages.groupNameExist, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    }
  }

  const openEditGroupName = (e, columnId, data) => {
    setSelectedGroup(data);
    setIsEditGroupNameClicked(columnId);
    setValue("editGroupName", data.groupname, {
      shouldValidate: true,
      shouldDirty: true
    })

  }
  /** ADD NEW COURSE GROUP */
  const onEditGroupNameSubmit = (data) => {
    console.log(data);
    console.log(selectedGroup);
    const editData={
      id: selectedGroup.coursegroupid,
      groupname: data.editGroupName,
      updatedby: getCurrentUserData().id.toString()
    }
    console.log(editData);
    MemberService.editGroupName(editData).then(
      (response) => {
        console.log(response)
        if (response && response.data) {
          setIsEditGroupNameClicked();
            getMemberCourseGroupByStreamIdNew();
            enqueueSnackbar("Group name modified successfully", { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    ).catch((e) => {
      console.log(e);
    });

  }

  const confirmGroupName = () => {
    Swal.fire({
      title: error_messages.confirmGroupName,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        onAddNewGroupSubmit();
      }
    });

  }

  /** UPDATE MEMBER GROUP */
  const updateMemberGroup = (data) => {
    if (data) {
      MemberService.updateMemberGroup(data).then(
        (response) => {
          console.log("Group Updated Successfully", response);
          enqueueSnackbar(error_messages.updateGroupSuccess, { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

        }
      ).catch((e) => {
        console.log(e);
      });
    }
  }

  //#endregion

  // DRAG FUNCTION
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.members];
      const destItems = [...destColumn.members];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          members: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          members: destItems,
        },
      });
      if (destColumn.coursegroupid != 0) {
        let updateGroupModel = {
          coursestreamid: streamId,
          coursegroupid: destColumn.coursegroupid.toString(),
          memberid: result.draggableId
        }
        updateMemberGroup(updateGroupModel)
      } else if (destColumn.coursegroupid == 0) {
        console.log(result)
        let getDeleteData = columns[source.droppableId].members;
        if (getDeleteData && getDeleteData.length > 1) {
          let getDeleteId = getDeleteData.filter((e) => e.id == result.draggableId)[0];
          console.log(getDeleteId);
          if (getDeleteId && getDeleteId.membercoursegroupid) {
            deleteMemberCourseGroup(getDeleteId.membercoursegroupid);
          }
        }
        // else{
        //   enqueueSnackbar(error_messages.unableToDeleteMemberFromGroup, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        // }
      }
      // console.log(updateGroupModel);
      // console.log(destColumn)
      // //let destinationObj = columns[]
      // //updateMemberGroup(updateGroupModel)
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.members];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          members: copiedItems,
        },
      });
    }
  };

  /** GET GROUPS BY STREAM ID */
  const getMemberCourseGroupByStreamIdNew = () => {
    let tempColumns = [];
    MemberService.getMemberOnlyCourseGroupByStreamId(streamId).then(
      (response) => {
        console.log("Participants", response.data);
        if (response.success == "true") {
          let tempColumnsObj = {
            coursegroupid: "",
            courseid: "",
            groupname: "",
            mission: "",
            news: "",
            type: "",
            members: []
          }
          tempColumnsObj.coursegroupid = 0;
          tempColumnsObj.groupname = "Participants";
          tempColumnsObj.type = "participants";
          tempColumnsObj.members = response.data;
          tempColumns.push(tempColumnsObj);
        }
        MemberService.getMemberCourseGroupByStreamIdNew(streamId).then(
          (response) => {
            console.log("Groups", response.data);
            if (response.data && response.data.length > 0) {
              response.data.forEach(element => {
                let tempColumnsObj = {
                  coursegroupid: "",
                  courseid: "",
                  groupname: "",
                  mission: "",
                  news: "",
                  type: "",
                  members: []
                }
                tempColumnsObj = element;
                tempColumns.push(tempColumnsObj);
              });
              setColumns(tempColumns);
            }
          }
        ).catch((e) => {
          console.log(e);
        });
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  /** DELETE GROUP */
  const deleteGroup = (e) => {
    let deleteGrpId = selectedGroup.coursegroupid;
    if (deleteGrpId && deleteGrpId != 0) {
      MemberService.deleteCourseGroup(deleteGrpId).then(
        (response) => {
          console.log("Group Deleted Successfully", response);
          getMemberCourseGroupByStreamIdNew();
          enqueueSnackbar(error_messages.deleteGroupSuccess, { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

        }
      ).catch((e) => {
        console.log(e);
      });
    }
  }

  /** DELETE MEMBER COURSE GROUP */
  const deleteMemberCourseGroup = (id) => {
    if (id) {
      MemberService.deleteMemberCourseGroup(id).then(
        (response) => {
          console.log("Group Deleted Successfully", response);
          getMemberCourseGroupByStreamIdNew();
          enqueueSnackbar(error_messages.deleteMemberFromGroup, { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      ).catch((e) => {
        console.log(e);
      });
    }
  }

  // DRAG FUNCTION
  return (
    <div>
      <div className="">
        <Box className="title_navbar mb-2">
          <div className="d-flex  align-items-center">
            <img src={planning_img} alt="" style={{ width: "25px" }} />
            <span className="planning_text">
              Groups
            </span>
          </div>
        </Box>

      </div>
      {/* DRAG section */}
      <DragDropContext
        className="drag_section"
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        <Container className="e-manage-groups-container">
          <TaskColumnStyles>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <Droppable key={`e-m-grp-` + columnId} droppableId={columnId}>
                  {(provided, snapshot) => (
                    <TaskList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div className="d-flex align-items-center title_text_icon;">
                        {isEditGroupNameClicked !== columnId ?
                          <Title className="">{column.groupname}
                            {columnId > 0 ?
                              <span className="e-manage-group-edit-group-name" onClick={(e) => openEditGroupName(e, columnId, column)}>
                                {getIcon(icons_config.editFilled)}
                              </span> : ""}
                          </Title>
                          :
                          <div className="w-100">
                            <form name="editForm" onSubmit={editFormSubmit(onEditGroupNameSubmit)}>
                              <Controller
                                control={editFormCtrl}
                                name="editGroupName"
                                rules={{ required: "Group name is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                  <TextField
                                    id="editGroupName"
                                    label="Group name"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={editFormErrors.editGroupName}
                                    helperText={editFormErrors.editGroupName?.message}
                                  />
                                )}
                              />
                              <div className="e-add-group-confirm-icons">
                                <IconButton type="submit">
                                  {getIcon(icons_config.tick)}
                                </IconButton>
                                <IconButton type="button" onClick={() => setIsEditGroupNameClicked()}>
                                  {getIcon(icons_config.no)}
                                </IconButton>
                              </div>
                            </form>
                          </div>}

                        {index !== 0  && isEditGroupNameClicked !== columnId? <MoreVertIcon className="ms-auto" onClick={(e) => groupOptionsOpen(e, column)} /> : ""}
                        <Menu
                          id={'select_group_options_' + columnId}
                          MenuListProps={{
                            "aria-labelledby": "select_group_options",
                          }}
                          anchorEl={groupOptions}
                          open={open_select}
                          onClose={groupOptionsClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={(e) => deleteGroup(e)} >
                            Delete
                          </MenuItem>
                        </Menu>

                      </div>

                      {column.members.map((item, index) => (
                        <TaskCard key={`m-grp-members-` + index} item={item} index={index} />
                      ))}
                      {provided.placeholder}
                    </TaskList>
                  )}
                </Droppable>
              );
            })}
            {/* ADD NEW GROUP */}
            <div className="addNewGroupContainer">
              <div className="addNewGroup">
                {!isAddNewGroupClicked ?
                  <Title onClick={(e) => onAddNewGroupClick(e)}><span className="addNewGroupPlus">+</span> Add New Group</Title>
                  :
                  <div>
                    <form name="addForm" onSubmit={handleSubmit(onAddNewGroupSubmit)}>
                      <TextField
                        required
                        id="groupName"
                        name="groupName"
                        label="Group Name"
                        fullWidth
                        margin="dense"
                        className="mt-0"
                        {...register("groupName")}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.groupName?.message}
                      </Typography>
                      <div className="e-add-group-confirm-icons">
                        <IconButton type="submit">
                          {getIcon(icons_config.tick)}
                        </IconButton>
                        <IconButton type="button" onClick={() => setIsAddNewGroupClicked(false)}>
                          {getIcon(icons_config.no)}
                        </IconButton>
                      </div>
                    </form>
                  </div>}
              </div>
            </div>
          </TaskColumnStyles>


        </Container>
      </DragDropContext>
    </div>
  );
};

export default TaskAssign;
