import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from 'notistack';

import CourseService from "../../services/courses-service";

export default function App() {
  const navigate = useNavigate();

  const checkoutData = useSelector((state) => state.settings.checkoutData);
  const stripeClientSecret = useSelector((state) => state.settings.stripeClientSecret);

  const { enqueueSnackbar } = useSnackbar();

  //const stripe = useStripe();
 // const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if (!stripe) {
    //   return;
    // }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(stripeClientSecret).then(({ paymentIntent }) => {
    //   if (paymentIntent.status == "succeeded") {
    //     enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     assignCourseToParent();
    //   } else if (paymentIntent.status == "processing") {
    //     navigate('/parent/my-courses');
    //     enqueueSnackbar('Your payment is processing.', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   } else if (paymentIntent.status == "requires_payment_method") {
    //     navigate('/parent/my-courses');
    //     enqueueSnackbar('Your payment was not successful, please try again.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   } else {
    //     navigate('/parent/my-courses');
    //     enqueueSnackbar('Something went wrong.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   }
    //   // switch (paymentIntent.status) {
    //   //   case "succeeded":
    //   //     enqueueSnackbar('Payment succeeded!', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   //     setMessage("Payment succeeded!");
    //   //     break;
    //   //   case "processing":
    //   //     setMessage("Your payment is processing.");
    //   //     break;
    //   //   case "requires_payment_method":
    //   //     enqueueSnackbar('Your payment was not successful, please try again.', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   //     //setMessage("Your payment was not successful, please try again.");
    //   //     break;
    //   //   default:
    //   //     enqueueSnackbar('Something went wrong.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //   //     //setMessage("Something went wrong.");
    //   //     break;
    //   // }
    // });
  }, []);

  const assignCourseToParent = () => {
    if (checkoutData) {
      CourseService.assignCourseToParent(checkoutData)
        .then(response => {
          console.log(response);
          navigate('/parent/my-courses');
          if (response && response.data && response.success === 'true') {
            enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else if (response && response.data && response.success === 'false') {
            enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
        .catch(error => {
          navigate('/parent/my-courses');
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        });
    }

  }

  return (
    <div>Payment Processing</div>
  );
}