//import axios from "axios";
import http from "../../router/http-common";
import api_path from "../../config/api";

import conditional_configs from "../../config/conditional_config";

import { getCurrentUserData, setSessionTime} from "../../utils/util";

const admin_login = (username, password) => {
  return http
    .post(api_path.a_login, {
      email: username,
      password: password,
    })
    .then((response) => {
      let tempCurriculamCreators = conditional_configs.curriculam_creators;
      if (response.data.data.token) {
        let loginData = response.data.data;
        let checkIsCurriculamCreator = tempCurriculamCreators.filter(e=>e == loginData.email)[0];
        if(checkIsCurriculamCreator){
          loginData.role = conditional_configs.role.curriculam_creator;
        }
        localStorage.setItem("user", JSON.stringify(loginData));
        setSessionTime();
      }
      return response.data;
    });
};

const parent_login = (username, password) => {
  return http
    .post(api_path.p_login, {
      email: username,
      password: password,
    })
    .then((response) => {
      console.log(response)
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        setSessionTime();
      }
      return response.data;
    });
};

const expert_login = (username, password) => {
  return http
    .post(api_path.e_login, {
      email: username,
      password: password,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        setSessionTime();
      }
      return response.data;
    });
};

const learner_login = (username, password) => {
  return http
    .post(api_path.l_login, {
      username: username,
      password: password,
    })
    .then((response) => {
      console.log(response)
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        setSessionTime();
      }
      return response.data;
    });
};

const forgot_password = (username) => {
  return http
    .post(api_path.forgot_password, {
      email: username
    })
    .then((response) => {
      console.log(response)
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.clear();
  window.location.reload();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const checkUserAuthentication = ()=>{
  // if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin){
  //   navigate('/customers');
  // }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert){
  //   navigate(navigate_route_config.expert.my_courses);
  // }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner){
  //   navigate(navigate_route_config.learner.my_courses);
  // }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent){
  //   navigate(navigate_route_config.parent.my_kids);
  // }else {
  //   localStorage.clear();
  //   navigate('/admin-login');
  // }
}

const AuthService = {
  admin_login,
  parent_login,
  expert_login,
  learner_login,
  forgot_password,
  logout,
  getCurrentUser,
  checkUserAuthentication
};
export default AuthService;