import React, { useState, useEffect, useRef } from "react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { LinearProgress, Tooltip } from "@mui/material";
import { Container, Grid, FormControl, InputLabel, Select, MenuItem, Stack, IconButton, Menu } from "@mui/material";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Badge from "@mui/material/Badge";
import "./pods.css";
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from "../../../../components/Iconify";
import {
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from 'moment-timezone';
import { Pagination } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";

import io from "socket.io-client";

//MUI Icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

//UTILS
import { fDate } from "../../../../utils/formatTime";
import {
  getCurrentUserData,
  getTimeZoneShortForm,
  storeChats,
  getChats,
  removeAllChats,
  getInitialsFromFullName,
  generateCommonParams,
  storeOpenedChatGroup,
  getOpenedChatGroup,
  storeCompletedSessions,
  storeCurriculumDetail,
  storeWhiteboardTemplateList,
  convertTime
} from '../../../../utils/util';

//CONFIGS
import gameConfig from "../../../../config/game_config";
import navigate_route_config from "../../../../config/navigate_route_config";
import storage_configs from "../../../../config/storage-config";
import conditional_configs from "../../../../config/conditional_config";
import url_configs from "../../../../config/url";
import tooltip_messages from "../../../../config/tooltip";
import assets from "../../../../config/assets";

//SERVICES
import UtilService from "../../../../services/util.service";
import ExpertService from "../../../../services/expert-service";
import SessionService from "../../../../services/session-service";
import icons_config from "../../../../config/icons";
import NoRecordFound from "../../../../components/no-record-found/no-record-found";
import error_messages from "../../../../config/error_messages";
import MemberService from "../../../../services/member-service";
import slugify from "react-slugify";
import FirebaseService from "../../../../services/firebase-service";
import whiteboardConfig from "../../../../config/whiteboard-config";
import AdminService from "../../../../services/admin-service";
import games from "../../../../config/games";
import { setGroupSpaceQuiz } from "../../../../store/slices/settingsSlice";

let socket_conection;
let chat_server_socket;

export default function Pods() {
  const { enqueueSnackbar } = useSnackbar();
  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;
  const dispatch = useDispatch();

  // CHATS
  const [openChat, SetOpenChat] = useState(false);
  const [chatRoom, setChatRoom] = useState("");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiCtrlMenu, setEmojiCtrlMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState()

  /** DECLARATIONS */
  const [openCompletedSessions, setOpenCompletedSessions] = useState(false);
  const [openGroupSpaceButton, setOpenGroupSpaceButton] = useState(false);
  const [lastCompletedSessions, SetLastCompletedSessions] = useState([]);
  const [selectedSessionId, SetSelectedSessionId] = useState([]);
  const [selectedCompletedSession, SetSelectedCompletedSession] = useState([]);
  const [courseGroups, SetCourseGroups] = useState([]);
  const [selectedGroup, SetSelectedGroup] = useState();
  const [curriculamDetail, SetCurriculamDetail] = useState([]);
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const [openGameSettings, setOpenGameSettings] = React.useState(false);
  const [gameLink, setGameLink] = React.useState();
  const [podData, setPodData] = useState([]);
  const [sessionSchedules, setSessionSchedules] = useState([]);
  const [selectedSession, setSelectedSession] = useState();

  let { id } = useParams();

  const navigate = useNavigate();

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#0089ff",
      padding: "10px",
    },
  };

  //pagination
  const itemsPerPage = 2;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [chatCtrlMenu, setChatCtrlMenu] = useState(false);
  const [chatOptionEl, setChatOptionEl] = useState();
  const [chatType, setChatType] = useState('general_chat');

  //const podData = podData.slice(startIndex, endIndex);

  //#region LAST COMPLETED SESSIONS

  const getCurriculum = () => {
    ExpertService.GetCurriculum(id)
      .then((response) => {
        SetCurriculamDetail(response.data);
        storeCurriculumDetail(response.data);
        getAllWhiteboardTemplate();
      })
  }

  const handleCloseCompletedSessionsPopup = () => {
    setOpenCompletedSessions(false);
  };

  const handleOnSelectCompletedSession = (e, obj) => {
    SetSelectedCompletedSession(obj);
  }

  const handleOpenGroupSpace = () => {
    if (!selectedGroup) {
      return enqueueSnackbar('Please a group', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

    }
    openGroupSpace(selectedCompletedSession)
  }

  const getAllWhiteboardTemplate = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      setWhiteboardTemplateList(response.data);
      storeWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const showBoardsList = (contentBoards, slug, isClone) => {
    let lgrp = selectedGroup[0];
    let boardsList = [];
    if (contentBoards) {
      for (let h = 0; h < contentBoards.length; h++) {
        if (contentBoards[h] && contentBoards[h].template_ids) {
          let splitBoardIds = contentBoards[h].template_ids.split(',');
          if (splitBoardIds && splitBoardIds.length > 0) {
            for (let i = 0; i < splitBoardIds.length; i++) {
              let boardObj = {};
              let actualBoardList = whiteboardTemplateList;
              if (actualBoardList && actualBoardList.length > 0) {
                let newWhiteboardArray = [];
                //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
                actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
                  newWhiteboardArray[index] = { ...device }
                })
                boardObj = newWhiteboardArray[0];
                let tempWLink = "";

                if (isClone == true && !lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-attended-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-attended-' + getCurrentUserData().id + whiteboardConfig.key;
                } else if (isClone == true && lgrp.groupname) {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                } else if (!lgrp.groupname) {
                  FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id);
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-not-in-group-' + getCurrentUserData().id + whiteboardConfig.key;
                } else {
                  tempWLink = url_configs.white_board_server + getCurrentUserData().name + '&group=' + boardObj.name + '&slug=' + slug + "#room=" + boardObj.slug + '-div-' + contentBoards[h].id + '-div-' + slug + '-div-' + slugify(lgrp.groupname) + whiteboardConfig.key;
                }


                boardObj.whiteboardLink = tempWLink;
                if (getCurrentUserData().role == conditional_configs.role.expert) {
                  boardObj.is_locked = false;
                } else {
                  boardObj.is_locked = true;
                }
                boardsList.push(boardObj);
              }
            }
          }
        }
      }

    }
    return boardsList;
  }

  const openGroupSpace = (obj) => {
    console.log(obj)
    let tempDetail = obj;
    let cData = curriculamDetail;
    let getCurriculamDetailBySession = [];
    cData.filter(e => e.id == selectedSessionId).forEach((device, index) => {
      getCurriculamDetailBySession[index] = { ...device }
    });
    if (getCurriculamDetailBySession[0]) {
      //WHITEBOADS 
      let sessionBoards = getCurriculamDetailBySession[0].coursesession_whiteboards;

      if (sessionBoards && sessionBoards.length > 0) {
        setTimeout(() => {
          let boardData = [];
          console.log(sessionBoards);
          boardData = showBoardsList(sessionBoards, tempDetail.slug, false);
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_boards, JSON.stringify(boardData));
          console.log(boardData);
        }, 100);
      }

      //GAMES
      let sessionGames = getCurriculamDetailBySession[0].coursesession_games;
      if (sessionGames && sessionGames.length > 0) {
        sessionGames.forEach(element => {
          element.gameDetail = games.gameList.filter(e => e.id == element.puzzleid)[0];
        });
        setTimeout(() => {
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_games, JSON.stringify(sessionGames));
        }, 100);
      }

      //QUIZ
      let sessionQuiz = getCurriculamDetailBySession[0].coursecontentsessionquiz;
      if (sessionQuiz && sessionQuiz.length > 0) {
        let tempObj = {};
        tempObj.quizList = [];
        let getSelectedQuiz = sessionQuiz;
        tempObj.quizList = getSelectedQuiz;

        tempObj.topic = 'Quiz';
        tempObj.level = 'Beginner';
        tempObj.totalQuestions = getSelectedQuiz.length;
        tempObj.perQuestionScore = 5;
        tempObj.totalTime = 60; // in seconds
        tempObj.questions = [];

        for (let i = 0; i < getSelectedQuiz.length; i++) {
          let tempQObj = {
            question: getSelectedQuiz[i].coursequestion.question,
            type: getSelectedQuiz[i].coursequestion.questiontype,
            choices: getSelectedQuiz[i].coursequestion.answerlist,
            correctAnswer: getSelectedQuiz[i].coursequestion.answerlist.filter(e => e.correct == '1')[0]?.id,
          }
          tempObj.questions.push(tempQObj);
        }

        dispatch(setGroupSpaceQuiz(tempObj));
        setTimeout(() => {
          localStorage.setItem(storage_configs.localStorage.sessions.group_space.gsp_quiz, JSON.stringify(tempObj));
        }, 100);
      }

      //LOADING NEEDED HERE
      setTimeout(() => {
        navigate(navigate_route_config.expert.group_space + '/' + tempDetail.slug);
        window.location.reload();
      }, 2000);
    }
    // let tempDetail = obj.memberCourseStreamDetails[0];
    // navigate(navigate_route_config.learner.group_space + '/' + tempDetail.slug);

  }

  const openLastCompletedSessions = (e, obj) => {
    SetSelectedSessionId(obj.coursesessionid);
    if (obj && obj.memberCourseStreamDetails.length > 0) {
      SetLastCompletedSessions(obj.memberCourseStreamDetails.sort((a, b) => Number(b.id) - Number(a.id)).slice(0, 3));
      console.log(obj.memberCourseStreamDetails.sort((a, b) => Number(b.id) - Number(a.id)).slice(0, 3));
      setOpenGroupSpaceButton(true);
      // if (obj.memberCourseStreamDetails.slice(0, 3).length == 1) {
      //   //openGroupSpace('', obj.memberCourseStreamDetails[0]);
      // } else {

      //   setOpenGroupSpaceButton(true);
      // }
      localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, obj.coursestreamId);
      getCourseGroupDetail(obj.coursestreamId);
    }
  }

  const onGroupSpaceButtonClick = () => {
    if (lastCompletedSessions) {
      SetSelectedCompletedSession(lastCompletedSessions[0]);
    }
    setOpenCompletedSessions(true);
  }

  const getCourseGroupDetail = (streamId) => {
    MemberService.getMemberCourseGroupByStreamIdNew(streamId).then(
      (response) => {
        console.log("Groups", response.data);
        if (response.data && response.data.length > 0) {
          SetCourseGroups(response.data);
        }
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  const handleSelectedCourseGroupChange = (e) => {
    if (e && e.target.value) {
      const gId = e.target.value;
      console.log("e.target.value", e.target.value);
      SetSelectedGroup(courseGroups.filter(e => e.coursegroupid == gId));
      storeOpenedChatGroup(courseGroups.filter(e => e.coursegroupid == gId)[0]);
      console.log("e.target.value", selectedGroup);
    }
  }

  //#endregion

  //#region CONTROL EVENTS

  /** NAVIGATE TO MANAGE GROUPS */
  const navigateToManageGroups = (e, data) => {
    if (data && data.course_stream_id) {
      navigate(navigate_route_config.expert.manage_groups + '/' + id + '/' + data.course_stream_id);
    }
  }

  /** NAVIGATE TO CURRICULUM */
  const navigateToCurriculum = () => {
    navigate(navigate_route_config.expert.curriculum + '/' + id);
  }

  // BACK TO MY COURSES
  const backToMyCourses = (e) => {
    navigate(navigate_route_config.expert.my_courses);
  }

  /** START SESSION */
  const handleStartSession = (e, data) => {
    console.log(data)
    if(data && data.sessionList && data.sessionList.length > 0){
      const completedSessionsList =  data.sessionList.filter(e=>e.memberCourseStreamDetails.length > 0);
      if(completedSessionsList && completedSessionsList.length > 0){
        storeCompletedSessions(completedSessionsList);
      }
    }
    const date = new Date();
    let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
    let startSessionModel = {
      expertid: getCurrentUserData().id.toString(),
      coursestreamdetailid: data.selectedSession.toString(),
      startdatetime: formatedTodayDate
    }
    let temp_current_session = data.sessionList.filter(e => e.id == data.selectedSession)[0];
    localStorage.setItem(storage_configs.localStorage.sessions.current_session_id, temp_current_session.coursesessionid.toString());
    localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, temp_current_session.coursestreamid.toString());
    SessionService.startSessionByExpert(startSessionModel).then(response => {
      if (response && response.success == "true") {
        if (response.data) {
          let tempSlug = response.data.slug;
          navigate('/expert-session/classroom/' + tempSlug);
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, response.data.id);

          //This socket `check-session-started` emits to learner My Courses, to enable Join Session button.
          socket_conection = io(url_configs.breakout_room_server);
          socket_conection.emit("check-session-started", { data: data, status: true }, (error) => {
            if (error) {
              console.log(error);
            }
          });
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  //Get Pod details 
  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      if (id) {
        localStorage.setItem('course_id', id);
        ExpertService.GetPodDetailByCourseId(id, getCurrentUserData().id).then(response => {
          let tempPodList = [];
          if (response.data) {
            response.data.forEach(podObj => {
              let temPodDataobj = {
                course_stream_id: "",
                course_name: "",
                total_sessions: "",
                startdate: "",
                enddate: "",
                completed_sessions: "",
                enrolled_learners: "",
                upcoming_session: {},
                nextsessions: {},
                sessionList: "",
                selectedSession: ""
              }
              temPodDataobj = {
                course_stream_id: podObj.course_stream_id,
                course_name: podObj.course_name,
                total_sessions: podObj.total_session,
                startdate: podObj.startdate,
                enddate: podObj.enddate,
                completed_sessions: podObj.completed_sessions,
                enrolled_learners: podObj.enrolled_learners,
                upcoming_session: podObj.upcoming_session,
                // upcoming_session_name: podObj.upcoming_session.name,
                // upcoming_session_startdate: podObj.upcoming_session.startdate,
                // upcoming_session_from: podObj.upcoming_session.from,
                // upcoming_session_to: podObj.upcoming_session.to,
                sessionList: podObj.courseStreamDetail,
                selectedSession: ""
              }

              if (temPodDataobj.sessionList && temPodDataobj.sessionList.length > 0) {
                let tempSessionDetail = getCompletedSessions(temPodDataobj.sessionList);
                if (tempSessionDetail) {
                  temPodDataobj.completedsessions = tempSessionDetail.completedsessions;
                  temPodDataobj.nextsessions = tempSessionDetail.nextsessions;
                }
              }
              tempPodList.push(temPodDataobj);
            });


          }

          setPodData(tempPodList);
          console.log("Completed Sessions::",tempPodList )

        })
          .catch(e => {
            console.log(e);
          });
        getCurriculum();
      } else {
        navigate(navigate_route_config.expert.my_courses)
      }
    } else {
      localStorage.clear();
      navigate('/expert-login');
    }
  }, []);

  const handleSessionsChange = (i, e) => {
    let tempPodData = [...podData];
    tempPodData[i].selectedSession = e.target.value;
    setPodData(tempPodData)
    SessionService.getPendingSchedules(getCurrentUserData().id, e.target.value).then(response => {
      setSessionSchedules(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getCompletedSessions = (courseStreamDetail) => {
    let completedsessions = 0;
    let nextsessions = [];

    if (courseStreamDetail) {
      for (let i = 0; i < courseStreamDetail.length; i++) {
        if (courseStreamDetail[i].memberCourseStreamDetails && courseStreamDetail[i].memberCourseStreamDetails.length > 0) {
          if (courseStreamDetail[i].memberCourseStreamDetails[0].enddatetime) {
            completedsessions = completedsessions + 1;
          }
        }

        if (courseStreamDetail[i].memberCourseStreamDetails && courseStreamDetail[i].memberCourseStreamDetails.length == 0) {
          nextsessions.push(courseStreamDetail[i]);
        }
      }
    }
    return { completedsessions: completedsessions, nextsessions: nextsessions[0] };
  }

  const handleSessionsClick = (e, i, obj) => {
    let tempPodData = [...podData];
    tempPodData[i].selectedSession = obj.id;
    setPodData(tempPodData)
    SessionService.getPendingSchedules(getCurrentUserData().id, obj.id).then(response => {
      setSessionSchedules(response.data);
      openLastCompletedSessions(e, obj);
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** OPEN GAME SETTINGS POPUP */
  const handleOpenGameSettings = () => {
    setOpenGameSettings(true)
  };
  /** CLOSE GAME SETTINGS POPUP */
  const handleCloseQuizInContentSession = () => setOpenGameSettings(false);

  /** GAME SETTINGS */
  const handleGameSettings = () => {
    let tempJsonObj = {
      userId: JSON.parse(localStorage.getItem('user')).id,
      userType: "teacher",
      name: JSON.parse(localStorage.getItem('user')).name,
      groupId: "100,101",
      setting: 1
    }
    let tempJsonValueList = [];
    tempJsonValueList.push(tempJsonObj);

    let model = {
      jsonvalue: tempJsonValueList
    }
    setOpenGameSettings(true)
    UtilService.encryptJsonValue(model).then(response => {
      if (response && response.data) {
        let tempGameLink = gameConfig.url.gameUrl + response.data.token;
        setGameLink(tempGameLink);
        setOpenGameSettings(true);
      }

    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion

  const convertExpertPodTime = (time) => {
    if (time) {

      // let convertedTime = moment(time, 'HH:mm').tz(timeZone).format('hh:mm a');
      let convertedTime = convertTime(time);
      //let convertedTime =  moment(time, 'HH:mm').tz("America/Los_Angeles").format('hh:mm a');
      //console.log(convertedTime);
      return convertedTime + " " + getTimeZoneShortForm(generateCommonParams(id));
    }
  }


  //#region CHATS
  
  const handleChatSubmit = (e) => {
    console.log('handleChatSubmit', message)
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: chatRoom,
      slug: chatRoom,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "expert"
    }
    if (message) {
      if (chatType == 'group_chat') {
        chat_server_socket.emit("sendMessage", { message: message, group: getOpenedChatGroup(), slug: chatRoom, userId: getCurrentUserData().id, userType: 'expert' });
        chatModel.group = getOpenedChatGroup();
      } else {
        chat_server_socket.emit("sendMessage", { message: message, group: null, slug: chatRoom, userId: getCurrentUserData().id, userType: 'expert' });
      }
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  const chatOptionMenuClickOpen = (event, obj) => {
    setChatOptionEl(event.currentTarget);
    if (obj){
      localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, obj.course_stream_id)
      getCourseGroupDetail(obj.course_stream_id);
    }
    setChatCtrlMenu(true);
  };

  const chatOptionMenuClickClose = () => {
    setChatCtrlMenu(false);
  };

  const openExpertPodGroupChats = (e, obj) => {
    setChatType('group_chat')
    storeOpenedChatGroup(obj);
    chatOptionMenuClickClose();
    const course_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    removeAllChats();
    SetOpenChat(true);
    SessionService.getChatsByStreamId(course_stream_id)
      .then(response => {
        console.log("Chats :::", response);
        if (response && response.data && response.data.length > 0) {
          storeChats(response.data.filter(e => e.group && e.group.coursegroupid == obj.coursegroupid));
        }
        let uName = getCurrentUserData().name;
        let uRoom = obj.groupname;
        setChatRoom(slugify(uRoom));
        chat_server_socket = io(url_configs.chat_server);
        chat_server_socket.emit("join", { name: uName, room: slugify(uRoom) }, (error) => {
          if (error) {
            alert(error);
          }
        });
        chat_server_socket.on("message", (message) => {
          let generalChats = [...messages];
          let storedChats = getChats();
          if (storedChats && storedChats.length > 0) {
            generalChats = storedChats;
          }
          generalChats.push(message)
          setMessages(generalChats);
          storeChats(generalChats);

          if (message && message.user != getCurrentUserData().name) {
            enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

            let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
            if (!tempNofications) {
              tempNofications = [];
            }
            tempNofications.push(message);
            localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
          }

        });
      })
      .catch(error => {
        console.log(error)
      });
  }

  const openExpertPodChats = (e) => {
    setChatType('general_chat')
    chatOptionMenuClickClose();
    const course_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    removeAllChats();
    SetOpenChat(true);
    SessionService.getChatsByStreamId(course_stream_id)
      .then(response => {
        console.log("Chats :::", response);
        if (response && response.data && response.data.length > 0) {
          storeChats(response.data.filter(e=> !e.group));
        }
        joinToGeneralChat(course_stream_id);
      })
      .catch(error => {
        console.log(error)
      });
  }

  const closeChat = (e) => {
    SetOpenChat(false);
    window.location.reload();
  }

  const joinToGeneralChat = (coursestreamid) => {
    //let cId = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    let room = 'pod-' + coursestreamid;
    setChatRoom(room);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit("join", { name: getCurrentUserData().name, room: room }, (error) => {
      if (error) {
        alert(error);
      }
    });

    chat_server_socket.on("message", (message) => {
      if (message.slug == room) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message)
        setMessages(generalChats);
        storeChats(generalChats);

        if (message && message.user != getCurrentUserData().name) {
          enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

          let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
        }
      }
    });

  }

  const onClickEmoji = (emojiData, event) => {
    setSelectedEmoji(emojiData.unified);
    let tempMsg = message;
    //const textAreaElement = document.getElementById('chat-text-area');
    //setMessage(message.substr(0, textAreaElement.selectionStart) +emojiData.emoji  +message.substr(textAreaElement.selectionEnd));
    setMessage(tempMsg + emojiData.emoji)
    handleEmojiControlMenuClose();
  }

  const addChats = (data) => {
    const current_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    data.isInSession = false;
    data.courseStreamId = current_stream_id;
    SessionService.addChats(data)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleEmojiControlMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEmojiCtrlMenu(true);
  };

  const handleEmojiControlMenuClose = () => {
    setEmojiCtrlMenu(false);
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes('data:image/');
      return checkIsImg;
    }
  }

  const renderSwitch = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse()
    }

    return (
      <div className="chat-msg-list-v2" >
        {msgs?.map((val, i) => {
          return (
            <div key={i}>
              <div className="chat-my-msg-main-v2">
                {val.user === getCurrentUserData().name ?
                  <div>
                    <div className="my-name-container-v2">
                      <span className="my-name-v2">Me</span>
                    </div>
                    <div className="chat-my-msg-container-v2">
                      <div className="rvr-msg-container-v2">
                        <span className="rvr-name-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                          <img src={val.text} />}</span>
                      </div></div>
                  </div> :

                  <div className="rvr-container-v2">
                    <div className="rvr-avatar-container">
                      {getInitialsFromFullName(val.user)}
                    </div>
                    <div className="w-100 rvr-msg-container-div">
                      <div className="rvr-name-container-v2">
                        <span className="chat-rvr-name-v2">{val.user}&nbsp;{val?.userType && val?.userType == 'expert' ?'(Expert)':val?.userType && val?.userType == 'admin' ?"(Admin)":""}</span>
                      </div>
                      <div className="chat-rvr-msg-container-v2">
                        <div className="rvr-msg-container-v2">
                          <span className="chat-rvr-msg-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                            <img src={val.text} />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    )
  };

  //#endregion

  //#region PODS HTML DESIGN
  return (
    <div className="pt-5">
      <Container>
        {podData?.map((pod_data, index) => { //Bind kids data into cards
          return (
            <div key={'expert-pods-' + index}>
              {pod_data.course_stream_id ?
                <Grid key={'pod_' + index} container justifyContent="center" className="mt-2">
                  {/* pods_card section */}

                  <Card className="pods_card">
                    <CardContent className="card_pods_text_section">
                      {/* pods_card text section */}
                      <Grid container>
                        <div className="me-5">
                          <h5 className="pod_1_text"> {pod_data.course_name}</h5>
                        </div>
                        <div className="me-5">
                          <label className="pods_label_t_text">START DATE</label>
                          <p className="pods_label_s_t_text m-0">{pod_data.startdate ? (<>{moment(pod_data.startdate).format('MMM D YYYY')}</>) : ("")} </p>
                        </div>
                        <div className="me-5">
                          <label className="pods_label_t_text">END DATE</label>
                          <p className="pods_label_s_t_text m-0">{pod_data.enddate ? (<>{moment(pod_data.enddate).format('MMM D YYYY')}</>) : ("")}</p>
                        </div>
                        <div className="me-5">
                          <label className="pods_label_t_text">TOTAL SESSIONS</label>
                          <p className="pods_label_s_t_text m-0">{pod_data.total_sessions}</p>
                        </div>
                        <Tooltip title={tooltip_messages.back} placement="top">
                          <IconButton className="expert-pods-tour-1" onClick={(e) => backToMyCourses(e)} >
                            <KeyboardArrowLeftIcon />
                          </IconButton></Tooltip>
                      </Grid>
                    </CardContent>
                    {/* pods_card text section end */}

                    {/* no_groups_box section */}
                    {/* <div className="p-2 mt-2">
                      <Box className="no_groups_box">
                        <Grid container>
                          <div>
                            <LightbulbOutlinedIcon
                              sx={{ color: "orange", marginRight: "5px" }}
                            />
                          </div>
                          <div>
                            <span className="no_groups_text">
                              No groups created yet. Create group to manage the session
                              easily.
                            </span>
                          </div>
                        </Grid>
                      </Box>
                    </div> */}
                    {/* no_groups_box section end*/}
                    <div className="p-2 m-0 pb-0">
                      <Box className="pod_box_section_2">
                        <Grid container>
                          <div className="w-100">
                            <div className="row ms-0 me-0 ">
                              <div className="col-6 p-0">
                                <div>
                                  <p className="m-0 pod_box_complete_text">{pod_data.completedsessions ? pod_data?.completedsessions : (0)}&nbsp;/&nbsp;{pod_data?.total_sessions}&nbsp;Complete</p>
                                  <p className="mt-1 mb-1">
                                    <Tooltip title={(pod_data?.completedsessions / pod_data?.total_sessions) * 100 + `% Completed`}>
                                      <LinearProgress
                                        className="my_schedule_linear"
                                        variant="determinate"
                                        value={(pod_data?.completedsessions / pod_data?.total_sessions) * 100}
                                        color="success"
                                        sx={{
                                          height: "10px",
                                          borderRadius: 2,
                                          bgcolor: "#fffff",
                                        }}
                                      /></Tooltip>
                                  </p>
                                  {/* UPCOMING SESSSION */}
                                  {pod_data?.completedsessions !== pod_data.total_sessions ? <div>
                                    <p className="m-0 pod_box_session_text">
                                      (UPCOMING)
                                    </p>
                                    <p className="m-0 pod_box_date_text">
                                      {moment(pod_data?.nextsessions?.startdate).format("dddd")}, {moment(pod_data?.nextsessions?.startdate).format('ll')} {convertExpertPodTime(pod_data?.nextsessions?.from)} - {convertExpertPodTime(pod_data?.nextsessions?.to)}
                                    </p>
                                  </div> : ""}

                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="d-flex justify-content-end">
                                  <div className="expert-pods-tour-2 ms-3">
                                    <Tooltip title={tooltip_messages.expert.openCurriculum}>
                                      <Button className="pod_view_btn" onClick={navigateToCurriculum}>View Curriculum</Button>
                                    </Tooltip>
                                  </div>
                                  {/* pod_data?.selectedSession && sessionSchedules.length == 0 ? */}
                                  {pod_data?.selectedSession && sessionSchedules.length > 0 ?
                                    <div className="ms-3">
                                      <Tooltip title={tooltip_messages.joinSession}>
                                        <Button className="pod_start_btn" onClick={event => handleStartSession(event, pod_data)}>Join session</Button>
                                      </Tooltip>
                                    </div> : pod_data?.selectedSession && sessionSchedules.length == 0 ? <div className="ms-3">
                                      <Tooltip title={tooltip_messages.expert.pods.startSession}>
                                        <Button className="pod_start_btn" onClick={event => handleStartSession(event, pod_data)}>Start session</Button>
                                      </Tooltip>
                                    </div> : ''}
                                </div>
                              </div>
                            </div>
                            <div className="e-pods-streams-container">
                              {pod_data?.sessionList?.map((cSObj, cSIndex) => {
                                return (
                                  <div key={`e-pods-key-` + cSIndex}>
                                    {
                                    // COMPLETED SESSIONS
                                    cSObj?.memberCourseStreamDetails.length > 0 && cSObj?.memberCourseStreamDetails[0].enddatetime ?
                                      <div title={tooltip_messages.sessionCompleted} className="e-pods-streams-boxes e-pods-streams-boxes-completed" onClick={(e) => handleSessionsClick(e, index, cSObj)}>
                                        <div>
                                          <div className="e-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                                          <div className="e-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                                        </div>
                                      </div> :
                                      // CURRENT SESSIONS TO START
                                      cSObj?.memberCourseStreamDetails.length == 0 && pod_data?.nextsessions?.id == cSObj.id ?
                                        <div title={tooltip_messages.startSession} className="e-pods-streams-boxes e-pods-streams-boxes-not-completed " onClick={(e) => handleSessionsClick(e, index, cSObj)}>
                                          <div>
                                            <div className="e-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                                            <div className="e-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                                          </div>
                                        </div> :
                                        // UPCOMING SESSIONS
                                        <div title={tooltip_messages.upcomingSession} className="e-pods-streams-boxes e-pods-streams-boxes-upcoming " onClick={(e) => handleSessionsClick(e, index, cSObj)}>
                                          <div>
                                            <div className="e-pods-start-date">{moment(cSObj?.startdate).format("dddd").slice(0, 3)}</div>
                                            <div className="e-pods-start-date">{moment(cSObj?.startdate).format("MMM Do")}</div>
                                          </div>
                                        </div>
                                    }</div>
                                )
                              })}
                            </div>
                            {/* <Grid item xs={12} sm={12}>
                              <FormControl style={{ width: "100%" }} className="mt-2">
                                <InputLabel
                                  id={"sessions_" + index}
                                  variant="outlined"
                                  margin="dense"
                                >
                                  Sessions
                                </InputLabel>
                                <Select
                                  labelId={"sessions_" + index}
                                  id={"sessions-select-" + index}
                                  name={"sessions-select-name-" + index}
                                  label="Sessions"
                                  margin="dense"
                                  variant="outlined"
                                  onChange={event => handleSessionsChange(index, event)}
                                >
                                  {pod_data.sessionList.map((sObj, sIndex) => {
                                    return (
                                      <MenuItem key={'expert-pods-sessions-'+sIndex} value={sObj.id}>{fDate(sObj.startdate)}({convertExpertPodTime(sObj.from)} - {convertExpertPodTime(sObj.to)})</MenuItem>)
                                  })}
                                </Select>
                              </FormControl>
                            </Grid> */}

                          </div>


                          {/* <div className="ms-3">
                            <Button className="pod_view_btn" onClick={navigateToCurriculum}>View Curriculum</Button>
                          </div>
                          
                          {pod_data.selectedSession && sessionSchedules.length > 0 ? 
                          <div className="ms-3">
                            <Button className="pod_start_btn" onClick={event => handleStartSession(event, pod_data)}>Join session</Button>
                          </div> : pod_data.selectedSession && sessionSchedules.length == 0 ? <div className="ms-3">
                            <Button className="pod_start_btn" onClick={event => handleStartSession(event, pod_data)}>Start session</Button>
                          </div> : ''} */}
                        </Grid>
                      </Box>
                    </div>


                    {/* button section */}
                    <CardActions className="pt-3 pb-3">
                      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                        {/* <Typography className="e-pods-footer-labels">
                          Enrolled Learners
                          <Badge
                            className="badge_Enrolled_btn"
                            badgeContent={pod_data.enrolled_learners}
                            sx={badgeStyle}
                          />
                        </Typography> */}
                        <Typography className="expert-pods-tour-4 e-pods-footer-labels cursor-pointer" onClick={(e) => navigateToManageGroups(e, pod_data)} >
                          Manage Groups - ({pod_data.enrolled_learners}) Learners
                        </Typography>
                        {openGroupSpaceButton ? <Button className="pod_start_btn" onClick={(e) => onGroupSpaceButtonClick()}>Group Space</Button> : ""}
                        <Typography className="expert-pods-tour-3 e-pod-chats" onClick={(e) => chatOptionMenuClickOpen(e, pod_data)}>
                          <IconButton >
                            {getIcon(icons_config.chat)}
                          </IconButton>
                        </Typography>
                      </Stack>

                      {/* <Button size="small">
                        Enrolled Learners{" "}
                        <Badge
                          className="badge_Enrolled_btn"
                          badgeContent={pod_data.enrolled_learners}
                          sx={badgeStyle}
                        />
                      </Button> */}
                      {/* <Button size="small" onClick={(e) => navigateToManageGroups(e, pod_data)}>Manage Groups</Button> */}
                    </CardActions>
                    {/* button section end */}
                  </Card>
                  {/* pods_card section end */}
                </Grid> : ""}
            </div>
          );
        })}

        {!podData.length > 0 ? <div>
          <NoRecordFound noRecordFoundImage={assets.noRecordFound.expertCourses} noRecordFoundText={error_messages.noDataFound}></NoRecordFound>
        </div> : ""}

        {/* {podDatas.length > 0 ? <div>
                <Box display="flex" justifyContent="center" mt={3}>
                  <Pagination
                    count={Math.ceil(podData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handleChange}
                    color="primary"
                  />
                </Box>
              </div> : ""} */}
      </Container>

      {openChat ?
        <div className="e-pods-chat-container">
          <div className="e-pods-chat-main">
            <div className="e-pods-chat-header">
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                <Typography variant="h4">
                  Chats
                </Typography>
                <Typography >
                  <IconButton className="e-pods-chat" onClick={(e) => closeChat(e)}>
                    <img className="cursor-pointer" src={assets.whiteClose} width="16px" height="16px" />
                  </IconButton>
                </Typography>
              </Stack>
            </div>
            <div className="e-pods-chat-body">
              {renderSwitch()}
            </div>
            <div className="e-pods-chat-footer">

              <form className="mt-2 mb-2 chat-box-container-v2" action="" onSubmit={handleChatSubmit}>
                <textarea id="chat-text-area" rows={3} className="form-control send-input-v2" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write message" />
                <div className="input-group mb-3 send-input-box-v2">

                  <span className="pe-2 send-screenshot d-flex" >
                    <img src={assets.emoji} width={23} height={23} className="me-2" onClick={(e) => handleEmojiControlMenuClick(e)} ></img>
                    {/* <span onClick={(e) => sendScreenshot(e)}> {getIcon(icons_config.screenshot)}</span> */}
                  </span>
                  {/* <span className="pe-2 send-screenshot" >
                       
                      </span> */}
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary chat-send-btn" type="submit">
                      <img src={assets.sendChatWhite}></img>
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> : ""}

      {/* EMOJI MENU */}
      <Menu className="emoji-menu"
        anchorEl={anchorEl}
        open={emojiCtrlMenu}
        onClose={handleEmojiControlMenuClose}
      >
        <MenuItem>
          <EmojiPicker
            onEmojiClick={onClickEmoji}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </MenuItem>
      </Menu>

      {/* COMPLETED SESSIONS POPUP */}
      <Dialog
        open={openCompletedSessions}
        onClose={handleCloseCompletedSessionsPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          {"Choose from last three sessions"}
          <span className="add_kids_close_icon" onClick={handleCloseCompletedSessionsPopup}>
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex">
            {lastCompletedSessions?.map((obj, idx) => {
              return (
                <div key={`last-completed-sessions-key-` + obj?.id} onClick={(e) => handleOnSelectCompletedSession(e, obj)}>
                  <div className={`l-pods-streams-boxes l-pods-streams-boxes-width ${selectedCompletedSession && selectedCompletedSession.id == obj.id ? 'e-pods-selected' : 'e-pods-not-selected'}`}>
                    <div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("dddd").slice(0, 3)}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("MMM Do")}</div>
                      <div className="l-pods-start-date">{moment(obj?.startdatetime).format("LT")} - {moment(obj?.enddatetime).format("LT")}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Grid item xs={12} sm={12}>
            <FormControl style={{ width: "100%" }} className="mt-3">
              <InputLabel
                id={"course-groups"}
                variant="outlined"
                margin="dense"
              >
                Groups
              </InputLabel>
              <Select
                labelId={"course-groups"}
                id={"course-groups"}
                name={"course-groups-name"}
                label="Groups"
                margin="dense"
                variant="outlined"
                onChange={event => handleSelectedCourseGroupChange(event)}
              >
                {courseGroups.map((sObj, sIndex) => {
                  return (
                    <MenuItem key={'expert-course-groups-' + sIndex} value={sObj.coursegroupid}>{sObj?.groupname}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog_action">
          <Button variant="contained" className="add_new_quiz_btn" onClick={(e) => handleOpenGroupSpace()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* PLAY GAME POPUP */}
      <Dialog id='game_settings' open={openGameSettings} onClose={handleCloseQuizInContentSession}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              GAME SETTINGS
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseQuizInContentSession}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>

          {/* <div style={container}>
      <div dangerouslySetInnerHTML={{ __html: gameHtmlCode }}></div>
    </div> */}
          <iframe width="500px" height="500px" src={gameLink}></iframe>

        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* chat Option Menu */}
      <Menu
        anchorEl={chatOptionEl}
        open={chatCtrlMenu}
        onClose={chatOptionMenuClickClose}
      >
        <MenuItem id="e-general-chat">
          <Typography variant="inherit" onClick={(e) => openExpertPodChats(e)}>
            General chat
          </Typography>
        </MenuItem>

        {courseGroups.map((sObj, sIndex) => {
          return (
            <MenuItem key={'expert-course-group-chat-' + sIndex} id={"e-group-chat" + sIndex} value={sObj.coursegroupid} onClick={(e) => openExpertPodGroupChats(e, sObj)}>
              <Typography variant="inherit">
                {sObj?.groupname}
              </Typography>
            </MenuItem>)
        })}

      </Menu>

    </div>
  );
  //#endregion
}
