import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Paper, Box, Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate,useParams } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';

//Services
import AuthService from '../../services/auth/auth.service';
import ExpertService from "../../services/expert-service";

import meta_data from "../../config/meta_data";
import LandHeader from "../../components/landing-header/landing-header";

export default function Forgotpassword() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  let { token } = useParams();

  console.log('token='+token);
  
  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),

  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    var inviteUserData = {
      password:data.password,
      token:token
     };
   ExpertService.addExpert(inviteUserData)
   .then(response => {
     if (response && response.success === 'true') {
       // variant could be success, error, warning, info, or default
       enqueueSnackbar('successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
       navigate('/expert-login');
     } else if (response && response.success === 'false') {
       enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
     } else {
       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
     }
   })
   .catch(error => {
    navigate('/expert-login');
     if (error.response) {
       
       enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
       console.log(error.response.data);
       console.log(error.response.status);
       console.log(error.response.headers);
     } else if (error.request) {
      
       console.log(error.request);
       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
     } else {
       // Something happened in setting up the request that triggered an Error
       console.log('Error', error.message);
       enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
     }
   });
  };

  return (
    <div  >
      <LandHeader/>
      <Grid 
        className="form-bg "
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper  className="form-bg-c">
          <Box px={3} py={2}>
          <Typography variant="h5" align="center" margin="dense">
          {meta_data.welcomeText}
          </Typography>
          <Typography align="center" margin="dense">
          Please enter your password
          </Typography>
            {/* FORM */}
            <Grid container spacing={1}>
              {/* Password */}
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="password"
                  name="password"
                  label="Password"
                  fullWidth
                  margin="dense"
                  {...register("password")}
                  error={errors.password ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.password?.message}
                </Typography>
              </Grid>
            </Grid>

            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </div>
  );
}
