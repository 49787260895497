import React from 'react';
//import Header from '../../components/header/header';
// import LandHeader from '../../../components/landing-header/landing-header';
import ExpertLogin from './expert-login-v2';
import meta_data from '../../../config/meta_data';

function App() {
    document.title = meta_data.company +'| Expert Login';
    return (
        <div>
            <ExpertLogin />
        </div>
    );
}

export default App;
