import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Container,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Paper,
  InputBase,
  Stack
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
//ICONS
import Launch from "@mui/icons-material/Launch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";
//ICONS END
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Sampleimg from "../../../assets/img/sample_img.png";
import "./games.css";

import Iconify from '../../../components/Iconify';
//CONFIG
import mimeTypes from "../../../config/mime-types";
import conditional_configs from "../../../config/conditional_config";

//FILE UPLOAD
import DropFileInput from '../../../components/drop-file-input/DropFileInput';

import { getCurrentUserData } from "../../../utils/util";

//SERVICES 
import LookUpService from '../../../services/look-up-service';
import DocumentService from '../../../services/document-service';
import AdminService from '../../../services/admin-service';

import { SnackbarProvider, useSnackbar } from 'notistack';

export default function Games() {
  const { enqueueSnackbar } = useSnackbar();
  const [gamesList, setGamesList] = useState([]);
  const [selectedGameId, setSelectedGameId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const [game_card_icon_btn, setGame_card_icon_btn] = React.useState(null);
  const open_select = Boolean(game_card_icon_btn);

  const [open, setOpen] = React.useState(false);

  const [gameIcon, setGameIcon] = React.useState("");
  const [gameFile, setGameFile] = React.useState("");

  const [fileUploading, setFileUploading] = React.useState(false);

  const navigate = useNavigate();

  /** VALIDATION SCHEMA */
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Game name is required"),
    port: Yup.string().required("Port number is required"),
    experturl: Yup.string().required("Expert url is required"),
    playurl: Yup.string().required("Play url number is required"),
    description: Yup.string()
  });

  const {
    register,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      AdminService.getAllActiveGames().then(response => {
        setGamesList(response.data);
        console.log(response.data);
      })
        .catch(e => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  //#region CATEGORY DDLISTS API
  const getCourseCategories = () => {
    console.log('Get Categories');
    LookUpService.GetCourseCategory().then(response => {
      console.log(response.data)
      setCategoryList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion

  //#region GAMES LIST CARD DESIGN EVENTS
  const card_icon_open = (gameid, event) => {
    console.log(gameid);
    setSelectedGameId(gameid);
    setGame_card_icon_btn(event.currentTarget);
  };

  const card_icon_close = () => {
    setGame_card_icon_btn(null);
  };
  //#endregion

  //#region CREATE/UPDATE GAME POPUP EVENTS
  const handleClickOpen = () => {
    getCourseCategories();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoriesChange = (cat_data) => {
    let categories = [];
    cat_data.forEach(element => {
      let categoryObj = {
        categoryid: element.id,
        name: element.name
      }
      categories.push(categoryObj);
    });
    setSelectedCategories(categories);
  };

  const uploadGameIcon = (files) => {
    console.log(files);
    if (files && files.length > 0) {
      var file = files[0];
      DocumentService.upload_document(file).then(response => {
        console.log('File Uploaded Successfully');
        console.log(response);
        setGameIcon(response.data.id);
      })
        .catch(e => {
          console.log(e);
        });
      console.log(files)
    }
  }

  const uploadGameFile = (files) => {
    console.log(files);
    setFileUploading(true);
    if (files && files.length > 0) {
      var file = files[0];
      DocumentService.upload_document(file).then(response => {
        console.log('File Uploaded Successfully');
        console.log(response);
        setFileUploading(false);
        setGameFile(response.data.id);
      })
        .catch(e => {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(e);
        });
      console.log(files)
    }
  }

  const deployGame = (gameDet) => {
    console.log(gameDet);
    if (gameDet)
      console.log(gameDet.id);
    AdminService.deployGame(gameDet.id)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Game deployed Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }

      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }
  //#endregion

  /** Add New Game */
  const onSubmit = (data) => {
    console.log(data);
    let insertGameModle = {
      name: data.name,
      category: selectedCategories,
      icon_documentid: gameIcon.toString(),
      gamefile_documentid: gameFile.toString(),
      description: data.description,
      port: data.port,
      experturl: data.experturl,
      playurl: data.playurl
    }
    console.log(insertGameModle);
    AdminService.uploadGames(insertGameModle)
      .then(response => {

        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Game uploaded Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          handleClose();
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }

      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  /** Delete Game */
  const deleteGame = () => {
    card_icon_close();
    console.log(selectedGameId);
    if (selectedGameId)
      AdminService.deleteGame(selectedGameId)
        .then(response => {
          if (response && response.success === 'true') {
            // variant could be success, error, warning, info, or default
            enqueueSnackbar('Game deleted Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            window.location.reload();
          } else if (response && response.success === 'false') {
            enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }

        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data) {
              enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              console.log(error.response);
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        });
  }

  return (
    <div>
      <Container>
        {/* GAME section */}
        <Box sx={{ width: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
            <Typography variant="h4" gutterBottom className='mb-0'>
              Games
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
              New Game
            </Button>
          </Stack>
          <div class="d-flex align-items-center  mb-3">
            <Paper
              component="form"
              className="common-search-box"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search by Course Name"
                inputProps={{ "aria-label": "search br course name" }}
              />
            </Paper>
          </div>
        </Box>
        {/* GAME section end*/}
        <div>
          {/* Gmae card section */}
          {gamesList.map((game_data, index) => {
            return (
              <ul className="horizontal-list list-group-item">
                <li>
                  <Card className="games_card">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div class="game_img">
                          <img src={game_data?.icondocumentid?.path} alt={game_data?.icondocumentid?.originalname} />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                        <h5 className="card_game_title">
                          {game_data.name}
                        </h5>
                        <Stack direction="row" spacing={1}>
                          {game_data?.puzzlecategory.map((game_cat_data, game_cat_index) => {
                            return <h6 className="card_game_sub_title">
                              {game_cat_data.categoryid}
                            </h6>
                          }
                          )}
                        </Stack>
                        <div class="d-flex align-items-center  mt-3">
                          <Stack className="align-items-center" direction="row" spacing={1}>

                            <Button
                              onClick={(e) => deployGame(game_data)}
                              className="deploy_game_btn"
                            >
                              <Launch />Deploy
                            </Button>
                          </Stack>

                          <span
                            className="ms-auto game_dot_icon"
                            onClick={(e) => card_icon_open(game_data.id, e)}
                          >
                            <MoreVertIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </li>
              </ul>
            );
          })}

          {/* Game card section end */}

          {/* IconButton */}
          <Grid container justifyContent="center">
            <div>
              <Menu
                id="select_file_btn_menu"
                MenuListProps={{
                  "aria-labelledby": "select_file_btn",
                }}
                anchorEl={game_card_icon_btn}
                open={open_select}
                onClose={card_icon_close}
                TransitionComponent={Fade}
              >
                {/* <MenuItem onClick={(e)=>deployGame(game_data)}>
                  <Launch style={{ marginRight: "10" }} /> Deploy
                </MenuItem> */}

                <MenuItem onClick={deleteGame}>
                  <DeleteIcon style={{ marginRight: "10" }} /> Delete
                </MenuItem>
              </Menu>
            </div>
          </Grid>
          {/* IconButton */}
        </div>
      </Container>
      {/* Dialog */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"New Game"}
            <span className="add_kids_close_icon" onClick={handleClose}>
              <CloseIcon />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* FORM SECTION */}

            <Grid container spacing={1}>
              {/* GAME NAME TEXT FIELD */}
              <Grid item xs={12} sm={12} className="">
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Game Name"
                  fullWidth
                  margin="dense"
                  {...register("name")}
                  error={errors.name ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.name?.message}
                </Typography>
              </Grid>
              {/* Select Categories */}
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="categories"
                  multiple
                  filterSelectedOptions
                  options={categoryList}
                  getOptionLabel={option => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, values) => handleCategoriesChange(values)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )
                  }
                />
              </Grid>
              {/* Upload Game Icon */}
              <Grid item xs={12} sm={12}>
                <Typography variant="inherit" color="textSecondary">
                  Upload Game Icon
                </Typography>
                <DropFileInput fileTypes={mimeTypes.png + ',' + mimeTypes.jpeg}
                  onFileChange={(files) => uploadGameIcon(files)}
                />
              </Grid>
              {/* Upload Game File */}
              <Grid item xs={12} sm={12} className="mt-2">
                <Typography variant="inherit" color="textSecondary">
                  Upload Game File
                </Typography>
                <DropFileInput fileTypes={mimeTypes.zip}
                  onFileChange={(files) => uploadGameFile(files)}
                />
              </Grid>

              {/* PORT TEXT FIELD */}
              <Grid item xs={12} sm={12} className="">
                <TextField
                  required
                  type="number"
                  id="port"
                  name="port"
                  label="Port"
                  fullWidth
                  margin="dense"
                  {...register("port")}
                  error={errors.port ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.port?.message}
                </Typography>
              </Grid>
              {/* EXPERT URL TEXT FIELD */}
              <Grid item xs={12} sm={12} className="">
                <TextField
                  required
                  id="experturl"
                  name="experturl"
                  label="Expert Url"
                  fullWidth
                  margin="dense"
                  {...register("experturl")}
                  error={errors.experturl ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.experturl?.message}
                </Typography>
              </Grid>
              {/* PLAY URL TEXT FIELD */}
              <Grid item xs={12} sm={12} className="">
                <TextField
                  required
                  id="playurl"
                  name="playurl"
                  label="Play Url"
                  fullWidth
                  margin="dense"
                  {...register("playurl")}
                  error={errors.playurl ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.playurl?.message}
                </Typography>
              </Grid>
              {/* GAME DESCRIPTION FIELD */}
              <Grid item xs={12} sm={12} className="mt-2">
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  {...register("description")}
                  margin="dense"
                />
              </Grid>
            </Grid>

            {/* FORM SECTION END*/}
          </DialogContent>
          <DialogActions>
            {!fileUploading ? <Button
              onClick={handleSubmit(onSubmit)}
              className="games_save_btn mb-3"
            >
              Submit
            </Button> :
              <Button
                className="games_save_btn_disabled mb-3"
              >
                Game file is uploading...
              </Button>}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
