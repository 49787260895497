const tour_Manager_Steps = {
    expertTourSteps: [
        {
            disableBeacon: true,
            target: '.profile-tour-1',
            content: 'Profile Details and Logout',
        },
        {
            target: '.step-2',
            content: 'My Courses',
        },
        {
            target: '.step-3',
            content: 'My Courses List',
        },
        {
            target: '.step-4',
            content: 'Pods Section',
        },
        {
            target: '.step-5',
            content: 'Curriculum Section',
        },
        {
            target: '.step-6',
            content: 'Course Informations',
        },
    ],
    learnerTourSteps: [
        {
            disableBeacon: true,
            target: '.profile-tour-1',
            content: 'Profile Details and Logout',
        },
        {
            target: '.step-2',
            content: 'My Courses',
        },
        {
            target: '.step-3',
            content: 'My Courses List',
        },
        {
            target: '.step-4',
            content: 'Group Space Section',
        },
        {
            target: '.step-5',
            content: 'Course Informations',
        },
    ],
    learnerPodsTourSteps: [
        {
            disableBeacon: true,
            target: '.learner-pods-tour-1',
            content: 'Chats',
        },
        {
            target: '.learner-pods-tour-2',
            content: 'Back Button',
        },
        {
            target: '.learner-pods-tour-3',
            content: 'Sessions',
        },

    ],
    expertPodsTourSteps: [
        {
            disableBeacon: true,
            target: '.expert-pods-tour-1',
            content: 'Back Button',
        },
        {
            target: '.expert-pods-tour-2',
            content: 'View Curriculum Sessions',
        },
        {
            target: '.expert-pods-tour-3',
            content: 'Chats',
        },
        {
            target: '.expert-pods-tour-4',
            content: ' Manage Groups',
        },

    ],

    //parent Courses Tour
    parentCoursesTourSteps: [
        {
            disableBeacon: true,
            target: '.profile-tour-1',
            content: 'Profile Details and Logout',
        },
        {
            target: '.parent-course-tour-2',
            content: 'Inprogress and Completed Tabs',
        },
        {
            target: '.parent-course-tour-3',
            content: 'Courses List',
        },
        {
            target: '.parent-course-tour-4',
            content: 'Add Learner Button',
        },
        {
            target: '.parent-course-tour-5',
            content: 'Assigned Learners Details',
        },
    ],
    //parent Kids Tour
    parentKidsTourSteps: [
        {
            disableBeacon: true,
            target: '.profile-tour-1',
            content: 'Profile Details and Logout',
        },
        {
            target: '.parent-kids-tour-2',
            content: 'My Kids list',
        },
        {
            target: '.parent-kids-tour-3',
            content: 'Add Kids Button',
        },

    ],
    //parent Other Courses
    parentOtherCoursesTourSteps: [
        {
            disableBeacon: true,
            target: '.profile-tour-1',
            content: 'Profile Details and Logout',
        },
        {
            target: '.parent-other-course-tour-2',
            content: 'Search by Courses Name',
        },
        {
            target: '.parent-other-course-tour-3',
            content: 'Other Courses Details List',
        },
    ],
}

export default tour_Manager_Steps;

