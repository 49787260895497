import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

//Services
import AuthService from '../../services/auth/auth.service';

//Config
import roleConfig from '../../config/conditional_config';

//Utils
import { getCurrentUserData } from '../../utils/util';




// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
    role: roleConfig.role.learner
  },
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
    role: roleConfig.role.parent
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/expert/my-profile',
    role: roleConfig.role.expert
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
    role: roleConfig.role.admin
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/parent/my-profile',
    role: roleConfig.role.parent
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/learner/my-profile',
    role: roleConfig.role.learner
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const zoomClient = useSelector((state) => state.zoom.zoomClient);
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const navigate = useNavigate();

  const [currentRole, setCurrentRole] = useState();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logOut = () => {
    if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
      navigate('/admin-login');
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
      if (zoomClient && zoomClient.stream) {
        navigate('/expert-login');
        zoomClient.leave(true);
      } else {
        navigate('/expert-login');
      }
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
      if (zoomClient && zoomClient.stream) {
        navigate('/');
        zoomClient.leave(true);
      } else {
        navigate('/');
      }
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
      navigate('/');
    } else {
      navigate('/admin-login');
    }
    setOpen(null);
    AuthService.logout();
  };

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem('user')) != null ? JSON.parse(localStorage.getItem('user')).role : null;
    if (!role) {
      role = 0;
    }

    setCurrentRole(role);
  }, []);




  return (
    <>


      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        style={{ background: 'none' }}
        sx={{
          p: 0,
          // ...(open && {
          //   '&:before': {
          //     zIndex: 1,
          //     content: "''",
          //     width: '100%',
          //     height: '100%',
          //     borderRadius: '50%',
          //     position: 'absolute',
          //     bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
          //   },
          // }),
        }}
      >
        <span style={
          {
            color: 'black',
            fontSize: '14px',
            fontWeight: '600',
            marginRight: '8px',
            cursor: 'pointer'
          }}>
          {account.displayName}
        </span>
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>

          {getCurrentUserData().role === roleConfig.role.parent && getCurrentUserData().status_id === 3 ? (
            <p className="active-your-account-v4">Verify and activate your account in the profile page.</p>
          ) : ""}

        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option, index) => (
            <div key={'menus-options-' + index}>
              {option.role == currentRole ?
                <MenuItem key={'m' + index} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                  {option.label}
                </MenuItem>
                : ""}
            </div>
          ))}
          <Divider sx={{ borderStyle: 'dashed' }} />
          <MenuItem onClick={logOut}>
            Logout
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
