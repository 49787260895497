import React, { useState, useEffect } from "react";
import { Badge, Container, Card, Typography } from "@mui/material";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { filter } from "lodash";
import ShareLicensesService from "../../../services/share-licenses-service";
import { getCurrentUserData } from "../../../utils/util";
import SearchNotFound from "../../../components/SearchNotFound";
import Scrollbar from "../../../components/Scrollbar";
import conditional_configs from "../../../config/conditional_config";

export default function ShareLicensesDetails(props) {
  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role === conditional_configs.role.parent
    ) {
      console.log(getCurrentUserData().id);
      ShareLicensesService.getShareLicensesDetailsById(getCurrentUserData().id)
        .then((response) => {
          console.log(response);
          setShareLicensesDetails(response);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (
      getCurrentUserData() &&
      getCurrentUserData().role === conditional_configs.role.admin
    ) {
      ShareLicensesService.getAllShareLicensesDetails()
        .then((response) => {
          console.log(response);
          setShareLicensesDetails(response);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("courseName");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [shareLicensesDetails, setShareLicensesDetails] = useState([]);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - shareLicensesDetails?.length)
      : 0;

  const filteredUsers = filter(
    shareLicensesDetails,
    (_user) =>
      !_user.courseName ||
      _user.courseName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    // Sort the data
    const sortedData = [...shareLicensesDetails].sort((a, b) => {
      if (property === "courseName" || property === "email") {
        return isAsc
          ? a[property].localeCompare(b[property])
          : b[property].localeCompare(a[property]);
      } else {
        return isAsc ? a[property] - b[property] : b[property] - a[property];
      }
    });

    setShareLicensesDetails(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ height: "100vh" }}>
      <div>
        <Container>
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
              mb={3}
            >
              <Typography variant="h4" gutterBottom className="mb-0">
                Shared Licenses
                <Badge
                  className="badge_quiz"
                  badgeContent={shareLicensesDetails?.length}
                  color="primary"
                />
              </Typography>
            </Stack>
          </Box>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Course Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Receiver Email
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Licenses
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const { courseName, email, status, licenses } = row;
                        return (
                          <TableRow key={index}>
                            <TableCell>{courseName}</TableCell>
                            <TableCell>{email}</TableCell>
                            <TableCell>
                              {status === 1 ? "invited" : "accepted"}
                            </TableCell>
                            <TableCell>{licenses}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                    {isUserNotFound && (
                      <TableRow>
                        <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={shareLicensesDetails?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </div>
    </div>
  );
}
