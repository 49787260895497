import axios from "axios";
import url_configs from "../config/url";

// export default axios.create({
//   baseURL: "http://54.144.210.42:4000/api/v1",
//   headers: {
//     "Content-type": "application/json",
//   }
// });

export default axios.create({
  baseURL: url_configs.api,
  headers: {
    "Content-type": "application/json",
  }
});
