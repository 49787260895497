import React, { useState, useEffect, useRef } from "react";
//import { BsFileEarmarkText } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from '../../../../store/slices/settingsSlice'
import courseFile from '../../../../assets/files/what-is-robotics.pdf'
import './course-view.css'

import WhiteboardTopics from "../../whiteboard-topics/whiteboard-topics";
//import WebViewer from '@pdftron/webviewer';

// import CommonWebViewer from "../../web-viewer/web-viewer";

//import s from '../../../assets/test_doc.pdf';

//CONFIGS
import storage_configs from "../../../../config/storage-config";
import conditional_configs from "../../../../config/conditional_config";

function CourseView(props) {
    const viewer = useRef(null);

    const dispatch = useDispatch();
    const selectedContent = useSelector((state) => state.settings.selectedContent);
    const selectedCurriculamType = useSelector((state) => state.settings.selectedCurriculamType);
    const shareScreenStatus = useSelector((state) => state.zoom.shareScreenStatus);
    const [showContent, setShowContent] = React.useState(selectedContent);
    const [testDoc, setTestDoc] = React.useState('https://docs.google.com/viewer?url=https://test.document.meeku.world/1683635532351-sample_ppt_file.pptx&embedded=true');
    const setSidebarMenu = (menu) => {
        dispatch(setMenu(menu));
    };

    useEffect(() => {
    }, []);

    const onClickWhiteboard = () => {
        alert("Whiteboard is locked.")
    }
    const checkIsEmdedCode = (content) => {
        if (content) {
            const checkStr = content.slice(0, 4);
            if (checkStr == 'http') {
                return false;
            } else {
                return true;
            }
        }
        return false
    }
    return (
        <div className="course-view-container-v2">
            {/* {shareScreenStatus? <video id="course-view-video" width={'100%'} height={'100%'}></video> : <iframe className={props.class} src={courseFile} width="100%" height="100%">
            </iframe>} */}
            {/* {selectedContent} */}
            {shareScreenStatus ?
                <video className="border-radius-20" id="course-view-video" width={'100%'} height={'100%'}></video> : ""}
            {selectedCurriculamType == conditional_configs.curriculam_types.content && selectedContent ?
                // <CommonWebViewer selectedFile={'https://test.document.meeku.world/1683635532351-sample_ppt_file.pptx'} />
                <div className="height-100">
                    {
                        checkIsEmdedCode(selectedContent) ? <div className="embed-code-style-v2 border-radius-20" dangerouslySetInnerHTML={{ __html: selectedContent }}></div> :
                            <iframe className={props.class+' border-radius-20'} src={selectedContent} width="100%" height="100%">
                            </iframe>
                            // <CommonWebViewer document={selectedContent} />
                    }
                </div>
                //    <iframe className={props.class} src={selectedContent} width="100%" height="100%">
                //    </iframe>
                // <iframe className={props.class} src={`https://view.officeapps.live.com/op/view.aspx?src=${'https://test.document.meeku.world/1683635532351-sample_ppt_file.pptx'}`} width="100%" height="100%" frameBorder="0">
                // </iframe>
                // <iframe className={props.class} src={getBlob(`https://view.officeapps.live.com/op/view.aspx?src=${'https://test.document.meeku.world/1683635532351-sample_ppt_file.pptx'}`)} width="100%" height="100%" frameBorder="0">
                // </iframe>
                // <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSJXqWlRZ6FMkMSdvW1MQXSQ5bNMIvpkVaqnLU_pnqtSpbD8yqwEJwJ8SO0uAaMUlSTJG1dFUQT_jdl/embed?start=false&loop=false&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                :
                selectedCurriculamType == conditional_configs.curriculam_types.whiteboard && selectedContent ?
                    <div className="height-100">
                        {/* <div className='whiteboard-locked-morph-div-v2 border-radius-20' onClick={() => onClickWhiteboard()}>
                        </div> */}
                        {/* <iframe className="content-section-iframe" src={documentimg} /> */}
                        {/* <iframe className={props.class + ' content-section-iframe-v2 height-100'} src={selectedContent}></iframe> */}
                        <WhiteboardTopics ></WhiteboardTopics>
                    </div>
                    : ""
            }
        </div>
    );
}

export default CourseView;