import React from "react";
import { Box, Card } from "@mui/material";
import assets from "../../config/assets";
import "./testimonial.css";

export default function Testimonial() {
  return (
    <div>
      <Box className="">
        <div className="testimonial-container">
          <div className="testimonial-section">
            <p
              className="testimonial-section-title-com-white-color m-0"
              style={{ lineHeight: "120%" }}
            >
              Real<strong> Parents </strong>Speak
            </p>
          </div>

          <img
            className="testimonial_star_img"
            src={assets.homev3.section3_star_img}
          ></img>
          {/* large-screen-section-7 */}
          <div className="large-screen-testimonial bd-example">
            <div
              id="carouselExampleCaptions1"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <ol className="carousel-indicators carousel-indicators-Parents-sec">
                <li
                  data-bs-target="#carouselExampleCaptions1"
                  data-bs-slide-to="0"
                  className="active"
                ></li>
                <li
                  data-bs-target="#carouselExampleCaptions1"
                  data-bs-slide-to="1"
                ></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container pb-5">
                    <div className="row ms-0 me-0 mt-5">
                      <div className="col-lg-6">
                        <Card className="Parents-card-sec-7 mb-3">
                          <Box>
                            <p className="Parents-sec-text-title">
                              I never knew learning could be this much fun
                            </p>
                            <p className="Parents-sec-text">
                              My kids absolutely love using Meeku! It's like a
                              fun playground for learning where they can explore
                              science and technology together. They're not just
                              learning STEM subjects, but also how to work as a
                              team. It's a win-win for parents and kids!
                            </p>
                            <div className=" mt-4">
                              <p className="Parents-name-text mb-0">Siphoria</p>
                              <p className="Parents-name-c-text">California</p>
                            </div>
                          </Box>
                        </Card>
                      </div>
                      <div className="col-lg-6">
                        <Card className="Parents-card-sec-7 mb-3">
                          <Box>
                            <p className="Parents-sec-text-title">
                              The kids are actually looking forward to learning
                              - I can’t believe it!
                            </p>
                            <p className="Parents-sec-text mt-4">
                              As a parent, I'm thrilled with this Meeku
                              platform. It keeps my 10-year-old engaged and
                              excited about learning. Their fun approach to
                              learning is brilliant. It's like they're having a
                              blast while becoming little scientists and
                              engineers. I highly recommend Meeku to other
                              parents!
                            </p>
                            <div className=" mt-4">
                              <p className="Parents-name-text mb-0">
                                Thomas Knoll
                              </p>
                              <p className="Parents-name-c-text">Dallas</p>
                            </div>
                          </Box>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="multi_union_w_icon"
            src={assets.homev3.multi_union_w_icon}
          ></img>
          <div className="d-flex justify-content-end">
            <img className="m_end_icon" src={assets.homev3.m_end_icon}></img>
          </div>

          {/* mobile-screen-section-7 */}
          <div className="mobile-screen-testimonial bd-example">
            <div
              id="carouselExampleCaptions2"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <ol className="carousel-indicators carousel-indicators-Parents-sec">
                <li
                  data-bs-target="#carouselExampleCaptions2"
                  data-bs-slide-to="0"
                  className="active"
                ></li>
                <li
                  data-bs-target="#carouselExampleCaptions2"
                  data-bs-slide-to="1"
                ></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container pb-5">
                    <div className="row ms-0 me-0 mt-5">
                      <div className="col-lg-6">
                        <Card className="Parents-card-sec-7 mb-3">
                          <Box>
                            <p className="Parents-sec-text-title">
                              I never knew learning could be this much fun
                            </p>
                            <p className="Parents-sec-text">
                              My kids absolutely love using Meeku! It's like a
                              fun playground for learning where they can explore
                              science and technology together. They're not just
                              learning STEM subjects, but also how to work as a
                              team. It's a win-win for parents and kids!
                            </p>
                            <div className=" mt-4">
                              <p className="Parents-name-text mb-0">Siphoria</p>
                              <p className="Parents-name-c-text">California</p>
                            </div>
                          </Box>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container pb-5">
                    <div className="row ms-0 me-0 mt-5">
                      <div className="col-lg-6">
                        <Card className="Parents-card-sec-7 mb-3">
                          <Box>
                            <p className="Parents-sec-text-title">
                              The kids are actually looking forward to learning
                              - I can’t believe it!
                            </p>
                            <p className="Parents-sec-text mt-4">
                              As a parent, I'm thrilled with this Meeku
                              platform. It keeps my 10-year-old engaged and
                              excited about learning. Their fun approach to
                              learning is brilliant. It's like they're having a
                              blast while becoming little scientists and
                              engineers. I highly recommend Meeku to other
                              parents!
                            </p>
                            <div className=" mt-4">
                              <p className="Parents-name-text mb-0">
                                Thomas Knoll
                              </p>
                              <p className="Parents-name-c-text">Dallas</p>
                            </div>
                          </Box>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
