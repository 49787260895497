import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { fDate } from "../../../utils/formatTime";

import "./my-schedule.css";
import io from "socket.io-client";

import Box from "@mui/material/Box";
import { Button, Container, Grid, } from "@mui/material";
import Card from "@mui/material/Card";

import NoRecordFound from '../../../components/no-record-found/no-record-found';


import Sampleimg from "../../../assets/img/sample_img.png";

import { LinearProgress } from "@mui/material";
import { getCurrentUserData, getTimeZoneShortForm } from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";

//SERVICE
import ExpertAPIService from '../../../services/expert-service';
import SessionService from "../../../services/session-service";


//CONFIG
import storage_configs from "../../../config/storage-config";
import assets from '../../../config/assets';
import error_messages from '../../../config/error_messages';
import url_configs from '../../../config/url';

let socket_conection;

export default function MySchedule() {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const navigate = useNavigate();
  const [Schedule_data, setSchedule_data] = useState([]);
  const [filteredScheduleData, setFilteredScheduleData] = useState([]);
  const [Schedule_date, setSchedule_date] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState(1);

  // const date =new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short',day: '2-digit',}).format(today);

  //GET TODAYS SESSION WHILE PAGE LOAD
  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      // setSchedule_date(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit', }).format(Date.now()));
      handleScheduleClick(conditional_configs.scheduleList[1])
      // ExpertAPIService.GetThisMonthSchedule(user_data.id).then(response => {
      //   //setSchedule_data(response.data);
      //   if (response && response.data) {
      //     let scheduleData = []
      //     response.data.forEach(element => {
      //       SessionService.getPendingSchedules(getCurrentUserData().id, element.coursestreamdetailid).then(res => {
      //         console.log("getPendingSchedules", res.data);
      //         if (res && res.data && res.data.length > 0) {
      //           element.schedule = res.data;
      //           scheduleData.push(element);
      //         }
      //       })
      //         .catch(e => {
      //           console.log(e);
      //         });
      //     });

      //     setTimeout(() => {
      //       setSchedule_data(scheduleData);
      //     }, 3000);
      //     console.log(scheduleData)
      //   }
      //   // setSchedule_date((date.now().toLocaleDateString()));
      //   // const today = Date.now();
      //   // setSchedule_date(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit', }).format(Date.now()))
      //   //console.log(response.data)
      //   console.log('Today', response.data)
      // })
      //   .catch(e => {
      //     console.log(e);
      //   });
    }
    else {
      localStorage.clear();
      navigate('/expert-login');
    }
  }, []);

const handleScheduleClick=(obj)=>{
  if(obj){
    setSelectedSchedule(obj.id);
    if(obj.id == 1){
      todaysSchedule();
    }else if(obj.id == 2){
      tommorowsSchedule();
    }else if(obj.id == 3){
      thisWeekSchedule();
    }else if(obj.id == 4){
      thisMonth();
    }else{
      getAllScheduledClasses();
    }
  }
}
  //GET TODAYS SCHEDULE
  const todaysSchedule = () => {
    setSchedule_date(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit', }).format(Date.now()));
    ExpertAPIService.GetTodaySchedule(getCurrentUserData().id).then(response => {
      setSchedule_data(response.data);
      setFilteredScheduleData(response.data.filter(e=>e.memberCourseStreamDetails.length == 0))
    })
      .catch(e => {
        console.log(e);
      });
  }

  //GET TOMMOROWS SCHEDULE
  const tommorowsSchedule = () => {
    ExpertAPIService.GetTommorowsSchedule(getCurrentUserData().id).then(response => {
      setSchedule_data(response.data);
      setFilteredScheduleData(response.data.filter(e=>e.memberCourseStreamDetails.length == 0))
    })
      .catch(e => {
        console.log(e);
      });
  }

  //GET THIS WEEK SCHEDULE
  const thisWeekSchedule = () => {
    ExpertAPIService.GetThisWeekSchedule(getCurrentUserData().id).then(response => {
      setSchedule_data(response.data);
      setFilteredScheduleData(response.data.filter(e=>e.memberCourseStreamDetails.length == 0))
    })
      .catch(e => {
        console.log(e);
      });

  }

  //GET THIS MONTH SCHEDULE
  const thisMonth = () => {
    ExpertAPIService.GetThisMonthSchedule(getCurrentUserData().id).then(response => {
      setSchedule_data(response.data);
      setFilteredScheduleData(response.data.filter(e=>e.memberCourseStreamDetails.length == 0))
    })
      .catch(e => {
        console.log(e);
      });
  }

  //GET ALL SCHEDULE
  const getAllScheduledClasses = () => {
    ExpertAPIService.getAllScheduledClasses(getCurrentUserData().id).then(response => {
      setSchedule_data(response.data);
      setFilteredScheduleData(response.data.filter(e=>e.memberCourseStreamDetails.length == 0))
      console.log(response.data)
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** START SESSION */
  // const handleStartSession = (e, data, schedata) => {
  //   // const date = new Date();
  //   // let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
  //   // let startSessionModel = {
  //   //   expertid: getCurrentUserData().id.toString(),
  //   //   coursestreamdetailid: data.selectedSession.toString(),
  //   //   startdatetime: formatedTodayDate
  //   // }
  //   // console.log(startSessionModel);
  //   //let temp_current_session = data.sessionList.filter(e => e.coursestreamdetailid == data.selectedSession)[0];
  //   //console.log(temp_current_session);
  //   localStorage.setItem(storage_configs.localStorage.sessions.current_session_id, data.sessionid.toString());
  //   localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, data.coursestreamid.toString());
  //   localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, schedata.id);
  //   navigate('/expert-session/classroom/' + schedata.slug);
  //   // SessionService.startSessionByExpert(startSessionModel).then(response => {
  //   //   console.log(response);
  //   //   if (response && response.success == "true") {
  //   //     if (response.data) {
  //   //       let tempSlug = response.data.slug;
  //   //       navigate('/expert-session/classroom/' + tempSlug);
  //   //       localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, response.data.id);

  //   //       //This socket `check-session-started` emits to learner My Courses, to enable Join Session button.
  //   //       socket_conection = io(url_configs.breakout_room_server);
  //   //       socket_conection.emit("check-session-started", { data: data, status: true }, (error) => {
  //   //         if (error) {
  //   //             console.log(error);
  //   //         }
  //   //     });
  //   //     }
  //   //   }
  //   // })
  //   // .catch(e => {
  //   //   console.log(e);
  //   // });
  // }

  /** START SESSION */
  const handleStartSession = (e, data) => {
    const date = new Date();
    let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
    let startSessionModel = {
      expertid: getCurrentUserData().id.toString(),
      coursestreamdetailid: data.coursestreamdetailid.toString(),
      startdatetime: formatedTodayDate
    }
    localStorage.setItem(storage_configs.localStorage.sessions.current_session_id, data.sessionid.toString());
    localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, data.coursestreamid.toString());
    SessionService.startSessionByExpert(startSessionModel).then(response => {
      if (response && response.success == "true") {
        if (response.data) {
          let tempSlug = response.data.slug;
          navigate('/expert-session/classroom/' + tempSlug);
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, response.data.id);

          //This socket `check-session-started` emits to learner My Courses, to enable Join Session button.
          socket_conection = io(url_configs.breakout_room_server);
          socket_conection.emit("check-session-started", { data: data, status: true }, (error) => {
            if (error) {
              console.log(error);
            }
          });
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  // const convertTime = (time) => {
  //   if (time) {
  //     let convertedTime = moment(time, 'HH:mm').format('hh:mm a');
  //     return convertedTime;
  //   }
  // }

  const convertTime = (time) => {
    if (time) {
      let convertedTime = moment(time, 'HH:mm').tz(timeZone).format('hh:mm a');
      return convertedTime + " " + getTimeZoneShortForm();
    }
  }

  const getCompletedPercentage = (data)=>{
    var completedSessionCount = 0;
    if(data){
      const tempAllSessionList = Schedule_data;
      const tempFilteredSession = tempAllSessionList.filter(e=>e.coursestreamid == data.coursestreamid);
      if(tempFilteredSession && tempFilteredSession.length > 0){
       const checkMemberCourseDetails = tempFilteredSession.filter(e=>e.memberCourseStreamDetails.length > 0);
       completedSessionCount = completedSessionCount + checkMemberCourseDetails.length;
       return (completedSessionCount/data.total_sessions) * 100;
      }else{
        return completedSessionCount;
      }
    }
    return completedSessionCount;
  }

  const getCompletedSessionsCount = (data)=>{
    var completedSessionCount = 0;
    if(data){
      const tempAllSessionList = Schedule_data;
      const tempFilteredSession = tempAllSessionList.filter(e=>e.coursestreamid == data.coursestreamid);
      if(tempFilteredSession && tempFilteredSession.length > 0){
       const checkMemberCourseDetails = tempFilteredSession.filter(e=>e.memberCourseStreamDetails.length > 0);
       completedSessionCount = completedSessionCount + checkMemberCourseDetails.length;
       return completedSessionCount;
      }else{
        return completedSessionCount;
      }
    }
    return completedSessionCount;
  }

  return (
    <div>
      <Container maxWidth="md">
        <div className="ms-lg-5 ms-md-5 ms-0">
          <Grid Container justifyContent="left">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <span className="schedule_title_text" alignItems="center">
                Schedule
              </span>
            </Grid>
          </Grid>
          <Grid container>
            {conditional_configs.scheduleList.map((obj, idx) => {
              return (
                <div key={`e-my-schedule-days-ctrl-` + idx} id={`e-my-schedule-days-ctrl-id-` + idx} className="my_schedule_button_div">
                  <Button onClick={() => handleScheduleClick(obj)}
                    variant="outlined"
                    className={selectedSchedule == obj.id ? `Schedule_btns schedule_btns_active mb-2 me-1 me-lg-0`: `Schedule_btns schedule_btns_activemb-2 me-1 me-lg-0`}
                  >
                    {obj?.value}
                  </Button>
                </div>
              )
            })}
          </Grid>
          {/* <span className="my_schedule_title ">{Schedule_date}</span> */}
        </div>
      </Container>
      <Container>
        {filteredScheduleData && filteredScheduleData.length > 0 ? filteredScheduleData?.map((schedule_datas, idx) => {
          return (
             <div>
               {schedule_datas?.memberCourseStreamDetails.length == 0?
            <Grid key={`e-my-schedule-`+idx} container justifyContent="center">
              {/* CARD SECTION 1 */}
              <Card className="schedule_card mt-3">
                <Grid container alignItems="center">
                  {/* IMAGE */}
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <img
                      src={schedule_datas.course_image}
                      alt="user_photo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Grid>
                  <Grid className="ps-2" item xs={12} sm={5} md={5} lg={5}>
                    <Box className="my_schedule_text_section">
                      <p className="my_schedule_time_text m-0">
                        {/* {schedule_datas.time} {schedule_datas.session_name} */}
                        {/* {fDate(schedule_datas.startdate)}({convertTime(schedule_datas.from)} - {convertTime(schedule_datas.to)}) */}
                        {moment(schedule_datas?.startdate).format("dddd")}, {moment(schedule_datas?.startdate).format('ll')} {convertTime(schedule_datas?.from)} - {convertTime(schedule_datas?.to)}
                      </p>
                      <p className="my_schedule_title">
                        {schedule_datas?.coursename}
                      </p>
                      <p>
                        <LinearProgress
                          className="my_schedule_linear"
                          variant="determinate"
                          value={getCompletedPercentage(schedule_datas)}
                          color="success"
                          sx={{
                            height: "10px",
                            borderRadius: 2,
                            bgcolor: "#fffff",
                          }}
                        />
                      </p>
                     <span className="my_schedule_text_section my_schedule_text_section_cap">
                        <strong>
                          {getCompletedSessionsCount(schedule_datas)}/{schedule_datas?.total_sessions}&nbsp;sessions
                        </strong>&nbsp;Complete
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    
                    alignItems="center"
                  >
                   { idx == 0 ? <Button variant="outlined" className="start_btn mt-0 " onClick={(e) => handleStartSession(e, schedule_datas)}>
                              Start Session
                            </Button>:""}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            :""}
            </div> 
          );
        }) :
          <Grid container justifyContent="center" className="mt-3">
            <NoRecordFound noRecordFoundImage={assets.noRecordFound.expertCourses} noRecordFoundText={error_messages.expertNoCoursesFound}></NoRecordFound>
          </Grid>}
      </Container>
      {/* CARD SECTION END*/}
    </div>
  );
}
