import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from 'react-router-dom';

import { setMenu, setIsChatGeneralNotification, setIsFluid } from "../../../store/slices/settingsSlice";

// IMAGES
import Course_img from "../../../assets/img/side_bar_img/course_img.png";
import Chat_img from "../../../assets/img/side_bar_img/chat_img.png";
import Docs_img from "../../../assets/img/side_bar_img/docs_img.png";
import Notes_img from "../../../assets/img/side_bar_img/notes_img.png";
import three_dot_img from "../../../assets/img/side_bar_img/three_dot_img.png";

import Mic_setting_img from "../../../assets/img/side_bar_img/mic_settings_img.png";
import Online_learning_img from "../../../assets/img/side_bar_img/online_learning_img.png";
import Groups_img from "../../../assets/img/side_bar_img/group_img.png";
import microphone_img from "../../../assets/img/side_bar_img/microphone_img.png";
import "./side-bar.css";

import IconButton from "@mui/material/IconButton";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import navigate_route_config from "../../../config/navigate_route_config";
import storage_configs from "../../../config/storage-config";
import { getBreakoutSessionStatus } from "../../../utils/util";

function SideBar() {
  //GET SLUG FROM LINK
  let { streamId, slug} = useParams();
  const isFluid = useSelector((state) => state.settings.isFluid);
  const isChatGeneralNotification = useSelector((state) => state.settings.isChatGeneralNotification);
  const breakoutRoomStatus = useSelector((state) => state.settings.breakoutRoomStatus);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const mainMenu = useSelector((state) => state.settings.menu);
  console.log("mainMenu", mainMenu);
  const setSidebarMenu = (menu) => {
    dispatch(setIsFluid(false));
    dispatch(setMenu(menu));
    if(menu == 'chat'){
      localStorage.removeItem(storage_configs.localStorage.sessions.chatGeneralNotification);
      dispatch(setIsChatGeneralNotification(false))
    }
  };

  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //

  const checkIsAnyNotification = () => {
      let curNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatGeneralNotification));
      if(curNotification){
        return curNotification;
      }
      return [];
  }

  const navigateTo =(page)=>{
    let course_id = localStorage.getItem('course_id');
    let current_stream_id = localStorage.getItem('current_stream_id');
    if(page == 'manage-groups'){
      if(course_id){
        navigate(navigate_route_config.expert.manage_groups + '/' + course_id + '/' + current_stream_id);
        window.location.reload();
        //window.open(navigate_route_config.expert.manage_groups + '/' + course_id + '/' + current_stream_id);
      }
    }else if(page == 'course-info'){
      var tempSlugArr = slug.split('-');
      var tempSlug = tempSlugArr.slice(0, -1).toString();
      var finalSlug = tempSlug.replaceAll(',','-'); 
      //window.open('/course-detail/' + finalSlug + '-' + course_id);
      navigate('/course-detail/' + finalSlug + '-' + course_id)
    }else{
      handleClose();
    }
    handleClose();
  }

  return (
    <div className="side-bar-container m-0">
      <div
        className={
          mainMenu === "course" && isFluid !== true ? "side-bar-item-active" : "side-bar-item"
        }
        onClick={() => setSidebarMenu("course")}
      >
        <div className="d-flex justify-content-center">
          <img src={Course_img} alt="" />
        </div>
        <div className="side-bar-label mt-1">COURSE</div>
      </div>

      <div
        className={
          mainMenu === "chat" && isFluid !== true ? "side-bar-item-active" : "side-bar-item"
        }
        onClick={() => setSidebarMenu("chat")}
      >
      {isChatGeneralNotification || checkIsAnyNotification().length > 0 ?  <span className="general-notification-dot-side-bar"></span>:""}
        <div className="d-flex justify-content-center">
          <img src={Chat_img} alt="" />
        </div>
        <div className="side-bar-label mt-3">CHAT</div>
      </div>

      <div
        className={
          mainMenu === "notes" && isFluid !== true ? "side-bar-item-active" : "side-bar-item"
        }
        onClick={() => setSidebarMenu("notes")}
      >
        <div className="d-flex justify-content-center">
          <img src={Notes_img} alt="" />
        </div>
        <div className="side-bar-label mt-3">NOTES</div>
      </div>

      <div
        className={
          mainMenu === "docs" && isFluid !== true ? "side-bar-item-active" : "side-bar-item"
        }
        onClick={() => setSidebarMenu("docs")}
      >
        <div className="d-flex justify-content-center">
          <img src={Docs_img} alt="" />
        </div>
        <div className="side-bar-label mt-3">DOCS</div>
      </div>

      {/* droupdown */}
      <div className="d-flex justify-content-center settings-menu">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img src={three_dot_img} alt="" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={()=>navigateTo('course-info')}
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#3b4357",
            }}
          >
            {" "}
            <img src={Online_learning_img} alt="" className="settings-icon" />
            Course Information
          </MenuItem>
          {/* <MenuItem
            onClick={handleClose}
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#3b4357",
            }}
          >
            <img src={Mic_setting_img} alt="" className="settings-icon" />
            Video & Mic Settings
          </MenuItem> */}
        {(breakoutRoomStatus !== 'STARTED' || getBreakoutSessionStatus() !== true) ?   <MenuItem
            onClick={()=>navigateTo('manage-groups')}
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#3b4357",
            }}
          >
            <img src={Groups_img} alt="" className="settings-icon" />
            Manage Groups
          </MenuItem>:""}
          {/* <MenuItem
            onClick={handleClose}
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#3b4357",
            }}
          >
            <img src={microphone_img} alt="" className="settings-icon" />
            Unmute All
          </MenuItem> */}
        </Menu>
      </div>
    </div>
  );
}

export default SideBar;
