import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

import SearchIcon from "@mui/icons-material/Search";

import TableHead from "@mui/material/TableHead";

//COMPONENTS
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from '../../../components/SearchNotFound';

//ICONS
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { UserListHead, UserListToolbar } from "../../../sections/@dashboard/user";
import "./all-courses.css";

import { getCurrentUserData } from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";
import navigate_route_config from "../../../config/navigate_route_config";

import CourseService from "../../../services/courses-service";
const TABLE_HEAD = [
  { id: 'name', label: 'Course Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'noofsessions', label: 'Sessions', alignRight: false },
  { id: '' },
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function AllCourses() {  
  const navigate = useNavigate();
  const [courseTableData, setcourseTableData] = useState([]);
  const [badgeData, setBadgeData] = useState([0]);

  
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUserList] = useState([]);

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      GetAllPublishedCourses();
  }else {
    localStorage.clear();
    navigate('/parent-login');
  }
  }, []);

  const GetAllPublishedCourses=()=>{
    CourseService.GetAllPublishedCourses()
      .then(response => {
        setUserList(response.data);
        console.log(USERLIST);

        setcourseTableData(response.data);
        setBadgeData(response.data.length)
      })
      .catch(error => {
        
      console.log(error)
      });
  }
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const navigateToCreateCourse = () => {
    navigate(navigate_route_config.admin.create_course);
  }

  const navigateToCourseDetail = (route, course_id, cname) => {
    //navigate('/course-detail/'+slugify(cname)+'-'+course_id);
    window.open('/course-detail/'+slugify(cname)+'-'+course_id);
    //navigate('/parent/course-detail/' + course_id);
  }

  return (
    <div>
      <Container>
        <div class="d-flex align-items-center  mb-3">
          <span className="parent_payment_text me-auto">
            Courses
            {/* <Badge
              className="parent_badge_payment"
              badgeContent={badgeData}
              sx={badgeStyle}
            /> */}
          </span>
        </div>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filterPlaceholder='Search Course Name' />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { courseid, name, price, noofsessions, minnolearners, maxnolearners, schedules, } = row;
                    const isItemSelected = selected.indexOf(courseid) !== -1;

                    return (
                      <TableRow
                        hover
                        key={courseid}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, courseid)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">${price}</TableCell>
                        <TableCell align="left" >{noofsessions}</TableCell>
                        <TableCell align="left" >
                          <Typography>
                            <Stack direction="row" spacing={1}>
                              {/* <Tooltip title="Edit Course" placement="bottom-start">
                                <IconButton aria-label="edit" onClick={(event) => navigateToCourseDetail(navigate_route_config.admin.course_info, courseid)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip> */}
                              {/* <IconButton aria-label="Kid" >
                                <ChildCareIcon />
                              </IconButton> */}
                              <Tooltip title="Pod Detail" placement="bottom-start">
                                <IconButton aria-label="Pod" onClick={(event) => navigateToCourseDetail(navigate_route_config.admin.course_detail, courseid, name)}>
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </Tooltip>

                            </Stack>
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}
                        <TableCell align="right">
                          {/* <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                            <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
                          </IconButton>
                          <Menu
                            open={isOpen}
                            anchorEl={ref.current}
                            onClose={() => setIsOpen(false)}
                            PaperProps={{
                              sx: { width: 200, maxWidth: '100%' },
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>

                            <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:edit-fill" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                          </Menu> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </div>
  );
}
