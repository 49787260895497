import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Badge,
    Fade,
    TextField,
    Typography,
    Button,
    Grid, InputBase,
    IconButton,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    Container,
    FormControl,
    Stepper,
    Step,
    StepLabel,
    Card,
    CardActions,
    CardContent,
    Radio,
    Paper,
    Stack,
    Modal
} from "@mui/material";

import {
    UserListHead,
    UserListToolbar,
    UserMoreMenu
} from "../../../sections/@dashboard/user";

import {
    Checkbox,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import { filter } from "lodash";
import SearchNotFound from "../../../components/SearchNotFound";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";



//ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";


//ASSETS -> IMG
import Sampleimg from "../../../assets/img/sample_img.png";
import "./image-library.css";
import Iconify from '../../../components/Iconify';

//CONFIGS
import iconsConfig from "../../../config/icons";
import questionTypes from '../../../config/question-types';
import conditional_configs from "../../../config/conditional_config";
import url_configs from "../../../config/url";

//SERVICES
import AdminService from "../../../services/admin-service";
import DocumentService from "../../../services/document-service";
import { getCurrentUserData } from "../../../utils/util";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from 'notistack';
import ConfirmationPopup from "../../../components/confirmation-popup/confirmation-popup";

//FILE UPLOAD
import DropFileInput from "../../../components/drop-file-input/DropFileInput";
import slugify from "react-slugify";

export default function ImageLibrary() {
    //#region Table
    const TABLE_HEAD = [
        { id: "name", label: "Library Name", alignRight: false },
        { id: "status", label: "Status", alignRight: false, type: "dateTime" },
        { id: "" },
    ];

    const Table_Row_Data = [
        { id: 1, libraryname: 'John Doe' },
        { id: 2, libraryname: 'Jane Doe' },
        { id: 3, libraryname: 'Admin User' },
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(
                array,
                (_user) =>
                    _user.libraryname.toLowerCase().indexOf(query.toLowerCase()) !== -1
            );
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("lname");

    const [filterName, setFilterName] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [libraryNameList, setLibraryNameList] = useState([]);

    //#endregion
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        templatename: Yup.string().required("Library template name is required"),
    });



    const [openLibraryTemplate, setOpenLibraryTemplate] = useState(false);
    const [anchorElPreAssessment, setAnchorElPreAssessment] = React.useState(null);
    const openPreAssessment = Boolean(anchorElPreAssessment);



    const {
        register,
        control,
        handleSubmit,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    useEffect(() => {
        if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
            setLibraryNameList(Table_Row_Data);
            getAllLibrary();
        } else {
            localStorage.clear();
        }
    }, []);

    const getAllLibrary = () => {
        AdminService.getAllLibrary()
            .then((response) => {
                setLibraryNameList(response.data.filter(e=>e.status == 1));
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - libraryNameList.length) : 0;

    const filteredUsers = applySortFilter(
        libraryNameList,
        getComparator(order, orderBy),
        filterName
    );

    const isUserNotFound = filteredUsers.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = libraryNameList.map((n) => n.libraryname);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, libraryname) => {
        const selectedIndex = selected.indexOf(libraryname);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, libraryname);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    /** OPEN TEMPLATE Library POPUP */
    const handleOpenLibraryTemplate = (type) => {
        setOpenLibraryTemplate(true)
    };
    /** CLOSE TEMPLATE Library POPUP */
    const handleCloseLibraryTemplate = () => setOpenLibraryTemplate(false);

    const badgeStyle = {
        "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: "#f07820",
            padding: "10px",
        },
    };

    //SUBMIT NEW ImageLibrary  TEMPLATE
    const onSubmit = (data) => {
        var insertLibraryTemplateModle = {
            name: data.templatename,
            documents: "",
            createdBy: getCurrentUserData().id.toString()
        }
        if (userFileUpload) {
            for (var i = 0; i < userFileUpload.length; i++) {
                console.log("data-img", userFileUpload[i])
                if (userFileUpload[i]) {
                    DocumentService.upload_document(userFileUpload[i])
                        .then(response => {
                            console.log('Images Uploaded Successfully');
                            if (insertLibraryTemplateModle.documents == "") {
                                insertLibraryTemplateModle.documents = response.data.id.toString()
                            } else {
                                insertLibraryTemplateModle.documents = insertLibraryTemplateModle.documents + "," + response.data.id
                            }
                            if (insertLibraryTemplateModle.documents != "" && insertLibraryTemplateModle.documents.toString().split(',').length == userFileUpload.length) {
                                AdminService.addLibrary(insertLibraryTemplateModle)
                                    .then(response => {
                                        enqueueSnackbar('Library added Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                        handleCloseLibraryTemplate();
                                        getAllLibrary();
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            }
        }else{
            enqueueSnackbar('Please upload files', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    };

    const [userFileUpload, setUserFileUpload] = React.useState();
    const [libId, setLibId] = React.useState();


    const handleUserImageUpload = (files) => {
        console.log(files);
        setUserFileUpload(files);
    };

    // confirmation box
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const handleDeleteOpenDialog = (id) => {
        setLibId(id);
        setConfirmationDialogOpen(true);
    };

    const handleDelete = () => {
        var data = {
            modifiedBy: getCurrentUserData().id
        }
        AdminService.inActiveLibrary(libId, data)
            .then((response) => {
                console.log("Library deleted.");
                getAllLibrary();
            })
            .catch((e) => {
                console.log(e);
            });
        setConfirmationDialogOpen(false); // Close the dialog after deletion.
    };

    const openWhiteboard = (obj) => {
        let nLink = url_configs.frontend + 'image-library-preview/' + slugify(obj);
        window.open(nLink, '_blank')
      }

    const handleCancel = () => {
        setConfirmationDialogOpen(false); // Close the dialog without deleting.
    };
    // confirmation box end
    return (
        <div>
            {/* TITLE HEADER */}
            <Container>
                <Box sx={{ width: "100%" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
                        <Typography variant="h4" gutterBottom className='mb-0'>
                            Image Library
                            <Badge className="badge_quiz" badgeContent={libraryNameList.length} sx={badgeStyle} />
                        </Typography>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e) => handleOpenLibraryTemplate('CREATE')}>
                            Add Library
                        </Button>
                    </Stack>
                </Box>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={libraryNameList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {
                                                id,
                                                name,
                                                status,
                                            } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) =>
                                                                handleClick(event, name)
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                            onClick={()=>openWhiteboard(name)}
                                                        >
                                                            <Typography className="link_text" variant="subtitle2" noWrap>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {status === 1 ? "Active" : "Inactive"}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                    <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteOpenDialog(id)} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={libraryNameList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

                {/* SELECT Library TEMPLATE POPUP */}
                <Dialog fullWidth open={openLibraryTemplate} onClose={handleCloseLibraryTemplate}>
                    <DialogTitle>
                        <div className="d-flex justify-content-center;">
                            <Typography
                                color="primary"
                                style={{ marginRight: 4 }}
                                className="select_quiz_text"
                            >
                                Create Library
                            </Typography>

                            <Typography
                                color="action"
                                style={{ marginLeft: "auto" }}
                                onClick={handleCloseLibraryTemplate}
                                className="select_quiz_close_btn"
                            >
                                <CloseIcon />
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <form>
                            {/* FIRST NAME TEXT FIELD */}
                            <Grid item xs={12} sm={12} className="mt-2">
                                <TextField
                                    required
                                    id="templatename"
                                    name="templatename"
                                    label="Library Name"
                                    fullWidth
                                    margin="dense"
                                    {...register("templatename")}
                                    error={errors.templatename ? true : false}
                                />
                                <Typography variant="inherit" color="textSecondary">
                                    {errors.templatename?.message}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Controller
                                    control={control}
                                    name="user_upload_files"
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <div className="mt-2">
                                            <span>Upload Images</span>
                                            <DropFileInput
                                                {...field}
                                                onFileChange={(files) => handleUserImageUpload(files)}
                                                isMultipleFile={true}
                                            />
                                        </div>
                                    )}
                                />

                            </Grid>
                        </form>
                        <div className="d-flex justify-content-end">
                            <Button variant="contained" className="img-library-submit-btn" onClick={handleSubmit(onSubmit)}>
                                Submit
                            </Button>
                        </div>

                    </DialogContent>
                </Dialog>
            </Container>
            <div>
                <ConfirmationPopup open={confirmationDialogOpen} onDelete={handleDelete} onCancel={handleCancel} onClose={() => setConfirmationDialogOpen(false)} />
            </div>
        </div>
    )
}
