import React, { useState, useEffect } from "react";
import { filter } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import "./course-pod.css";
import {
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Fade,
  Grid,
  FormControl,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Select,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TextField,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

//DATETIME FORMAT
import moment from 'moment';

//DATE PICKER
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

import Page from '../../../../../components/Page';

import { SnackbarProvider, useSnackbar } from 'notistack';

//Icon
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SourceIcon from "@mui/icons-material/Source";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from '@mui/material/IconButton';
import LockOutlined from "@mui/icons-material/LockOutlined";
import LockResetOutlined from "@mui/icons-material/LockResetOutlined";

//FILE UPLOAD
import DropFileInput from '../../../../../components/drop-file-input/DropFileInput';

//SERVICES
import AdminService from "../../../../../services/admin-service";
import DocumentService from "../../../../../services/document-service";
import CourseService from "../../../../../services/courses-service";
import LookUpervice from "../../../../../services/look-up-service";
import SessionService from "../../../../../services/session-service";

//CONFIG
import route_config from "../../../../../config/route_config";
import curriculamTypes from '../../../../../config/curriculam_types';
import conditionalConfig from '../../../../../config/conditional_config';
import questionTypes from '../../../../../config/question-types';
import curriculam_content_file_types from '../../../../../config/curriculam_content_file_types';
import fileTypesConfig from '../../../../../config/file_types';
import mimeTypes from '../../../../../config/mime-types';
import navigate_route_config from "../../../../../config/navigate_route_config";

import { getCurrentUserData } from "../../../../../utils/util";
//ASSETS
import Sampleimg from "../../../../../assets/img/white-board/whiteboard.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function CoursePod() {
  let { courseid } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [expertList, setExpertList] = useState([]); // Used in Step - 5 Add Stream
  const [courseDetailId, setCourseDetailId] = useState(""); // Used in Step - 5 Add Stream

  const handleNext = (data) => {
    console.log(data);
    // enqueueSnackbar('Content session updated successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

    let createCourseStreamModel = {
      courseid: courseid.toString(),
      coursedetailid: courseDetailId.toString(),
      stream: streamList
    }
    createCourseStreamModel.stream.forEach(streamObj => {
      streamObj.session.forEach(sessionObj => {
        if (sessionObj && sessionObj.to) {
          sessionObj.to = moment(new Date(sessionObj.to), 'DD/MM/YYYY').format('HH:mm:ss');
        }
        if (sessionObj && sessionObj.from) {
          sessionObj.from = moment(new Date(sessionObj.from), 'DD/MM/YYYY').format('HH:mm:ss');
        }
      });
    });
    console.log(createCourseStreamModel);
    CourseService.updateStream(courseid.toString(), createCourseStreamModel)
      .then(response => {
        console.log(response);
        if (response && response.data && response.success === 'true') {
          enqueueSnackbar('Content session updated successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else if (response && response.data && response.success === 'false') {
          enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditionalConfig.role.admin) {
      if (!courseid) {
        navigate(route_config.admin.courses.main_route_name);
      }
      getExpertList();
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  /** GET EXPERT LIST */
  //Used in Course Stream Dropdown
  const getExpertList = () => {
    AdminService.getAllActiveExperts().then(response => {
      setExpertList(response.data);
      GetCoursesDetailsById();
    })
      .catch(e => {
        console.log(e);
      });
  }

  // const GetCourseDetailsByCourseId = () => {
  //   CourseService.GetCourseDetailsByCourseId(courseid).then(response => {
  //     response.data.coursesession;
  //   }).catch(e => {
  //     console.log(e);
  //   });
  // }

  const GetCoursesDetailsById = () => {
    CourseService.GetCoursesDetailsById(courseid).then(response => {
      console.log(response);
      setCourseDetailId(response.data.coursedetail[0].id.toString());
      if (response.data && response.data.coursestream && response.data.coursestream.length > 0) {

        let tempStreamList = [];
        response.data.coursestream.forEach(streamEle => {
          let tempStreamObj = {
            expertid: streamEle.expertid,
            startdate: streamEle.startdate,
            session: []
          }

          streamEle.coursestreamdetail.forEach(cStreamEle => {
            let tempStreamSessionObj = {
              coursesessionid: "",
              coursesessionname: "",
              startdate: "",
              from: "",
              to: ""
            };
            tempStreamSessionObj.coursesessionid = cStreamEle.coursesessionid;
            tempStreamSessionObj.startdate = cStreamEle.startdate;
            tempStreamSessionObj.from = cStreamEle.startdate + 'T' + cStreamEle.from;
            tempStreamSessionObj.to = cStreamEle.startdate + 'T' + cStreamEle.to;
            tempStreamObj.session.push(tempStreamSessionObj);
          });

          tempStreamList.push(tempStreamObj);
        });

        setStreamList(tempStreamList);
        console.log('tempStreamList', tempStreamList);
      }

    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion


  //#region STEP - 5 COURSE STREAM
  const [streamList, setStreamList] = useState([]);

  /** STREAM EXPERT FIELD CHANGE EVENT */
  const handleStreamExpertFieldChange = (event, streamIndex) => {
    let tempStreamList = [...streamList];
    tempStreamList[streamIndex].expertid = event.target.value;
    setStreamList(tempStreamList);
  }

  /** STREAM SESSION FORM CHANGE EVENT */
  const handleStreamSessionChange = (event, field, streamIndex, sessionIndex) => {
    let tempStreamList = [...streamList];

    if (sessionIndex == 0 && field == 'startdate') {
      tempStreamList[streamIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (field == 'startdate') {
      tempStreamList[streamIndex].session[sessionIndex].startdate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      setStreamList(tempStreamList);
    } else if (field == 'from') {
      tempStreamList[streamIndex].session[sessionIndex].from = event.$d;
      setStreamList(tempStreamList);
    } else if (field == 'to') {
      tempStreamList[streamIndex].session[sessionIndex].to = event.$d;
      setStreamList(tempStreamList);
    } else {
      return;
    }
  }

  /** ADD POD ITEM */
  const addPodItem = (e) => {
    let tempStreamObj = {
      expertid: "",
      startdate: "",
      session: []
    }
    CourseService.GetCourseDetailsByCourseId(courseid).then(response => {
      response.data.coursesession.forEach(element => {
        let tempStreamSessionObj = {
          coursesessionid: element.id,
          coursesessionname: element.name,
          startdate: "",
          from: "",
          to: ""
        };
        tempStreamObj.session.push(tempStreamSessionObj);
      });
      setStreamList([...streamList, tempStreamObj]);
    }).catch(e => {
      console.log(e);
    });
  }

  const removePodItem = (index) => {
    let data = [...streamList];
    data.splice(index, 1)
    setStreamList(data)
  }

  /** CREATE COURSE STREAM */

  //#endregion

  const tabNavigation = (screen) => {
    navigate(screen + '/' + courseid);
  }
  return (
    <Page title="Course Pod">
      <Container>

        <Typography>
          <Stack direction="row" spacing={1} mt={3}>
            <Button
              id="tab-button"
              variant="contained"
              onClick={(e) => tabNavigation(navigate_route_config.admin.course_info)}
            >
              Course Info
            </Button>
            <Button
              id="tab-button"
              variant="contained"
              onClick={(e) => tabNavigation(navigate_route_config.admin.course_content)}
            >
              Course Content
            </Button>
            <Button
              id="tab-button"
              variant="contained"
              className="tab-btn-active"
              onClick={(e) => tabNavigation(navigate_route_config.admin.course_pod)}
            >
              Pods
            </Button>
          </Stack>
        </Typography>

        <form onSubmit={handleSubmit(handleNext)}>
          <Typography variant="h6" margin="dense">
            Course Pod
          </Typography>
          <div>
            <div className="d-flex align-items-center">
              <Stack direction="row" spacing={1}>
                <Button
                  className="add_pod_btn"
                  id={'add_pod_'}
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={(e) => addPodItem(e)}
                >
                  Add Pod
                </Button>
              </Stack>
            </div>

            {streamList.map((steamObj, steamIndex) => {
              return <Accordion key={'stream_' + steamIndex} className="mt-2 pre_assessment_list_accordion" elevation={12}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  variant="outlined"
                >
                  <div className="align-items-center justify-content-between d-flex w-100">
                    <Typography>
                      <Stack className="align-items-center" direction="row" >
                        <IconButton aria-label="library" color="primary">
                          <DragIndicatorIcon color="action" />
                        </IconButton>
                        <span>Pod - {steamIndex + 1}</span>
                      </Stack>
                    </Typography>
                    <Typography>
                      <Stack direction="row" spacing={1}>
                        {/* <IconButton aria-label="library" color="primary">
                                      <LibraryAddIcon />
                                    </IconButton> */}
                        <IconButton aria-label="delete" onClick={(e) => removePodItem(steamIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Container spacing={5}>
                    {/* SELECT EXPERT FIELD */}
                    <Grid item xs={12} sm={12}>
                      <FormControl className="w-100">
                        <InputLabel
                          id={'stream_expert_id_' + steamIndex}
                          variant="outlined"
                          margin="dense">
                          Assign Expert
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId={'stream_expert_id_' + steamIndex}
                          label="Assign Expert"
                          id={'stream_expert_id_' + steamIndex}
                          name={'stream_expert_' + steamIndex}
                          value={steamObj.expertid}
                          onChange={(e) => handleStreamExpertFieldChange(e, steamIndex)}
                          margin="dense"
                          variant="outlined"
                        >
                          {expertList.map((expertObj, expertIndex) => {
                            return <MenuItem value={expertObj.id}>{expertObj.firstname}</MenuItem>
                          }
                          )}
                        </Select>
                      </FormControl>
                      {/* <Autocomplete
                      id={'stream_expert_id_'+steamIndex}
                      name={'stream_expert_'+steamIndex}
                      options={expertList}
                      getOptionLabel={(option) => option.firstname}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={steamObj.expertid}
                      onChange={(e) => handleStreamExpertFieldChange(e, steamIndex)}
                      renderInput={(params) => (
                        <TextField {...params} label="Assign Expert" />
                      )}
                    /> */}
                    </Grid>
                    {/* SESSIONS LIST */}
                    {steamObj.session.map((streamSessionObj, streamSessionIndex) => {
                      return <div key={'stream_sessions_key_' + steamIndex + '_' + streamSessionIndex}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="mt-4">
                          <div>
                            <span className="session_text mt-sm-1">{streamSessionObj.coursesessionname} </span>
                          </div>
                        </Grid>
                        {/* SESSIONS FORM */}
                        <Grid item xs={12} sm={12} className=" mb-4">
                          <Grid container alignItems="center" spacing={1}>
                            {/* SESSION DATE */}
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="mt-sm-1">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    fullWidth
                                    label="Start Date"
                                    id={'session_start_date_id_' + steamIndex + '_' + streamSessionIndex}
                                    name={'session_start_date_' + steamIndex + '_' + streamSessionIndex}
                                    inputFormat="DD/MM/YYYY"
                                    value={streamSessionObj.startdate}
                                    onChange={(e) => handleStreamSessionChange(e, 'startdate', steamIndex, streamSessionIndex)}
                                    renderInput={(params) => <TextField
                                      fullWidth
                                      {...params} />}
                                  />
                                </LocalizationProvider>
                              </div>
                            </Grid>
                            {/* SESSION FROM TIME */}
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className=" mt-sm-1">
                                {/* <TextField
                                fullWidth
                                label="From"
                                id={'session_from_id_' + steamIndex + '_' + streamSessionIndex}
                                name={'session_from_' + steamIndex + '_' + streamSessionIndex}
                                value={streamSessionObj.from}
                                onChange={(e) => handleStreamSessionChange(e, 'from', steamIndex, streamSessionIndex)}
                              /> */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                    label="From"
                                    id={'session_from_id_' + steamIndex + '_' + streamSessionIndex}
                                    name={'session_from_' + steamIndex + '_' + streamSessionIndex}
                                    value={streamSessionObj.from}
                                    onChange={(e) => handleStreamSessionChange(e, 'from', steamIndex, streamSessionIndex)}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </div>
                            </Grid>
                            {/* SESSION TO TIME */}
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className=" mt-sm-1">
                                {/* <TextField
                                fullWidth
                                label="To"
                                id={'session_to_id_' + steamIndex + '_' + streamSessionIndex}
                                name={'session_to_' + steamIndex + '_' + streamSessionIndex}
                                value={streamSessionObj.to}
                                onChange={(e) => handleStreamSessionChange(e, 'to', steamIndex, streamSessionIndex)}
                              /> */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                    label="To"
                                    id={'session_to_id_' + steamIndex + '_' + streamSessionIndex}
                                    name={'session_to_' + steamIndex + '_' + streamSessionIndex}
                                    value={streamSessionObj.to}
                                    onChange={(e) => handleStreamSessionChange(e, 'to', steamIndex, streamSessionIndex)}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    })}

                  </Container>
                </AccordionDetails>
              </Accordion>
            })}
          </div>
          <Button
            id="submit-button"
            className={classes.button}
            variant="contained"
            type="submit"
          >Submit
          </Button>
        </form>
      </Container>
    </Page>
  );
}