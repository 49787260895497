import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarProvider, useSnackbar } from 'notistack';

// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

//Services
import AuthService from '../../../services/auth/auth.service';

//Utils
import { getCurrentUserData } from '../../../utils/util';

//Configs
import roleConfig from '../../../config/conditional_config';
import navigate_route_config from '../../../config/navigate_route_config';
import assets from '../../../config/assets';
import storage_configs from '../../../config/storage-config';

//Services
import ParentAPIService from '../../../services/parent-service';
import SessionService from '../../../services/session-service';
import MemberService from '../../../services/member-service';

import {
  Paper,
  Box,
  Grid,
  Typography
} from '@mui/material';

// ----------------------------------------------------------------------

let currentGroupDetail;

export default function JoinDemoSessionForm() {

  //GET SLUG FROM LINK
  let { streamId, slug } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    name: Yup.string().required('Name is required'),
  });

  const defaultValues = {
    email: '',
    name: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = data => {
    var addKidData = {
      firstname: data.name,
      lastname: '',
      username: '',
      email: data.email,
      password: '',
      parentid: '',
      relationship: "2",
      coursestreamid: streamId
    };
    ParentAPIService.addDemoSessionKid(addKidData)
      .then(response => {
        console.log(response)
        if (response && response.success === 'true') {
          loginUser(response.user, 'password');
          // variant could be success, error, warning, info, or default
          //enqueueSnackbar('Kids Added Sucessfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        console.log(error)
      });
  };

  const loginUser = (username, password) => {
    AuthService.learner_login(username, password)
      .then(response => {
        if (response && response.success === 'true') {
          navigateToSession();
          // variant could be success, error, warning, info, or default          
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  /** JOIN SESSION */
  const navigateToSession = () => {

    if (currentGroupDetail && currentGroupDetail.coursegroupid != 0) {
      let updateGroupModel = {
        coursestreamid: streamId,
        coursegroupid: currentGroupDetail.coursegroupid.toString(),
        memberid: getCurrentUserData().id
      }
      updateMemberGroup(updateGroupModel)
    }

    const date = new Date();
    let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
    let splitCurrentSlug = slug.split('-');
    if (splitCurrentSlug.length > 0) {
      let tempmembercoursestreamdetailid = splitCurrentSlug[splitCurrentSlug.length - 1];
      let joinSessionModel = {
        membercoursestreamdetailid: tempmembercoursestreamdetailid.toString(),
        learnerid: getCurrentUserData().id.toString(),
        startdatetime: formatedTodayDate
      }

      SessionService.joinSessionByLearner(joinSessionModel).then(response => {
        console.log(response);
        if (response && response.success == "true") {
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamdetailid, tempmembercoursestreamdetailid);
          localStorage.setItem(storage_configs.localStorage.sessions.membercoursestreamlearnerdetailsid, response.data.id);
          localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, streamId);
          navigate('/learner-session/' + slug);
          //navigate('/learner-session/' + data.slug);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  const getGroups = () => {
    MemberService.getMemberCourseGroupByStreamIdNew(streamId).then(
      (response) => {
        console.log("Groups", response.data);
        if (response && response.data && response.data.length > 0) {
          currentGroupDetail = response.data[0];
          console.log("Groups Detaiil", currentGroupDetail);
        }
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  /** UPDATE MEMBER GROUP */
  const updateMemberGroup = (data) => {
    if (data) {
      MemberService.updateMemberGroup(data).then(
        (response) => {
          console.log("Group Updated Successfully", response);
        }
      ).catch((e) => {
        console.log(e);
      });
    }
  }

  useEffect(() => {
    if (slug && streamId) {
      if (getCurrentUserData()) {
        navigate('/learner-session/' + slug);
      }
      getGroups(streamId)
    }
    // getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin
  }, []);

  return (

    <Grid className='form-bg-demo' container
      spacing={0} direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>
      <Paper className='form-bg-c-demo'>
        <Box px={3} py={2} >
          <Typography variant="h6" className='company-logo-container' align="center" margin="dense">
            <img src={assets.meekuLogo} width={120} ></img>
          </Typography>
          <Typography variant="h6" className='company-text' align="center" margin="dense">
            <div>
              Please enter a valid email ID. Once this alpha testing is completed, a gift card will be sent to the provided email.
            </div>
          </Typography>
          
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ my: 2 }}>
              <RHFTextField name="name" label="Your Name" />
            </Stack>
            <Stack spacing={3} sx={{ my: 2 }}>
              <RHFTextField name="email" label="Email address" />
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Join Session
            </LoadingButton>
          </FormProvider>

        </Box>
      </Paper>
    </Grid>
  );
}
