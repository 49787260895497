import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, TextField, DialogTitle, Button, Grid } from '@mui/material';

import ParentService from '../../services/parent-service';
import { SnackbarProvider, useSnackbar } from "notistack";
import { getCurrentUserData } from '../../utils/util';

const OtpPopup = ({ open, onClose, onSubmit }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpValid, setOtpValid] = useState(false); // To track if OTP is valid

    // Function to handle OTP change
    const handleOtpChange = (e) => {
        const value = e.target.value;
        setOtpValid(false)
        // Allow only numeric and set the OTP
        if (/^\d*$/.test(value) && value.length <= 6) {
            setOtp(value);
            setOtpError('');
        } else {
            setOtpError('Please enter a valid 6-digit OTP.');
        }
    };

    // Use `useEffect` to reset form when the popup is closed
    useEffect(() => {
        if (!open) {
            resetForm(); // Reset form when popup is closed
        }
    }, [open]);


    // Function to reset the form
    const resetForm = () => {
        setOtp(''); // Reset OTP field
        setOtpError(''); // Clear any errors
    };


    // Function to handle OTP submit
    const handleOtpSubmit = (e) => {
        e.preventDefault();

        if (otp.length == 6) {

            var userOptData = {
                memberid: getCurrentUserData().id.toString(),
                otp: otp
            };
            console.log("userOptData", userOptData)
            ParentService.verifyOtp(userOptData)
                .then((response) => {
                    if (response.success) {
                        // If OTP is valid, show tab 3
                        setOtpValid(false);
                        enqueueSnackbar("OTP verified and account activated successfully", {
                            variant: "success",
                            key: "topright",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        });
                        // Reset the form and close the popup
                        resetForm(); // Reset OTP and error fields
                        onClose(); // Close the popup

                        // If OTP is valid, update the local storage
                        const updatedUserData = {
                            ...getCurrentUserData(), // Fetch current user data
                            status_id: 1, // Assuming states_id 1
                        };
                        // Update local storage with the new user data
                        localStorage.setItem("user", JSON.stringify(updatedUserData));

                    } else {
                        setOtpError('OTP is invalid or expired, please try again.');
                    }
                })
                .catch(() => {
                    setOtpError('Error validating OTP, please try again.');
                });
        } else {
            setOtpError('OTP must be 6 digits.');
        }
    };



    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>OTP Verification</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <p className="small-note-text">
                            Please check your registered email for your OTP. Please enter the OTP to activate your account.
                        </p>
                    </Grid>
                    {/* OTP INPUT FIELD */}
                    <Grid item xs={12} sm={12} className="mt-2">
                        <TextField
                            required
                            id="otp"
                            name="otp"
                            label="Enter 6-digit OTP"
                            fullWidth
                            margin="dense"
                            value={otp}
                            onChange={handleOtpChange}
                            error={Boolean(otpError)}
                            helperText={otpError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="flex-end">
                            <button
                                disabled={otpValid}
                                type="submit"
                                onClick={handleOtpSubmit}
                                className="submit-opt-btn-v4 mt-2"
                            >
                                <span>Submit OTP</span>
                            </button>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default OtpPopup;
