import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Typography, Stack, ListItemIcon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import Iconify from "../../../Iconify";

import { SnackbarProvider, useSnackbar } from "notistack";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "./header.css";
import right_icon_img from "../../../../assets/img/header_img/right_arrow_img.png";
import Left_icon_img from "../../../../assets/img/header_img/left_img.png";
import Hand_icon_img from "../../../../assets/img/header_img/hand_icon_img.png";
import Video_icon_img from "../../../../assets/img/header_img/video_icon_img.png";
import Video_icon_off_img from "../../../../assets/img/header_img/video_muted.png";
import Voice_icon_img from "../../../../assets/img/header_img/voice_icon_img.png";
import Voice_icon_off_img from "../../../../assets/img/header_img/audio_muted.png";
import Share_img from "../../../../assets/img/header_img/share_img.png";
import Logout_img from "../../../../assets/img/header_img/logout_img.png";
import Sand_clock from "../../../../assets/img/header_img/sand_clock_img.png";

import {
  setMainMenu,
  setIsFluid,
  setIsGalleryView,
} from "../../../../store/slices/settingsSlice";
import {
  setCameraStatus,
  setShareScreenStatus,
  setParticipants,
} from "../../../../store/slices/zoomSlice";

//configs

import conditional_configs from "../../../../config/conditional_config";
import storage_configs from "../../../../config/storage-config";
import navigate_route_config from "../../../../config/navigate_route_config";
import url_configs from "../../../../config/url";
import meta_data from "../../../../config/meta_data";
import assets from "../../../../config/assets";
import icons_config from "../../../../config/icons";
import zoomConfigs from "../../../../config/zoom";

//UTILS
import {
  getCurrentUserData,
  getBreakoutSessionStatus,
  clearClassRoomSessionData,
  getUnique,
  getInitialsFromFullName,
} from "../../../../utils/util";
import {
  setZoomAudio,
  setLeaveGroup,
  setAllowParticipants,
} from "../../../../store/slices/zoomSlice";

//SERVICES
import SessionService from "../../../../services/session-service";

import io from "socket.io-client";
import error_messages from "../../../../config/error_messages";
import ZoomService from "../../../../services/zoom-service";
import { getExploreName } from "../../../../utils/platform";
import {
  getActiveCamera,
  getActiveMicrophone,
  getActiveSpeaker,
} from "../../../../utils/zoomFunctions";

import ShortLogo from "../../../../assets/img/short_logo.png";
import {
  FaVideo,
  FaMicrophone,
  FaRegHandPaper,
  FaMicrophoneSlash,
  FaVideoSlash,
  FaPhoneSlash,
} from "react-icons/fa";
import Online_learning_img from "../../../../assets/img/side_bar_img/online_learning_img.png";
import Groups_img from "../../../../assets/img/side_bar_img/group_img.png";
import IconButton from "@mui/material/IconButton";

let sessionSocket;

let socket_conection;
let b_room_socket;

function Header(args) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const { enqueueSnackbar } = useSnackbar();

  //WAITING PARTICIPANTS
  const [waitingPartEl, setWaitingPartEl] = useState();
  const [waitingPartCtrlMenu, setWaitingPartCtrlMenu] = useState(false);
  const [waitingPartEnableNotify, setWaitingPartEnableNotify] = useState(false);
  const allowParticipants = useSelector(
    (state) => state.zoom.allowParticipants
  );

  const [anchorEl, setAnchorEl] = useState();
  const [raisedHandCtrlMenu, setRaisedHandCtrlMenu] = useState(false);
  const [isRaisedHand, setIsRaisedHand] = useState(false);

  const [copySuccess, setCopySuccess] = useState("");
  let currentUserData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const mainMenu = useSelector((state) => state.settings.mainMenu);
  const isGalleryView = useSelector((state) => state.settings.isGalleryView);
  const isFluid = useSelector((state) => state.settings.isFluid);
  const zoomClient = useSelector((state) => state.zoom.zoomClient);
  const zoomStatus = useSelector((state) => state.zoom.status);
  const zoomCameraStatus = useSelector((state) => state.zoom.cameraStatus);
  const zoomStream = useSelector((state) => state.zoom.stream);
  const stopBreakoutLoading = useSelector(
    (state) => state.zoom.stopBreakoutLoading
  );
  const [isOpen, setIsOpen] = useState(false);
  //const [zoomAudioStatus, setAudio] = useState("mute");
  const zoomAudioStatus = useSelector((state) => state.zoom.zoomAudio);

  const breakoutRoomStatus = useSelector(
    (state) => state.settings.breakoutRoomStatus
  );

  const zoomShareScreenStatus = useSelector(
    (state) => state.zoom.shareScreenStatus
  );
  const selectedGroup = useSelector((state) => state.settings.selectedGroup);
  const currenGroupName = useSelector(
    (state) => state.settings.currentGroupName
  );
  //const [isZoomScreenSharing, setIsZoomScreenSharing] = useState(false);

  //GET SLUG FROM LINK
  let { slug } = useParams();

  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  //Share Screen Menu
  const [shareScreenEl, setShareScreenEl] = useState();
  const [shareScreenOptionMenu, setShareScreenOptionMenu] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const [informationBoxOpen, setInformationBoxOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // const isAudioMuted = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.isAudioMuted));
    // if(!isAudioMuted){
    //   setAudio("unmute");
    // }

    b_room_socket = io(url_configs.breakout_room_server);
    b_room_socket.on("raise-your-hand", (raisedObj) => {
      console.log("raisedObj", raisedObj);
      let tempRaisedList = [];
      tempRaisedList.push(raisedObj.data);
      tempRaisedList = getUnique(tempRaisedList, "id");
      setTimeout(() => {
        console.log("tempRaisedList", tempRaisedList);
        let raisedNotificationList = JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.raisedNotificationList
          )
        );
        if (raisedNotificationList && raisedNotificationList.length > 0) {
          for (let i = 0; i < tempRaisedList.length; i++) {
            raisedNotificationList.push(tempRaisedList[i]);
          }
          raisedNotificationList = getUnique(raisedNotificationList, "id");
          localStorage.setItem(
            storage_configs.localStorage.sessions.raisedNotificationList,
            JSON.stringify(raisedNotificationList)
          );
          enqueueSnackbar("Raised hand!", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setIsRaisedHand(true);
        } else {
          tempRaisedList = getUnique(tempRaisedList, "id");
          localStorage.setItem(
            storage_configs.localStorage.sessions.raisedNotificationList,
            JSON.stringify(tempRaisedList)
          );
          enqueueSnackbar("Raised hand!", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setIsRaisedHand(true);
        }
      }, 2000);
    });

    b_room_socket.on("user-joined", (obj) => {
      console.log("user-joined", obj.data);
      //const checkNotification = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.isNotification));
      if (getBreakoutSessionStatus()) {
        if (obj && obj.data && obj.data.slug == slug) {
          console.log("user-joined", obj.data);
          if (obj.data.zoomDetail) {
            var waitingParticipants = [];
            waitingParticipants = JSON.parse(
              localStorage.getItem(
                storage_configs.localStorage.sessions.zoom.waitingParticipants
              )
            );
            if (waitingParticipants && waitingParticipants.length > 0) {
              waitingParticipants = waitingParticipants.filter(
                (e) => e.displayName !== obj.data.zoomDetail.displayName
              );
              waitingParticipants.push(obj.data.zoomDetail);
              localStorage.setItem(
                storage_configs.localStorage.sessions.zoom.waitingParticipants,
                JSON.stringify(waitingParticipants)
              );
            } else {
              var tempWaitingParticipants = [];
              tempWaitingParticipants.push(obj.data.zoomDetail);
              localStorage.setItem(
                storage_configs.localStorage.sessions.zoom.waitingParticipants,
                JSON.stringify(tempWaitingParticipants)
              );
            }
            setWaitingPartEnableNotify(true);
          }
          var userName = obj.data.name.split(" ")[0];
          enqueueSnackbar(
            userName + " has joined and waiting in main session!",
            {
              variant: "warning",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
        }
      }
    });

    // b_room_socket.on("break-out-status", (obj) => {
    //   if (obj && obj.success == true && obj.status == 'STARTED') {
    //     return;
    //   } else {

    //   }
    // });
  }, []);

  //#region Share Screen Menu
  const handleShareScreenMenuClose = () => {
    setShareScreenOptionMenu(false);
  };

  const handleShareScreenMenuOpen = (event) => {
    setShareScreenEl(event.currentTarget);
    setShareScreenOptionMenu(true);
  };

  const handleMenuItemClick = (e, option) => {
    e.stopPropagation();
    //var selfShareCanvas = document.querySelector("#course-view-video");
    if (option) {
      setActiveOption(option.id);
      dispatch(setShareScreenStatus(true));
      if (option.id == 0) {
        setTimeout(async () => {
          var selfShareCanvas = document.querySelector("#course-view-video");
          if (
            (await zoomStream?.getShareStatus()) === "ended" &&
            selfShareCanvas
          ) {
            await zoomStream?.startShareScreen(selfShareCanvas, {
              controls: {
                preferCurrentTab: true,
                surfaceSwitching: "include",
                selfBrowserSurface: "include",
                monitorTypeSurfaces: "include",
              },
              displaySurface: "window",
              requestReadReceipt: true,
            });
            handleShareScreenMenuClose();
          } else {
            enqueueSnackbar("Screen sharing is already enabled", {
              variant: "success",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        }, 1000);
      } else if (option.id == 1) {
        setTimeout(async () => {
          var selfShareCanvas = document.querySelector("#course-view-video");
          if (zoomStream?.getShareStatus() === "ended" && selfShareCanvas) {
            await zoomStream?.startShareScreen(selfShareCanvas);
            handleShareScreenMenuClose();
          } else {
            enqueueSnackbar("Screen sharing is already enabled", {
              variant: "success",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        }, 1000);
      } else {
        return;
      }
      console.log("Clicked:", option);
    }
    return;
  };
  //#endregion

  //#region WAITING PARTICIPANTS
  const handleWaitingParticipantsControlMenuClick = (event) => {
    if (getWaitingParticipants().length > 0) {
      setWaitingPartEl(event.currentTarget);
      setWaitingPartCtrlMenu(true);
    }
  };

  const handleWaitingParticipantsControlMenuClose = () => {
    setWaitingPartCtrlMenu(false);
  };

  const handleAllowParticipants = (e, obj) => {
    e.stopPropagation();
    localStorage.setItem("allow-this-pariticipant", JSON.stringify(obj));
    dispatch(setAllowParticipants(true));
    handleWaitingParticipantsControlMenuClose();
  };

  const getAllowedParticipant = () => {
    var partData = JSON.parse(localStorage.getItem("allow-this-pariticipant"));
    return partData;
  };

  const getWaitingParticipants = () => {
    let tempList = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.zoom.waitingParticipants
      )
    );
    if (tempList && tempList.length > 0) {
      return tempList;
    } else {
      return [];
    }
  };

  //#endregion

  //#region RAISE HAND
  const handleRaisedHandControlMenuClick = (event) => {
    if (getRaisedNotifications().length > 0) {
      setAnchorEl(event.currentTarget);
      setRaisedHandCtrlMenu(true);
      setIsRaisedHand(false);
    }
  };

  const handleRaisedHandControlMenuClose = () => {
    setRaisedHandCtrlMenu(false);
  };

  const getRaisedNotifications = () => {
    let tempList = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.raisedNotificationList
      )
    );
    if (tempList && tempList.length > 0) {
      return tempList;
    } else {
      return [];
    }
  };

  const handleLowerHand = (e, obj) => {
    e.stopPropagation();
    let tempList = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.raisedNotificationList
      )
    );
    if (tempList && tempList.length > 0) {
      tempList = tempList.filter((e) => e.id != obj.id);
      localStorage.setItem(
        storage_configs.localStorage.sessions.raisedNotificationList,
        JSON.stringify(tempList)
      );
      handleRaisedHandControlMenuClose();
    }
  };
  //#endregion

  const setMain = (menu) => {
    dispatch(setMainMenu(menu));
    if (menu === "breakout") {
      navigate("/expert-session/break-out-room");
    } else {
      navigate("/expert-session/classroom");
    }
  };

  const setFluid = () => {
    dispatch(setIsFluid(!isFluid));
    // zoomStream.stopVideo();
    // if (zoomCameraStatus && isFluid) {
    //   if (currentUserData.role == conditional_configs.role.expert) {
    //     setTimeout(() => {
    //       ZoomService.renderExpertSelfVideo(zoomStream, zoomClient);
    //     }, 2000);
    //   }
    // }
  };

  //#region EXPERT END SESSION
  const logout = () => {
    Swal.fire({
      title: error_messages.endSessionConfirmation,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire("Logged Out!", "success");
        // if (zoomStatus === "started") {
        //   zoomClient.leave(true);
        //   Swal.fire("Zoom Session ended!", "success");
        // } else {
        //   Swal.fire("Zoom session Already ended", "success");
        // }
        completeSession();
        localStorage.removeItem(
          storage_configs.localStorage.sessions.raisedNotificationList
        );
        // if(currentUserData.role == conditional_configs.role.expert){
        //   navigate("/expert/my-courses");
        // }else if(currentUserData.role == conditional_configs.role.learner){
        //   navigate("/learner/my-courses");
        // }else{
        //   return
        // }
      }
    });
  };

  const completeSession = () => {
    if (getCurrentUserData()) {
      if (getCurrentUserData().role == conditional_configs.role.expert) {
        sessionSocket = io(url_configs.breakout_room_server);
        sessionSocket.emit("end-session", { message: slug }, (error) => {
          if (error) {
            console.log(error);
          }
        });
        stopSessionByExpert();
        zoomClient.leave(true);
      } else {
        return;
      }
    }
  };

  const stopSessionByExpert = () => {
    let id = localStorage.getItem(
      storage_configs.localStorage.sessions.membercoursestreamdetailid
    );
    if (id) {
      console.log("expert end id" + id);
      let model = {
        membercoursestreamdetailid: id.toString(),
      };
      SessionService.stopSessionByExpert(model)
        .then((response) => {
          console.log("expert session stopped");
          console.log(response);

          clearClassRoomSessionData();
          navigate(navigate_route_config.expert.my_courses);
          socket_conection = io(url_configs.breakout_room_server);

          socket_conection.emit(
            "check-session-started",
            { data: response, status: false },
            (error) => {
              if (error) {
                console.log(error);
              }
            }
          );
        })
        .catch((e) => {
          navigate(navigate_route_config.expert.my_courses);
          console.log(e);
        });
    }
  };
  //#endregion

  //#region ZOOM VIDEO
  const swichOfVideo = () => {
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
    }, 3000);
    if (zoomStream && zoomStream.isCapturingVideo()) {
      //const session = zoomClient.getSessionInfo();
      ZoomService.stopExpertSelfVideo(zoomStream);
      //zoomStream.stopVideo();
      dispatch(setCameraStatus(false));
      //stopRenderVideo(zoomStream, zoomClient, true, session.userId);
    }
  };

  const swichOnVideo = () => {
    dispatch(setCameraStatus(true));

    ZoomService.renderExpertSelfVideo(zoomStream, zoomClient);

    //renderVideo(zoomStream, zoomClient, true);
    setIsVideoLoading(true);
    setTimeout(() => {
      setIsVideoLoading(false);
      // zoomStream.startVideo({
      //   videoElement: document.querySelector("#e-self-view-video"),
      // });
    }, 3000);
  };

  const stopRenderVideo = (stream, client, isHost, userId) => {
    if (!stream.isCameraTaken()) {
      // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
      if (
        (!stream.isSupportMultipleVideos() &&
          typeof OffscreenCanvas === "function") ||
        /android/i.test(navigator.userAgent)
      ) {
        // if MediaStreamTrackProcessor is not supported
        if (!(typeof MediaStreamTrackProcessor === "function")) {
          // render video on HTML Canvas Element
          setTimeout(() => {
            stream.stopRenderVideo(
              document.querySelector(
                isHost
                  ? "#e-self-view-canvas"
                  : "#participants-canvas-" + userId
              ),
              userId
            );
            document.querySelector(
              isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
            ).style.display = "none";
          }, 2000);
        } else {
          // show HTML Video Element in DOM
          stream.stopRenderVideo(
            document.querySelector(
              isHost ? "#e-self-view-video" : "#participants-video-" + userId
            ),
            userId
          );
          document.querySelector(
            isHost ? "#e-self-view-video" : "#participants-video-" + userId
          ).style.display = "none";
        }
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
      } else {
        // render video on HTML Canvas Element
        stream.stopRenderVideo(
          document.querySelector(
            isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
          ),
          userId
        );
        document.querySelector(
          isHost ? "#e-self-view-canvas" : "#participants-canvas-" + userId
        ).style.display = "none";
      }
    }
  };

  const renderVideo = (stream, client, isHost) => {
    if (!stream.isCameraTaken()) {
      const session = client.getSessionInfo();
      setTimeout(() => {
        stream
          .startVideo({
            videoElement: document.querySelector(
              isHost
                ? "#e-self-view-video"
                : "#participants-video-" + session.userId
            ),
            hd: stream.isSupportHDVideo(),
          })
          .then(() => {
            // if MediaStreamTrackProcessor is not supported
            if (!(typeof MediaStreamTrackProcessor === "function")) {
              // render video on HTML Canvas Element
              stream
                .renderVideo(
                  document.querySelector(
                    isHost
                      ? "#e-self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ),
                  session.userId,
                  320,
                  180,
                  0,
                  0,
                  2
                )
                .then(() => {
                  // show HTML Canvas Element in DOM
                  document.querySelector(
                    isHost
                      ? "#e-self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ).style.display = "block";
                  document.querySelector(
                    isHost
                      ? "#e-self-view-video"
                      : "#participants-video-" + session.userId
                  ).style.display = "none";
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              // show HTML Video Element in DOM
              stream
                .renderVideo(
                  document.querySelector(
                    isHost
                      ? "#e-self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ),
                  session.userId,
                  320,
                  180,
                  0,
                  0,
                  2
                )
                .then(() => {
                  // show HTML Canvas Element in DOM
                  document.querySelector(
                    isHost
                      ? "#e-self-view-canvas"
                      : "#participants-canvas-" + session.userId
                  ).style.display = "block";
                  document.querySelector(
                    isHost
                      ? "#e-self-view-video"
                      : "#participants-video-" + session.userId
                  ).style.display = "none";
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    }
  };

  // const renderVideo = (stream, client, isHost) => {
  //   if (!stream.isCameraTaken()) {
  //     const session = client.getSessionInfo();
  //     // if Desktop Chrome, Edge, and Firefox with SharedArrayBuffer not enabled, Android browsers, and on devices with less than 4 logical processors available
  //     if ((!stream.isSupportMultipleVideos() && (typeof OffscreenCanvas === 'function')) || /android/i.test(navigator.userAgent)) {
  //       // start video - video will render automatically on HTML Video Element if MediaStreamTrackProcessor is supported
  //       stream.startVideo({ videoElement: document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId),hd:true }).then(() => {
  //         // if MediaStreamTrackProcessor is not supported
  //         if (!(typeof MediaStreamTrackProcessor === 'function')) {
  //           // render video on HTML Canvas Element
  //           stream.renderVideo(document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId), session.userId, 1280, 720, 0, 0, 3).then(() => {
  //             // show HTML Canvas Element in DOM
  //             document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId).style.display = 'block';
  //             document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId).style.display = 'none';
  //           }).catch((error) => {
  //             console.log(error)
  //           })
  //         } else {
  //           // show HTML Video Element in DOM

  //           document.querySelector(isHost?'#e-self-view-video':'#participants-video-'+session.userId).style.display = 'block';
  //           document.querySelector(isHost?'#e-self-view-canvas':'#participants-canvas-'+session.userId).style.display = 'none';
  //         }
  //       }).catch((error) => {
  //         console.log(error)
  //       })

  //       // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
  //     } else {
  //       stream.startVideo().then(() => {
  //         // render video on HTML Canvas Element
  //         stream.renderVideo(document.querySelector(isHost? '#e-self-view-canvas':"#participants-canvas-"+session.userId), session.userId, 1280, 720, 0, 0, 3).then(() => {
  //           // show HTML Canvas Element in DOM
  //           document.querySelector(isHost?'#e-self-view-canvas':"#participants-canvas-"+session.userId).style.display = 'block';
  //           document.querySelector(isHost?'#e-self-view-video':"#participants-video-"+session.userId).style.display = 'none'
  //         }).catch((error) => {
  //           console.log(error)
  //         })
  //       }).catch((error) => {
  //         console.log(error)
  //       })
  //     }
  //     // stream.startVideo({
  //     //   videoElement: document.querySelector("#e-self-view-video"),
  //     // });
  //   }
  // }
  //#endregion

  //#region ZOOM AUDIO
  const audioToggle = async (flag) => {
    if (flag === "mute") {
      ZoomService.muteAudio(zoomStream);
      //zoomStream.muteAudio();
      //setAudio("mute");
      dispatch(setZoomAudio("mute"));
    } else {
      const firstTimeInteracted = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.zoom.firstTimeInteracted
        )
      );
      if (firstTimeInteracted == true) {
        // localStorage.setItem(storage_configs.localStorage.sessions.zoom.firstTimeInteracted, false);
        // await ZoomService.stopAudio(zoomStream);
        // await ZoomService.startAudio(zoomStream);
        // await ZoomService.unMuteAudio(zoomStream);
        // dispatch(setZoomAudio("unmute"));
        Swal.fire({
          title: error_messages.allowAudio,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Allow",
        }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.setItem(
              storage_configs.localStorage.sessions.zoom.firstTimeInteracted,
              false
            );
            await ZoomService.stopAudio(zoomStream);
            await ZoomService.startAudio(zoomStream);
            await ZoomService.unMuteAudio(zoomStream);
            dispatch(setZoomAudio("unmute"));
          }
        });
      } else {
        await ZoomService.unMuteAudio(zoomStream);
        dispatch(setZoomAudio("unmute"));
      }
      // ZoomService.unMuteAudio(zoomStream);
      //zoomStream.unmuteAudio();
      //setAudio("unmute");
    }
  };

  //#endregion

  //#region ZOOM SCREENSHARE
  const allowScreenShare = () => {
    dispatch(setShareScreenStatus(false));
    zoomStream.setSharePrivilege(3);
    //dispatch(setStream(stream));
  };

  const shareScreen = () => {
    dispatch(setShareScreenStatus(true));
    setTimeout(async () => {
      var selfShareCanvas = document.querySelector("#course-view-video");
      if (zoomStream?.getShareStatus() === "ended" && selfShareCanvas) {
        await zoomStream?.startShareScreen(selfShareCanvas);
        // await zoomStream?.startShareScreen(selfShareCanvas, { controls: {preferCurrentTab:true,surfaceSwitching:'include', selfBrowserSurface:'include', monitorTypeSurfaces:'include'}, displaySurface: "window", requestReadReceipt: true });
      }
    }, 1000);
  };

  const stopShareScreen = () => {
    dispatch(setShareScreenStatus(false));
    setTimeout(() => {
      zoomStream.stopShareScreen();
    }, 1000);
  };

  //#endregion

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
      alert("Link copied");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const shareLink = () => {
    let current_stream_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_stream_id
    );

    let generateLink =
      url_configs.frontend + "join-demo/" + current_stream_id + "/" + slug;
    copyToClipBoard(generateLink);
  };

  const openGalleryView = () => {
    dispatch(setIsGalleryView(!isGalleryView));
    if (!isGalleryView) {
      ZoomService.renderParticipantVideoInGallery(zoomStream, zoomClient, 0);
    }
  };

  const stopBreakoutRoom = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: error_messages.stopBreakoutSessionConfirmation,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      heightAuto: false,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        b_room_socket = io(url_configs.breakout_room_server);
        b_room_socket.emit(
          "stop-breakout-session",
          { message: "STOPPED" },
          (error) => {
            if (error) {
              console.log(error);
            }
          }
        );
      }
    });
  };

  const getGroupName = () => {
    const current_group_id = localStorage.getItem(
      storage_configs.localStorage.sessions.current_group_id
    );
    const currentGroups = JSON.parse(localStorage.getItem("current-groups"));
    if (current_group_id && currentGroups && currentGroups.length > 0) {
      const groupInfo = currentGroups.filter(
        (e) => e.coursegroupid == current_group_id
      )[0];
      if (groupInfo) {
        return groupInfo.groupname;
      }
    }
    return null;
  };

  const leaveCurrentGroup = (grp) => {
    dispatch(setLeaveGroup(true));
  };

  //#region INFORMATION BOX
  const handleClickOpenInformationBox = (e) => {
    setInformationBoxOpen(true);
  };

  const handleCloseInformationBox = () => {
    setInformationBoxOpen(false);
  };
  //#endregion

  // droup down
  const [anchorDroupEl, setAnchorDroupEl] = React.useState(null);
  const open = Boolean(anchorDroupEl);
  const handleClick = (event) => {
    setAnchorDroupEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorDroupEl(null);
  };

  const navigateTo = (page) => {
    let course_id = localStorage.getItem("course_id");
    let current_stream_id = localStorage.getItem("current_stream_id");
    if (page == "manage-groups") {
      if (course_id) {
        navigate(
          navigate_route_config.expert.manage_groups +
            "/" +
            course_id +
            "/" +
            current_stream_id
        );
        window.location.reload();
        //window.open(navigate_route_config.expert.manage_groups + '/' + course_id + '/' + current_stream_id);
      }
    } else if (page == "course-info") {
      var tempSlugArr = slug.split("-");
      var tempSlug = tempSlugArr.slice(0, -1).toString();
      var finalSlug = tempSlug.replaceAll(",", "-");
      //window.open('/course-detail/' + finalSlug + '-' + course_id);
      navigate("/course-detail/" + finalSlug + "-" + course_id);
    } else {
      handleClose();
    }
    handleClose();
  };
  //

  return (
    <div>
      <div className="expert-class-room-container-v2">
        <div className="row ms-0 me-0 align-items-center expert-class-room-header-padding-v2">
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0">
            <p className="expert-class-room-logo-container-v2 mb-0">
              <img
                className="expert-class-room-short-logo-v2"
                src={ShortLogo}
              ></img>
              <span className="ms-2 expert-class-room-bread-crumbs-v2 text-color-white">
                {" "}
                MEEKU{" "}
                <span className="text-color-active-breadcrumbs">
                  / CLASSROOM
                </span>
              </span>
              <span
                className="ps-1 cursor-pointer text-color-white"
                onClick={(e) => handleClickOpenInformationBox(e)}
              >
                {getIcon(icons_config.infoIcon)}
              </span>
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 text-center text-color-white">
            <div className="">
              <div className="controls-container-ex-v2">
                {/* EXPERT AUDIO ON/OFF */}

                {zoomAudioStatus === "unmute" &&
                zoomStream &&
                !zoomStream.isAudioMuted() ? (
                  <div
                    className="e-class-control-box-v2"
                    onClick={() =>
                      !stopBreakoutLoading ? audioToggle("mute") : {}
                    }
                  >
                    <FaMicrophone className="e-control-icons-v2" />
                  </div>
                ) : (
                  <div
                    className="e-class-control-box-v2"
                    onClick={() =>
                      !stopBreakoutLoading ? audioToggle("unmute") : {}
                    }
                  >
                    <FaMicrophoneSlash className="e-control-icons-v2" />
                  </div>
                )}

                {/* EXPERT VIDEO ENABLE/DISABLE */}

                {zoomStream && zoomStream.isCapturingVideo() ? (
                  <div>
                    {isVideoLoading ? (
                      <div className="e-class-control-box-v2">
                        <img src={assets.whiteDots} width="23px"></img>
                      </div>
                    ) : (
                      <div
                        className="e-class-control-box-v2 e-control-icons-v2"
                        onClick={!stopBreakoutLoading ? swichOfVideo : {}}
                      >
                        <FaVideo className="e-control-icons-v2" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {isVideoLoading ? (
                      <div className="e-class-control-box-v2">
                        <img src={assets.whiteDots} width="23px"></img>
                      </div>
                    ) : (
                      <div
                        className="e-class-control-box-v2"
                        onClick={!stopBreakoutLoading ? swichOnVideo : {}}
                      >
                        <FaVideoSlash className="e-control-icons-v2" />
                      </div>
                    )}
                  </div>
                )}
                {/* EXPERT SCREEN SHARE */}
                {(currentUserData &&
                  currentUserData?.role == conditional_configs.role.expert) ||
                currentUserData?.role == conditional_configs.role.learner ? (
                  <div className="e-class-control-box-v2">
                    <div
                      href="#"
                      onClick={(e) =>
                        !stopBreakoutLoading ? handleShareScreenMenuOpen(e) : {}
                      }
                    >
                      <img src={Share_img} alt="" width={"25px"} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* EXPERT HAND RAISE STATUS */}
                <div className="">
                  <div onClick={(e) => handleRaisedHandControlMenuClick(e)}>
                    <div className="e-class-control-box-v2">
                      {getRaisedNotifications().length > 0 ? (
                        <span className="raised-hand-notification-dot-e-v2"></span>
                      ) : (
                        ""
                      )}
                      <img src={assets.handIcon} alt="" width={"25px"} />
                    </div>
                  </div>
                </div>

                {/* EXPERT LEFT NAV ENABLE/DISABLE */}
                <div>
                  <div
                    className={
                      !isFluid
                        ? "e-class-control-box-v2"
                        : "e-class-control-box-v2 clicked-raised-hand-ex-v2"
                    }
                    onClick={setFluid}
                  >
                    {isFluid ? (
                      <img src={Left_icon_img} alt="" width={"25px"} />
                    ) : (
                      <img src={Left_icon_img} alt="" width={"25px"} />
                    )}
                  </div>
                </div>

                {/* session end  */}

                {breakoutRoomStatus !== "STARTED" ||
                getBreakoutSessionStatus() !== true ? (
                  <div>
                    <div
                      onClick={logout}
                      className="e-class-control-box-v2 control-red-bg"
                    >
                      <img src={assets.zoomControls.end} width="23px"></img>
                    </div>
                  </div>
                ) : (
                  <div
                    title={error_messages.breakoutSessionInProgress}
                    className="e-class-control-box-v2  control-red-bg-in-active"
                  >
                    <img src={assets.zoomControls.end} width="23px"></img>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 d-flex justify-content-end">
            <div className="learner-user-name-container">
              {/* End Breakout */}
              <div>
                {breakoutRoomStatus == "STARTED" ||
                getBreakoutSessionStatus() == true ? (
                  <div className="breakout-session-header-expert-main-v2 me-3">
                    {currenGroupName && getGroupName() ? (
                      ""
                    ) : !stopBreakoutLoading ? (
                      <span
                        className="end-breakout-session-by-expert-btn-v2"
                        onClick={(e) => stopBreakoutRoom(e)}
                      >
                        End Breakout
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Leave Group */}
              <div>
                {currenGroupName && getGroupName() ? (
                  <div className="header-e-opened_group_floating_lable_v2 me-3">
                    {getGroupName() ? getGroupName() : ""}

                    <span
                      className="leave-group-text-btn-v2"
                      onClick={() => leaveCurrentGroup(getGroupName())}
                    >
                      Leave Group
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* EXPERT WAITING PARTICIPANTS */}
              <div>
                {currentUserData &&
                currentUserData?.role == conditional_configs.role.expert &&
                getBreakoutSessionStatus() == true ? (
                  <div>
                    <div
                      onClick={(e) =>
                        handleWaitingParticipantsControlMenuClick(e)
                      }
                    >
                      <div className="hand-icon-container me-3">
                        {!allowParticipants &&
                        getWaitingParticipants().length > 0 ? (
                          <span className="raised-hand-notification-dot-e-v2"></span>
                        ) : (
                          ""
                        )}
                        <img
                          src={assets.notificationAlarm}
                          alt=""
                          className="meeku-header-icon"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <span className="me-1">{getCurrentUserData().name}</span> */}
              <span
                className="learner-user-name-icon"
                style={{ cursor: "pointer" }}
                onClick={handleClick}
              >
                {getInitialsFromFullName(getCurrentUserData().name)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* RAISE HAND MENU */}
      <Menu
        anchorEl={anchorEl}
        open={raisedHandCtrlMenu}
        onClose={handleRaisedHandControlMenuClose}
      >
        {getRaisedNotifications()?.map((obj, Index) => {
          return (
            <MenuItem>
              <div className="d-flex justify-content-center align-items-center">
                <div className="raise-hand-left-container-v2">
                  <p className="m-0">
                    <span className="raise-hand-left-initial-v2">
                      {getInitialsFromFullName(obj.name)}
                    </span>
                    <span className="raise-hand-name-v2">{obj.name}</span>
                  </p>
                </div>
                <div
                  className="lower-hand-container-v2"
                  onClick={(e) => handleLowerHand(e, obj)}
                >
                  Lower Hand
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Menu>

      {/* WAITING PARTICIPANTS */}
      <Menu
        anchorEl={waitingPartEl}
        open={waitingPartCtrlMenu}
        onClose={handleWaitingParticipantsControlMenuClose}
      >
        {getWaitingParticipants()?.map((obj, Index) => {
          return (
            <MenuItem>
              <div className="d-flex justify-content-center align-items-center">
                <div className="raise-hand-left-container-v2">
                  <p className="m-0">
                    <span className="raise-hand-left-initial-v2">
                      {getInitialsFromFullName(obj?.displayName)}
                    </span>
                    <span className="raise-hand-name-v2">
                      {obj?.displayName}
                    </span>
                  </p>
                </div>
                {getAllowedParticipant()?.userId !== obj?.userId ? (
                  <div
                    className="lower-hand-container-v2"
                    onClick={(e) => handleAllowParticipants(e, obj)}
                  >
                    Allow
                  </div>
                ) : (
                  ""
                )}
              </div>
            </MenuItem>
          );
        })}
      </Menu>

      {/* SHARE SCREEN OPTION MENU */}
      <Menu
        anchorEl={shareScreenEl}
        open={shareScreenOptionMenu}
        onClose={handleShareScreenMenuClose}
      >
        {zoomConfigs.shareScreenOptions.map((option, index) => (
          <MenuItem
            key={option.id}
            onClick={(e) => handleMenuItemClick(e, option)}
            selected={activeOption === option.id}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        fullScreen={fullScreen}
        open={informationBoxOpen}
        onClose={handleCloseInformationBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-center"
          id="responsive-dialog-title"
        >
          <img src={assets.alertIcon} width="90"></img>
        </DialogTitle>
        <DialogContent>
          <div>
            <span className="info-box-label">Browser:</span>
            <span className="info-box-text">{getExploreName()}</span>
          </div>
          <div>
            <span className="info-box-label">Video Permission:</span>
            <span className="info-box-text">
              {zoomStream && !zoomStream.isCaptureForbidden()
                ? "Allowed"
                : "Not allowed"}
            </span>
          </div>
          <div>
            <span className="info-box-label">Is Video Enabled:</span>
            <span className="info-box-text">
              {zoomStream && zoomStream.isCapturingVideo() ? "Yes" : "No"}
            </span>
          </div>
          <div>
            <span className="info-box-label">Is Audio Enabled:</span>
            <span className="info-box-text">
              {zoomStream && !zoomStream.isAudioMuted() ? "Yes" : "No"}
            </span>
          </div>
          <div>
            <span className="info-box-label">Active Camera:</span>
            <span className="info-box-text">{getActiveCamera(zoomStream)}</span>
          </div>
          <div>
            <span className="info-box-label">Active Microphone:</span>
            <span className="info-box-text">
              {getActiveMicrophone(zoomStream)}
            </span>
          </div>
          <div>
            <span className="info-box-label">Active Speaker :</span>
            <span className="info-box-text">
              {getActiveSpeaker(zoomStream)}
            </span>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <div className="confirm-no-btn" onClick={handleCloseInformationBox}>
            Close
          </div>
        </DialogActions>
      </Dialog>

      {/* droupdown menu*/}
      <div className="d-flex justify-content-center settings-menu">
        <Menu
          id="basic-menu"
          anchorEl={anchorDroupEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => navigateTo("course-info")}
            style={{
              padding: "10px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#3b4357",
            }}
          >
            {" "}
            <img src={Online_learning_img} alt="" className="settings-icon" />
            Course Information
          </MenuItem>

          {breakoutRoomStatus !== "STARTED" ||
          getBreakoutSessionStatus() !== true ? (
            <MenuItem
              onClick={() => navigateTo("manage-groups")}
              style={{
                padding: "10px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#3b4357",
              }}
            >
              <img src={Groups_img} alt="" className="settings-icon" />
              Manage Groups
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
      </div>
    </div>
  );
}

export default Header;
