import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";
import { getCurrentUserData } from "../utils/util";

const createStripePayment = async (data) => {
  const response = await http.post('/create-payment-intent', data, { headers: authHeader() });
  return response.data;
};

const getPlans = async () => {
  const response = await http.get('/get-plans', { headers: authHeader() });
  return response.data;
};

const checkoutSubscription = async (data) => {
  const response = await http.post('/checkout-subscription', data, { headers: authHeader() });
  return response.data;
};

const completeTheSubscriptionPayment = async (data) => {
  const response = await http.post('/complete-payment', data, { headers: authHeader() });
  return response.data;
};

const getMySubscriptionDetail = async () => {
  const response = await http.get('/get-my-subscription-detail?parentId='+ getCurrentUserData().id, { headers: authHeader() });
  return response.data;
};

const getMySubscriptionDetailByKid = async () => {
  const response = await http.get('/get-my-subscription-detail-by-kid?kidId='+ getCurrentUserData().id, { headers: authHeader() });
  return response.data;
};

const cancelSubscription = async (id) => {
  const response = await http.get('/cancel-subscription?id='+ id, { headers: authHeader() });
  return response.data;
};

const PaymentService = {
  createStripePayment,
  getPlans,
  checkoutSubscription,
  completeTheSubscriptionPayment,
  getMySubscriptionDetail,
  cancelSubscription,
  getMySubscriptionDetailByKid
};
export default PaymentService;