
import pdf from '../assets/img/file_upload/pdf.png';
import doc from '../assets/img/file_upload/doc.png';
import ppt from '../assets/img/file_upload/ppt.png';
import file from '../assets/img/file_upload/file.png';

const file_types_config = {
    pdf: pdf,
    doc: doc,
    ppt: ppt,
    default: file
};
    
export default file_types_config;