// component
import Iconify from "../../components/Iconify";
//CONFIG
import roleConfig from "../../config/conditional_config";
import navigateRouteConfig from "../../config/navigate_route_config";
import iconsConfig from "../../config/icons";
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  //ADMIN
  {
    title: "Customers",
    path: navigateRouteConfig.admin.customers,
    icon: getIcon("eva:people-fill"),
    role: roleConfig.role.admin,
  },
  {
    title: "Parents",
    path: navigateRouteConfig.admin.parents,
    icon: getIcon("raphael:parent"),
    role: roleConfig.role.admin,
  },
  {
    title: "Leaners",
    path: navigateRouteConfig.admin.learners,
    icon: getIcon("ph:student-fill"),
    role: roleConfig.role.admin,
  },
  {
    title: "Experts",
    path: navigateRouteConfig.admin.experts,
    icon: getIcon("ph:chalkboard-teacher-fill"),
    role: roleConfig.role.admin,
  },
  // {
  //   title: 'Block Requests',
  //   path: navigateRouteConfig.admin.block_requests,
  //   icon: getIcon('bxs:user-x'),
  //   role: roleConfig.role.admin
  // },
  {
    title: "Courses",
    path: navigateRouteConfig.admin.courses,
    icon: getIcon(iconsConfig.courses),
    role: roleConfig.role.admin,
  },
  // {
  //   title: 'Pods',
  //   path: navigateRouteConfig.admin.pods,
  //   icon: getIcon(iconsConfig.schedule),
  //   role: roleConfig.role.admin
  // },
  {
    title: "Games",
    path: navigateRouteConfig.admin.games,
    icon: getIcon("dashicons:games"),
    role: roleConfig.role.admin,
  },
  {
    title: "quiz",
    path: navigateRouteConfig.admin.quiz,
    icon: getIcon(iconsConfig.quiz),
    role: roleConfig.role.admin,
  },
  {
    title: "Whiteboard Templates",
    path: navigateRouteConfig.admin.whiteboard_templates,
    icon: getIcon(iconsConfig.whiteboard),
    role: roleConfig.role.admin,
  },
  {
    title: "Image Library",
    path: navigateRouteConfig.admin.image_library,
    icon: getIcon(iconsConfig.imageLibrary),
    role: roleConfig.role.admin,
  },
  {
    title: "Shared Licenses",
    path: navigateRouteConfig.admin.share_licenses_details,
    icon: getIcon(iconsConfig.shareIcon),
    role: roleConfig.role.admin,
  },
  {
    title: "Sessions",
    path: navigateRouteConfig.admin.sessions,
    icon: getIcon("carbon:prompt-session"),
    role: roleConfig.role.admin,
  },
  //PARENTS
  {
    title: "Missions",
    path: navigateRouteConfig.parent.self_learning,
    icon: getIcon(iconsConfig.missions),
    role: roleConfig.role.parent,
  },
  // {
  //   title: "My Courses",
  //   path: navigateRouteConfig.parent.my_courses,
  //   icon: getIcon(iconsConfig.courses),
  //   role: roleConfig.role.parent,
  // },
  // {
  //   title: "My Kids",
  //   path: navigateRouteConfig.parent.my_kids,
  //   icon: getIcon(iconsConfig.kid),
  //   role: roleConfig.role.parent,
  // },
  // {
  //   title: 'Other courses',
  //   path: navigateRouteConfig.parent.home,
  //   icon: getIcon(iconsConfig.home),
  //   role: roleConfig.role.parent
  // },
  // {
  //   title: 'Purchase course',
  //   path: navigateRouteConfig.parent.all_courses,
  //   icon: getIcon(iconsConfig.payment),
  //   role: roleConfig.role.parent
  // },
  // {
  //   title: "Payments",
  //   path: navigateRouteConfig.parent.payments,
  //   icon: getIcon(iconsConfig.payment),
  //   role: roleConfig.role.parent,
  // },

  // {
  //   title: "Shared Licenses",
  //   path: navigateRouteConfig.parent.share_licenses_details,
  //   icon: getIcon(iconsConfig.shareIcon),
  //   role: roleConfig.role.parent,
  // },
  {
    title: "Challenges",
    path: navigateRouteConfig.parent.self_learning_boards,
    icon: getIcon(iconsConfig.challenges),
    role: roleConfig.role.parent,
  },
  //LEARNERS
  // {
  //   title: "My Courses",
  //   path: navigateRouteConfig.learner.my_courses,
  //   icon: getIcon(iconsConfig.courses),
  //   role: roleConfig.role.learner,
  // },
  // {
  //   title: "Self Learning",
  //   path: navigateRouteConfig.learner.self_learning,
  //   icon: getIcon(iconsConfig.courses),
  //   role: roleConfig.role.learner,
  // },
  // {
  //   title: "My Schedule",
  //   path: navigateRouteConfig.learner.my_schedule,
  //   icon: getIcon(iconsConfig.schedule),
  //   role: roleConfig.role.learner,
  // },
  {
    title: "Missions",
    path: navigateRouteConfig.learner.missions,
    icon: getIcon(iconsConfig.missions),
    role: roleConfig.role.learner,
  },
  {
    title: "Challenges",
    path: navigateRouteConfig.learner.challenges,
    icon: getIcon(iconsConfig.challenges),
    role: roleConfig.role.learner,
  },
  // EXPERTS
  {
    title: "My Courses",
    path: navigateRouteConfig.expert.my_courses,
    icon: getIcon(iconsConfig.courses),
    role: roleConfig.role.expert,
  },
  {
    title: "My Schedule",
    path: navigateRouteConfig.expert.my_schedule,
    icon: getIcon(iconsConfig.schedule),
    role: roleConfig.role.expert,
  },
  //CURRICULAM CREATORS
  {
    title: "Whiteboard Templates",
    path: navigateRouteConfig.admin.whiteboard_templates,
    icon: getIcon(iconsConfig.whiteboard),
    role: roleConfig.role.curriculam_creator,
  },

];

export default navConfig;
