import conditional_configs from "./conditional_config";

const curriculam_types = {
    types: [
        {
            icon: "",
            name: "Content",
            type: conditional_configs.curriculam_types.content
        },
        {
            icon: "",
            name: "Quiz",
            type: conditional_configs.curriculam_types.quiz
        },
        {
            icon: "",
            name: "Games",
            type: conditional_configs.curriculam_types.games
        },
        {
            icon: "",
            name: "Whiteboard",
            type: conditional_configs.curriculam_types.whiteboard
        }
    ]
};
    
export default curriculam_types;