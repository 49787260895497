// DraggableAccordion.js
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
//import { ItemTypes } from './constants'; // Create a constants file for item types

const DraggableAccordion = ({ id, index, moveAccordion, children }) => {
  const [, ref] = useDrag({
    type: 'accordion',
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: 'accordion',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveAccordion(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div style={{display: 'block', zIndex: 1, position: 'relative'}} ref={(node) => ref(drop(node))}>
      {children}
    </div>
  );
};

export default DraggableAccordion;