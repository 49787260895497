import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";

import { Avatar } from "@mui/material";
import account from "../../../../_mock/account";

import { Button, Grid, Typography } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Dialog
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

import "./learners-details.css";

//ICONS
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as Yup from "yup";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  TextField,
  FormHelperText,
} from "@mui/material";
import { getCharacterValidationError } from "../../../../utils/util";
import { snackbar_options } from "../../../../config/snackbar_options";
import AdminAPIService from "../../../../services/admin-service";
//kids_table

function kids_detail_list(
  courseName,
  lastSessionDate,
  courseStatus,
  sessionStatus
) {
  return { courseName, lastSessionDate, courseStatus, sessionStatus };
}
const kids_detail_row = [
  kids_detail_list("Chinemography", "Mar 12,2022", "COMPLETED", "5/5"),
  kids_detail_list("Greek Mythology", "Mar 12,2022", "ONGOING", "2/5"),
  kids_detail_list("Greek Mythology", "Mar 12,2022", "ONGOING", "3/5"),
];
//

//  kids_data

export default function LearnersDetails() {
  let { id } = useParams();
  const navigate = useNavigate();

  //popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //popup end

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      setSelectedCustomerId(id);
      return;
    } else {
      localStorage.clear();
      navigate("/admin-login");
    }
  }, []);

  const navigateToParentDetail = (e) => {
    navigate("/users/parents/" + "3");
  };

  const [selectedCustomerId, setSelectedCustomerId] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [changeCustomerPasswordOpen, SetChangeCustomerPasswordOpen] =
    useState(false);
  const changeCustomerPasswordPopupOpen = () => {
    SetChangeCustomerPasswordOpen(true);
  };
  const changeCustomerPasswordPopupClose = () => {
    SetChangeCustomerPasswordOpen(false);
  };

  const CustomerPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: customerFormPasswordReg,
    control: customerFormPasswordCtrl,
    handleSubmit: customerFormPasswordSubmit,
    reset: customerFormReset,
    formState: { errors: CustomerFormPasswordErrors },
  } = useForm({
    resolver: yupResolver(CustomerPasswordSchema),
  });

  const CustomeronPasswordSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    try {
      // Update the kid's password using the MemberService
      await AdminAPIService.changeCustomerPassword(
        selectedCustomerId,
        data.password
      );

      // If the password update is successful, show a success message
      enqueueSnackbar(
        "password updated successfully",
        snackbar_options.success
      );
      customerFormReset();
      // Close the password change dialog
      SetChangeCustomerPasswordOpen(false);
    } catch (error) {
      // If an error occurs during password update, show an error message
      enqueueSnackbar(
        "Failed to update kid's password. Please try again later.",
        snackbar_options.error
      );

      // Optionally, log the error for debugging purposes
      console.error("Error updating kid's password:", error);
    }
  };

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light mt-3">
        <div className="container">
          <Avatar src={account.photoURL} alt="photoURL" className="me-2" />
          <p className="learner__name_title m-0">Greek Mythology</p>

          <div className="ms-auto">
            <div className="d-flex  align-items-center">
              <Typography
                className="link_text me-2"
                variant="subtitle2"
                noWrap
                onClick={(e) => navigateToParentDetail(e)}
              >
                View Parent Info
              </Typography>

              <Button
                variant="contained"
                className="block_ex_de_btn"
                onClick={handleClickOpen}
              >
                Block Expert
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <hr></hr>
      <div className="container">
        <a className="" href="#" onClick={changeCustomerPasswordPopupOpen}>
          Change Password
        </a>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="payment_table_header">
              <TableRow>
                <TableCell className="payment_table_header_text col_1">
                  Course Name
                </TableCell>
                <TableCell
                  className="payment_table_header_text col_2"
                  align="left"
                >
                  Last Session Date
                </TableCell>
                <TableCell
                  className="payment_table_header_text col_3"
                  align="left"
                >
                  Course States
                </TableCell>
                <TableCell
                  className="payment_table_header_text col_4"
                  align="left"
                >
                  Session Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kids_detail_row.map((row) => (
                <TableRow
                  key={row.courseName}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.courseName}
                  </TableCell>
                  <TableCell align="left">{row.lastSessionDate}</TableCell>
                  <TableCell style={{ color: "green" }} align="left">
                    {row.courseStatus}
                  </TableCell>
                  <TableCell align="left">{row.sessionStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog */}
        <div>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Block Expert"}
                <span className="add_kids_close_icon" onClick={handleClose}>
                  <CloseIcon />
                </span>
              </DialogTitle>

              <DialogContent>
                <textarea />
              </DialogContent>

              <div class="btn-text-left">
                <Button className="expert__submit_btn mb-3 ms-auto">
                  Submit
                </Button>
              </div>
            </Dialog>
          </div>
        </div>

        {/* change password */}
        <div>
          <Dialog
            open={changeCustomerPasswordOpen}
            fullWidth
            onClose={changeCustomerPasswordPopupClose}
            aria-labelledby="alert-dialog-title1"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title1">
              {"Change Password"}
              <span
                className="add_kids_close_icon"
                onClick={changeCustomerPasswordPopupClose}
              >
                <CloseIcon />
              </span>
            </DialogTitle>

            <DialogContent>
              <form
                onSubmit={customerFormPasswordSubmit(CustomeronPasswordSubmit)}
              >
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={customerFormPasswordCtrl}
                    name="password"
                    rules={{ required: "password is required" }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={CustomerFormPasswordErrors.password}
                        {...customerFormPasswordReg("password")}
                        helperText={
                          CustomerFormPasswordErrors.password?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Button
                  className="mb-3"
                  id="submit-button"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
