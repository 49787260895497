import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './home.css';
import Header from './header';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MdClose, MdSend } from "react-icons/md";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import video_icon_white from "../../assets/img/playimg.png";
import success_icon from "../../assets/img/success.webp";
import axios from "axios";

import { getCurrentUserData } from '../../utils/util';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

import Typography from '@mui/material/Typography';
//CONFIGS
import google_config from '../../config/google_config';
import url_configs from '../../config/url';
import meta_data from '../../config/meta_data';
import navigate_route_config from '../../config/navigate_route_config';
import roleConfig from '../../config/conditional_config';

/** SECTION - 1 */
import section_1_bg from '../../assets/img/home/section_1_bg.png';
import section_1_bg_1_mobile from '../../assets/img/home/section_1_bg_1_mobile.png';
import watch_video from '../../assets/img/home/watch_video.png';

import section_2_right_side from '../../assets/img/home/section_2_right_side.png';

/**SECTION 3 */
import section_3_img_1 from '../../assets/img/home/section_3_img_1.png';
import section_3_img_2 from '../../assets/img/home/section_3_img_2.png';
import section_3_img_3 from '../../assets/img/home/section_3_img_3.png';

/** SECTION 4 */
import section_4_img_1 from '../../assets/img/home/section_4_img_1.png';

/** SECTION 5 */
import section_5_img_1 from '../../assets/img/home/section_5_img_1.png';

/** JOIN MEEKU */
import join_meeku_bg from '../../assets/img/home/join_meeku_bg.png';
import join_meeku_inner_bg from '../../assets/img/home/join_meeku_inner_bg.png';
import join_meeku_bg_mobile from '../../assets/img/home/join_meeku_bg_mobile.png';

// FOOTER
import logo from '../../assets/img/home/logo.png';
import facebook from '../../assets/img/home/facebook.png';
import instagram from '../../assets/img/home/instagram.png';

const APIURL = url_configs.api;

const SITE_KEY = google_config.recaptcha.siteKey;

export default function Home({ className, onSubmit, submitBtn }) {
    const navigate = useNavigate();

    document.title = meta_data.company + '| Home';

    let getStartedRef = React.useRef()

    const [isClicked, setIsClicked] = React.useState('panel1');

    const homeMenu = useSelector((state) => state.settings.homePageMenu);

    const [expanded, setExpanded] = React.useState('panel1');

    //Email Form
    const [email, setEmail] = React.useState("");

    //Popup Open
    const [open, setOpen] = React.useState(false);

    //Detail Form
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");

    const [age, setAge] = React.useState("");
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleAccordionItemChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : 'panel1');
    };

    const handleChangeDesktop = (e, panel) => {
        setIsClicked(panel);
    };
    if (homeMenu == 'get-started') {
        window.scrollTo({ behavior: 'smooth', top: getStartedRef.current.offsetTop })
    }

    const goToGetStarted = () => {
        window.scrollTo({ behavior: 'smooth', top: getStartedRef.current.offsetTop })
    }

    //GOOGLE RECAPTCHA V3 
    useEffect(() => {
        if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin){
            navigate('/customers');
          }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert){
            navigate(navigate_route_config.expert.my_courses);
          }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner){
            navigate(navigate_route_config.learner.my_courses);
          }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent){
            navigate(navigate_route_config.parent.my_kids);
          }else if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.curriculam_creator){
            navigate(navigate_route_config.admin.whiteboard_templates);
          }else {
            localStorage.clear();
          }
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
        }
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    const clearFields = () => {
        setEmail("");
        setName("");
        setPhone("");
        setAge("");
    };
    //#region EMAIL FORM

    function handleChange(event) {
        setEmail(event.target.value);
    }
 
    function onEmailFormSubmit(event) {
        // 👇️ prevent page refresh
        event.preventDefault();
        setIsSubmitting(true);
        // //const result = await onSubmit(email)
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                verifycaptcha(token);
            });
        });
    }

    async function verifycaptcha(token) {
        // Call Google's API to get score
        axios
            .post(APIURL + "/landingpageonboard/verifycaptcha", {
                captcha: token,
            })
            .then((res) => {
                setIsSubmitting(false);
                if (res.status === 200 && res.data === true) {
                    setOpen(true);
                    setIsSubmitted(false);
                } else {
                    setOpen(false)
                    clearFields();
                    setIsSubmitted(false);
                }
                //console.log(res);
                //console.log(res.data);
            })
            .catch(function (error) {
                setIsSubmitting(false);
                setOpen(false)
                clearFields();
                setIsSubmitted(false);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
    }

    //#endregion

    //#region DETAIL POPUP FORM

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handlePhoneChange(event) {
        if (event.target.validity.valid) {
            setPhone(event.target.value);
        } else {
            setPhone("");
        }
    }

    function handleAgeChange(event) {
        if (event.target.validity.valid) {
            setAge(event.target.value);
        } else {
            setAge("");
        }
    }

    async function handleDetailsSubmit(event) {
        event.preventDefault();
        setIsSubmitting(true);
        axios
            .post(APIURL + "/landingpageonboard", {
                email: email,
                name: name,
                mobile: phone.toString(),
                age: age.toString(),
            })
            .then((res) => {
                setIsSubmitting(false);
                if (res.status === 200) {
                    clearFields();
                    setIsSubmitted(true);
                } else {
                    clearFields();
                    setIsSubmitted(true);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                setIsSubmitted(true);
                clearFields();
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
        //setSuccess(true)
    }

    const handleEmptyClose = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        axios
            .post(APIURL + "/landingpageonboard", {
                email: email,
            })
            .then((res) => {
                if (res.status === 200) {
                    setOpen(false);
                    //setSuccess(true);
                    clearFields();
                } else {
                    setOpen(false);
                    //setSuccess(false);
                    clearFields();
                }
                //console.log(res);
                //console.log(res.data);
            })
            .catch(function (error) {
                setOpen(false);
                //setSuccess(false);
                clearFields();
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
    };

    //#endregion

    return (
        <div className='main-container'>
            <Header />
            <div className='common-bg'>
                {/* SECTION - 1 - DESKTOP VIEW */}
                <Box className='large-screen' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                    <div className='section-1-container' sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
                        <img className='p-0 w-100' src={section_1_bg}></img>
                        <div className='desktop-section-1-container'>
                            <p className='desktop-section-1-title'>Nurture Kids For Tomorrow {homeMenu}</p>
                            <p className='white-desc' >
                                Environment for ages 8-14 where children can discover and solve challenges together. Let your kids  develop 21st-century skills!
                            </p>
                            <div className='d-flex'>
                                <div className='light-get-started-btn' onClick={() => goToGetStarted()}>
                                    <span>Get Started</span>
                                </div>
                                {/* <div className='transparent-btn'>
                                    <span>Watch Video
                                        <div className='transparent-rouund-play-btn'>
                                            <img className='p-0' src={watch_video}></img>
                                        </div>
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Box>

                {/* SECTION - 1 - MOBILE VIEW */}
                <Box className='mobile-screen' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                    <div className='mobile-section-1-container' sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
                        <div>
                            <img className='p-0 w-100' src={section_1_bg_1_mobile}></img>
                            <div className='mobile-text-section-1-container'>
                                <p className='mobile-section-1-title spacing'>Nurture Kids For Tomorrow</p>
                                <p className='white-desc spacing' >Environment for ages 8-14 where children can discover and solve challenges together. Let your kids  develop 21st-century skills!</p>
                                <div className='d-flex justify-content-center'>
                                    <div className='mobile-light-get-started-btn' onClick={() => goToGetStarted()}>
                                        <span>Get Started</span>
                                    </div>
                                    {/* <div className='mobile-transparent-btn'>
                                        <span>Watch Video
                                            <div className='mobile-transparent-rouund-play-btn'>
                                                <img className='p-0' src={watch_video}></img>
                                            </div>
                                        </span>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                        {/* <div>
<img className='p-0 w-100' src={section_1_bg_2_mobile}></img>
</div> */}

                    </div>
                </Box>

                {/* SECTION - 2 */}
                <Box sx={{ flexGrow: 1 }}>
                    <div className='section-2-container' sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
                        <div className='row align-items-center ms-0 me-0'>
                            <div className='col-lg-5 large-screen'>
                                <h1 className='section-2-title'>A space or an area for young minds to…</h1>
                                <div className='dark-get-started-btn' onClick={() => goToGetStarted()}>
                                    <span>Get Started</span>
                                </div>
                            </div>
                            <div className='col-lg-5 mobile-screen '>
                                <h1 className='section-2-title pt-3'>A space or an area for young minds to…</h1>
                                <div className='mobile-btn-container pt-3 pb-3'>
                                    <div className='mobile-dark-get-started-btn' onClick={() => goToGetStarted()}>
                                        <span>Get Started</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7 p-0'>
                                <img className='p-0' src={section_2_right_side}></img>
                            </div>
                        </div>
                    </div>
                </Box>

                <div className='spacer'></div>

                {/* SECTION - 3 - DESKTOP */}
                <Box className='large-screen'>
                    <div className='section-3-container spacing' sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
                        <div className='row ms-0 me-0'>
                            <h1 className='section-3-title'>How do we do it at Meeku?</h1>
                            <div className='col-lg-12 d-flex justify-content-center section-3-container-z-index'>
                                <div className='section-3-card'>
                                    <div>
                                        <div className={isClicked == 'panel1' ? 's-3-tab s-3-tab-1-sizing s-3-tab-1-active' : 's-3-tab s-3-tab-1-sizing'} onClick={(e) => handleChangeDesktop(e, 'panel1')}>
                                            <span className='s-3-tab-text'>
                                                Collaborate
                                            </span>
                                        </div>
                                        <div className={isClicked == 'panel2' ? 's-3-tab s-3-tab-2-sizing s-3-tab-2-active' : 's-3-tab s-3-tab-2-sizing'} onClick={(e) => handleChangeDesktop(e, 'panel2')}>
                                            <span className='s-3-tab-text'>
                                                Create
                                            </span>
                                        </div>
                                        <div className={isClicked == 'panel3' ? 's-3-tab s-3-tab-3-sizing s-3-tab-3-active' : 's-3-tab s-3-tab-3-sizing'} onClick={(e) => handleChangeDesktop(e, 'panel3')}>
                                            <span className='s-3-tab-text'>
                                                Solve Problem Together
                                            </span>
                                        </div>
                                    </div>
                                    <div className='s-3-tab-content'>
                                        {isClicked == 'panel1' ?
                                            <img src={section_3_img_1}></img> :
                                            isClicked == 'panel2' ?
                                                <img src={section_3_img_2}></img> :
                                                isClicked == 'panel3' ?
                                                    <img src={section_3_img_3}></img> : ""
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

                {/* SECTION - 3 - MOBILE VIEW */}
                <Box className='mobile-screen'>
                    <div className='section-3-container spacing'>
                        <h1 className='section-3-title mb-5 '>How do we do it at Meeku?</h1>
                        <Accordion className='accordion section-3-container-z-index' expanded={expanded === 'panel1'} onChange={handleAccordionItemChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className={expanded == 'panel1' ? 's-3-tab-1-active-mobile' : ''}
                            >
                                <Typography className='s-3-tab-text-mobile' sx={{ flexShrink: 0 }}>
                                    Collaborate
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <img src={section_3_img_1}></img>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='accordion' expanded={expanded === 'panel2'} onChange={handleAccordionItemChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className={expanded == 'panel2' ? 's-3-tab-2-active-mobile' : ''}
                            >
                                <Typography className='s-3-tab-text-mobile' sx={{ flexShrink: 0 }}>
                                    Create
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <img src={section_3_img_2}></img>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='accordion mb-3' expanded={expanded === 'panel3'} onChange={handleAccordionItemChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                className={expanded == 'panel3' ? 's-3-tab-3-active-mobile' : ''}
                            >
                                <Typography className='s-3-tab-text-mobile' sx={{ flexShrink: 0 }}>
                                    Solve Problem Together
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <img src={section_3_img_3}></img>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                </Box>

                <div className='spacer'></div>

                {/* SECTION 4 */}
                <Box sx={{ flexGrow: 1 }}>
                    <div className='section-4-container spacing'>
                        <div className='large-screen'>
                            <div className='row align-items-center dotted-bg ms-0 me-0'>
                                <div className='col-lg-5 dotted-bg'>
                                    <h1 className='section-4-title'>Discuss & Share Content</h1>
                                    <p className='white-desc'>
                                        A platform where we foster communication experts and inculcate the practice of healthy discussion.
                                    </p>
                                    <div className='large-screen'>
                                        <div className='light-get-started-btn' onClick={() => goToGetStarted()}>
                                            <span>Get Started</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7 dotted-bg section-4-container-img'>
                                <img src={section_4_img_1}></img>
                                </div>

                                {/* <div className='mobile-screen mt-3'>
    <div className='mobile-btn-container'>
        <div className='mobile-light-get-started-btn'>
            <span>Get Started</span>
        </div>
    </div>
</div> */}
                            </div>
                        </div>

                        <div className='mobile-screen'>
                            <div className='row align-items-center dotted-bg ms-0 me-0'>
                                <div className='col-12 dotted-bg section-4-container-img'>
                                    <h1 className='section-4-title'>Discuss & Share Content</h1>
                                    <p className='white-desc'>
                                        A platform where we foster communication experts and inculcate the practice of healthy discussion.
                                    </p>
                                </div>
                                <div className='col-12 dotted-bg section-4-container-img'>
                                    <img src={section_4_img_1}></img>
                                </div>
                                <div className='mobile-btn-container mt-3'>
                                    <div className='mobile-light-get-started-btn' onClick={() => goToGetStarted()}>
                                        <span>Get Started</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Box>

                <div className='spacer'></div>

                {/* SECTION 5 */}
                <Box sx={{ flexGrow: 1 }}>
                    <div className='section-5-container'>
                        <div className='large-screen'>
                            <div className='row align-items-center ms-0 me-0 dotted-bg'>
                                <div className='col-lg-6 p-0 dotted-bg'>
                                    <img src={section_5_img_1}></img>
                                </div>
                                <div className='col-lg-6 dotted-bg spacing'>
                                    <h1 className='section-4-title'>Challenges to Solve Real World Problems</h1>
                                    <p className='white-desc'>
                                        Our enrichment program is developed for kids ages 8 to 14. The purpose of our platform is to provide students an immersive environment for better decission making. We want to help kids to learn from their mistakes and not to be afraid of committing them.
                                    </p>
                                    <div className='large-screen'>
                                        <div className='light-get-started-btn large-screen' onClick={() => goToGetStarted()}>
                                            <span>Get Started</span>
                                        </div>
                                    </div>
                                    <div className='mobile-screen'>
                                        <div className='mobile-btn-container'>
                                            <div className='mobile-light-get-started-btn' onClick={() => goToGetStarted()}>
                                                <span>Get Started</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-screen'>
                            <div className='row align-items-center ms-0 me-0'>

                                <div className='col-12 dotted-bg '>
                                    <h1 className='section-4-title'>Challenges to Solve Real World Problems</h1>
                                    <p className='white-desc'>
                                        Our enrichment program is developed for kids ages 8 to 14. The purpose of our platform is to provide students an immersive environment for better decission making. We want to help kids to learn from their mistakes and not to be afraid of committing them.
                                    </p>
                                    <div className='large-screen'>
                                        <div className='light-get-started-btn large-screen' onClick={() => goToGetStarted()}>
                                            <span>Get Started</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 dotted-bg'>
                                    <img src={section_5_img_1}></img>
                                </div>
                                <div className='mobile-screen mt-3'>
                                    <div className='mobile-btn-container'>
                                        <div className='mobile-light-get-started-btn' onClick={() => goToGetStarted()}>
                                            <span>Get Started</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

                <div ref={getStartedRef} className='spacer'></div>

                {/* JOIN MEEKU */}
                <Box sx={{ flexGrow: 1 }}>
                    <div className='large-screen'>
                        <div className='section_6_container'>
                            <div className='desktop_join_meeku_container spacing'>
                                <div className='desktop_join_meeku_bg_container'>
                                    <img src={join_meeku_bg}></img>
                                    <img className='desktop_join_meeku_inner_bg' src={join_meeku_inner_bg}></img>
                                </div>
                                <div className='desktop_join_meeku_inner_text_container'>
                                    <h1 className='desktop_join_meeku_inner_title'>Join Meeku Today</h1>
                                    <p className='white-desc text-center mt-3'>
                                        Meeku is a safe environment for children ages 8-14 where children discover and solve challenges together. At Meeku, we help kids to develop 21st-century skills so that they can be tomorrow's problem solvers.
                                    </p>
                                    <form className="w-100 row m-0" onSubmit={onEmailFormSubmit} id="email-form-desktop">
                                        <div className="form-group col-lg-12 p-0">
                                            <input
                                                type="email"
                                                className="inputEmail"
                                                placeholder="Enter Email Id"
                                                required
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className='col-lg-12 d-flex p-0 justify-content-center mt-3'>
                                            <button disabled={isSubmitting} type="submit" className='submit-btn'>
                                                <span>{isSubmitting !== true ? 'Explore' : 'Explore...'}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mobile-screen'>
                        <div className='section_6_container'>
                            <div className='desktop_join_meeku_container spacing'>
                                <div className='desktop_join_meeku_bg_container'>
                                    <img src={join_meeku_bg_mobile}></img>
                                </div>
                                <div className='desktop_join_meeku_inner_text_container'>
                                    <h1 className='desktop_join_meeku_inner_title'>Join Meeku Today</h1>
                                    <p className='white-desc text-center mt-4'>
                                        Meeku is a safe environment for children ages 8-14 where children discover and solve challenges together. At Meeku, we help kids to develop 21st-century skills so that they can be tomorrow's problem solvers.
                                    </p>

                                    <form className="w-100 row m-0" onSubmit={onEmailFormSubmit} id="email-form-mobile">
                                        <div className="form-group col-lg-12 p-0">
                                            <input
                                                type="email"
                                                className="inputEmailMobile"
                                                placeholder="Enter Email Id"
                                                required
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className='form-group col-lg-12 p-0 d-flex p-0 justify-content-center mt-3'>
                                            <button disabled={isSubmitting} type="submit" className='submit-btn'>
                                                <span>{isSubmitting !== true ? 'Explore' : 'Explore...'}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

                <div className='spacer'></div>
            </div>
            {/* FOOTER */}
            <Box sx={{ flexGrow: 1 }}>
                <div className='footer-container spacing'>
                    <div className='large-screen'>
                        <div className='row ms-0 me-0'>
                            <div className='col-lg-3'>
                                <div className='footer-subscribe-section'>
                                    <img width='154px' height='45px' className='mb-2' src={logo}></img>
                                    {/* <p className='footer-value-text mb-2'>
                                        A safe online student-oriented platform aimed at helping children between 8-14 years to develop an early interest in STEM with our uniquely designed stimulative program by experts in the field.
                                    </p> */}
                                </div>
                            </div>
                            <div className='col-lg-6 text-center'>
                                <p>
                                    <span className='f-label'>Email&nbsp;:&nbsp;</span>
                                    <a href="mailto:admin@meeku.world" style={{ textDecoration: 'none' }}>
                                        <span className='f-value'>admin@meeku.world</span>
                                    </a>
                                </p>
                            </div>
                            <div className='col-lg-3'>
                                <p><span className='f-label'>Follow Us On</span></p>
                                <p className='d-flex'>
                                    <a href="https://www.facebook.com/profile.php?id=100086239975028" target="_blank"><img className='me-2' src={facebook} /></a>
                                    <a href="https://www.instagram.com/meeku_world/" target="_blank"><img src={instagram} /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-screen'>
                        <div className='footer-subscribe-section'>
                            <div className='d-flex justify-content-center'>
                                <img width='154px' height='45px' className='mb-2' src={logo}></img>
                            </div>
                            {/* <p className='footer-value-text mb-2'>
                                A safe online student-oriented platform aimed at helping children between 8-14 years to develop an early interest in STEM with our uniquely designed stimulative program by experts in the field.
                            </p> */}
                        </div>
                        <div className='row ms-0 me-0'>
                            <div className='col-6 pt-3'>
                                <div className='mb-2'>
                                    <p className='f-label mb-0'>Email&nbsp;:&nbsp;</p>
                                    <a href="mailto:admin@meeku.world" style={{ textDecoration: 'none' }}>
                                        <p className='f-value mb-0'>admin@meeku.world</p>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 pt-3'>
                                <div className='mb-1'>
                                    <span className='f-label mb-0'>Follow Us On</span>
                                </div>
                                <p className='d-flex'>
                                    <a href="https://www.facebook.com/profile.php?id=100086239975028" target="_blank">
                                        <img className='me-2' src={facebook} />
                                    </a>
                                    <a href="https://www.instagram.com/meeku_world/" target="_blank">
                                        <img src={instagram} />
                                    </a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </Box>

            {/* DETAIL SCREEN POPUP */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    {isSubmitted !== true ? (
                        <span className="close-btn" onClick={handleClose}>
                            <MdClose className="close-icon" />
                        </span>
                    ) : (
                        <span className="close-btn" onClick={handleEmptyClose}>
                            <MdClose className="close-icon" />
                        </span>
                    )}
                </DialogTitle>
                {isSubmitted !== true ? (
                    <form onSubmit={handleDetailsSubmit} id="detail-form">
                        <div className="container form-container w-100">
                            <div>
                                <img
                                    src={video_icon_white}
                                    className="play-img"
                                    alt=""
                                    width={100}
                                />{" "}
                            </div>
                            <p className="free-live-text mb-3 mt-3">
                                Do you want to Explore a Free Live Class?
                            </p>
                            <div className="container w-100">
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <input
                                            required
                                            type="text"
                                            className="w-100 rounded-sm border border-gray-300 bg-white px-4 py-3 text-sm  shadow-none"
                                            value={name}
                                            id="name"
                                            name="name"
                                            placeholder="Enter full name"
                                            autoComplete="off"
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <PhoneInput
                                            required
                                            className="w-100 rounded-sm border border-gray-300 bg-white px-4 py-3 text-sm  shadow-none"
                                            maxLength="15"
                                            defaultCountry="US"
                                            international
                                            countryCallingCodeEditable={true}
                                            placeholder="Enter phone number with country code"
                                            value={phone}
                                            onChange={setPhone} />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <input
                                            required
                                            type="text"
                                            pattern="[0-9]*"
                                            maxLength="3"
                                            className="w-100 rounded-sm border border-gray-300 bg-white px-4 py-3 text-sm  shadow-none"
                                            value={age}
                                            id="age"
                                            name="age"
                                            placeholder="Enter child age"
                                            autoComplete="off"
                                            onChange={handleAgeChange}
                                        />
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-submit">
                                        {isSubmitting !== true ? 'SUBMIT' : 'SUBMIT...'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="success_c container form-container w-100">
                        <div className="success_img_c">
                            <div className="success_img_div">
                                <img src={success_icon} className="success_icon" alt="meeku" />
                            </div>
                            <span>Thanks for joining the Meeku community. We have reserved your child a spot for Free Live Class.</span>
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
}
