import React from 'react';
//import Header from '../../components/header/header';
import LandHeader from '../../../components/landing-header/landing-header';
import ParentLogin from './parent-login-v2';
import meta_data from '../../../config/meta_data';

function App() {
    document.title = meta_data.company + '| Parent Login';
    return (
        <React.Fragment>
            <ParentLogin />
        </React.Fragment>
    );
}

export default App;
