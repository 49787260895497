import React from "react";
import "./faq.css";
import { Button, Typography, Container, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import assets from "../../config/assets";

export default function Faq() {
  return (
    <div>
      <Box className="">
        <div className="faq-container-section">
          <div className="faq-section">
            <p className="faq-section-title m-0" style={{ lineHeight: "120%" }}>
              <strong>Frequently Asked</strong> by Parents
            </p>
          </div>
          <div className="">
            {" "}
            <img
              className="multi-union-2-img-faq"
              src={assets.homev3.multi_union_2}
            ></img>
          </div>
          <div className="faq-section-container-div">
            <div className="row ms-0 me-0 mt-4">
              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        What kind of programmes does Meeku offer?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        At Meeku, we provide children with interdisciplinary
                        STEM-based collaborative, team-based learning missions
                        that emphasize practical, real-world skills development.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        What is the purpose of those programmes?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        To experience real-time problem-solving with a team of
                        curious learners, and develop your logical thinking and
                        coding skills with Meeku's program
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="row ms-0 me-0">
              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        How is course learning structured?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Our current program spans over nine-weeks and is titled
                        'Problem Solving with Robotics. It is divided into three
                        modules - Introductory, Learning and Execution. Each
                        module is for three weeks with one hour class per week.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        Are the modules individual ones or do they need to be
                        taken together?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        All modules needed to be taken together for a total of
                        nine weeks
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="row ms-0 me-0">
              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        What is the duration of each session?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        One hour per week under the guidance of a qualified
                        instructor.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <Accordion className="Accordion-Frequently-sec-faq">
                    <AccordionSummary
                      className="Accordion-Summar-Frequently-sec-faq"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="Accordion-title-Frequently-faq">
                        Is there a free trial?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        We have a free demo class for 30 minutes. Sign up for a
                        test-drive and experience Meeku for yourself!One hour
                        per week under the guidance of a qualified instructor.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <img
              className="mm-img-Frequently-faq"
              src={assets.homev3.faq_mm_icon}
            ></img>
          </div>
        </div>
      </Box>
    </div>
  );
}
