import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api from "../config/api";

const GetCourseCategory = data => {
    return http.get(api.lookups_CourseCategory, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

//LEARNERS -> GROUP SPACE -> STATUS LIST
const GetTaskStatus = data => {
    return http.get(api.lookups_get_task_status, { headers: authHeader() }).then((response) => {
        console.log(response)
        return response.data;
      });
};

//DDLists
const getAllActiveCountries = async data => {
    const response = await http.get(api.getActiveCountries, { headers: authHeader() });
    console.log(response);
    return response.data;
};

const LookUpervice = {
    GetCourseCategory,
    GetTaskStatus,
    getAllActiveCountries
};
export default LookUpervice;