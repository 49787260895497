import firebaseConfig from "../config/firebase-config";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc, addDoc, setDoc } from "@firebase/firestore"

/** INITIALIZE FIREBASE */
const initializeFirebaseApp = () => {
    try {
        const app = initializeApp(firebaseConfig);
        const firestore = getFirestore(app)
        return firestore;
    } catch (error) {
        console.error('ERROR FIREBASE INTIALIZE', error);
        return;
    }
};

/** GET ALL DOCUMENTS BY COLLECTION */
const getAllFirebaseDocumentsByCollection = async (collection_name) => {
    try {
        var getDb = initializeFirebaseApp();
        var docRef = await getDocs(collection(getDb, collection_name))
        const res = []
        docRef.forEach(docData => {
            res.push({
                id: docData.id,
                ...docData.data()
            })
        })
        return res
    } catch (error) {
        console.error('FIREBASE ERROR', error);
        return;
    }
};

/** GET SINGLE DOCUMENT BY ID */
const getSingleDocumentsById = async (collection_name, docId) => {
    try {
        var getDb = initializeFirebaseApp();
        var docRef = await getDoc(doc(getDb, collection_name, docId));
        return docRef.data();
    } catch (error) {
        console.error('FIREBASE ERROR', error);
        return;
    }
};

/** SET DOCUMENT BY CUSTOM DOCUMENT ID */
const setDocument = async (getDb, collection_name, newDocId, data) => {
    try {
        // addDoc(getDb, data)
        //     .then(docRef => {
        //         console.log(docRef)
        //         console.log("Document has been added successfully");
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        await setDoc(doc(getDb, collection_name, newDocId), data);
    } catch (error) {
        console.error('FIREBASE ERROR', error);
        return;
    }
}

/** CLONE DOCUMENT */
const cloneDocument = async (collection_name, existingDocId, newDocId) => {
    try {
        var getDb = initializeFirebaseApp();
        var docRef = await getDoc(doc(getDb, collection_name, existingDocId));
        var curData = docRef.data();
        //Create document with custome new document id with data; Here getting the existing data from existingDocId.
        await setDocument(getDb, collection_name, newDocId, curData);
    } catch (error) {
        console.error('FIREBASE ERROR', error);
        return;
    }
}

const FirebaseService = {
    setDocument,
    cloneDocument,
    initializeFirebaseApp,
    getAllFirebaseDocumentsByCollection,
    getSingleDocumentsById
};
export default FirebaseService;