
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
//Packages
import slugify from 'react-slugify';
import { useSelector, useDispatch } from "react-redux";

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
//import { columnsFromBackend } from "./task-assign-list";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
//import TaskCard from "./task-assign-card";
import Box from "@mui/material/Box";
import account from "../../../_mock/account";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//import DynTabs from '../dyn-tabs/dyn-tabs';

import {
    Card,
    Table,
    Stack,
    Avatar,
    Grid,
    TextField,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Tabs,
    Tab,
    AppBar
} from "@mui/material";

import TextareaAutosize from "@mui/material/TextareaAutosize";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
//Components
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import Iconify from '../../../components/Iconify';

//images
import Assignment_img from "../../../assets/img/group_space/assignment_img.png";
import planning_img from "../../../assets/img/group_space/planning_img.png";
import team_img from "../../../assets/img/group_space/team_img.png";
import whiteboard_img from "../../../assets/img/group_space/whiteboard_img.png";
import closeWTab_img from "../../../assets/img/white-board/tabs/close.png";
import maximizeWTab_img from "../../../assets/img/white-board/tabs/maximize.png";
import minimizeWTab_img from "../../../assets/img/white-board/tabs/minimize.png";
import lockWhiteboard_img from "../../../assets/img/white-board/tabs/lock.png";
import unlockWhiteboard_img from "../../../assets/img/white-board/tabs/unlock.png";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End
//ICONS
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//ICONS
import "./whiteboard-topics.css";

//SERVICES
import LookUpervice from "../../../services/look-up-service";
import MemberService from "../../../services/member-service";
import UtilService from "../../../services/util.service";
import GameService from '../../../services/games-service';
import ExpertService from '../../../services/expert-service';
import FirebaseService from '../../../services/firebase-service';

//CONFIG
import navigate_route_config from "../../../config/navigate_route_config";
import storage_configs from "../../../config/storage-config";
import url_configs from "../../../config/url";
import conditional_configs from "../../../config/conditional_config";
import game_config from "../../../config/game_config";
import gamesListConfig from '../../../config/games';
import error_messages from '../../../config/error_messages';

//UTILS
import { getCurrentUserData, storeZoomClient } from "../../../utils/util";
import { setAutoPlayGameRouteName, setIsFluid, setIsMaximized } from '../../../store/slices/settingsSlice';

import LoadingSpinner from '../../../pages/shared/Loader/LoadingSpinner';

import io from "socket.io-client";
// Style


let maxTabIndex = 0;
let currentTablIndex = 0;

let b_room_socket;

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  //   min-height: 86px;
  border-radius: 8px;
  max-width: 350px;
  /* background: ${({ isDragging }) =>
        isDragging ? "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: rgb(255 255 255);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  color: black;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

// const Container = styled.div`
//   display: flex;
// `;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: rgb(241 243 246);
  min-width: 300px;
  border-radius: 8px;
  padding: 13px;
  margin-right: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  font-size: 18px;
  padding: 2px 5px;
  font-weight: bold;
  align-self: flex-start;
`;

// Style end
const WhiteboardTopics = () => {

    const selectedContent = useSelector((state) => state.settings.selectedContent);

    const [isLoading, setIsLoading] = useState(false);

    const [columns, setColumns] = useState([]);

    const [groups, setGroups] = useState([]);

    const [currentGroupDetail, setCurrentGroupDetail] = useState([]);

    const [selectedTab, setSelectedTab] = useState("whiteboard");

    const [whiteboardLink, setWhiteboardLink] = useState("");

    const [whiteboardKey, setWhiteboardKey] = useState("");

    const [currentWhiteboardDetail, setCurrentWhiteboardDetail] = useState();

    const [gameLink, setGameLink] = useState("");

    const [currentStreamId, setCurrentStreamId] = useState();

    const [imageUrl, setImageUrl] = useState("");

    const [gameList, setGameList] = useState(gamesListConfig.gameList);

    let { streamId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentGroupName = useSelector((state) => state.settings.currentGroupName);
    const autoPlayGameRouteName = useSelector((state) => state.settings.autoPlayGameRouteName);
    const isFluid = useSelector((state) => state.settings.isFluid);
    const isMaximized = useSelector((state) => state.settings.isMaximized);

    //GET SLUG FROM LINK
    let { slug } = useParams();

    const [whiteBoardTabList, setWhiteBoardTabList] = useState([]);
    const [currentSessionList, setCurrentSessionList] = useState([]);
    const [currentSessionWhiteboardList, setCurrentSessionWhiteboardList] = useState([]);
    const [breakoutCurrentScreenData, setBreakoutCurrentScreenData] = useState(null);

    // Handle Tab Button Click
    const [tabId, setTabId] = React.useState(0);
    const handleTabChange = (event, newTabId) => {
        if (newTabId === "tabProperties") {
            handleAddTab();
        } else {
            currentTablIndex = newTabId;
            setTabId(newTabId);
        }
    };

    // Handle Add Tab Button
    const [tabs, setAddTab] = React.useState([]);
    const handleAddTab = () => {
        if (streamId)
            MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
                (response) => {
                    console.log(response);
                    if (selectedTab == 'whiteboard') {
                        let url = '';
                        if (getCurrentUserData().role == conditional_configs.role.expert) {
                            url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
                        } else {
                            url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
                        }
                        setWhiteboardLink(url);

                        maxTabIndex = maxTabIndex + 1;
                        setAddTab([
                            ...tabs,
                            <Tab label={`Whiteboard ${maxTabIndex}`} key={maxTabIndex} />
                        ]);
                        setTabsContent([
                            ...tabsContent,
                            <TabPanel tabId={tabId}>
                                <div>
                                    <iframe id={'id_whiteboard' + tabId} className="whiteboard-iframe" src={whiteboardLink}></iframe>
                                </div>
                            </TabPanel>
                        ]);
                    }
                    setCurrentGroupDetail(response.data);
                }
            ).catch((e) => {
                console.log(e);
            });
    };

    // Handle Add Tab Content
    const [tabsContent, setTabsContent] = React.useState([
        <TabPanel tabId={tabId}>
            <div>
                <iframe id={'id_whiteboard' + tabId} className="whiteboard-iframe" src={whiteboardLink}></iframe>
            </div>
        </TabPanel>
    ]);

    useEffect(() => {
        console.log('WHITEBOARD TOPICS-', selectedContent);
        setWhiteBoardTabList([]);
            setWhiteboardLink(null);
            setCurrentWhiteboardDetail(null);
        if (selectedContent && selectedContent.length > 0) {
            let newWhiteboardArray = [];
            selectedContent.forEach((device, index) => {
                newWhiteboardArray[index] = { ...device }
            })
            setWhiteBoardTabList(newWhiteboardArray);
            setWhiteboardLink(newWhiteboardArray[0].whiteboardLink);
            setCurrentWhiteboardDetail(newWhiteboardArray[0]);
        }

    }, []);

    /** Add Whiteboard Tab */
    const pushWhiteBoardTabContent = async (content) => {
        let data = [...whiteBoardTabList];
        setWhiteboardKey(content.id);
        let whiteBoardTabContent = null;
        if (content.type == "master") {
            whiteBoardTabContent = {
                username: content.username,
                whiteboardLink: content.whiteboardLink,
                tabName: content.tabName,
                slug: content.slug,
                type: content.type,
                id: content.id,
                active: content.active,
                is_locked: content.is_locked,
                userId: getCurrentUserData().id,
                streamId: streamId
            }
            data.push(whiteBoardTabContent);
        } else {

            // whiteBoardTabContent = {
            //   username: content.username,
            //   whiteboardLink: content.whiteboardLink,
            //   tabName: content.tabName + '-' + (whiteBoardTabList.length + 1),
            //   slug: content.slug,
            //   type: "child",
            //   id: content.id,
            //   active: content.active,
            //   is_locked: content.is_locked
            // }
            data.push(content);
        }
        setWhiteBoardTabList(data);
        //setWhiteBoardTabList([...whiteBoardTabList, whiteBoardTabContent]);


    }

    const addNewWhiteboardTab = (e) => {
        let data = [...whiteBoardTabList];
        data.forEach(element => {
            element.active = false;
        });
        let tempBoardSlug = slugify('White Board-' + streamId + '-' + getCurrentUserData().id + '-' + (whiteBoardTabList.length + 1))
        let tempWLink = generateWhiteboardLink(currentGroupName, slug, tempBoardSlug);
        setWhiteboardLink(tempWLink);
        // let whiteBoardTabContent = {
        //   username: getCurrentUserData().name,
        //   whiteboardLink: tempWLink,
        //   tabName: 'White Board-' + (whiteBoardTabList.length + 1),
        //   slug: slug,
        //   type: "child",
        //   id: (whiteBoardTabList.length + 1),
        //   active: true,
        //   is_locked: false
        // }
        //pushWhiteBoardTabContent(whiteBoardTabContent);
        let whiteBoardTabContent = {
            username: getCurrentUserData().name,
            whiteboardLink: tempWLink,
            tabName: 'White Board-' + (whiteBoardTabList.length + 1),
            slug: tempBoardSlug,
            type: "child",
            active: true,
            is_locked: false,
            streamId: streamId,
            userId: getCurrentUserData().id
        }
        MemberService.addMemberStreamBoard(whiteBoardTabContent).then(
            (response) => {
                console.log("Board Data", response.data);
                pushWhiteBoardTabContent(response.data);
            }
        ).catch((e) => {
            console.log(e);
        });
    }

    const removeWhiteboardTab = (e, index) => {
        e.stopPropagation();
        let data = [...whiteBoardTabList];
        if (data[index].type == 'child') {
            MemberService.softDeleteMemberStreamBoard(data[index].id, data[index]).then(
                (response) => {
                    data.splice(index, 1)

                    setWhiteBoardTabList(data)
                    // //let data = [...whiteBoardTabList];
                    // for(let i=0; i<response.data.length;i++){
                    //   data.push(response.data[i]);
                    // }
                    // setWhiteBoardTabList(data);
                }
            ).catch((e) => {
                console.log(e);
            });
        }


    }

    const openWhiteBoard = (e, obj, index) => {
        e.stopPropagation()
        setWhiteboardKey(obj.id);
        let data = [...whiteBoardTabList];
        data.forEach(element => {
            element.active = false;
        });
        setWhiteboardLink(data[index].whiteboardLink);
        localStorage.setItem('current-board', data[index].whiteboardLink);
        data[index].active = true;
        setWhiteBoardTabList(data);
        setCurrentWhiteboardDetail(data[index]);
    }

    const onClickWhiteboard = () => {
        alert("Please unlock the board to modify.")
    }

    const maximizeWhiteboardLock = (e, obj, index) => {
        e.stopPropagation();
        //dispatch(setIsMaximized(!isMaximized))
        dispatch(setIsFluid(true))
    }

    const miniimizeWhiteboardLock = (e, obj, index) => {
        e.stopPropagation();
        //dispatch(setIsMaximized(!isMaximized))
        dispatch(setIsFluid(false))
    }
    const toggleWhiteboardLock = (e, obj, index) => {
        e.stopPropagation();
        if (obj.type == 'template' && getCurrentUserData().role !== conditional_configs.role.expert) {
            return;
        }

        let data = [...whiteBoardTabList];
        let tempLock = data[index].is_locked;
        data[index].is_locked = !tempLock;
        setWhiteBoardTabList(data);
        if (data[index].active == true) {
            setCurrentWhiteboardDetail(data[index]);
        }

        if ((obj.type == 'template' || obj.type == 'master') && getCurrentUserData().role === conditional_configs.role.expert) {
            b_room_socket.emit("lock-whiteboard", { boardDetail: data[index], locked: !tempLock }, (error) => {
                if (error) {
                    console.log(error);
                }
            });
        }

    }

    const generateWhiteboardLink = (group, slug, tab) => {
        let url = "";
        url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + group + "&slug=" + slug + "#room=" + tab + ",-4-Gf1BCSf5ns_M4ChSamw";
        return url;
    }

    const openSelectedWhiteboard = async (obj, tab) => {
        setSelectedTab(tab);
        dispatch(setAutoPlayGameRouteName(null));

        setWhiteBoardTabList([]);

        // let tempUrl = url_configs.white_board_server + getCurrentUserData().name + "&group=" + obj?.whiteboard_template?.name + "&slug=" + slug + "#room=" + obj?.whiteboard_template?.slug + '--' + slug + ",-4-Gf1BCSf5ns_M4ChSamw";
        // let tempWhiteBoardTabContent = {
        //   username: getCurrentUserData().name,
        //   whiteboardLink: tempUrl,
        //   tabName: obj?.whiteboard_template?.name,
        //   slug: obj?.whiteboard_template?.slug + '--' + slug,
        //   id: 0,
        //   type: "template",
        //   active: false,
        //   is_locked: true
        // }
        // let tempData = [];
        // tempData.push(tempWhiteBoardTabContent);

        localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, tab);
        if (tab == 'whiteboard') {
            let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
            if (current_stream_id) {
                streamId = current_stream_id;
            }
            if (streamId) {
                MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
                    (response) => {
                        console.log('openSelectedWhiteboard-getMemberCourseGroupByStreamAndLearnerId', response);
                        if (selectedTab == 'whiteboard') {
                            localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, 'whiteboard');
                            let url = '';
                            let tempGroupName = "";
                            if (getCurrentUserData().role == conditional_configs.role.expert) {
                                url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
                                tempGroupName = currentGroupName;
                            } else {
                                url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
                                tempGroupName = response?.data?.groupname;
                            }

                            /** CLONE WHITEBOARD */
                            // FirebaseService.cloneDocument("scenes", obj?.whiteboard_template?.slug, obj?.whiteboard_template?.slug + '--' + slug + '---' + tempGroupName);

                            /** TEMPLATE BOARD */
                            let tempUrl = url_configs.white_board_server + getCurrentUserData().name + "&group=" + obj?.whiteboard_template?.name + "&slug=" + slug + "#room=" + obj?.whiteboard_template?.slug + '--' + slug + '---' + tempGroupName + ",-4-Gf1BCSf5ns_M4ChSamw";
                            let tempWhiteBoardTabContent = {
                                username: getCurrentUserData().name,
                                whiteboardLink: tempUrl,
                                tabName: obj?.whiteboard_template?.name,
                                slug: obj?.whiteboard_template?.slug + '--' + slug + '---' + tempGroupName,
                                id: 0,
                                type: "template",
                                active: false,
                                is_locked: true
                            }
                            let tempData = [];
                            tempData.push(tempWhiteBoardTabContent);

                            /** GROUPS COMMON BOARD */
                            // //setWhiteboardLink(url);
                            let whiteBoardTabContent = null;
                            whiteBoardTabContent = {
                                username: getCurrentUserData().name,
                                whiteboardLink: url,
                                tabName: tempGroupName,
                                slug: slug,
                                id: 1,
                                type: "master",
                                active: true,
                                is_locked: false
                            }
                            setWhiteboardLink(url);

                            setCurrentWhiteboardDetail(whiteBoardTabContent);
                            //let tData = [...whiteBoardTabList];
                            tempData.push(whiteBoardTabContent);

                            setTimeout(() => {
                                MemberService.getAllActiveMemberStreamBoard(getCurrentUserData().id, streamId).then(
                                    (response) => {
                                        console.log("Board Data", response.data);
                                        //let data = [...whiteBoardTabList];
                                        for (let i = 0; i < response.data.length; i++) {
                                            response.data[i].active = false;
                                            tempData.push(response.data[i]);
                                        }
                                        setWhiteBoardTabList(tempData);
                                    }
                                ).catch((e) => {
                                    console.log(e);
                                });
                            }, 2000);
                            //pushWhiteBoardTabContent(whiteBoardTabContent);
                        }
                        setCurrentGroupDetail(response.data);
                    }
                ).catch((e) => {
                    console.log(e);
                });
            }
        }


    }

    return (
        <Page title="User">
            <div>
                <Card>
                    <Scrollbar>
                        <div className='whiteboard-tabs'>
                            {whiteBoardTabList.map((wTab, wIndex) => {
                                return (
                                    <div className='whiteboard-tab-container'>
                                        <div className={wTab?.active ? 'whiteboard-active whiteboard-tab-button' : 'whiteboard-in-active whiteboard-tab-button'} onClick={(e) => openWhiteBoard(e, wTab, wIndex)}>
                                            <div className='whiteboard-tab-name'>
                                                {wTab?.name}
                                            </div>
                                            <div className='whiteboard-tab-controls'>
                                                {/* {wTab.is_locked == true ?
                                                    <span onClick={(e) => toggleWhiteboardLock(e, wTab, wIndex)}><img src={lockWhiteboard_img}></img></span> :
                                                    <span onClick={(e) => toggleWhiteboardLock(e, wTab, wIndex)}><img src={unlockWhiteboard_img}></img></span>} */}

                                                {isFluid !== true ?
                                                    <span onClick={(e) => maximizeWhiteboardLock(e, wTab, wIndex)}><img src={maximizeWTab_img}></img></span>
                                                    :
                                                    <span onClick={(e) => miniimizeWhiteboardLock(e, wTab, wIndex)}><img src={minimizeWTab_img}></img></span>
                                                }

                                                {/* {
                                                    wTab.type != 'master' && wTab.type != 'template' ? <span onClick={(e) => removeWhiteboardTab(e, wIndex)}><img src={closeWTab_img}></img></span> : ""
                                                } */}
                                            </div>
                                        </div>
                                        {/* <div className='whiteboard-add-tab' onClick={(e)=>addNewWhiteboardTab(e)}>+</div> */}
                                        {/* {
                                            (whiteBoardTabList.length - 1) == wIndex ? <div className='whiteboard-add-tab' onClick={(e) => addNewWhiteboardTab(e)}>+</div> : ""
                                        } */}
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            {currentWhiteboardDetail && currentWhiteboardDetail.is_locked == true ?
                                <div>
                                    <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                                    </div>
                                    <iframe key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe" src={whiteboardLink}></iframe>
                                </div>
                                :
                                <iframe key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe" src={whiteboardLink}></iframe>
                            }
                            {/* {getCurrentUserData().role == conditional_configs.role.expert ? <div>
                                <iframe key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe" src={whiteboardLink}></iframe>

                            </div> :
                                <div>
                                    {currentWhiteboardDetail && currentWhiteboardDetail.is_locked == true ?
                                        <div>
                                            <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                                            </div>
                                            <iframe key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe" src={whiteboardLink}></iframe>
                                        </div>
                                        :
                                        <iframe key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe" src={whiteboardLink}></iframe>
                                    }
                                </div>} */}

                        </div>
                    </Scrollbar>
                </Card>
            </div>
        </Page>

    );

    function TabPanel(props) {
        const { children, tabId } = props;
        return (
            <Box
                value={maxTabIndex}
                index={maxTabIndex}
                hidden={tabId !== currentTablIndex}
                key={maxTabIndex}
            >
                {children}
            </Box>
        );
    }

};

export default WhiteboardTopics;
