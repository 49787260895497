import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Card, Paper } from "@mui/material";
import account from "../../../../_mock/account";
import "./block-requests.css";
import { Button, Container, Grid, InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

//ICONS
import CloseIcon from "@mui/icons-material/Close";
import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";
//kids_table

function review_detail_list(reportedUser, userRole, reportedOn, reportNote) {
  return { reportedUser, userRole, reportedOn, reportNote };
}
const review_detail_row = [
  review_detail_list("Chinemography", "Expert", "Mar 12,2022", "Not providing"),
  review_detail_list(
    "Greek Mythology",
    "Expert",
    "Mar 12,2022",
    "Not providing enough"
  ),
  review_detail_list(
    "Greek Mythology",
    "Expert",
    "Mar 12,2022",
    "Not providing enough"
  ),
];
//

//  kids_data

const kids_data = [
  { id: "1", kids_Name: "Samppa", kids_course: "3 Courses" },
  { id: "2", kids_Name: "Riku Samppa", kids_course: "5 Courses" },
  { id: "3", kids_Name: "Riku", kids_course: "3 Courses" },
];
//  kids_data end
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function BlockRequest() {
  const [showtab, setShowtab] = useState(1);
  const navigate = useNavigate();

  const handletabclick = (e) => {
    setShowtab(Number(e));
  };
  //popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //popup end

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
    return;
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  return (
    <div className="container">
      <hr></hr>

      <div className="">
        <p className="block_req_name_title">Block Requests</p>
      </div>
      <div className="d-flex  align-items-center">
        <div class="btn-group" role="group" aria-label="Basic outlined example">
          <button
            type="button"
            class="btn btn-outline tab_btn"
            onClick={() => handletabclick(1)}
          >
            Review(3)
          </button>
          <button
            type="button"
            class="btn btn-outline tab_btn"
            onClick={() => handletabclick(2)}
          >
            Blocked(2)
          </button>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper
              component="form"
              className="search_by_name_b_re"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search by name"
                inputProps={{ "aria-label": "search br course name" }}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>

      <hr></hr>

      <div className="">
        <div className="tab-content" id="pills-tabContent">
          {/* table one */}
          <div
            className={
              showtab === 1 ? "tab-pane fade show active" : "tab-pane fade show"
            }
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="payment_table_header">
                  <TableRow>
                    <TableCell className="payment_table_header_text col_1">
                      Reported User
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_2"
                      align="left"
                    >
                      User Role
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_3"
                      align="left"
                    >
                      Reported on
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Report Note
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {review_detail_row.map((row) => (
                    <TableRow
                      key={row.reportedUser}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" style={{ color: "blue" }}>
                        {row.reportedUser}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.userRole}
                      </TableCell>
                      <TableCell align="left">{row.reportedOn}</TableCell>
                      <TableCell align="left">{row.reportNote}</TableCell>
                      <TableCell align="left">
                        <Button
                          className="block_btn_b_r"
                          onClick={handleClickOpen}
                        >
                          Block
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* table two */}
          <div
            className={
              showtab === 2 ? "tab-pane fade show active" : "tab-pane fade show"
            }
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="payment_table_header">
                  <TableRow>
                    <TableCell className="payment_table_header_text col_1">
                      Reported User
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_2"
                      align="left"
                    >
                      User Role
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_3"
                      align="left"
                    >
                      Reported on
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Report Note
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {review_detail_row.map((row) => (
                    <TableRow
                      key={row.reportedUser}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" style={{ color: "blue" }}>
                        {row.reportedUser}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.userRole}
                      </TableCell>
                      <TableCell align="left">{row.reportedOn}</TableCell>
                      <TableCell align="left">{row.reportNote}</TableCell>
                      <TableCell align="left">
                        <Button className="unblock_btn_b_r">Unblock</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* Dialog */}
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Block Expert"}
              <span className="add_kids_close_icon" onClick={handleClose}>
                <CloseIcon />
              </span>
            </DialogTitle>

            <DialogContent>
           
            <textarea>Reason for block
               
            </textarea>
           
            </DialogContent>

           <DialogActions>
           <Button className="block_expert_submit_btn mb-3 ms-auto">
               Submit
            </Button>
           
           </DialogActions>
              
          </Dialog>
        </div>
      </div>
    </div>
  );
}
