import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
// sections

import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarProvider, useSnackbar } from 'notistack';

// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

//Services
import AuthService from '../../../services/auth/auth.service';

//CONFIG
import navigateRouteRonfig from '../../../config/navigate_route_config';
import roleConfig from '../../../config/conditional_config';

//Utils
import { getCurrentUserData } from '../../../utils/util';

import './learner-login-v2.css';
import assets from '../../../config/assets';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
// ----------------------------------------------------------------------


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// Tooltip

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ----------------------------------------------------------------------

export default function LearnerLogin() {
  const { enqueueSnackbar } = useSnackbar();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    //email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });


  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = data => {
    AuthService.learner_login(data.username, data.password)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar('Logged in successful', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          navigate(navigateRouteRonfig.learner.missions);
          window.location.reload();
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
      navigate('/customers');
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
      navigate(navigateRouteRonfig.expert.my_courses);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
      navigate(navigateRouteRonfig.learner.self_learning);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
      navigate(navigateRouteRonfig.parent.my_kids);
    } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.curriculam_creator) {
      navigate(navigateRouteRonfig.admin.whiteboard_templates);
    } else {
      localStorage.clear();
    }
  }, []);
  return (
    <Page title="Login">
      <div className="">
        <div className="row ms-0 me-0">
          {/* backgroundImage section */}
          <div className="col-md-8 left-side-lear-v2 d-none d-md-block" style={{ backgroundImage: `url(${assets.login.learner_login_img})`, backgroundSize: 'cover', backgroundPosition: 'left' }}>
            <div className="d-flex justify-content-start">
              <img alt=""
                src={assets.login.login_cat_img}
                className="login_cat_img me-3"
              ></img>
            </div>
          </div>
          {/* Forms section */}
          <div className="col-md-4 right-side-lear-v2">
            <div className=''>
              <ContentStyle>

                <div className='d-flex justify-content-center'>
                  <Logo />
                </div>

                <Typography className='sign-text-lear-v2'>
                  Sign in as a Learner!
                </Typography>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <RHFTextField name="username" label="Username" />

                    <RHFTextField
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  {/* to="/forgot-password" */}
                  <Stack className='f-pwd-stack' direction="row" alignItems="center" display="flex" justifyContent="start" >
                    <Link variant="subtitle2" className='mt-1 mb-3' underline="hover" to="" component={RouterLink} smooth="true">
                      Forgot password?
                    </Link>
                    <BootstrapTooltip title="To update the learner's password, log in as a parent, go to your profile, and follow the steps to change the password for the learner.">
                      <InfoIcon color="secondary" sx={{ fontSize: 15 }} className='f-pwd-icon mb-4'></InfoIcon>
                    </BootstrapTooltip>
                  </Stack>

                  <LoadingButton className='login-btn-lear-v2' fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Login
                  </LoadingButton>
                </FormProvider>
              </ContentStyle>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
