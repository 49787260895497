import React from "react";

import account from "../../../_mock/account";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Footer from "../footer/footer";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import baby_img from "../../../assets/img/baby_img.png";
import { Grid, Avatar, Button, Box } from "@mui/material";
import "./courses.css";
import meta_data from "../../../config/meta_data";

export default function Courses() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg nav__header_session">
        <div className="container">
          <a href="#" className="cou_nav_title">
          {meta_data.company}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="ms-auto">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    aria-current="page"
                    href="#"
                    className="header_a_link_text me-3  nav-link"
                  >
                    cart(1)
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    aria-current="page"
                    href="#"
                    className="header_a_link_text me-3  nav-link"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Avatar src={account.photoURL} alt="photoURL" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>My Courses</MenuItem>
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </div>
      </nav>
      <div className="container">
        <div
          style={{
            backgroundImage: `url(${baby_img})`,
            padding: "30px",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row mt-5">
            {/* Introduction section */}
            <div className="col-12 col-lg-6 col-md-6">
              <div className="intro__section ">
                <p className="introduction_text">
                  Introduction to Robotics-A <br></br> lego based learning
                </p>
                <Grid container>
                  <div className="me-5">
                    <label className="price_session_text">PRICE/SESSION</label>
                    <p className="price_session_number">
                      <strong>$10</strong>/session
                    </p>
                  </div>
                  <div className="">
                    <label className="price_session_text">SESSIONS</label>
                    <p className="price_session_number">
                      <strong>10</strong>
                    </p>
                  </div>
                </Grid>

                <Button variant="outlined" className="enrool__cour_btn">
                  Enroll Course
                </Button>
              </div>
            </div>
            {/* Introduction section end */}
            {/* baby image section */}
            <div className="col-12 col-lg-6 col-md-6 mt-3"></div>
            {/* baby image section end */}
          </div>
        </div>

        <div >
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card  className="card__free_session">
                <CardMedia
                  component="img"
                  height="140"
                  image={baby_img}
                  alt="green iguana"
                />
                <CardContent>
                    <p className="card_courses__title m-0">Advanced Robotics</p>
                    <p className="card_courses__session m-0"><strong>$10</strong>/session</p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card  className="card__free_session">
                <CardMedia
                  component="img"
                  height="140"
                  image={baby_img}
                  alt="green iguana"
                />
                <CardContent>
                    <p className="card_courses__title m-0">Advanced Universe</p>
                    <p className="card_courses__session m-0"><strong>$20</strong>/session</p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card  className="card__free_session">
                <CardMedia
                  component="img"
                  height="140"
                  image={baby_img}
                  alt="green iguana"
                />
                <CardContent>
                    <p className="card_courses__title m-0">Mythololy</p>
                    <p className="card_courses__session m-0"><strong>$30</strong>/session</p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}
