
// const curriculam_content_file_types = {
//     types: [
//         {
//             icon: "",
//             name: ".PDF",
//             accept: "application/pdf",
//             type: "pdf"
//         },
//         {
//             icon: "",
//             name: ".DOC",
//             accept: "application/msword",
//             type: "doc"
//         },
//         {
//             icon: "",
//             name: ".PPT",
//             accept: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation",
//             type: "ppt"
//         }
//     ]
// };

const curriculam_content_file_types = {
    types: [
        {
            icon: "",
            name: ".PDF",
            accept: "application/pdf",
            type: "pdf"
        },
        {
            icon: "",
            name: ".PPT",
            accept: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation",
            type: "ppt"
        }
    ]
};

export default curriculam_content_file_types;