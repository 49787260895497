import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Card, Paper } from "@mui/material";

import "./sessions.css";

import { Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AdminAPIService from "../../../services/admin-service";
import moment from "moment";

import { getCurrentUserData } from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";

import { yupResolver } from "@hookform/resolvers/yup";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as Yup from "yup";
import {
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  Stack,
  Badge,
  TextField,
  FormHelperText,
  TablePagination,
} from "@mui/material";

export default function SessionsDetails() {
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      getLearnerStreamDetailsById();
    } else {
      localStorage.clear();
      navigate("/admin-login");
    }
  }, []);

  const [learnerStreamDetails, setLearnerStreamDetails] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { enqueueSnackbar } = useSnackbar();

  const getLearnerStreamDetailsById = () => {
    AdminAPIService.getLearnerStreamDetailsById(id)
      .then((response) => {
        console.log("getLearnerStreamDetailsById", response);
        setLearnerStreamDetails(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {" "}
      <Container>
        <Box sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
            mb={3}
          >
            <Typography variant="h4" gutterBottom>
              Sessions Learner Details
              <Badge
                className="badge_quiz"
                badgeContent={learnerStreamDetails.length}
                color="secondary"
              >
                {/* Badge */}
              </Badge>
            </Typography>
          </Stack>
        </Box>

        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Learner Name</TableCell>
                  <TableCell>Learner Email</TableCell>
                  <TableCell>Start Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {learnerStreamDetails
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((stream) => {
                    const isSelected = selected.indexOf(stream.id) !== -1;
                    return (
                      <TableRow
                        key={stream.id}
                        hover
                        onClick={(event) => handleClick(event, stream.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        selected={isSelected}
                      >
                        <TableCell>{stream.learnername}</TableCell>
                        <TableCell>{stream.email}</TableCell>
                        <TableCell>
                          {" "}
                          {moment(stream.startdatetime).format("D/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={learnerStreamDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </div>
  );
}
