import http from "../router/http-common";
import api_path from "../config/api";

const encryptJsonValue = data => {
    return http.post("/util/encryptToken", data).then((response) => {
        return response;
      });
};

const getCurrentIpAddress = async () => {
    const res = await http.get(api_path.getCurrentIpAddress);
    console.log(res.data);
    return res.data.ip;
};

const getCurrentCountryInfoByIpAddress = async () => {
    const locationInfo = JSON.parse(localStorage.getItem('location-info'));
    if(locationInfo){
        return {data: locationInfo};
    }else{
        const res = await http.get(api_path.getCurrentCountryInfoByIpAddress);
        localStorage.setItem('location-info', JSON.stringify(res.data));
        return res;
    }
};

const UtilService = {
    encryptJsonValue,
    getCurrentIpAddress,
    getCurrentCountryInfoByIpAddress
};
export default UtilService;