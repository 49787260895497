import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";
import { getCurrentUserData } from "../utils/util";

const startSessionByExpert = data => {
    return http.post(api_path.startSessionByExpert, data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const stopSessionByExpert = data => {
    return http.post(api_path.stopSessionByExpert, data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const joinSessionByLearner = data => {
    return http.post(api_path.joinSessionByLearner, data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const endSessionByLearner = data => {
    return http.post(api_path.endSessionByLearner, data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getCourseContentBySessionId = data => {
    return http.get(api_path.getCourseContentBySessionId + data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAdminCourseContentBySessionId = data => {
    return http.get("/courses/course/GetCourseContentBySessionId/" + data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getCourseContentBymembercoursestreamdetailid = data =>{
    return http.get(api_path.getCourseContentBymembercoursestreamdetailid + data, { headers: authHeader() }).then((response) => {
        return response.data;
    });
}

const getSessionDocuments = async (data) => {
    const response = await http.get(api_path.getSessionDocuments+ data, { headers: authHeader() });
    return response.data;
};

const uploadDocumnetInSession = async (data) => {
    const response = await http.post(api_path.uploadDocumnetInSession, data, { headers: authHeader() });
    return response.data;
};

const getPendingSchedulesExpert = async (expertid) => {
    const response = await http.get(api_path.getPendingSchedulesExpert+ expertid, { headers: authHeader() });
    return response.data;
};

const getPendingSchedules = async (expertid, coursestreamdetailid) => {
    const response = await http.get(api_path.getPendingSchedules+ expertid +'/'+coursestreamdetailid, { headers: authHeader() });
    return response.data;
};

const sendExpertCurrentContent = async (data) => {
    const response = await http.post('/expert-current-page', data, { headers: authHeader() });
    return response.data;
};

// CHATS
const addChats = async (data) => {
    const response = await http.post(api_path.addChats, data, { headers: authHeader() });
    return response.data;
};

const getChatsByStreamId = async (courseStreamId) => {
    const response = await http.get(api_path.getChatsByStreamId + courseStreamId, { headers: authHeader() });
    return response.data;
};

// QUIZ
const updateQuizBySlugAndLearner = async (slug, data) => {
    const response = await http.post(api_path.updateQuizBySlugAndLearner + slug+'&userId='+getCurrentUserData().id, data, { headers: authHeader() });
    return response.data;
};

const getQuizBySlugAndLearner = async (slug) => {
    const response = await http.get(api_path.getQuizBySlugAndLearner + slug+'&userId='+getCurrentUserData().id, { headers: authHeader() });
    return response.data;
};

const getQuizBySlug = async (slug) => {
    const response = await http.get(api_path.getQuizBySlug + slug, { headers: authHeader() });
    return response.data;
};

const SessionService = {
    startSessionByExpert,
    stopSessionByExpert,
    joinSessionByLearner,
    endSessionByLearner,
    getCourseContentBySessionId,
    getAdminCourseContentBySessionId,
    getCourseContentBymembercoursestreamdetailid,
    getSessionDocuments,
    uploadDocumnetInSession,
    getPendingSchedulesExpert,
    getPendingSchedules,
    sendExpertCurrentContent,
    addChats,
    getChatsByStreamId,
    updateQuizBySlugAndLearner,
    getQuizBySlugAndLearner,
    getQuizBySlug
};
export default SessionService;