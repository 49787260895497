import React, { useEffect, useState } from 'react'


import WhiteBoard from '../../../../components/whiteboard/WhiteBoard1'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import './breakout-room.css'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

import {
  Stack
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

//IMAGES
import muteImg from '../../../../assets/img/courses_img/un_speaker_img.png';
import unMuteImg from '../../../../assets/img/courses_img/speaker_img.png';
import joinUserImg from '../../../../assets/img/courses_img/user_img.png';
//UTILS
import storage_configs from '../../../../config/storage-config';
import { getInitialsFromFullName } from '../../../../utils/util';

export default function BreakOut() {
  const dispatch = useDispatch();
  const zoomClient = useSelector((state) => state.zoom.zoomClient);
  const [isMute, setIsMute] = useState(false);
  const navigate = useNavigate();

  const endBreakout = () => {
    Swal.fire({
      title: 'End Breakout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/expert");
      }
    })
  }

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [currentSubSessionsList, setCurrentSubSessionsList] = useState([]);
  const [selectedGroup, setselectedGroup] = useState("");

  const getTime = () => {
    const time = new Date();
    setHours((time.getHours()) % 12)
    setMinutes(time.getMinutes());
    setSeconds(time.getSeconds());
  };

  const changeToMute = () => {
    setIsMute(true);
  }

  const changeToUnMute = () => {
    setIsMute(false);
  }

  useEffect(() => {
    setCurrentSubSessionsList(JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.zoom.getSubsessionList)));
    let subsession = zoomClient.getSubsessionClient();
    console.log(subsession.getSubsessionList());

    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  const selectGroup = (group, id) => {
    setTimeout(() => {
      let subsession = zoomClient.getSubsessionClient();
      //Join Sub Session
      // subsession.joinSubsession(id);
      console.log(subsession.getSubsessionList());
      let participants = zoomClient.getAllUser();
      //let participants = zmClient.getParticipantsList();
      console.log("participants", participants);
    }, 3000);
    setselectedGroup(group)
  }

  return (
    <div>
      <div className="breakout-progress-navbar mt-3">
        <div>
          <p>Breakout session in progress</p>
        </div>
        <div>
          <p>{minutes + ':' + seconds} sec</p>
        </div>
        <div>
          <button style={{ background: 'rgb(250, 236, 34)', border: '1px', fontSize: '12px', fontWeight: '600', color: '#522bc1', width: '100%' }}>View Rooms</button>
        </div>
        <div>
          <button style={{ background: 'rgb(250, 236, 34)', border: '1px', fontSize: '12px', fontWeight: '600', color: '#522bc1', width: '100%' }} onClick={endBreakout}>End Breakout</button>
        </div>
      </div>

      <div className='white-boards-tab'>
        <Row className='m-0'>
          {currentSubSessionsList?.map((sObj, sIndex) => {
            return (
              <Col sm={6}>
                <div className='whiteboard-grid m-0'>
                  <Card body onClick={(e) => selectGroup(sObj.subsessionName, sObj.subsessionId)} className={selectedGroup === sObj.subsessionName ? "group-item-active" : "group-item"} >
                    <div className='group-title'>{sObj.subsessionName}</div>
                    <div className='group-actions-main'>
                      {isMute ? <img className='group-actions' src={muteImg} onClick={(e) => changeToUnMute()} ></img> : ""}
                      {!isMute ? <img className='group-actions' src={unMuteImg} onClick={(e) => changeToMute()}></img> : ""}
                      <img className='group-actions' placeholder='Join' src={joinUserImg}></img>
                    </div>
                    <div>
                      <div className='break-out-room-users'>
                        <Stack direction="row" spacing={1}>
                          {sObj?.userList.map((uObj, uIndex) => {
                            return (
                              <span className='b-users'>{getInitialsFromFullName(uObj.displayName)}</span>
                            )
                          })}
                        </Stack>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            )
          })}
        </Row>
        {/* <WhiteBoard /> */}
      </div>

    </div>
  );
}
