import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { setHomePageMenu } from "../../store/slices/settingsSlice";

//import Logo from '../../assets/svg/Logo';
import Logo from '../../assets/img/home/logo.png';
import Search from '../../assets/img/home/search.png';
import Cart from '../../assets/img/home/cart.png';
import MenuIcon from '../../assets/img/home/menu.png';
import './header.css';

const drawerWidth = 240;
const navItems = ['Courses'];

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FDFDFD',
    },
  },
});

function DrawerAppBar(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const clickMenu = (menu) => {
    dispatch(setHomePageMenu(menu));
    setTimeout(() => {
      dispatch(setHomePageMenu(''));
    }, 2000);
  };

  const signUp = () => {
    navigate('/parent-registration')
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography justifyContent='center' display="flex" variant="h6" sx={{ my: 2 }}>
        <img className='mobile-logo' src={Logo}></img>
      </Typography>
      <Divider />
      {/* <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            {/* DESKTOP LOGO */}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <img className='desktop-logo ms-5' src={Logo}></img>
            </Typography>
            {/* MOBILE LOGO */}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}
            >
              <img className='mobile-logo' src={Logo}></img>
            </Typography>
            {/* DESKTOP MENUS */}
            {/* <Box sx={{flexGrow: 1,  display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button className='desktop-menu' key={item} >
                  {item}
                </Button>
              ))}
            </Box> */}
            {/* <Box className='desktop-search' sx={{flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
            <img src={Search}></img>
            </Box>
            <Box className='desktop-cart' sx={{flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
            <img src={Cart}></img>
            </Box> */}
            <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
              <div className='desktop-sign-in-container' onClick={() => signUp()}>
                <span>Signup</span>
              </div>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'block', sm: 'none' } }} onClick={() => signUp()}>
              <div className='mobile-sign-in-container'>
                <span>Signup</span>
              </div>
            </Box>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <img src={MenuIcon}></img>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
