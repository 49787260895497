import { useState, useEffect } from "react";
import { filter } from "lodash";
// import { sentenceCase } from "change-case";
// import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AdminAPIService from "../../../services/admin-service";
import moment from "moment";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Grid,
  TextField,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

//ICONS
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import PasswordIcon from "@mui/icons-material/Password";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { snackbar_options } from "../../../config/snackbar_options";
// components
import Page from "../../../components/Page";
//import Label from '../../../components/Label';
import Scrollbar from "../../../components/Scrollbar";
//import Iconify from '../../../components/Iconify';
import SearchNotFound from "../../../components/SearchNotFound";
import { SnackbarProvider, useSnackbar } from "notistack";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
// mock
//import USERLIST from '../../_mock/user';

import "./experts.css";
import Experts_img from "../../../assets/img/experts_img.png";
import Iconify from "../../../components/Iconify";

import { getCurrentUserData } from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";

//CONFIG
import navigate_route_config from "../../../config/navigate_route_config";
import meta_data from "../../../config/meta_data";
import ConfirmationPopup from "../../../components/confirmation-popup/confirmation-popup";

import { getCharacterValidationError } from "../../../utils/util";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "firstname", label: "First Name", alignRight: false },
  { id: "lastname", label: "Last Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "reg_dt", label: "Reg. Date", alignRight: false },
  // { id: 'no_of_kids', label: 'Number of kids', alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "dateTime" },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Experts() {
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUserList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      AdminAPIService.getallexperts()
        .then((response) => {
          setUserList(response.data.data);
          console.log(USERLIST);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate("/admin-login");
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.firstname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  //popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //popup end

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Expert name is required"),

    email: Yup.string()
      .required("Expert email is required")
      .email("Email is invalid"),
  });

  const {
    register,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
    AdminAPIService.inviteExperts(data)
      .then((response) => {
        if (response) {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar(response.message, {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          window.location.reload();
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigateToExpertDetail = (e) => {
    navigate("/users/expert-details");
  };

  const [showtab, setShowtab] = useState(1);

  const handletabclick = (e) => {
    setShowtab(Number(e));
  };

  //exoert update popup
  const [editopen, setEditOpen] = useState(false);

  const handleClickEditFormOpen = () => {
    setEditOpen(true);
  };

  const handleEditFormClose = () => {
    setEditOpen(false);
  };

  //Expert update popup end

  const expertInformationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register: expertFormReg,
    control: expertFormCtrl,
    handleSubmit: expertSubmit,
    formState: { errors: expertFormErrors },
  } = useForm({
    resolver: yupResolver(expertInformationSchema),
  });

  const onExperttSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
    console.log(data);
    enqueueSnackbar("Expert update successfully", snackbar_options.success);
  };

  // confirmation box
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDeleteOpenDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const handleDelete = () => {
    setConfirmationDialogOpen(false); // Close the dialog after deletion.
  };

  const handleCancel = () => {
    setConfirmationDialogOpen(false); // Close the dialog without deleting.
  };
  // confirmation box end

  const [selectedCustomerName, setSelectedCustomerName] = useState(false);
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(false);

  const [changeCustomerPasswordOpen, SetChangeCustomerPasswordOpen] =
    useState(false);

  const changeCustomerPasswordPopupOpen = (name, id, email) => {
    setSelectedCustomerName(name);
    setSelectedCustomerId(id);
    setSelectedCustomerEmail(email);
    SetChangeCustomerPasswordOpen(true);
  };
  const changeCustomerPasswordPopupClose = () => {
    SetChangeCustomerPasswordOpen(false);
  };

  const CustomerPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: customerFormPasswordReg,
    control: customerFormPasswordCtrl,
    handleSubmit: customerFormPasswordSubmit,
    reset: customerFormReset,
    formState: { errors: CustomerFormPasswordErrors },
  } = useForm({
    resolver: yupResolver(CustomerPasswordSchema),
  });

  const CustomeronPasswordSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    try {
      // Update the password
      await AdminAPIService.changeCustomerPassword(
        selectedCustomerId,
        data.password
      );

      // If the password update is successful, show a success message
      enqueueSnackbar(
        "password updated successfully",
        snackbar_options.success
      );
      customerFormReset();
      // Close the password change dialog
      SetChangeCustomerPasswordOpen(false);
    } catch (error) {
      // If an error occurs during password update, show an error message
      enqueueSnackbar(
        "Failed to update kid's password. Please try again later.",
        snackbar_options.error
      );

      // Optionally, log the error for debugging purposes
      console.error("Error updating kid's password:", error);
    }
  };

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          mt={3}
        >
          <Typography variant="h4" gutterBottom className="mb-0">
            Experts
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleClickOpen}
          >
            Invite to {meta_data.company}
          </Button>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={handleClickOpen}
          >
            invite to meeku
          </Button> */}
        </Stack>

        <div className="d-flex  align-items-center ms-auto pb-2">
          <div
            class="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              class="btn btn-outline tab_btn_exp"
              onClick={() => handletabclick(1)}
            >
              Active ({USERLIST.length})
            </button>
            <button
              type="button"
              class="btn btn-outline tab_btn_exp"
              onClick={() => handletabclick(2)}
            >
              Pending
            </button>
            <button
              type="button"
              class="btn btn-outline tab_btn_exp"
              onClick={() => handletabclick(3)}
            >
              Blocked
            </button>
          </div>
        </div>

        <div className="mt-4">
          <div className="tab-content" id="pills-tabContent">
            {/* courses section */}
            <div
              className={
                showtab === 1
                  ? "tab-pane p-0 fade show active"
                  : "tab-pane fade show"
              }
            >
              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  filterPlaceholder="Search Expert"
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              id,
                              firstname,
                              lastname,
                              email,
                              createdAt,
                              status,
                            } = row;
                            const isItemSelected =
                              selected.indexOf(firstname) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onChange={(event) =>
                                      handleClick(event, firstname)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  onClick={(e) => navigateToExpertDetail(e)}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                                    <Typography
                                      className="link_text"
                                      variant="subtitle2"
                                      noWrap
                                    >
                                      {firstname}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{lastname}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left" type="date">
                                  {moment(createdAt).format("D/MM/YYYY")}
                                </TableCell>
                                <TableCell align="left">
                                  {status === 1 ? "Active" : "Inactive"}
                                </TableCell>
                                {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}

                                <TableCell align="left">
                                  <Tooltip
                                    title="Change Password"
                                    placement="top-start"
                                  >
                                    <PasswordIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        changeCustomerPasswordPopupOpen(
                                          row.firstname,
                                          row.id,
                                          row.email
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </TableCell>

                                <TableCell align="right">
                                  <UserMoreMenu
                                    handleClickEditFormOpen={
                                      handleClickEditFormOpen
                                    }
                                    handleDeleteOpenDialog={
                                      handleDeleteOpenDialog
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={USERLIST.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </div>

            <div
              className={
                showtab === 2
                  ? "tab-pane p-0 fade show active"
                  : "tab-pane fade show"
              }
            >
              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  filterPlaceholder="Search Expert"
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              id,
                              firstname,
                              lastname,
                              email,
                              createdAt,
                              status,
                            } = row;
                            const isItemSelected =
                              selected.indexOf(firstname) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onChange={(event) =>
                                      handleClick(event, firstname)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  onClick={(e) => navigateToExpertDetail(e)}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                                    <Typography
                                      className="link_text"
                                      variant="subtitle2"
                                      noWrap
                                    >
                                      {firstname}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{lastname}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left" type="date">
                                  {moment(createdAt).format("D/MM/YYYY")}
                                </TableCell>
                                <TableCell align="left">
                                  {status === 1 ? "Active" : "Inactive"}
                                </TableCell>
                                {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}
                                <TableCell align="left">
                                  <Tooltip
                                    title="Change Password"
                                    placement="top-start"
                                  >
                                    <PasswordIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        changeCustomerPasswordPopupOpen(
                                          row.firstname,
                                          row.id,
                                          row.email
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                  <UserMoreMenu />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={USERLIST.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </div>

            <div
              className={
                showtab === 3
                  ? "tab-pane p-0 fade show active"
                  : "tab-pane fade show"
              }
            >
              test
            </div>
          </div>
        </div>

        {/* Dialog */}
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Invite Expert to Meeku"}
              <span className="add_kids_close_icon" onClick={handleClose}>
                <CloseIcon />
              </span>
            </DialogTitle>
            <div className="mx-auto">
              <img src={Experts_img}></img>
            </div>
            <DialogContent>
              {/* FORM SECTION */}

              <Grid container spacing={1}>
                {/* FIRST NAME TEXT FIELD */}
                <Grid item xs={12} sm={6} className="">
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Expert Email"
                    fullWidth
                    margin="dense"
                    {...register("email")}
                    error={errors.email ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.expertemail?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="">
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Expert Name"
                    fullWidth
                    margin="dense"
                    {...register("name")}
                    error={errors.name ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.expertname?.message}
                  </Typography>
                </Grid>
                {/* <span className="delete_icon">
                  <DeleteIcon color="action" />
                </span> */}
              </Grid>

              {/* FORM SECTION END*/}
            </DialogContent>

            <div class="btn-text-left">
              <Button
                onClick={handleSubmit(onSubmit)}
                className="send_invite_btn mb-3 ms-auto"
              >
                Send Invite
              </Button>
            </div>
          </Dialog>
        </div>

        {/*EDIT Dialog */}
        <div>
          <Dialog
            open={editopen}
            onClose={handleEditFormClose}
            aria-labelledby="alert-dialog-title1"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title1">
              {"Expert Edit Form"}
              <span
                className="add_kids_close_icon"
                onClick={handleEditFormClose}
              >
                <CloseIcon />
              </span>
            </DialogTitle>

            <DialogContent>
              <form onSubmit={expertSubmit(onExperttSubmit)}>
                <Grid container spacing={1}>
                  {/* first_name */}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={expertFormCtrl}
                      name="firstname"
                      rules={{ required: "First name is required" }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          id="firstname"
                          label="First name"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={expertFormErrors.firstname}
                          helpertext={expertFormErrors.firstname?.message}
                        />
                      )}
                    />
                    <Typography variant="inherit" color="#FF4842;">
                      {expertFormErrors.firstname?.message}
                    </Typography>
                  </Grid>
                  {/* LAST NAME TEXT FIELD */}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={expertFormCtrl}
                      name="lastname"
                      rules={{ required: "Last name is required" }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          id="lastname"
                          label="Last name"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={expertFormErrors.lastname}
                          helpertext={expertFormErrors.lastname?.message}
                        />
                      )}
                    />
                    <Typography variant="inherit" color="#FF4842;">
                      {expertFormErrors.lastname?.message}
                    </Typography>
                  </Grid>
                  {/* EMAIL FIELD */}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={expertFormCtrl}
                      name="email"
                      rules={{ required: "Email is required" }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          fullWidth
                          margin="dense"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          {...expertFormReg("email")}
                          error={expertFormErrors.email ? true : false}
                          helpertext={expertFormErrors.email?.message}
                        />
                      )}
                    />
                    <Typography variant="inherit" color="#FF4842;">
                      {expertFormErrors.email?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  className="mb-3"
                  id="submit-button"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>

        {/* change password */}
        <div>
          <Dialog
            open={changeCustomerPasswordOpen}
            fullWidth
            onClose={changeCustomerPasswordPopupClose}
            aria-labelledby="alert-dialog-title1"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title1">
              {"Change Password for " + selectedCustomerName}
              <span
                className="add_kids_close_icon"
                onClick={changeCustomerPasswordPopupClose}
              >
                <CloseIcon />
              </span>
            </DialogTitle>

            <DialogContent>
              {selectedCustomerEmail}
              <form
                onSubmit={customerFormPasswordSubmit(CustomeronPasswordSubmit)}
              >
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={customerFormPasswordCtrl}
                    name="password"
                    rules={{ required: "password is required" }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={CustomerFormPasswordErrors.password}
                        {...customerFormPasswordReg("password")}
                        helperText={
                          CustomerFormPasswordErrors.password?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Button
                  className="mb-3"
                  id="submit-button"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>

        <ConfirmationPopup
          open={confirmationDialogOpen}
          onDelete={handleDelete}
          onCancel={handleCancel}
          onClose={() => setConfirmationDialogOpen(false)}
        ></ConfirmationPopup>
      </Container>
    </Page>
  );
}
