import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmationPopup = ({ open, onDelete, onCancel, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <div className="delete-confirmation">
          <p>Are you sure you want to delete this item?</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} color="secondary">
          Delete
        </Button>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
