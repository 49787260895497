import React from 'react';
import LandHeader from '../../../components/landing-header/landing-header';
import AdminLogin from './admin-login';
import './admin-login.css';
import meta_data from '../../../config/meta_data';

function App() {
    document.title = meta_data.company + '| Admin Login';
    return (
        <div>
            <LandHeader />
            <AdminLogin />
        </div>
    );
}

export default App;
