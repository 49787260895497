import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import { Avatar } from "@mui/material";

//SERVICES
import MemberService from '../../../services/member-service';

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
//   min-height: 86px;
  border-radius: 8px;
  max-width: 350px;
  /* background: ${({ isDragging }) =>
    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
  background: rgb(255 255 255);
  margin-top: 15px;
  font-size: 16px;
    font-weight: 600;
    color:
    black;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;


// TaskCard

const TaskCard = ({ item, index }) => {

  const getInitials = (data) => {
    let fn = data.firstname.substring(0, 1);
    let ln = data.lastname.substring(0, 1);
    return fn + ln;
  }

  /** DELETE TASK */
  const deleteTask = (id) => {
    MemberService.deleteTask(id).then(
      (response) => {
        console.log("Task Deleted Successfully", response.data);
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Draggable key={item.membercoursetasksid} draggableId={item.membercoursetasksid.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/* card */}
          <TaskInformation>
            <p className='m-0'>{item.task}</p>
            <p className="mt-1 mb-1 task__description">{item.taskdescription}</p>

            <ul className="hor_lu_list m-0 p-0">
              {item.learners.map((lObj, lIndex) => {
                return (
                  <li>
                    <div className="m-1">
                      <Avatar>{getInitials(lObj)}</Avatar>
                    </div>
                  </li>
                )
              })}
              {/* <li>
                <div className="m-1">
                  <Avatar>M</Avatar>
                </div>
              </li>
              <li>
                <div className="m-1">
                  <Avatar>S</Avatar>
                </div>
              </li> */}
            </ul>
            <span className="task_delete_icon" onClick={(e)=>deleteTask(item.membercoursetasksid)}>Delete</span>
            <div className="secondary-details">

              {/* <p className='m-0'>
                <span>
                <Avatar src={item.icon} alt="photoURL" />
                </span>
              </p> */}
            </div>
          </TaskInformation>
          {/* card end*/}
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
