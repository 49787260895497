const userData = JSON.parse(localStorage.getItem("user"));
const emailAddress = userData ? userData.email : null;
const name = userData ? userData.name : null;
const fullname = userData ? userData.firstname : null;
const documentPath = userData ? userData.document_path : null;

const account = {
  displayName: name ? name : fullname,
  email: emailAddress != null ? emailAddress : "",
  photoURL: documentPath
    ? documentPath
    : "/static/mock-images/avatars/avatar_default.jpg",
};

export default account;