import React, { useEffect, useState } from 'react';
import { BsFileEarmarkText } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { setMenu } from '../../../store/slices/settingsSlice'
import './notes.css'

function NotesList() {
  const dispatch = useDispatch();
  const [selecetdColor, setColor] = useState('#f15c5c');
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    getNotes();
  }, [])

  const setSidebarMenu = (menu) => {
    dispatch(setMenu(menu));
  };

  const addNotes = () => {
    if (note) {
      setIsRequired(false);
      if (selecetdColor === '') {
        Swal.fire(

          {
            title: 'Colour required',
            text: "You won't be able to add notes without selecting a color!",
            icon: 'warning',
          }
        );
        return;
      }
      let notesArr = notes;
      notesArr.push({ note: note, color: selecetdColor });
      setNotes(notesArr.reverse())
      localStorage.setItem('meekuNotes', JSON.stringify(notesArr));
      getNotes();
      setNote('');
    } else {
      setIsRequired(true);
      // Swal.fire(
      //   {
      //     title: 'Notes required',
      //     text: "You won't be able to add notes without selecting a color!",
      //     icon: 'warning',
      //   }
      // );
    }
    // setColor('');

  }

  const getNotes = () => {
    // localStorage.setItem('meekuNotes', JSON.stringify([]));
    if (localStorage.getItem('meekuNotes') != null) {
      let localNotes = localStorage.getItem('meekuNotes');
      let parsedNotes = JSON.parse(localNotes);
      setNotes(parsedNotes)
    }

  }

  const handleNotes = (e) => {
    if (e.target.value) {
      setNote(e.target.value);
      setIsRequired(false);
    } else {
      setNote(e.target.value);
      setIsRequired(true);
    }
  }

  const removeNote = (e, index) => {
    let localNotes = localStorage.getItem('meekuNotes');
    let parsedNotes = JSON.parse(localNotes);
    parsedNotes.splice(index, 1);
    setNotes(parsedNotes)
    localStorage.setItem('meekuNotes', JSON.stringify(parsedNotes));
    getNotes();
  }


  return (
    <div className="host-course-list">
      <div className='e-sticky-header'>
      <div className="d-flex align-items-center e-role-label">
          <div className=" me-auto"> Notes </div>
          {/* <span onClick={() => setSidebarMenu("")}>
            <MdClose className="close-icon" />
          </span> */}
      </div>

      <div className='e-sticky-text-area'>
          <div className="host-course-item">
            <div className='notes-input'>
              <textarea className='notes-textarea' rows={4} placeholder='Add notes here..' value={note} onChange={handleNotes}></textarea>
            </div>
          </div>
          <div className='notes-action'>
            <div className='notes-color'>
              <div className='notes-red' style={{ 'zoom': selecetdColor === "#f15c5c" ? '1.3' : '1' }} onClick={() => setColor("#f15c5c")}></div>
              <div className='notes-blue' style={{ 'zoom': selecetdColor === "#0dcaf0" ? '1.3' : '1' }} onClick={() => setColor("#0dcaf0")}></div>
              <div className='notes-yellow' style={{ 'zoom': selecetdColor === "#ffc107" ? '1.3' : '1' }} onClick={() => setColor("#ffc107")}></div>
              <div className='notes-violet' style={{ 'zoom': selecetdColor === "#9b79fb" ? '1.3' : '1' }} onClick={() => setColor("#9b79fb")}></div>
            </div>
            <div className='add-note' onClick={addNotes}>
              +ADD NOTE
            </div>

          </div>
          {isRequired ? <p className='required_alert_text'>Notes is required</p> : ""}
        </div>
      </div>
      
      <div className='e-notes-container'>
        <div className='notes-area'>

          {notes.length > 0 && notes.map((noteOb, noteIndex) => {
            return (
              <div className='note-item' style={{ "background": noteOb.color }} key={noteOb.color}>
                {noteOb.note}
                <MdClose className=" removeNotes close-icon" onClick={(e) => removeNote(e, noteIndex)} />
                {/* <button className='removeNotes' onClick={(e) => removeNote(e, noteIndex)}>Remove</button> */}
              </div>
            )
          })}

          {/* {notes.length > 0 && notes.map(noteOb => (
          <div className='note-item' style={{ "background": noteOb.color }} key={noteOb.color}>
            {noteOb.note}
            <button onClick={(e)=>removeNote(e, index)}>Remove</button>
          </div>
        ))} */}
        </div>
      </div>
    </div>
  );
}

export default NotesList;