import React, { useState, useRef } from "react";
import Board from "../board/Board";
import Reactangle from "../Reactangle/Reactangle";
import io from "socket.io-client";

import "./style.css";

import pen from '../../../assets/img/white-board/tools/pen.png';
import eraser from '../../../assets/img/white-board/tools/eraser.png';
import rounded_rectangle from '../../../assets/img/white-board/tools/rounded-rectangle.png';
import swipe from '../../../assets/img/white-board/tools/swipe.png';
import text from '../../../assets/img/white-board/tools/text.png';
import arrow from '../../../assets/img/white-board/tools/arrow.png';

import url_configs from "../../../config/url";

import HomePage from "../home/home-page";
import { Stage, Layer } from "react-konva";
import { addTextNode } from "../text-node/text-node";

class Container extends React.Component {
  timeout;
  data;

  // Development
  socket = io.connect(url_configs.white_board_server);

  constructor(props) {
    super(props);
    var root = this;
    this.state = {
      color: "#000000",
      size: "3",
      shapes: [],
      selectShape: null,
      stageEl: React.createRef(),
      layerEl: React.createRef(),
      name: "tool"
    };

    setTimeout(function () {
      var canvas = document.querySelector("#main");
      root.setState({ reactangle: true });
      if (canvas)
        canvas.addEventListener("mousemove", (e) => {
          var el = document.getElementById("myself");
          el.style.top = e.clientY + "px";
          el.style.left = e.clientX + "px";
          var urlParams = new URLSearchParams(window.location.search);
          root.data = {
            y: e.clientY,
            x: e.clientX,
            user: urlParams.get("name"),
          };
          if (root.timeout !== undefined)
            clearTimeout(root.timeout);
          root.timeout = setTimeout(function () {
            root.socket.emit("mouse-move", root.data);
          }, 100);
        });
    }, 100);

    setTimeout(function () {
      var image = new Image();
      if (localStorage.getItem("imgSrc")) {
        var canvas = document.querySelector("#board");
        if(!canvas) return;
        var ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          ctx.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = localStorage.getItem("imgSrc");
      }
    }, 500);

    this.socket.on("canvas-data", function (data) {
      var root = this;

      var interval = setInterval(function () {
        if (root.isDrawing) return;

        root.isDrawing = true;
        clearInterval(interval);
        var image = new Image();

        var canvas = document.querySelector("#text_box");
        if(!canvas) return;
        var ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          ctx.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = data;
        this.data = localStorage.setItem("imgSrc", data);
      }, 200);
    });

    this.socket.on("mouse-move", function (data) {
      var interval = setInterval(function () {
        clearInterval(interval);
        if (data) {
          var urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get("name") !== data.user) {
            var el = document.getElementById("hov");
            el.innerHTML =
              `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 28 28" enable-background="new 0 0 28 28" xml:space="preserve">
          <polygon fill="#FFFFFF" points="8.2,20.9 8.2,4.9 19.8,16.5 13,16.5 12.6,16.6 "/>
          <polygon fill="#FFFFFF" points="17.3,21.6 13.7,23.1 9,12 12.7,10.5 "/>
          <rect x="12.5" y="13.6" transform="matrix(0.9221 -0.3871 0.3871 0.9221 -5.7605 6.5909)" width="2" height="8"/>
          <polygon points="9.2,7.3 9.2,18.5 12.2,15.6 12.6,15.5 17.4,15.5 "/>
          </svg>` + data.user;
            el.style.top = data.y + "px";
            el.style.left = data.x + "px";
          }
        }
      }, 100);
    });
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "pencil":
        this.setState({ pencil: true });
        this.setState({ reactangle: false });
        this.setState({ line: false });
        this.setState({ text_box: false });
        break;
      case "reactangle":
        this.setState({ reactangle: true });
        this.setState({ line: false });
        this.setState({ pencil: false });
        this.setState({ text_box: false });
        break;
      case "line":
        this.setState({ reactangle: false });
        this.setState({ line: true });
        this.setState({ pencil: false });
        this.setState({ text_box: false });
        break;
      case "text_box":
        this.setState({ reactangle: false });
        this.setState({ line: true });
        this.setState({ pencil: false });
        this.setState({ text_box: true });
        break;
    }
  }

  changeColor(params) {
    this.setState({
      color: params.target.value,
    });
  }

  changeSize(params) {
    this.setState({
      size: params.target.value,
    });
  }

  clearCanvas = () => {
    localStorage.removeItem('imgSrc');
    window.location.reload();
  };


  render() {
    //const [selectedId, selectShape] = useState(null);
    //const [shapes, setShapes] = useState([]);
    const { pencil, reactangle, text_box, stageEl, layerEl, selectShape, shapes } = this.state;

    const drawText = () => {
      //stageEl = React.createRef();
      //layerEl = React.createRef();
      // let stageEl = this.state.stageEl;
      // let layerEl = this.state.layerEl;
      this.setState({ reactangle: false });
      this.setState({ line: true });
      this.setState({ pencil: false });
      this.setState({ text_box: true });
      if (stageEl.current && layerEl.current) {
        const id = addTextNode(stageEl.current.getStage(), layerEl.current);
        const shs = this.state.shapes.concat([id]);
        shapes = shs;

        this.socket.emit("canvas-data", shapes);
      }
    };

    return (
      <>
        <div id="main">
          <div id="hov"></div>

          {/* CURSOR */}
          <div id="myself" className="my-cursor">
            <span className="my-cursor-content">
              Me
            </span>
          </div>
          {/* TOOLS */}
          <div className="tools-section">
            <div className="tools">
              {/* ARROW */}
              <div className="d-flex justify-content-center">
                <img className="arrow_img" src={arrow} onClick={() => this.hideComponent("pencil")}></img>
              </div>

              {/* SWIPE */}
              <div className="d-flex justify-content-center">
                <img className="swipe_img" src={swipe} onClick={() => this.hideComponent("pencil")}></img>
              </div>

              {/* RECTANGLE */}
              <div className="d-flex justify-content-center">
                <img className="rect_img" src={rounded_rectangle} onClick={() => this.hideComponent("reactangle")}></img>
              </div>

              {/* PENCIL */}
              <div className="d-flex justify-content-center">
                <img className="pen_img" src={pen} onClick={() => this.hideComponent("pencil")}></img>
              </div>

              {/* TEXT */}
              {/* <div className="d-flex justify-content-center">
                <img className="text_img" src={text} onClick={() => this.hideComponent("text_box")}></img>
              </div> */}
              <div className="d-flex justify-content-center">
                <img className="text_img" src={text} onClick={() => drawText()}></img>
              </div>
              {/* CLEAR */}
              <div className="d-flex justify-content-center" placeholder="clear">
                <a onClick={this.clearCanvas}>
                  <img src={eraser}></img>
                </a>
                {/* <button onClick={this.clearCanvas} className="btn-width">
                  </button> */}
              </div>

              {/* COLOR PICKER */}
              <div className="color_picker">
                <input
                  type="color"
                  value={this.state.color}
                  onChange={this.changeColor.bind(this)}
                />
              </div>
              {/* BRUSH SIZE */}
              <div>
                <select
                  value={this.state.size}
                  onChange={this.changeSize.bind(this)}
                >
                  <option> 3 </option>
                  <option> 5 </option>
                  <option> 10 </option>
                  <option> 15 </option>
                  <option> 20 </option>
                  <option> 25 </option>
                  <option> 30 </option>
                </select>
              </div>
            </div>
          </div>

          <div className="board-container">

            {pencil && (
              <Board color={this.state.color} size={this.state.size} />
            )}
            {reactangle && (
              <Reactangle color={this.state.color} size={this.state.size} />
            )}
            <Stage
            id="text_box"
              width={window.innerWidth * 0.9}
              height={window.innerHeight - 150}
              ref={stageEl}
              onMouseDown={e => {
                // deselect when clicked on empty area
                const clickedOnEmpty = e.target === e.target.getStage();
                if (clickedOnEmpty) {
                  selectShape = null;
                }
              }}
            >
              <Layer ref={layerEl}>

              </Layer>
            </Stage>
          </div>
        </div>
      </>
    );
  }
}

export default Container;
