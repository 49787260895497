import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
  name: 'counter',
  initialState: {
    menu: 'course',
    slide: 0,
    mainMenu: 'class',
    isFluid: false,
    isMaximized: false,
    homePageMenu: '',
    currentGroupName: '',
    selectedContent: '',
    selectedCurriculamType: 'content',
    selectedCurriculam: null,
    selectedCurriculamData: null,
    breakoutCurrentScreenData: null,
    breakoutGameList: null,
    autoPlayGameRouteName: null,
    breakoutRoomStatus:"",
    selectedGroup:"",
    displayOption: "",
    startSelectedBreakoutRoom: false,
    topicName: '',
    checkoutData: null,
    stripeClientSecret: "",
    currentSubSessionsList: [],
    isChatGeneralNotification: false,
    isGalleryView: false,
    groupSpaceQuiz: null,
    questions: [],
    question: null,
    choices: [],
    correctAnswer: null,
    currentLocationDetail: null,
    isNotificationOn: true,
    isRecapClicked: false,
    isRecapOpened: false,
  },
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload
    },
    setMainMenu: (state, action) => {
      state.mainMenu = action.payload;
    },
    setIsFluid: (state, action) => {
      state.isFluid = action.payload;
    },
    setIsMaximized: (state, action) => {
      state.isMaximized = action.payload;
    },
    setHomePageMenu: (state, action) => {
      state.homePageMenu = action.payload;
    },
    setCurrentGroupName: (state, action) => {
      state.currentGroupName = action.payload;
    },
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload;
    },
    setSelectedCurriculamType: (state, action) => {
      state.selectedCurriculamType = action.payload;
    },
    setSelectedCurriculam: (state, action) => {
      state.selectedCurriculam = action.payload;
    },
    setSelectedCurriculamData: (state, action) => {
      state.selectedCurriculamData = action.payload;
    },
    setAutoPlayGameRouteName: (state, action) => {
      state.autoPlayGameRouteName = action.payload;
    },
    setTopicName: (state, action) => {
      state.topicName = action.payload;
    },
    setBreakoutCurrentScreenData: (state, action) => {
      state.breakoutCurrentScreenData = action.payload;
    },
    setBreakoutGameList: (state, action) => {
      state.breakoutGameList = action.payload;
    },
    setBreakoutRoomStatus: (state, action) => {
      state.breakoutRoomStatus = action.payload;
    },
    setselectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setDisplayOption: (state, action) => {
      state.displayOption = action.payload;
    },
    setStartSelectedBreakoutRoom: (state, action) => {
      state.startSelectedBreakoutRoom = action.payload;
    },
    setCheckoutData: (state, action) => {
      state.checkoutData = action.payload;
    },
    setStripeClientSecret: (state, action) => {
      state.stripeClientSecret = action.payload;
    },
    setCurrentSubSessionsList: (state, action) => {
      state.currentSubSessionsList = action.payload;
    },
    setIsChatGeneralNotification: (state, action) => {
      state.isChatGeneralNotification = action.payload;
    },
    setIsGalleryView: (state, action) => {
      state.isGalleryView = action.payload;
    },
    setGroupSpaceQuiz: (state, action) => {
      state.groupSpaceQuiz = action.payload;
    },

    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setCorrectAnswer: (state, action) => {
      state.correctAnswer = action.payload;
    },
    setChoices: (state, action) => {
      state.choices = action.payload;
    },
    setCurrentLocationDetail: (state, action) => {
      state.currentLocationDetail = action.payload;
    },
    setIsNotificationOn: (state, action) => {
      state.isNotificationOn = action.payload;
    },
    setIsRecapClicked: (state, action) => {
      state.isRecapClicked = action.payload;
    },
    setIsRecapOpened: (state, action) => {
      state.isRecapOpened = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setMenu,
  setMainMenu,
  setIsFluid,
  setIsMaximized,
  setHomePageMenu, 
  setCurrentGroupName, 
  setSelectedContent, 
  setSelectedCurriculamType, 
  setSelectedCurriculam, 
  setSelectedCurriculamData, 
  setAutoPlayGameRouteName, 
  setTopicName, 
  setBreakoutCurrentScreenData, 
  setBreakoutGameList,
  setBreakoutRoomStatus,
  setselectedGroup,
  setDisplayOption,
  setStartSelectedBreakoutRoom,
  setCheckoutData,
  setStripeClientSecret,
  setCurrentSubSessionsList,
  setIsChatGeneralNotification,
  setIsGalleryView,
  setGroupSpaceQuiz,
  setQuestions,
  setQuestion,
  setChoices,
  setCorrectAnswer,
  setCurrentLocationDetail,
  setIsNotificationOn,
  setIsRecapClicked,
  setIsRecapOpened
 } = settingsSlice.actions

export default settingsSlice.reducer