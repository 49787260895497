import React, { useState, useEffect } from "react";
import { filter } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import './course-info.css';
import {
    Autocomplete,
    Card,
    Grid,
    FormControl,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    InputLabel,
    MenuItem,
    Select,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TextField,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";

import Page from '../../../../../components/Page';

import { SnackbarProvider, useSnackbar } from 'notistack';

//FILE UPLOAD
import DropFileInput from '../../../../../components/drop-file-input/DropFileInput';

//SERVICES
import DocumentService from "../../../../../services/document-service";
import CourseService from "../../../../../services/courses-service";
import LookUpervice from "../../../../../services/look-up-service";

//CONFIG
import route_config from "../../../../../config/route_config";
import navigate_route_config from "../../../../../config/navigate_route_config";
import conditional_configs from "../../../../../config/conditional_config";

import { getCurrentUserData } from "../../../../../utils/util";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export default function CourseInfo() {
    const classes = useStyles();
    let { courseid } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [categoryList, setCategoryList] = useState([]);


    const [language, setLanguage] = React.useState("");
    const [courseCoverPic, setcourseCoverPic] = React.useState("");
    const [selectedCategories, setSelectedCategories] = React.useState([]);

    //FORM FIELD DECLARATION

    const {
        control,
        handleSubmit,
        reset,
        register,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
            if (!courseid) {
                navigate(route_config.admin.courses.main_route_name);
            }
            getCourseCategories();
        } else {
            localStorage.clear();
            navigate('/admin-login');
        }

        //getContentSessionList('pod');
    }, []);

    //#region APIS
    const getCourseCategories = () => {
        LookUpervice.GetCourseCategory().then(response => {
            console.log('Catgory List', response.data)
            setCategoryList(response.data);
            GetCourseInfoByCourseId(response.data);
        }).catch(e => {
            console.log(e);
        });
    }

    const GetCourseInfoByCourseId = (categoryList) => {
        CourseService.GetCourseInfoByCourseId(courseid).then(response => {
            console.log(response);
            if (response && response.data && response.data.coursecategory && response.data.coursecategory.length > 0) {
                let catList = [];
                response.data.coursecategory.forEach(element => {
                    if (categoryList && categoryList.length > 0) {
                        let catObj = {
                            id: element.category,
                            name: categoryList.filter(e => e.id == element.category)[0].name
                        }
                        catList.push(catObj);
                    }
                });
                setTimeout(() => {
                    setValue("categories", catList, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    console.log('Cat ids', catList)
                }, 2000);
            }

            if (response && response.data && response.data.courseinfo && response.data.courseinfo.length > 0) {
                setTimeout(() => {
                    setValue("course_name", response.data.courseinfo[0].name, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("selected_language", response.data.courseinfo[0].language, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("course_price", response.data.courseinfo[0].price.toString(), {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("number_of_sessions", response.data.courseinfo[0].noofsessions, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("min_number_of_learners", response.data.courseinfo[0].minnolearners, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("max_number_of_learners", response.data.courseinfo[0].maxnolearners, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                    setValue("price_per_lesson", response.data.courseinfo[0].priceperlesson, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                }, 2000);
            }
        }).catch(e => {
            console.log(e);
        });
    }
    //#endregion

    //#region CHANGE EVENTS
    const handleCategoriesChange = (cat_data) => {
        let categories = [];
        cat_data.forEach(element => {
            let categoryObj = {
                categoryid: element.id,
                name: element.name
            }
            categories.push(categoryObj);
        });
        setSelectedCategories(categories);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleCourseCoverPicChange = (files) => {
        console.log(files);
        if (files && files.length > 0) {
            var file = files[0];
            //setcourseCoverPic(file.path);
            //console.log(courseCoverPic);
            DocumentService.upload_document(file).then(response => {
                console.log('File Uploaded Successfully');
                console.log(response);
                setcourseCoverPic(response.data.id);
            })
                .catch(e => {
                    console.log(e);
                });
            console.log(files)
        }
    }
    //#endregion

    const handleNext = (data) => {
        console.log(data);
        enqueueSnackbar('Course information updated successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }

    const tabNavigation = (screen) => {
        navigate(screen + '/' + courseid);
    }

    return (
        <Page title="Course Information">
            <Container>
                <Typography>
                    <Stack direction="row" spacing={1} mt={3}>
                        <Button
                            id="tab-button"
                            className="tab-btn-active"
                            variant="contained"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_info)}
                        >
                            Course Info
                        </Button>
                        <Button
                            id="tab-button"
                            variant="contained"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_content)}
                        >
                            Course Content
                        </Button>
                        <Button
                            id="tab-button"
                            variant="contained"
                            onClick={(e) => tabNavigation(navigate_route_config.admin.course_pod)}
                        >
                            Pods
                        </Button>
                    </Stack>
                </Typography>

                <form onSubmit={handleSubmit(handleNext)}>
                    <Grid container spacing={1}>
                        <Typography variant="h6" margin="dense">
                            Course Information
                        </Typography>
                        {/* Course Name */}
                        <Grid item xs={12} sm={12}>
                            <Controller
                                control={control}
                                name="course_name"
                                rules={{ required: "Course name is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="course_name"
                                        label="Course name"
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.course_name}
                                        helpertext={errors.course_name?.message}
                                    />
                                )}
                            />
                        </Grid>
                        {/* Select Categories */}
                        <Grid item xs={12} sm={12}>
                            <Controller
                                control={control}
                                name="categories"
                                rules={{ required: false }}
                                defaultValue={[]}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Autocomplete
                                        id="categories"
                                        multiple
                                        filterSelectedOptions
                                        options={categoryList}
                                        value={value}
                                        getOptionLabel={option => option.name}
                                        getoptionselected={(option, value) => option.id === value.id}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, values) => handleCategoriesChange(values)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Categories"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {/* language */}
                        <Grid item xs={12} sm={12}>
                            <Controller
                                control={control}
                                name="selected_language"
                                rules={{ required: "Language is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    //render={({ field }) => (
                                    <FormControl style={{ width: "100%" }} className="mt-2">
                                        <InputLabel
                                            id="languages"
                                            variant="outlined"
                                            margin="dense"
                                        >
                                            Language
                                        </InputLabel>
                                        <Select
                                            inputRef={ref}
                                            labelId="languages"
                                            id="languages-select"
                                            label="Language"
                                            margin="dense"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            error={errors.selected_language}
                                            helpertext={errors.selected_language?.message}
                                        >
                                            <MenuItem value={74}>English</MenuItem>
                                            <MenuItem value={75}>Tamil</MenuItem>
                                            <MenuItem value={76}>Hindi</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        {/* Upload Cover Picture */}
                        <Grid item xs={12} sm={12}>
                            <Controller
                                control={control}
                                name="course_cover_pic"
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <div className="box">
                                        <span>Upload course cover picture</span>
                                        <DropFileInput
                                            {...field}
                                            onFileChange={(files) => handleCourseCoverPicChange(files)}
                                        />
                                    </div>
                                )}
                            />
                        </Grid>
                        {/* Price Information */}
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" margin="dense">
                                Price Information
                            </Typography>
                        </Grid>
                        {/* course_price */}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="course_price"
                                rules={{ required: "Course price is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="course_price"
                                        label="Course price"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.course_price}
                                        helpertext={errors.course_price?.message}
                                    />
                                )}
                            />
                        </Grid>
                        {/* Number Of sessions */}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="number_of_sessions"
                                rules={{ required: "No. of sessions is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="number_of_sessions"
                                        label="Number Of sessions"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.number_of_sessions}
                                        helpertext={errors.number_of_sessions?.message}
                                    />
                                )}
                            />
                        </Grid>
                        {/* Min.number of learners */}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="min_number_of_learners"
                                rules={{ required: "Minimum no. of learners is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="min_number_of_learners"
                                        label="Min number of learners"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.min_number_of_learners}
                                        helpertext={errors.min_number_of_learners?.message}
                                    />
                                )}
                            />
                        </Grid>
                        {/* Max.number of learners */}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="max_number_of_learners"
                                rules={{ required: "Maximum no. of learners is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="max_number_of_learners"
                                        label="Max number of learners"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.max_number_of_learners}
                                        helpertext={errors.max_number_of_learners?.message}
                                    />
                                )}
                            />
                        </Grid>
                        {/* pre-course-price number of learners */}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="price_per_lesson"
                                rules={{ required: "Price per lesson is required" }}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField
                                        id="price_per_lesson"
                                        label="Price per lesson"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={errors.price_per_lesson}
                                        helpertext={errors.price_per_lesson?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        id="submit-button"
                        className={classes.button}
                        variant="contained"
                        type="submit"
                    >Submit
                    </Button>
                </form>
            </Container>
        </Page>
    );
}