import React from "react";
import styled from "styled-components";

import InviteForm from './invite-email-form';
import './landing-page.css';
import background from "../../assets/img/landing-banner.png";
import landing_img_1 from "../../assets/img/landing_img_1.png";

export default function Banner() {
  function onNewsletterSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ values })
      }, 1000)
    })
  }
  return (
    <Wrapper style={myStyle}>
      <ContainerWrapper id="home" className="container flexSpaceCenter c_padding">
        <CenterDiv>
          <span className="heading">An enriching Peer learning experience for your <strong>gifted ones</strong></span>
          <p className="sub_text">
              Meeku is a secure environment for 8 - 14 year olds where kids <strong>explore</strong> and <strong>learn together and become tomorrow's problem solvers.</strong> Meeku provides an innovative curriculum that tailors their interest and needs.</p>
          {/* <HeaderP className="font13">
            <p>
              Meeku is a secure environment for 8 - 14 year olds where kids <strong>explore</strong> and <strong>learn together and become tomorrow's problem solvers.</strong> Meeku provides an innovative curriculum that tailors their interest and needs.</p>
          </HeaderP> */}
          <InviteForm className=""
            submitBtn="JOIN FREE CLASS"
            onSubmit={onNewsletterSubmit}></InviteForm>
            <img className="landing_img_1" src={landing_img_1} alt="Meeku" ></img>
        </CenterDiv>
      </ContainerWrapper>
    </Wrapper>
  );
}
const myStyle = {
  backgroundImage: `url(${background})`,
  height: '100vh',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed'
};

const Wrapper = styled.section`
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const CenterDiv = styled.div`
color: #4500e2;
width: 60%;
height: 100%;
text-align: center;
justify-content: center;
  @media (max-width: 960px) {
    width: 100%;
    margin-top: 30px;
  }
`;

// const HeaderP = styled.div`
// display: flex;
// justify-content: center;
// text-align: center;
//   padding: 15px 0 15px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 15px 0;
//     text-align: center;
//     max-width: 100%;
//     width: 100%;
//   }
// `;


