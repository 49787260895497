
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import './course-detail.css';

import io from "socket.io-client";

//import { sentenceCase } from 'change-case';
//import { Link as RouterLink } from 'react-router-dom';
import CourseService from '../../../../services/courses-service';
import AdminService from '../../../../services/admin-service';
import SessionService from '../../../../services/session-service';

import { SnackbarProvider, useSnackbar } from 'notistack';

//DATETIME FORMAT
import moment from 'moment';
import { fDate } from "../../../../utils/formatTime";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Grid,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Menu
} from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

//DATE PICKER
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

// components
import Page from '../../../../components/Page';
//import Label from '../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../../sections/@dashboard/user';

// CONFIGS
import navigate_route_config from '../../../../config/navigate_route_config';
import conditional_configs from '../../../../config/conditional_config';
import url_configs from '../../../../config/url';

import { getChats, getCurrentUserData, getInitialsFromFullName, getOpenedChatGroup, removeAllChats, storeChats, storeOpenedChatGroup } from '../../../../utils/util';

import { makeStyles } from "@mui/styles";
import storage_configs from '../../../../config/storage-config';
import MemberService from '../../../../services/member-service';
import assets from '../../../../config/assets';
import slugify from 'react-slugify';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'coursename', label: 'Course Name', alignRight: false },
  { id: 'expertid', label: 'Expert', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'learners', label: 'Learners', alignRight: false },
  { id: 'startdate', label: 'Start Date', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

let chat_server_socket;

export default function CourseDetail() {

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  let { courseid } = useParams();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUserList] = useState([]);

  const navigate = useNavigate();

  const [streamList, setStreamList] = useState([]);

  const [parentList, setParentList] = useState([]);

  const [kidsList, setKidsList] = useState([]);

  const [selectedParent, setSelectedParent] = React.useState("");

  const [selectedStreamId, setSelectedStreamId] = React.useState("");

  const [openCourseAssign, setOpenCourseAssign] = React.useState(false);

  const [openCourseKids, setOpenCourseKids] = React.useState(false);

  //ADD NEW POD DECRATION
  const [openAddPod, setOpenAddPod] = React.useState(false);
  const [expertList, setExpertList] = useState([]);
  const [selectedExpert, setSelectedExpert] = React.useState("");
  const [sessionStartDate, setSessionStartDate] = React.useState();
  const [sessionStartTime, setSessionStartTime] = React.useState();
  const [sessionEndTime, setSessionEndTime] = React.useState();

  //SCHEDULE A COURSE SESSION
  const [openScheduleCourse, setOpenScheduleCourse] = React.useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = React.useState("");
  const [selectedCourseStream, setSelectedCourseStream] = React.useState("");

  //CHAT MENU
  const [chatCtrlMenu, setChatCtrlMenu] = useState(false);
  const [chatOptionEl, setChatOptionEl] = useState();
  const [chatType, setChatType] = useState('general_chat');
  const [courseGroups, SetCourseGroups] = useState([]);
  const [openChat, SetOpenChat] = useState(false);
  const [chatRoom, setChatRoom] = useState("");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiCtrlMenu, setEmojiCtrlMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // CourseService.getCourses().then(response => {
    //   //setUserList(response.data);
    //   console.log(USERLIST);
    // })
    //   .catch(e => {
    //     console.log(e);
    //   });
    getExpertList();
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      GetCoursesDetailsById();
      getParentList();
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }

  }, []);

  const GetAllLearnersByStreamId = (streamId) => {
    CourseService.GetAllLearnersByStreamId(streamId).then(response => {
      console.log('kids', response);
      setKidsList(response.data);
      handleOpenCourseKids(streamId)
    })
      .catch(e => {
        console.log(e);
      });
  }

  const GetCoursesDetailsById = () => {
    CourseService.GetCoursesDetailsById(courseid).then(response => {
      console.log(response);
      if (response.data && response.data.coursestream && response.data.coursestream.length > 0) {
        let tempStreamList = response.data.coursestream;
        tempStreamList.forEach(element => {
          element.coursename = response.data.courseinfo[0].name;
          element.price = response.data.courseinfo[0].price;
          element.maxnolearners = response.data.courseinfo[0].maxnolearners;
          element.minnolearners = response.data.courseinfo[0].minnolearners;
        });
        setStreamList(tempStreamList);
        setUserList(tempStreamList);
        console.log('tempStreamList', tempStreamList);
      }

    })
      .catch(e => {
        console.log(e);
      });
  }

  const getParentList = () => {
    AdminService.getallparents().then(response => {
      console.log(response.data.data);
      setParentList(response.data.data);
      setSelectedParent("");
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const navigateToCreateCourse = () => {
    navigate(navigate_route_config.admin.create_course);
  }

  const handleOpenCourseAssign = (e, strmId) => {
    setSelectedStreamId(strmId);
    setOpenCourseAssign(true)
  };
  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseCourseAssign = () => setOpenCourseAssign(false);

  const handleParentChange = (event) => {
    setSelectedParent(event.target.value);
  };

  const handleNext = (data) => {
    console.log(data);
    console.log('Streamid', selectedStreamId);

    let model = {
      coursestreamid: selectedStreamId.toString(),
      memberid: data.selectedParent.toString(),
      paidon: '2023-03-13',
      linkno: '1234',
      amount: '100.00'
    }
    console.log('Assign Course Model', model);

    //setSelectedParent("");

    // CourseService.assignCourseToParent(model)
    //   .then(response => {
    //     console.log(response);

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (response && response.data && response.success === 'true') {
    //       enqueueSnackbar('Course Assigned to Parent', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else if (response && response.data && response.success === 'false') {
    //       enqueueSnackbar(response.data.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   })
    //   .catch(error => {

    //     handleCloseCourseAssign();
    //     setSelectedParent("");
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //       enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log('Error', error.message);
    //       enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    //     }
    //   });
  }

  const handleOpenCourseKids = (strmId) => {
    setSelectedStreamId(strmId);
    setOpenCourseKids(true)
  };

  /** CLOSE TEMPLATE QUIZ POPUP */
  const handleCloseCourseKids = () => setOpenCourseKids(false);

  const generatePodDetail = (list) => {
    console.log('list', list)
    if (list && list.length > 0) {
      let startDate = '';
      let endDate = '';
      startDate = list[0].startdate;
      endDate = list[(list.length - 1)].startdate;
      //return endDate + ' - ' + startDate;
      return startDate + ' - ' + endDate;
    } else {
      return "";
    }
  }

  // Add new pod
  const handleCloseAddPod = () => setOpenAddPod(false);

  const handleExpertChange = (event) => {
    setSelectedExpert(event.target.value);
  };

  const handleAddPodSubmit = (data) => {

  }

  const openAddPodPopup = (e) => {
    e.stopPropagation();
    setOpenAddPod(true);
    setSelectedExpert(null);
    setSessionStartDate(null);
    setSessionStartTime(null);
    setSessionEndTime(null);
    getExpertList();
    // let tempStreamObj = {
    //   expertid: "",
    //   startdate: "",
    //   session: []
    // }
  }

  const getExpertList = () => {
    AdminService.getAllActiveExperts().then(response => {
      console.log('Experts-',response.data)
      setExpertList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleStreamSessionChange = (event, field) => {
    //let tempStreamList = [...streamList];

    if (field == 'startdate') {
      let stDate = moment(event.$d, 'DD/MM/YYYY').format('YYYY-MM-DD');
      setSessionStartDate(stDate);
    } else if (field == 'from') {
      let stTime = event.$d;
      setSessionStartTime(stTime);
    } else if (field == 'to') {
      let endTime = event.$d;
      setSessionEndTime(endTime);
    } else {
      return;
    }
  }

  //SCHEDULE A COURSE
  const handleCloseScheduleCourse = () => setOpenScheduleCourse(false);

  const handleChangeCourseSchedule = (event) => {
    setSelectedSchedule(event.target.value);
  };

  const handleOpenCourseSchedule = (e, objStream, cObj) => {
    e.stopPropagation();
    setOpenScheduleCourse(true);
    setSelectedExpert(cObj.expertid);
    setSelectedCourseStream(cObj.id);
    setSelectedSchedule(null);
    setScheduleList([]);
    setScheduleList(objStream);
    getExpertList();
  }

  const submitScheduleClass = (data) => {
    console.log('Schedule class', data)

    const date = new Date();
    let formatedTodayDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
    let startSessionModel = {
      expertid: selectedExpert.toString(),
      coursestreamdetailid: data.selectedSchedule.toString(),
      startdatetime: formatedTodayDate
    }
    //let generateLink = url_configs.frontend +'join-demo/'+current_stream_id+'/'+slug;
    console.log('Schedule class - submitted', startSessionModel);
    SessionService.startSessionByExpert(startSessionModel).then(response => {
      console.log('Session-', response);
      if (response && response.success == "true") {
        if (response.data) {
          let tempSlug = response.data.slug;
          let generateLearnerLink = url_configs.frontend + 'join-demo/' + selectedCourseStream + '/' + tempSlug;
          alert(generateLearnerLink)
          //navigate('/expert-session/classroom/' + tempSlug);
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const convertTime = (time) => {
    if (time) {
      let convertedTime = moment(time, 'HH:mm').format('hh:mm a');
      return convertedTime;
    }
  }

  const getExpert = (d) => {
    let eData = [...expertList]
    if (d) {
      if (eData && eData.length > 0) {
        let expertData = eData.filter(e=>e.id == d)[0];
        if(expertData){
          return expertData.firstname
        }else{
          return ""
        }
      }
    }
  }

  //#region CHATS
  const handleChatSubmit = (e) => {
    console.log('handleChatSubmit', message)
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: chatRoom,
      slug: chatRoom,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType: "admin"
    }
    if (message) {
      if (chatType == 'group_chat') {
        chat_server_socket.emit("sendMessage", { message: message, group: getOpenedChatGroup(), slug: chatRoom, userId: getCurrentUserData().id, userType: 'admin' });
        chatModel.group = getOpenedChatGroup();
      } else {
        chat_server_socket.emit("sendMessage", { message: message, group: null, slug: chatRoom, userId: getCurrentUserData().id, userType: 'admin' });
      }
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  /** MENU EVENTS */
  const chatOptionMenuClickOpen = (event, sId) => {
    setChatOptionEl(event.currentTarget);
    if (sId){
      localStorage.setItem(storage_configs.localStorage.sessions.current_stream_id, sId)
      getCourseGroupDetail(sId);
    }
    setChatCtrlMenu(true);
  };

  const chatOptionMenuClickClose = () => {
    setChatCtrlMenu(false);
  };

  const openGeneralChats = (e) => {
    setChatType('general_chat')
    chatOptionMenuClickClose();
    const course_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    removeAllChats();
    SetOpenChat(true);
    SessionService.getChatsByStreamId(course_stream_id)
      .then(response => {
        console.log("Chats :::", response);
        if (response && response.data && response.data.length > 0) {
          storeChats(response.data.filter(e=> !e.group));
        }
        joinToGeneralChat(course_stream_id);
      })
      .catch(error => {
        console.log(error)
      });
  }

  const openPodGroupChats = (e, obj) => {
    setChatType('group_chat');
    storeOpenedChatGroup(obj);
    chatOptionMenuClickClose();
    const course_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    removeAllChats();
    SetOpenChat(true);
    SessionService.getChatsByStreamId(course_stream_id)
      .then(response => {
        console.log("Chats :::", response);
        if (response && response.data && response.data.length > 0) {
          storeChats(response.data.filter(e => e.group && e.group.coursegroupid == obj.coursegroupid));
        }
        let uName = getCurrentUserData().name;
        let uRoom = obj.groupname;
        setChatRoom(slugify(uRoom));
        chat_server_socket = io(url_configs.chat_server);
        chat_server_socket.emit("join", { name: uName, room: slugify(uRoom) }, (error) => {
          if (error) {
            alert(error);
          }
        });
        chat_server_socket.on("message", (message) => {
          let generalChats = [...messages];
          let storedChats = getChats();
          if (storedChats && storedChats.length > 0) {
            generalChats = storedChats;
          }
          generalChats.push(message)
          setMessages(generalChats);
          storeChats(generalChats);

          if (message && message.user != getCurrentUserData().name) {
            enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

            let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
            if (!tempNofications) {
              tempNofications = [];
            }
            tempNofications.push(message);
            localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
          }

        });
      })
      .catch(error => {
        console.log(error)
      });
  }

  const closeChat = (e) => {
    SetOpenChat(false);
    window.location.reload();
  }

  const joinToGeneralChat = (coursestreamid) => {
    //let cId = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.course_detail));
    let room = 'pod-' + coursestreamid;
    setChatRoom(room);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit("join", { name: getCurrentUserData().name, room: room }, (error) => {
      if (error) {
        alert(error);
      }
    });

    chat_server_socket.on("message", (message) => {
      if (message.slug == room) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message)
        setMessages(generalChats);
        storeChats(generalChats);

        if (message && message.user != getCurrentUserData().name) {
          enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

          let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
        }
      }
    });

  }

  const onClickEmoji = (emojiData, event) => {
    setSelectedEmoji(emojiData.unified);
    let tempMsg = message;
    //const textAreaElement = document.getElementById('chat-text-area');
    //setMessage(message.substr(0, textAreaElement.selectionStart) +emojiData.emoji  +message.substr(textAreaElement.selectionEnd));
    setMessage(tempMsg + emojiData.emoji)
    handleEmojiControlMenuClose();
  }

  const addChats = (data) => {
    const current_stream_id = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id));
    data.isInSession = false;
    data.courseStreamId = current_stream_id;
    SessionService.addChats(data)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleEmojiControlMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEmojiCtrlMenu(true);
  };

  const handleEmojiControlMenuClose = () => {
    setEmojiCtrlMenu(false);
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes('data:image/');
      return checkIsImg;
    }
  }

  
  const renderSwitch = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse()
    }

    return (
      <div className="chat-msg-list-v2" >
        {msgs?.map((val, i) => {
          return (
            <div key={i}>
              <div className="chat-my-msg-main-v2">
                {val.user === getCurrentUserData().name ?
                  <div>
                    <div className="my-name-container-v2">
                      <span className="my-name-v2">Me</span>
                    </div>
                    <div className="chat-my-msg-container-v2">
                      <div className="rvr-msg-container-v2">
                        <span className="rvr-name-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                          <img src={val.text} />}</span>
                      </div></div>
                  </div> :

                  <div className="rvr-container-v2">
                    <div className="rvr-avatar-container">
                      {getInitialsFromFullName(val.user)}
                    </div>
                    <div className="w-100 rvr-msg-container-div">
                      <div className="rvr-name-container-v2">
                        <span className="chat-rvr-name-v2">{val.user}&nbsp;{val?.userType && val?.userType == 'expert' ?'(Expert)':val?.userType && val?.userType == 'admin' ?"(Admin)":""}</span>
                      </div>
                      <div className="chat-rvr-msg-container-v2">
                        <div className="rvr-msg-container-v2">
                          <span className="chat-rvr-msg-v2">{findTypeOfMessage(val.text) !== true ? val.text :
                            <img src={val.text} />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    )
  };

  /** GET APIS */
  const getCourseGroupDetail = (streamId) => {
    MemberService.getMemberCourseGroupByStreamIdNew(streamId).then(
      (response) => {
        if (response.data && response.data.length > 0) {
          SetCourseGroups(response.data);
        }
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  //#endregion
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
          <Typography variant="h4" gutterBottom className='mb-0'>
            Pods
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={(e) => openAddPodPopup(e)} >
            Add Pod
          </Button> */}
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filterPlaceholder='Search Course' /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, courseid, coursename, price, expertid, minnolearners, maxnolearners, startdate, coursestreamdetail } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={firstname} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {coursename}
                            </Typography>
                          </Stack>
                          <div className='pod_detail_col'>{generatePodDetail(coursestreamdetail)}</div>
                        </TableCell>
                        <TableCell align="left">{getExpert(expertid)}</TableCell>
                        <TableCell align="left">${price}</TableCell>
                        <TableCell align="left">{'Min ' + minnolearners + ' Max ' + maxnolearners}</TableCell>
                        <TableCell align="left">{startdate}</TableCell>
                        {/* <TableCell align="left"><span className='pod_detail_col'>{generatePodDetail(coursestreamdetail)}</span></TableCell> */}
                        <TableCell align="left">
                          <Typography>
                            <Stack direction="row" spacing={1}>

                              <Tooltip title="Assign Pod to Parent" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => handleOpenCourseAssign(e, id)} >
                                  <PersonAddIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="View Kids" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => GetAllLearnersByStreamId(id)} >
                                  <ChildCareIcon />
                                </IconButton>
                              </Tooltip>
                              
                              <Tooltip title="Manage Chats" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => chatOptionMenuClickOpen(e, id)}>
                                  <ChatOutlinedIcon />
                                </IconButton>
                              </Tooltip>

                              {/* <Tooltip title="Schedule Class" placement="bottom-start">
                                <IconButton aria-label="Kid" onClick={(e) => handleOpenCourseSchedule(e, coursestreamdetail, row)} >
                                  <CalendarMonth />
                                </IconButton>
                              </Tooltip> */}
                              {/* <IconButton aria-label="Pod">
                                <KeyboardArrowRightIcon />
                              </IconButton> */}
                            </Stack>
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === '1' && '0') || 'Active'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {openChat ?
        <div className="e-pods-chat-container">
          <div className="e-pods-chat-main">
            <div className="e-pods-chat-header">
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                <Typography variant="h4">
                  Chats
                </Typography>
                <Typography >
                  <IconButton className="e-pods-chat" onClick={(e) => closeChat(e)}>
                    <img className="cursor-pointer" src={assets.whiteClose} width="16px" height="16px" />
                  </IconButton>
                </Typography>
              </Stack>
            </div>
            <div className="e-pods-chat-body">
              {renderSwitch()}
            </div>
            <div className="e-pods-chat-footer">

              <form className="mt-2 mb-2 chat-box-container-v2" action="" onSubmit={handleChatSubmit}>
                <textarea id="chat-text-area" rows={3} className="form-control send-input-v2" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write message" />
                <div className="input-group mb-3 send-input-box-v2">

                  <span className="pe-2 send-screenshot d-flex" >
                    <img src={assets.emoji} width={23} height={23} className="me-2" onClick={(e) => handleEmojiControlMenuClick(e)} ></img>
                    {/* <span onClick={(e) => sendScreenshot(e)}> {getIcon(icons_config.screenshot)}</span> */}
                  </span>
                  {/* <span className="pe-2 send-screenshot" >
                       
                      </span> */}
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary chat-send-btn" type="submit">
                      <img src={assets.sendChatWhite}></img>
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> : ""}

      {/* ADD NEW POD */}
      <Dialog id='assign_course' open={openAddPod} onClose={handleCloseAddPod}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Add Pod
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseAddPod}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(handleAddPodSubmit)} >
            <div>
              <Controller
                control={control}
                name="selectedExpert"
                rules={{ required: "Expert is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="experts"
                      variant="outlined"
                      margin="dense"
                    >
                      Experts
                    </InputLabel>
                    <Select
                      labelId="experts"
                      id="expert-select"
                      value={selectedExpert}
                      label="Expert"
                      onChange={handleExpertChange}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedExpert}
                      helperText={errors.selectedExpert?.message}
                    >
                      {expertList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{obj.firstname}({obj.email})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            {/* SESSIONS FORM */}
            <Grid item xs={12} sm={12} className="mt-4 mb-2">
              <Grid container alignItems="center" spacing={1}>
                {/* SESSION DATE */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="mt-sm-1">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        fullWidth
                        label="Start Date"
                        id={'session_start_date_id'}
                        name={'session_start_date'}
                        inputFormat="DD/MM/YYYY"
                        value={sessionStartDate}
                        onChange={(e) => handleStreamSessionChange(e, 'startdate')}
                        renderInput={(params) => <TextField
                          fullWidth
                          {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                {/* SESSION FROM TIME */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className=" mt-sm-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="From"
                        id={'session_from_id'}
                        name={'session_from'}
                        value={sessionStartTime}
                        onChange={(e) => handleStreamSessionChange(e, 'from')}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                {/* SESSION TO TIME */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className=" mt-sm-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="To"
                        id={'session_to_id'}
                        name={'session_to'}
                        value={sessionEndTime}
                        onChange={(e) => handleStreamSessionChange(e, 'to')}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* SCHEDULE A COURSE SESSION */}
      <Dialog id='assign_course' open={openScheduleCourse} onClose={handleCloseScheduleCourse}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Schedule Course
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseScheduleCourse}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(submitScheduleClass)} >
            <div>
              <Controller
                control={control}
                name="selectedSchedule"
                rules={{ required: "Schedule time is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="schedules"
                      variant="outlined"
                      margin="dense"
                    >
                      Sessions
                    </InputLabel>
                    <Select
                      labelId="schedules"
                      id="schedules-select"
                      value={selectedSchedule}
                      label="Language"
                      onChange={handleChangeCourseSchedule}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedSchedule}
                      helperText={errors.selectedSchedule?.message}
                    >
                      {scheduleList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{fDate(obj.startdate)}({convertTime(obj.from)} - {convertTime(obj.to)})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* ASSIGN COURSE TO PARENT */}
      <Dialog id='assign_course' open={openCourseAssign} onClose={handleCloseCourseAssign}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Assign Course
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseCourseAssign}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          <form onSubmit={handleSubmit(handleNext)} >
            <div>
              <Controller
                control={control}
                name="selectedParent"
                rules={{ required: "Parent is required" }}
                render={({ field }) => (
                  <FormControl style={{ width: "400px" }} className="mt-2">
                    <InputLabel
                      id="languages"
                      variant="outlined"
                      margin="dense"
                    >
                      Parents
                    </InputLabel>
                    <Select
                      labelId="languages"
                      id="languages-select"
                      value={selectedParent}
                      label="Language"
                      onChange={handleParentChange}
                      margin="dense"
                      variant="outlined"
                      {...field}
                      error={errors.selectedParent}
                      helperText={errors.selectedParent?.message}
                    >
                      {parentList.map((obj, index) => {
                        return (
                          <MenuItem value={obj.id}>{obj.firstname}({obj.email})</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <Button
              id="submit-button"
              className={classes.button}
              variant="contained"
              type="submit"
            >Submit
            </Button>
          </form>
        </DialogContent>
        {/* <DialogActions>
            <Button variant="outlined" className="add_new_linked_btn" fullWidth>
              <AddCircleIcon style={{ marginRight: 7 }} /> Add New Linked
            </Button>
          </DialogActions> */}
      </Dialog>

      {/* DISPLAY LINKED KIDS TO PARENT */}
      <Dialog id='course_linked_kids' open={openCourseKids} onClose={handleCloseCourseKids}>
        <DialogTitle>
          <div className="d-flex justify-content-center;">
            <Typography
              color="primary"
              style={{ marginRight: 4 }}
              className="select_quiz_text"
            >
              Kids - <span className='badge-kids-count'>{kidsList.length}</span>
            </Typography>

            <Typography
              color="action"
              style={{ marginLeft: "auto" }}
              onClick={handleCloseCourseKids}
              className="select_quiz_close_btn"
            >
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent >
          {kidsList.map((obj, index) => {
            return (
              <div className='stream-kids me-2 mb-2'>{obj.firstname} - {obj.lastname}({obj.email})</div>
            )
          })}
        </DialogContent>
      </Dialog>

      {/* CHAT MENU */}
      <Menu
        anchorEl={chatOptionEl}
        open={chatCtrlMenu}
        onClose={chatOptionMenuClickClose}
      >
        <MenuItem id="e-general-chat">
          <Typography variant="inherit" onClick={(e)=>openGeneralChats(e)}>
            General chat
          </Typography>
        </MenuItem>

        {courseGroups.map((sObj, sIndex) => {
          return (
            <MenuItem key={'admin-course-group-chat-' + sIndex} id={"admin-group-chat" + sIndex} value={sObj.coursegroupid} onClick={(e)=>openPodGroupChats(e,sObj)}>
              <Typography variant="inherit">
                {sObj?.groupname}
              </Typography>
            </MenuItem>)
        })}
      </Menu>

    </Page>
  );
}
