const sessionNotHandlePages = [
    '/expert-login',
    '/learner-login',
    '/admin-login',
    '/parent-login',
    '/home',
    '/checkout',
    '/parent-registration',
    '/content',
    '/demo-session',
    '/course-detail/:slug'
];
    
export default sessionNotHandlePages;