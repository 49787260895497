import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Card, Paper } from "@mui/material";
import account from "../../../../_mock/account";
import "./parents-details.css";

import { Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AdminAPIService from "../../../../services/admin-service";
import moment from "moment";
import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
//Dialog End
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as Yup from "yup";
import {
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  TextField,
  FormHelperText,
} from "@mui/material";
import { getCharacterValidationError } from "../../../../utils/util";
import { snackbar_options } from "../../../../config/snackbar_options";
// payment_table

function payment_list_data(date, coursename, totalamount, assignedtolearner) {
  return { date, coursename, totalamount, assignedtolearner };
}
const payment_table_rows = [
  payment_list_data("Mar 12,2022", "Cinemography", "$100", "Riku Samppa"),
  payment_list_data("Mar 12,2022", "Greek Mytology", "$100", "Pearce Crofton"),
  payment_list_data("Mar 12,2022", "Mytology", "$100", "Riku Samppa"),
];

// payment_table

//kids_table

function kids_detail_list(
  courseName,
  lastSessionDate,
  courseStatus,
  sessionStatus
) {
  return { courseName, lastSessionDate, courseStatus, sessionStatus };
}
const kids_detail_row = [
  kids_detail_list("Chinemography", "Mar 12,2022", "COMPLETED", "5/5"),
  kids_detail_list("Greek Mythology", "Mar 12,2022", "ONGOING", "2/5"),
  kids_detail_list("Greek Mythology", "Mar 12,2022", "ONGOING", "3/5"),
];
//

//  kids_data

const kids_data = [
  { id: "1", kids_Name: "Samppa", kids_course: "3 Courses" },
  { id: "2", kids_Name: "Riku Samppa", kids_course: "5 Courses" },
  { id: "3", kids_Name: "Riku", kids_course: "3 Courses" },
];
//  kids_data end
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ParentsDetails() {
  const [value, setValue] = React.useState(0);
  const [showtab, setShowtab] = useState(1);
  const [parentDetail, setParentDetail] = useState([]);

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.admin
    ) {
      setSelectedCustomerId(id);
      AdminAPIService.getParentProfileById(id)
        .then((response) => {
          console.log(response.data);
          setParentDetail(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate("/admin-login");
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handletabclick = (e) => {
    setShowtab(Number(e));
  };
  const [selectedCustomerName, setSelectedCustomerName] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [changeCustomerPasswordOpen, SetChangeCustomerPasswordOpen] =
    useState(false);
  const changeCustomerPasswordPopupOpen = () => {
    SetChangeCustomerPasswordOpen(true);
  };
  const changeCustomerPasswordPopupClose = () => {
    SetChangeCustomerPasswordOpen(false);
  };

  const CustomerPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: customerFormPasswordReg,
    control: customerFormPasswordCtrl,
    handleSubmit: customerFormPasswordSubmit,
    reset: customerFormReset,
    formState: { errors: CustomerFormPasswordErrors },
  } = useForm({
    resolver: yupResolver(CustomerPasswordSchema),
  });

  const CustomeronPasswordSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    try {
      // Update the kid's password using the MemberService
      await AdminAPIService.changeCustomerPassword(
        selectedCustomerId,
        data.password
      );

      // If the password update is successful, show a success message
      enqueueSnackbar(
        "password updated successfully",
        snackbar_options.success
      );
      customerFormReset();
      // Close the password change dialog
      SetChangeCustomerPasswordOpen(false);
    } catch (error) {
      // If an error occurs during password update, show an error message
      enqueueSnackbar(
        "Failed to update kid's password. Please try again later.",
        snackbar_options.error
      );

      // Optionally, log the error for debugging purposes
      console.error("Error updating kid's password:", error);
    }
  };

  return (
    <div>
      {/* Sample Nav */}
      <nav class="navbar navbar-expand-lg navbar-light bg-light mt-3">
        <div class="container">
          <div className="d-flex justify-content-center align-items-center">
            <Avatar src={account.photoURL} alt="photoURL" />
            <span className="profile_name_title">
              {parentDetail.firstname} {parentDetail.lastname}
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="tab_btn"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Payments" {...a11yProps(1)} />
            </Tabs>
          </div>

          <div class="d-flex">
            <Button className="block_parent_btn">Block Parent</Button>
          </div>
        </div>
      </nav>
      {/* Sample Nav End */}
      <hr></hr>
      <Box sx={{ width: "100%" }}>
        {/* Profile */}
        <TabPanel value={value} index={0}>
          <div className="container">
            <Box className="profile_box_section">
              {" "}
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">First Name</span>
                        <p className="name_text">{parentDetail?.firstname}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">Last Name</span>
                        <p className="name_text">{parentDetail?.lastname}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">
                          Registration Date
                        </span>
                        <p className="name_text">
                          {moment(parentDetail?.createdat).format("D/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-2 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">Address</span>
                        <p className="name_text">
                          {parentDetail?.address}
                        </p>
                      </div>
                    </div> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">Email</span>
                        <p className="name_text">{parentDetail?.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <span className="name_title_text">Phone</span>
                        <p className="name_text">{parentDetail?.mobile}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile_name_section">
                        <a
                          className=""
                          href="#"
                          onClick={changeCustomerPasswordPopupOpen}
                        >
                          Change Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            <div>
              {/* <h6 className="kids_title">Kids</h6> */}

              {parentDetail?.kids?.map((kids_datas, index) => {
                return (
                  <ul className="horizontal-list list-group-item">
                    <li key={index}>
                      <Card
                        id="kids_box"
                        className={
                          showtab === 1 ? "nav-link active" : "nav-link"
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Avatar src={account.photoURL} alt="photoURL" />
                          <div className="kids_text_section">
                            <div>
                              <span className="kids_name">
                                {kids_datas.firstname} {kids_datas.lastname}
                              </span>
                            </div>

                            {/* <span className="kids_course">
                              0 Courses
                            </span> */}
                          </div>
                          <div></div>
                        </div>
                      </Card>
                    </li>
                  </ul>
                );
              })}
            </div>

            <div className="tab-content" id="pills-tabContent">
              {/* table one */}
              <div
                className={
                  showtab === 1
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
              >
                {/* <TableContainer>
                  <h4 style={{ color: "black" }}>Samppa</h4>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="payment_table_header">
                      <TableRow>
                        <TableCell className="payment_table_header_text col_1">
                          Course Name
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_2"
                          align="left"
                        >
                          Last Session Date
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_3"
                          align="left"
                        >
                          Course States
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_4"
                          align="left"
                        >
                          Session Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kids_detail_row.map((row) => (
                        <TableRow
                          key={row.courseName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.courseName}
                          </TableCell>
                          <TableCell align="left">
                            {row.lastSessionDate}
                          </TableCell>
                          <TableCell style={{ color: "green" }} align="left">
                            {row.courseStatus}
                          </TableCell>
                          <TableCell align="left">
                            {row.sessionStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </div>
              {/* table two */}
              <div
                className={
                  showtab === 2
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
              >
                <TableContainer>
                  <h4 style={{ color: "black" }}>Riku Samppa</h4>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="payment_table_header">
                      <TableRow>
                        <TableCell className="payment_table_header_text col_1">
                          Course Name
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_2"
                          align="left"
                        >
                          Last Session Date
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_3"
                          align="left"
                        >
                          Course States
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_4"
                          align="left"
                        >
                          Session Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kids_detail_row.map((row) => (
                        <TableRow
                          key={row.courseName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.courseName}
                          </TableCell>
                          <TableCell align="left">
                            {row.lastSessionDate}
                          </TableCell>
                          <TableCell style={{ color: "green" }} align="left">
                            {row.courseStatus}
                          </TableCell>
                          <TableCell align="left">
                            {row.sessionStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* table three */}
              <div
                className={
                  showtab === 3
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
              >
                <TableContainer>
                  <h4 style={{ color: "black" }}>Riku</h4>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="payment_table_header">
                      <TableRow>
                        <TableCell className="payment_table_header_text col_1">
                          Course Name
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_2"
                          align="left"
                        >
                          Last Session Date
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_3"
                          align="left"
                        >
                          Course States
                        </TableCell>
                        <TableCell
                          className="payment_table_header_text col_4"
                          align="left"
                        >
                          Session Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kids_detail_row.map((row) => (
                        <TableRow
                          key={row.courseName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.courseName}
                          </TableCell>
                          <TableCell align="left">
                            {row.lastSessionDate}
                          </TableCell>
                          <TableCell style={{ color: "green" }} align="left">
                            {row.courseStatus}
                          </TableCell>
                          <TableCell align="left">
                            {row.sessionStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Profile end*/}

        {/* payment table */}
        <TabPanel value={value} index={1}>
          <div>
            {/* <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="payment_table_header">
                  <TableRow>
                    <TableCell className="payment_table_header_text col_1">
                      Date
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_2"
                      align="left"
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_3"
                      align="left"
                    >
                      Total Amount
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Assigned to Learner
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payment_table_rows.map((row) => (
                    <TableRow
                      key={row.coursename}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="left">{row.coursename}</TableCell>
                      <TableCell align="left">{row.totalamount}</TableCell>
                      <TableCell align="left">
                        {row.assignedtolearner}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </TabPanel>
        {/* payment table */}
      </Box>
      {/* change password */}
      <div>
        <Dialog
          open={changeCustomerPasswordOpen}
          fullWidth
          onClose={changeCustomerPasswordPopupClose}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title1">
            {"Change Password"}
            <span
              className="add_kids_close_icon"
              onClick={changeCustomerPasswordPopupClose}
            >
              <CloseIcon />
            </span>
          </DialogTitle>

          <DialogContent>
            <p>{parentDetail?.firstname}</p>
            <form
              onSubmit={customerFormPasswordSubmit(CustomeronPasswordSubmit)}
            >
              <Grid item xs={12} sm={12}>
                <Controller
                  control={customerFormPasswordCtrl}
                  name="password"
                  rules={{ required: "password is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={CustomerFormPasswordErrors.password}
                      {...customerFormPasswordReg("password")}
                      helperText={CustomerFormPasswordErrors.password?.message}
                    />
                  )}
                />
              </Grid>
              <Button
                className="mb-3"
                id="submit-button"
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
