

export const events = {
 zoom:{
     activeShareChange: 'active-share-change',
     activeSpeaker: 'active-speaker',
     mediaSDKChange: 'media-sdk-change',
     peerVideoStateChange: 'peer-video-state-change',
     userAdded: 'user-added',
     userRemoved: 'user-removed',
     userUpdated: 'user-updated'
 }
};
