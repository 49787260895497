import React, { useEffect, useState, createRef, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./group-space-v2.css";
import { useSelector, useDispatch } from "react-redux";
import {
  FaVideo,
  FaMicrophone,
  FaRegHandPaper,
  FaMicrophoneSlash,
  FaVideoSlash,
  FaPhoneSlash,
} from "react-icons/fa";
import { BsFileEarmarkText } from "react-icons/bs";

import io from "socket.io-client";
//Packages
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Menu,
  MenuItem,
  Typography,
  Stack,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Col, Container, Row } from "reactstrap";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import { SnackbarProvider, useSnackbar } from "notistack";

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

import {
  getChats,
  getCurrentUserData,
  getInitialsFromFullName,
  getOpenedChatGroup,
  storeChats,
  storeOpenedChatGroup,
} from "../../../utils/util";

//Compoents
import Scrollbar from "../../../components/Scrollbar";
import Iconify from "../../../components/Iconify";

import icons_config from "../../../config/icons";
import { fDate } from "../../../utils/formatTime";

//ASSETS

import Book_icon_img from "../../../assets/img/courses_img/book_icon_img.png";
// import background_img from "../../../assets/img/courses_img/background-img-gs.png"
import ShortLogo from "../../../assets/img/short_logo.png";
import VideoOnImg from "../../../assets/img/header_img/video_icon_img.png";
import VideoOffImg from "../../../assets/img/header_img/video_muted.png";
import VoiceOnImg from "../../../assets/img/header_img/voice_icon_img.png";
import VoiceOffImg from "../../../assets/img/header_img/audio_muted.png";
import assets from "../../../config/assets";
import storage_configs from "../../../config/storage-config";
import conditional_configs from "../../../config/conditional_config";

import {
  setGroupSpaceQuiz,
  setQuestion,
  setQuestions,
  setChoices,
  setCorrectAnswer,
} from "../../../store/slices/settingsSlice";

//CONFIGS
import game_config from "../../../config/game_config";
import url_configs from "../../../config/url";

//SERVICES
import GameService from "../../../services/games-service";
import UtilService from "../../../services/util.service";
import SessionService from "../../../services/session-service";
import tooltip_messages from "../../../config/tooltip";
import UserNameDetails from "../../../components/user-name-details/user-name-details";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import slugify from "react-slugify";
import MemberService from "../../../services/member-service";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 240,
    border: "0px solid gray",
    height: "40px",
    borderRadius: "30px",
    padding: "4px",
    boxShadow: "0px 0px 2px 2px #f6f1f1",
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": {
      width: 320,
      boxShadow: theme.shadows[8], // Use the shadow value from your theme
    },
    "& fieldset": {
      borderWidth: "1px !important",
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
}));

const quiz = {
  topic: "Javascript",
  level: "Beginner",
  totalQuestions: 10,
  perQuestionScore: 5,
  totalTime: 60, // in seconds
  questions: [
    {
      question:
        "Which function is used to serialize an object into a JSON string in Javascript?",
      choices: ["stringify()", "parse()", "convert()", "None of the above"],
      type: "MCQs",
      correctAnswer: "stringify()",
    },
    {
      question:
        "Which of the following keywords is used to define a variable in Javascript?",
      choices: ["var", "let", "var and let", "None of the above"],
      type: "MCQs",
      correctAnswer: "var and let",
    },
    {
      question:
        "Which of the following methods can be used to display data in some form using Javascript?",
      choices: [
        "document.write()",
        "console.log()",
        "window.alert",
        "All of the above",
      ],
      type: "MCQs",
      correctAnswer: "All of the above",
    },
    {
      question: "How can a datatype be declared to be a constant type?",
      choices: ["const", "var", "let", "constant"],
      type: "MCQs",
      correctAnswer: "const",
    },
  ],
};

const resultTabList = [
  { id: 1, name: "Quiz", active: true },
  { id: 2, name: "Games", active: false },
];

let chat_server_socket;

export default function GroupSpacegv2() {
  const { enqueueSnackbar } = useSnackbar();
  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;

  //GET SLUG FROM LINK
  let { slug } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //CHATS
  const [openChat, SetOpenChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiCtrlMenu, setEmojiCtrlMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [chatRoom, setChatRoom] = useState("");

  const groupSpaceQuiz = useSelector((state) => state.settings.groupSpaceQuiz);

  const questions = useSelector((state) => state.settings.questions);
  const question = useSelector((state) => state.settings.question);
  const choices = useSelector((state) => state.settings.choices);
  const correctAnswer = useSelector((state) => state.settings.correctAnswer);

  //GAMES
  const [gamesList, SetGamesList] = React.useState([]);
  const [gameLink, setGameLink] = React.useState();

  //WHITEBOARDS
  const [whiteBoardTabList, SetWhiteBoardTabList] = React.useState([]);
  const [whiteboardLink, setWhiteboardLink] = React.useState();
  const [whiteboardKey, setWhiteboardKey] = React.useState();

  //QUIZ
  const [activeQuestion, setActiveQuestion] = React.useState(0);
  const [selectedAnswer, setSelectedAnswer] = React.useState("");
  const [showResult, setShowResult] = React.useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = React.useState(null);

  //RESULTS
  const [resultTabs, SetResultTabs] = React.useState(resultTabList);
  const [currentResultTab, SetCurrentResultTab] = React.useState(1);
  const [learnerQuizResults, setLearnerQuizResults] = React.useState();
  const [learnerQuizOfflineResults, setLearnerQuizOfflineResults] =
    React.useState();
  const [learnerGameResults, setLearnerGameResults] = React.useState([]);
  const [learnerGameOfflineResults, setLearnerGameOfflineResults] =
    React.useState([]);

  const [result, setResult] = React.useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  // let { questions } = groupSpaceQuiz ? groupSpaceQuiz: quiz ;
  // let { question, choices, correctAnswer } = questions[activeQuestion];

  const [learnerAnswer, setLearnerAnswer] = React.useState(null);

  const [footerActive, setFooterActive] = React.useState("whiteboard");

  const [confirmationBoxOpen, setConfirmationBoxOpen] = React.useState(false);

  const [footerCtrlMenu, setFooterCtrlMenu] = useState(false);

  const handleCloseConfirmationBox = () => {
    setConfirmationBoxOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFooterControlMenuClick = (event) => {
    setFooterCtrlMenu(true);
  };
  const handleFooterControlMenuClose = () => {
    setFooterCtrlMenu(false);
  };

  useEffect(() => {
    setWhiteboardLink("");
    //let lgrp = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.learner_grp_detail));

    let tempBoards = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.group_space.gsp_boards
      )
    );
    if (tempBoards && tempBoards.length > 0) {
      tempBoards[0].active = true;
      setWhiteboardKey(tempBoards[0].id);
      setWhiteboardLink(tempBoards[0].whiteboardLink);
      //let generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(tempBoards[0].group_name) + '-div-' + slugify(tempBoards[0].id) + '-div-' + lgrp.coursegroupid + '-div-' + slug + '-div-'+ slugify(lgrp.groupname)+",-4-Gf1BCSf5ns_M4ChSamw";
      //setWhiteboardLink(generateBoardLink);
      SetWhiteBoardTabList(tempBoards);
    }

    // let tempGspQuiz = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.group_space.gsp_quiz));
    // if(tempGspQuiz && tempGspQuiz.length >0){

    //   dispatch(setGroupSpaceQuiz(tempGspQuiz));
    //   dispatch(setQuestions(tempGspQuiz.questions));

    // }
    // let tempQuiz = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.group_space.gsp_quiz));
    // dispatch(setGroupSpaceQuiz(tempQuiz));
    // dispatch(setQuestions(tempQuiz.questions));
    // dispatch(setQuestion(tempQuiz.questions[activeQuestion].question));
    // dispatch(setChoices(tempQuiz.questions[activeQuestion].choices));
    // dispatch(setCorrectAnswer(tempQuiz.questions[activeQuestion].correctAnswer));
    // if(tempQuiz){
    //     questions = tempQuiz.questions;
    //     if(questions.length > 0){
    //         question = questions[activeQuestion].question;
    //         choices = questions[activeQuestion].choices;
    //         correctAnswer = questions[activeQuestion].correctAnswer;
    //     }
    // }

    getQuizBySlugAndLearner();
  }, []);

  const openWhiteBoard = (e, obj, index) => {
    setWhiteboardLink("");
    e.stopPropagation();
    setWhiteboardLink("");
    setFooterActive("whiteboard");
    let data = [...whiteBoardTabList];
    data.forEach((element) => {
      element.active = false;
    });
    setWhiteboardKey(obj.id);
    setWhiteboardLink(data[index].whiteboardLink);
    //let generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(obj.group_name) + '-div-' + slugify(obj.id) + '-div-' + lgrp.coursegroupid + '-div-' + slug + '-div-'+ slugify(lgrp.groupname)+",-4-Gf1BCSf5ns_M4ChSamw";
    //setWhiteboardLink(generateBoardLink);
    data[index].active = true;
    SetWhiteBoardTabList(data);
  };

  const onFooterClick = (e, type) => {
    setFooterActive(type);
    if (type == conditional_configs.curriculam_types.games) {
      SetGamesList(
        JSON.parse(
          localStorage.getItem(
            storage_configs.localStorage.sessions.group_space.gsp_games
          )
        )
      );
      console.log("gamesList", gamesList);
    } else if (type == conditional_configs.curriculam_types.quiz) {
      let tempQuiz = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.group_space.gsp_quiz
        )
      );
      if (tempQuiz) {
        dispatch(setQuestions(tempQuiz.questions));
        if (tempQuiz.questions.length > 0) {
          dispatch(setQuestion(tempQuiz.questions[activeQuestion].question));
          dispatch(setChoices(tempQuiz.questions[activeQuestion].choices));
          dispatch(
            setCorrectAnswer(tempQuiz.questions[activeQuestion].correctAnswer)
          );
        }
      }
    } else if (type == "results") {
      getQuizBySlugAndLearner();
    } else if (type == "chats") {
      emitGroupChat();
    }
  };

  const openGame = (e, obj, idx) => {
    let learnerGrpDetail = JSON.parse(
      localStorage.getItem(
        storage_configs.localStorage.sessions.learner_grp_detail
      )
    );
    setWhiteboardKey(obj.id);
    let data = [...gamesList];
    data.forEach((element) => {
      element.active = false;
    });
    data[idx].active = true;
    SetGamesList(data);

    //Group Detail
    let groupDetail = {
      id: learnerGrpDetail.coursegroupid,
      name: learnerGrpDetail.groupname,
    };

    //Learner Detail
    let learnerDetail = {
      id: getCurrentUserData().id,
      name: getCurrentUserData().name,
      groupDetail: groupDetail,
    };

    //Game Detail
    let gameDetail = {
      id: obj.puzzleid,
      name: obj.gameDetail.name,
    };

    //Game Settings
    let gameSettings = {};
    GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id)
      .then((response) => {
        console.log(response);
        if (response && response.data && response.data.length > 0) {
          gameSettings = response.data[0].learnersettings[0];
          console.log("Game Settings");
          console.log(gameSettings);
          const course_detail = JSON.parse(
            localStorage.getItem(
              storage_configs.localStorage.sessions.course_detail
            )
          );

          let tempJsonObj = {
            learnerDetail: learnerDetail,
            gameDetail: gameDetail,
            userType: game_config.userType.student,
            gameSettings: gameSettings,
            slug: slug,
            courseStreamId: course_detail?.coursestreamid,
            isInSession: false,
          };
          console.log("My Game Obj");
          console.log(tempJsonObj);
          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList,
            };

            console.log(model);

            setIsGameCardContainerVisible(false);
            setIsWhiteboardIframeVisible(true);
            UtilService.encryptJsonValue(model)
              .then((response) => {
                console.log(response);
                if (response && response.data) {
                  setGameLink("");
                  let tempGameLink = obj.gameDetail.route + response.data.token;
                  setTimeout(() => {
                    setGameLink(tempGameLink);
                  }, conditional_configs.loader.timeout);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        } else {
          //NO GAMESETTINGS FOUND
          noGameSettingsFound("No game settings found!");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const noGameSettingsFound = (msg) => {
    Swal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };

  //QUIZ

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    setResult((prev) =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    formQuizResultData(
      questions[activeQuestion],
      learnerAnswer,
      selectedAnswer,
      "inprogress",
      result
    );

    if (activeQuestion !== questions.length - 1) {
      dispatch(setQuestion(questions[activeQuestion + 1].question));
      dispatch(setChoices(questions[activeQuestion + 1].choices));
      dispatch(setCorrectAnswer(questions[activeQuestion + 1].correctAnswer));
      setActiveQuestion((prev) => prev + 1);
    } else {
      formQuizResultData(
        questions[activeQuestion],
        learnerAnswer,
        selectedAnswer,
        "completed",
        result
      );
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const formQuizResultData = (
    activeQues,
    lAnswer,
    learnerAnswerResult,
    status,
    result
  ) => {
    let learnerQuizAnswers = [];
    let lQuizAnsObj = {
      actualQuestion: activeQues,
      learnerAnswered: lAnswer,
      learnerAnswerResult: learnerAnswerResult,
    };
    learnerQuizAnswers.push(lQuizAnsObj);

    if (status == "inprogress") {
      let quizResults = [];

      let getQuizResultsFromStorage = JSON.parse(
        localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
      );
      if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
        getQuizResultsFromStorage.forEach((element) => {
          if (element.status == status) {
            element.learnerQuizAnswers.push(lQuizAnsObj);
            addQuiz(element);
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.quizResults,
            JSON.stringify(getQuizResultsFromStorage)
          );
        }, 100);
      } else {
        let quizResultObj = {
          attempt: 1,
          totalQuestions: questions.length,
          totalScore: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
          status: status,
          userDetail: getCurrentUserData(),
          learnerQuizAnswers: learnerQuizAnswers,
        };
        quizResults.push(quizResultObj);
        localStorage.setItem(
          storage_configs.localStorage.sessions.quizResults,
          JSON.stringify(quizResults)
        );
        addQuiz(quizResultObj);
      }
    } else {
      let getQuizResultsFromStorage = JSON.parse(
        localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
      );
      if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
        getQuizResultsFromStorage.forEach((element) => {
          if (element.status == "inprogress") {
            element.status = status;
            element.totalScore =
              learnerAnswerResult == true ? result.score + 5 : result.score;
            element.correctAnswers =
              learnerAnswerResult == true
                ? result.correctAnswers + 1
                : result.correctAnswers;
            element.wrongAnswers =
              learnerAnswerResult !== true
                ? result.wrongAnswers + 1
                : result.wrongAnswers;
            element.learnerQuizAnswers.push(lQuizAnsObj);
            addQuiz(element);
          }
        });
        setTimeout(() => {
          localStorage.setItem(
            storage_configs.localStorage.sessions.quizResults,
            JSON.stringify(getQuizResultsFromStorage)
          );
        }, 100);
      }
    }
  };

  const addQuiz = (quizData) => {
    const course_detail = JSON.parse(
      localStorage.getItem(storage_configs.localStorage.sessions.course_detail)
    );

    if (quizData) {
      let quizModel = {
        isInSession: false,
        courseStreamId: course_detail?.coursestreamid,
        slug: slug,
        user: getCurrentUserData().name,
        userId: getCurrentUserData().id,
        totalScore: quizData.totalScore.toString(),
        correctAnswers: quizData.correctAnswers,
        wrongAnswers: quizData.wrongAnswers,
        totalQuestions: quizData.totalQuestions,
        attempt: quizData.attempt,
        status: quizData.status,
        learnerQuizAnswers: quizData.learnerQuizAnswers,
      };

      SessionService.updateQuizBySlugAndLearner(slug, quizModel)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onClickBackQuiz = () => {
    setActiveQuestion((prev) => prev - 1);
  };

  const retryQuiz = () => {
    setActiveQuestion(0);
    setShowResult(false);
    setResult({
      score: 0,
      correctAnswers: 0,
      wrongAnswers: 0,
    });
    let getQuizResultsFromStorage = JSON.parse(
      localStorage.getItem(storage_configs.localStorage.sessions.quizResults)
    );
    if (getQuizResultsFromStorage && getQuizResultsFromStorage.length > 0) {
      let quizResultObj = {
        attempt: getQuizResultsFromStorage.length + 1,
        totalQuestions: questions.length,
        totalScore: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
        status: "inprogress",
        userDetail: getCurrentUserData(),
        learnerQuizAnswers: [],
      };
      getQuizResultsFromStorage.push(quizResultObj);
      localStorage.setItem(
        storage_configs.localStorage.sessions.quizResults,
        JSON.stringify(getQuizResultsFromStorage)
      );
    }
  };

  const onAnswerSelected = (answer, index, answerObj) => {
    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }

    setLearnerAnswer(answerObj);
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  const getQuizBySlugAndLearner = () => {
    if (getCurrentUserData().role == conditional_configs.role.learner) {
      SessionService.getQuizBySlugAndLearner(slug)
        .then((response) => {
          console.log("Quizes", response);
          if (response && response.data && response.data.length > 0) {
            setLearnerQuizResults(
              response.data.filter((e) => e.isInSession == true)
            );
            setLearnerQuizOfflineResults(
              response.data.filter((e) => e.isInSession !== true)
            );
          }
          getGameResultBySlugAndUserId();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      SessionService.getQuizBySlug(slug)
        .then((response) => {
          console.log("Quizes", response);
          if (response && response.data && response.data.length > 0) {
            setLearnerQuizResults(
              response.data.filter((e) => e.isInSession == true)
            );
            setLearnerQuizOfflineResults(
              response.data.filter((e) => e.isInSession !== true)
            );
          }
          getGameResultBySlugAndUserId();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getGameResultBySlugAndUserId = () => {
    GameService.getGameResultBySlugAndUserId(slug)
      .then((response) => {
        console.log("Gameresults", response);
        if (response && response.data && response.data.length > 0) {
          setLearnerGameResults(
            response.data.filter((e) => e.isInSession == true)
          );
          setLearnerGameOfflineResults(
            response.data.filter((e) => e.isInSession !== true)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openResults = (e, obj, idx) => {
    let data = [...resultTabs];
    data.forEach((element) => {
      element.active = false;
    });
    data[idx].active = true;
    SetResultTabs(data);
    if (obj.id == 1) {
      getQuizBySlugAndLearner();
    } else {
      getGameResultBySlugAndUserId();
    }
    SetCurrentResultTab(obj.id);
  };

  const navigateTo = (e, page) => {
    if (page) {
      navigate(page);
    }
  };

  const [selectedWhiteboardTabIndex, setSelectedWhiteboardTabIndex] =
    useState(0);

  const handleWhiteboardTabChange = (event) => {
    setSelectedWhiteboardTabIndex(event.target.value);
  };

  const classes = useStyles();

  const [isGameCardContainerVisible, setIsGameCardContainerVisible] =
    useState(true);
  const [isWhiteboardIframeVisible, setIsWhiteboardIframeVisible] =
    useState(false);

  const backToGameList = (e) => {
    setIsGameCardContainerVisible(true);
    setIsWhiteboardIframeVisible(false);
  };

  //#region CHATS

  const emitGroupChat = () => {
    SetOpenChat(true);
    if (getCurrentUserData().role == conditional_configs.role.learner) {
      const current_stream_id = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.course_detail
        )
      );
      if (current_stream_id && current_stream_id.coursestreamid) {
        MemberService.getMemberCourseGroupByStreamAndLearnerId(
          current_stream_id.coursestreamid,
          getCurrentUserData().id
        )
          .then((res) => {
            console.log("getMemberCourseGroupByStreamAndLearnerId :::", res);
            if (res && res.data) {
              storeOpenedChatGroup(res.data);
              SessionService.getChatsByStreamId(
                current_stream_id.coursestreamid
              )
                .then((response) => {
                  console.log("Chats :::", response);
                  if (response && response.data && response.data.length > 0) {
                    storeChats(
                      response.data.filter(
                        (e) =>
                          e.group &&
                          e.group.coursegroupid == res.data.coursegroupid
                      )
                    );
                  }
                  let uName = getCurrentUserData().name;
                  let uRoom = res.data.groupname;
                  setChatRoom(slugify(uRoom));
                  chat_server_socket = io(url_configs.chat_server);
                  chat_server_socket.emit(
                    "join",
                    { name: uName, room: slugify(uRoom) },
                    (error) => {
                      if (error) {
                        alert(error);
                      }
                    }
                  );
                  chat_server_socket.on("message", (message) => {
                    let generalChats = [...messages];
                    let storedChats = getChats();
                    if (storedChats && storedChats.length > 0) {
                      generalChats = storedChats;
                    }
                    generalChats.push(message);
                    setMessages(generalChats);
                    storeChats(generalChats);

                    if (message && message.user != getCurrentUserData().name) {
                      enqueueSnackbar("Message received", {
                        variant: "success",
                        key: "topright",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      });

                      let tempNofications = JSON.parse(
                        localStorage.getItem(
                          storage_configs.localStorage.sessions.chatNotification
                        )
                      );
                      if (!tempNofications) {
                        tempNofications = [];
                      }
                      tempNofications.push(message);
                      localStorage.setItem(
                        storage_configs.localStorage.sessions.chatNotification,
                        JSON.stringify(tempNofications)
                      );
                    }
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else if (getCurrentUserData().role == conditional_configs.role.expert) {
      let current_stream_id = localStorage.getItem(
        storage_configs.localStorage.sessions.current_stream_id
      );
      if (current_stream_id)
        SessionService.getChatsByStreamId(current_stream_id)
          .then((response) => {
            console.log("Chats :::", response);
            if (response && response.data && response.data.length > 0) {
              storeChats(
                response.data.filter(
                  (e) =>
                    e.group &&
                    e.group.coursegroupid == getOpenedChatGroup().coursegroupid
                )
              );
            }
            let uName = getCurrentUserData().name;
            let uRoom = getOpenedChatGroup().groupname;
            setChatRoom(slugify(uRoom));
            chat_server_socket = io(url_configs.chat_server);
            chat_server_socket.emit(
              "join",
              { name: uName, room: slugify(uRoom) },
              (error) => {
                if (error) {
                  alert(error);
                }
              }
            );
            chat_server_socket.on("message", (message) => {
              let generalChats = [...messages];
              let storedChats = getChats();
              if (storedChats && storedChats.length > 0) {
                generalChats = storedChats;
              }
              generalChats.push(message);
              setMessages(generalChats);
              storeChats(generalChats);

              if (message && message.user != getCurrentUserData().name) {
                enqueueSnackbar("Message received", {
                  variant: "success",
                  key: "topright",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });

                let tempNofications = JSON.parse(
                  localStorage.getItem(
                    storage_configs.localStorage.sessions.chatNotification
                  )
                );
                if (!tempNofications) {
                  tempNofications = [];
                }
                tempNofications.push(message);
                localStorage.setItem(
                  storage_configs.localStorage.sessions.chatNotification,
                  JSON.stringify(tempNofications)
                );
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
      SetOpenChat(false);
      return;
    }
  };

  const closeChat = (e) => {
    SetOpenChat(false);
    window.location.reload();
  };

  const onClickEmoji = (emojiData, event) => {
    setSelectedEmoji(emojiData.unified);
    let tempMsg = message;
    //const textAreaElement = document.getElementById('chat-text-area');
    //setMessage(message.substr(0, textAreaElement.selectionStart) +emojiData.emoji  +message.substr(textAreaElement.selectionEnd));
    setMessage(tempMsg + emojiData.emoji);
    handleEmojiControlMenuClose();
  };

  const handleChatSubmit = (e) => {
    console.log("handleChatSubmit", message);
    e.preventDefault();
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: chatRoom,
      slug: chatRoom,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType:
        getCurrentUserData().role == conditional_configs.role.learner
          ? "learner"
          : getCurrentUserData().role == conditional_configs.role.expert
          ? "expert"
          : "admin",
    };
    if (message) {
      chat_server_socket.emit("sendMessage", {
        message: message,
        group: getOpenedChatGroup(),
        slug: chatRoom,
        userId: getCurrentUserData().id,
        userType: chatModel.userType,
      });
      chatModel.group = getOpenedChatGroup();
      setMessage("");
      addChats(chatModel);
    } else alert("empty input");
  };

  const addChats = (data) => {
    var current_stream_id = null;
    if (getCurrentUserData().role == conditional_configs.role.learner) {
      current_stream_id = JSON.parse(
        localStorage.getItem(
          storage_configs.localStorage.sessions.course_detail
        )
      ).coursestreamid;
    } else if (getCurrentUserData().role == conditional_configs.role.expert) {
      current_stream_id = localStorage.getItem(
        storage_configs.localStorage.sessions.current_stream_id
      );
    }
    if (current_stream_id) data.isInSession = false;
    data.courseStreamId = current_stream_id;
    SessionService.addChats(data)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEmojiControlMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEmojiCtrlMenu(true);
  };

  const handleEmojiControlMenuClose = () => {
    setEmojiCtrlMenu(false);
  };

  const findTypeOfMessage = (txtMessage) => {
    if (txtMessage) {
      let checkIsImg = txtMessage.includes("data:image/");
      return checkIsImg;
    }
  };

  const renderSwitch = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse();
    }

    return (
      <div className="chat-msg-list-v2">
        {msgs?.map((val, i) => {
          return (
            <div key={i}>
              <div className="chat-my-msg-main-v2">
                {val.user === getCurrentUserData().name ? (
                  <div>
                    <div className="my-name-container-v2">
                      <span className="my-name-v2">Me</span>
                    </div>
                    <div className="chat-my-msg-container-v2">
                      <div className="rvr-msg-container-v2">
                        <span className="rvr-name-v2">
                          {findTypeOfMessage(val.text) !== true ? (
                            val.text
                          ) : (
                            <img src={val.text} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="rvr-container-v2">
                    <div className="rvr-avatar-container">
                      {getInitialsFromFullName(val.user)}
                    </div>
                    <div className="w-100 rvr-msg-container-div">
                      <div className="rvr-name-container-v2">
                        <span className="chat-rvr-name-v2">
                          {val.user}&nbsp;
                          {val?.userType && val?.userType == "expert"
                            ? "(Expert)"
                            : val?.userType && val?.userType == "admin"
                            ? "(Admin)"
                            : ""}
                        </span>
                      </div>
                      <div className="chat-rvr-msg-container-v2">
                        <div className="rvr-msg-container-v2">
                          <span className="chat-rvr-msg-v2">
                            {findTypeOfMessage(val.text) !== true ? (
                              val.text
                            ) : (
                              <img src={val.text} />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  //#endregion
  return (
    <div className="learner-group-space-container-gv2">
      {/* HEADER LARGE SCREEN*/}
      <div className="large-screen">
        <div className="row ms-0 me-0 learner-group-space-header-height">
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0">
            <p className="learner-group-space-logo-container-gv2 mb-0">
              <img
                className="learner-group-space-short-logo"
                src={ShortLogo}
              ></img>
              <span className="ms-2 learner-group-space-bread-crumbs text-color-white">
                {" "}
                MEEKU&nbsp;
                <span
                  className="text-color-active-breadcrumbs-inactive"
                  onClick={(e) =>
                    navigateTo(
                      e,
                      getCurrentUserData().role ==
                        conditional_configs.role.learner
                        ? "/learner/my-courses"
                        : "/expert/my-courses"
                    )
                  }
                >
                  /&nbsp;MY COURSES
                </span>{" "}
                {getCurrentUserData().role ==
                conditional_configs.role.learner ? (
                  <span
                    className="text-color-active-breadcrumbs-inactive"
                    onClick={(e) => navigateTo(e, "/learner/pods")}
                  >
                    /&nbsp;SESSIONS
                  </span>
                ) : (
                  ""
                )}{" "}
                <span className="text-color-active-breadcrumbs">
                  /&nbsp;GROUP SPACE
                </span>
              </span>
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 text-center  text-color-white">
            <span className="learner-group-space-title-gv2">
              {" "}
              <div className="large-screen">
                <div className="controls-container">
                  {/* AUDIO ON/OFF */}

                  <Tooltip title={tooltip_messages.whiteboard}>
                    <div
                      className={
                        footerActive == "whiteboard"
                          ? `control-box footer-active`
                          : `control-box`
                      }
                      onClick={(e) => onFooterClick(e, "whiteboard")}
                    >
                      {getIcon(icons_config.wboard)}
                    </div>
                  </Tooltip>

                  {getCurrentUserData().role ==
                  conditional_configs.role.learner ? (
                    <Tooltip title={tooltip_messages.games}>
                      <div
                        className={
                          footerActive ==
                          conditional_configs.curriculam_types.games
                            ? `control-box footer-active`
                            : `control-box`
                        }
                        onClick={(e) =>
                          onFooterClick(
                            e,
                            conditional_configs.curriculam_types.games
                          )
                        }
                      >
                        {getIcon(icons_config.games)}
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {getCurrentUserData().role ==
                  conditional_configs.role.learner ? (
                    <Tooltip title={tooltip_messages.quiz}>
                      <div
                        className={
                          footerActive ==
                          conditional_configs.curriculam_types.quiz
                            ? `control-box footer-active`
                            : `control-box`
                        }
                        onClick={(e) =>
                          onFooterClick(
                            e,
                            conditional_configs.curriculam_types.quiz
                          )
                        }
                      >
                        {getIcon(icons_config.quiz_out_line)}
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title={tooltip_messages.results}>
                    <div
                      className={
                        footerActive == "results"
                          ? `control-box footer-active`
                          : `control-box`
                      }
                      onClick={(e) => onFooterClick(e, "results")}
                    >
                      {getIcon(icons_config.results)}
                    </div>
                  </Tooltip>
                  <div
                    className={
                      footerActive == "results"
                        ? `control-box footer-active`
                        : `control-box`
                    }
                    onClick={(e) => onFooterClick(e, "chats")}
                  >
                    {getIcon(icons_config.chat)}
                  </div>
                  {/* <div className="control-box" onClick={handleFooterControlMenuClick}>
                                <img src={assets.whiteDots} width="23px"></img>
                            </div> */}
                </div>
              </div>
            </span>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 d-flex justify-content-end">
            <UserNameDetails></UserNameDetails>
            {/* <div className="learner-user-name-container">

              <span className="me-1">{getCurrentUserData().name}</span>
              <span className="learner-user-name-icon">{getInitialsFromFullName(getCurrentUserData().name)}</span>
            </div> */}

            {/* <div className="learner-group-space-session-timing-gv2  text-color-white">
            <span>09:00</span>
          </div> */}
          </div>
        </div>
      </div>
      {/* HEADER MOBILE SCREEN */}
      <div className="mobile-screen">
        {/* HEADER MOBILE SCREEN LANDSCAPE */}
        <div className="mobile-screen-landscape">
          <div className="row ms-0 me-0 learner-group-space-header-height">
            <div className="col-4 ps-0 pe-0">
              <p className="learner-group-space-logo-container-gv2 mb-0">
                <img
                  className="learner-group-space-short-logo"
                  src={ShortLogo}
                ></img>
                <span className="ms-2 learner-group-space-bread-crumbs text-color-white">
                  {" "}
                  MEEKU{" "}
                  <span className="text-color-active-breadcrumbs">
                    / CLASSROOM
                  </span>
                </span>
              </p>
            </div>
            <div className="col-4 ps-0 pe-0 text-center  text-color-white">
              <span className="learner-group-space-title-gv2"></span>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 ps-0 pe-0 d-flex justify-content-end">
              <UserNameDetails></UserNameDetails>
              {/* <div className="learner-user-name-container">
                <span className="me-1">{getCurrentUserData().name}</span>
                <span className="learner-user-name-icon">{getInitialsFromFullName(getCurrentUserData().name)}</span>
              </div> */}
            </div>
            {/* <div className="col-4 ps-0 pe-0 d-flex justify-content-end">
            <div className="learner-group-space-session-timing-gv2  text-color-white">
              <span>09:00</span>
            </div>
          </div> */}
          </div>
        </div>
        {/* HEADER MOBILE SCREEN PORTRAIT */}
        <div className="mobile-screen-portrait">
          <div className="row ms-0 me-0 learner-group-space-header-height">
            <div className="col-6 col-sm-6 ps-0 pe-0">
              <p className="learner-group-space-logo-container-gv2 mb-0">
                <img
                  className="learner-group-space-short-logo"
                  src={ShortLogo}
                ></img>
                <span className="ms-2 learner-group-space-bread-crumbs text-color-white">
                  {" "}
                  MEEKU{" "}
                  <span className="text-color-active-breadcrumbs">
                    / CLASSROOM
                  </span>
                </span>
              </p>
            </div>
            {/* <div className="col-6 col-sm-6 ps-0 pe-0 d-flex justify-content-end">
                           <div className="learner-group-space-session-timing-gv2  text-color-white">
                       <span>09:00</span>
                     </div>
          </div> */}
          </div>
          <div className="row ms-0 me-0 learner-group-space-header-height">
            <div className="col-12 ps-0 pe-0 text-center  text-color-white">
              <span className="learner-group-space-title-gv2"></span>
            </div>
          </div>
        </div>
      </div>
      {/* CONTENT */}
      <div className="height-100">
        <div className="learner-group-space-content-height">
          <div className="learner-group-space-content-row">
            {/* LEFT SIDE CONTENT */}
            <div className="multi-div-gv2 h-100">
              {/* WHITEBOARDS */}
              {footerActive == "whiteboard" ? (
                // <div className="h-100 w-100 whiteboard-container-section-div">

                //   <FormControl className="dropdown-wb-gsp" sx={{
                //     minWidth: 120
                //   }}>
                //     <Select
                //       className={classes.select}
                //       value={selectedWhiteboardTabIndex}
                //       onChange={handleWhiteboardTabChange}
                //       displayEmpty
                //       inputProps={{ 'aria-label': 'Without label' }}

                //     >
                //       {whiteBoardTabList.map((wTab, wIndex) => (
                //         <MenuItem key={'whiteboard-tab-' + wIndex} value={wIndex} onClick={(e) => openWhiteBoard(e, wTab, wIndex)}>
                //           {wTab?.name}
                //         </MenuItem>
                //       ))}
                //     </Select>
                //   </FormControl>

                //   <iframe id={'l-pod-grpsp-board-' + whiteboardKey} className="whiteboard-iframe-gsp whiteboard-link-iframe-gsp" src={whiteboardLink}></iframe>
                // </div>
                <div className="h-100 w-100">
                  <div className="whiteboard-tabs-gsp">
                    {whiteBoardTabList.map((wTab, wIndex) => {
                      return (
                        <div
                          key={"id_whiteboard-" + wIndex}
                          className="whiteboard-tab-container-gsp"
                        >
                          <div
                            className={
                              wTab?.active
                                ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                                : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                            }
                            onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                          >
                            <div className="whiteboard-tab-name-gsp">
                              {wTab.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <iframe
                    id={"l-pod-grpsp-board-" + whiteboardKey}
                    className="whiteboard-iframe-gsp"
                    src={whiteboardLink}
                  ></iframe>
                </div>
              ) : // GAMES
              footerActive == conditional_configs.curriculam_types.games ? (
                <div className="h-100 w-100 game-container-section-div">
                  {isGameCardContainerVisible && (
                    <div className="game-card-container-gsp">
                      {gamesList.map((wTab, wIndex) => {
                        return (
                          <Card
                            key={"id_game_gsp-" + wIndex}
                            className={
                              wTab?.active
                                ? "game-card-active-gsp"
                                : "game-card-in-active-gsp"
                            }
                          >
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="game_img">
                                  <img
                                    src={wTab?.gameDetail?.img}
                                    alt={wTab?.gameDetail?.name}
                                    style={{ height: "100px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                {wTab?.gameDetail?.name}
                                <div className="row ms-0 me-0">
                                  <button
                                    className="play_game_btn_gsp"
                                    onClick={(e) => openGame(e, wTab, wIndex)}
                                  >
                                    Play
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                  {isWhiteboardIframeVisible && (
                    <Tooltip title="Back To Game List" placement="top">
                      <IconButton
                        className="game-back-icon-gsp"
                        onClick={(e) => backToGameList(e)}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isWhiteboardIframeVisible && (
                    <iframe
                      id={"id_game_gsp-" + whiteboardKey}
                      className="whiteboard-iframe-gsp game-link-iframe-gsp"
                      src={gameLink}
                    ></iframe>
                  )}
                </div>
              ) : footerActive == conditional_configs.curriculam_types.quiz ? (
                <div className="h-100 w-100">
                  <div className="quiz-container">
                    {!showResult ? (
                      <div>
                        <div>
                          <span className="active-question-no">
                            {addLeadingZero(activeQuestion + 1)}
                          </span>
                          <span className="total-question">
                            /{addLeadingZero(questions.length)}
                          </span>
                        </div>
                        <h2>{question}</h2>
                        <ul>
                          {choices.map((answer, index) => (
                            <li
                              onClick={() =>
                                onAnswerSelected(answer.id, index, answer)
                              }
                              key={`q-ans` + answer.id}
                              className={
                                selectedAnswerIndex === index
                                  ? "selected-answer"
                                  : null
                              }
                            >
                              {answer.answer}
                            </li>
                          ))}
                        </ul>
                        <div className="d-flex justify-content-between">
                          {activeQuestion > 0 ? (
                            <div className="flex-left">
                              <span
                                className="quiz-back-btn"
                                onClick={onClickBackQuiz}
                              >
                                Back
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="flex-right">
                            <button
                              onClick={onClickNext}
                              disabled={selectedAnswerIndex === null}
                            >
                              {activeQuestion === questions.length - 1
                                ? "Finish"
                                : "Next"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="result">
                        <h3>Result</h3>
                        <p>
                          Total Question: <span>{questions.length}</span>
                        </p>
                        <p>
                          Total Score:<span> {result.score}</span>
                        </p>
                        <p>
                          Correct Answers:<span> {result.correctAnswers}</span>
                        </p>
                        <p>
                          Wrong Answers:<span> {result.wrongAnswers}</span>
                        </p>
                        <div className="flex-right">
                          <span
                            className="retry-quiz-btn"
                            onClick={(e) => retryQuiz(e)}
                          >
                            Retry
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : footerActive == "results" ? (
                <div className="h-100 w-100">
                  <div className="whiteboard-tabs-gsp">
                    {resultTabs.map((rTab, rIndex) => {
                      return (
                        <div
                          key={"id_results_gsp-" + rIndex}
                          className="whiteboard-tab-container-gsp"
                        >
                          <div
                            className={
                              rTab?.active
                                ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                                : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                            }
                            onClick={(e) => openResults(e, rTab, rIndex)}
                          >
                            <div className="whiteboard-tab-name-gsp">
                              {rTab?.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="gsp-results-container">
                    {currentResultTab == 1 ? (
                      <div className="gsp-results-quiz">
                        <div>
                          {!learnerQuizResults && !learnerQuizOfflineResults ? (
                            <div className="gsp-no-quiz-found-container">
                              No quiz results found!
                            </div>
                          ) : (
                            <div>
                              {learnerQuizResults.length > 0 ? (
                                <Typography variant="h6">
                                  During session
                                </Typography>
                              ) : (
                                ""
                              )}
                              {learnerQuizResults?.map((resultObj, index) => (
                                <div>
                                  <Accordion
                                    key={"learner_quiz_" + index}
                                    id={"learner_quiz_id_" + index}
                                    elevation={12}
                                    className="mt-2"
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1a-content"
                                      variant="outlined"
                                    >
                                      <Typography>
                                        <Stack
                                          className="align-items-center"
                                          direction="row"
                                          spacing={1}
                                        >
                                          <span>
                                            Attempt - {resultObj.attempt}
                                          </span>
                                          <span className="gsp-quiz-question-pop">
                                            {learnerQuizResults?.user}
                                          </span>
                                          {resultObj?.status == "completed" ? (
                                            <span className="gsp-quiz-question-pop">
                                              {resultObj.totalScore} /{" "}
                                              {resultObj.totalQuestions * 5}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <span
                                            className={
                                              resultObj?.status == "completed"
                                                ? `gsp-quiz-status-flag-completed`
                                                : `gsp-quiz-status-flag-inprogress`
                                            }
                                          >
                                            {resultObj?.status == "completed"
                                              ? `Completed`
                                              : `In complete`}
                                          </span>
                                          <p className="gsp-quiz-question-pop">
                                            ({fDate(resultObj.createdAt)})
                                          </p>
                                          {getCurrentUserData().role ==
                                          conditional_configs.role.expert ? (
                                            <p className="gsp-quiz-question-pop">
                                              {" "}
                                              - {resultObj?.user}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </Stack>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        <Stack
                                          className="align-items-center mb-2"
                                          direction="row"
                                          spacing={1}
                                        >
                                          <span>
                                            Total Question:{" "}
                                            <span className="gsp-quiz-question-pop">
                                              {resultObj.totalQuestions}
                                            </span>
                                          </span>
                                          <span>
                                            Total Score:{" "}
                                            <span className="gsp-quiz-question-pop">
                                              {resultObj.totalScore}
                                            </span>
                                          </span>
                                          <span>
                                            Correct Answers:{" "}
                                            <span className="gsp-quiz-question-pop">
                                              {" "}
                                              {resultObj.correctAnswers}
                                            </span>
                                          </span>
                                          <span>
                                            Wrong Answers:{" "}
                                            <span className="gsp-quiz-question-pop">
                                              {" "}
                                              {resultObj.wrongAnswers}
                                            </span>
                                          </span>
                                        </Stack>
                                      </Typography>
                                      <ol>
                                        {resultObj?.learnerQuizAnswers.map(
                                          (lAnsObj, aIndex) => (
                                            <Card
                                              key={`ans-id-` + aIndex}
                                              className="mb-2 p-4"
                                            >
                                              <li>
                                                <Typography className="gsp-quiz-question-pop">
                                                  {
                                                    lAnsObj?.actualQuestion
                                                      ?.question
                                                  }
                                                </Typography>
                                                <ol>
                                                  {lAnsObj?.actualQuestion?.choices.map(
                                                    (lChoiceObj, cIndex) => (
                                                      <li
                                                        type="a"
                                                        key={
                                                          `q-choices-` + cIndex
                                                        }
                                                        className={
                                                          lChoiceObj?.correct ==
                                                          "1"
                                                            ? `gsp-quiz-correct-answer-pop`
                                                            : `gsp-quiz-answer-pop`
                                                        }
                                                      >
                                                        {lChoiceObj.answer}{" "}
                                                        {lChoiceObj?.correct ==
                                                        "1"
                                                          ? " - Correct Answer"
                                                          : ""}
                                                      </li>
                                                    )
                                                  )}
                                                </ol>
                                              </li>
                                              <div>
                                                <Typography
                                                  direction="row"
                                                  spacing={1}
                                                >
                                                  Learner Answer:{" "}
                                                  <span
                                                    className={
                                                      lAnsObj?.learnerAnswerResult ==
                                                      true
                                                        ? `gsp-quiz-question-pop gsp-quiz-learner-answer-correct`
                                                        : `gsp-quiz-question-pop gsp-quiz-learner-answer-wrong`
                                                    }
                                                  >
                                                    {
                                                      lAnsObj?.learnerAnswered
                                                        ?.answer
                                                    }
                                                  </span>
                                                </Typography>
                                              </div>
                                            </Card>
                                          )
                                        )}
                                      </ol>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              ))}

                              {learnerQuizOfflineResults.length > 0 ? (
                                <Typography variant="h6" className="mt-3">
                                  In group space
                                </Typography>
                              ) : (
                                ""
                              )}
                              {learnerQuizOfflineResults?.map(
                                (resultObj, index) => (
                                  <div>
                                    <Accordion
                                      key={"learner_quiz_" + index}
                                      id={"learner_quiz_id_" + index}
                                      elevation={12}
                                      className="mt-2"
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        variant="outlined"
                                      >
                                        <Typography>
                                          <Stack
                                            className="align-items-center"
                                            direction="row"
                                            spacing={1}
                                          >
                                            <span>
                                              Attempt - {resultObj.attempt}
                                            </span>
                                            <span className="gsp-quiz-question-pop">
                                              {learnerQuizOfflineResults?.user}
                                            </span>
                                            {resultObj?.status ==
                                            "completed" ? (
                                              <span className="gsp-quiz-question-pop">
                                                {resultObj.totalScore} /{" "}
                                                {resultObj.totalQuestions * 5}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <span
                                              className={
                                                resultObj?.status == "completed"
                                                  ? `gsp-quiz-status-flag-completed`
                                                  : `gsp-quiz-status-flag-inprogress`
                                              }
                                            >
                                              {resultObj?.status == "completed"
                                                ? `Completed`
                                                : `In complete`}
                                            </span>
                                            <p className="gsp-quiz-question-pop">
                                              ({fDate(resultObj.createdAt)})
                                            </p>
                                            {getCurrentUserData().role ==
                                            conditional_configs.role.expert ? (
                                              <p className="gsp-quiz-question-pop">
                                                {" "}
                                                - {resultObj?.user}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </Stack>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          <Stack
                                            className="align-items-center mb-2"
                                            direction="row"
                                            spacing={1}
                                          >
                                            <span>
                                              Total Question:{" "}
                                              <span className="gsp-quiz-question-pop">
                                                {resultObj.totalQuestions}
                                              </span>
                                            </span>
                                            <span>
                                              Total Score:{" "}
                                              <span className="gsp-quiz-question-pop">
                                                {resultObj.totalScore}
                                              </span>
                                            </span>
                                            <span>
                                              Correct Answers:{" "}
                                              <span className="gsp-quiz-question-pop">
                                                {" "}
                                                {resultObj.correctAnswers}
                                              </span>
                                            </span>
                                            <span>
                                              Wrong Answers:{" "}
                                              <span className="gsp-quiz-question-pop">
                                                {" "}
                                                {resultObj.wrongAnswers}
                                              </span>
                                            </span>
                                          </Stack>
                                        </Typography>
                                        <ol>
                                          {resultObj?.learnerQuizAnswers.map(
                                            (lAnsObj, aIndex) => (
                                              <Card
                                                key={`ans-id-` + aIndex}
                                                className="mb-2 p-4"
                                              >
                                                <li>
                                                  <Typography className="gsp-quiz-question-pop">
                                                    {
                                                      lAnsObj?.actualQuestion
                                                        ?.question
                                                    }
                                                  </Typography>
                                                  <ol>
                                                    {lAnsObj?.actualQuestion?.choices.map(
                                                      (lChoiceObj, cIndex) => (
                                                        <li
                                                          type="a"
                                                          key={
                                                            `q-choices-` +
                                                            cIndex
                                                          }
                                                          className={
                                                            lChoiceObj?.correct ==
                                                            "1"
                                                              ? `gsp-quiz-correct-answer-pop`
                                                              : `gsp-quiz-answer-pop`
                                                          }
                                                        >
                                                          {lChoiceObj.answer}{" "}
                                                          {lChoiceObj?.correct ==
                                                          "1"
                                                            ? " - Correct Answer"
                                                            : ""}
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                </li>
                                                <div>
                                                  <Typography
                                                    direction="row"
                                                    spacing={1}
                                                  >
                                                    Learner Answer:{" "}
                                                    <span
                                                      className={
                                                        lAnsObj?.learnerAnswerResult ==
                                                        true
                                                          ? `gsp-quiz-question-pop gsp-quiz-learner-answer-correct`
                                                          : `gsp-quiz-question-pop gsp-quiz-learner-answer-wrong`
                                                      }
                                                    >
                                                      {
                                                        lAnsObj?.learnerAnswered
                                                          ?.answer
                                                      }
                                                    </span>
                                                  </Typography>
                                                </div>
                                              </Card>
                                            )
                                          )}
                                        </ol>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {learnerGameResults.length == 0 &&
                        learnerGameOfflineResults.length == 0 ? (
                          <div className="gsp-no-quiz-found-container">
                            No game results found!
                          </div>
                        ) : (
                          ""
                        )}

                        {learnerGameResults.length > 0 ? (
                          <Typography variant="h6">During session</Typography>
                        ) : (
                          ""
                        )}

                        {learnerGameResults?.map((resultObj, index) => (
                          <div>
                            <Accordion
                              key={"learner_game_" + index}
                              id={"learner_game_id_" + index}
                              elevation={12}
                              className="mt-2"
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                variant="outlined"
                              >
                                <Typography>
                                  <Stack
                                    className="align-items-center"
                                    direction="row"
                                    spacing={1}
                                  >
                                    <span className="gsp-quiz-question-pop">
                                      {resultObj?.gamename}
                                    </span>
                                    <p className="gsp-quiz-question-pop">
                                      ({fDate(resultObj.createdAt)})
                                    </p>
                                  </Stack>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {resultObj?.puzzleresultsdetail.map(
                                  (lObj, aIndex) => (
                                    <Card
                                      key={`game-id-` + aIndex}
                                      className="mb-2 p-2"
                                    >
                                      <div>
                                        {Object.entries(lObj?.results[0]).map(
                                          ([property, value]) => (
                                            <div
                                              key={property}
                                              className="align-items-center"
                                            >
                                              <span className="gsp-game-property-text">
                                                {property}:{" "}
                                                <span className="gsp-quiz-question-pop">
                                                  {value}
                                                </span>
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Card>
                                  )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}

                        {learnerGameOfflineResults.length > 0 ? (
                          <Typography variant="h6" className="mt-3">
                            In group space
                          </Typography>
                        ) : (
                          ""
                        )}

                        {learnerGameOfflineResults?.map((resultObj, index) => (
                          <div>
                            <Accordion
                              key={"learner_game_" + index}
                              id={"learner_game_id_" + index}
                              elevation={12}
                              className="mt-2"
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                variant="outlined"
                              >
                                <Typography>
                                  <Stack
                                    className="align-items-center"
                                    direction="row"
                                    spacing={1}
                                  >
                                    <span className="gsp-quiz-question-pop">
                                      {resultObj?.gamename}
                                    </span>
                                    <p className="gsp-quiz-question-pop">
                                      ({fDate(resultObj.createdAt)})
                                    </p>
                                  </Stack>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {resultObj?.puzzleresultsdetail.map(
                                  (lObj, aIndex) => (
                                    <Card
                                      key={`game-id-` + aIndex}
                                      className="mb-2 p-2"
                                    >
                                      <div>
                                        {Object.entries(lObj?.results[0]).map(
                                          ([property, value]) => (
                                            <div
                                              key={property}
                                              className="align-items-center"
                                            >
                                              <span className="gsp-game-property-text">
                                                {property}:{" "}
                                                <span className="gsp-quiz-question-pop">
                                                  {value}
                                                </span>
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Card>
                                  )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* FOOTER CONTROLS */}
          <div className="footer-controls mobile-screen">
            <div className="controls-container">
              {/* AUDIO ON/OFF */}

              <Tooltip title={tooltip_messages.whiteboard}>
                <div
                  className={
                    footerActive == "whiteboard"
                      ? `control-box footer-active`
                      : `control-box`
                  }
                  onClick={(e) => onFooterClick(e, "whiteboard")}
                >
                  {getIcon(icons_config.wboard)}
                </div>
              </Tooltip>

              {getCurrentUserData().role == conditional_configs.role.learner ? (
                <Tooltip title={tooltip_messages.games}>
                  <div
                    className={
                      footerActive == conditional_configs.curriculam_types.games
                        ? `control-box footer-active`
                        : `control-box`
                    }
                    onClick={(e) =>
                      onFooterClick(
                        e,
                        conditional_configs.curriculam_types.games
                      )
                    }
                  >
                    {getIcon(icons_config.games)}
                  </div>
                </Tooltip>
              ) : (
                ""
              )}

              {getCurrentUserData().role == conditional_configs.role.learner ? (
                <Tooltip title={tooltip_messages.quiz}>
                  <div
                    className={
                      footerActive == conditional_configs.curriculam_types.quiz
                        ? `control-box footer-active`
                        : `control-box`
                    }
                    onClick={(e) =>
                      onFooterClick(
                        e,
                        conditional_configs.curriculam_types.quiz
                      )
                    }
                  >
                    {getIcon(icons_config.quiz_out_line)}
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              <Tooltip title={tooltip_messages.results}>
                <div
                  className={
                    footerActive == "results"
                      ? `control-box footer-active`
                      : `control-box`
                  }
                  onClick={(e) => onFooterClick(e, "results")}
                >
                  {getIcon(icons_config.results)}
                </div>
              </Tooltip>
              <div
                className={
                  footerActive == "results"
                    ? `control-box footer-active`
                    : `control-box`
                }
                onClick={(e) => onFooterClick(e, "chats")}
              >
                {getIcon(icons_config.chat)}
              </div>
              {/* <div className="control-box" onClick={handleFooterControlMenuClick}>
                                <img src={assets.whiteDots} width="23px"></img>
                            </div> */}
            </div>
          </div>
        </div>

        {openChat ? (
          <div className="l-pods-chat-container">
            <div className="l-pods-chat-main">
              <div className="l-pods-chat-header">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={{ xs: 0.5, sm: 1.5 }}
                >
                  <Typography variant="h4">Chats</Typography>
                  <Typography>
                    <IconButton
                      className="l-pods-chat"
                      onClick={(e) => closeChat(e)}
                    >
                      <img
                        className="cursor-pointer"
                        src={assets.whiteClose}
                        width="16px"
                        height="16px"
                      />
                    </IconButton>
                  </Typography>
                </Stack>
              </div>
              <div className="l-pods-chat-body">{renderSwitch()}</div>
              <div className="l-pods-chat-footer">
                <form
                  className="mt-2 mb-2 chat-box-container-v2"
                  action=""
                  onSubmit={handleChatSubmit}
                >
                  <textarea
                    id="chat-text-area"
                    rows={3}
                    className="form-control send-input-v2"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write message"
                  />
                  <div className="input-group mb-3 send-input-box-v2">
                    <span className="pe-2 send-screenshot d-flex">
                      <img
                        src={assets.emoji}
                        width={23}
                        height={23}
                        className="me-2"
                        onClick={(e) => handleEmojiControlMenuClick(e)}
                      ></img>
                      {/* <span onClick={(e) => sendScreenshot(e)}> {getIcon(icons_config.screenshot)}</span> */}
                    </span>
                    {/* <span className="pe-2 send-screenshot" >
                       
                      </span> */}
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary chat-send-btn"
                        type="submit"
                      >
                        <img src={assets.sendChatWhite}></img>
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Menu
        open={footerCtrlMenu}
        onClose={handleFooterControlMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem>
          <ListItemIcon></ListItemIcon>
          <Typography variant="inherit">My courses</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon></ListItemIcon>
          <Typography variant="inherit">My schedule</Typography>
        </MenuItem>
      </Menu>

      <Dialog
        fullScreen={fullScreen}
        open={confirmationBoxOpen}
        onClose={handleCloseConfirmationBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-center"
          id="responsive-dialog-title"
        >
          <img src={assets.alertIcon} width="90"></img>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="leave-session-warn">
            error_messages
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <div className="confirm-no-btn">No</div>
          <div className="confirm-yes-btn">Yes</div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
