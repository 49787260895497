import React, { useEffect, Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, FormControl, FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "./contact-popup.css";
import MemberService from "../../services/member-service";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider, useSnackbar } from "notistack";

// REGISTER FORM VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  contact_number: Yup.string()
    .required("Mobile number is required")
    .min(5, "Invalid mobile number"),
  description: Yup.string().required("Description is required"),
});

const ContactPopup = ({ open, onClose }) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (data) => {
    if (data) {
      const ref_code_c = localStorage.getItem('ref_code_c');
      var contactData = {
        name: data.name,
        email: data.email,
        contact_number: data.contact_number,
        description: data.description,
        ref_code: ref_code_c
      };
      MemberService.contactUs(contactData)
        .then((response) => {
          enqueueSnackbar("Form submitted successfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          onClose()
        })
        .catch((e) => {
          console.log(e);
          onClose()
        });
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle id="alert-dialog-title">
          {"Contact Us"}
          <span className="add_kids_close_icon" onClick={onClose}>
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="delete-confirmation">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/*  NAME*/}
              <Grid item xs={12} sm={12}>
                <TextField
                required
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  margin="dense"
                  error={Boolean(errors.name)}
                  helperText={errors.name ? errors.name.message : ""}
                  {...register("name", { required: true })}
                />
              </Grid>
  
              {/* EMAIL FIELD */}
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="dense"
                  error={Boolean(errors.email)}
                  helperText={errors.email ? errors.email.message : ""}
                  {...register("email", { required: true })}
                />
              </Grid>
  
              {/* MOBILE NUMBER */}
              <div className="form-group">
                <div className="input-group">
                  <FormControl fullWidth required margin="dense">
                    <PhoneInput
                      id="mobile-number"
                      name="contact_number"
                      label="Mobile number"
                      className="w-100 border border-gray-300 bg-white px-4 py-3 text-sm shadow-none parent-reg-mobile-number"
                      maxLength="15"
                      defaultCountry="US"
                      international
                      countryCallingCodeEditable={true}
                      placeholder="Enter phone number with country code"
                      error={Boolean(errors.contact_number)}
                      {...register("contact_number", { required: true })}
                    />
                    {errors.contact_number ? (
                      <FormHelperText className="requiredField">
                        {errors.contact_number.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </div>
              </div>
  
              <div className="form-group">
                <div className="input-group">
                  <FormControl fullWidth required margin="dense">
                  <textarea
                  required
                  className="textarea-contact-popup"
                  rows={4}
                  placeholder="Add description here.."
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  margin="dense"
                  error={Boolean(errors.description)}
                  helperText={
                    errors.description ? errors.description.message : ""
                  }
                  {...register("description", { required: true })}
                ></textarea>
                  </FormControl>
                </div>
              </div>
              
              <div className="d-flex mt-4 pb-3">
                <button type="submit" className="Submit-btn-prv2">
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
  );
};

export default ContactPopup;
