import React, { useState, useEffect } from 'react';
import storage_configs from '../config/storage-config';

const CountdownTimer = (props) => {
  const [seconds, setSeconds] = useState(props.seconds);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
        console.log('Countdown - ', seconds);
      } else {
        clearInterval(countdownInterval);
        // Do something when the countdown reaches zero
        console.log('Countdown reached zero!');
        localStorage.setItem(storage_configs.localStorage.sessions.networkTimerStatus, JSON.stringify('ended'));
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [seconds]);

  return (
    <span>
     {seconds}s
    </span>
  );
};

export default CountdownTimer;