import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./stripe-checkout-form";
import "./checkout.css";
import PaymentService from "../../services/payment-service";
import moment from "moment-timezone";

import {
  setStripeClientSecret,
  setCheckoutData,
} from "../../store/slices/settingsSlice";
//UTILS
import { checkIsINR, convertTime } from "../../utils/util";
import {
  Stack,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  Card,
  Button,
} from "@mui/material";

//ICONS
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import tooltip_messages from "../../config/tooltip";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

//ICONS
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { setCurrentLocationDetail } from "../../store/slices/settingsSlice";
//CONFIGS
import assets from "../../config/assets";

//SERVICES
import UtilService from "../../services/util.service";
import conditional_configs from "../../config/conditional_config";
import error_messages from "../../config/error_messages";
import url_configs from "../../config/url";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { calculatePrice } from "../../utils/util";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

//TESTS
const stripePromise = loadStripe(url_configs.stripeKey);

//LIVE
// const stripePromise = loadStripe(url_configs.stripeKey);

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clientSecret, setClientSecret] = useState("");
  const [selectedCourseToBuy, setSelectedCourseToBuy] = useState("");
  const checkoutData = useSelector((state) => state.settings.checkoutData);
  const currentLocationDetail = useSelector(
    (state) => state.settings.currentLocationDetail
  );

  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [soldLicense, setSoldLicense] = useState(0);
  const [actualQuatity, setActualQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getCurrentCountryInfoByIpAddress();
    console.log("checkoutData :::", checkoutData);
    setSelectedCourseToBuy(
      JSON.parse(localStorage.getItem("selected-course-to-buy"))
    );
    if (!checkoutData) {
      dispatch(
        setCheckoutData(JSON.parse(localStorage.getItem("checkout-data")))
      );
    }
    if (checkoutData) {
      createStripePayment(checkoutData);
    } else {
      navigate(-1);
    }
  }, []);

  const getCurrentCountryInfoByIpAddress = () => {
    UtilService.getCurrentCountryInfoByIpAddress()
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          dispatch(setCurrentLocationDetail(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const navigateTo = (page) => {
    if (page) {
      navigate(page);
    }
  };

  const createStripePayment = (data) => {
    PaymentService.createStripePayment({ items: [data] })
      .then((response) => {
        console.log("createStripePayment :: ", response);
        setClientSecret(response.clientSecret);
        dispatch(setStripeClientSecret(response.clientSecret));
      })
      .catch((error) => {
        console.log("createStripePayment :: Error");
        console.log(error);
      });
  };

  const getCourseDetail = () => {
    const courseInfo = JSON.parse(localStorage.getItem("course-info"));
    if (courseInfo) {
      return courseInfo;
    }
  };

  const getCalculatedPrice = () => {
    return JSON.parse(localStorage.getItem("checkout-data"));
  };
  const getSessionDetail = () => {
    const courseInfo = JSON.parse(
      localStorage.getItem("selected-course-to-buy")
    );
    return courseInfo;
  };

  //#region Open QTY popup

  const handleClickOpen = () => {
    var lic = 0;
    const memberPayments = getSessionDetail().memberpayment;
    for (var i = 0; i < memberPayments.length; i++) {
      lic = lic + memberPayments[i].license;
    }
    setTimeout(() => {
      setSoldLicense(lic);
      setErrorMessage(""); // Clear any previous error messages
      setOpen(true);
      setQuantity(actualQuatity);
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrease = () => {
    const courseInfo = JSON.parse(localStorage.getItem("course-info"));
    const getAvailedQty = courseInfo.maxnolearners - soldLicense;
    if (quantity >= getAvailedQty) {
      setErrorMessage(
        getAvailedQty == 1
          ? error_messages.oneSlotLeft
          : error_messages.slotsLeft.replace("%SLOT%", getAvailedQty)
      );
    } else {
      setErrorMessage(""); // Clear any previous error messages
      setQuantity(quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setErrorMessage("");
      setQuantity(quantity - 1);
    } else {
      setErrorMessage("This item has a minimum of 1 per order.");
    }
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);

    if (!isNaN(value) && value >= 1) {
      setErrorMessage("");
      setQuantity(value);
    } else {
      setErrorMessage("This item has a minimum of 1 per order.");
    }
  };

  const [shareLicenses, setShareLicenses] = useState(false);

  const handleCheckboxChange = (event) => {
    const newShareLicenses = event.target.checked;
    setShareLicenses(newShareLicenses);

    // Retrieve existing checkout data from local storage
    let checkoutDataWithLicenses = JSON.parse(
      localStorage.getItem("checkout-data")
    );

    if (checkoutDataWithLicenses) {
      // Update share_licenses property with new value
      checkoutDataWithLicenses.share_licenses = newShareLicenses;

      // Save updated checkout data back to local storage
      localStorage.setItem(
        "checkout-data",
        JSON.stringify(checkoutDataWithLicenses)
      );
    }
  };

  const [actualPrice, setActualPrice] = useState(0); // State to hold actual price
  const [discountPrice, setDiscountPrice] = useState(0); // State to hold discount price
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleUpdateQty = (e) => {
    const coureseId = getCourseDetail()?.id;
    console.log("coureseId", coureseId);
    setActualQuantity(quantity);

    // Calculate the price using calculatePrice function
    const { actualPrice, totalPrice, discountPrice, discountPercentage } =
      calculatePrice(
        coureseId,
        getCourseDetail().price, // Use price from the course detail
        quantity
      );

    // Update states with calculated prices
    setActualPrice(actualPrice);
    setDiscountPrice(discountPrice);
    setDiscountPercentage(discountPercentage);
    setTotalPrice(totalPrice);
    let model = {
      coursestreamid: checkoutData.coursestreamid,
      memberid: checkoutData.memberid,
      paidon: checkoutData.paidon,
      linkno: checkoutData.linkno,
      // amount: checkIsINR(
      //   currentLocationDetail ? currentLocationDetail.currency : "USD"
      // )
      //   ? (getCourseDetail().price_inr * quantity).toString()
      //   : (getCourseDetail().price * quantity).toString(),
      amount: totalPrice.toString(),
      quantity: quantity,
      currency: currentLocationDetail
        ? currentLocationDetail.currency.toLowerCase()
        : "usd",

      share_licenses: shareLicenses,
    };

    console.log("model", model);
    dispatch(setCheckoutData(model));
    localStorage.setItem("checkout-data", JSON.stringify(model));
    createStripePayment(model);
    handleClose();
  };
  //#endregion

  return (
    <div className="App">
      {clientSecret && (
        <div className="payment-main-div">
          <Card className="checkout-card">
            <div className="payment-col-left">
              <div className="payment-left-main">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <KeyboardArrowLeft
                    className="cursor-pointer"
                    onClick={() => navigateTo(-1)}
                  />
                  <img
                    className="payment-company-short-logo"
                    src={assets.shortLogo}
                  ></img>
                </Stack>
                <Stack
                  direction="row"
                  className="payment-course-name ms-0 me-0 mt-4"
                >
                  {getCourseDetail()?.name}
                </Stack>
                <Stack
                  direction="row"
                  className="payment-full-amount ms-0 me-0"
                >
                  {checkIsINR(
                    currentLocationDetail
                      ? currentLocationDetail?.currency
                      : "USD"
                  ) ? (
                    <span>
                      {conditional_configs.currency.inr}
                      {getCourseDetail()?.price_inr}
                    </span>
                  ) : (
                    <span>
                      {conditional_configs.currency.usd}
                      {getCourseDetail()?.price}
                    </span>
                  )}
                </Stack>

                <div className="checkout-sessions-main mt-4">
                  <div className="d-flex align-items-center">
                    <img
                      className="checkout-session-img"
                      src={assets.fileImage}
                      alt={getCourseDetail()?.name}
                    ></img>

                    <div>
                      <span className="ps-2 checkout-session-detail">
                        {moment(selectedCourseToBuy?.startdate).format("dddd") +
                          ", " +
                          moment(selectedCourseToBuy?.startdate).format(
                            "MMM Do"
                          ) +
                          " (" +
                          convertTime(selectedCourseToBuy?.from?.from) +
                          ")"}
                      </span>
                      <div
                        className="quantity-button-checkout"
                        onClick={handleClickOpen}
                      >
                        <span>Qty {actualQuatity}</span>
                        <KeyboardArrowDownIcon sx={{ fontSize: 17 }} />
                      </div>
                    </div>
                  </div>
                  <div className="checkout-session-amount">
                    {checkIsINR(
                      currentLocationDetail
                        ? currentLocationDetail?.currency
                        : "USD"
                    ) ? (
                      <span>
                        {conditional_configs.currency.inr}
                        {getCourseDetail()?.price_inr * actualQuatity}
                      </span>
                    ) : (
                      <span>
                        {conditional_configs.currency.usd}
                        {getCourseDetail()?.price * actualQuatity}
                      </span>
                    )}
                  </div>
                </div>
                {/* new */}
                {discountPrice ? (
                  <div>
                    <div className="checkout-sessions-main mt-1">
                      <div className="d-flex align-items-center">
                        Discount (%)
                      </div>
                      <div className="checkout-session-amount">
                        {discountPercentage}
                      </div>
                    </div>

                    <div className="checkout-sessions-main mt-1">
                      <div className="d-flex align-items-center">
                        Discount Price
                      </div>
                      <div className="checkout-session-amount">
                        -{" "}
                        {checkIsINR(
                          currentLocationDetail
                            ? currentLocationDetail?.currency
                            : "USD"
                        ) ? (
                          <span>
                            {conditional_configs.currency.inr} {discountPrice}
                          </span>
                        ) : (
                          <span>
                            {conditional_configs.currency.usd}
                            {discountPrice}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="checkout-sessions-main mt-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shareLicenses}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="share licenses"
                  />
                </div>
                <div className="checkout-total-border mt-4 mb-4">
                  <div className="checkout-sessions-main mt-2">
                    <span className="d-flex align-items-center justify-content-end checkout-session-total">
                      Total
                    </span>
                    {totalPrice ? (
                      <div className="checkout-session-amount">
                        {checkIsINR(
                          currentLocationDetail
                            ? currentLocationDetail?.currency
                            : "USD"
                        ) ? (
                          <span>
                            {conditional_configs.currency.inr} {totalPrice}
                          </span>
                        ) : (
                          <span>
                            {conditional_configs.currency.usd} {totalPrice}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="checkout-session-amount">
                        {checkIsINR(
                          currentLocationDetail
                            ? currentLocationDetail?.currency
                            : "USD"
                        ) ? (
                          <span>
                            {conditional_configs.currency.inr}{" "}
                            {getCourseDetail()?.price_inr * actualQuatity}
                          </span>
                        ) : (
                          <span>
                            {conditional_configs.currency.usd}{" "}
                            {getCourseDetail()?.price * actualQuatity}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="payment-col-right">
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </div>
          </Card>
        </div>
      )}

      {/* Dialog */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className="d-flex align-items-center justify-content-center;">
              <div className="">
                <p className="Update-quantity-text m-0">Update quantity</p>
                <p className="course-name-quantity-text m-0">
                  {" "}
                  {getCourseDetail()?.name}
                </p>
              </div>

              <div className="closeIcon-quantity ms-auto" onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <hr className="m-0"></hr>
          <DialogContent>
            <div className="input-container-quantity d-flex align-items-center justify-content-center">
              {" "}
              {/* Use CSS class names for styling */}
              <button
                className="me-3 Increase-button-quantity"
                onClick={handleDecrease}
              >
                -
              </button>
              <input
                type="number"
                value={quantity}
                onChange={handleInputChange}
                className="quantity-input"
              />
              <button
                className="ms-3 Increase-button-quantity"
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
            {errorMessage && (
              <p className="error-quantity-msg">{errorMessage}</p>
            )}
            <button
              className="update-quantity-btn"
              type="button"
              onClick={handleUpdateQty}
            >
              Update
            </button>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
