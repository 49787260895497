import React from "react";
import "./footer.css";
import { Box } from "@mui/material";
import assets from "../../config/assets";
import { useNavigate } from "react-router-dom";
import navigate_route_config from "../../config/navigate_route_config";
import CustomerService from "../../services/customer-service";
import { useState, useEffect } from "react";
import google_config from "../../config/google_config";

export default function Footer() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  //Email Form
  const [email, setEmail] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  //#region FOOTER FORM
  //ON EMAIL CHANGE EVENT
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  //ON EMAIL SUBSCRIBE BUTTON SUBMIT
  function onEmailFormSubmit(event) {
    // 👇️ prevent page refresh
    event.preventDefault();
    setIsSubmitting(true);
    subscribeUser();
    // window.grecaptcha.ready(() => {
    //     window.grecaptcha.execute(google_config.recaptcha.siteKey, { action: 'submit' }).then(token => {
    //         verifycaptcha(token);
    //     });
    // });
  }

  //GOOGLE CAPTCHA VERIFICATION
  async function verifycaptcha(token) {
    // Call Google's API to get score
    CustomerService.verifycaptcha({
      captcha: token,
    })
      .then((res) => {
        setIsSubmitting(false);
        if (res.status === 200 && res.data === true) {
          setIsSubmitted(true);
          subscribeUser();
        } else {
          clearFields();
          setIsSubmitted(true);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        clearFields();
        setIsSubmitted(true);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }

  //SUBSCRIBE USER API CALL
  const subscribeUser = () => {
    setIsSubmitting(false);
    CustomerService.subscribeUser({
      email: email,
    })
      .then((res) => {
        clearFields();
        setIsSubmitted(true);
      })
      .catch((error) => {
        clearFields();
        setIsSubmitted(true);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  //CLEAR INPUT FIELDS
  const clearFields = () => {
    setEmail("");
  };

  const navigateTo = (page) => {
    if (page) {
      navigate(page);
    }
  };

  const navigateToHome = (e) => {
    navigate("/");
  };

  //#endregion

  return (
    <div>
      <Box className="">
        <div className="footer-container-n">
          <div className="footer-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 order-2 order-sm-1 order-md-1 mb-4">
                  <p className="safe-environment-footer-text">
                    Meeku is a safe environment for children ages 8-14 where
                    children discover and solve challenges together.
                  </p>

                  {/* <div className="mt-3">
                    <p className="user-guide-footer-text-hv4">
                      <a
                        href={navigate_route_config.home.userGuide}
                        className="atag-color"
                        target="_blank"
                      >
                        User Guide
                      </a>
                    </p>
                  </div> */}

                  <div className="content">
                    <form className="subscription" onSubmit={onEmailFormSubmit}>
                      <input
                        required
                        autoComplete="off"
                        className="add-email"
                        type="email"
                        placeholder="Subscriber Newsletter"
                        id="subscriber-email-id"
                        name="subscriber-email-name"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <button
                        disabled={isSubmitting}
                        className="submit-email"
                        type="submit"
                      >
                        <span className="before-submit">Subscribe</span>
                        <span className="after-submit">
                          Thank you for subscribing!
                        </span>
                      </button>
                    </form>
                  </div>
                  {isSubmitted ? (
                    <span className="subscribe-completion-text">
                      Subscription completed!
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-4 order-1 order-sm-2 order-md-2 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      onClick={(e) => navigateToHome(e)}
                      className="meeku_logo_footer"
                      src={assets.homev3.meeku_logo_footer}
                    ></img>
                  </div>
                </div>
                <div className="col-lg-4 order-3 order-sm-3 order-md-3 mb-4">
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <img className="me-2" src={assets.course_v2.music_icon} style={{ width: "60px" }}></img>
                                        <img className="me-2" src={assets.course_v2.youtube_icon} style={{ width: "60px" }}></img> */}
                    <a
                      href="https://www.facebook.com/profile.php?id=100086239975028"
                      target="_blank"
                    >
                      <img
                        className="me-4"
                        src={assets.course_v2.facebook_icon}
                        style={{ width: "60px" }}
                      ></img>
                    </a>
                    <a
                      href="https://www.instagram.com/meeku_world/"
                      target="_blank"
                    >
                      <img
                        className="me-3"
                        src={assets.course_v2.Instagram_icon}
                        style={{ width: "65px" }}
                      ></img>
                    </a>
                    {/* <img className="me-2" src={assets.course_v2.twitter_icon} style={{ width: "60px" }}></img> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p className="Meeku-Learning-footer-text">
                © {currentYear} Meeku Learning. All Rights Reserved.{" "}
                <a
                  href={navigate_route_config.home.termsAndConditions}
                  className="atag-color"
                >
                  Terms and Conditions
                </a>
                &nbsp; | &nbsp;
                <a
                  href={navigate_route_config.home.privacyPolicy}
                  className="atag-color"
                >
                  Privacy Policy
                </a>
                {"   "}
              </p>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
