import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Paper, Box, Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Logo from "../../components/Logo";
import { LoadingButton } from '@mui/lab';
//Services
import AuthService from '../../services/auth/auth.service';
import './forgot-password.css'

export default function Forgotpassword() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
    AuthService.forgot_password(data.email)
      .then(response => {
        if (response && response.success === 'true') {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar(response.data, { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          navigate('/parent-login');
        } else if (response && response.success === 'false') {
          enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar('Something went wrong. Please contact Admin.', { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          enqueueSnackbar(error.message, { variant: 'error', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  };

  return (
    <div className="">
      <Grid
        className="forgot-password-container"
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper className="form-bg-c">
          <Box px={3} py={2}>
            <div className='d-flex justify-content-center'>
              <Logo />
            </div>
            <Typography align="center" margin="dense">
              <p className="forgot-text">Forgot Password?</p>
              <p className="forgot-text-1">
                Please enter your email, and we will send<br></br> you a link to
                create a new password
              </p>
            </Typography>
            {/* FORM */}
            <Grid container spacing={1}>
              {/* Email */}
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email address"
                  fullWidth
                  margin="dense"
                  {...register("email")}
                  error={errors.email ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.email?.message}
                </Typography>
              </Grid>
            </Grid>
              <LoadingButton className='forgot-btn mt-3 mb-2' fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit(onSubmit)}>
                Submit
              </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </div>
  );
}
