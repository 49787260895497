import React from "react";
import io from "socket.io-client";

import "./style.css";

import url_configs from "../../../config/url";

class Board extends React.Component {
  timeout;

  // Development
  socket = io.connect(url_configs.white_board_server);

  ctx;
  isDrawing = false;

  constructor(props) {
    super(props);

    //sample

    this.state = {
      name: "tool"
    };

    //sample

    var root = this;
    setTimeout(function () {
      var image = new Image();
      if (localStorage.getItem("imgSrc")) {
        var canvas = document.querySelector("#board");
        if(!canvas) return;
        var ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          ctx.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = localStorage.getItem("imgSrc");
      }
    }, 500);

    this.socket.on("canvas-data", function (data) {
      var root = this;

      var interval = setInterval(function () {
        if (root.isDrawing) return;

        root.isDrawing = true;
        clearInterval(interval);
        var image = new Image();

        var canvas = document.querySelector("#board");
        if(!canvas) return;
        var ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        image.onload = function () {
          ctx.drawImage(image, 0, 0);

          root.isDrawing = false;
        };
        image.src = data;
        this.data = localStorage.setItem("imgSrc", data);
      }, 200);
    });
  }

  componentDidMount() {
    this.drawOnCanvas();
  }

  componentWillReceiveProps(newProps) {
    this.ctx.strokeStyle = newProps.color;
    this.ctx.lineWidth = newProps.size;
  }

  drawOnCanvas() {
    var canvas = document.querySelector("#board");
    this.ctx = canvas.getContext("2d");
    var ctx = this.ctx;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // var sketch = document.querySelector("#sketch");
    // var sketch_style = getComputedStyle(sketch);
    // canvas.width = parseInt(sketch_style.getPropertyValue("width"));
    // canvas.height = parseInt(sketch_style.getPropertyValue("height"));

    var mouse = { x: 0, y: 0 };
    var last_mouse = { x: 0, y: 0 };
    var text_mouse = { x: 0, y: 0 };
    /* Mouse Capturing Work */
    canvas.addEventListener(
      "mousemove",
      function (e) {
        last_mouse.x = mouse.x;
        last_mouse.y = mouse.y;

        mouse.x = e.pageX - this.offsetLeft;
        mouse.y = e.pageY - this.offsetTop;
      },
      false
    );

    /* Drawing on Paint App */
    ctx.lineWidth = this.props.size;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.strokeStyle = this.props.color;

    canvas.addEventListener(
      "mousedown",
      function (e) {
        canvas.addEventListener("mousemove", onPaint, false);
      },
      false
    );

    canvas.addEventListener(
      "mouseup",
      function (e) {
        text_mouse.x = e.pageX - this.offsetLeft;
        text_mouse.y = e.pageY - this.offsetTop;
        canvas.removeEventListener("mousemove", onPaint, false);
      },
      false
    );
//onpoint
    var root = this;
    var onPaint = function () {
      
      ctx.beginPath();
      ctx.moveTo(last_mouse.x, last_mouse.y);
      ctx.lineTo(mouse.x, mouse.y);
      ctx.closePath();
      ctx.stroke();
      if (root.timeout !== undefined) clearTimeout(root.timeout);
      root.timeout = setTimeout(function () {
        //name

        var urlParams = new URLSearchParams(window.location.search);
        root.data = {
          y: mouse.x,
          x: mouse.y,
          user: urlParams.get("name"),
        };
        if (urlParams.get("name") != null)
          ctx.fillText(root.data.user, text_mouse.x + 5, text_mouse.y + 5);
        //name

        var base64ImageData = canvas.toDataURL();
        this.data = localStorage.setItem("imgSrc", base64ImageData);
        root.socket.emit("canvas-data", base64ImageData);
      }, 500);
    };



    
  }

  render() {
    return (
      <div>
        {/* <div class="sketch" id="sketch"> */}
        <canvas className="board" id="board"></canvas>
      </div>
    );
  }
}

export default Board;
