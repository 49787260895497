import React from 'react';
import LandHeader from '../../../components/landing-header/landing-header';
import JoinDemoSession from './join-demo-session-form';
import './join-demo-session-form.css';
import meta_data from '../../../config/meta_data';

function App() {
    document.title = meta_data.company + '| Demo Session';
    return (
        <div>
            {/* <LandHeader /> */}
            <JoinDemoSession />
        </div>
    );
}

export default App;
