import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";

const GetMyCourse = (kidid) => { 
    return http.get(api_path.l_get_my_course+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

//#region SCHEDULE
const GetTodaySchedule = (kidid) => { 
    return http.get(api_path.l_get_todays_schedule+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};
	  
const GetTommorowsSchedule = (kidid) => { 
    return http.get(api_path.l_get_tommorows_schedule+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetThisWeekSchedule = (kidid) => { 
    return http.get(api_path.l_get_thisweek_schedule+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const GetThisMonthSchedule = (kidid) => { 
    return http.get(api_path.l_get_thismonth_schedule+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};

const getAllScheduledClasses = (kidid) => { 
    return http.get(api_path.l_getAllScheduledClasses+ '/'+kidid,{ headers: authHeader() }).then((response) => {       
        return response.data;
      });
};
//#endregion

const LearnerService = {
    GetMyCourse,
    GetTodaySchedule,
    GetTommorowsSchedule,
    GetThisWeekSchedule,
    GetThisMonthSchedule,
    getAllScheduledClasses
};
export default LearnerService;