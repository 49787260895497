import React from 'react';
import Banner from './banner';
import LandHeader from '../../components/landing-header/landing-header';
import meta_data from '../../config/meta_data';

function App() {
    document.title = meta_data.company;
    return (
        <div>
            <LandHeader />
            <Banner />
        </div>
    );
}

export default App;
