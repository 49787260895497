
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
//Packages
import slugify from 'react-slugify';
import { useSelector, useDispatch } from "react-redux";

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { columnsFromBackend } from "./task-assign-list";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import TaskCard from "./task-assign-card";
import Box from "@mui/material/Box";
import account from "../../../_mock/account";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//import DynTabs from '../dyn-tabs/dyn-tabs';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Grid,
  TextField,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tabs,
  Tab,
  AppBar
} from "@mui/material";

import TextareaAutosize from "@mui/material/TextareaAutosize";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
//Components
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import Iconify from '../../../components/Iconify';

//images
import Assignment_img from "../../../assets/img/group_space/assignment_img.png";
import planning_img from "../../../assets/img/group_space/planning_img.png";
import team_img from "../../../assets/img/group_space/team_img.png";
import whiteboard_img from "../../../assets/img/group_space/whiteboard_img.png";
import closeWTab_img from "../../../assets/img/white-board/tabs/close.png";
import maximizeWTab_img from "../../../assets/img/white-board/tabs/maximize.png";
import minimizeWTab_img from "../../../assets/img/white-board/tabs/minimize.png";
import lockWhiteboard_img from "../../../assets/img/white-board/tabs/lock.png";
import unlockWhiteboard_img from "../../../assets/img/white-board/tabs/unlock.png";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End
//ICONS
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//ICONS
import "./task-assign-v2.css";

//SERVICES
import LookUpervice from "../../../services/look-up-service";
import MemberService from "../../../services/member-service";
import UtilService from "../../../services/util.service";
import GameService from '../../../services/games-service';
import ExpertService from '../../../services/expert-service';

//CONFIG
import navigate_route_config from "../../../config/navigate_route_config";
import storage_configs from "../../../config/storage-config";
import url_configs from "../../../config/url";
import conditional_configs from "../../../config/conditional_config";
import game_config from "../../../config/game_config";
import gamesListConfig from '../../../config/games';
import error_messages from '../../../config/error_messages';

//UTILS
import { getCurrentUserData, getOpenedChatGroup, getUnique, storeZoomClient } from "../../../utils/util";
import { setAutoPlayGameRouteName, setIsFluid, setIsMaximized } from '../../../store/slices/settingsSlice';

import LoadingSpinner from '../../shared/Loader/LoadingSpinner';

import io from "socket.io-client";
// Style


let maxTabIndex = 0;
let currentTablIndex = 0;

let b_room_socket;

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  //   min-height: 86px;
  border-radius: 8px;
  max-width: 350px;
  /* background: ${({ isDragging }) =>
    isDragging ? "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: rgb(255 255 255);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  color: black;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

// const Container = styled.div`
//   display: flex;
// `;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: rgb(241 243 246);
  min-width: 300px;
  border-radius: 8px;
  padding: 13px;
  margin-right: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  font-size: 18px;
  padding: 2px 5px;
  font-weight: bold;
  align-self: flex-start;
`;

// Style end
const BreakoutWhiteboard = () => {

  const [isLoading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState([]);

  const [groups, setGroups] = useState([]);

  const [currentGroupDetail, setCurrentGroupDetail] = useState([]);

  const [selectedTab, setSelectedTab] = useState("whiteboard");

  const [whiteboardLink, setWhiteboardLink] = useState("");

  const [whiteboardKey, setWhiteboardKey] = useState("");

  const [currentWhiteboardDetail, setCurrentWhiteboardDetail] = useState();

  const [gameLink, setGameLink] = useState("");

  const [currentStreamId, setCurrentStreamId] = useState();

  const [imageUrl, setImageUrl] = useState("");

  const [gameList, setGameList] = useState(gamesListConfig.gameList);

  let { streamId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentGroupName = useSelector((state) => state.settings.currentGroupName);
  const autoPlayGameRouteName = useSelector((state) => state.settings.autoPlayGameRouteName);
  const isFluid = useSelector((state) => state.settings.isFluid);
  const isMaximized = useSelector((state) => state.settings.isMaximized);

  //GET SLUG FROM LINK
  let { slug } = useParams();

  const [whiteBoardTabList, setWhiteBoardTabList] = useState([]);
  const [currentSessionList, setCurrentSessionList] = useState([]);
  const [currentSessionWhiteboardList, setCurrentSessionWhiteboardList] = useState([]);
  const [breakoutCurrentScreenData, setBreakoutCurrentScreenData] = useState(null);

  // Handle Tab Button Click
  const [tabId, setTabId] = React.useState(0);
  const handleTabChange = (event, newTabId) => {
    if (newTabId === "tabProperties") {
      handleAddTab();
    } else {
      currentTablIndex = newTabId;
      setTabId(newTabId);
    }
  };

  // Handle Add Tab Button
  const [tabs, setAddTab] = React.useState([]);
  const handleAddTab = () => {
    if (streamId)
      MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
        (response) => {
          console.log(response);
          if (selectedTab == 'whiteboard') {
            let url = '';
            if (getCurrentUserData().role == conditional_configs.role.expert) {
              url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
            } else {
              url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
            }
            setWhiteboardLink(url);
            localStorage.setItem('current-board', url);

            maxTabIndex = maxTabIndex + 1;
            setAddTab([
              ...tabs,
              <Tab label={`Whiteboard ${maxTabIndex}`} key={maxTabIndex} />
            ]);
            setTabsContent([
              ...tabsContent,
              <TabPanel tabId={tabId}>
                <div className='height-100'>
                  <iframe anonymous id={'id_whiteboard' + tabId} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                </div>
              </TabPanel>
            ]);
          }
          setCurrentGroupDetail(response.data);
        }
      ).catch((e) => {
        console.log(e);
      });
  };

  // Handle Add Tab Content
  const [tabsContent, setTabsContent] = React.useState([
    <TabPanel tabId={tabId}>
      <div className='height-100'>
        <iframe anonymous id={'id_whiteboard' + tabId} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
      </div>
    </TabPanel>
  ]);

  const handleTabsContent = () => {
    setTabsContent([
      ...tabsContent,
      <TabPanel tabId={tabId}>
        <div className='height-100'>
          <iframe anonymous id={'id_whiteboard' + tabId} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
        </div>
      </TabPanel>
    ]);
  };

  useEffect(() => {
    let tempTab = '';
    emitBreakoutRoom();
    let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
    if (tempScreenData) {
      setBreakoutCurrentScreenData(tempScreenData);
      if (tempScreenData.selectedCurriculamType == conditional_configs.curriculam_types.games && getCurrentUserData().role == conditional_configs.role.learner) {
        let tempGameObj = getGameDetailByGameId(tempScreenData.puzzleid);
        if (tempGameObj) {
          getGameLink(tempGameObj);
          dispatch(setAutoPlayGameRouteName(tempGameObj));
          setSelectedTab('games');
          tempTab = 'games';
          localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, 'games');
        }
      } else {
        setSelectedTab('whiteboard');
        tempTab = 'whiteboard';
      }
    } else {
      setSelectedTab('whiteboard');
      tempTab = 'whiteboard';
    }
    //setSelectedTab('whiteboard');
    if (streamId) {
      setCurrentStreamId(streamId)
      refreshTasks(streamId);
      //getCurrentMemberGroupDetail();
      if (tempTab == 'whiteboard') {
        openSelectedWhiteboard(tempScreenData, tempTab);
      }
      getMemberCourseGroupByStreamId(streamId);
    } else {
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
      streamId = current_stream_id;
      setCurrentStreamId(current_stream_id)
      if (current_stream_id) {
        refreshTasks(current_stream_id);
        // getCurrentMemberGroupDetail();
        if (tempTab == 'whiteboard') {
          openSelectedWhiteboard(tempScreenData, tempTab);
        }
        getMemberCourseGroupByStreamId(current_stream_id);
      } else {
        navigate(navigate_route_config.learner.my_courses);
      }
    }

  }, []);

  /** REFRESH Tasks DATA */
  const refreshTasks = (stId) => {
    LookUpervice.GetTaskStatus()
      .then((response) => {
        console.log(response);
        let statusList = [];
        response.data.forEach((sObj) => {
          let tempStatusObj = {
            category: "",
            createdAt: "",
            id: "",
            name: "",
            status: "",
            updatedAt: "",
            updatedby: "",
            value: "",
            taskList: [],
          };
          tempStatusObj.category = sObj.category;
          tempStatusObj.createdAt = sObj.createdAt;
          tempStatusObj.id = sObj.id;
          tempStatusObj.name = sObj.name;
          tempStatusObj.status = sObj.status;
          tempStatusObj.updatedAt = sObj.updatedAt;
          tempStatusObj.updatedby = sObj.updatedby;

          MemberService.GetTaskList(tempStatusObj.id, stId).then(
            (response_2) => {
              tempStatusObj.taskList = response_2.data;
              console.log("response_2", response_2.data);
              statusList.push(tempStatusObj);
              setColumns([...statusList]);
            }
          ).catch((e) => {
            console.log(e);
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /** GET GROUPS BY STREAM ID */
  const getMemberCourseGroupByStreamId = (id) => {
    MemberService.getMemberCourseGroupByStreamIdNew(id).then(
      (response) => {
        console.log("Groups", response.data);
        setGroups(response.data);
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  /** GET CURRENT MEMBER GROUP DETAIL */
  const getCurrentMemberGroupDetail = () => {
    if (streamId)
      MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
        (response) => {
          console.log('getMemberCourseGroupByStreamAndLearnerId', response);
          if (selectedTab == 'whiteboard') {
            localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, 'whiteboard');
            let url = '';
            let tempGroupName = "";
            if (getCurrentUserData().role == conditional_configs.role.expert) {
              url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
              tempGroupName = currentGroupName;
            } else {
              url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
              tempGroupName = response?.data?.groupname;
            }
            if (response && response.data) {
              getCurriculam(response?.data?.courseid);
            }
            /** MULTIPLE WHITEBOARD TABS */
            setWhiteboardLink(url);
            localStorage.setItem('current-board', url);
            let whiteBoardTabContent = {
              username: getCurrentUserData().name,
              whiteboardLink: url,
              tabName: tempGroupName,
              slug: slug,
              id: 0,
              type: "master",
              active: true,
              isLocked: false
            }
            //pushWhiteBoardTabContent(whiteBoardTabContent);
            let data = [...whiteBoardTabList];
            data.push(whiteBoardTabContent);
            setTimeout(() => {
              MemberService.getAllActiveByGroupId(response?.data?.coursegroupid).then(
                (response) => {
                  console.log("Board Data bv2", response.data);
                  //let data = [...whiteBoardTabList];
                  for (let i = 0; i < response.data.length; i++) {
                    data.push(response.data[i]);
                  }
                  setWhiteBoardTabList(data);
                }
              ).catch((e) => {
                console.log(e);
              });
            }, 2000);

          }
          setCurrentGroupDetail(response.data);
        }
      ).catch((e) => {
        console.log(e);
      });
  }

  const getCurriculam = (sId) => {
    ExpertService.GetCurriculum(sId)
      .then((response) => {
        console.log(response.data);
        setCurrentSessionList(response.data)
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /** Add Whiteboard Tab */
  const pushWhiteBoardTabContent = async (content) => {
    let data = [...whiteBoardTabList];
    setWhiteboardKey(content.id);
    let whiteBoardTabContent = null;
    if (content.type == "master") {
      whiteBoardTabContent = {
        username: content.username,
        whiteboardLink: content.whiteboardLink,
        tabName: content.tabName,
        slug: content.slug,
        type: content.type,
        id: content.id,
        active: content.active,
        isLocked: content.isLocked,
        userId: getCurrentUserData().id,
        streamId: streamId
      }
      data.push(whiteBoardTabContent);
    } else {

      // whiteBoardTabContent = {
      //   username: content.username,
      //   whiteboardLink: content.whiteboardLink,
      //   tabName: content.tabName + '-' + (whiteBoardTabList.length + 1),
      //   slug: content.slug,
      //   type: "child",
      //   id: content.id,
      //   active: content.active,
      //   isLocked: content.isLocked
      // }
      data.push(content);
    }
    setWhiteBoardTabList(data);
    //setWhiteBoardTabList([...whiteBoardTabList, whiteBoardTabContent]);


  }

  const addNewWhiteboardTab = (e) => {
    let data = [...whiteBoardTabList];
    data.forEach(element => {
      element.active = false;
    });
    const tempStreamId = !streamId ? localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id): streamId;
    let tempBoardSlug = slugify('White Board-' + tempStreamId + '-' + getCurrentUserData().id + '-' + (whiteBoardTabList.length + 1))
    let tempWLink = generateWhiteboardLink(currentGroupName, slug, tempBoardSlug);
    setWhiteboardLink(tempWLink);
    localStorage.setItem('current-board', tempWLink);
    // let whiteBoardTabContent = {
    //   username: getCurrentUserData().name,
    //   whiteboardLink: tempWLink,
    //   tabName: 'White Board-' + (whiteBoardTabList.length + 1),
    //   slug: slug,
    //   type: "child",
    //   id: (whiteBoardTabList.length + 1),
    //   active: true,
    //   isLocked: false
    // }
    //pushWhiteBoardTabContent(whiteBoardTabContent);
    let whiteBoardTabContent = {
      username: getCurrentUserData().name,
      whiteboardLink: tempWLink,
      tabName: 'White Board-' + (whiteBoardTabList.length + 1),
      slug: tempBoardSlug,
      type: "child",
      active: true,
      isLocked: false,
      streamId: tempStreamId,
      userId: getCurrentUserData().id,

      createdby: getCurrentUserData().id,
      groupId: getOpenedChatGroup().coursegroupid,
      sessionId: localStorage.getItem(
        storage_configs.localStorage.sessions.membercoursestreamdetailid
      )
    }
    MemberService.addMemberStreamBoard(whiteBoardTabContent).then(
      (response) => {
        console.log("Board Data", response.data);
        pushWhiteBoardTabContent(response.data);
        //REFRESH BOARDS
        const refBoards = {
          slug: slug,
          groupId: getOpenedChatGroup().coursegroupid,
          userId: getCurrentUserData().id,
          user: getCurrentUserData().name,
          id: 0,
          isRemove: false
        }
        refreshWhiteboards(refBoards);
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  const removeWhiteboardTab = (e, index) => {
    e.stopPropagation();
    let data = [...whiteBoardTabList];
    if (data[index].type == 'child') {
      //REFRESH BOARDS
      const refBoards = {
        slug: slug,
        groupId: getOpenedChatGroup().coursegroupid,
        userId: getCurrentUserData().id,
        user: getCurrentUserData().name,
        id: data[index].id,
        isRemove: true
      }
      refreshWhiteboards(refBoards);
      MemberService.softDeleteMemberStreamBoard(data[index].id, data[index]).then(
        (response) => {
          data.splice(index, 1)

          setWhiteBoardTabList(data);
          
          // //let data = [...whiteBoardTabList];
          // for(let i=0; i<response.data.length;i++){
          //   data.push(response.data[i]);
          // }
          // setWhiteBoardTabList(data);
        }
      ).catch((e) => {
        console.log(e);
      });
    }


  }

  const openWhiteBoard = (e, obj, index) => {
    e.stopPropagation()
    setWhiteboardKey(obj.id);
    let data = [...whiteBoardTabList];
    data.forEach(element => {
      element.active = false;
    });
    setWhiteboardLink(data[index].whiteboardLink);
    localStorage.setItem('current-board', data[index].whiteboardLink);
    data[index].active = true;
    setWhiteBoardTabList(data);
    setCurrentWhiteboardDetail(data[index]);

    let current_learner_groupname = localStorage.getItem(storage_configs.localStorage.sessions.current_learner_groupname);

    let screenDetail = {
      screenData: currentWhiteboardDetail,
      url: data[index].whiteboardLink,
      slug: slug,
      user: getCurrentUserData(),
      group: current_learner_groupname.toLowerCase()
    }

    b_room_socket.emit("on-b-room-learner-opens-board", { data: screenDetail }, (error) => {
      if (error) {
        console.log(error);
      }
    });
  }

  const onClickWhiteboard = () => {
    alert("Please unlock the board to modify.")
  }

  const maximizeWhiteboardLock = (e, obj, index) => {
    e.stopPropagation();
    //dispatch(setIsMaximized(!isMaximized))
    dispatch(setIsFluid(true))
  }

  const miniimizeWhiteboardLock = (e, obj, index) => {
    e.stopPropagation();
    //dispatch(setIsMaximized(!isMaximized))
    dispatch(setIsFluid(false))
  }
  const toggleWhiteboardLock = (e, obj, index) => {
    e.stopPropagation();
    if (obj.type == 'template' && getCurrentUserData().role !== conditional_configs.role.expert) {
      return;
    }

    let data = [...whiteBoardTabList];
    let tempLock = data[index].isLocked;
    data[index].isLocked = !tempLock;
    setWhiteBoardTabList(data);
    if (data[index].active == true) {
      setCurrentWhiteboardDetail(data[index]);
    }

    if ((obj.type == 'template' || obj.type == 'master') && getCurrentUserData().role === conditional_configs.role.expert) {
      b_room_socket.emit("lock-whiteboard", { boardDetail: data[index], locked: !tempLock }, (error) => {
        if (error) {
          console.log(error);
        }
      });
    }

  }

  const generateWhiteboardLink = (group, slug, tab) => {
    let url = "";
    url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + group + "&slug=" + slug + "#room=" + tab + ",-4-Gf1BCSf5ns_M4ChSamw";
    return url;
  }

  /** CHANGE TASK STATUS */
  const changeTaskStatus = (destId, taskid) => {
    let model = {
      taskstatus: destId.toString()
    }
    MemberService.changeStatus(model, taskid).then(
      (response) => {
        console.log("Change task completed", response.data);
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  //DRAG FUNCTION
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.taskList];
      const destItems = [...destColumn.taskList];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          taskList: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          taskList: destItems,
        },
      });

      changeTaskStatus(destColumn.id, result.draggableId);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.taskList];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          taskList: copiedItems,
        },
      });
    }
  };

  //GAME ICON BUTTON FUNCTION
  const [plan_btn, setPlan_btn] = React.useState(null);
  const [gameListMenuItems, setGameListMenuItems] = React.useState(null);
  const [sessionListMenuItems, setSessionListMenuItems] = React.useState(null);
  const [sessionWhiteboardListMenuItems, setSessionwhiteboardListMenuItems] = React.useState(null);
  const open_select = Boolean(plan_btn);
  const selectGameMenu = Boolean(gameListMenuItems);
  const selectSessionMenu = Boolean(sessionListMenuItems);
  const selectSessionWhiteboardMenu = Boolean(sessionWhiteboardListMenuItems);

  //#region MENU ITEM EVENTS
  /** SESSION WHITEBOARDS MENU ITEM EVENTS */
  const openSessionWhiteboardMenu = (event, obj) => {
    setSessionwhiteboardListMenuItems(event.currentTarget);
    setCurrentSessionWhiteboardList(obj.coursesession_whiteboards);
  };

  const closeSessionWhiteboardMenu = () => {
    setSessionwhiteboardListMenuItems(null);
  };

  /** SESSION MENU ITEM EVENTS */
  const openSessionsMenu = (event, tab) => {
    setSessionListMenuItems(event.currentTarget);
    headerMenuChange(tab);
  };

  const closeSessionsMenu = () => {
    setSessionListMenuItems(null);
  };

  /** PLANNING MENU ITEM EVENTS */
  const plan_btn_open = (event) => {
    setPlan_btn(event.currentTarget);
  };

  const plan_btn_close = () => {
    setPlan_btn(null);
  };

  /** GAMES MENU ITEM EVENTS */
  const openGamesMenu = (event) => {
    setGameListMenuItems(event.currentTarget);
  };

  const closeGamesMenu = () => {
    setGameListMenuItems(null);
  };
  //#endregion

  /** HEADER MENU SELECTION EVENT PLANNING/WHITEBOARD/TEAM/ASSIGNMENT */
  const headerMenuChange = (tab) => {
    console.log(tab);
    setSelectedTab(tab);
    dispatch(setAutoPlayGameRouteName(null));
    localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, tab);
    if (tab == 'whiteboard') {
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
      streamId = current_stream_id;
      if (streamId) {
        getCurrentMemberGroupDetail();
        // MemberService.getMemberCourseGroupByStreamAndLearnerId(current_stream_id, getCurrentUserData().id).then(
        //   (response) => {
        //     console.log(response)
        //     setCurrentGroupDetail(response.data);
        //     console.log(response.data?.groupname)
        // let url = url_configs.white_board_server +response.data?.groupname+'?name=' + getCurrentUserData().name
        // setWhiteboardLink(url);
        //   }
        // ).catch((e) => {
        //   console.log(e);
        // });
      }


    }

    if (tab == 'games') {
      plan_btn_close();
      let tempJsonObj;
      if (getCurrentUserData().role == conditional_configs.role.expert) {
        if (localStorage.getItem(storage_configs.localStorage.sessions.current_group_id)) {
          let groupIds = [];
          let gId = 0;
          gId = localStorage.getItem(storage_configs.localStorage.sessions.current_group_id);
          groupIds.push(gId);

          let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

          tempJsonObj = {
            userId: getCurrentUserData().id.toString(),
            userType: game_config.userType.teacher,
            name: getCurrentUserData().name,
            groupId: groupIds,
            setting: "{}",
            slug: slug,
            courseStreamId: current_stream_id,
            isInSession: true
          }

          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList
            }

            console.log(model);
            UtilService.encryptJsonValue(model).then(response => {
              console.log(response)
              if (response && response.data) {
                setGameLink("");
                //let tempGameLink = url_configs.game_server + response.data.token;
                //let tempGameLink = url_configs.game_server + autoPlayGameRouteName.routeName+'?token=' + response.data.token;
                //let tempGameLink = autoPlayGameRouteName.server + autoPlayGameRouteName.routeName + '/?token=' + response.data.token;
                let tempGameLink = autoPlayGameRouteName.route + response.data.token;
                setTimeout(() => {
                  setGameLink(tempGameLink);
                }, conditional_configs.loader.timeout);
              }
            })
              .catch(e => {
                console.log(e);
              });
            //let url = url_configs.game_server + '/robo-builder?token=5c9fda835e32bf69a930e99d027ebc1cdd44f92bcde76f84766f5268331d0f06eff73ff38c41adcb60555ce41ac893ec6457ba08c83bf9efc9690bc6ca062e2ffba33009347ee3e096defdf0af8b1de8a0011483ed8e78ccf04b6c9931e9423fa44bd898b6e1322fd289bae26e167ca75a8d5bc75214242f11a6f5bc24c836062eef7c4e874181c4f12744791649d213ec6039f7d82b3b2f9404836a1877373bcf505ec62cb25dda65dfdee2db3ab54323ea0940db0ddf906ac98ea029c78eb3727926a7361c00a2c25f58286d490e2a1925f7'
            //setGameLink(url);
          }
        }

      } else if (getCurrentUserData().role == conditional_configs.role.learner) {
        console.log('student-game-entered')
        let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

        if (current_stream_id) {

          console.log('student-game-entered_' + current_stream_id)
          console.log('student-game-entered_steam' + streamId)
          MemberService.getMemberCourseGroupByStreamAndLearnerId(current_stream_id, getCurrentUserData().id).then(
            (response) => {
              setCurrentGroupDetail(response.data);
              console.log('student-game-entered_', response.data);
              console.log(response.data);

              //Group Detail
              let groupDetail = {
                id: response.data.coursegroupid,
                name: response.data.groupname
              }

              //Learner Detail
              let learnerDetail = {
                id: getCurrentUserData().id,
                name: getCurrentUserData().name,
                groupDetail: groupDetail
              }

              //Game Detail
              let gameDetail = {
                id: 1,
                name: "Drill Game"
              }

              //Game Settings
              let gameSettings = {};
              GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id).then(response => {
                console.log(response)
                if (response && response.data && response.data.length > 0) {
                  // let gameSettings ={
                  //   data:JSON.parse(JSON.stringify(response.data[0].learnersettings[0].data))
                  // }
                  gameSettings = response.data[0].learnersettings[0];
                  console.log('Game Settings');
                  console.log(gameSettings);

                  let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

                  let tempJsonObj = {
                    learnerDetail: learnerDetail,
                    gameDetail: gameDetail,
                    userType: game_config.userType.student,
                    gameSettings: gameSettings,
                    slug: slug,
                    courseStreamId: current_stream_id,
                    isInSession: true
                  }

                  console.log('My Game Obj')
                  console.log(tempJsonObj);
                  if (tempJsonObj) {
                    let tempJsonValueList = [];
                    tempJsonValueList.push(tempJsonObj);

                    let model = {
                      jsonvalue: tempJsonValueList
                    }

                    console.log(model);
                    UtilService.encryptJsonValue(model).then(response => {
                      console.log(response)
                      if (response && response.data) {
                        let tempGmServer = localStorage.getItem('game-server');
                        setGameLink("");
                        //let tempGameLink = autoPlayGameRouteName.server + autoPlayGameRouteName.routeName + '/?token=' + response.data.token;
                        let tempGameLink = autoPlayGameRouteName.route + response.data.token;
                        //let tempGameLink = url_configs.game_server_drill_game + response.data.token;
                        setTimeout(() => {
                          setGameLink(tempGameLink);
                        }, conditional_configs.loader.timeout);
                      }
                    })
                      .catch(e => {
                        console.log(e);
                      });
                  }
                }
              })
                .catch(e => {
                  console.log(e);
                });
            }
          ).catch((e) => {
            console.log(e);
          });
        }
      } else {
        return;
      }
    }

    // setTimeout(() => {
    //   let node = document.getElementById('id_whiteboard')
    //   html2canvas(node).then(function (canvas) {
    //     console.log(canvas)
    //     //const image = canvas.toDataURL("image/png", 1.0);
    //     const image = canvas.toDataURL();
    //     setImageUrl(image)
    //   });
    // }, 5000);

  }

  //groups_dropdown_btn FUNCTION
  const [group, setGroup] = React.useState();

  const handle_group_Change = (event) => {
    console.log(event.target.value);
    setGroup(event.target.value);
  };

  //Team Popup Declaration
  const [open, setOpen] = React.useState(false);

  /** OPEN TEAM INFORMATION */
  const handleClickOpen = () => {
    getCurrentMemberGroupDetail();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onAddTaskSubmit = (data) => {
    console.log(data);
    //Add Task Model
    let addTaskModel = {
      coursestreamid: currentStreamId.toString(),
      memberid: getCurrentUserData().id.toString(),
      task: data.title,
      taskdescription: data.description,
      learners: []
    }
    //Filter Groups by Course Group Id
    let filterGroups = groups.filter(e => e.coursegroupid == group)[0];
    if (filterGroups && filterGroups.members) {
      filterGroups.members.forEach(lObj => {
        let learnerObj = {
          learnerid: "",
          assignedon: null,
          dueon: null
        }
        learnerObj.learnerid = lObj.id.toString();
        //Getting Learners Lis
        addTaskModel.learners.push(learnerObj);
      });
    }
    console.log(addTaskModel);
    MemberService.createTask(addTaskModel).then(
      (response) => {
        console.log("Task Added Successfully", response.data);
        refreshTasks(currentStreamId);
        setAdd_icon_open_popup(false);
      }
    ).catch((e) => {
      console.log(e);
    });
  };
  //validation end
  //popup
  const [add_icon_open_popup, setAdd_icon_open_popup] = React.useState(false);

  const AddIconClickOpen = () => {
    setAdd_icon_open_popup(true);
  };

  const AddIconClickClose = () => {
    setAdd_icon_open_popup(false);
  };
  //popup end
  // DRAG FUNCTION

  //#region TASK CARDS

  const getInitials = (data) => {
    let fn = data.firstname.substring(0, 1);
    let ln = data.lastname.substring(0, 1);
    return fn + ln;
  }

  /** DELETE TASK */
  const deleteTask = (id) => {
    MemberService.deleteTask(id).then(
      (response) => {
        console.log("Task Deleted Successfully", response.data);
        refreshTasks(currentStreamId);
      }
    ).catch((e) => {
      console.log(e);
    });
  }

  //#endregion

  const getGameLink = (gameData) => {
    if (getCurrentUserData().role == conditional_configs.role.learner) {
      console.log('student-game-entered')
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

      if (current_stream_id) {
        console.log('student-game-entered_' + current_stream_id)
        MemberService.getMemberCourseGroupByStreamAndLearnerId(current_stream_id, getCurrentUserData().id).then(
          (response) => {
            setCurrentGroupDetail(response.data);
            console.log('student-game-entered_', response.data);
            console.log(response.data);

            //Group Detail
            let groupDetail = {
              id: response.data.coursegroupid,
              name: response.data.groupname
            }

            //Learner Detail
            let learnerDetail = {
              id: getCurrentUserData().id,
              name: getCurrentUserData().name,
              groupDetail: groupDetail
            }

            //Game Detail
            let gameDetail = {
              id: gameData.id,
              name: gameData.name
            }

            //Game Settings
            let gameSettings = {};
            GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id).then(response => {
              console.log(response)
              if (response && response.data && response.data.length > 0) {
                gameSettings = response.data[0].learnersettings[0];
                console.log('Game Settings');
                console.log(gameSettings);

                let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

                let tempJsonObj = {
                  learnerDetail: learnerDetail,
                  gameDetail: gameDetail,
                  userType: game_config.userType.student,
                  gameSettings: gameSettings,
                  slug: slug,
                  courseStreamId: current_stream_id,
                  isInSession: true
                }

                console.log('My Game Obj')
                console.log(tempJsonObj);
                if (tempJsonObj) {
                  let tempJsonValueList = [];
                  tempJsonValueList.push(tempJsonObj);

                  let model = {
                    jsonvalue: tempJsonValueList
                  }

                  console.log(model);
                  UtilService.encryptJsonValue(model).then(response => {
                    console.log(response)
                    if (response && response.data) {
                      setGameLink("");
                      let tempGameLink = '';
                      //tempGameLink = gameData.server + gameData.routeName + '/?token=' + response.data.token;
                      tempGameLink = gameData.route + response.data.token;
                      localStorage.setItem('game-server', gameData.server);
                      //tempGameLink = url_configs.game_server + gameData.routeName + '?token=' + response.data.token;
                      setTimeout(() => {
                        setGameLink(tempGameLink);
                      }, conditional_configs.loader.timeout);
                    }
                  })
                    .catch(e => {
                      console.log(e);
                    });
                }
              } else {
                //gameSettings = response.data[0].learnersettings[0];
                console.log('Game Settings');
                console.log(gameSettings);

                let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

                let tempJsonObj = {
                  learnerDetail: learnerDetail,
                  gameDetail: gameDetail,
                  userType: game_config.userType.student,
                  gameSettings: gameSettings,
                  slug: slug,
                  courseStreamId: current_stream_id,
                  isInSession: true
                }
                console.log('My Game Obj')
                console.log(tempJsonObj);
                if (tempJsonObj) {
                  let tempJsonValueList = [];
                  tempJsonValueList.push(tempJsonObj);

                  let model = {
                    jsonvalue: tempJsonValueList
                  }

                  console.log(model);
                  UtilService.encryptJsonValue(model).then(response => {
                    console.log(response)
                    if (response && response.data) {
                      setGameLink("");
                      let tempGameLink = '';
                      //tempGameLink = gameData.server + gameData.routeName + '/?token=' + response.data.token;
                      tempGameLink = gameData.route + response.data.token;
                      localStorage.setItem('game-server', gameData.server);
                      //tempGameLink = url_configs.game_server + gameData.routeName + '?token=' + response.data.token;
                      setTimeout(() => {
                        setGameLink(tempGameLink);
                      }, conditional_configs.loader.timeout);
                    }
                  })
                    .catch(e => {
                      console.log(e);
                    });
                }
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        ).catch((e) => {
          console.log(e);
        });
      }
    }
  }

  const emitBreakoutRoom = () => {
    console.log('session-status-entered-task-assign');

    b_room_socket = io(url_configs.breakout_room_server);

    b_room_socket.emit("join", { name: getCurrentUserData().name, sessionSlug: slug, room: null, role: 0 }, (error) => {
      if (error) {
        console.log(error)
        //alert(error);
      }
    });

    b_room_socket.on("break-out-status", (obj) => {
      if (obj && obj.success == true && obj.status == 'STARTED') {
        setTimeout(() => {
          let current_learner_groupname = localStorage.getItem(storage_configs.localStorage.sessions.current_learner_groupname);

          if (current_learner_groupname) {
            b_room_socket.emit("join-breakout-session", { name: getCurrentUserData().name, sessionSlug: slug, room: current_learner_groupname, role: 0 }, (error) => {
              if (error) {
                console.log(error)
                //alert(error);
              }
            });

          }
        }, 5000);
      }
    });

    b_room_socket.on("auto-play-game", (obj) => {
      let tempGameObj = getGameDetailByGameId(obj.data.game.id);

      //setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        dispatch(setAutoPlayGameRouteName(tempGameObj));
        getGameLink(tempGameObj);
        let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
        if (tempScreenData) {
          tempScreenData.screentype = "startgame";
          setBreakoutCurrentScreenData(tempScreenData);
          localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempScreenData));
        }
      }, conditional_configs.loader.timeout);
    });

    if (getCurrentUserData().role === conditional_configs.role.learner) {
      b_room_socket.on("is-whiteboard-locked", (obj) => {
        console.log('is-whiteboard-locked-v2', obj);
        if (obj && obj.data) {
          //let data = [...whiteBoardTabList];
          //console.log("is-whiteboard-locked-v2 :: Pre :: Data",data);
          //setWhiteBoardTabList(tempData);
          let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
          let current_learner_groupname = localStorage.getItem(storage_configs.localStorage.sessions.current_learner_groupname);
          if (current_learner_groupname && current_learner_groupname.toLowerCase() == obj.group.toLowerCase()) {
            emitWhiteboard(tempScreenData, 'whiteboard', obj);
          }
        }
      });
    }

    b_room_socket.on("ask-screen-detail", (obj) => {
      console.log('ask-screen-detail', obj);
      if (obj && obj.data && obj.data.userDetail) {
        let splitDisplayName = obj.data.userDetail.displayName.split('-');
        let getLearnerId;
        if (splitDisplayName && splitDisplayName.length > 0) {
          getLearnerId = splitDisplayName[(splitDisplayName.length - 1)];
        }
        let currentZoomUserId = localStorage.getItem(storage_configs.localStorage.sessions.currentZoomUserId);
        if (getLearnerId == getCurrentUserData().id) {
          let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
          //let tempUrl = url_configs.white_board_server + getCurrentUserData().name + "&group=" + tempScreenData?.whiteboard_template?.name + "&slug=" + slug + "#room=" + tempScreenData?.whiteboard_template?.slug + ",-4-Gf1BCSf5ns_M4ChSamw";
          let cr = localStorage.getItem('current-board');
          let screenDetail = {
            screenData: currentWhiteboardDetail,
            url: cr,
            slug: slug,
            session: obj.data
          }
          b_room_socket.emit("on-b-room-know-users-reply", { data: screenDetail }, (error) => {
            if (error) {
              console.log(error);
            }
          });
        }
      }
    });

    
    onRefreshWhiteboards(b_room_socket);
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const selectGameToPlay = (obj) => {
    closeGamesMenu();
    plan_btn_close();
    //setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, conditional_configs.loader.timeout);
    let tempJsonObj;
    setSelectedTab('games');
    dispatch(setAutoPlayGameRouteName(obj));
    if (getCurrentUserData().role == conditional_configs.role.expert) {
      if (localStorage.getItem(storage_configs.localStorage.sessions.current_group_id)) {
        let groupIds = [];
        let gId = 0;
        gId = localStorage.getItem(storage_configs.localStorage.sessions.current_group_id);
        groupIds.push(gId);

        let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

        tempJsonObj = {
          userId: getCurrentUserData().id.toString(),
          userType: game_config.userType.teacher,
          name: getCurrentUserData().name,
          groupId: groupIds,
          setting: "{}",
          slug: slug,
          courseStreamId: current_stream_id,
          isInSession: true
        }
        if (tempJsonObj) {
          let tempJsonValueList = [];
          tempJsonValueList.push(tempJsonObj);

          let model = {
            jsonvalue: tempJsonValueList
          }

          console.log(model);
          UtilService.encryptJsonValue(model).then(response => {
            console.log(response)
            if (response && response.data) {
              setGameLink("");
              //let tempGameLink = url_configs.game_server + response.data.token;
              //let tempGameLink = url_configs.game_server + autoPlayGameRouteName.routeName+'?token=' + response.data.token;
              //let tempGameLink = autoPlayGameRouteName.server + autoPlayGameRouteName.routeName + '/?token=' + response.data.token;
              let tempGameLink = autoPlayGameRouteName.route + response.data.token;
              setTimeout(() => {
                setGameLink(tempGameLink);
              }, conditional_configs.loader.timeout);
            }
          })
            .catch(e => {
              console.log(e);
            });
          //let url = url_configs.game_server + '/robo-builder?token=5c9fda835e32bf69a930e99d027ebc1cdd44f92bcde76f84766f5268331d0f06eff73ff38c41adcb60555ce41ac893ec6457ba08c83bf9efc9690bc6ca062e2ffba33009347ee3e096defdf0af8b1de8a0011483ed8e78ccf04b6c9931e9423fa44bd898b6e1322fd289bae26e167ca75a8d5bc75214242f11a6f5bc24c836062eef7c4e874181c4f12744791649d213ec6039f7d82b3b2f9404836a1877373bcf505ec62cb25dda65dfdee2db3ab54323ea0940db0ddf906ac98ea029c78eb3727926a7361c00a2c25f58286d490e2a1925f7'
          //setGameLink(url);
        }
      }

    } else if (getCurrentUserData().role == conditional_configs.role.learner) {
      console.log('student-game-entered')
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

      if (current_stream_id || streamId) {

        console.log('student-game-entered_' + current_stream_id)
        console.log('student-game-entered_steam' + streamId)
        MemberService.getMemberCourseGroupByStreamAndLearnerId(current_stream_id ? current_stream_id : streamId, getCurrentUserData().id).then(
          (response) => {
            setCurrentGroupDetail(response.data);
            console.log('student-game-entered_', response.data);
            console.log(response.data);

            //Group Detail
            let groupDetail = {
              id: response.data.coursegroupid,
              name: response.data.groupname
            }

            //Learner Detail
            let learnerDetail = {
              id: getCurrentUserData().id,
              name: getCurrentUserData().name,
              groupDetail: groupDetail
            }

            //Game Detail
            let gameDetail = {
              id: obj.id,
              name: obj.name
            }

            //Game Settings
            let gameSettings = {};
            GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id).then(response => {
              console.log(response)
              if (response && response.data && response.data.length > 0) {
                // let gameSettings ={
                //   data:JSON.parse(JSON.stringify(response.data[0].learnersettings[0].data))
                // }
                gameSettings = response.data[0].learnersettings[0];
                console.log('Game Settings');
                console.log(gameSettings);

                let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

                let tempJsonObj = {
                  learnerDetail: learnerDetail,
                  gameDetail: gameDetail,
                  userType: game_config.userType.student,
                  gameSettings: gameSettings,
                  slug: slug,
                  courseStreamId: current_stream_id,
                  isInSession: true
                }

                console.log('My Game Obj')
                console.log(tempJsonObj);
                if (tempJsonObj) {
                  let tempJsonValueList = [];
                  tempJsonValueList.push(tempJsonObj);

                  let model = {
                    jsonvalue: tempJsonValueList
                  }

                  console.log(model);
                  UtilService.encryptJsonValue(model).then(response => {
                    console.log(response)
                    if (response && response.data) {
                      let tempGmServer = localStorage.getItem('game-server');
                      setGameLink("");
                      //let tempGameLink = obj.server + obj.routeName + '/?token=' + response.data.token;
                      let tempGameLink = obj.route + response.data.token;
                      setTimeout(() => {
                        setGameLink(tempGameLink);
                      }, conditional_configs.loader.timeout);
                    }
                  })
                    .catch(e => {
                      console.log(e);
                    });
                }
              } else {
                let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);

                let tempJsonObj = {
                  learnerDetail: learnerDetail,
                  gameDetail: gameDetail,
                  userType: game_config.userType.student,
                  gameSettings: {},
                  slug: slug,
                  courseStreamId: current_stream_id,
                  isInSession: true
                }
                console.log('My Game Obj')
                console.log(tempJsonObj);
                if (tempJsonObj) {
                  let tempJsonValueList = [];
                  tempJsonValueList.push(tempJsonObj);

                  let model = {
                    jsonvalue: tempJsonValueList
                  }

                  console.log(model);
                  UtilService.encryptJsonValue(model).then(response => {
                    console.log(response)
                    if (response && response.data) {
                      setGameLink("");
                      let tempGmServer = localStorage.getItem('game-server');
                      //let tempGameLink = obj.server + obj.routeName + '/?token=' + response.data.token;
                      let tempGameLink = obj.route + response.data.token;
                      setTimeout(() => {
                        setGameLink(tempGameLink);
                      }, conditional_configs.loader.timeout);
                    }
                  })
                    .catch(e => {
                      console.log(e);
                    });
                }
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        ).catch((e) => {
          console.log(e);
        });
      }
    } else {
      return;
    }
  }

  const getGameDetailByGameId = (id) => {
    let gameObj = gameList.filter(e => e.id == id)[0];
    console.log('getGameDetailByGameId', gameObj)
    return gameObj;
  }

  const emitWhiteboard = async (obj, tab, boardDetail) => {
    setSelectedTab(tab);
    dispatch(setAutoPlayGameRouteName(null));

    setWhiteBoardTabList([]);

    localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, tab);
    if (tab == 'whiteboard') {
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
      if (current_stream_id) {
        streamId = current_stream_id;
      }
      if (streamId) {
        MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
          (response) => {
            console.log('openSelectedWhiteboard-getMemberCourseGroupByStreamAndLearnerId', response);
            if (selectedTab == 'whiteboard') {
              localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, 'whiteboard');
              let url = '';
              let tempGroupName = "";
              if (getCurrentUserData().role == conditional_configs.role.expert) {
                url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
                tempGroupName = currentGroupName;
              } else {
                url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
                tempGroupName = response?.data?.groupname;
              }

              /** TEMPLATE BOARD URL */
              let tempData = [];
              if (obj && obj.boardData && obj.boardData.length > 0) {
                for (let i = 0; i < obj.boardData.length; i++) {
                  let tempUrl = url_configs.white_board_server + getCurrentUserData().name + "&group=" + obj?.boardData[i]?.name + "&slug=" + slug + "#room=" + obj.boardData[i].slug + '-div-' + obj.id+'-div-' + slug + '-div-' + slugify(tempGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw";
                  setWhiteboardLink(tempUrl);
                  localStorage.setItem('current-board', tempUrl);
                  let tempWhiteBoardTabContent = {
                    username: getCurrentUserData().name,
                    whiteboardLink: tempUrl,
                    tabName: obj.boardData[i]?.name,
                    slug: obj.boardData[i].slug + '-div-' + obj.id+'-div-' + slug + '-div-' + slugify(tempGroupName),
                    id: i,
                    type: "template",
                    active: false,
                    isLocked: false
                  }
                  tempData.push(tempWhiteBoardTabContent);
                }
              }

              // /** MULTIPLE WHITEBOARD TABS */
              // //setWhiteboardLink(url);
              let whiteBoardTabContent = null;
              whiteBoardTabContent = {
                username: getCurrentUserData().name,
                whiteboardLink: url,
                tabName: tempGroupName,
                slug: slug,
                id: tempData.length + 1,
                type: "master",
                active: false,
                isLocked: false
              }
              tempData.push(whiteBoardTabContent);
              tempData[0].active = true;
              // setWhiteboardLink(tempData[0].whiteboardLink);
              // setCurrentWhiteboardDetail(tempData[0]);
              setTimeout(() => {
                MemberService.getAllActiveByGroupId(response?.data?.coursegroupid).then(
                  (response) => {
                    console.log("Board Data", response.data);
                    for (let i = 0; i < response.data.length; i++) {
                      tempData.push(response.data[i]);
                    }
                    tempData.forEach(element => {
                      if (element.slug == boardDetail.data.slug) {
                        element.isLocked = boardDetail.data.isLocked;
                        element.active = true;
                        setWhiteboardLink(element.whiteboardLink);
                        localStorage.setItem('current-board', element.whiteboardLink);
                        setCurrentWhiteboardDetail(element);
                      } else {
                        element.active = false;
                      }
                    });

                    setWhiteBoardTabList(tempData);
                  }
                ).catch((e) => {
                  console.log(e);
                });
              }, 2000);
              //pushWhiteBoardTabContent(whiteBoardTabContent);
            }
            setCurrentGroupDetail(response.data);
          }
        ).catch((e) => {
          console.log(e);
        });
      }
    }
  }

  const openSelectedWhiteboard = async (obj, tab) => {
    setSelectedTab(tab);
    dispatch(setAutoPlayGameRouteName(null));

    setWhiteBoardTabList([]);

    localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, tab);
    if (tab == 'whiteboard') {
      let current_stream_id = localStorage.getItem(storage_configs.localStorage.sessions.current_stream_id);
      if (current_stream_id) {
        streamId = current_stream_id;
      }
      if (streamId) {
        MemberService.getMemberCourseGroupByStreamAndLearnerId(streamId, getCurrentUserData().id).then(
          (response) => {
            console.log('openSelectedWhiteboard-getMemberCourseGroupByStreamAndLearnerId', response);
            if (selectedTab == 'whiteboard') {
              localStorage.setItem(storage_configs.localStorage.sessions.group_space_selected_tab, 'whiteboard');
              let url = '';
              let tempGroupName = "";
              if (getCurrentUserData().role == conditional_configs.role.expert) {
                url = url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(currentGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw"
                tempGroupName = currentGroupName;
              } else {
                url = url_configs.white_board_server + getCurrentUserData().name + "&group=" + response?.data?.groupname + "&slug=" + slug + "#room=" + slugify(response?.data?.groupname) + ",-4-Gf1BCSf5ns_M4ChSamw"
                tempGroupName = response?.data?.groupname;
              }

              /** TEMPLATE BOARD */
              let tempData = [];
              if (obj && obj.boardData && obj.boardData.length > 0) {
                for (let i = 0; i < obj.boardData.length; i++) {
                  let tempUrl = url_configs.white_board_server + getCurrentUserData().name + "&group=" + obj?.boardData[i]?.name + "&slug=" + slug + "#room=" + obj.boardData[i].slug + '-div-' + obj.id+'-div-' + slug + '-div-' + slugify(tempGroupName) + ",-4-Gf1BCSf5ns_M4ChSamw";
                  setWhiteboardLink(tempUrl);
                  localStorage.setItem('current-board', tempUrl);
                  let tempWhiteBoardTabContent = {
                    username: getCurrentUserData().name,
                    whiteboardLink: tempUrl,
                    tabName: obj.boardData[i]?.name,
                    slug: obj.boardData[i].slug + '-div-' + obj.id+'-div-' + slug + '-div-' + slugify(tempGroupName),
                    id: i,
                    type: "template",
                    active: false,
                    isLocked: false
                  }
                  tempData.push(tempWhiteBoardTabContent);
                }
              } else {
                let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
                if (tempScreenData) {
                  let whiteBoardTabContent = null;
                  whiteBoardTabContent = {
                    username: getCurrentUserData().name,
                    whiteboardLink: url_configs.white_board_server + getCurrentUserData().name + "#room=" + slugify(tempScreenData.group_name) + '-div-' + slugify(tempScreenData.id) + '-div-' + response?.data?.coursegroupid + '-div-' + slug + '-div-'+ slugify(response?.data?.groupname)+",-4-Gf1BCSf5ns_M4ChSamw",
                    tabName: tempScreenData.group_name,
                    slug: slug,
                    id: tempData.length + 1,
                    type: "template",
                    active: false,
                    isLocked: false
                  }
                  tempData.push(whiteBoardTabContent);
                }
              }

              // /** MULTIPLE WHITEBOARD TABS */
              // //setWhiteboardLink(url);
              let whiteBoardTabContent = null;
              whiteBoardTabContent = {
                username: getCurrentUserData().name,
                whiteboardLink: url,
                tabName: tempGroupName,
                slug: slug,
                id: tempData.length + 1,
                type: "master",
                active: false,
                isLocked: false
              }
              tempData.push(whiteBoardTabContent);
              tempData[0].active = true;
              setWhiteboardLink(tempData[0].whiteboardLink);
              localStorage.setItem('current-board', tempData[0].whiteboardLink);
              setCurrentWhiteboardDetail(tempData[0]);
              setTimeout(() => {
                MemberService.getAllActiveByGroupId(response?.data?.coursegroupid).then(
                  (response) => {
                    console.log("Board Data", response.data);
                    for (let i = 0; i < response.data.length; i++) {
                      response.data[i].active = false;
                      tempData.push(response.data[i]);
                    }
                    setWhiteBoardTabList(tempData);
                  }
                ).catch((e) => {
                  console.log(e);
                });
              }, 2000);
            }
            setCurrentGroupDetail(response.data);
          }
        ).catch((e) => {
          console.log(e);
        });
      }
    }
  }

  //REFRESH WHITEBOARDS
  const refreshWhiteboards=(data)=>{
    b_room_socket.emit("on-refresh-boards", { data: data }, (error) => {
      if (error) {
        console.log(error);
      }
    });
  }

  const onRefreshWhiteboards=(b_room_socket)=>{
    b_room_socket.on("refresh-boards", (obj) => {
      console.log('refresh-boards', obj);
      if (obj && obj.data && obj.data.slug == slug && obj.data.groupId == getOpenedChatGroup().coursegroupid) {
        if(obj.data.isRemove == false){
        MemberService.getAllActiveByGroupId(obj.data.groupId).then(
          (response) => {
            console.log("Board Data", response.data);
            let tempData = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].active = false;
              // setWhiteBoardTabList(prevData => { 
              //   const newData = [...prevData, response.data[i]]
              // return newData;
              // });
              //setWhiteBoardTabList([...whiteBoardTabList, response.data[i]]);
            tempData.push(response.data[i]);
            }
            setWhiteBoardTabList(prevData => {
              // Create a new array by concatenating the existing and new items
              const newData = [...prevData, ...tempData];
              const removeDulicate = getUnique(newData, 'id');
        
              return removeDulicate;
            });
          }
        ).catch((e) => {
          console.log(e);
        });
      }else{
        setWhiteBoardTabList(prevData => {
          // Create a new array by concatenating the existing and new items
          const newData = prevData.filter(e=>e.id != obj.data.id);
          const removeDulicate = getUnique(newData, 'id');
    
          return removeDulicate;
        });
        }
      }
    });
  }

  return (
    <Page title="User" className="height-100">
      <div className="height-100">
        {/* {breakoutCurrentScreenData ? <Card className="title_navbar mb-2">
          <div className="d-flex  align-items-center" onClick={plan_btn_open}>
            <img src={planning_img} alt="" style={{ width: "25px" }} />
            {breakoutCurrentScreenData && breakoutCurrentScreenData?.selectedCurriculamType !== conditional_configs.curriculam_types.games && !autoPlayGameRouteName ?
              <span className="planning_text">
                {selectedTab == 'planning' ? 'Planning'
                  :
                  selectedTab == 'whiteboard' ? 'Whiteboard'
                    :
                    selectedTab == 'games' ? 'Games' :
                      ""}  <KeyboardArrowDownIcon />
              </span> :
              <span className="planning_text">
                Games: {breakoutCurrentScreenData?.name}
              </span>}
          </div>
        </Card> : ""} */}

        <Card className="height-100">
          {/* DRAG section */}
          {breakoutCurrentScreenData && breakoutCurrentScreenData?.selectedCurriculamType !== conditional_configs.curriculam_types.games && !autoPlayGameRouteName ?
            <div className="height-100">
              {selectedTab == 'planning' ?
                <Scrollbar className='height-100'>
                  <DragDropContext
                    className="drag_section"
                    onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                  >
                    <Container>
                      <TaskColumnStyles>
                        {Object.entries(columns).map(([columnId, column], index) => {
                          return (
                            <Droppable key={columnId} droppableId={columnId}>
                              {(provided, snapshot) => (
                                <TaskList
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <div className="d-flex align-items-center title_text_icon;">
                                    <Title className="">{column.name} </Title>
                                    {column.name == 'All' ?
                                      <AddCircleOutlineIcon
                                        className="ms-auto"
                                        onClick={AddIconClickOpen}
                                        style={{ cursor: "pointer" }}
                                      /> : ""}
                                    {/* <MoreVertIcon className="ms-auto" /> */}
                                  </div>

                                  {column?.taskList.map((item, index) => (
                                    <Draggable key={item.membercoursetasksid} draggableId={item.membercoursetasksid.toString()} index={index}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {/* card */}
                                          <TaskInformation>
                                            <p className='m-0'>{item.task}</p>
                                            <p className="mt-1 mb-1 task__description">{item.taskdescription}</p>

                                            <ul className="hor_lu_list m-0 p-0">
                                              {item.learners.map((lObj, lIndex) => {
                                                return (
                                                  <li>
                                                    <div className="m-1">
                                                      <Avatar>{getInitials(lObj)}</Avatar>
                                                    </div>
                                                  </li>
                                                )
                                              })}
                                              {/* <li>
                                     <div className="m-1">
                                       <Avatar>M</Avatar>
                                     </div>
                                   </li>
                                   <li>
                                     <div className="m-1">
                                       <Avatar>S</Avatar>
                                     </div>
                                   </li> */}
                                            </ul>
                                            <span className="task_delete_icon" onClick={(e) => deleteTask(item.membercoursetasksid)}>Delete</span>
                                            <div className="secondary-details">

                                              {/* <p className='m-0'>
                                     <span>
                                     <Avatar src={item.icon} alt="photoURL" />
                                     </span>
                                   </p> */}
                                            </div>
                                          </TaskInformation>
                                          {/* card end*/}
                                        </div>
                                      )}
                                    </Draggable>
                                    // <TaskCard key={item} item={item} index={index} />
                                  ))}

                                  {provided.placeholder}
                                </TaskList>
                              )}
                            </Droppable>
                          );
                        })}
                      </TaskColumnStyles>
                    </Container>
                  </DragDropContext>
                </Scrollbar>
                :
                selectedTab == 'whiteboard' && whiteboardLink ?
                  <div className='height-100 show-scroll'>
                    <div className='whiteboard-tabs-breakout-room-learner'>
                      {whiteBoardTabList.map((wTab, wIndex) => {
                        return (
                          <div className={`whiteboard-tab-container-breakout-room-learner ${wIndex == 0 ? 'ps-0':''}`}>
                            <div className={wTab.active ? 'whiteboard-active whiteboard-tab-button-breakout-room-learner' : 'whiteboard-in-active whiteboard-tab-button-breakout-room-learner'} onClick={(e) => openWhiteBoard(e, wTab, wIndex)}>
                              <div className='whiteboard-tab-name-breakout-room-learner'>
                                {wTab.tabName}
                              </div>
                              <div className='whiteboard-tab-controls'>
                                {wTab.isLocked == true ?
                                  <span onClick={(e) => toggleWhiteboardLock(e, wTab, wIndex)}><img src={lockWhiteboard_img}></img></span> :
                                  <span onClick={(e) => toggleWhiteboardLock(e, wTab, wIndex)}><img src={unlockWhiteboard_img}></img></span>}

                                {/* {isFluid !== true ?
                                  <span onClick={(e) => maximizeWhiteboardLock(e, wTab, wIndex)}><img src={maximizeWTab_img}></img></span>
                                  :
                                  <span onClick={(e) => miniimizeWhiteboardLock(e, wTab, wIndex)}><img src={minimizeWTab_img}></img></span>
                                } */}

                                {
                                  wTab.type != 'master' && wTab.type != 'template' && (getCurrentUserData().role !== conditional_configs.role.expert && wTab.userId === getCurrentUserData().id)? 
                                  <span onClick={(e) => removeWhiteboardTab(e, wIndex)}><img src={closeWTab_img}></img></span> : ""
                                }
                              </div>
                            </div>
                            {/* <div className='whiteboard-add-tab' onClick={(e)=>addNewWhiteboardTab(e)}>+</div> */}
                            {
                              (whiteBoardTabList.length - 1) == wIndex ? <div className='whiteboard-add-tab' onClick={(e) => addNewWhiteboardTab(e)}>+</div> : ""
                            }
                          </div>
                        );
                      })}
                    </div>
                    <div className='board-height'>
                      {getCurrentUserData().role == conditional_configs.role.expert ?
                        <div className='height-100'>
                          <iframe anonymous key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                        </div> :
                        <div className='height-100'>
                          {currentWhiteboardDetail && currentWhiteboardDetail.isLocked == true ?
                            <div className='height-100'>
                              <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                              </div>
                              <iframe anonymous key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                            </div>
                            :
                            <iframe anonymous key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                          }
                        </div>}

                    </div>

                    {/* {whiteBoardTabList.map((wTab, wIndex) => {
                      return (
                        <div>
                          {wTab.isLocked !== true ?
                            <iframe anonymous key={'id_whiteboard-' + wIndex} id={'id_whiteboard-' + wIndex} className="whiteboard-iframe-v2" src={wTab.whiteboardLink}></iframe>
                            :
                            <div>
                              {wTab.isLocked.toString()}
                              <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                              </div>
                              <iframe anonymous key={'id_whiteboard-' + whiteboardKey} id={'id_whiteboard-' + whiteboardKey} className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                            </div>
                          }
                        </div>
                      );
                    })} */}


                  </div>
                  // <Scrollbar>
                  //   <iframe anonymous id='id_whiteboard' className="whiteboard-iframe-v2" src={whiteboardLink}></iframe>
                  // </Scrollbar>
                  :
                  selectedTab == 'games' ?
                    <div className="height-100">{isLoading ? <LoadingSpinner message={error_messages.startingGameInLearner.replace('%GAMENAME%', autoPlayGameRouteName.name)} /> :
                      <div className="height-100">
                        <iframe className="whiteboard-iframe-v2" src={gameLink}></iframe>
                        {/* <iframe className="whiteboard-iframe-v2" src={(e)=>getGameLink(autoPlayGameRouteName)}></iframe> */}
                        {/* {getGameLink(autoPlayGameRouteName)} */}
                      </div>}
                    </div>
                    // <Scrollbar>
                    //   <iframe className="whiteboard-iframe-v2" src={gameLink}></iframe>
                    // </Scrollbar>
                    :
                    ""
              }
            </div> :
            breakoutCurrentScreenData && breakoutCurrentScreenData?.selectedCurriculamType == conditional_configs.curriculam_types.games && !autoPlayGameRouteName ?
              <LoadingSpinner message={error_messages.startingGameInLearner.replace('%GAMENAME%', breakoutCurrentScreenData?.name + '....')} /> :
              <div className="height-100">
                {breakoutCurrentScreenData && breakoutCurrentScreenData.screentype == 'gamesettings' ?
                  <LoadingSpinner message={error_messages.startingGameInLearner.replace('%GAMENAME%', autoPlayGameRouteName.name)} />
                  :
                  <div className="height-100">
                    <iframe className="whiteboard-iframe-v2" src={gameLink}></iframe>
                    {/* <iframe className="whiteboard-iframe-v2" src={(e)=>getGameLink(autoPlayGameRouteName)}></iframe> */}
                    {/* {getGameLink(autoPlayGameRouteName)} */}
                  </div>}
              </div>
          }
        </Card>

        {/* WHITEBOARD MENU ITEMS HEADER LIST START */}
        <Grid container justifyContent="center">
          <div>
            <Menu
              id="select_sessions_menu"
              MenuListProps={{
                "aria-labelledby": "select_session_menu",
              }}
              anchorEl={sessionListMenuItems}
              open={selectSessionMenu}
              onClose={closeSessionsMenu}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {currentSessionList.map((sObj, sIndex) => {
                return (
                  <MenuItem onClick={(e) => openSessionWhiteboardMenu(e, sObj)}>
                    {sObj.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Grid>

        {/* WHITEBOARDS UNDER SESSIONS */}
        <Grid container justifyContent="center">
          <div>
            <Menu
              id="select_sessions_whiteboads_menu"
              MenuListProps={{
                "aria-labelledby": "select_session_whiteboards_menu",
              }}
              anchorEl={sessionWhiteboardListMenuItems}
              open={selectSessionWhiteboardMenu}
              onClose={closeSessionWhiteboardMenu}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {currentSessionWhiteboardList.map((sObj, sIndex) => {
                return (
                  <MenuItem onClick={(e) => openSelectedWhiteboard(sObj, 'whiteboard')}>
                    {sObj.whiteboard_template.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Grid>
        {/* WHITEBOARD MENU ITEMS HEADER LIST END */}

        {/* PLANNING HEADER LIST START */}
        <Grid container justifyContent="center">
          <div>
            <Menu
              id="select_file_btn_menu"
              MenuListProps={{
                "aria-labelledby": "select_file_btn",
              }}
              anchorEl={plan_btn}
              open={open_select}
              onClose={plan_btn_close}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={(e) => openSessionsMenu(e, 'whiteboard')}>
                <img
                  src={whiteboard_img}
                  alt=""
                  style={{ width: "22px", marginRight: "15px" }}
                />{" "}
                Whiteboard
              </MenuItem>
              <MenuItem onClick={(e) => headerMenuChange('planning')}>
                <img
                  src={planning_img}
                  alt=""
                  style={{ width: "22px", marginRight: "15px" }}
                />{" "}
                Planning
              </MenuItem>
              <MenuItem onClick={handleClickOpen}>
                <img
                  src={team_img}
                  alt=""
                  style={{ width: "22px", marginRight: "15px" }}
                />{" "}
                Team
              </MenuItem>
              <MenuItem onClick={openGamesMenu}>
                <img
                  src={Assignment_img}
                  alt=""
                  style={{ width: "22px", marginRight: "15px" }}
                />{" "}
                Game
              </MenuItem>
              {/* <MenuItem onClick={(e) => headerMenuChange('games'), openGamesMenu}>
                <img
                  src={Assignment_img}
                  alt=""
                  style={{ width: "22px", marginRight: "15px" }}
                />{" "}
                Game
              </MenuItem> */}
            </Menu>
          </div>
        </Grid>
        {/* PLANNING HEADER LIST END */}

        {/* GAMES MENU ITEMS HEADER LIST START */}
        <Grid container justifyContent="center">
          <div>
            <Menu
              id="select_game_menu_menu"
              MenuListProps={{
                "aria-labelledby": "select_game_menu",
              }}
              anchorEl={gameListMenuItems}
              open={selectGameMenu}
              onClose={closeGamesMenu}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {gamesListConfig.gameList.map((gObj, gIndex) => {
                return (
                  <MenuItem onClick={(e) => selectGameToPlay(gObj)}>
                    {gObj.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Grid>
        {/* GAMES MENU ITEMS HEADER LIST END */}

        {/* ADD TASK POPUP START */}
        <div>
          <Dialog
            open={add_icon_open_popup}
            onClose={AddIconClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <p className="add_task_title m-0">{"Add Task"}</p>
              <span className="add_task_close_icon" onClick={AddIconClickClose}>
                <CloseIcon />
              </span>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                {/* TAKS TITLE FIELD */}
                <Grid item xs={12} sm={12}>
                  <p className="m-0">Title</p>
                  <TextField
                    required
                    id="title"
                    name="title"
                    label="Title"
                    fullWidth
                    margin="dense"
                    className="mt-0"
                    {...register("title")}
                    error={errors.title ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.title?.message}
                  </Typography>
                </Grid>
                {/* TASK DESCRIPTION FIELD */}
                <Grid item xs={12} sm={12}>
                  <p className="m-0">Description</p>
                  <TextareaAutosize
                    id="description"
                    name="description"
                    required
                    fullWidth
                    aria-label="minimum height"
                    minRows={3}
                    margin="dense"
                    {...register("description")}
                    error={errors.description ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.description?.message}
                  </Typography>
                </Grid>
                {/* SELECT GROUP */}
                <Grid item xs={12} sm={12} className="mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Groups
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={group}
                      label="Age"
                      onChange={handle_group_Change}
                    >
                      {groups.map((gObj, gIndex) => {
                        return (
                          <MenuItem value={gObj.coursegroupid}>{gObj.groupname}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                className="my_kids_save_btn mb-3"
                onClick={handleSubmit(onAddTaskSubmit)}
              >
                Add Task
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* ADD TASK POPUP END */}

        {/* TEAM POPUP START */}
        <div>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {/* <p className="team_wolf_title m-0">{"Team Wolf"}</p> */}
              <p className="team_wolf_title m-0">{currentGroupDetail?.groupname}</p>
              <span className="Team_close_icon" onClick={handleClose}>
                <CloseIcon />
              </span>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-lg-8">
                  <p className="m-0 our_mission_text">Our Mission</p>
                  <Box className="our_mission_text_box">
                    <p>{currentGroupDetail?.mission}</p>
                  </Box>
                </div>
                <div className="col-lg-4">
                  <p className="our_mission_text">Team Members</p>
                  {currentGroupDetail ?
                    <div>
                      {currentGroupDetail?.members?.map((mObj, mIndex) => {
                        return (
                          <div className="d-flex  align-items-center mt-1">
                            <Avatar src={account.photoURL} alt="photoURL" />
                            <span className="team_members_name">{mObj?.firstname}</span>
                          </div>
                        );
                      })}
                    </div> : ""
                  }


                  {/* <div className="d-flex  align-items-center">
                    <Avatar src={account.photoURL} alt="photoURL" />
                    <span className="team_members_name">Lori McDonald</span>
                  </div>
                  <div className="d-flex  align-items-center">
                    <Avatar src={account.photoURL} alt="photoURL" />
                    <span className="team_members_name">Loan Wood</span>
                  </div> */}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button className="my_kids_save_btn mb-3" onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* TEAM POPUP END */}
      </div>
    </Page>

  );

  function TabPanel(props) {
    const { children, tabId } = props;
    return (
      <Box
        value={maxTabIndex}
        index={maxTabIndex}
        hidden={tabId !== currentTablIndex}
        key={maxTabIndex}
      >
        {children}
      </Box>
    );
  }

};

export default BreakoutWhiteboard;
