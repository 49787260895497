import React, { useEffect, useState } from 'react';
import moment from 'moment'
// import { IdleTimeOutModal } from './IdelTimeOutModel';
import Swal from "sweetalert2";

//Config
import sessionNotHandlePages from '../config/session-not-handle-pages';
import roleConfig from '../config/conditional_config';

import AuthService from '../services/auth/auth.service';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserData } from './util';
import storage_configs from '../config/storage-config';

const IdleTimeOutHandler = (props) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)
    let timer = undefined;
    const events = ['click', 'load', 'keydown', 'mouseover','mousemove', 'mouseleave', 'focus', 'focusin', 'focusout']

    const eventHandler = (eventType) => {
        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', moment())
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };

    useEffect(() => {

        addEvents();

        return (() => {
            removeEvents();
            clearTimeout(timer);
        })
    }, [])

    const startTimer = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            let tempSessionNotHandlePages = sessionNotHandlePages;
            let location = window.location.pathname;

            let checkIsNotSessionPage = tempSessionNotHandlePages.filter(e => e == location);
            if (checkIsNotSessionPage && checkIsNotSessionPage.length == 0) {
                let lastInteractionTime = localStorage.getItem('lastInteractionTime')
                const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
                let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : roleConfig.idleTime;

                if (isLogout) {
                    clearTimeout(timer)
                } else {
                    if (diff._milliseconds < timeOutInterval) {
                        startTimer();
                        props.onActive();
                    } else {
                        props.onIdle();
                        // alert("Your session is Timed Out. Please login again");
                        // if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
                        //     navigate('/admin-login');
                        // } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
                        //     navigate('/expert-login');
                        // } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
                        //     navigate('/learner-login');
                        // } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
                        //     navigate('/parent-login');
                        // } else {
                        //     navigate('/admin-login');
                        // }
                        // AuthService.logout();
                        popup();
                        //setShowModal(true)
                    }
                }
            }
        }, props.timeOutInterval ? props.timeOutInterval : roleConfig.idleTime)
    }

    const addEvents = () => {
        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler)
        })
        startTimer();
    }

    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler)
        })
    };

    const handleContinueSession = () => {
        setShowModal(false)
        setLogout(false)
    }
    const handleLogout = () => {
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        props.onLogout();
        setShowModal(false)
        if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.admin) {
            navigate('/admin-login');
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.expert) {
            navigate('/expert-login');
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner) {
            navigate('/learner-login');
        } else if (getCurrentUserData() && getCurrentUserData().role == roleConfig.role.parent) {
            navigate('/parent-login');
        } else {
            // navigate('/admin-login');
            navigate('/');
        }
        AuthService.logout();
    }

    const popup=()=>{
        if(getCurrentUserData() && getCurrentUserData().role == roleConfig.role.learner){
            const breakoutStatus = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.break_out_session.status));
            if(breakoutStatus == true){
                // Swal.fire({
                //     title: "Due to screen inactivity, your session has ended.",
                //     icon: "warning",
                //     confirmButtonColor: "#3085d6",
                //     cancelButtonColor: "#d33",
                //     confirmButtonText: "Okay",
                //   }).then((result) => {
                //     if (result.isConfirmed) {
                //         handleLogout();
                //     }
                //   });
                commonPopup(true);
            }else{
                commonPopup(true);
                
            }
        }else{
            commonPopup(false);
        }
        
    }

const commonPopup=(isReload)=>{
        Swal.fire({
            title: "Session time out.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Stay",
            cancelButtonText:"Logout"
          }).then((result) => {
            if (result.isConfirmed) {
                handleContinueSession();
                if(isReload == true){
                    setTimeout(() => {
                        window.location.reload();
                    }, 200);
                }
            }else{
                handleLogout();
            }
          });
    }
    return (
        <div>

            {/* <IdleTimeOutModal
                showModal={showModal}
                handleContinue={handleContinueSession}
                handleLogout={handleLogout}
            /> */}

        </div>
    )

}

export default IdleTimeOutHandler;