import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Button,
  Avatar,
  Card,
  Grid,
  TextField,
  Typography,
  Select,
  InputLabel,
  FormControl,
  CardActionArea,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import account from "../../../_mock/account";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
//COMPONENTS
import NoRecordFound from "../../../components/no-record-found/no-record-found";

//ICONS
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Badge from "@mui/material/Badge";
//ICONS END

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Paper, { PaperProps } from "@mui/material/Paper";
//Dialog End

import Drawer from "@mui/material/Drawer";
import moment from "moment";

import { styled } from "@mui/material/styles";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import "./my-kids.css";

import ParentAPIService from "../../../services/parent-service";
import { ConnectedTvOutlined, WindowSharp } from "@mui/icons-material";
import {
  getCurrentUserData,
  getCharacterValidationError,
  getUnique,
} from "../../../utils/util";
import conditional_configs from "../../../config/conditional_config";
import assets from "../../../config/assets";
import error_messages from "../../../config/error_messages";
import DocumentService from "../../../services/document-service";
import { fi } from "date-fns/locale";

const drawerWidth = 340;

function kids_detail_list(
  courseName,
  sesionCompletionStatus,
  lastSessionDate,
  courseStatus
) {
  return { courseName, sesionCompletionStatus, lastSessionDate, courseStatus };
}
//kids table data
const kids_detail_row = [
  kids_detail_list("Chinemography", "5/5 COMPLETE", "Mar 12,2022", "ONGOING"),
  kids_detail_list(
    "Greek Mythology",
    "5/20 COMPLETE",
    "Mar 12,2022",
    "ONGOING"
  ),
  kids_detail_list("Greek Mythology", "5/8 COMPLETE", "Mar 12,2022", "ONGOING"),
];

export default function MyKids() {
  const navigate = useNavigate();
  const [showtab, setShowtab] = useState(1);
  const [ChangeButton, setChangeButton] = React.useState(true); // Enable and disbale save and update button on dialog box
  const [ChangeLabel, setChangeLabel] = React.useState(true); // to show add kid and update kid label in dialog box
  const [ChangeUsernameField, setChangeUsernameField] = React.useState("");
  const [OpenUpdate, setOpenUpdate] = React.useState(""); // to show user name while click add button it should be false when we click update button
  const [kidsCountInBadge, setkidsCountInBadge] = useState([0]); // To set badge data
  const [SelectCourseData, setSelectCourseData] = useState([]);
  const [courseTableData, setcourseTableData] = useState([]);

  const [MYKIDSLIST, setmyKidsList] = useState([]);
  const [activeKid, setActiveKid] = useState();
  const [EDITKIDSLIST, seteditKidsList] = useState([]);
  const [profileDocumentId, setProfileDocumentId] = React.useState("");

  const EditInitialKidData = [
    {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    },
  ];

  // Edit button state

  useEffect(() => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.parent
    ) {
      ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id)
        .then((response) => {
          setmyKidsList(response.data);
          setkidsCountInBadge(response.data.length);
          console.log("kiddata", response.data);
          if (response.data && response.data.length > 0) {
            setActiveKid(response.data[0]);
            getKidsTableData(response.data[0]);
          }
          ParentAPIService.getkidsCourseDetails(
            response.data[0].id,
            getCurrentUserData().id
          )
            .then((response) => {
              if (response && response.success === "true") {
                setcourseTableData(getUnique(response.data, "coursestreamid"));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate("/parent-login");
    }
  }, []);

  //KIDS ICON BUTTON FUNCTION
  const [my_kids_card_icon_btn, setMy_kids_card_icon_btn] =
    React.useState(null);
  const open_select = Boolean(my_kids_card_icon_btn);
  const card_icon_open = (event) => {
    console.log(event);
    setMy_kids_card_icon_btn(event.currentTarget);
  };
  const card_icon_close = () => {
    setMy_kids_card_icon_btn(null);
  };
  //KIDS ICON BUTTON FUNCTION END

  //popup
  const [open, setOpen] = React.useState(false);
  const [delopen, setDelOpen] = React.useState(false);

  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // File size exceeds 1MB, set error message
        setErrorMessage(
          "File size exceeds 1MB. Please upload a smaller image."
        );
        return;
      } else {
        DocumentService.upload_document(file)
          .then((response) => {
            console.log("File Uploaded Successfully");
            setProfileDocumentId(response.data.id);
            console.log("response.data.id", response.data.id);
          })
          .catch((e) => {
            console.log(e);
          });
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setErrorMessage(""); // Clear error message if image upload is successful
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setErrorMessage(""); // Clear error message when image is removed
  };

  //Add Kid Button
  const handleClickOpen = () => {
    // setChangeUsername(true);
    setOpen(true);
    setChangeLabel(true);
    setChangeButton(true);
    setChangeUsernameField(true);
    seteditKidsList(EditInitialKidData); //reset form value

    ParentAPIService.getCourseByParentId(getCurrentUserData().id)
      .then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
          var pods = getUnique(response.data, "coursestreamid");
          setSelectCourseData(pods);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateClose = () => {
    setOpenUpdate(false);
  };

  //popup

  //#region Course Drawer
  const [drawopen, setDrawopen] = React.useState(false);

  const handleDrawerOpen = (obj) => {
    setDrawopen(true);
    console.log(obj);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleDrawerClose = () => {
    setDrawopen(false);
  };
  //#endregion

  {
    /* ADD KID VALIDATION*/
  }
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    username: Yup.string().required("User name is required"),

    //birthday: Yup.string().required("Birthday is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  {
    /*  END OF ADD KID VALIDATION*/
  }

  {
    /* UPDATE KID VALIDATION*/
  }
  const validate = Yup.object().shape({
    //updatefirstname: Yup.string().required("First name is required"),
    //updatelastname: Yup.string().required("Last name is required"),
    //updatebirthday: Yup.string().required("Birthday is required"),
    //updateselectcourse: Yup.string().required("Select Course is required"),
  });
  const {
    register: registerUpdate,
    handleSubmit: handleUpdateSubmit,
    formState: { errors: errorUpdate },
  } = useForm({
    resolver: yupResolver(validate),
  });

  // Add Kid
  const onAddSubmit = (data) => {
    //  setChangeLabel(true);
    // setChangeButton(true);
    const parentid = getCurrentUserData().id;
    var addKidData = {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      email: getCurrentUserData().email,
      password: data.password,
      parentid: parentid.toString(),
      relationship: "2",
      coursestreamid: data.selectcourse,
      profile_documentid: profileDocumentId.toString(),
    };
    console.log(addKidData);
    ParentAPIService.addKid(addKidData)
      .then((response) => {
        if (response && response.success === "true") {
          console.log(response);
          // variant could be success, error, warning, info, or default
          enqueueSnackbar("Kids Added Sucessfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setDrawopen(false);
          window.location.reload();
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //while click edit button it on the kid card
  const editKidClick = () => {
    setOpenUpdate(true);
    ParentAPIService.getKidsByKidID(activeKid.id)
      .then((response) => {
        if (response && response.success === "true") {
          seteditKidsList([response.data]);
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Delete  Kid
  const deleteKidClick = () => {
    setDelOpen(true);
    // setKidid();
  };

  const deleteKidConfirm = () => {
    var deleteKidData = {
      firstname: "",
      lastname: "",
      password: "",
      country: "",
      timezone: "",
      createdby: 0,
    };
    ParentAPIService.deleteKid(activeKid.id, deleteKidData)
      .then((response) => {
        if (response && response.success === "true") {
          // variant could be success, error, warning, info, or default
          enqueueSnackbar("Deleted Sucessfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setDrawopen(false);
          window.location.reload();
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteKidCancel = () => {
    setDelOpen(false);
  };

  //Get KIds course Details
  const getKidsTableData = (obj) => {
    //window.location.reload();
    setActiveKid(obj);
    ParentAPIService.getkidsCourseDetails(obj.id, getCurrentUserData().id)
      .then((response) => {
        if (response && response.success === "true") {
          setcourseTableData(getUnique(response.data, "coursestreamid"));
          console.log("tabledata", response.data);
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Update Kid service
  const onUpdateSubmit = (data) => {
    // setChangeUsernameField(false);
    var updateKidData = {
      firstname: data.updatefirstname,
      lastname: data.updatelastname,
      birthday: data.updatebirthday,
      nickname: data.updatenickname,
      profile_documentid: 2,
    };
    ParentAPIService.updateKid(updateKidData, activeKid.id)
      .then((response) => {
        if (response && response.success === "true") {
          console.log("response in mykids", response.data);
          // variant could be success, error, warning, info, or default
          enqueueSnackbar("Kids data Updated Sucessfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setDrawopen(false);
          window.location.reload();
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#f07820",
      padding: "10px",
    },
  };

  const getCompletedSessions = (courseStreamDetail) => {
    let completedsessions = 0;
    let nextsessions = [];

    if (courseStreamDetail) {
      for (let i = 0; i < courseStreamDetail.length; i++) {
        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length > 0
        ) {
          if (courseStreamDetail[i].memberCourseStreamDetails[0].enddatetime) {
            completedsessions = completedsessions + 1;
          }
        }

        if (
          courseStreamDetail[i].memberCourseStreamDetails &&
          courseStreamDetail[i].memberCourseStreamDetails.length == 0
        ) {
          nextsessions.push(courseStreamDetail[i]);
        }
      }
    }
    return {
      completedsessions: completedsessions,
      nextsessions: nextsessions[0],
    };
  };

  const getLastSession = (courseStreamDetail) => {
    if (courseStreamDetail && courseStreamDetail.length > 0) {
      return courseStreamDetail[0].startdate;
    }
  };

  //GET STARTDATE BY COURSESTREAMDETAIL
  const getStartDateByStreamDetail = (obj) => {
    if (obj && obj.length > 0) {
      const startDate = obj[0].startdate;
      return moment(startDate).format("MMM D YYYY");
    }
  };

  //GET END DATE BY COURSESTREAMDETAIL
  const getEndDateByStreamDetail = (obj) => {
    if (obj && obj.length > 0) {
      const endDate = obj[obj.length - 1].startdate;
      return moment(endDate).format("MMM D YYYY");
    }
  };
  return (
    <div className="pt-lg-4 pt-md-4 pt-4 ps-lg-5 ps-2 pe-lg-5 pe-2">
      <div>
        <div open={open}>
          <Container>
            {/* My Kids section */}
            <Box sx={{ width: "100%" }}>
              <div class="d-flex align-items-center  mb-3">
                <span className="my_kids_text me-auto">
                  My Kids{" "}
                  <Badge
                    className="badge_mykids"
                    badgeContent={MYKIDSLIST.length}
                    sx={badgeStyle}
                  />
                </span>

                <Button
                  variant="contained"
                  className="my_kids_btn parent-kids-tour-3"
                  onClick={handleClickOpen}
                >
                  Add Kids
                </Button>
              </div>
            </Box>
            {/* My Kids section end*/}
            <div className="my-kids-list-myk parent-kids-tour-2">
              {/* kids card section */}
              {MYKIDSLIST.map((kids_datas, index) => {
                //Bind kids data into cards
                return (
                  <ul className="horizontal-list list-group-item">
                    <li key={`my-kids-list-` + index}>
                      <Card
                        className={`cursor-pointer ${
                          activeKid && activeKid.id == kids_datas.id
                            ? `active-my-kid`
                            : ``
                        }`}
                        id="my_kids_card"
                        onClick={() => getKidsTableData(kids_datas)}
                      >
                        <div className="d-flex align-items-center">
                          {kids_datas.path ? (
                            <Avatar src={kids_datas.path} alt="photoURL" />
                          ) : (
                            <Avatar src={account.photoURL} alt="photoURL" />
                          )}
                          <div className="kids_text_section_in_parent">
                            <div>
                              <span className="kids_name_in_parent">
                                {kids_datas.firstname}
                              </span>
                            </div>

                            {/*  <span className="kids_course">
                              {kids_datas.kids_course}
                            </span>*/}
                          </div>
                          {/* <div
                            className="my_kids_dot_icon"
                            onClick={card_icon_open}
                          >
                            <span><MoreVertIcon /></span>
                          </div> */}
                        </div>
                      </Card>
                    </li>
                  </ul>
                );
              })}
              {/* kids card section end */}

              {/* IconButton */}
              <Grid container justifyContent="center">
                <div>
                  <Menu
                    id="select_file_btn_menu"
                    MenuListProps={{
                      "aria-labelledby": "select_file_btn",
                    }}
                    anchorEl={my_kids_card_icon_btn}
                    open={open_select}
                    onClose={card_icon_close}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={() => editKidClick()}>
                      <ModeEditIcon style={{ marginRight: "10" }} /> Edit Kid
                      Info
                    </MenuItem>

                    <MenuItem onClick={() => deleteKidClick()}>
                      <DeleteIcon style={{ marginRight: "10" }} /> Delete Kid
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
              {/* IconButton */}
            </div>

            <div className="kid-detail-main">
              <div className="row">
                <div className="col-4 col-sm-3 col-md-4 col-lg-3">
                  <div className="kid-detail-label">First name</div>
                  <div className="kid-detail-value">{activeKid?.firstname}</div>
                </div>
                <div className="col-4 col-sm-3 col-md-4 col-lg-3">
                  <div className="kid-detail-label">Last name</div>
                  <div className="kid-detail-value">{activeKid?.lastname}</div>
                </div>
                <div className="col-4 col-sm-3 col-md-4 col-lg-3">
                  <div className="kid-detail-label">Username</div>
                  <div className="kid-detail-value">{activeKid?.username}</div>
                </div>
                <div className="col-4 col-sm-3 col-md-4 col-lg-3">
                  <div className="kid-detail-label">Birthday</div>
                  <div className="kid-detail-value">
                    {activeKid?.birthday ? (
                      activeKid?.birthday
                    ) : (
                      <span className="kid-detail-empty-value">-</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* KIDS TABLE */}
            {courseTableData && courseTableData.length > 0 ? (
              <div className="tab-content" id="pills-tabContent">
                {/* table one */}
                <div
                  className={
                    showtab === 1
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                >
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead className="payment_table_header">
                        <TableRow>
                          <TableCell className="payment_table_header_text col_1">
                            Course Name
                          </TableCell>
                          <TableCell
                            className="payment_table_header_text col_2"
                            align="left"
                          >
                            Session Completion Status
                          </TableCell>
                          <TableCell
                            className="payment_table_header_text col_3"
                            align="left"
                          >
                            Last Session Date
                          </TableCell>
                          <TableCell
                            className="payment_table_header_text col_4"
                            align="left"
                          >
                            Course Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {courseTableData.map((row) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* REMOVING CLICK EVENT TEMPORARLY */}
                            {/* <TableCell className="kids_name" onClick={(e)=>handleDrawerOpen(row)}
                            style={{ color: "blue" }}
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell> */}

                            <TableCell
                              className="kids_name"
                              style={{ color: "blue" }}
                              component="th"
                              scope="row"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="left">
                              {
                                getCompletedSessions(row.courseStreamDetail)
                                  ?.completedsessions
                              }
                              /{row.courseStreamDetail.length} Sessions
                              <LinearProgress
                                className="my_schedule_linear"
                                variant="determinate"
                                value={
                                  (getCompletedSessions(row.courseStreamDetail)
                                    ?.completedsessions /
                                    row.courseStreamDetail.length) *
                                  100
                                }
                                color="success"
                                sx={{
                                  height: "10px",
                                  borderRadius: 2,
                                  bgcolor: "#fffff",
                                }}
                              />
                            </TableCell>

                            <TableCell align="left" type="date">
                              {getCompletedSessions(row.courseStreamDetail)
                                ?.completedsessions == 0
                                ? `Yet to start`
                                : getLastSession(row.courseStreamDetail)}
                            </TableCell>

                            <TableCell
                              className="d-flex"
                              style={{ color: "green", alignItems: "center" }}
                              align="left"
                            >
                              <FiberManualRecordIcon
                                sx={{ fontSize: "15px" }}
                              />
                              <span>
                                {getCompletedSessions(row.courseStreamDetail)
                                  ?.completedsessions == row.noofsessions
                                  ? " Completed"
                                  : getCompletedSessions(row.courseStreamDetail)
                                      ?.completedsessions == 0
                                  ? " Yet to start"
                                  : " Ongoing"}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <NoRecordFound
                  noRecordFoundImage={assets.noRecordFound.expertCourses}
                  noRecordFoundText={
                    error_messages.noCourseAssignedToKidByParent
                  }
                />
              </div>
            )}
            {/* KIDS TABLE END */}
          </Container>
          {/* Dialog */}

          {/* Add kid dialogue box*/}
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Add Kid"}
                <span className="add_kids_close_icon" onClick={handleClose}>
                  <CloseIcon />
                </span>
              </DialogTitle>

              <DialogContent>
                <div className="row">
                  {/* FORM SECTION */}
                  <div className="col-sm-6">
                    <Grid container spacing={1}>
                      {/* FIRST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12} className="mt-2">
                        <TextField
                          required
                          id="firstname"
                          name="firstname"
                          label="First Name"
                          // defaultValue={kids_datas.firstname}
                          fullWidth
                          margin="dense"
                          {...register("firstname")}
                          error={errors.firstname ? true : false}
                          onChange={(e) => {
                            this.seteditKidsList({ firstname: e.target.value });
                          }} //It change the textbox value
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.firstname?.message}
                        </Typography>
                      </Grid>
                      {/* LAST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="lastname"
                          name="lastname"
                          label="Last Name"
                          //defaultValue={kids_datas.lastname}
                          fullWidth
                          margin="dense"
                          {...register("lastname")}
                          error={errors.lastname ? true : false}
                          onChange={(e) => {
                            this.seteditKidsList({ lastname: e.target.value });
                          }}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.lastname?.message}
                        </Typography>
                      </Grid>

                      {ChangeUsernameField && (
                        <>
                          {" "}
                          <Grid item xs={12} sm={12}>
                            <TextField
                              required
                              id="username"
                              name="username"
                              label="User Name"
                              //defaultValue={kids_datas.username}
                              fullWidth
                              margin="dense"
                              {...register("username")}
                              error={errors.username ? true : false}
                              onChange={(e) => {
                                this.seteditKidsList({
                                  username: e.target.value,
                                });
                              }}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.username?.message}
                            </Typography>
                          </Grid>
                          {/* EMAIL TEXT FIELD */}
                          {/* <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="email"
                            name="email"
                            label="Email"
                            fullWidth
                            margin="dense"
                            {...register("email")}
                            onChange={(e) => { this.seteditKidsList({ email: e.target.value }) }}
                          />
                        </Grid> */}
                        </>
                      )}

                      {/* BIRTHDAY TEXT FIELD */}
                      {/* <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="birthday"
                          name="birthday"
                          label="Birthday"
                          fullWidth
                          margin="dense"
                          {...register("birthday")}
                          error={errors.birthday ? true : false}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.birthday?.message}
                        </Typography>
                      </Grid> */}

                      {/* PASSWORD FIELD */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="password"
                          name="password"
                          label="Password(for kids login)"
                          type="password"
                          //defaultValue={kids_datas.password}
                          fullWidth
                          margin="dense"
                          {...register("password")}
                          error={errors.password ? true : false}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.password?.message}
                        </Typography>
                      </Grid>

                      {/* TIMEZONE FIELD */}
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth required margin="dense">
                          <InputLabel id="selectCourse-label">
                            Select Course
                          </InputLabel>
                          <Select
                            labelId="selectCourse-label"
                            required
                            id="selectcourse"
                            name="selectcourse"
                            label="Select Course"
                            {...register("selectcourse")}
                            // error={errors.selectcourse ? true : false}
                          >
                            {/* <MenuItem value={1}>Greek Mythology</MenuItem>
                            <MenuItem value={2}>Greek Mythology</MenuItem>
                            <MenuItem value={3}>Greek Mythology</MenuItem>*/}

                            {SelectCourseData.map((val) => (
                              <MenuItem value={val.coursestreamid}>
                                {val.coursename}&nbsp; (
                                {getStartDateByStreamDetail(
                                  val.courseStreamDetail
                                )}{" "}
                                -{" "}
                                {getEndDateByStreamDetail(
                                  val.courseStreamDetail
                                )}
                                )
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <Typography variant="inherit" color="textSecondary">
                          {errors.selectcourse?.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  {/* FORM SECTION END*/}

                  {/* IMAGE SECTION */}
                  <div className="col-sm-6">
                    <div className="upload_section">
                      <label htmlFor="image-upload" className="profile-image">
                        {image ? (
                          <img src={image} alt="Profile" />
                        ) : (
                          <PhotoCameraIcon sx={{ color: "white" }} />
                        )}
                      </label>
                      <div>
                        {image ? (
                          <p
                            className="remove-pic-up-kid m-0"
                            onClick={handleRemoveImage}
                          >
                            <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                          </p>
                        ) : (
                          <p className="add-pic-up-kid m-0">Upload Image</p>
                        )}
                      </div>
                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                      />
                      {errorMessage && (
                        <p className="error-meg-for-img-size">{errorMessage}</p>
                      )}
                    </div>
                  </div>
                  {/* IMAGE SECTION END */}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleSubmit(onAddSubmit)}
                  className="my_kids_save_btn mb-3"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/*  End of Add kid dialogue box*/}

          {/*Update Kid Dialog box*/}
          {EDITKIDSLIST.map((kids_datas, index) => {
            return (
              <div>
                <Dialog
                  open={OpenUpdate}
                  onClose={handleUpdateClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Update Kid"}
                    <span
                      className="add_kids_close_icon"
                      onClick={handleUpdateClose}
                    >
                      <CloseIcon />
                    </span>
                  </DialogTitle>
                  <DialogContent>
                    <div className="row">
                      {/* FORM SECTION */}
                      <div className="col-sm-6">
                        <Grid container spacing={1}>
                          {/* FIRST NAME TEXT FIELD */}
                          <Grid item xs={12} sm={12} className="mt-2">
                            <TextField
                              required
                              id="updatefirstname"
                              name="updatefirstname"
                              label="First Name"
                              defaultValue={kids_datas.firstname}
                              fullWidth
                              margin="dense"
                              {...registerUpdate("updatefirstname")}
                              //  error={errorUpdate.updatefirstname ? true : false}
                              onChange={(e) => {
                                this.seteditKidsList({
                                  firstname: e.target.value,
                                });
                              }} //It change the textbox value
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errorUpdate.updatefirstname?.message}
                            </Typography>
                          </Grid>
                          {/* LAST NAME TEXT FIELD */}
                          <Grid item xs={12} sm={12}>
                            <TextField
                              required
                              id="updatelastname"
                              name="updatelastname"
                              label="Last Name"
                              defaultValue={kids_datas.lastname}
                              fullWidth
                              margin="dense"
                              {...registerUpdate("updatelastname")}
                              // error={errorUpdate.updatelastname ? true : false}
                              onChange={(e) => {
                                this.seteditKidsList({
                                  updatelastname: e.target.value,
                                });
                              }}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errorUpdate.updatelastname?.message}
                            </Typography>
                          </Grid>

                          {/* NICK NAME TEXT FIELD */}
                          <Grid item xs={12} sm={12}>
                            <TextField
                              required
                              id="updatebirthday"
                              name="updatebirthday"
                              label="Birthday"
                              fullWidth
                              margin="dense"
                              {...registerUpdate("updatebirthday")}
                              //  error={errorUpdate.updatebirthday ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errorUpdate.updatebirthday?.message}
                            </Typography>
                          </Grid>

                          {/* NICK NAME TEXT FIELD */}
                          <Grid item xs={12} sm={12}>
                            <TextField
                              required
                              id="updatenickname"
                              name="updatenickname"
                              label="Nick name"
                              fullWidth
                              margin="dense"
                              {...registerUpdate("updatenickname")}
                              //error={errorUpdate.updatebirthday ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errorUpdate.updatebirthday?.message}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      {/* FORM SECTION END*/}

                      {/* IMAGE SECTION */}
                      <div className="col-sm-6">
                        <div className="upload_section">
                          <label
                            htmlFor="image-upload"
                            className="profile-image"
                          >
                            {image ? (
                              <img src={image} alt="Profile" />
                            ) : (
                              <PhotoCameraIcon sx={{ color: "white" }} />
                            )}
                          </label>
                          <div>
                            {image ? (
                              <p
                                className="remove-pic-up-kid m-0"
                                onClick={handleRemoveImage}
                              >
                                <DeleteIcon /> Remove
                              </p>
                            ) : (
                              <p className="add-pic-up-kid m-0">Upload Image</p>
                            )}
                          </div>
                          <input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                          />
                          {errorMessage && (
                            <p className="error-meg-for-img-size">
                              {errorMessage}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* IMAGE SECTION END */}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleUpdateSubmit(onUpdateSubmit)}
                      className="my_kids_save_btn mb-3"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            );
          })}

          {/*End of Update Kid Dialog box*/}

          {/*Delete confirmation Dialog*/}
          <div>
            <Dialog
              open={delopen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText>Really want to delete ?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={deleteKidConfirm}>
                  YES
                </Button>
                <Button onClick={deleteKidCancel}>NO</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>

        {/* Drawer section*/}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={drawopen}
        >
          <div className="drawer_section mt-2 mb-3 p-2">
            <div className="">
              {"Course Information"}
              <span className="add_kids_close_icon" onClick={handleDrawerClose}>
                <CloseIcon />
              </span>
            </div>
            <h5 className="cinemography_text">Cinemography</h5>
            <a href="" style={{ textDecoration: "none" }}>
              View Course info
            </a>
            <div className="d-flex align-items-center mt-3">
              <div>
                <label className="section_text_label">Section 1</label>
                <h6 className="section_date">September 14, 2022</h6>
              </div>
              <div className="ms-auto complete_text_cor_inf">
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
                />
                <span className="complete_text_cor_inf">COMPLETE</span>
              </div>
            </div>
            <hr></hr>

            <div className="d-flex align-items-center mt-3">
              <div>
                <label className="section_text_label">Section 2</label>
                <h6 className="section_date">September 15, 2022</h6>
              </div>
              <div className="ms-auto complete_text_cor_inf">
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
                />
                <span className="complete_text_cor_inf">COMPLETE</span>
              </div>
            </div>
            <hr></hr>

            <div className="d-flex align-items-center mt-3">
              <div>
                <label className="section_text_label">Section 3</label>
                <h6 className="section_date">September 16, 2022</h6>
              </div>
              <div className="ms-auto missed_text_cor_inf">
                <ErrorOutlineRoundedIcon
                  className="missed__cor_inf"
                  sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
                />

                <span className="missed_text_cor_inf">MISSED</span>
              </div>
            </div>
            <hr></hr>

            <div className="d-flex align-items-center mt-3">
              <div>
                <label className="section_text_label">Section 4</label>
                <h6 className="section_date">September 17, 2022</h6>
              </div>
              <div className="ms-auto complete_text_cor_inf">
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
                />
                <span className="complete_text_cor_inf">COMPLETE</span>
              </div>
            </div>
            <hr></hr>

            <div className="d-flex align-items-center mt-3">
              <div>
                <label className="section_text_label">Section 5</label>
                <h6 className="section_date">September 18, 2022</h6>
              </div>
              <div className="ms-auto complete_text_cor_inf">
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: 20, marginRight: "5px", marginBottom: "5px" }}
                />
                <span className="complete_text_cor_inf">COMPLETE</span>
              </div>
            </div>
            <hr></hr>
          </div>
        </Drawer>
        {/* Drawer section end*/}
      </div>
    </div>
  );
}
