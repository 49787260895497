import React, { Fragment, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ParentDataService from "../../../services/parent-service";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Avatar,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import account from "../../../_mock/account";
import "./register.css";

const ParentRegister = () => {
  // showtab function
  const [showtab, setShowtab] = useState(1);

  const handletabclick = (e) => {
    setShowtab(e);
  };
  // showtab function end

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    // best learning section
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),

    // best learning section end

    // kid info section
    kidName: Yup.string().required("Kid name is required"),
    userName: Yup.string().required("User name is required"),
    kidPassword: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    birthDay: Yup.string().required("Birthday is required"),
    conditionsagree: Yup.bool().oneOf([true], "Accept Terms is required"),
    // kid info section end
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // variant could be success, error, warning, info, or default
    console.log(JSON.stringify(data, null, 2));
    console.log(data);
    var userData = {
      name: data.name,
      email: data.email,
      password: data.password,

      kidName: data.kidName,
      userName: data.userName,
      kidPassword: data.kidPassword,
      birthDay: data.birthDay,

      conditionsagree: data.conditionsagree.toString(),
      createdby: "0",
    };
    console.log(userData);
    setIsSubmitting(true);
    // ParentDataService.register(userData)
    //   .then((response) => {
    //     setIsSubmitting(false);
    //     console.log(response);
    //     if (response && response.data && response.data.success === "true") {
    //       // variant could be success, error, warning, info, or default
    //       enqueueSnackbar("Parent registration is successful", {
    //         variant: "success",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //       navigate("/parent-login");
    //     } else if (
    //       response &&
    //       response.data &&
    //       response.data.success === "false"
    //     ) {
    //       enqueueSnackbar(response.data.data, {
    //         variant: "warning",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //     } else {
    //       enqueueSnackbar("Something went wrong. Please contact Admin.", {
    //         variant: "warning",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setIsSubmitting(false);
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       enqueueSnackbar(error.response.data.message, {
    //         variant: "error",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //       enqueueSnackbar("Something went wrong. Please contact Admin.", {
    //         variant: "error",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log("Error", error.message);
    //       enqueueSnackbar(error.message, {
    //         variant: "error",
    //         key: "topright",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //       });
    //     }
    //   });
  };

  return (
    <div>
      <div className="container">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className="parent_reg_section_box">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "100vh" }}
              >
                <div>
                  <Box className="small_collabor_box">
                    <Box className="small_inside_collabor_box">
                      <div className="card_collabor_circle"></div>
                    </Box>
                  </Box>
                </div>
                <p className="collabor_text">
                  Collaborative virtual Learning<br></br> For the millennials
                </p>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "100vh" }}
            >
              <div className="tab-content" id="pills-tabContent">
                {/* Form 1 */}
                <div
                  className={
                    showtab === 1
                      ? "tab-pane fade show active p-0"
                      : "tab-pane fade show p-0"
                  }
                >
                  <Paper>
                    <Box className="par_regi_form_session">
                      <p className="par_regi_meeku__text">Meeku</p>
                      <Typography variant="h6" align="center" margin="dense">
                        The best learning experience for kids!
                      </Typography>
                      {/* FORM */}
                      <Grid container spacing={1}>
                        {/* NAME TEXT FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            margin="dense"
                            {...register("name")}
                            error={errors.name ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.name?.message}
                          </Typography>
                        </Grid>

                        {/* EMAIL FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="email"
                            name="email"
                            label="Email ID"
                            fullWidth
                            margin="dense"
                            {...register("email")}
                            error={errors.email ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.email?.message}
                          </Typography>
                        </Grid>
                        {/* PASSWORD FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            margin="dense"
                            {...register("password")}
                            error={errors.password ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.password?.message}
                          </Typography>
                        </Grid>

                        {/* ACCEPT TERMS AND CONDITIONS */}
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                name="conditionsagree"
                                defaultValue="false"
                                inputRef={register()}
                                render={({ field: { onChange } }) => (
                                  <Checkbox
                                    color="primary"
                                    onChange={(e) => onChange(e.target.checked)}
                                  />
                                )}
                              />
                            }
                            label={
                              <Typography
                                color={
                                  errors.conditionsagree ? "error" : "inherit"
                                }
                              >
                                I agree to <a href="/">terms and conditions</a>
                              </Typography>
                            }
                          />
                          <br />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.conditionsagree
                              ? "(" + errors.conditionsagree.message + ")"
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box mt={3} textAlign="center">
                        {isSubmitting != true ? (
                          <div>
                            <Button
                              variant="contained"
                              className="parent_regi_next_btn"
                              onClick={handleSubmit(onSubmit)}
                            >
                              Next
                            </Button>
                          </div>
                        ) : (
                          <Button variant="contained" color="primary">
                            Next...
                          </Button>
                        )}

                        <p className="mt-4">
                          Already have account?{" "}
                          <a href="#" onClick={() => handletabclick(2)}>
                            Signin
                          </a>
                        </p>
                      </Box>
                    </Box>
                  </Paper>
                </div>
                 {/* Form 2 */}
                <div
                  className={
                    showtab === 2
                      ? "tab-pane fade show active p-0"
                      : "tab-pane fade show p-0"
                  }
                >
                  <Paper>
                    <Box className="par_regi_form_session">
                      <p className="par_regi_meeku__text">Meeku</p>
                      <Typography variant="h6" align="center" margin="dense">
                        Enter Kid Info
                      </Typography>
                      {/* FORM */}
                      <Grid container spacing={1}>
                        {/* kid NAME TEXT FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="kidName"
                            name="kidName"
                            label="kid Name"
                            fullWidth
                            margin="dense"
                            {...register("kidName")}
                            error={errors.kidName ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.kidName?.message}
                          </Typography>
                        </Grid>

                        {/* USER FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="userName"
                            name="userName"
                            label="Username"
                            fullWidth
                            margin="dense"
                            {...register("userName")}
                            error={errors.userName ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.userName?.message}
                          </Typography>
                        </Grid>

                        {/* PASSWORD FIELD */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="kidPassword"
                            name="kidPassword"
                            label="Password"
                            type="kidPassword"
                            fullWidth
                            margin="dense"
                            {...register("kidPassword")}
                            error={errors.kidPassword ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.kidPassword?.message}
                          </Typography>
                        </Grid>

                        {/* BIRTHDAY */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="birthDay"
                            name="birthDay"
                            label="Birthday"
                            fullWidth
                            margin="dense"
                            {...register("birthDay")}
                            error={errors.birthDay ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.birthDay?.message}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box mt={3} textAlign="center">
                        {isSubmitting != true ? (
                          <Button
                            variant="contained"
                            className="parent_regi_addkid_btn"
                            onClick={handleSubmit(onSubmit)}
                          >
                            Add kid
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary">
                            Next...
                          </Button>
                        )}

                        <p className="mt-3">
                          <a href="#" onClick={() => handletabclick(1)}>
                            Signup
                          </a>{" "}
                          &
                          <a href="#" onClick={() => handletabclick(3)}>
                            Add Later
                          </a>
                        </p>
                        <span>
                          By signing up you to agree to the terms and conditions
                        </span>
                      </Box>
                    </Box>
                  </Paper>
                </div>
                 {/* Form 3 */}
                <div
                  className={
                    showtab === 3
                      ? "tab-pane fade show active p-0"
                      : "tab-pane fade show p-0"
                  }
                >
                  <Paper>
                    <Box className="par_regi_form_session">
                      <p className="par_regi_meeku__text">Meeku</p>
                      <Typography variant="h6" align="center" margin="dense">
                        Enter Kid Info
                      </Typography>
                      <div>
                        <ul className="parent_courses_ul p-0">
                          <li>
                            <Card className="kid_info_card">
                              <div className="d-flex align-items-center">
                                <Avatar src={account.photoURL} alt="photoURL" />
                                <div className="_parent_kids_text_section">
                                  <div>
                                    <p className="parent_kids_name m-0">Arun</p>
                                  </div>

                                  <p className="kids_years m-0">10 years</p>
                                </div>
                                <div className="my_kids_dot_icon ms-auto">
                                  <span>
                                    <MoreVertIcon />
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </li>
                        </ul>
                      </div>

                      <Box mt={3} textAlign="center">
                        {isSubmitting != true ? (
                          <Button
                            variant="contained"
                            className="parent_kid_addkid_btn"
                          >
                            Add kid
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary">
                            Next...
                          </Button>
                        )}

                        <p className="mt-3">
                          <a href="#" onClick={() => handletabclick(1)}>
                            Complete
                          </a>{" "}
                          &
                          <a href="#" onClick={() => handletabclick(3)}>
                            Signup
                          </a>
                        </p>
                        <span>
                          By signing up you to agree to the terms and conditions
                        </span>
                      </Box>
                    </Box>
                  </Paper>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ParentRegister;
