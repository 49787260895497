const tooltip_messages = {
    completedSessionsGroupSpace: 'Session has already completed. Click here to view workspace.',
    notCompletedSessionsGroupSpace:'Access to the group space will be available once the expert completes the session.',
    chatGroupSpace: 'Click here to chat within same session.',
    startSession: 'Click here to start the session.',
    sessionCompleted: 'Session Completed.',
    upcomingSession: 'Upcoming Session.',
    back: 'Back',
    whiteboard: 'Whiteboard',
    games: 'Games',
    quiz: 'Quiz',
    results: 'Results',
    openGroupSpace: 'Click here to open Group Space',
    viewCourseDetail: 'Click here to see course information',
    joinSession: 'Click here to join session',
    close: 'Close',
    expert:{
        openPods: 'Click here to view pods',
        pods: {
            completedSessions: 'This session has been completed',
            startSession: 'Click here to start session'
        },
        openCurriculum: 'Click here to view curriculum',
    },
    registerParent:{
        editSchedule: 'Change schedule'
    }

};
    
export default tooltip_messages;