import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Iconify from "../../../components/Iconify";

import { Box, IconButton, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Book_icon_img from "../../../assets/img/courses_img/book_icon_img.png";
import Whiteboard_Icon from "../../../assets/img/white-board/whiteboard-24.png";
import roboBuilder from '../../../assets/img/games/robo_builder.jpg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import off_clock_img from "../../../assets/img/courses_img/off_clock_img.png";
import short_video_img from "../../../assets/img/courses_img/short_video_img.png";

import CourseView from "../../../components/classroom/course-view/course-view";
import "./curriculum.css";

import WhiteboardTopics from "../../../components/classroom/whiteboard-topics/whiteboard-topics";

import { getCurrentUserData, getUnique, checkIsEmdedCode } from "../../../utils/util";
import { setSelectedContent, setSelectedCurriculamType } from '../../../store/slices/settingsSlice';
//CONFIGS
import url_configs from "../../../config/url";
import conditional_configs from "../../../config/conditional_config";
import whiteboardConfig from "../../../config/whiteboard-config";
import icons_config from "../../../config/icons";


//SERVICES
import ExpertService from "../../../services/expert-service";
import AdminService from "../../../services/admin-service";
import assets from "../../../config/assets";
import navigate_route_config from "../../../config/navigate_route_config";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


export default function Curriculum() {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  /** DECLARATIONS */
  const navigate = useNavigate();
  const [curriculumTemplateList, setCurriculumTemplateList] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState();
  const [curriculamType, setCurriculamType] = useState("");
  const [whiteboardTemplateList, setWhiteboardTemplateList] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  //GET STREAM ID FROM LINK
  let { streamId } = useParams();

  const dispatch = useDispatch();

  //GET CURRICULUM DATA WHILE PAGE LOAD
  useEffect(() => {
    getAllWhiteboardTemplate();
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      ExpertService.GetCurriculum(streamId)
        .then((response) => {
          localStorage.removeItem('temp-expert-content-quiz');
          setCurriculumTemplateList(response.data);
          var getQuiz = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < response.data[i].coursecontentsessionquiz.length; j++) {
              getQuiz.push(response.data[i].coursecontentsessionquiz[j]);
            }
          }
          setTimeout(() => {
            localStorage.setItem('temp-expert-content-quiz', JSON.stringify(getQuiz));
            handleCurriculumContent(response.data[0]?.coursecontentsessiondetail[0], conditional_configs.curriculam_types.content);
          }, 500);
          setExpanded(!expanded ? 'e-curriculum-templates-0' : false);

          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      localStorage.clear();
      navigate('/expert-login');
    }
  }, []);
  //api end

  //click function
  const handleCurriculumContent = (data, type) => {
    setCurriculamType(type)
    if (type == conditional_configs.curriculam_types.content) {
      if (data.curriculumlink) {
        const tempCurriculum = {
          data: data,
          type: type,
          isEmbedCode: checkIsEmdedCode(data.curriculumlink),
          curriculum: data.curriculumlink
        }
        setSelectedCurriculum(tempCurriculum);
      } else {
        const tempCurriculum = {
          data: data,
          type: type,
          isEmbedCode: checkIsEmdedCode(data.document.documenturl),
          curriculum: data.document.documenturl
        }
        setSelectedCurriculum(tempCurriculum);
      }
    } else if (type == conditional_configs.curriculam_types.quiz) {
      var tempCurriculum = {
        data: data,
        type: type,
        isEmbedCode: false,
        curriculum: []
      }
      if (data) {
        const getQuiz = JSON.parse(localStorage.getItem('temp-expert-content-quiz'));
        if (getQuiz && getQuiz.length > 0) {
          tempCurriculum.curriculum = getQuiz.filter(e => e.templateid == data.templateid)
        }
      }
      setSelectedCurriculum(tempCurriculum);
    } else if (type == conditional_configs.curriculam_types.games) {
      var tempCurriculum = {
        data: data,
        type: type,
        isEmbedCode: false,
        curriculum: []
      }
      setSelectedCurriculum(tempCurriculum);
    }
  };

  //OPEN WHITEBOARD
  const openWhiteboardLink = (obj, e) => {
    setCurriculamType('content')
    dispatch(setSelectedContent(" "));
    if (obj && obj.template_ids) {
      var tempCurriculum = {
        data: obj,
        type: conditional_configs.curriculam_types.whiteboard,
        isEmbedCode: false,
        curriculum: []
      }
      setSelectedCurriculum(tempCurriculum);
      setTimeout(() => {
        let boardData = showBoardsList(obj.template_ids, obj);
        //let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + obj.whiteboard_template.slug + ',-4-Gf1BCSf5ns_M4ChSamw'
        //localStorage.setItem(storage_configs.localStorage.sessions.show_content, tempWLink)
        boardData[0].active = true;
        setCurriculamType('whiteboard')
        dispatch(setSelectedContent(boardData));
        dispatch(setSelectedCurriculamType(conditional_configs.curriculam_types.whiteboard));
      }, 1000);
    }

    // if (obj && obj.whiteboard_template) {
    //   setCurriculamType('whiteboard')
    //   let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + obj.whiteboard_template.slug + ',-4-Gf1BCSf5ns_M4ChSamw'
    //   setSelectedCurriculum(tempWLink);
    // }
  }

  const getAllWhiteboardTemplate = () => {
    AdminService.getAllActiveWhiteboardTemplates().then(response => {
      console.log(response.data);
      setWhiteboardTemplateList(response.data);
    })
      .catch(e => {
        console.log(e);
      });
  }

  const showBoardsList = (template_ids, obj) => {
    let boardsList = [];
    if (template_ids) {
      let splitBoardIds = template_ids.split(',');
      if (splitBoardIds && splitBoardIds.length > 0) {
        for (let i = 0; i < splitBoardIds.length; i++) {
          let boardObj = {};
          let actualBoardList = whiteboardTemplateList;
          if (actualBoardList && actualBoardList.length > 0) {
            let newWhiteboardArray = [];
            //boardObj = actualBoardList.filter(e => e.id == splitBoardIds[i])[0];
            actualBoardList.filter(e => e.id == splitBoardIds[i]).forEach((device, index) => {
              newWhiteboardArray[index] = { ...device }
            })
            boardObj = newWhiteboardArray[0];
            // if (boardObj) {
            //   FirebaseService.cloneDocument("scenes", boardObj.slug, boardObj.slug + '-div-' + slug);
            // }
            let tempWLink = url_configs.white_board_server + getCurrentUserData().name + "#room=" + boardObj.slug + whiteboardConfig.key;
            boardObj.whiteboardLink = tempWLink;
            boardObj.is_locked = obj.is_locked;
            boardsList.push(boardObj);
          }
        }
      }
    }
    console.log('SHOW BOARD LIST ', boardsList)
    return boardsList;
  }

  const onClickWhiteboard = () => {
    alert("Whiteboard is locked.")
  }

  const handleAccordionChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  //click function end

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="e-curriculum-main">
      <div className="e-curriculum-breadscrum-container">
        <Stack className="align-items-center p-3" direction="row">
          <div className="mobile-toggle-section-div ps-3 me-2">
            <MenuIcon className="cursor-pointer" onClick={toggleDrawer}></MenuIcon>
            {/* <button className="mobile-toggle-section-btn" onClick={toggleDrawer}>Sessions</button> */}
          </div>

          <Typography className="e-curriculum-bs-text cursor-pointer">
            {getIcon(icons_config.home)}
          </Typography>
          <Typography className="e-curriculum-bs-text">
            <KeyboardArrowRightIcon />
          </Typography>
          <Typography className="e-curriculum-bs-text cursor-pointer" onClick={(e) => navigate(navigate_route_config.expert.my_courses)}>
            My Courses
          </Typography>
          <Typography className="e-curriculum-bs-text">
            <KeyboardArrowRightIcon />
          </Typography>
          <Typography className="e-curriculum-bs-text">
            Curriculum
          </Typography>
        </Stack>
      </div>

      {/* mobile view toggle */}
      <div className={`mobile-drawer ${isOpen ? 'open' : ''}`}>
        <div className="drawer-content">
          <div className="d-flex justify-content-end">
            <CloseIcon onClick={toggleDrawer}></CloseIcon>
            {/* <button onClick={toggleDrawer}>Close Drawer</button> */}
          </div>

          <div className="e-curriculum-left-menu-mobile">
            {/* CURRICULUM LIST */}
            {curriculumTemplateList.map(
              (curriculumTemplateObj, curriculumTemplateIndex) => {
                return (
                  <Accordion key={`e-curriculum-templates-` + curriculumTemplateIndex} expanded={expanded === `e-curriculum-templates-` + curriculumTemplateIndex} onChange={handleAccordionChange(`e-curriculum-templates-` + curriculumTemplateIndex)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="accordion_sess_title m-0">
                        {curriculumTemplateObj.name}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className="session_list_ul p-0">
                        {/* Curriculum Content */}
                        {curriculumTemplateObj?.coursecontentsessiondetail ? (
                          <div>
                            {curriculumTemplateObj?.coursecontentsessiondetail.map(
                              (curriculumContent, curriculumContentIndex) => {
                                return (
                                  <li key={`e-curriculum-content-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                    className={selectedCurriculum?.type == conditional_configs.curriculam_types.content && selectedCurriculum?.data?.id == curriculumContent.id ? `active-course session_list_li` : `session_list_li`}
                                    onClick={(e) =>
                                      handleCurriculumContent(
                                        curriculumContent, conditional_configs.curriculam_types.content
                                      )
                                    }
                                  >
                                    <div className="session_ilst_item d-flex align-items-center">
                                      <div>
                                        <img
                                          src={Book_icon_img}
                                          alt=""
                                          className="me-3"
                                        />
                                      </div>
                                      <div className="session_list_li_text">
                                        {curriculumContent.subject}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* Curriculum Content Quiz */}
                        {curriculumTemplateObj?.coursecontentsessionquiz ? (
                          <div>
                            {getUnique(curriculumTemplateObj?.coursecontentsessionquiz, 'templateid')?.map(
                              (
                                curriculumContentQuiz,
                                curriculumContentIndex
                              ) => {
                                return (
                                  <li key={`e-curriculum-quiz-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                    className={selectedCurriculum?.type == conditional_configs.curriculam_types.quiz && selectedCurriculum?.data?.id == curriculumContentQuiz.id ? `active-course session_list_li` : `session_list_li`}
                                    onClick={(e) =>
                                      handleCurriculumContent(
                                        curriculumContentQuiz, conditional_configs.curriculam_types.quiz
                                      )
                                    }
                                  >
                                    <div className="session_ilst_item d-flex align-items-center">
                                      <div>
                                        <img
                                          src={assets.quiz}
                                          alt=""
                                          className="me-3"
                                        />
                                      </div>
                                      <div className="session_list_li_text">
                                        {
                                          curriculumContentQuiz?.template?.name
                                        }
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Curriculum Content Whiteboard */}
                        {curriculumTemplateObj?.coursesession_whiteboards ? (
                          <div>
                            {curriculumTemplateObj?.coursesession_whiteboards.map(
                              (wObj, curriculumContentIndex) => {
                                return (
                                  <li key={`e-curriculum-boards-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                    className={selectedCurriculum?.type == conditional_configs.curriculam_types.whiteboard && selectedCurriculum?.data?.id == wObj.id ? `active-course session_list_li` : `session_list_li`}

                                    onClick={(e) => openWhiteboardLink(wObj, e)}
                                  >
                                    <div className="session_ilst_item d-flex align-items-center">
                                      <div>
                                        <img
                                          src={Whiteboard_Icon}
                                          alt=""
                                          className="me-3"
                                        />
                                      </div>
                                      <div className="session_list_li_text">
                                        {wObj?.group_name}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </div>
                        ) : ""}

                        {/* Curriculum Games */}
                        {curriculumTemplateObj?.coursesession_games ? (
                          <div>
                            {curriculumTemplateObj?.coursesession_games.map(
                              (wObj, curriculumContentIndex) => {
                                return (
                                  <li key={`e-curriculum-games-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                    className={selectedCurriculum?.type == conditional_configs.curriculam_types.games && selectedCurriculum?.data?.id == wObj.id ? `active-course session_list_li` : `session_list_li`}

                                    onClick={(e) => handleCurriculumContent(
                                      wObj, conditional_configs.curriculam_types.games
                                    )}
                                  >
                                    <div className="session_ilst_item d-flex align-items-center">
                                      <div>
                                        <img
                                          src={roboBuilder}
                                          alt={wObj?.name}
                                          className="me-3 e-curriculum-icon"
                                        />
                                      </div>
                                      <div className="session_list_li_text">
                                        {wObj?.name}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </div>
                        ) : ""}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
          </div>
        </div>
      </div>


      <div className="e-curriculum-container">
        <div className="e-curriculum-left-menu">
          {/* CURRICULUM LIST */}
          {curriculumTemplateList.map(
            (curriculumTemplateObj, curriculumTemplateIndex) => {
              return (
                <Accordion key={`e-curriculum-templates-` + curriculumTemplateIndex} expanded={expanded === `e-curriculum-templates-` + curriculumTemplateIndex} onChange={handleAccordionChange(`e-curriculum-templates-` + curriculumTemplateIndex)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="accordion_sess_title m-0">
                      {curriculumTemplateObj.name}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="session_list_ul p-0">
                      {/* Curriculum Content */}
                      {curriculumTemplateObj?.coursecontentsessiondetail ? (
                        <div>
                          {curriculumTemplateObj?.coursecontentsessiondetail.map(
                            (curriculumContent, curriculumContentIndex) => {
                              return (
                                <li key={`e-curriculum-content-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                  className={selectedCurriculum?.type == conditional_configs.curriculam_types.content && selectedCurriculum?.data?.id == curriculumContent.id ? `active-course session_list_li` : `session_list_li`}
                                  onClick={(e) =>
                                    handleCurriculumContent(
                                      curriculumContent, conditional_configs.curriculam_types.content
                                    )
                                  }
                                >
                                  <div className="session_ilst_item d-flex align-items-center">
                                    <div>
                                      <img
                                        src={Book_icon_img}
                                        alt=""
                                        className="me-3"
                                      />
                                    </div>
                                    <div className="session_list_li_text">
                                      {curriculumContent.subject}
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Curriculum Content Quiz */}
                      {curriculumTemplateObj?.coursecontentsessionquiz ? (
                        <div>
                          {getUnique(curriculumTemplateObj?.coursecontentsessionquiz, 'templateid')?.map(
                            (
                              curriculumContentQuiz,
                              curriculumContentIndex
                            ) => {
                              return (
                                <li key={`e-curriculum-quiz-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                  className={selectedCurriculum?.type == conditional_configs.curriculam_types.quiz && selectedCurriculum?.data?.id == curriculumContentQuiz.id ? `active-course session_list_li` : `session_list_li`}
                                  onClick={(e) =>
                                    handleCurriculumContent(
                                      curriculumContentQuiz, conditional_configs.curriculam_types.quiz
                                    )
                                  }
                                >
                                  <div className="session_ilst_item d-flex align-items-center">
                                    <div>
                                      <img
                                        src={assets.quiz}
                                        alt=""
                                        className="me-3"
                                      />
                                    </div>
                                    <div className="session_list_li_text">
                                      {
                                        curriculumContentQuiz?.template?.name
                                      }
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Curriculum Content Whiteboard */}
                      {curriculumTemplateObj?.coursesession_whiteboards ? (
                        <div>
                          {curriculumTemplateObj?.coursesession_whiteboards.map(
                            (wObj, curriculumContentIndex) => {
                              return (
                                <li key={`e-curriculum-boards-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                  className={selectedCurriculum?.type == conditional_configs.curriculam_types.whiteboard && selectedCurriculum?.data?.id == wObj.id ? `active-course session_list_li` : `session_list_li`}

                                  onClick={(e) => openWhiteboardLink(wObj, e)}
                                >
                                  <div className="session_ilst_item d-flex align-items-center">
                                    <div>
                                      <img
                                        src={Whiteboard_Icon}
                                        alt=""
                                        className="me-3"
                                      />
                                    </div>
                                    <div className="session_list_li_text">
                                      {wObj?.group_name}
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </div>
                      ) : ""}

                      {/* Curriculum Games */}
                      {curriculumTemplateObj?.coursesession_games ? (
                        <div>
                          {curriculumTemplateObj?.coursesession_games.map(
                            (wObj, curriculumContentIndex) => {
                              return (
                                <li key={`e-curriculum-games-` + curriculumTemplateIndex + `-` + curriculumContentIndex}
                                  className={selectedCurriculum?.type == conditional_configs.curriculam_types.games && selectedCurriculum?.data?.id == wObj.id ? `active-course session_list_li` : `session_list_li`}

                                  onClick={(e) => handleCurriculumContent(
                                    wObj, conditional_configs.curriculam_types.games
                                  )}
                                >
                                  <div className="session_ilst_item d-flex align-items-center">
                                    <div>
                                      <img
                                        src={roboBuilder}
                                        alt={wObj?.name}
                                        className="me-3 e-curriculum-icon"
                                      />
                                    </div>
                                    <div className="session_list_li_text">
                                      {wObj?.name}
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </div>
                      ) : ""}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </div>
        <div className="e-curriculum-right-content">
          {curriculamType == conditional_configs.curriculam_types.content ?
            <div>
              {selectedCurriculum && selectedCurriculum.isEmbedCode ?
                <div id="e-curriculum-content-embed" className="embed-code-style" dangerouslySetInnerHTML={{ __html: selectedCurriculum.curriculum }}></div>
                :
                <iframe id="e-curriculum-content" className="content-section-iframe" src={selectedCurriculum?.curriculum} />
              }
            </div>
            :
            curriculamType == conditional_configs.curriculam_types.quiz ?
              <ol>
                {selectedCurriculum?.curriculum?.map((qobj, qindex) => {
                  return (
                    <li key={`e-curriculum-quiz-questions-` + qindex} className="mb-3">
                      <p className="e-curriculum-quiz-question m-0">{qobj?.coursequestion?.question}</p>
                      <ol>
                        {qobj?.coursequestion?.answerlist?.map((aObj, aIdx) => {
                          return (
                            <li className={aObj?.correct == '1' ? `e-curriculum-quiz-answer e-curriculum-quiz-answer-active` : `e-curriculum-quiz-answer`} type="a" key={`e-curriculum-quiz-answers-` + qindex + `-` + aIdx}>{aObj?.answer}</li>
                          )
                        })}
                      </ol>
                    </li>
                  )
                })}
              </ol> :
              curriculamType == conditional_configs.curriculam_types.games ?
                <div className="e-curriculum-games-container">
                  {selectedCurriculum?.data?.name} can be play during the session
                </div> :
                <div>
                  {/* <div className='whiteboard-locked-morph-div' onClick={() => onClickWhiteboard()}>
                    </div> */}
                  {/* <iframe className="content-section-iframe" src={documentimg} /> */}
                  <WhiteboardTopics></WhiteboardTopics>
                </div>
          }
        </div>
      </div>
    </div>
  );
}
