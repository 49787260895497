import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Avatar } from "@mui/material";
import account from "../../../../_mock/account";

import { Button, Grid } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Dialog
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//Dialog End

import "./experts-datails.css";

//ICONS
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";

//expert table_table

function expert_table_list(
  courseName,
  startDate,
  endDate,
  courseStatus,
  noOfSession,
  coursePrice,
  paymentPerSesion
) {
  return {
    courseName,
    startDate,
    endDate,
    courseStatus,
    noOfSession,
    coursePrice,
    paymentPerSesion,
  };
}
const expert_table_row = [
  expert_table_list(
    "Robotics",
    "Mar 01,2022",
    "Mar 12,2022",
    "COMPLETED",
    "4",
    "$100",
    "$50"
  ),
  expert_table_list(
    "Nanotechnology",
    "Feb 01,2022",
    "Feb 12,2022",
    "COMPLETED",
    "5",
    "$100",
    "$50"
  ),
  expert_table_list(
    "Gaming Theory",
    "Mar 01,2022",
    "Mar 12,2022",
    "COMPLETED",
    "3",
    "$100",
    "$50"
  ),
];
// expert table_table end

export default function ExpertsDetails() {
  const [showtab, setShowtab] = useState(1);
  const navigate = useNavigate();
  const handletabclick = (e) => {
    setShowtab(Number(e));
  };
  //popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //popup end

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      return;
    } else {
      localStorage.clear();
      navigate('/admin-login');
    }
  }, []);

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light mt-3">
        <div className="container">
          <Avatar src={account.photoURL} alt="photoURL" className="me-2" />
          <p className="experts__name_title m-0">Greek Mythology</p>

          <div className="d-flex  align-items-center ms-auto">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic outlined example"
            >
              <button
                type="button"
                class="btn btn-outline tab_btn"
                onClick={() => handletabclick(1)}
              >
                Courses
              </button>
              <button
                type="button"
                class="btn btn-outline tab_btn"
                onClick={() => handletabclick(2)}
              >
                Profile
              </button>
            </div>
          </div>
          <div className="ms-auto">
            <Button
              variant="contained"
              className="block_ex_de_btn"
              onClick={handleClickOpen}
            >
              Block Expert
            </Button>
          </div>
        </div>

      </nav>

      <hr></hr>

      <div className="">
        <div className="tab-content" id="pills-tabContent">
          {/* courses section */}
          <div
            className={
              showtab === 1 ? "tab-pane fade show active" : "tab-pane fade show"
            }
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="payment_table_header">
                  <TableRow>
                    <TableCell className="payment_table_header_text col_1">
                      Course Name
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_2"
                      align="left"
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_3"
                      align="left"
                    >
                      End Date
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Course Status
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      No of Session
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Course Price
                    </TableCell>
                    <TableCell
                      className="payment_table_header_text col_4"
                      align="left"
                    >
                      Payment Per Sesion
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expert_table_row.map((row) => (
                    <TableRow
                      key={row.courseName}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" style={{ color: "blue" }}>
                        {row.courseName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.startDate}
                      </TableCell>
                      <TableCell align="left">{row.endDate}</TableCell>
                      <TableCell align="left" style={{ color: "green" }}>
                        <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                        {row.courseStatus}
                      </TableCell>
                      <TableCell align="left">{row.noOfSession}</TableCell>
                      <TableCell align="left">{row.coursePrice}</TableCell>
                      <TableCell align="left">{row.paymentPerSesion}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* profile session */}
          <div
            className={
              showtab === 2 ? "tab-pane fade show active" : "tab-pane fade show"
            }
          >
            {" "}
            <Grid container justifyContent="center">
              <Box className="expert_profile_box">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <label className="expert_name_title_text">First Name</label>
                    <p className="expert_name_text m-0">Laurena</p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <span className="expert_name_title_text">Last Name</span>
                    <p className="expert_name_text m-0">Sheena</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <label className="expert_name_title_text">Email</label>
                    <p className="expert_name_text m-0">laurena@gmail.com</p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <span className="expert_name_title_text">
                      Registration Date
                    </span>
                    <p className="expert_name_text m-0">January 12,2022</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <label className="expert_name_title_text">Phone</label>
                    <p className="expert_name_text m-0">+1 239 238 2387</p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <span className="expert_name_title_text">Address</span>
                    <p className="expert_name_text m-0">
                      11,Avenue Lane, Dallas textSecondary 5001
                    </p>
                  </div>
                </div>
                <div>
                  <label className="expert_name_title_text">About Expert</label>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <br></br>Aenean euismod bibendum laoreet.Proin gravida dolor
                    sit <br></br> amet lacus accumsan et viverra justo
                    commodo.Proin <br></br> sodales pulvinar sic tempor. Sociis
                    natoque penatibus et <br></br> mangnis dis parturient
                    montes,nascetur ridiculus mus.
                  </p>
                </div>
              </Box>
            </Grid>
          </div>
        </div>
        {/* Dialog */}
        <div>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Block Expert"}
                <span className="add_kids_close_icon" onClick={handleClose}>
                  <CloseIcon />
                </span>
              </DialogTitle>

              <DialogContent>
                <textarea />
              </DialogContent>

              <div class="btn-text-left">
                <Button className="expert__submit_btn mb-3 ms-auto">
                  Submit
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
