import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import error_messages from '../../config/error_messages';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

//USED FOR COURSE DETAIL SCHEDULE
const displayMoreLicense = (obj) => {
    if (obj) {
        const percentage = (obj.license / obj.maxnolearners) * 100;
        if (percentage >= 50) {
            return true;
        } else {
            return false;
        }
    }
}

const getWarningMessage = (obj) => {
    if (obj) {
        const licenseLeft = obj.maxnolearners - obj.license;
        if (licenseLeft == 1) {
            return error_messages.oneSlotLeft;
        } else {
            var message = error_messages.slotsLeft;
            message = message.replace('%SLOT%', licenseLeft);
            return message
        }
    }
}

export default function TooltipWithHtml(props) {
    return (
        <div>
            {/* <HtmlTooltip placement="top"
                title={
                    <React.Fragment>
                        <Typography color="inherit">{props.TooltipTitle}</Typography>
                        Starts at <span className="htmlToolTipDesc">{props.TooltipDescription}</span>
                    </React.Fragment>
                }
            >
                {props?.screen == 'course-detail-schedule' ?
                    <div className="course-d-stream-sessions-box-active">
                        <div>
                            <p className='mb-0'>{props.courseDetailContent.label}</p>
                            {displayMoreLicense(props.courseDetailContent) ?
                                    <span className='mb-0 seats-more'>{error_messages.fewSlotsLeft}</span> :
                                    props.courseDetailContent.maxnolearners - props.courseDetailContent.license <= 3 ?
                                    <span className='mb-0 seats-more'>{getWarningMessage(props.courseDetailContent)}</span> :
                                    ""}
                        </div>
                    </div>
                    : ""}
            </HtmlTooltip> */}
            {props?.screen == 'course-detail-schedule' ?
                    <div className="course-d-stream-sessions-box-active">
                        <div>
                            <p className='mb-0'>{props.courseDetailContent.label}</p>
                            {displayMoreLicense(props.courseDetailContent) ?
                                    <span className='mb-0 seats-more'>{error_messages.fewSlotsLeft}</span> :
                                    props.courseDetailContent.maxnolearners - props.courseDetailContent.license <= 3 ?
                                    <span className='mb-0 seats-more'>{getWarningMessage(props.courseDetailContent)}</span> :
                                    ""}
                        </div>
                    </div>
                    // <span className="course-d-stream-sessions-box-active">{props.ToolTipName}</span>
                    : ""}
        </div>
    );
}